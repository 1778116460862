import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { BsSave, BsPlusCircle } from 'react-icons/bs';
import {
  ModalFormDetail,
  TextInput,
  Selection,
  FreightPriceForm,
  FreightSubChargeForm,
} from 'components';
import { LoadingView } from 'components/loading-view';
import { Box, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Formik } from 'formik';
import {
  SUB_SERVICES_ID,
  SERVICES_ID,
  PRICE_BOARD_STATUS_OPTIONS,
  INPUT_TYPES,
} from 'commons/constants';
import ApiServices from 'services';
import HeaderFormDetail from '../header-form-detail';
import { BtnCreateUpdate } from '../styles';

const AIR_CARRIER_TYPE = 1; // Hàng không
const AIR_PORT_TYPE = 1; // Cảng hàng không

export const FormDetail = ({ selected, onCloseModal, initData }) => {
  const { SELECTION, DATE_PICKER, TEXT_INPUT } = INPUT_TYPES;
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [carrierList, setCarrierList] = useState([]);

  const disableByStatus = useMemo(() => {
    return selected?.status === 1;
  }, [selected?.id]);

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(SUB_SERVICES_ID.AIR, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          if (!selected?.isNew) {
            const ind = selected?.arrSurchargeAirFreight?.findIndex(
              (i) => i.feeGroupID === item.feeGroupID,
            );
            if (ind !== -1) {
              item.surcharge = selected?.arrSurchargeAirFreight[ind]?.surcharge || [];
              item.checked = true;
            } else {
              item.checked = false;
            }
          } else {
            item.checked = false;
          }
          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDataInits = async () => {
    let [getCurrencie, getPortsByType, getUnit, getCarrier] = await Promise.all([
      ApiServices.getCurrencies(),
      ApiServices.getPortsByType(AIR_PORT_TYPE),
      ApiServices.getUnits(),
      ApiServices.getCarrierByType(AIR_CARRIER_TYPE),
    ]);
    setCurrencyList(getCurrencie.data || []);
    setPortList(getPortsByType.data || []);
    setUnitList(getUnit.data || []);
    setCarrierList(getCarrier.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoading(true);
      await getDataInits();
      setLoading(false);
    };
    getDataInit();
  }, []);

  useEffect(() => {
    if (selected) {
      getSurchargeByFeeGroupSS();
      if (selected?.arrPackingAirFreight?.length > 0) {
        setShowPrice(true);
      }
    }
  }, [selected]);

  const handleCheckedPrice = (event) => {
    setShowPrice(event.target.checked);
  };

  const handleCheckFeeGroup = (event, item) => {
    const data = groupFeeGroup?.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.checked = event.target.checked;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };

  const handleCloseCheckedItem = (item) => {
    const ind = groupFeeGroup?.findIndex((i) => i.feeGroupID === item.feeGroupID);
    if (ind === -1) return;
    const data = groupFeeGroup;
    data[ind].checked = false;
    setGroupFeeGroup(data);
  };

  const handleChangeDataFeeGroup = (event, item) => {
    const data = groupFeeGroup?.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    setGroupFeeGroup(data);
  };

  const filterDataIsChecked = () => {
    return groupFeeGroup
      ?.filter((el) => el.checked)
      ?.map((item) => {
        return {
          feeGroupID: item?.feeGroupID,
          surcharge: item?.surcharge,
        };
      });
  };

  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!showPrice) {
      values.arrPackingAirFreight = [];
    }
    if (groupFeeGroup?.length > 0) {
      values.arrSurchargeAirFreight = filterDataIsChecked();
    } else {
      values.arrSurchargeAirFreight = [];
    }
    values.serviceId = SERVICES_ID.FREIGHT; // Cước quốc tế
    values.subServiceId = SUB_SERVICES_ID.AIR; // Đường biển FCL
    delete values.isNew;

    try {
      const res = await ApiServices.createFreight(values);
      if (res.status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        onCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!showPrice) {
      values.arrPackingAirFreight = [];
    }
    if (groupFeeGroup?.length > 0) {
      values.arrSurchargeAirFreight = filterDataIsChecked();
    } else {
      values.arrSurchargeAirFreight = [];
    }
    if (!values.expirydate) {
      delete values.expirydate;
    }

    try {
      const { data } = await ApiServices.updateFreight(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        onCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const ARRAY_INPUT = [
    {
      field: 'status',
      lable: 'Trang thái',
      type: SELECTION,
      fieldValue: 'value',
      fieldView: 'label',
      dataArrObject: PRICE_BOARD_STATUS_OPTIONS,
      disabled: selected?.isNew || disableByStatus,
      isShowItem: true,
    },
    {
      field: 'carrierId',
      lable: 'Carrier',
      type: SELECTION,
      dataArrObject: carrierList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'polId',
      lable: 'Cảng xếp dỡ',
      type: SELECTION,
      dataArrObject: portList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'podId',
      lable: 'Cảng đến',
      type: SELECTION,
      dataArrObject: portList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'currencyCode',
      lable: 'Đơn vị tiền tệ',
      type: SELECTION,
      dataArrObject: currencyList,
      fieldValue: 'code',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    { field: 'etd', lable: 'ETD', type: DATE_PICKER, disabled: disableByStatus },
    { field: 'expirydate', lable: 'Hết hạn', type: DATE_PICKER },
    { field: 'note', lable: 'Ghi chú', disabled: disableByStatus, type: TEXT_INPUT },
  ];

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={onCloseModal}
        title={selected?.isNew ? 'Thêm mới' : 'Thông tin chi tiết'}
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <Divider sx={{ marginY: 1 }}>Thông tin cơ bản</Divider>
        {!loading && (
          <Formik
            initialValues={{
              status: 0,
              carrierId: '',
              polId: '',
              podId: '',
              currencyCode: '',
              etd: '',
              expirydate: '',
              arrPackingAirFreight: [],
              note: '',
              packingAirCodeCheck: [],
              ...selected,
            }}
            validationSchema={Yup.object().shape({
              carrierId: Yup.string().max(255).required('Vui lòng chọn hãng tàu'),
              polId: Yup.string().max(255).required('Vui lòng chọn cảng xếp dỡ'),
              podId: Yup.string().max(255).required('Vui lòng chọn cảng đến'),
              currencyCode: Yup.string().max(255).required('Vui lòng chọn đơn vị tiền tệ'),
              etd: Yup.string().max(255).required('Vui lòng chọn ngày ETD'),
              expirydate: Yup.string().max(255).required('Vui lòng chọn ngày hết hạn'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (selected?.isNew) {
                createFn(values, { setSubmitting });
              } else {
                updateFn(values, { setSubmitting });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {ARRAY_INPUT.map((item, index) => {
                      switch (item.type) {
                        case SELECTION:
                          return (
                            <Grid key={`${item?.field}-${index}`} item xs={4} sm={6} md={3}>
                              <Selection
                                sxInput={{ padding: '0px', margin: '0px' }}
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item?.lable}
                                name={item?.field}
                                data={item?.data}
                                disabled={item?.disabled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item?.field]}
                                fieldValue={item?.fieldValue}
                                fieldView={item?.fieldView}
                                syncData={item?.syncData}
                                dataArrObject={item?.dataArrObject}
                              />
                            </Grid>
                          );

                        case DATE_PICKER:
                          return (
                            <Grid key={`${item?.field}-${index}`} item xs={4} sm={6} md={3}>
                              <TextInput
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item?.lable}
                                name={item?.field}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item?.field]}
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={item?.disabled}
                              />
                            </Grid>
                          );
                        default:
                          return (
                            <Grid key={`${item?.field}-${index}`} item xs={4} sm={6} md={3}>
                              <TextInput
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item?.lable}
                                name={item?.field}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item?.field]}
                                disabled={item?.disabled}
                              />
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                </Box>
                <Divider sx={{ marginY: 1 }}>Bảng giá chi tiết</Divider>
                <Selection
                  sx={{ width: '50%', marginBottom: '10px' }}
                  error={Boolean(touched.packingAirCodeCheck && errors.packingAirCodeCheck)}
                  helperText={touched.packingAirCodeCheck && errors.packingAirCodeCheck}
                  label="Quy cách"
                  name="packingAirCodeCheck"
                  multiple={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.packingAirCodeCheck}
                  syncData={ApiServices.getPackingAirs}
                  fieldValue="code"
                  disabled={disableByStatus}
                />
                {values?.packingAirCodeCheck?.length > 0 && (
                  <>
                    <FormGroup>
                      <FormControlLabel
                        sx={{ width: '30%' }}
                        control={<Checkbox checked={showPrice} onChange={handleCheckedPrice} />}
                        label="Giá cước"
                        disabled={disableByStatus}
                      />
                    </FormGroup>
                    {showPrice && (
                      <FreightPriceForm
                        arrContType={values.packingAirCodeCheck}
                        unitList={unitList}
                        name="arrPackingAirFreight"
                        requiredTitle="Vui lòng nhập đầy đủ thông tin"
                        onChange={handleChange}
                        value={values?.arrPackingAirFreight}
                        isAddRow={false}
                        closeCheckedItem={() => setShowPrice(false)}
                        disabledField={disableByStatus}
                        keyArray="arrPackingAir" // array cont main
                        keyNameOfArray="packingAirCode" // name of array cont
                      />
                    )}
                    {groupFeeGroup?.map((item) => {
                      return (
                        <FormGroup key={item.feeGroupID}>
                          <FormControlLabel
                            sx={{ width: '30%' }}
                            control={
                              <Checkbox
                                checked={item.checked}
                                onChange={(event) => handleCheckFeeGroup(event, item)}
                                disabled={disableByStatus}
                              />
                            }
                            label={item.feeGroupName}
                          />
                          {item.checked && (
                            <FreightSubChargeForm
                              arrContType={values?.packingAirCodeCheck}
                              arrSurcharge={item?.surchargeListFree}
                              arrUnit={unitList}
                              name={item?.feeGroupID}
                              onChange={(event) => handleChangeDataFeeGroup(event, item)}
                              value={item?.surcharge}
                              closeCheckedItem={() => handleCloseCheckedItem(item)}
                              requiredTitle="Vui lòng nhập đầy đủ thông tin"
                              disabledField={disableByStatus}
                              keyArray="arrPackingAir" // array cont main
                              keyNameOfArray="packingAirCode" // name of array cont
                            />
                          )}
                        </FormGroup>
                      );
                    })}
                  </>
                )}
                <Box sx={{ display: 'flex', py: 2 }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    {!selected?.expirationStatus && (
                      <BtnCreateUpdate
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        startIcon={selected?.isNew ? <BsPlusCircle /> : <BsSave />}
                      >
                        {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                      </BtnCreateUpdate>
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
