import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Grid } from '@mui/material';

export const GeneralInformation = ({ selected }) => {
  const ARRAY_HEADER = [
    {
      title: 'Mã:',
      value: `${selected?.code}`,
      xs: 2,
    },
    {
      title: 'Tên khách hàng:',
      value: `${selected?.name}`,
      xs: 4,
    },
    {
      title: 'Công ty chủ quản:',
      value: `${selected?.companyCode}`,
      xs: 3,
    },
    {
      title: 'Mã số thuế',
      value: `${selected?.tax_Code}`,
      xs: 3,
    },
    {
      title: 'Số điện thoại:',
      value: `${selected?.arrNumberPhone}`,
      xs: 2,
    },
    {
      title: 'Địa chỉ:',
      value: `${selected?.arrAddress}`,
      xs: 4,
    },
    {
      title: 'Fax:',
      value: `${selected?.arrFax}`,
      xs: 3,
    },
    {
      title: 'Email:',
      value: `${selected?.email}`,
      xs: 3,
    },
  ];

  return (
    <>
      <Box sx={{ padding: '5px', fontSize: '14px' }}>
        <Grid container spacing={1}>
          {ARRAY_HEADER.map((item, index) => (
            <Grid item xs={item?.xs} key={`${item?.title}-${index}`}>
              <Typography component="b" variant="string">
                {item?.title}
              </Typography>
              <Typography component="div" variant="string" sx={{ color: 'blue' }}>
                {item?.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export const ContainerItemService = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
}));
