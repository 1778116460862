import React, { useState, useEffect, useMemo } from 'react';
import {
  BsEye,
  BsSave,
  BsBoxArrowInUpRight,
  BsXCircle,
  BsCheck2Circle,
  BsEnvelope,
} from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { updateServiceToQuote } from 'store/reducers/customerQuotes/action';
import { SUB_SERVICES_ID, QUOTE_STATUS } from 'commons/constants';
import { Box, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { QuoteTruckingPriceForm } from '../../forms-management/quote-trucking-price-form';
import { QuoteTruckingReturnContForm } from '../../forms-management/quote-trucking-return-cont-form';
import { QuoteTruckingSubChargeForm } from '../../forms-management/quote-trucking-sub-charge-form';
import Utils from 'commons/utils';
import ApiServices from 'services';

import { HeaderFreight } from './header';
import {
  ContainerPrice,
  BtnViewReportQuote,
  BtnCreateQuote,
  BtnRequestQuote,
  BtnRejectQuote,
  BtnApproveQuote,
  BtnSendQuote,
} from './styles';

const TruckingControlQuoteForm = ({ handleCloseAfterSubmit, initData, selected }) => {
  const dispatch = useDispatch();
  const { TRACTOR, TRUCK } = SUB_SERVICES_ID;
  const { itemTruckingSelected, objQuotes } = useSelector((state) => state.customerQuotes);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [locationVTList, setLocationVTList] = useState([]);

  const isTractor = useMemo(() => {
    return itemTruckingSelected?.subServiceId === TRACTOR;
  }, [itemTruckingSelected?.subServiceId]);
  const isTruck = useMemo(() => {
    return itemTruckingSelected?.subServiceId === TRUCK;
  }, [itemTruckingSelected?.subServiceId]);

  const isDisabledForm = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [selected]);

  const getCargoType = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCargoTypes();
      if (data) {
        setCargoTypeList(data || []);
      }
    } catch (err) {
      console.warn('getCargoType', err);
    } finally {
      setLoading(false);
    }
  };
  const getLocationVT = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getLocationVTs();
      if (data) {
        setLocationVTList(data || []);
      }
    } catch (err) {
      console.warn('getLocationVT', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargoType();
    getLocationVT();
  }, []);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = itemTruckingSelected?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = itemTruckingSelected?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (itemTruckingSelected?.subServiceId) {
      getSurchargeByFeeGroupSS(itemTruckingSelected?.subServiceId);
    }
  }, []);

  const createQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: {
        ...objQuotes,
        quotesStatus,
        quotedate: quoteDate,
        arrServiceId: [itemTruckingSelected?.serviceId],
        arrSubServiceId: [itemTruckingSelected?.subServiceId],
      },
      objTrucking: { ...itemTruckingSelected },
    };
    try {
      const { status } = await ApiServices.createTruckingQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const updateQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: { ...objQuotes, quotedate: quoteDate, quotesStatus },
      objTrucking: { ...itemTruckingSelected },
    };
    try {
      const { status } = await ApiServices.updateTruckingQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const handleSubmit = (quotesStatus, quoteDate = null) => {
    if (selected?.isNew) {
      return createQuote(quotesStatus, quoteDate);
    } else {
      return updateQuote(quotesStatus, quoteDate);
    }
  };

  const handleReportQuoteTemplate = async (subServiceId, quoteId) => {
    if (subServiceId === TRUCK) return;
    await ApiServices.exportFileOfTractor(subServiceId, quoteId)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `${selected?.code}.docx`);
        }
      })
      .catch((err) => {
        console.log('Failed to get report quote tempalte:', err);
      });
  };

  const handleChangeContTractor = (e) => {
    dispatch(updateServiceToQuote({ arrContTractor: e.target.value }));
  };
  const handleChangeReturnCont = (e) => {
    dispatch(updateServiceToQuote({ subChargeNewPort: e.target.value }));
  };
  const handleChangeSubCharge = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    dispatch(updateServiceToQuote({ arrSurcharge: data }));
  };

  const renderSubChargeTrucking = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteTruckingSubChargeForm
          arrSurcharge={item.surchargeListFree}
          name={item.feeGroupID}
          onChange={(event) => handleChangeSubCharge(event, item)}
          value={item?.surcharge}
          disabled={isDisabledForm}
        />
      )}
    </FormGroup>
  );

  return (
    <>
      <HeaderFreight />
      <b>Giá cước</b>

      <ContainerPrice>
        <QuoteTruckingPriceForm
          arrContType={itemTruckingSelected?.arrTypeCargoCode}
          cargoTypeList={cargoTypeList}
          name="arrContTractor"
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          onChange={handleChangeContTractor}
          value={itemTruckingSelected?.arrContTractor}
          disabled={isDisabledForm}
        />
        <FormGroup>
          <FormControlLabel
            sx={{ width: '50%' }}
            control={
              <Checkbox
                checked={itemTruckingSelected?.subChargeNewPort?.length > 0 ? true : false}
              />
            }
            label="Lấy hoặc trả cont ở cảng khác"
          />
        </FormGroup>
        {itemTruckingSelected?.subChargeNewPort?.length > 0 && (
          <QuoteTruckingReturnContForm
            locationVTList={locationVTList}
            name="subChargeNewPort"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={handleChangeReturnCont}
            value={itemTruckingSelected.subChargeNewPort}
            disabled={isDisabledForm}
          />
        )}
        {groupFeeGroup?.map(renderSubChargeTrucking)}
      </ContainerPrice>
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          {!selected?.isNew && (
            <BtnViewReportQuote
              variant="outlined"
              startIcon={<BsEye />}
              onClick={() => handleReportQuoteTemplate(selected?.arrSubServiceId[0], selected?.id)}
            >
              Xem báo giá
            </BtnViewReportQuote>
          )}

          {(selected?.isNew || selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) && (
            <>
              <BtnCreateQuote
                sx={{ marginRight: '15px' }}
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<BsSave size={16} />}
                onClick={() => handleSubmit(QUOTE_STATUS.ORIGIN_QUOTE)}
                disabled={!objQuotes?.clientCompanyId}
              >
                {selected?.isNew ? 'Tạo báo giá' : 'Lưu'}
              </BtnCreateQuote>
              <BtnRequestQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsBoxArrowInUpRight size={20} />}
                onClick={() =>
                  handleSubmit(QUOTE_STATUS.REQUEST_APPROVE, Utils.convertToDateFormat(new Date()))
                }
                disabled={!objQuotes?.clientCompanyId || !objQuotes?.clientId}
              >
                Trình duyệt
              </BtnRequestQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.REQUEST_APPROVE && (
            <>
              <BtnRejectQuote
                sx={{ marginRight: '15px' }}
                color="error"
                type="submit"
                variant="contained"
                startIcon={<BsXCircle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.REJECT_APPROVE)}
              >
                Không duyệt
              </BtnRejectQuote>
              <BtnApproveQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsCheck2Circle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
              >
                Duyệt
              </BtnApproveQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.APPROVED && (
            <BtnSendQuote
              color="success"
              type="submit"
              variant="contained"
              startIcon={<BsEnvelope size={20} />}
              // onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
            >
              Gửi báo giá
            </BtnSendQuote>
          )}
        </Box>
      </Box>
    </>
  );
};

export { TruckingControlQuoteForm };
