import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { Table, DatePicker } from 'components';
import { FormDetail } from './form-detail';
import { Box, IconButton, Typography, Stack } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';

import SearchIcon from '@mui/icons-material/Search';

import { Columns } from './columns';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';
import { RESPONSE_STATUSES } from 'commons/constants';
import { debounce } from 'lodash';

const CLIENT_GIFT_TEMPLATE = 'CustomerGifts_TemplateIm';
const START_DATE = moment().startOf('month').format();
const END_DATE = moment().endOf('date').format();

const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
  fromDate: START_DATE,
  toDate: END_DATE,
};

const DEFAULT_PARAMS = {
  typeClientCompany: '',
  strClientCompanyName: '',
  strRealReceiver: '',
};

function ClientGift() {
  const inputRef = useRef();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [params, setParams] = useState(DEFAULT_PARAMS);

  const [searchClientType, setSearchClientType] = useState(2);

  // search Date
  const [startDate, setStartDate] = useState(START_DATE);
  const [endDate, setEndDate] = useState(END_DATE);

  const initData = (query = filter, paramSearch = params) => {
    console.log({ aaa: paramSearch });
    setLoading(true);
    return ApiServices.getPagingClientGift(query, paramSearch)
      .then((res) => {
        if (res?.data) {
          if (totalRecords !== res?.data?.totalRecords) setTotalRecords(res?.data?.totalRecords);
          setDataList(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('get Clients:', err))
      .finally(() => setLoading(false));
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newParams) => {
      initData(newFilter, newParams);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter, params);
  }, [filter, params]);

  const reloadData = async () => {
    return await initData(filter);
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };

  const handleSearchColumnByParam = (event) => {
    let value = event.target.value || '';
    let name = event.target.name;

    setParams((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSearchSelect = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setSearchClientType(value);

    setParams((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeStartDate = useCallback(
    (event) => {
      setStartDate(event.target.value);
    },
    [startDate],
  );
  const handleChangeEndDate = useCallback(
    (event) => {
      setEndDate(event.target.value);
    },
    [endDate],
  );

  const handleSearchDate = (startDateSearch, endDateSearch) => {
    console.log({ startDateSearch, endDateSearch });
    if (!startDateSearch || !endDateSearch) return;
    setFilter((prev) => ({ ...prev, fromDate: startDateSearch, toDate: endDateSearch }));
  };

  const deleteItem = async (row) => {
    if (!row?.id) return;

    setLoading(true);
    return ApiServices.deleteClientGift(row?.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteClientGift', err);
      })
      .finally(() => setLoading(false));
  };

  const onHandleImport = (event) => {
    if (loadingImport || !event) return;
    const file = event?.target?.files[0];
    inputRef.current.value = '';
    if (!file) {
      window.UIMessage?.error('Tải file excel thất bại !!!');
      return;
    }
    setLoadingImport(true);
    return ApiServices.importExcelClientGift({ excelFile: file })
      .then((res) => {
        if (res?.data) {
          window.UIMessage?.success(`Import excel thành công`, 3000);
          // event.target.value = '';
          reloadData();
        }
      })
      .catch((err) => {
        console.warn('import excel - importExcelClientGift', err);
        window.UIMessage?.error('Import excel thất bại !!!');
      })
      .finally(() => setLoadingImport(false));
  };
  const handleExportClient = () => {
    setLoading(true);
    return ApiServices.exportExcelClientGift()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `Danh_Sách_Quà_Tặng_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Client file error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportTemplate = () => {
    setLoading(true);
    return ApiServices.exportTemplates(CLIENT_GIFT_TEMPLATE)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Danh_Sách_Quà_Tặng.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Stack spacing={2} direction="row">
          <Box sx={{ width: '160px' }}>
            <DatePicker
              sx={{
                marginBottom: '10px',
                padding: 0,
              }}
              width={100}
              marginTop="0"
              size="small"
              onChange={handleChangeStartDate}
              value={startDate}
              error={true}
              label="Từ ngày"
            />
          </Box>
          <Box sx={{ width: '160px' }}>
            <DatePicker
              sx={{
                marginBottom: '10px',
                padding: 0,
              }}
              width={100}
              marginTop="0"
              size="small"
              onChange={handleChangeEndDate}
              value={endDate}
              error={true}
              label="Đến ngày"
            />
          </Box>

          <IconButton
            aria-label="filter"
            sx={{ color: 'blue' }}
            onClick={() => handleSearchDate(startDate, endDate)}
          >
            <SearchIcon />
          </IconButton>
          {/* <IconButton aria-label="filter" sx={{ color: 'red' }}>
            <SearchOffIcon />
          </IconButton> */}
        </Stack>
      </ContainerHeader>

      {/* <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton> */}
      <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          onChange={onHandleImport}
        />
      </ButtonImport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClient}
      >
        Tải excel
      </ButtonExport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.giftSendDate = Utils.checkIsDate(row.giftSendDate)
      ? Utils.convertToDateFormat(row.giftSendDate)
      : '';
    return dataSelect;
  };
  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelected(dataSelect);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" component="h6" sx={{ marginBottom: '10px' }}>
          DANH SÁCH QUÀ TẶNG
        </Typography>
        <Table
          filter={filter}
          rows={dataList}
          headCells={Columns(
            handleSearchColumn,
            deleteItem,
            searchClientType,
            handleSearchSelect,
            handleSearchColumnByParam,
          )}
          totalRecords={totalRecords}
          onPageChange={(paging) => {
            setFilter((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={handleClickDetail}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, reloadData }} />
      </Box>
    </>
  );
}
export { ClientGift };
