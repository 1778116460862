import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, InputBase, Stack, Button, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import FileServices from 'services/file-services';
import { FileIcon, defaultStyles } from 'react-file-icon';

export const FileItem = ({ file, index, onDeleted, onUploaded, imaged, setCountLoadingFiles }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!file?.id) {
      setLoading(true);
      setCountLoadingFiles((prev) => prev + 1);
      return FileServices.uploadFile(file)
        .then((response) => {
          onUploaded && onUploaded(index, response.data);
        })
        .catch((err) => {
          window.UIMessage.error('Upload file thất bại');
          console.error('FileServices - upload err', err);
          setTimeout(() => onDeleted && onDeleted(index), 1500);
        })
        .finally(() => {
          setLoading(false);
          setCountLoadingFiles((prev) => prev - 1);
        });
    }
  }, [file]);
  const onDelete = () => {
    onDeleted && onDeleted(index);
  };
  const getImagesSrc = (file) =>
    process.env.REACT_APP_FILE_API_URL +
    process.env.REACT_APP_FILE_PATH_PREFIX +
    `/streaming/${file?.contentType && file.contentType.replace('/', '%2F')}/${file?.id}`;

  const getFileExtension = () => {
    if (file.type) {
      return file.type.split('/').pop();
    }
    return file?.contentType && file?.contentType.split('/').pop();
  };
  return (
    <FileWrapper>
      {loading ? (
        <LoadingView>
          <CircularProgress size="20px" />
        </LoadingView>
      ) : (
        <CancelButton onClick={onDelete}>
          <CloseIcon fontSize="5px" sx={{ color: 'background.paper' }} />
        </CancelButton>
      )}

      {imaged ? (
        <Image src={getImagesSrc(file)} />
      ) : (
        <IconWrapper>
          <FileIcon extension={getFileExtension()} {...defaultStyles[getFileExtension()]} />
        </IconWrapper>
      )}
    </FileWrapper>
  );
};
const Image = styled('img')(({ theme }) => ({
  height: 'auto',
  width: 50,
  maxHeight: 60,
  objectFit: 'cover',
}));
const IconWrapper = styled(Box)(({ theme }) => ({
  height: 30,
  width: 30,
}));
const CancelButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -8,
  right: -8,
  backgroundColor: '#c8c8cc',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 20,
  width: 20,
  zIndex: 1,
  '&:hover': {
    backgroundColor: theme.palette.action.active,
    cursor: 'pointer',
  },
}));

const LoadingView = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.action.hover,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  zIndex: 1,
}));

const FileWrapper = styled(Box)(({ theme }) => ({
  height: 60,
  width: 60,
  position: 'relative',
  borderRadius: '4px',
  marginTop: 8,
  borderColor: theme.palette.action.hover,
  borderWidth: 1,
  borderStyle: 'solid',
  marginRight: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
