import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box, FormGroup, FormControlLabel, Checkbox, IconButton, Collapse } from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateResCheckPrice } from 'store/reducers/res-check-price/action';
import { Selection } from 'components';
import ApiServices from 'services';
import { SUB_SERVICES_ID } from 'commons/constants';
import { TractorPriceForm } from './tractor-price-form';
import { TruckCargoForm } from './truck-cargo-form';
import { TractorReturnContForm } from './tractor-return-cont-form';
import { SubChargeForm } from './sub-charge-form';

export const TruckingResponseForm = ({ selected, itemData }) => {
  const indexData = 0;
  const dispatch = useDispatch();
  const { subServiceSelectedList } = useSelector((state) => state.resCheckPrice);
  const { TRACTOR, TRUCK } = SUB_SERVICES_ID;
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [locationVTList, setLocationVTList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [locationFromId, setLocationFromId] = useState('');
  const [areaFromId, setAreaFromId] = useState('');
  const [locationToId, setLocationToId] = useState('');
  const [areaToId, setAreaToId] = useState('');
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [arrTypeCargoCode, setArrTypeCargoCode] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyCode, setCurrencyCode] = useState('');
  const [arrContTractor, setArrContTractor] = useState([]);
  const [arrContTruck, setArrContTruck] = useState([]);
  const [showReturnCont, setShowReturnCont] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [subChargeNewPort, setSubChargeNewPort] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [groupFeeGroup, setGroupFeeGroup] = React.useState([]);
  // const [arrSurcharge, setArrSurcharge] = React.useState([]);

  useEffect(() => {
    if (itemData && itemData?.contentList) {
      setLocationFromId(itemData?.contentList[0]?.locationFromId || '');
      setAreaFromId(itemData?.contentList[0]?.areaFromId || '');
      setLocationToId(itemData?.contentList[0]?.locationToId || '');
      setAreaToId(itemData?.contentList[0]?.areaToId || '');
      setArrTypeCargoCode(itemData?.contentList[0]?.arrTypeCargoCode || []);
      setCurrencyCode(itemData?.contentList[0]?.currencyCode || '');
      setArrContTractor(itemData?.contentList[0]?.arrContTractor || []);
      setArrContTruck(itemData?.contentList[0]?.arrContTruck || []);
      setSubChargeNewPort(itemData?.contentList[0]?.subChargeNewPort || []);
      if (itemData?.contentList[0]?.subChargeNewPort?.length > 0) {
        setShowReturnCont(true);
      }
      // setArrSurcharge(itemData?.contentList[0]?.arrSurcharge || []);
    }
  }, [itemData]);

  const getDataInits = async () => {
    let [getLocationVT, getArea, getCargoType, getCurrency, getUnit] = await Promise.all([
      ApiServices.getLocationVTs(),
      ApiServices.getAreas(),
      ApiServices.getCargoTypes(),
      ApiServices.getCurrencies(),
      ApiServices.getUnits(),
    ]);
    setLocationVTList(getLocationVT.data || []);
    setAreaList(getArea.data || []);
    setCargoTypeList(getCargoType.data || []);
    setCurrencyList(getCurrency.data || []);
    setUnitList(getUnit.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoadingInit(true);
      await getDataInits();
      setLoadingInit(false);
    };
    getDataInit();
  }, []);

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(itemData?.id, true);
      if (data) {
        const dataMapFeeGroup = data.map((element) => {
          if (itemData?.contentList[0]?.arrSurcharge?.length > 0) {
            const ind = itemData?.contentList[0]?.arrSurcharge?.findIndex(
              (i) => i.feeGroupID === element.feeGroupID,
            );
            if (ind !== -1) {
              element.surcharge = itemData?.contentList[0]?.arrSurcharge[ind]?.surcharge || [];
              element.checked = true;
            } else {
              element.checked = false;
              element.surcharge = [];
            }
          } else {
            element.checked = false;
            element.surcharge = [];
          }
          return element;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (itemData.id) {
      getSurchargeByFeeGroupSS();
    }
  }, [itemData?.id]);

  useEffect(() => {
    if (groupFeeGroup && groupFeeGroup?.length > 0) {
      const data = groupFeeGroup
        ?.filter((el) => el.checked)
        ?.map((item) => {
          return {
            feeGroupID: item?.feeGroupID,
            surcharge: item?.surcharge,
          };
        });
      return dispatch(updateResCheckPrice(itemData?.id, 'arrSurcharge', data, indexData));
    }
  }, [groupFeeGroup]);

  const handleChangeLocationFrom = useCallback(
    async (e) => {
      setLocationFromId(e.target.value);
      setAreaFromId('');
      dispatch(
        updateResCheckPrice(itemData.id, 'locationFromId', e.target.value, indexData, 'areaFromId'),
      );
    },
    [locationFromId],
  );
  const handleChangeAreaFrom = useCallback(
    async (e) => {
      setAreaFromId(e.target.value);
      setLocationFromId('');
      dispatch(
        updateResCheckPrice(itemData.id, 'areaFromId', e.target.value, indexData, 'locationFromId'),
      );
    },
    [areaFromId],
  );
  const handleChangeLocationTo = useCallback(
    async (e) => {
      setLocationToId(e.target.value);
      setAreaToId('');
      dispatch(
        updateResCheckPrice(itemData.id, 'locationToId', e.target.value, indexData, 'areaToId'),
      );
    },
    [locationToId],
  );
  const handleChangeAreaTo = useCallback(
    async (e) => {
      setAreaToId(e.target.value);
      setLocationToId('');
      dispatch(
        updateResCheckPrice(itemData.id, 'areaToId', e.target.value, indexData, 'locationToId'),
      );
    },
    [areaToId],
  );
  const handleChangeCargoType = useCallback(
    async (e) => {
      setArrTypeCargoCode(e.target.value);
      dispatch(updateResCheckPrice(itemData.id, 'arrTypeCargoCode', e.target.value, indexData));
    },
    [arrTypeCargoCode],
  );
  const handleChangeCurrency = useCallback(
    async (e) => {
      setCurrencyCode(e.target.value);
      dispatch(updateResCheckPrice(itemData.id, 'currencyCode', e.target.value, indexData));
    },
    [currencyCode],
  );
  const handleChangeTractorRequestPrice = useCallback(
    async (e) => {
      setArrContTractor(e.target.value);
      dispatch(updateResCheckPrice(itemData.id, 'arrContTractor', e.target.value, indexData));
    },
    [arrContTractor],
  );
  const handleChangeTruckRequestPrice = useCallback(
    async (e) => {
      setArrContTruck(e.target.value);
      dispatch(updateResCheckPrice(itemData.id, 'arrContTruck', e.target.value, indexData));
    },
    [arrContTruck],
  );
  const handleChangeSubChargeNewPort = useCallback(
    async (e) => {
      setSubChargeNewPort(e.target.value);
      dispatch(updateResCheckPrice(itemData.id, 'subChargeNewPort', e.target.value, indexData));
    },
    [subChargeNewPort],
  );
  const handleCheckedReturnCont = (event) => {
    setShowReturnCont(event.target.checked);
  };
  const handleOpenItem = () => {
    setOpen(!open);
  };

  const handleCheckFeeGroup = (event, item) => {
    const data = groupFeeGroup?.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.checked = event.target.checked;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };

  const handleChangeDataFeeGroup = (event, item) => {
    const data = groupFeeGroup?.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.surcharge = event.target.value;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };
  const handleCloseCheckedItem = (item) => {
    const ind = groupFeeGroup?.findIndex((i) => i.feeGroupID === item.feeGroupID);
    if (ind !== -1) {
      const data = groupFeeGroup;
      data[ind].checked = false;
      setGroupFeeGroup(data);
    }
  };
  const renderFeeGroup = (item) => (
    <FormGroup key={item.feeGroupID}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={
          <Checkbox checked={item.checked} onChange={(event) => handleCheckFeeGroup(event, item)} />
        }
        label={item.feeGroupName}
      />
      {item.checked && (
        <SubChargeForm
          arrSurcharge={item.surchargeListFree}
          arrUnit={unitList}
          name={item.feeGroupID}
          onChange={(event) => handleChangeDataFeeGroup(event, item)}
          value={item?.surcharge}
          closeCheckedItem={() => handleCloseCheckedItem(item)}
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
        />
      )}
    </FormGroup>
  );

  const ARRAY_SELECT_GENERAL_INFO = [
    {
      value: locationFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(areaFromId),
      label: 'Điểm đi',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationFrom,
    },
    {
      value: areaFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(locationFromId),
      label: 'Khu vực đi',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaFrom,
    },
    {
      value: locationToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(areaToId),
      label: 'Điểm đến',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationTo,
    },
    {
      value: areaToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(locationToId),
      label: 'Khu vực đến',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaTo,
    },
  ];

  return (
    <>
      {!loadingInit && !loading && (
        <>
          <ContainerHeaderTractor>
            <Selection
              sx={{ width: '50%' }}
              error={!arrTypeCargoCode || arrTypeCargoCode?.length === 0}
              helperText={(!arrTypeCargoCode || arrTypeCargoCode?.length === 0) && 'Bắt buộc (*)'}
              label="Loại hàng"
              name="arrTypeCargoCode"
              multiple={true}
              onChange={handleChangeCargoType}
              value={arrTypeCargoCode}
              fieldValue="id"
              fieldView="name"
              dataArrObject={cargoTypeList}
              disabled
            />
            {itemData?.id === TRACTOR && (
              <Selection
                sx={{ width: '50%', marginLeft: '10px' }}
                error={!currencyCode}
                helperText={!currencyCode && 'Bắt buộc (*)'}
                label="Đơn vị tiền tệ"
                name="currencyCode"
                onChange={handleChangeCurrency}
                value={currencyCode}
                fieldValue="code"
                fieldView="name"
                dataArrObject={currencyList}
                disabled
              />
            )}
          </ContainerHeaderTractor>
          <b>GIÁ CƯỚC</b>
          <ContainerGeneral>
            {ARRAY_SELECT_GENERAL_INFO.map((item, index) => {
              return (
                <Selection
                  key={`selection-${item?.label + index}`}
                  sx={{ width: '50%', marginRight: '10px' }}
                  sxInput={{ padding: '0px', margin: '0px' }}
                  error={item?.isError}
                  helperText={item?.isError && 'Bắt buộc (*)'}
                  label={item.label}
                  name={item.field}
                  disabled={item?.disabled}
                  onChange={item?.handleChange}
                  value={item?.value}
                  fieldValue={item?.fieldValue}
                  fieldView={item?.fieldView}
                  syncData={item?.syncData}
                  dataArrObject={item?.dataArrObject}
                  disabledItemNone={false}
                />
              );
            })}
          </ContainerGeneral>
          <ContainerRequestPrice>
            {itemData?.id === TRACTOR && arrTypeCargoCode?.length > 0 && (
              <TractorPriceForm
                arrContType={arrTypeCargoCode}
                cargoTypeList={cargoTypeList}
                name="arrContTractor"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeTractorRequestPrice}
                value={arrContTractor}
                isShowAction={false}
              />
            )}
            {itemData?.id === TRUCK && arrTypeCargoCode?.length > 0 && (
              <TruckCargoForm
                arrContType={arrTypeCargoCode}
                cargoTypeList={cargoTypeList}
                name="arrContTruck"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeTruckRequestPrice}
                value={arrContTruck}
                isShowAction={false}
              />
            )}
          </ContainerRequestPrice>
          <ContainerSurCharge>
            <Box>
              <IconButton aria-label="expand row" size="small" onClick={handleOpenItem}>
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </IconButton>
              <i style={{ color: 'blue' }}>Phụ phí vận chuyển</i>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {itemData?.id === TRACTOR && (
                <ContainerReturnCont>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ width: '50%' }}
                      control={
                        <Checkbox checked={showReturnCont} onChange={handleCheckedReturnCont} />
                      }
                      label="Lấy hoặc trả cont ở cảng khác"
                    />
                  </FormGroup>
                  {showReturnCont && (
                    <TractorReturnContForm
                      locationVTList={locationVTList}
                      unitList={unitList}
                      name="subChargeNewPort"
                      requiredTitle="Vui lòng nhập đầy đủ thông tin"
                      onChange={handleChangeSubChargeNewPort}
                      value={subChargeNewPort}
                      closeCheckedItem={() => setShowReturnCont(false)}
                    />
                  )}
                </ContainerReturnCont>
              )}
              {groupFeeGroup?.map(renderFeeGroup)}
            </Collapse>
          </ContainerSurCharge>
        </>
      )}
    </>
  );
};

export const ContainerGeneral = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const ContainerRequestPrice = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
}));

export const ContainerHeaderTractor = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));
export const ContainerSurCharge = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
}));
export const ContainerReturnCont = styled(Box)(({ theme }) => ({
  width: '100%',
  // marginLeft: '10px',
}));
