import moment from 'moment';
import { appointments } from './appointments';

const currentDate = moment();
let date = currentDate.date();

const makeTodayAppointment = (startDate, endDate) => {
  const days = moment(startDate).diff(endDate, 'days');
  const nextStartDate = moment(startDate)
    .year(currentDate.year())
    .month(currentDate.month())
    .date(date);
  const nextEndDate = moment(endDate)
    .year(currentDate.year())
    .month(currentDate.month())
    .date(date + days);

  return {
    startDate: nextStartDate.toDate(),
    endDate: nextEndDate.toDate(),
  };
};

export default (data) =>
  appointments(data).map(({ startDate, endDate, ...restArgs }) => {
    const result = {
      ...makeTodayAppointment(startDate, endDate),
      ...restArgs,
    };
    date += 1;
    if (date > 31) date = 1;
    return result;
  });
