import actionTypes from './type';
import ApiServices from 'services';

export function getSubServiceSelected(serviceId, dataContent = []) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getSubServiceByServiceId(serviceId);
      if (data) {
        const dataMapSubService = data.map((item) => {
          let newData = {
            id: item?.id,
            name: item?.name,
            checked: false,
            contentList: [],
          };
          const dataContentFilter = dataContent?.filter(
            (itemContent) => itemContent?.subServiceId === item?.id,
          );
          if (dataContentFilter?.length > 0) {
            newData = {
              ...newData,
              checked: true,
              contentList: dataContentFilter,
            };
          }
          return newData;
        });
        return dispatch({
          data: dataMapSubService,
          type: actionTypes.GET_SUB_SERVICE_SELECTED_LIST,
        });
      }
    } catch (error) {
      console.error('getSubServiceSelected-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.GET_SUB_SERVICE_SELECTED_LIST });
  };
}
export function clearSubServiceSelected() {
  return async (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_SUB_SERVICE_SELECTED_LIST });
  };
}
export function addResCheckPrice(itemId, newData) {
  return async (dispatch) => {
    return dispatch({ itemId, newData, type: actionTypes.ADD_DATA_RES_CHECK_PRICE });
  };
}

export function updateResCheckPrice(
  itemId,
  keyUpdate,
  dataUpdate,
  indexContentList,
  keyClear = null,
) {
  return async (dispatch) => {
    return dispatch({
      itemId,
      keyUpdate,
      dataUpdate,
      indexContentList,
      keyClear,
      type: actionTypes.UPDATE_DATA_RES_CHECK_PRICE,
    });
  };
}

export function deleteResCheckPrice(itemId, indexContentList) {
  return async (dispatch) => {
    return dispatch({
      itemId,
      indexContentList,
      type: actionTypes.DELETE_DATA_RES_CHECK_PRICE,
    });
  };
}
