import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  ({ item, index, setValues, error, disabled, keyArray, keyNameOfArray, cargoTypeList }, ref) => {
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setArrayCont(item[keyArray] || []);
    }, [item]);

    const onChangeCont = React.useCallback(
      (e, indContType, keyChange, cargoTypePrice) => {
        const value = +e.target.value;
        setArrayCont((prev) => {
          if (keyChange === 'makeup') {
            // Gia ban = Gia mua + gia mua * makeup
            prev[indContType].buyPrice = cargoTypePrice + (cargoTypePrice * value) / 100;
            prev[indContType].makeup = value;
          }
          if (keyChange === 'buyPrice') {
            // Gia ban = Gia mua + gia mua * makeup
            prev[indContType].makeup = ((value - cargoTypePrice) / cargoTypePrice) * 100;
            prev[indContType].buyPrice = value;
          }
          return [...prev];
        });

        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    const renderLabel = (id) => {
      const ind = cargoTypeList.findIndex((item) => item.id === id);
      if (ind === -1) return '';
      return cargoTypeList[ind]?.name;
    };

    return (
      <Container>
        <StyledTextCont
          label="Loại cont"
          variant="outlined"
          type="text"
          size="small"
          value={item?.contTypeCode}
        />
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`cont-${indContType}`}>
            <StyledTextFieldPrice
              label={renderLabel(el[keyNameOfArray])}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.cargoTypePrice}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`MakeUp-${indContType}`}>
            <TextFieldMakeUp
              label={`%Make Up ${renderLabel(el[keyNameOfArray])}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => onChangeCont(e, indContType, 'makeup', el?.cargoTypePrice)}
              value={el?.makeup}
              disabled={disabled}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`BuyPrice-${indContType}`}>
            <TextFieldBuyPrice
              label={`Giá bán ${renderLabel(el[keyNameOfArray])}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => onChangeCont(e, indContType, 'buyPrice', el?.cargoTypePrice)}
              value={el?.buyPrice}
              error={!el?.buyPrice}
              disabled={disabled}
            />
          </Box>
        ))}
        <StyledTextField
          label="Remark"
          variant="outlined"
          type="text"
          size="small"
          value={item?.remark}
          error={error}
        />
      </Container>
    );
  },
);
export default InputItem;

const StyledTextCont = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '200px',
}));
const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '120px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: '#FCF2EF',
}));
const TextFieldBuyPrice = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
