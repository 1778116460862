import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box } from '@mui/material';
import { BsPlus } from 'react-icons/bs';
import ApiServices from 'services';
import { Columns } from './columns';
import { ContainerHeader, ButtonAdd } from './styles';

function CurrencyPage() {
  const [currencyList, setCurrencyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCurrencies();
      setCurrencyList(data || []);
    } catch (err) {
      console.warn('initData - Currency page', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteCurrencyItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteCurrency(row.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteCurrencyItem', err);
    }
    setLoading(false);
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <SearchInput sx={{ mr: 2 }} maxWidth={400} />
      </ContainerHeader>
      <ButtonAdd
        variant="contained"
        size="small"
        startIcon={<BsPlus />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={currencyList}
        headCells={Columns(deleteCurrencyItem)}
        onClickDetail={(row) => setSelected(row)}
        title="Đơn vị tiền tệ"
        loading={loading}
        renderHeader={renderHeader}
      />
      <FormDetail {...{ selected, setSelected, initData }} />
    </Box>
  );
}
export { CurrencyPage };
