import * as React from 'react';
import {
  Box,
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Table, SearchInput, SearchInputColumn } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import ApiServices from 'services';
import { removeVietnameseTones } from 'commons/removeVietnamese';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export const FunctionList = ({ memberGroupDetail }) => {
  const searchInputRef = React.useRef(null);
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.openSideBar);

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [valueSeatchLeft, setValueSeatchLeft] = React.useState(null);
  const [valueSearchRight, setValueSearchRight] = React.useState(null);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const getDataInit = async () => {
    try {
      const { data } = await ApiServices.getSysFunctionPermission(memberGroupDetail.id);
      if (data) {
        setLeft(data.notInGroup);
        setRight(data.inGroup);
      }
    } catch (err) {
      console.warn('initData - Client Groups', err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (memberGroupDetail) {
      getDataInit();
    } else {
      setChecked([]);
      setLeft([]);
      setRight([]);
    }
  }, [memberGroupDetail]);

  const onSearchInputChange = (value, type) => {
    clearTimeout(searchInputRef.current);
    searchInputRef.current = setTimeout(() => {
      if (type === 'notInGroup') {
        setValueSeatchLeft(value);
      }
      if (type === 'inGroup') {
        setValueSearchRight(value);
      }
    }, 500);
  };

  const setPermissionMenu = async (dataSend) => {
    try {
      const { status } = await ApiServices.setPermission(memberGroupDetail.id, dataSend);
      if (status === 200) {
        getDataInit();
      }
    } catch (err) {
      console.warn('initData - Client Groups', err);
    }
    setLoading(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setPermissionMenu({
      groupId: memberGroupDetail.id,
      arrSysFuncs: leftChecked.map((item) => item.id),
      addToGroup: true,
    });
  };

  const handleCheckedLeft = () => {
    setPermissionMenu({
      groupId: memberGroupDetail.id,
      arrSysFuncs: rightChecked.map((item) => item.id),
      addToGroup: false,
    });
  };

  const customList = (title, items, type) => (
    <Card
      sx={{
        width: `calc((100vw - 300px - ${isOpen ? '240px' : '0px'} )/2)`,
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">{`${numberOfChecked(items)}/${items.length} item`}</Typography>
            <Typography
              sx={{
                textAlign: 'right',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
              variant="h6"
            >
              {title}
            </Typography>
          </Stack>
        }
        subheader={
          <SearchInput
            sx={{ mr: 2 }}
            maxWidth={400}
            onChange={({ target }) => onSearchInputChange(target.value, type)}
          />
        }
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          height: 'calc(100vh - 335px)',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': value.id,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={value.id}
                primary={
                  <p
                    style={{ color: !value.arrTagMenu ? 'red' : 'blue' }}
                  >{`${value.controllerName} - ${value.actionName} - ${value.description}`}</p>
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <Box sx={{ width: 'ca' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            {customList(
              'Chưa khai báo',
              valueSeatchLeft
                ? left.filter(
                    (item) =>
                      removeVietnameseTones(item?.controllerName?.toUpperCase()).includes(
                        removeVietnameseTones(valueSeatchLeft.toUpperCase()),
                      ) ||
                      removeVietnameseTones(item?.actionName?.toUpperCase()).includes(
                        removeVietnameseTones(valueSeatchLeft.toUpperCase()),
                      ),
                  )
                : left,
              'notInGroup',
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            {customList(
              'Đã khai báo',
              valueSearchRight
                ? right.filter(
                    (item) =>
                      removeVietnameseTones(item?.controllerName?.toUpperCase()).includes(
                        removeVietnameseTones(valueSearchRight.toUpperCase()),
                      ) ||
                      removeVietnameseTones(item?.actionName?.toUpperCase()).includes(
                        removeVietnameseTones(valueSearchRight.toUpperCase()),
                      ),
                  )
                : right,
              'inGroup',
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
