import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection, DatePicker, FileUpload } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';

import ApiServices from 'services';
import Storage from 'storage';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const createData = async function (values, { setSubmitting }) {
    values.companyId = Storage?.user?.CompanyId;
    setSubmitting(true);
    try {
      const res = await ApiServices.createContract(values);
      if (res.status === 200) {
        reload();
        setSelected(null);
        window.UIMessage?.success('Thao tác thành công');
      } else {
        window.UIMessage?.error('Thao tác thất bại');
      }
    } catch (err) {
      console.warn('create Contract - companies', err);
    }
    setSubmitting(false);
  };
  const updateData = async function (values, { setSubmitting }) {
    delete values.updatedOn;
    setSubmitting(true);
    if (values?.file?.isAvailable) {
      delete values.file;
    }
    try {
      const { data } = await ApiServices.updateContract(values.id, values);
      reload();
      setSelected(null);
      window.UIMessage?.success('Thao tác thành công');
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('create Contract - companies', err);
    }
    setSubmitting(false);
  };
  const arrInput = [
    { field: 'code', lable: 'Mã Hợp đồng' },
    { field: 'contractNumber', lable: 'Số Hợp đồng' },
    { field: 'signedDate', lable: 'Ngày ký HĐ', type: 'date-picker' },
    { field: 'expireDate', lable: 'Ngày hết hạn HĐ', type: 'date-picker' },
    { field: 'extensionDate', lable: 'Ngày Gia hạn HĐ', type: 'date-picker' },
    {
      field: 'clientId',
      lable: 'Khách hàng',
      type: 'selection',
      syncData: ApiServices.getClients,
    },
  ];
  return (
    <HandleFormDetail
      {...{ selected, setSelected, width: FORM_WIDTH }}
      showTitleTab={selected?.isNew ? 'Tạo mới' : `Hợp đồng: ${selected?.contractNumber}`}
      moreTabs={[
        {
          title: 'Test Tab More',
          content: () => <> TAB 2</>,
        },
      ]}
    >
      <Formik
        initialValues={{
          code: '',
          contractNumber: '',
          signedDate: null,
          expireDate: null,
          extensionDate: null,
          clientId: '',
          ...selected,
          file: selected?.objFileinfo ? { ...selected?.objFileinfo, isAvailable: true } : null,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required('Vui lòng nhập Mã Hợp đồng'),
          contractNumber: Yup.string().max(255).required('vui lòng nhập số Hợp đồng'),
          signedDate: Yup.string().max(255).required('vui lòng nhập ngày ký Hợp đồng'),
          clientId: Yup.string().max(255).required('vui lòng nhập Khách hàng'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {arrInput.map((item) => {
              if (item.type == 'selection') {
                return (
                  <Selection
                    key={`selection-Contract-${item.field}`}
                    error={Boolean(touched[item.field] && errors[item.field])}
                    helperText={touched[item.field] && errors[item.field]}
                    label={item.lable}
                    name={item.field}
                    data={item?.data}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values[item.field]}
                    syncData={item?.syncData}
                  />
                );
              }
              if (item.type == 'date-picker') {
                return (
                  <DatePicker
                    key={`selection-Contract-${item.field}`}
                    onChange={handleChange}
                    value={values[item.field]}
                    error={Boolean(touched[item.field] && errors[item.field])}
                    helperText={touched[item.field] && errors[item.field]}
                    label={item.lable}
                    name={item.field}
                  />
                );
              }
              return (
                <TextInput
                  key={`input-Contract-${item.field}`}
                  error={Boolean(touched[item.field] && errors[item.field])}
                  helperText={touched[item.field] && errors[item.field]}
                  label={item.lable}
                  name={item.field}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values[item.field]}
                  // multiline
                />
              );
            })}
            <TextInput
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              label="Ghi chú"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
              multiline
            />
            <FileUpload
              error={Boolean(touched.file && errors.file)}
              helperText={touched.file && errors.file}
              label="File đính kèm"
              name="file"
              onChange={handleChange}
              value={values.file}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
