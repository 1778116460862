import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Selection, TextInput, FileUpload, ComboBox, FileUploadContract } from 'components';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { styled } from '@mui/material/styles';

const CreateForm = ({ initData, onCloseModal }) => {
  // init
  const [customerList, setCustomerList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  //
  const [contractNumber, setContractNumber] = useState('');
  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [clientCompanyName, setClientCompanyName] = useState('');
  //
  const [effectiveDate, setEffectiveDate] = useState('');
  const [signedDate, setSignedDate] = useState('');
  const [expireDate, setExpireDate] = useState('');

  const [note, setNote] = useState('');
  //
  const [file, setFile] = useState([]);
  const [serviceIds, setServiceIds] = useState([]);

  const isSubmitting = useMemo(() => {
    return (
      !contractNumber ||
      !clientCompanyId ||
      !effectiveDate ||
      !serviceIds ||
      !serviceIds?.length > 0
    );
  }, [contractNumber, clientCompanyId, effectiveDate, serviceIds]);

  const getClientCustomer = async () => {
    try {
      const res = await ApiServices.getClientCompanies();
      if (res?.data) {
        setCustomerList(res?.data || []);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  const getClientService = async () => {
    try {
      const res = await ApiServices.getServices();
      if (res?.data) {
        setServiceList(res?.data || []);
      }
    } catch (error) {
      console.log('getServices Failed:::', error);
    }
  };

  useEffect(() => {
    getClientCustomer();
    getClientService();
  }, []);

  const handleClose = () => {
    onCloseModal();
  };

  const handleSubmit = async () => {
    const fileTmp = file?.map(([item]) => item);
    const arrServiceIds = serviceIds?.map((item) => item?.id);
    const dataSend = {
      contractNumber,
      note,
      arrServiceId: arrServiceIds,
      file: fileTmp,
      clientCompanyId: clientCompanyId?.id,
      signedDate,
      effectiveDate,
      expireDate,
    };
    try {
      const res = await ApiServices.createContract(dataSend);
      if (res?.status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        handleClose();
        initData();
      }
    } catch (error) {
      window.UIMessage?.error('Thêm mới thất bại !!!');
      console.log('Create Contract Failed:::', error);
    }
  };

  const handleChangeContractNumber = useCallback(
    (e) => {
      setContractNumber(e.target.value);
    },
    [contractNumber],
  );

  const handleChangeCustomer = useCallback(
    (_, newValue) => {
      setClientCompanyId(newValue || null);
      if (newValue) {
        setClientCompanyName(newValue?.name || newValue?.code);
      } else {
        setClientCompanyName('');
      }
    },
    [clientCompanyId],
  );
  const handleChangeService = useCallback(
    (_, newValue) => {
      setServiceIds(newValue || []);
    },
    [clientCompanyId],
  );

  const handleChangeEffectiveDate = useCallback(
    (e) => {
      setEffectiveDate(e.target.value);
    },
    [effectiveDate],
  );
  const handleChangeSignedDate = useCallback(
    (e) => {
      setSignedDate(e.target.value);
    },
    [signedDate],
  );
  const handleChangeExpireDate = useCallback(
    (e) => {
      setExpireDate(e.target.value);
    },
    [expireDate],
  );
  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );
  const handleChangeFile = useCallback(
    (e) => {
      setFile(e.target.value);
    },
    [file],
  );

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <TextInput
            required
            error={!contractNumber}
            helperText={!contractNumber && 'Vui lòng nhập số hợp đồng'}
            label="Số hợp đồng"
            name="contractNumber"
            onChange={handleChangeContractNumber}
            value={contractNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextInput
            label="Ngày ký"
            name="signedDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeSignedDate}
            value={signedDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ComboBox
            error={!clientCompanyId}
            helperText={!clientCompanyId && 'Vui lòng chọn mã khách hàng'}
            options={customerList}
            label="Mã khách hàng"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="code"
            value={clientCompanyId}
            onChange={handleChangeCustomer}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextInput
            label="Khách hàng"
            name="contractNumber"
            value={clientCompanyName}
            readOnly={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextInput
            required
            error={!effectiveDate}
            helperText={!effectiveDate && 'Vui lòng nhập ngày bắt đầu'}
            label="Ngày bắt đầu"
            name="effectiveDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeEffectiveDate}
            value={effectiveDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextInput
            label="Ngày kết thúc"
            name="expireDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeExpireDate}
            value={expireDate}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <ComboBox
            error={!serviceIds?.length > 0}
            helperText={!serviceIds?.length > 0 && 'Vui lòng chọn mã khách hàng'}
            multiple
            disableCloseOnSelect
            options={serviceList}
            label="Dịch vụ"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="name"
            value={serviceIds}
            onChange={handleChangeService}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextInput
            label="Ghi chú"
            name="summaryNote"
            onChange={handleChangeNote}
            value={note}
            multiline={true}
            rows={2}
          />
        </Grid>
      </Grid>
      <FileUploadContract
        // error={Boolean(touched.file && errors.file)}
        // helperText={touched.file && errors.file}
        label="File đính kèm"
        name="file"
        // onBlur={handleBlur}
        onChange={handleChangeFile}
        value={file}
        multiple={true}
      />
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnCreateUpdate
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            variant="contained"
          >
            Thêm mới
          </BtnCreateUpdate>
        </Box>
      </Box>
    </Box>
  );
};

export { CreateForm };

export const BtnCreateUpdate = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '5px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '6px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
