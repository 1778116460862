import React, { useEffect, useState } from 'react';
import { ComboBox } from 'components';
import { Box, Tab, Typography, Paper, Toolbar } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ApiServices from 'services';
import { UserList } from './user-list';
import { MenuList } from './menu-list';
import { FunctionList } from './function-list';

function PermissionExecute() {
  const [value, setValue] = React.useState('1');
  const [memberGroup, setMemberGroup] = React.useState(null);
  const [memberGroupList, setMemberGroupList] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getPermissionMemberGroups();
      if (data) {
        setMemberGroupList(data || []);
      }
    } catch (err) {
      console.warn('initData - Client Groups', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const onHandleChangeFn = async (event, newValue) => {
    setMemberGroup(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', position: 'relative' }}>
          <Toolbar>
            <Typography
              variant="h5"
              component="h5"
              sx={{
                paddingTop: '0.5rem',
              }}
            >
              Phân Quyền Chức Năng
            </Typography>
          </Toolbar>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <ComboBox
                    options={memberGroupList}
                    label="Nhóm"
                    sx={{ width: 300, marginLeft: '20px' }}
                    value={memberGroup}
                    onChange={(event, newValue) => onHandleChangeFn(event, newValue)}
                  />
                </Box>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab
                    sx={{ marginLeft: 4, textTransform: 'none' }}
                    label="DS Người Dùng"
                    value="1"
                  />
                  <Tab sx={{ marginLeft: 4, textTransform: 'none' }} label="DS Menu" value="2" />
                  <Tab
                    sx={{ marginLeft: 4, textTransform: 'none' }}
                    label="DS Chức Năng"
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <UserList memberGroupDetail={memberGroup} />
              </TabPanel>
              <TabPanel value="2">
                <MenuList memberGroupDetail={memberGroup} />
              </TabPanel>
              <TabPanel value="3">
                <FunctionList memberGroupDetail={memberGroup} />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
export { PermissionExecute };
