import React, { useState, useEffect, useMemo } from 'react';
import {
  BsEye,
  BsSave,
  BsBoxArrowInUpRight,
  BsXCircle,
  BsCheck2Circle,
  BsEnvelope,
} from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { updateServiceToQuote } from 'store/reducers/customerQuotes/action';
import { SUB_SERVICES_ID, QUOTE_STATUS } from 'commons/constants';
import { Box, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { QuoteCustomsCargoTypeForm } from '../../forms-management/quote-customs-cargo-type-form';
import { QuoteCustomsSubChargeForm } from '../../forms-management/quote-customs-sub-charge-form';
import { QuoteCustomsWeightForm } from '../../forms-management/quote-customs-weight-form';
import Utils from 'commons/utils';
import ApiServices from 'services';

import { HeaderFreight } from './header';
import {
  ContainerPrice,
  BtnViewReportQuote,
  BtnCreateQuote,
  BtnRequestQuote,
  BtnRejectQuote,
  BtnApproveQuote,
  BtnSendQuote,
} from './styles';

const CustomsControlQuoteForm = ({ handleCloseAfterSubmit, initData, selected }) => {
  const dispatch = useDispatch();
  const { CUSTOMS_FCL, CUSTOMS_LCL, CUSTOMS_AIR } = SUB_SERVICES_ID;
  const { itemCustomsSelected, objQuotes } = useSelector((state) => state.customerQuotes);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cargoTypeList, setCargoTypeList] = useState([]);

  const isCustomsFcl = useMemo(() => {
    return itemCustomsSelected?.subServiceId === CUSTOMS_FCL;
  }, [itemCustomsSelected?.subServiceId]);
  const isCustomsLcl = useMemo(() => {
    return itemCustomsSelected?.subServiceId === CUSTOMS_LCL;
  }, [itemCustomsSelected?.subServiceId]);
  const isCustomsAir = useMemo(() => {
    return itemCustomsSelected?.subServiceId === CUSTOMS_AIR;
  }, [itemCustomsSelected?.subServiceId]);

  const isDisabledForm = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [selected]);

  const getCargoType = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCargoTypes();
      if (data) {
        setCargoTypeList(data || []);
      }
    } catch (err) {
      console.warn('getCargoType', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargoType();
  }, []);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = itemCustomsSelected?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = itemCustomsSelected?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (itemCustomsSelected?.subServiceId) {
      getSurchargeByFeeGroupSS(itemCustomsSelected?.subServiceId);
    }
  }, []);

  const createQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: {
        ...objQuotes,
        quotesStatus,
        quotedate: quoteDate,
        arrServiceId: [itemCustomsSelected?.serviceId],
        arrSubServiceId: [itemCustomsSelected?.subServiceId],
      },
      objCustoms: { ...itemCustomsSelected },
    };
    try {
      const { status } = await ApiServices.createCustomsQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const updateQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: { ...objQuotes, quotedate: quoteDate, quotesStatus },
      objCustoms: { ...itemCustomsSelected },
    };
    try {
      const { status } = await ApiServices.updateCustomsQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const handleSubmit = (quotesStatus, quoteDate = null) => {
    if (selected?.isNew) {
      return createQuote(quotesStatus, quoteDate);
    } else {
      return updateQuote(quotesStatus, quoteDate);
    }
  };

  const handleChangeCargoType = (e) => {
    dispatch(updateServiceToQuote({ arrCont: e.target.value }));
  };
  const handleChangeSpecificationsWeight = (e) => {
    dispatch(updateServiceToQuote({ arrSpecificationsWeight: e.target.value }));
  };

  const handleChangeSubCharge = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    dispatch(updateServiceToQuote({ arrSurcharge: data }));
  };

  const renderSubChargeCustoms = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteCustomsSubChargeForm
          arrSurcharge={item.surchargeListFree}
          name={item.feeGroupID}
          onChange={(event) => handleChangeSubCharge(event, item)}
          value={item?.surcharge}
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          showQuantity={false}
          disabled={isDisabledForm}
        />
      )}
    </FormGroup>
  );

  return (
    <>
      <HeaderFreight />
      <b>Giá cước:</b>

      <ContainerPrice>
        {isCustomsFcl && (
          <QuoteCustomsCargoTypeForm
            arrContType={itemCustomsSelected?.arrTypeCargoIdCheck}
            cargoTypeList={cargoTypeList}
            name="arrCont"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={handleChangeCargoType}
            value={itemCustomsSelected?.arrCont}
            disabled={isDisabledForm}
          />
        )}
        {(isCustomsLcl || isCustomsAir) && (
          <QuoteCustomsWeightForm
            name="arrSpecificationsWeight"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={handleChangeSpecificationsWeight}
            value={itemCustomsSelected?.arrSpecificationsWeight}
            disabled={isDisabledForm}
          />
        )}
        {groupFeeGroup?.map(renderSubChargeCustoms)}
      </ContainerPrice>
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          {!selected?.isNew && (
            <BtnViewReportQuote
              variant="outlined"
              startIcon={<BsEye />}
              // onClick={() => handleReportQuoteTemplate(selected?.arrSubServiceId[0], selected?.id)}
            >
              Xem báo giá
            </BtnViewReportQuote>
          )}
          {(selected?.isNew || selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) && (
            <>
              <BtnCreateQuote
                sx={{ marginRight: '15px' }}
                color="primary"
                variant="contained"
                startIcon={<BsSave size={16} />}
                onClick={() => handleSubmit(QUOTE_STATUS.ORIGIN_QUOTE)}
                disabled={!objQuotes?.clientCompanyId}
              >
                {selected?.isNew ? 'Tạo báo giá' : 'Lưu'}
              </BtnCreateQuote>
              <BtnRequestQuote
                color="success"
                variant="contained"
                startIcon={<BsBoxArrowInUpRight size={20} />}
                onClick={() =>
                  handleSubmit(QUOTE_STATUS.REQUEST_APPROVE, Utils.convertToDateFormat(new Date()))
                }
                disabled={!objQuotes?.clientCompanyId || !objQuotes?.clientId}
              >
                Trình duyệt
              </BtnRequestQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.REQUEST_APPROVE && (
            <>
              <BtnRejectQuote
                sx={{ marginRight: '15px' }}
                color="error"
                variant="contained"
                startIcon={<BsXCircle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.REJECT_APPROVE)}
              >
                Không duyệt
              </BtnRejectQuote>
              <BtnApproveQuote
                color="success"
                variant="contained"
                startIcon={<BsCheck2Circle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
              >
                Duyệt
              </BtnApproveQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.APPROVED && (
            <BtnSendQuote
              color="success"
              variant="contained"
              startIcon={<BsEnvelope size={20} />}
              // onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
            >
              Gửi báo giá
            </BtnSendQuote>
          )}
        </Box>
      </Box>
    </>
  );
};

export { CustomsControlQuoteForm };
