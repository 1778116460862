import React, { useState, useEffect } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateTruckingService, updateCustomsService } from 'store/reducers/customerQuotes/action';
import ApiServices from 'services';
import { QuoteCustomsSubChargeForm } from '../../forms-management/quote-customs-sub-charge-form';
import { QuoteTruckingSubChargeForm } from '../../forms-management/quote-trucking-sub-charge-form';
import { SERVICES_ID } from 'commons/constants';

const SubCharge = ({ dataSubCharge }) => {
  const dispatch = useDispatch();
  const { CUSTOMS, TRUCKING } = SERVICES_ID;
  const [loading, setLoading] = useState(false);
  const [subChargeList, setSubChargeList] = useState([]);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = dataSubCharge?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = dataSubCharge?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setSubChargeList(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataSubCharge?.subServiceId) {
      getSurchargeByFeeGroupSS(dataSubCharge?.subServiceId);
    }
  }, []);

  const handleChangeCustomsSubCharge = (event, item) => {
    const data = subChargeList?.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    dispatch(updateCustomsService({ arrSurcharge: data }));
  };
  const handleChangeTruckingSubCharge = (event, item) => {
    const data = subChargeList.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    dispatch(updateTruckingService({ arrSurcharge: data }));
  };

  const renderSubChargeCustoms = (item, index) => (
    <FormGroup key={item?.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item?.checked} />}
        label={item?.feeGroupName}
      />
      {item?.checked && (
        <QuoteCustomsSubChargeForm
          arrSurcharge={item?.surchargeListFree}
          name={item?.feeGroupID}
          onChange={(event) => handleChangeCustomsSubCharge(event, item)}
          value={item?.surcharge}
          showQuantity={false}
          // disabled={isDisabledForm}
        />
      )}
    </FormGroup>
  );

  const renderSubChargeTrucking = (item, index) => (
    <FormGroup key={item?.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item?.checked} />}
        label={item?.feeGroupName}
      />
      {item?.checked && (
        <QuoteTruckingSubChargeForm
          arrSurcharge={item?.surchargeListFree}
          name={item?.feeGroupID}
          onChange={(event) => handleChangeTruckingSubCharge(event, item)}
          value={item?.surcharge}
          // disabled={isDisabledForm}
        />
      )}
    </FormGroup>
  );

  if (dataSubCharge?.serviceId === CUSTOMS) {
    return subChargeList?.map(renderSubChargeCustoms);
  } else if (dataSubCharge?.serviceId === TRUCKING) {
    return subChargeList?.map(renderSubChargeTrucking);
  }
};

export { SubCharge };
