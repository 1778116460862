import { styled } from '@mui/material/styles';
import { Button, Box } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  flex: 1,
}));

export const BtnAddNew = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '8px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));
export const BtnImport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));
