import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { TextInput, FileUploadContract, ComboBox } from 'components';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { styled } from '@mui/material/styles';

const GeneralInfo = ({ selectedContract, getContractDetail, initData, isReadOnly = false }) => {
  // init
  const [customerList, setCustomerList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  //
  const [contractNumber, setContractNumber] = useState('');
  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [clientCompanyName, setClientCompanyName] = useState('');
  //
  const [effectiveDate, setEffectiveDate] = useState('');
  const [signedDate, setSignedDate] = useState('');
  const [expireDate, setExpireDate] = useState('');

  const [note, setNote] = useState('');
  //
  const [file, setFile] = useState([]);
  const [currentFile, setCurrentFile] = useState([]);

  const [serviceIds, setServiceIds] = useState([]);

  const isSubmitting = useMemo(() => {
    return (
      !contractNumber ||
      !clientCompanyId ||
      !effectiveDate ||
      !serviceIds ||
      !serviceIds?.length > 0
    );
  }, [contractNumber, clientCompanyId, effectiveDate, serviceIds]);

  const getClientCustomer = async () => {
    try {
      const res = await ApiServices.getClientCompanies();
      if (res?.data) {
        setCustomerList(res?.data || []);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  const getClientService = async () => {
    try {
      const res = await ApiServices.getServices();
      if (res?.data) {
        setServiceList(res?.data || []);
      }
    } catch (error) {
      console.log('getServices Failed:::', error);
    }
  };
  useEffect(() => {
    getClientCustomer();
    getClientService();
  }, []);

  useEffect(() => {
    // customer
    if (selectedContract?.clientCompanyId && customerList?.length > 0) {
      const findCustomer = customerList?.find(
        (item) => item.id === selectedContract.clientCompanyId,
      );
      if (findCustomer) {
        setClientCompanyId(findCustomer);
        setClientCompanyName(findCustomer?.name || '');
      }
    }
    // Service
    if (selectedContract?.arrServiceId && serviceList?.length > 0) {
      const findService = serviceList?.filter((el) =>
        selectedContract?.arrServiceId.includes(el.id),
      );
      if (findService?.length > 0) {
        setServiceIds(findService || []);
      }
    }
  }, [customerList, selectedContract, serviceList]);

  useEffect(() => {
    const currentSignedDate = selectedContract?.signedDate;
    const currentEffectiveDate = selectedContract?.effectiveDate;
    const currentExpireDate = selectedContract?.expireDate;
    const currentNote = selectedContract?.note;
    const currentArrUrlfile = selectedContract?.arrUrlfile;

    if (selectedContract?.contractNumber) {
      setContractNumber(selectedContract?.contractNumber);
    }
    if (currentSignedDate && Utils.checkIsDate(currentSignedDate)) {
      setSignedDate(Utils.convertToDateFormat(currentSignedDate));
    }
    if (currentEffectiveDate && Utils.checkIsDate(currentEffectiveDate)) {
      setEffectiveDate(Utils.convertToDateFormat(currentEffectiveDate));
    }
    if (currentExpireDate && Utils.checkIsDate(currentExpireDate)) {
      setExpireDate(Utils.convertToDateFormat(currentExpireDate));
    }
    if (currentNote) {
      setNote(currentNote);
    }
    if (currentArrUrlfile?.length > 0) {
      setCurrentFile(currentArrUrlfile || []);
    }
    setFile([]);
  }, [selectedContract]);

  const handleChangeContractNumber = useCallback(
    (e) => {
      setContractNumber(e.target.value);
    },
    [contractNumber],
  );

  const handleUpdate = async () => {
    const contractId = selectedContract?.id;

    const fileTmp = file?.map(([item]) => item);
    const arrServiceIds = serviceIds?.map((item) => item?.id);
    const dataSend = {
      contractNumber,
      note,
      arrServiceId: arrServiceIds,
      file: fileTmp,
      clientCompanyId: clientCompanyId?.id,
      signedDate,
      effectiveDate,
      expireDate,
      arrUrlfile: currentFile || [],
    };
    if (!contractId || !dataSend) return;
    console.log({ dataSend });
    try {
      const res = await ApiServices.updateContract(contractId, dataSend);
      if (res?.status === 200) {
        window.UIMessage?.success('Cập nhật thành công');
        setFile([]);
        getContractDetail(contractId);
        initData();
      }
    } catch (error) {
      window.UIMessage?.error('Cập nhật thất bại !!!');
      console.log('Create Contract Failed:::', error);
    }
  };

  const handleChangeCustomer = useCallback(
    (_, newValue) => {
      setClientCompanyId(newValue || null);
      if (newValue) {
        setClientCompanyName(newValue?.name || newValue?.code);
      } else {
        setClientCompanyName('');
      }
    },
    [clientCompanyId],
  );
  const handleChangeService = useCallback(
    (_, newValue) => {
      setServiceIds(newValue || []);
    },
    [clientCompanyId],
  );

  const handleChangeEffectiveDate = useCallback(
    (e) => {
      setEffectiveDate(e.target.value);
    },
    [effectiveDate],
  );
  const handleChangeSignedDate = useCallback(
    (e) => {
      setSignedDate(e.target.value);
    },
    [signedDate],
  );
  const handleChangeExpireDate = useCallback(
    (e) => {
      setExpireDate(e.target.value);
    },
    [expireDate],
  );
  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );
  const handleChangeFile = useCallback(
    (e) => {
      setFile(e.target.value);
    },
    [file],
  );
  const handleChangeCurrentFile = useCallback(
    (fileCurrent) => {
      setCurrentFile(fileCurrent || []);
    },
    [currentFile],
  );

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput
            required
            error={!contractNumber}
            helperText={!contractNumber && 'Vui lòng nhập số hợp đồng'}
            label="Số hợp đồng"
            name="contractNumber"
            onChange={handleChangeContractNumber}
            value={contractNumber}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ComboBox
            error={!clientCompanyId}
            helperText={!clientCompanyId && 'Vui lòng chọn mã khách hàng'}
            options={customerList}
            label="Mã khách hàng"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="code"
            value={clientCompanyId}
            onChange={handleChangeCustomer}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6}>
          <TextInput
            label="Khách hàng"
            name="contractNumber"
            value={clientCompanyName}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput
            label="Ngày ký"
            name="signedDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeSignedDate}
            value={signedDate}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TextInput
            required
            error={!effectiveDate}
            helperText={!effectiveDate && 'Vui lòng nhập ngày bắt đầu'}
            label="Ngày bắt đầu"
            name="effectiveDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeEffectiveDate}
            value={effectiveDate}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput
            label="Ngày kết thúc"
            name="expireDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeExpireDate}
            value={expireDate}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput
            label="Ghi chú"
            name="summaryNote"
            onChange={handleChangeNote}
            value={note}
            multiline={true}
            rows={2}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6}>
          <ComboBox
            error={!serviceIds?.length > 0}
            helperText={!serviceIds?.length > 0 && 'Vui lòng chọn mã khách hàng'}
            multiple
            disableCloseOnSelect
            options={serviceList}
            label="Dịch vụ"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="name"
            value={serviceIds}
            onChange={handleChangeService}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6}>
          <FileUploadContract
            label="File đính kèm"
            name="file"
            marinTop={1}
            // onBlur={handleBlur}
            onChange={handleChangeFile}
            value={file}
            multiple={true}
            isUploadOnlyContract={true}
            valueCurrent={currentFile}
            onChangeCurrent={handleChangeCurrentFile}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          marginTop: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ButtonAdd
          disabled={isSubmitting || isReadOnly}
          variant="contained"
          size="small"
          onClick={handleUpdate}
        >
          Cập nhật
        </ButtonAdd>
      </Box>
    </Box>
  );
};

export { GeneralInfo };

export const ButtonAdd = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '5px',
  //   marginLeft: 'auto',
  color: '#FFF',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '5px 40px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));
