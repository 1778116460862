import moment from 'moment';

export const appointments = (data) => {
  return data.map((item) => {
    const time = moment(item?.time);

    return {
      title: item.scheduler,
      startDate: new Date(time.year(), time.month(), time.date(), time.hour()),
      endDate: new Date(time.year(), time.month(), time.date(), time.hour() + 1),
      id: item?.id,
      location: item?.location,
    };
  });
};
