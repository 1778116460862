import React, { Component } from 'react';
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box, Typography } from '@mui/material';
import { stateFromHTML } from 'draft-js-import-html';

export { HtmlEditor };

const HtmlEditor = ({
  label = '',
  onChange,
  value = '',
  name = '',
  fullWidth = true,
  error = false,
  helperText = '',
  multiple = false,
  placeholder = '',
  ...props
}) => {
  // const [contentState, setContentState] = React.useState(null);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [firstState, setFirstState] = React.useState(false);
  React.useEffect(() => {
    if (firstState || !value) {
      return;
    }
    let contentState = stateFromHTML(value);
    setFirstState(true);
    setEditorState(EditorState.createWithContent(contentState));
  }, [value]);

  const toContentState = (html = '<p></p>') => {
    const contentBlocks = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(contentBlocks);

    return convertToRaw(state);
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  return (
    <Box
      sx={{
        borderColor: 'text.primary',
        borderWidth: '0',
        borderBottomWidth: '1.5px',
        borderStyle: 'solid',
        mb: 2,
        mt: 2,
      }}
    >
      {label && (
        <Typography sx={{ fontSize: '1rem' }} variant="p" component="div">
          {label}
        </Typography>
      )}
      <Editor
        // contentState={contentState}
        // editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        onChange={async (editorContent) => {
          // await setContentState(editorContent);
          onChange &&
            onChange({
              persist: () => {},
              target: {
                type: 'change',
                name,
                value: draftToHtml(editorContent),
              },
            });
        }}
        spellCheck
        placeholder={placeholder || 'data...'}
      />
    </Box>
  );
};
