import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography, Box, Grid } from '@mui/material';
import Utils from 'commons/utils';

export const HeaderFreight = () => {
  const { itemServiceSelectedToQuote } = useSelector((state) => state.customerQuotes);

  const ARRAY_HEADER = [
    {
      title: 'Cảng xếp dỡ:',
      value: `${itemServiceSelectedToQuote?.objPOL?.name} - ${itemServiceSelectedToQuote?.objPOL?.countryCode}`,
      xs: 3,
    },
    {
      title: 'Cảng đến:',
      value: `${itemServiceSelectedToQuote?.objPOD?.name} - ${itemServiceSelectedToQuote?.objPOD?.countryCode}`,
      xs: 3,
    },
    {
      title: 'Hãng tàu:',
      value: itemServiceSelectedToQuote?.carrierName,
      xs: 3,
    },
    {
      title: 'ETD:',
      value: Utils.formatDateFn(itemServiceSelectedToQuote?.etd),
      xs: 2,
    },
    {
      title: 'ĐV tiền tệ:',
      value: itemServiceSelectedToQuote?.currencyCode,
      xs: 1,
    },
  ];

  return (
    <>
      <ContainerItemService>
        <b>
          {itemServiceSelectedToQuote?.serviceName} - {itemServiceSelectedToQuote?.subServiceName}
        </b>
      </ContainerItemService>
      <Box sx={{ padding: '10px 20px 10px 20px', fontSize: '14px' }}>
        <Grid container spacing={3}>
          {ARRAY_HEADER.map((item, index) => (
            <Grid item xs={item?.xs} key={`${item?.title}-${index}`}>
              <Typography component="b" variant="string">
                {item?.title}
              </Typography>
              <Typography component="div" variant="string" sx={{ color: 'blue' }}>
                {item?.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export const ContainerItemService = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
}));
