import React, { useState } from 'react';
import { Menu, MenuItem, Divider, IconButton, Box, Link, Card, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatServices from 'services/chat-services';
import InfoIcon from '@mui/icons-material/Info';
import { Popup } from '../../../components';
import { MemberList } from './member-list';
import { AddMember } from './add-member';
import { useSelector, useDispatch } from 'react-redux';
import storage from 'storage';
import { setSelectedChanel, fetchChanel } from 'store/reducers/chat-chanel/action';
import { LinkInviteGroup } from './link-invite-group';
import { BiCopyAlt } from 'react-icons/bi';

export default function PositionedMenu({ onDelete }) {
  const dispatch = useDispatch();
  const [memberList, setMemberList] = React.useState(false);
  const [addMember, setAddMember] = React.useState(false);
  const [tokenInviteGroup, setTokenInviteGroup] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [showLinkInviteGroup, setShowLinkInviteGroup] = useState(true);
  const { selectedChanel, chanels } = useSelector((state) => state.chatChanel);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChanelDelete = async (chanelId) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await ChatServices.deleteChanel(chanelId);
      if (data.code === 'success') {
        window.UIMessage.success('Xóa nhóm thành công');
        handleClose();
        dispatch(fetchChanel());
      }
    } catch (error) {
      window.UIMessage.error('Xóa nhóm thất bại');
    }
    setLoading(false);
  };

  const onHandleOutGroup = async (chanelId) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await ChatServices.outGroup(chanelId);
      if (data.code === 'success') {
        window.UIMessage.success('Rời nhóm thành công');
        const chanelList = await chanels.filter((item) => item._id !== data?.results[0]?._id);
        await dispatch(setSelectedChanel(chanelList[0]));
        await dispatch(fetchChanel());
        await handleClose();
      }
    } catch (error) {
      window.UIMessage.error('Rời nhóm thất bại');
    }
    setLoading(false);
  };

  const onMemberListFn = () => {
    setMemberList(true);
  };

  const onHandleGenerateLinkToInvite = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await ChatServices.generateInviteLink(selectedChanel._id, {
        name: selectedChanel.name,
        _id: selectedChanel._id,
      });
      if (data.code === 'success') {
        setTokenInviteGroup(data.results[0]);
        handleClose();
      }
    } catch (error) {
      window.UIMessage.error('Tạo link thất bại');
    }
    setLoading(false);
  };

  return (
    <>
      {!selectedChanel?.isPersonal && (
        <Box>
          <IconButton
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <InfoIcon />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={onMemberListFn}>Danh sách thành viên</MenuItem>
            <MenuItem onClick={() => setAddMember(true)}>Thêm thành viên</MenuItem>
            <MenuItem onClick={() => onHandleGenerateLinkToInvite()}>
              Link mời tham gia nhóm
            </MenuItem>
            {/* {showLinkInviteGroup && (
            <MenuItem>
              <Box
                sx={{
                  borderRadius: '10px',
                  width: '250px',
                  display: 'flex',
                  boxShadow:
                    'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
                }}
              >
                <Link
                  underline="hover"
                  sx={{
                    paddingLeft: '5px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '13px',
                    width: '200px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '10px',
                  }}
                >
                  Nguyen miinh duongadaidhasjkhdjksahdjsahdjsahdjsahdhsadhdhsajkd
                </Link>
                <Stack spacing={0.1}>
                  <IconButton
                    // onClick={(e) => onHandleCopyLink(e)}
                    aria-label="delete"
                    // color={copyLink ? 'success' : 'default'}
                  >
                    <BiCopyAlt sx={{ fontSize: '10px' }} />
                  </IconButton>
                  <p style={{ fontSize: '13px', color: 'black' }}>Copy</p>
                </Stack>
              </Box>
            </MenuItem>
          )} */}
            <Divider />
            {selectedChanel?.owner === storage.user?.Code && (
              <MenuItem
                onClick={() => onChanelDelete(selectedChanel._id)}
                sx={{ color: 'error.main' }}
              >
                Xóa Nhóm
              </MenuItem>
            )}
            {selectedChanel?.owner !== storage.user?.Code && (
              <MenuItem
                onClick={() => onHandleOutGroup(selectedChanel._id)}
                sx={{ color: 'error.main' }}
              >
                Rời nhóm
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
      <Popup
        title="Danh sách thành viên"
        open={memberList}
        closePopup={() => setMemberList(false)}
        body={<MemberList selectedChanel={selectedChanel} />}
      />
      <Popup
        title="Thêm thành viên"
        open={addMember}
        closePopup={() => setAddMember(false)}
        body={<AddMember selectedChanel={selectedChanel} setAddMember={setAddMember} />}
      />
      <Popup
        title={`Link tham gia nhóm ${selectedChanel?.name}`}
        open={!!tokenInviteGroup}
        // maxWidth="md"
        closePopup={() => setTokenInviteGroup(null)}
        body={<LinkInviteGroup tokenInviteGroup={tokenInviteGroup} />}
      />
    </>
  );
}
