import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { BondedWarehouse } from './bonded-warehouse';
import { SUB_SERVICES_ID } from 'commons/constants';

const SUB_SERVICES_OF_WAREHOUSE = [
  {
    label: 'Kho ngoại quan',
    value: SUB_SERVICES_ID.BONDED_WAREHOUSE,
  },
];

export const WarehouseService = () => {
  const { BONDED_WAREHOUSE } = SUB_SERVICES_ID;
  const [tabIndex, setTabIndex] = React.useState(BONDED_WAREHOUSE);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={tabIndex}>
        <TabList onChange={handleChangeTabIndex} aria-label="Warehouse quote">
          {SUB_SERVICES_OF_WAREHOUSE.map((item) => (
            <TabItem key={`${item.value}`} label={item.label} value={item.value} />
          ))}
        </TabList>

        <TabPanel value={BONDED_WAREHOUSE} sx={{ padding: '5px 0px' }}>
          <BondedWarehouse />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
