import actionTypes from './type';
import ChatServices from 'services/chat-services';

export function fetchMessages(chanelId, query = {}) {
  return async (dispatch) => {
    try {
      const { data } = await ChatServices.getMessages(chanelId, query);
      if (data.code === 'success') {
        return dispatch({
          messages: data.results,
          resultKey: data.meta?.resultKey,
          type: actionTypes.FETCH_MESSAGES,
        });
      }
    } catch (error) {
      console.error('fetchChanel-ERR', error);
    }
    return dispatch({ messages: [], type: actionTypes.FETCH_MESSAGES });
  };
}

export function loadMore(chanelId, query = {}) {
  return async (dispatch) => {
    try {
      const { data } = await ChatServices.getMessages(chanelId, query);
      if (data.code === 'success') {
        return dispatch({
          messages: data.results,
          resultKey: data.meta?.resultKey,
          type: actionTypes.LOAD_MORE_MESSAGES,
        });
      }
    } catch (error) {
      console.error('fetchChanel-ERR', error);
    }
    return dispatch({ messages: [], type: actionTypes.FETCH_MESSAGES });
  };
}
export function addMessage(message) {
  return (dispatch) => dispatch({ message, type: actionTypes.ADD_MESSAGE });
}
export function updateMessage(message) {
  return (dispatch) => dispatch({ message, type: actionTypes.UPDATE_MESSAGE });
}
export function deleteMessage(message) {
  return (dispatch) => dispatch({ message, type: actionTypes.DELETE_MESSAGE });
}
