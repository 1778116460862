import React, { useEffect, useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export const CheckBoxCustom = ({ value, onChange, ...props }) => {
  const [price, setPrice] = useState(false);

  const handleCheckedPrice = (event) => {
    // setPrice(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={onChange} />}
        label="Giá cước"
      />
    </FormGroup>
  );
};
