import React from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// logo
import logo from 'assets/img/logo.png';
import { useHistory } from 'react-router-dom';
function ErrorPage() {
  var classes = useStyles();
  const history = useHistory();
  return (
    <Grid container style={classes.container}>
      {/* <div style={classes.logotype}>
        <img style={classes.logotypeIcon} src={logo} alt="logo" />
        <Typography variant="h3" color="white" style={classes.logotypeText}>
          Material Admin
        </Typography>
      </div> */}
      <Paper classes={{ root: classes.paperRoot }} sx={{ p: 3 }}>
        <Typography
          variant="h1"
          color="primary"
          // style={classnames(classes.textRow, classes.errorCode)}
        >
          404
        </Typography>
        <Typography variant="h5" color="primary" style={classes.textRow}>
          Oops. Có vẻ như trang bạn tìm kiếm không tồn tại.
        </Typography>
        <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          // className={classnames(classes.textRow, classes.safetyText)}
        >
          Bạn có thể quay lại trang chủ hoặc tìm kiếm trang khác.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          size="large"
          style={classes.backButton}
          onClick={() => history.push('/')}
        >
          Back to Home
        </Button>
      </Paper>
    </Grid>
  );
}
export { ErrorPage };
