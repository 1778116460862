import React, { useEffect, useState } from 'react';
import { Table } from 'components';
import { FormCreate } from './form-create';
import { Box } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';
function ExternalCompany({ openAddExternalCompany, closeAddExternalCompany }) {
  const [clientSupportBoardList, setClientSupportBoardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (openAddExternalCompany) {
      setSelected({ isNew: true });
    }
  }, [openAddExternalCompany]);

  const onCloseModal = () => {
    setSelected(null);
    closeAddExternalCompany();
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getClientSupportBoardsByClientCompany(true);
      setClientSupportBoardList(data || []);
    } catch (err) {
      console.warn('initData - getClientSupportBoardsByClientCompany', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteUnitItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteClientSupportBoard(row.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('delete Client Support Board', err);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={clientSupportBoardList}
        headCells={Columns(deleteUnitItem)}
        onClickDetail={(row) => setSelected(row)}
        loading={loading}
        isHeader={false}
      />
      <FormCreate {...{ selected, onCloseModal, initData }} />
    </Box>
  );
}
export { ExternalCompany };
