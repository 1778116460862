import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  selectObjQuotes,
  selectServiceToQuote,
  selectTruckingService,
  selectCustomsService,
  clearAllDataQuote,
} from 'store/reducers/customerQuotes/action';
import { Table } from 'components';
import { Box } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';
import Utils from 'commons/utils';
import { FormDetail } from './form-detail';
import { SERVICES_ID } from 'commons/constants';

const Quotes = ({ openAddQuotes, closeAddQuotes }) => {
  const { TRUCKING, CUSTOMS } = SERVICES_ID;
  const dispatch = useDispatch();
  const [quoteList, setQuoteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);

  useEffect(() => {
    if (openAddQuotes) {
      setSelected({ isNew: true });
    }
  }, [openAddQuotes]);

  const onCloseModal = () => {
    setSelected(null);
    closeAddQuotes();
    dispatch(clearAllDataQuote());
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getPagingQuotes({
        query: { pageNumber: 1, pageSize: 10 },
      });
      if (data) {
        setQuoteList(data?.data || []);
      }
    } catch (err) {
      console.warn('initData - getPagingQuotes', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteContTypeItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteQuote(row?.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteCheckPrice', err);
    }
    setLoading(false);
  };
  const handleSelecteRow = (row) => {
    setLoadingForm(true);
    const dataSelect = { ...row };
    dataSelect.quotedate = Utils.checkIsDate(row?.quotedate)
      ? Utils.convertToDateFormat(row?.quotedate)
      : '';
    setSelected(dataSelect);
    dispatch(selectObjQuotes(dataSelect));
    const isGroup = row?.isGroup === 1;
    ApiServices.getQuoteDetail(row?.id, isGroup)
      .then((res) => {
        console.log({ res });
        if (res?.data) {
          if (isGroup) {
            dispatch(selectCustomsService(res?.data?.objCustoms));
            dispatch(selectTruckingService(res?.data?.objTrucking));
          }
          if (res?.data?.serviceId === TRUCKING) {
            dispatch(selectTruckingService(res?.data));
          }
          if (res?.data?.serviceId === CUSTOMS) {
            dispatch(selectCustomsService(res?.data));
          }
          dispatch(selectServiceToQuote(res?.data));
        }
      })
      .catch((err) => {
        console.log({ err });
      });
    setLoadingForm(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={quoteList}
        headCells={Columns(deleteContTypeItem)}
        onClickDetail={(row) => handleSelecteRow(row)}
        loading={loading}
        isHeader={false}
      />
      {!loadingForm && <FormDetail {...{ selected, onCloseModal, initData }} />}
    </Box>
  );
};

export { Quotes };
