import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography, ToggleButtonGroup } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
}));
export const ContainerHeaderForm = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
}));
export const ContainerContent = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'white',
  borderRadius: '15px',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
  boxShadow:
    'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
}));
export const ContainerContentForm = styled(Box)(({ theme }) => ({
  width: '100%',
  // padding: '20px',
}));
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    textTransform: 'none',
    margin: theme.spacing(1),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
export const ContainerItemService = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
}));
