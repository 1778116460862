import React from 'react';
import {
  Box,
  Link,
  Stack,
  Typography,
  Button,
  Avatar,
  AvatarGroup,
  Divider,
  Grid,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import jwt_decode from 'jwt-decode';
import { useLocation, useHistory } from 'react-router-dom';
import { BsCheck2Circle, BsXCircle, BsArrowLeftCircle } from 'react-icons/bs';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchChanel } from 'store/reducers/chat-chanel/action';
import ChatServices from 'services/chat-services';
import { fetchUsers } from 'store/reducers/users/action';
import { setSelectedChanel } from 'store/reducers/chat-chanel/action';
import storage from 'storage';
import ImgWarning from 'assets/img/warning.jpg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const LinkInviteGroupByToken = ({ isSelect, setIsSelect }) => {
  let query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataToken, setDataToken] = React.useState(null);
  const [token, setToken] = React.useState('');

  const { selectedChanel } = useSelector((state) => state.chatChanel);
  const { users } = useSelector((state) => state.users);
  const [mapUserAndMember, setMapUserAndMember] = React.useState([]);

  React.useEffect(() => {
    const initData = async () => {
      await dispatch(fetchUsers());
    };
    initData();
  }, []);

  React.useEffect(() => {
    const getDataToken = async () => {
      const getToken = query.get('token');
      if (getToken) {
        setToken(getToken);
        try {
          const decodeToken = await jwt_decode(getToken);
          if (decodeToken) {
            setDataToken(decodeToken);
          }
        } catch (error) {
          console.log({ error });
          setDataToken(null);
        }
      }
    };
    getDataToken();
  }, []);

  React.useEffect(() => {
    const getChanelById = async () => {
      try {
        const { data } = await ChatServices.getChanelById(dataToken._id);
        if (data.code === 'success') {
          const datamapUserAndMember =
            data?.results[0]?.members.map((item) => {
              const dataUser = users.find((m) => m.code === item.code);
              return dataUser;
            }) || [];
          if (datamapUserAndMember) {
            const ind = datamapUserAndMember.findIndex((m) => m.code === storage?.user?.Code);
            if (ind !== -1) {
              history.push('/chat');
            } else {
              setMapUserAndMember(datamapUserAndMember);
            }
            setMapUserAndMember(datamapUserAndMember);
          }
        }
      } catch (error) {
        console.log({ error });
      }
    };
    getChanelById();
  }, [users]);

  const onHanldeAccept = async () => {
    try {
      const { data } = await ChatServices.addMemberByInviteLink(token);
      if (data.code === 'success') {
        window.UIMessage.success('Bạn đã tham gia nhóm thành công');
        history.push('/chat');
      }
    } catch (error) {
      window.UIMessage.error('Tham gia nhóm thất bại');
    }
  };

  const onHanldeCancel = async () => {
    history.push('/chat');
  };

  return dataToken ? (
    <>
      <Typography m={2} component="h2" variant="string" sx={{ textAlign: 'center' }}>
        Thông tin nhóm
      </Typography>
      <Box
        sx={{
          padding: '0px 10px 10px 10px',
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <AvatarGroup
            max={4}
            sx={{
              justifyContent: 'center',
            }}
          >
            {mapUserAndMember?.map((item, index) => (
              <Avatar
                key={item?.id}
                alt={item?.name}
                src={item?.urlAvatar && `${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                // sx={{ width: 56, height: 56 }}
              />
            ))}
          </AvatarGroup>
          <Typography m={1} component="p" variant="string">
            <b>{dataToken?.name}</b>
          </Typography>
          <Typography component="div" variant="string">
            {mapUserAndMember?.length} thành viên
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            margin: '10px 0px',
            maxHeight: '400px',
            overflow: 'auto',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid container spacing={2}>
            {mapUserAndMember?.map((item, index) => (
              <Grid item xs={4} md={3} key={item.id}>
                <Box
                  sx={{
                    display: 'flex',
                    // justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    alt={item?.name}
                    src={item?.urlAvatar && `${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                  />
                  <Typography component="div" variant="string" sx={{ marginLeft: '10px' }}>
                    {item?.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ marginTop: '10px' }}>
          <Typography mb={5} align="center" component="i" variant="string">
            Bạn có muốn tham gia vào nhóm này không?
          </Typography>
          <Stack direction="row" spacing={2} sx={{ marginTop: '5px' }}>
            <ButtonCancel
              variant="outlined"
              startIcon={<BsXCircle />}
              color="error"
              onClick={onHanldeCancel}
            >
              Không
            </ButtonCancel>
            <ButtonAccept
              variant="outlined"
              color="success"
              startIcon={<BsCheck2Circle />}
              onClick={onHanldeAccept}
            >
              Tham gia
            </ButtonAccept>
          </Stack>
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Typography m={2} component="h2" variant="string" sx={{ textAlign: 'center', color: 'red' }}>
        Link mời tham gia nhóm không hợp lệ
      </Typography>
      <Box sx={{ padding: '10px', width: '100%' }}>
        <img src={ImgWarning} style={{ width: '100%', height: '200px', objectFit: 'contain' }} />
      </Box>
      <Box
        sx={{
          marginTop: '10px',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Typography mb={5} align="center" component="div" variant="string">
          Bạn có muốn tham gia vào nhóm này không?
        </Typography> */}
        <ButtonAccept
          variant="outlined"
          color="primary"
          startIcon={<BsArrowLeftCircle />}
          href="/chat"
        >
          Chat
        </ButtonAccept>
      </Box>
    </>
  );
};

const ButtonCancel = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  padding: '4px 20px',
}));

const ButtonAccept = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  // color: '#6C757D',
  // border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 20px',
  // '.MuiButton-startIcon': {
  //   marginRight: '2px',
  // },
}));
