import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection, NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      setValues,
      disabledResponseCheck,
      keyArray,
      keyNameOfArray,
      widthContainerLeft = '45%',
      widthContainerBody = '65%',
      subFeeList,
      unitList,
    },
    ref,
  ) => {
    const [arrFeeGroup, setArrFeeGroup] = React.useState([]);
    const [setNameFeeGroup, setSetNameFeeGroup] = React.useState('');
    const [unitCode, setUnitCode] = React.useState('');
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setArrFeeGroup(item?.arrFeeGroup || []);
      setUnitCode(item?.unitCode || '');
      setArrayCont(item[keyArray] || []);
    }, [item]);

    React.useEffect(() => {
      if (arrFeeGroup && arrFeeGroup.length > 0) {
        const nameFeeGroup = arrFeeGroup
          ?.map((item) => {
            const name = subFeeList.find((i) => i.id === item)?.name;
            return name;
          })
          .join(', ');
        setSetNameFeeGroup(nameFeeGroup);
      } else {
        setSetNameFeeGroup('');
      }
    }, [subFeeList, arrFeeGroup]);

    React.useEffect(() => {
      if (arrFeeGroup && arrFeeGroup.length > 0) {
        const nameFeeGroup = arrFeeGroup
          .map((item) => {
            const name = subFeeList.find((i) => i.id === item)?.name;
            return name;
          })
          .join(', ');
        setSetNameFeeGroup(nameFeeGroup);
      } else {
        setSetNameFeeGroup('');
      }
    }, [arrFeeGroup]);

    const onChangeFeeGroup = React.useCallback(
      (e) => {
        setArrFeeGroup(e.target.value);
        setValues((prev) => {
          prev[index].arrFeeGroup = e.target.value;
          return [...prev];
        });
      },
      [arrFeeGroup],
    );

    const onChangeUnit = React.useCallback(
      (e) => {
        setUnitCode(e.target.value);
        setValues((prev) => {
          prev[index].unitCode = e.target.value;
          return [...prev];
        });
      },
      [unitCode],
    );
    const onChangeCont = React.useCallback(
      (e, indContType) => {
        const { value } = e.target;
        setArrayCont((prev) => {
          prev[indContType].price = +value;
          return [...prev];
        });

        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    return (
      <>
        <Box>
          <i style={{ color: 'blue' }}>
            Giá bán request bao gồm: Giá cước{setNameFeeGroup && `, ${setNameFeeGroup}`}
          </i>
        </Box>
        <Container>
          <ContainerLeft width={widthContainerLeft}>
            <Selection
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Giá request"
              name="arrFeeGroup"
              multiple={true}
              onChange={onChangeFeeGroup}
              value={arrFeeGroup}
              fieldValue="id"
              fieldView="name"
              dataArrObject={subFeeList}
              disabled={disabledResponseCheck}
            />
          </ContainerLeft>
          <ContainerBody width={widthContainerBody}>
            {arrayCont?.map((el, indContType) => (
              <Box sx={{ width: '100%' }} key={indContType}>
                <StyledTextFieldPrice
                  label={el[keyNameOfArray]}
                  variant="outlined"
                  type="text"
                  size="small"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={(e) => onChangeCont(e, indContType)}
                  value={el?.price}
                  disabled={disabledResponseCheck}
                />
              </Box>
            ))}
            <Box sx={{ width: '100%', marginLeft: '10px', marginRight: '10px' }}>
              <Selection
                sx={{
                  width: '100%',
                  marginTop: 0,
                  fontSize: '12px',
                }}
                sxInput={{ padding: '0px', margin: '0px' }}
                label="Đơn vị tính"
                name="serviceId"
                onChange={onChangeUnit}
                value={unitCode}
                fieldValue="code"
                fieldView="name"
                dataArrObject={unitList}
                disabled={disabledResponseCheck}
              />
            </Box>
          </ContainerBody>
          {!disabledResponseCheck && (
            <ContainerAction>
              <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
            </ContainerAction>
          )}
        </Container>
      </>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px',
}));
const ContainerBody = styled(Box)(({ theme, width = '45%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerLeft = styled(Box)(({ theme, width = '15%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
}));
