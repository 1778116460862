import actionTypes from './type';
import Storage from '../../../storage';

export function signIn(userData) {
  return dispatch => {
    Storage.saveUser(userData)
      .then(user => {
        dispatch(changeUser(user));
      })
      .catch(error => {
        console.log('signIn -> error', error);
      });
  };
}

export function signOut() {
  return dispatch => {
    Storage.removeUser();
    dispatch(changeUser(null));
  };
}

export function appInitialized() {
  return changeAppInitializing(false);
}

export function appStartInitializing() {
  return changeAppInitializing(true);
}

export function updateUserInfo(userData) {
  return dispatch => {
    Storage.saveUser(userData)
      .then(user => {
        dispatch(changeUser(user));
      })
      .catch(error => {
        console.log('updateUserInfo -> error', error);
      });
  };
}

export function changeAppInitializing(initializing) {
  return {type: actionTypes.APP_INITIALIZING_CHANGED, initializing};
}

export function changeUser(user) {
  return {type: actionTypes.USER_CHANGED, user};
}
