import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { ModalFormDetail, TextInput, Selection } from 'components';
import { LoadingView } from 'components/loading-view';
import { Box, Button, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Formik } from 'formik';
import {
  PRICE_BOARD_STATUS_OPTIONS,
  SUB_SERVICES_ID,
  SERVICES_ID,
  FEE_GROUP_ID,
} from 'commons/constants';
import ApiServices from 'services';
import HeaderFormDetail from '../header-form-detail';
import { WhHandlingChargeForm } from '../forms-management/wh-handling-charge-form';
import { WhTransportChargeForm } from '../forms-management/wh-transport-charge-form';
import { WhOtherServicesForm } from '../forms-management/wh-other-services-form';
import { WhLeasingPriceForm } from '../forms-management/wh-leasing-price-form';
import { WhInboundOutboundForm } from '../forms-management/wh-inbound-outbound-form';

export const FormDetail = ({ selected, onCloseModal, initData, ...props }) => {
  const disableByStatus = useMemo(() => {
    return selected?.status === 1;
  }, [selected?.id]);

  const [loading, setLoading] = useState(false);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [leaseSpaceList, setLeaseSpaceList] = useState([]);
  const [showHandlingCharge, setShowHandlingCharge] = useState(false);
  const [showInboundCharge, setShowInboundCharge] = useState(false);
  const [showOutboundCharge, setShowOutboundCharge] = useState(false);
  const [showTransportCharge, setShowTransportCharge] = useState(false);

  const handleClose = () => {
    setGroupFeeGroup([]);
    setCurrencyList([]);
    setUnitList([]);
    setCargoTypeList([]);
    setLeaseSpaceList([]);
    setShowHandlingCharge(false);
    setShowInboundCharge(false);
    setShowOutboundCharge(false);
    setShowTransportCharge(false);
    onCloseModal();
  };

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSSAndFeeId(
        FEE_GROUP_ID.OTHER_SERVICES,
        SUB_SERVICES_ID.BONDED_WAREHOUSE,
      );
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          if (!selected?.isNew) {
            const ind = selected?.arrSurcharge?.findIndex((i) => i.feeGroupID === item.feeGroupID);
            if (ind !== -1) {
              item.surcharge = selected?.arrSurcharge[ind]?.surcharge || [];
              item.checked = true;
            } else {
              item.checked = false;
            }
          } else {
            item.checked = false;
          }
          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDataInits = async () => {
    let [getUnit, getCurrency, getCargoType, getLeaseSpace] = await Promise.all([
      ApiServices.getUnits(),
      ApiServices.getCurrencies(),
      ApiServices.getCargoTypes(),
      ApiServices.getLeaseSpaces(),
    ]);
    setUnitList(getUnit.data || []);
    setCurrencyList(getCurrency.data || []);
    setCargoTypeList(getCargoType.data || []);
    setLeaseSpaceList(getLeaseSpace.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoading(true);
      await getDataInits();
      setLoading(false);
    };
    getDataInit();
  }, []);

  useEffect(() => {
    if (selected) {
      getSurchargeByFeeGroupSS();
      if (selected?.listHandlingCharges && selected?.listHandlingCharges?.length > 0) {
        setShowHandlingCharge(true);
      }
      if (selected?.listCCSChargeInbound && selected?.listCCSChargeInbound?.length > 0) {
        setShowInboundCharge(true);
      }
      if (selected?.listCCSChargeOutBound && selected?.listCCSChargeOutBound?.length > 0) {
        setShowOutboundCharge(true);
      }
      if (selected?.transportServiceCharge && selected?.transportServiceCharge?.length > 0) {
        setShowTransportCharge(true);
      }
    }
  }, [selected]);

  const handleCheckedHandlingCharge = (e) => {
    setShowHandlingCharge(e.target.checked);
  };
  const handleCheckedInboundCharge = (e) => {
    setShowInboundCharge(e.target.checked);
  };
  const handleCheckedOutboundCharge = (e) => {
    setShowOutboundCharge(e.target.checked);
  };
  const handleCheckedTransport = (e) => {
    setShowTransportCharge(e.target.checked);
  };
  const handleCheckFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.checked = event.target.checked;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };

  const handleCloseCheckedItem = (item) => {
    const ind = groupFeeGroup.findIndex((i) => i.feeGroupID === item.feeGroupID);
    if (ind !== -1) {
      const data = groupFeeGroup;
      data[ind].checked = false;
      setGroupFeeGroup(data);
    }
  };

  const handleChangeDataFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.surcharge = event.target.value;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };

  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!showHandlingCharge) {
      values.listHandlingCharges = [];
    }
    if (!showInboundCharge) {
      values.listCCSChargeInbound = [];
    }
    if (!showOutboundCharge) {
      values.listCCSChargeOutBound = [];
    }
    if (!showTransportCharge) {
      values.transportServiceCharge = [];
    }
    if (groupFeeGroup && groupFeeGroup.length > 0) {
      const data = groupFeeGroup
        .filter((el) => el.checked)
        .map((item) => {
          return {
            feeGroupID: item?.feeGroupID,
            surcharge: item?.surcharge,
          };
        });
      values.arrSurcharge = data;
    } else {
      values.arrSurcharge = [];
    }
    values.serviceId = SERVICES_ID.WAREHOUSE; // Kho
    values.subServiceId = SUB_SERVICES_ID.BONDED_WAREHOUSE; // Kho ngoại quan
    delete values.isNew;
    try {
      const res = await ApiServices.createWarehouse(values);
      if (res.status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        handleClose();
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!showHandlingCharge) {
      values.listHandlingCharges = [];
    }
    if (!showInboundCharge) {
      values.listCCSChargeInbound = [];
    }
    if (!showOutboundCharge) {
      values.listCCSChargeOutBound = [];
    }
    if (!showTransportCharge) {
      values.transportServiceCharge = [];
    }
    if (groupFeeGroup && groupFeeGroup.length > 0) {
      const data = groupFeeGroup
        .filter((el) => el.checked)
        .map((item) => {
          return {
            feeGroupID: item?.feeGroupID,
            surcharge: item?.surcharge,
          };
        });
      values.arrSurcharge = data;
    } else {
      values.arrSurcharge = [];
    }
    if (!values.expirydate) {
      delete values.expirydate;
    }

    try {
      const { data } = await ApiServices.updateWarehouse(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        handleClose();
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const renderFeeGroup = (item) => (
    <FormGroup key={item.feeGroupID}>
      <FormControlLabel
        sx={{ width: '50%' }}
        control={
          <Checkbox
            checked={item.checked}
            onChange={(event) => handleCheckFeeGroup(event, item)}
            disabled={disableByStatus}
          />
        }
        label={item.feeGroupName}
      />
      {item.checked && (
        <WhOtherServicesForm
          arrSurcharge={item.surchargeListFree}
          arrUnit={unitList}
          name={item.feeGroupID}
          onChange={(event) => handleChangeDataFeeGroup(event, item)}
          value={item?.surcharge}
          closeCheckedItem={() => handleCloseCheckedItem(item)}
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          disabledField={disableByStatus}
        />
      )}
    </FormGroup>
  );

  const ARRAY_INPUT = [
    // { field: 'code', lable: 'Mã', disabled: !selected?.isNew },
    {
      field: 'status',
      lable: 'Trang thái',
      type: 'selection',
      dataArrObject: PRICE_BOARD_STATUS_OPTIONS,
      disabled: selected?.isNew || disableByStatus,
      isShowItem: true,
    },
    {
      field: 'currencyCode',
      lable: 'Đơn vị tiền tệ',
      type: 'selection',
      dataArrObject: currencyList,
      fieldValue: 'code',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'typeCargoId',
      lable: 'Loại hàng hóa',
      type: 'selection',
      dataArrObject: cargoTypeList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    { field: 'expirydate', lable: 'Hết hạn', type: 'date-picker' },
    { field: 'note', lable: 'Ghi chú', disabled: disableByStatus },
  ];

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={onCloseModal}
        title={selected?.isNew ? 'Thêm mới' : 'Thông tin chi tiết'}
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <Divider sx={{ marginY: 1 }}>Thông tin cơ bản</Divider>
        {!loading && (
          <Formik
            initialValues={{
              // code: '',
              status: 0,
              currencyCode: '',
              typeCargoId: '',
              expirydate: '',
              note: '',
              warehouseLeasingPrice: [],
              listHandlingCharges: [],
              listCCSChargeInbound: [],
              listCCSChargeOutBound: [],
              transportServiceCharge: [],
              ...selected,
            }}
            validationSchema={Yup.object().shape({
              // code: Yup.string().max(255).required('Vui lòng nhập mã'),
              currencyCode: Yup.string().max(255).required('Vui lòng chọn đơn vị tiền tệ'),
              typeCargoId: Yup.string().max(255).required('Vui lòng chọn loại hàng hóa'),
              expirydate: Yup.string().max(255).required('Vui lòng chọn ngày hết hạn'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (selected?.isNew) {
                createFn(values, { setSubmitting });
              } else {
                updateFn(values, { setSubmitting });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {ARRAY_INPUT.map((item) => {
                      if (item.type == 'selection') {
                        if (selected?.expirationStatus && item.isShowItem) {
                          return <></>;
                        } else {
                          return (
                            <Grid key={`selection-${item.field}`} item xs={4} sm={6} md={3}>
                              <Selection
                                sxInput={{ padding: '0px', margin: '0px' }}
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item.lable}
                                name={item.field}
                                data={item?.data}
                                disabled={item?.disabled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item.field]}
                                fieldValue={item?.fieldValue}
                                fieldView={item?.fieldView}
                                syncData={item?.syncData}
                                dataArrObject={item?.dataArrObject}
                              />
                            </Grid>
                          );
                        }
                      }
                      if (item.type == 'date-picker') {
                        return (
                          <Grid key={`date-picker-${item.field}`} item xs={4} sm={6} md={3}>
                            <TextInput
                              error={Boolean(touched[item.field] && errors[item.field])}
                              helperText={touched[item.field] && errors[item.field]}
                              label={item.lable}
                              name={item.field}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values[item.field]}
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={item?.disabled}
                            />
                          </Grid>
                        );
                      }
                      return (
                        <Grid key={`input-${item.field}`} item xs={4} sm={6} md={3}>
                          <TextInput
                            error={Boolean(touched[item.field] && errors[item.field])}
                            helperText={touched[item.field] && errors[item.field]}
                            label={item.lable}
                            name={item.field}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values[item.field]}
                            disabled={item?.disabled}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Divider sx={{ marginY: 1 }}>Phí khai báo hải quan</Divider>
                <b>Warehouse leasing price</b>
                <WhLeasingPriceForm
                  name="warehouseLeasingPrice"
                  requiredTitle="Vui lòng nhập đầy đủ thông tin"
                  onChange={handleChange}
                  value={values.warehouseLeasingPrice}
                  disabledField={disableByStatus}
                  leaseSpaceList={leaseSpaceList}
                />
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '30%' }}
                    control={
                      <Checkbox
                        checked={showHandlingCharge}
                        onChange={handleCheckedHandlingCharge}
                      />
                    }
                    label="Handling charges"
                    disabled={disableByStatus}
                  />
                </FormGroup>
                {showHandlingCharge && (
                  <WhHandlingChargeForm
                    name="listHandlingCharges"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.listHandlingCharges}
                    disabledField={disableByStatus}
                  />
                )}
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '50%' }}
                    control={
                      <Checkbox checked={showInboundCharge} onChange={handleCheckedInboundCharge} />
                    }
                    label="Customs clearance service charges Inbound"
                    disabled={disableByStatus}
                  />
                </FormGroup>
                {showInboundCharge && (
                  <WhInboundOutboundForm
                    name="listCCSChargeInbound"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.listCCSChargeInbound}
                    disabledField={disableByStatus}
                  />
                )}
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '50%' }}
                    control={
                      <Checkbox
                        checked={showOutboundCharge}
                        onChange={handleCheckedOutboundCharge}
                      />
                    }
                    label="Customs clearance service charges Outbound"
                    disabled={disableByStatus}
                  />
                </FormGroup>
                {showOutboundCharge && (
                  <WhInboundOutboundForm
                    name="listCCSChargeOutBound"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.listCCSChargeOutBound}
                    disabledField={disableByStatus}
                    isOutbound={true}
                  />
                )}
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '30%' }}
                    control={
                      <Checkbox checked={showTransportCharge} onChange={handleCheckedTransport} />
                    }
                    label="Transport service charge"
                    disabled={disableByStatus}
                  />
                </FormGroup>
                {showTransportCharge && (
                  <WhTransportChargeForm
                    name="transportServiceCharge"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.transportServiceCharge}
                    disabledField={disableByStatus}
                  />
                )}
                {groupFeeGroup?.map(renderFeeGroup)}

                <Box sx={{ display: 'flex', py: 2 }}>
                  {/* {dataError && <ErrorText>Vui lòng nhập đầy đủ thông tin</ErrorText>} */}
                  <Box sx={{ marginLeft: 'auto' }}>
                    {!selected?.expirationStatus && (
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                      </Button>
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};

const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '14px',
  marginLeft: '10px',
  fontWeight: 'bold',
}));
