import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      onAdd,
      values,
      setValues,
      disabledField,
      keyArray,
      keyNameOfArray,
      cargoTypeList,
    },
    ref,
  ) => {
    const [grosston, setGrosston] = React.useState(0);
    const [volume, setVolume] = React.useState(0);
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setGrosston(item?.grosston);
      setVolume(item.volume);
      setArrayCont(item[keyArray] || []);
    }, [item]);

    const onChangeGrosston = React.useCallback(
      (e) => {
        setGrosston(+e.target.value);
        setValues((prev) => {
          prev[index].grosston = +e.target.value;
          return [...prev];
        });
      },
      [grosston],
    );
    const onChangeVolume = React.useCallback(
      (e) => {
        setVolume(+e.target.value);
        setValues((prev) => {
          prev[index].volume = +e.target.value;
          return [...prev];
        });
      },
      [volume],
    );
    const onChangeCont = React.useCallback(
      (e, indContType) => {
        const { value } = e.target;
        setArrayCont((prev) => {
          prev[indContType].cargoTypePrice = +value;
          return [...prev];
        });
        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    const renderLabel = (id) => {
      const ind = cargoTypeList.findIndex((item) => item.id === id);
      if (ind === -1) return '';
      return cargoTypeList[ind]?.name;
    };

    const isDisabled = !grosston || !volume || !arrayCont.some((item) => item.cargoTypePrice);

    return (
      <Container>
        <ContainerBody>
          <Box sx={{ width: '100%' }}>
            <StyledTextFieldPrice
              label="Trọng tải"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={onChangeGrosston}
              value={grosston}
              error={!grosston}
              disabled={disabledField}
            />
            {!grosston && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <Box sx={{ width: '100%' }}>
            <StyledTextFieldPrice
              label="Thể tích"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={onChangeVolume}
              value={volume}
              error={!volume}
              disabled={disabledField}
            />
            {!volume && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          {arrayCont?.map((el, indContType) => (
            <Box sx={{ width: '100%' }} key={indContType}>
              <StyledTextFieldPrice
                label={renderLabel(el[keyNameOfArray])}
                variant="outlined"
                type="text"
                size="small"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => onChangeCont(e, indContType)}
                value={el?.cargoTypePrice}
                error={!el?.cargoTypePrice}
                disabled={disabledField}
              />
              {!el?.cargoTypePrice && <ErrorText>Bắt buộc (*)</ErrorText>}
            </Box>
          ))}
        </ContainerBody>
        <ContainerAction>
          {index === values.length - 1 ? (
            <>
              <ButtonWrapper
                onClick={() => onDelete(index)}
                sx={{ borderColor: 'error.main' }}
                disabled={disabledField}
              >
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
              <ButtonWrapper
                onClick={onAdd}
                sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                disabled={isDisabled}
              >
                <AddCircleOutlineIcon
                  sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                />
              </ButtonWrapper>
            </>
          ) : (
            <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
              <HighlightOffIcon sx={{ color: 'error.main' }} />
            </ButtonWrapper>
          )}
        </ContainerAction>
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px',
}));
const ContainerBody = styled(Box)(({ theme }) => ({
  width: '95%',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
