import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn, SelectionSearchColumn } from 'components';
import { Button } from '@mui/material';
import { TYPE_CLIENT_OPTIONS } from 'commons/constants';

const renderCustomerType = (type) => {
  switch (type) {
    case 0:
      return 'Khách hàng tiềm năng';
    case 1:
      return 'Khách hàng hiện hữu';
    default:
      break;
  }
};

export const Columns = (
  handleSearchColumn,
  deleteItem,
  searchClientType,
  handleSearchSelect,
  handleSearchColumnByParam,
) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã quà tặng" name="code" onChange={handleSearchColumn} />,
    width: '100px',
    align: 'center',
  },
  {
    id: 'typeClientCompany',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <>
        <b>Loại khách hàng</b>
        <SelectionSearchColumn
          size="small"
          label=""
          name="typeClientCompany"
          dataArrObject={TYPE_CLIENT_OPTIONS}
          value={searchClientType}
          onChange={handleSearchSelect}
          displayNone={false}
        />
      </>
    ),
    align: 'left',
    render: (row) => renderCustomerType(row?.typeClientCompany),
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Khách hàng"
        name="strClientCompanyName"
        onChange={handleSearchColumnByParam}
      />
    ),
    align: 'left',
  },
  {
    id: 'realReceiver',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Người nhận"
        name="strRealReceiver"
        onChange={handleSearchColumnByParam}
      />
    ),
    align: 'left',
  },
  {
    id: 'typeGiftName',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Loại quà tặng',
    // label: (
    //   <SearchInputColumn title="Loại quà tặng" name="typeGiftName" onChange={handleSearchColumn} />
    // ),
    align: 'left',
  },
  {
    id: 'priceGift',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Giá trị',
    // label: <SearchInputColumn title="Giá trị" name="priceGift" onChange={handleSearchColumn} />,
    align: 'right',
    render: (row) => Utils.formatNumber(row?.priceGift),
  },
  {
    id: 'note',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Ghi chú',
    align: 'left',
  },
  {
    id: 'giftSendDate',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Ngày tặng',
    // label: (
    //   <SearchInputColumn title="Ngày tặng" name="giftSendDate" onChange={handleSearchColumn} />
    // ),
    align: 'left',
    render: (row) => Utils.formatDateFn(row?.giftSendDate),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    width: '10px',
    fixed: 'right',
    label: 'Thao tác',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
