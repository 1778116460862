import actionTypes from './type';

const initialState = {
  subServiceSelectedList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SUB_SERVICE_SELECTED_LIST:
      return {
        ...state,
        subServiceSelectedList: action.data,
      };
    case actionTypes.CLEAR_SUB_SERVICE_SELECTED_LIST:
      return {
        ...state,
        subServiceSelectedList: [],
      };
    case actionTypes.ADD_DATA_RES_CHECK_PRICE:
      const index = state?.subServiceSelectedList?.findIndex((el) => el.id === action.itemId);
      if (index === -1) return state;
      const cloneDataList = [...state?.subServiceSelectedList];
      const addContentList = [...cloneDataList[index]?.contentList];
      addContentList.push(action.newData);
      cloneDataList[index] = {
        ...cloneDataList[index],
        contentList: addContentList,
      };
      return {
        ...state,
        subServiceSelectedList: cloneDataList,
      };

    case actionTypes.UPDATE_DATA_RES_CHECK_PRICE:
      const ind = state?.subServiceSelectedList?.findIndex((el) => el.id === action.itemId);
      if (ind === -1) return state;
      const dataList = [...state?.subServiceSelectedList];
      const newContentList = [...dataList[ind]?.contentList];
      newContentList[action.indexContentList][action.keyUpdate] = action.dataUpdate;
      if (action?.keyClear) {
        newContentList[action.indexContentList][action.keyClear] = null;
      }
      dataList[ind] = {
        ...dataList[ind],
        contentList: newContentList,
      };
      return {
        ...state,
        subServiceSelectedList: dataList,
      };

    case actionTypes.DELETE_DATA_RES_CHECK_PRICE:
      const indexDelete = state?.subServiceSelectedList?.findIndex((el) => el.id === action.itemId);
      if (indexDelete === -1) return state;
      const dataDeleteList = [...state?.subServiceSelectedList];
      const newContentDeleteList = [...dataDeleteList[indexDelete]?.contentList];
      newContentDeleteList.splice(action.indexContentList, 1);
      dataDeleteList[indexDelete] = {
        ...dataDeleteList[indexDelete],
        contentList: newContentDeleteList,
      };
      return {
        ...state,
        subServiceSelectedList: dataDeleteList,
      };
    default:
      return state;
  }
}
