import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'components';
import { FormDetail } from './form-detail';
import { Box, Typography } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { BsPlusCircle } from 'react-icons/bs';
import { Columns } from './columns';
import { ContainerHeader, ButtonAdd } from './styles';
import { RESPONSE_STATUSES } from 'commons/constants';
import { debounce } from 'lodash';

const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
};

function VendorList() {
  const [dataList, setDataList] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const initData = () => {
    setLoading(true);
    return ApiServices.getVendorServices()
      .then((res) => {
        if (res?.data) {
          const mapData = res?.data?.map((item) => {
            let strService = '';

            item?.arrService?.map((service) => {
              strService += service?.name;
            });

            item.strService = strService;
            item.strAddress = item?.arrAddress?.join('');
            item.strNumberPhone = item?.arrNumberPhone?.join('');

            return item;
          });

          setDataList(mapData || []);
          setOriginData(mapData || []);
        }
      })
      .catch((err) => console.warn('get init getVendorServices:', err))
      .finally(() => setLoading(false));
  };

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }
      if (query?.email) {
        if (!Utils.convertValSearch(item?.email)?.includes(Utils.convertValSearch(query?.email))) {
          checkFilter = false;
        }
      }
      if (query?.strService) {
        if (
          !Utils.convertValSearch(item?.strService)?.includes(
            Utils.convertValSearch(query?.strService),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.strAddress) {
        if (
          !Utils.convertValSearch(item?.strAddress)?.includes(
            Utils.convertValSearch(query?.strAddress),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.strNumberPhone) {
        if (
          !Utils.convertValSearch(item?.strNumberPhone)?.includes(
            Utils.convertValSearch(query?.strNumberPhone),
          )
        ) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setDataList(dataFilter);
  };

  const handleSearchColumn = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    initData();
  }, []);
  const reloadData = async () => {
    return await initData(filter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const deleteItem = async (row) => {
    if (!row?.id) return;

    setLoading(true);
    return ApiServices.deleteVendorService(row?.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteCell - companies', err);
      })
      .finally(() => setLoading(false));
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          DANH SÁCH ĐỐI TÁC
        </Typography>
      </ContainerHeader>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.birthday = Utils.checkIsDate(row.birthday)
      ? Utils.convertToDateFormat(row.birthday)
      : '';
    dataSelect.nationality = row.nationality || '';
    dataSelect.religion = row.religion || '';
    dataSelect.arrAddress = row.arrAddress || [];
    dataSelect.fax = row.fax || '';
    dataSelect.email = row.email || '';
    return dataSelect;
  };
  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelected(dataSelect);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={dataList}
          headCells={Columns(handleSearchColumn, deleteItem)}
          onClickDetail={handleClickDetail}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, reloadData }} />
      </Box>
    </>
  );
}
export { VendorList };
