import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { Box, Button, Typography, Grid, Link } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const TaskListLatest = () => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        item
        xs={6}
        sx={{ justifyContent: 'space-between', display: 'flex', m: '10px', mr: '10px', mb: '10px' }}
      >
        <Typography variant="h7" component="div" sx={{ color: 'text.primary' }} align="center">
          <b>Công việc</b>
        </Typography>
        <Link href="#" underline="hover">
          Xem tất cả
        </Link>
      </Box>
    </Box>
  );
};

export { TaskListLatest };
