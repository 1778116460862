import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import { Drawer, Box, Typography, Tabs, Tab } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ContentTab = (props) => (
  <div
    role="tabpanel"
    hidden={props?.index !== props?.tabValue}
    id={`simple-tabpanel-${props?.index}`}
    aria-labelledby={`simple-tab-${props?.index}`}
    style={{ width: props?.width || '400px', padding: '10px' }}
  >
    {props.children}
  </div>
);
export const HandleFormDetail = ({
  showTitleTab = null,
  showTitleTabFn = null,
  children,
  selected,
  setSelected,
  onClose,
  showTitleTabRight = null,
  moreTabs = [],
  ...props
}) => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (selected) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
    setTabValue(0);
  }, [selected]);
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setSelected?.();
    onClose?.();
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={onCloseDrawer}
        sx={{ zIndex: theme.zIndex.drawer + 2, mb: 5 }}
      >
        <Box role="presentation" variant="h6">
          <Box
            sx={{
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'justify',
              flexDirection: 'start-end',
              alignItems: 'center',
              p: '5px',
              pl: '10px',
              ...props?.sx,
            }}
          >
            {(showTitleTab && (
              <Typography color="primary" variant="h6" noWrap component="div">
                {showTitleTab}
              </Typography>
            )) ||
              (showTitleTabFn && showTitleTabFn()) || (
                <Typography
                  color="primary"
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  {selected?.id ? `ID: ${selected.id}` : 'Tạo mới'}
                </Typography>
              )}
            {showTitleTabRight && (
              <Box
                sx={{
                  flexDirection: 'flex-end',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  ml: 'auto',
                  mr: '20px',
                }}
              >
                {showTitleTabRight}
              </Box>
            )}
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
              aria-label="Thông Tin Chi tiết"
            >
              <Tab label="Thông Tin Chi tiết" {...a11yProps(0)} />
              {moreTabs.map(({ title, disabled }, index) => (
                <Tab
                  label={title || 'TAB ' + index + 1}
                  {...a11yProps(index + 1)}
                  disabled={disabled}
                />
              ))}
            </Tabs>
          </Box>
          <ContentTab index={0} tabValue={tabValue} width={props?.width}>
            {children}
          </ContentTab>

          {moreTabs.map(({ content }, index) => (
            <ContentTab
              index={index + 1}
              tabValue={tabValue}
              key={`simple-tabpanel-${index}`}
              width={props?.width}
            >
              {content && content()}
            </ContentTab>
          ))}
        </Box>
      </Drawer>
    </div>
  );
};
