import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from 'store/reducers/confirm-messages/action';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ConfirmMessage() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { submit } = useSelector((state) => state.confirm);
  const handleClose = () => {
    setOpen(false);
    dispatch(clear());
  };

  React.useEffect(() => {
    submit && setOpen(true);
  }, [submit]);
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Chắc chắn xóa</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Vui lòng xác nhận thao tác của bạn!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              submit();
              handleClose();
            }}
          >
            Đồng ý
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
