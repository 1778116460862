import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import Utils from 'commons/utils';
import { RenderPriceBoardStatus, RenderPriceBoardState } from 'components/custom-status';

const HeaderFormDetail = ({ selected }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <b>Mã: </b> <span style={{ color: 'blue' }}>{selected?.code}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Trạng thái: </b> <RenderPriceBoardStatus status={selected?.status} />
        </Grid>
        <Grid item xs={2}>
          <b>Ngày tạo: </b> {Utils.formatDateFn(selected?.createdOn)}
        </Grid>
        <Grid item xs={2}>
          <b>Ngày CS cuối: </b> {Utils.formatDateFn(selected?.updatedOn)}
        </Grid>
        <Grid item xs={2}>
          <b>Tình trạng: </b> <RenderPriceBoardState state={selected?.expirationStatus} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(HeaderFormDetail);
