import React from 'react';
import { Box, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { BsPlusCircle } from 'react-icons/bs';
import { Tractor } from './tractor';
import { Truck } from './truck';
import { TabItem, BtnCreateTrucking } from './styles';

export const TruckingPriceBoard = () => {
  const [tabIndex, setTabIndex] = React.useState('1');
  const [addTractor, setAddTractor] = React.useState(false);
  const [addTruck, setAddTruck] = React.useState(false);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const closeAddNew = () => {
    if (tabIndex === '1') {
      setAddTractor(false);
    } else if (tabIndex === '2') {
      setAddTruck(false);
    }
  };

  const onHandleAddNew = () => {
    if (tabIndex === '1') {
      setAddTractor(true);
    } else if (tabIndex === '2') {
      setAddTruck(true);
    }
  };

  React.useEffect(() => {
    return () => {
      setAddTractor(false);
      setAddTruck(false);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" component="h6">
        BẢNG GIÁ DỊCH VỤ VẬN TẢI
      </Typography>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
            <TabItem label="Đầu kéo" value="1" />
            <TabItem label="Xe tải" value="2" />
            <Box sx={{ alignItems: 'center', marginLeft: 'auto' }}>
              <BtnCreateTrucking
                variant="contained"
                startIcon={<BsPlusCircle />}
                onClick={onHandleAddNew}
              >
                Thêm mới
              </BtnCreateTrucking>
            </Box>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: '5px 0px' }}>
          <Tractor openAddTractor={addTractor} closeAddTractor={closeAddNew} />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '5px 0px' }}>
          <Truck openAddTruck={addTruck} closeAddTruck={closeAddNew} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
