import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Card,
  CardHeader,
  Avatar,
  AvatarGroup,
  CardActions,
  IconButton,
  Typography,
  Stack,
  Chip,
  Link,
  Tooltip,
  Badge,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Telegram as TelegramIcon,
  Call as CallIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { RiKey2Line } from 'react-icons/ri';
import ApiServices from 'services';
import { DrawerInformationDetails } from './information-detail';
import { CLIENT_STATUS_ID } from 'commons/constants';
import { SearchInput } from 'components';
import moment from 'moment';
import './client-care-list.css';

const ClientCarelist = ({
  columns,
  setColumns,
  getDataInit,
  handleSearchColumn,
  columnsOriginCare,
  columnsOriginDeal,
  columnsOriginDealSuccess,
}) => {
  const { LEAD_TAKING_CARE, LEAD_WAIT_CARE_AGAIN, DEAL, DEAL_WAIT_CARE_AGAIN, DEAL_SUCCESS } =
    CLIENT_STATUS_ID;

  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [clientCareHistoryItem, setClientCareHistoryItem] = React.useState({});
  const [clientInformationDetail, setClientInformationDetail] = React.useState({});

  const [textSearch1, setTextSearch1] = React.useState('');
  const [textSearch2, setTextSearch2] = React.useState('');
  const [textSearch3, setTextSearch3] = React.useState('');

  const { isOpen } = useSelector((state) => state.openSideBar);

  const updateClientStatus = async (idClientCareHistory, idStatus) => {
    try {
      const { data } = await ApiServices.updateClientStatus(idClientCareHistory, `${idStatus}`);
      if (data) {
        return getDataInit();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) return;
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
    await updateClientStatus(draggableId, destination.droppableId);
  };

  const getClientCareHistoryDetails = async (idClientCareHistory) => {
    try {
      const { data } = await ApiServices.getClientCareHistoryDetails(idClientCareHistory);
      setClientCareHistoryItem(data);
      setClientInformationDetail(data.objClientCompany);
    } catch (error) {
      console.log(error);
    }
  };

  const timeoutGetData = React.useCallback(
    debounce((newTextVal, newDataColumn, newDataInit) => {
      handleSearchColumn && handleSearchColumn(newTextVal, newDataColumn, newDataInit);
    }, 500),
    [],
  );

  const handleChangeTextSearch = (event, dataColumn, dataInit) => {
    const textVal = event.target.value;

    switch (dataColumn.columnId) {
      case LEAD_TAKING_CARE:
        setTextSearch1(textVal);
        timeoutGetData(textVal, dataColumn, dataInit);
        return;

      case DEAL:
        setTextSearch2(textVal);
        timeoutGetData(textVal, dataColumn, dataInit);
        return;

      case DEAL_SUCCESS:
        setTextSearch3(textVal);
        timeoutGetData(textVal, dataColumn, dataInit);
        return;

      default:
        break;
    }
  };

  const renderSearch = (dataColumn) => {
    switch (dataColumn.columnId) {
      case LEAD_TAKING_CARE:
        return (
          <SearchInput
            sx={{ mt: 1 }}
            value={textSearch1}
            onChange={(event) => handleChangeTextSearch(event, dataColumn, columnsOriginCare)}
          />
        );
      case DEAL:
        return (
          <SearchInput
            sx={{ mt: 1 }}
            value={textSearch2}
            onChange={(event) => handleChangeTextSearch(event, dataColumn, columnsOriginDeal)}
          />
        );
      case DEAL_SUCCESS:
        return (
          <SearchInput
            sx={{ mt: 1 }}
            value={textSearch3}
            onChange={(event) =>
              handleChangeTextSearch(event, dataColumn, columnsOriginDealSuccess)
            }
          />
        );

      default:
        break;
    }
  };

  const onEditFn = async (item) => {
    await getClientCareHistoryDetails(item.id);
    return setIsOpenDrawer(true);
  };

  const onChangeColumn = async (item) => {
    if (item.clientStatusId === LEAD_TAKING_CARE) {
      await updateClientStatus(item.id, LEAD_WAIT_CARE_AGAIN);
    }
    if (item.clientStatusId === DEAL) {
      await updateClientStatus(item.id, DEAL_WAIT_CARE_AGAIN);
    }
  };

  const renderWarningDateCare = (item) => {
    const careTime = item?.careTime || 0;
    // lastDayofCare
    const timeDiff = moment(item?.lastDayofCare).diff(moment());
    var duration = moment.duration(timeDiff);
    const daysDuration = duration.asDays();
    const formatDay = Math.abs(parseInt(daysDuration));

    const endCompare = careTime - formatDay;

    if (endCompare >= 0) {
      return (
        <>
          <i style={{ color: 'green', marginLeft: '10px' }}>
            <i style={{ color: 'black' }}>Chu kỳ chăm sóc:</i> {formatDay + 1}/{careTime} ngày
          </i>
        </>
      );
    } else {
      return (
        <>
          <WarningAmberIcon
            sx={{
              fontSize: 20,
              color: 'orange',
              marginBottom: '-5px',
            }}
          />
          <i style={{ color: 'orange', marginLeft: '10px' }}>
            <span>Chu kỳ {careTime} -</span> Đã trễ {Math.abs(endCompare)} ngày.
          </i>
        </>
      );
      // return `Đã trể ${Math.abs(endCompare)} ngày chưa chăm sóc`;
    }
  };

  return (
    <div
      className="drag-and-drop"
      style={{
        display: 'flex',
        height: 'calc(100vh - 162px)',
        width: isOpen ? 'calc(100vw - 299px)' : 'calc(100vw - 130px)',

        overflowX: 'auto',
      }}
    >
      <DragDropContext onDragEnd={(result) => onHandleDragEnd(result)}>
        {Object.entries(columns).map(([id, column]) => {
          return (
            <div
              key={id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="b" variant="h7" sx={{ marginTop: '5px' }}>
                <Stack direction="row" spacing={2}>
                  {column.name}
                  <Avatar
                    sx={{
                      bgcolor: '#E0E0E0',
                      color: '#0B409C',
                      width: 23,
                      height: 23,
                      marginInline: '5px',
                      borderRadius: '50%',
                    }}
                    variant="rounded"
                    key={`page-pagination-${column?.items?.length}`}
                  >
                    <b style={{ fontSize: '12px' }}>{column?.items?.length}</b>
                  </Avatar>
                </Stack>
              </Typography>
              {/* ===  Render Search   =====*/}
              {renderSearch(column)}

              {/* ===  Render data  =====*/}
              <div className="column-drag-drop">
                <Droppable droppableId={id}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver ? '#deebff' : '#f5f5f5', //f4f5f7
                          border: snapshot.isDraggingOver ? '2px dashed blue' : null,
                          padding: 4,
                          width: '396px',
                          height: 'calc(100vh - 222px)',
                          position: 'relative',
                          cursor: snapshot.isDraggingOver ? 'all-scroll' : null,
                          borderRadius: '12px',
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => {
                                return (
                                  <Card
                                    className="card-display-in-draggable"
                                    sx={{ minWidth: 250 }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      margin: '5px 5px 10px 5px',
                                      borderRadius: '12px',
                                      backgroundColor: snapshot.isDragging ? '#b0b1b3' : 'white',
                                      border: snapshot.isDragging
                                        ? '1px solid rgb(67, 67, 238)'
                                        : null,
                                      cursor: 'all-scroll',
                                      // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                      boxShadow:
                                        'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <CardHeader
                                      sx={{ padding: '16px 16px 0px 16px' }}
                                      action={
                                        <>
                                          <Tooltip title="Chờ chăm sóc" placement="top-start" arrow>
                                            <IconButton
                                              aria-label="settings"
                                              onClick={() => onChangeColumn(item)}
                                            >
                                              <TelegramIcon />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Chi tiết" placement="top-start" arrow>
                                            <IconButton
                                              aria-label="settings"
                                              onClick={() => onEditFn(item)}
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      }
                                      title={
                                        <Chip
                                          label={`${item?.serviceName}`}
                                          color="primary"
                                          size="small"
                                          sx={{ padding: '0px 10px' }}
                                        />
                                      }
                                      subheader={
                                        <b>
                                          {item?.objClientCompany?.code} -{' '}
                                          {item?.objClientCompany?.name}
                                        </b>
                                      }
                                    />
                                    <CardActions disableSpacing>
                                      <div>
                                        <CallIcon
                                          sx={{ fontSize: 20, color: 'blue', marginBottom: '-5px' }}
                                        />
                                        <Link
                                          href="#"
                                          underline="hover"
                                          sx={{ paddingLeft: '5px' }}
                                        >
                                          {item?.objClientCompany?.arrNumberPhone?.[0]}
                                        </Link>
                                        <div>{renderWarningDateCare(item)}</div>
                                      </div>
                                      <AvatarGroup max={3} sx={{ marginLeft: 'auto' }}>
                                        {item?.arrMainUserobj?.map((item, index) => (
                                          <Tooltip
                                            key={`${item.code} - ${index}`}
                                            title={item.name}
                                            placement="top-start"
                                            arrow
                                          >
                                            <Badge
                                              badgeContent={<RiKey2Line />}
                                              color="secondary"
                                              overlap="circular"
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                              }}
                                            >
                                              <Avatar
                                                alt={item.code}
                                                src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                                              />
                                            </Badge>
                                          </Tooltip>
                                        ))}
                                        {item?.arrSecondaryUserobj?.map((item, index) => (
                                          <Tooltip
                                            key={index}
                                            title={item.name}
                                            placement="top-start"
                                            arrow
                                          >
                                            <Avatar
                                              alt={item.code}
                                              src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                                            />
                                          </Tooltip>
                                        ))}
                                      </AvatarGroup>
                                    </CardActions>
                                  </Card>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>

      <DrawerInformationDetails
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        clientCareHistoryItem={clientCareHistoryItem}
        clientInformationDetail={clientInformationDetail}
        getClientCareHistoryDetails={getClientCareHistoryDetails}
      />
    </div>
  );
};

export { ClientCarelist };
