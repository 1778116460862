export default class User {
  constructor() {
    this.Id = '';
    this.Code = '';
    this.Name = '';
    this.DepartmentId = '';
    this.CompanyId = '';
    this.UserMail = '';
    this.ArrFunction = '';
    this.ArrMenu = '';
    this.token = '';
    this.UrlAvatar = '';
  }

  update(data) {
    let obj = { ...this };
    Object.keys(obj).forEach((item) => {
      this[item] = data[item] || this[item];
    });
  }

  static clone(data) {
    const cloneData = new User();
    cloneData.update(data);
    return cloneData;
  }
}
