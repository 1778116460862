import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearServiceToQuote,
  clearTruckingService,
  clearCustomsService,
} from 'store/reducers/customerQuotes/action';
import { styled } from '@mui/material/styles';
import { ModalFormDetail } from 'components';
import { LoadingView } from 'components/loading-view';
import { Box, Badge, IconButton, Popover, ToggleButton } from '@mui/material';
import { ShoppingCart as ShoppingCartIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { SERVICES_ID, SERVICES_OPTION } from 'commons/constants';
import { InformationGeneral } from './information-general';
import { ContainerContent, StyledToggleButtonGroup } from './styles';
import { FreightService } from './freight-service';
import { CustomsService } from './customs-service';
import { TruckingService } from './trucking-service';
import { WarehouseService } from './warehouse-service';
import { FreightControlQuoteForm } from './form-control-quote/freight-control-quote-form';
import { TruckingControlQuoteForm } from './form-control-quote/trucking-control-quote-form';
import { CustomsControlQuoteForm } from './form-control-quote/customs-control-quote-form';
import { WarehouseControlQuoteForm } from './form-control-quote/warehouse-control-quote-form';
import { MergeCustomsAndTruckingForm } from './form-control-quote/merge-customs-and-trucking-form';
import { RenderItemSelectedList } from './render-item-selected-list';

export const FormDetail = ({ selected, onCloseModal, initData }) => {
  const dispatch = useDispatch();
  const { FREIGHT, TRUCKING, CUSTOMS, WAREHOUSE } = SERVICES_ID;
  const { itemServiceSelectedToQuote, itemTruckingSelected, itemCustomsSelected } = useSelector(
    (state) => state.customerQuotes,
  );

  const [loading, setLoading] = useState(false);
  const [selectActionPrice, setSelectActionPrice] = useState(false);
  const [selectService, setSelectService] = useState(FREIGHT);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const countSelected = useMemo(() => {
    let count = 0;
    if (itemServiceSelectedToQuote || itemTruckingSelected || itemCustomsSelected) count = 1;
    if (itemTruckingSelected && itemCustomsSelected) count = 2;
    return count;
  }, [itemServiceSelectedToQuote, itemTruckingSelected, itemCustomsSelected]);

  const handleClose = () => {
    setSelectActionPrice(false);
    setSelectService(FREIGHT);
    onCloseModal();
  };

  const handleActionPrice = (event, actionPrice) => {
    if (actionPrice === null) return;
    setSelectActionPrice((pre) => actionPrice);
  };
  const handleSelectService = (event, serviceId) => {
    if (serviceId === null) return;
    setSelectService(serviceId);
  };

  const renderService = useMemo(() => {
    switch (selectService) {
      case TRUCKING:
        return <TruckingService />;
      case CUSTOMS:
        return <CustomsService />;
      case WAREHOUSE:
        return <WarehouseService />;
      default:
        return <FreightService />;
    }
  }, [selectService]);

  const renderSelectItemQuote = () => (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <StyledToggleButtonGroup
            size="small"
            color="primary"
            value={selectService}
            exclusive
            onChange={handleSelectService}
            aria-label="select service"
          >
            {SERVICES_OPTION?.map((item, index) => (
              <ToggleButton
                key={`${item?.value}-${index}`}
                value={item?.value}
                aria-label={item?.label}
              >
                {item?.label}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      </Box>
      {renderService}
    </>
  );

  const renderControlQuote = () => {
    console.log({ selected });
    if (itemTruckingSelected && itemCustomsSelected) {
      return (
        <MergeCustomsAndTruckingForm
          handleCloseAfterSubmit={handleClose}
          initData={initData}
          selected={selected}
        />
      );
    } else if (itemTruckingSelected) {
      return (
        <TruckingControlQuoteForm
          handleCloseAfterSubmit={handleClose}
          initData={initData}
          selected={selected}
        />
      );
    } else if (itemCustomsSelected) {
      return (
        <CustomsControlQuoteForm
          handleCloseAfterSubmit={handleClose}
          initData={initData}
          selected={selected}
        />
      );
    } else if (itemServiceSelectedToQuote?.serviceId === FREIGHT) {
      return (
        <FreightControlQuoteForm
          handleCloseAfterSubmit={handleClose}
          initData={initData}
          selected={selected}
        />
      );
    } else if (itemServiceSelectedToQuote?.serviceId === WAREHOUSE) {
      return (
        <WarehouseControlQuoteForm
          handleCloseAfterSubmit={handleClose}
          initData={initData}
          selected={selected}
        />
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleDeleteItemSelectToQuote = () => {
    dispatch(clearServiceToQuote());
  };
  const handleDeleteItemTruckingSelected = () => {
    dispatch(clearTruckingService());
  };
  const handleDeleteItemCustomsSelected = () => {
    dispatch(clearCustomsService());
  };

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={handleClose}
        title={selected?.isNew ? 'Tạo mới báo giá' : 'Thông tin chi tiết'}
      >
        <InformationGeneral selected={selected} />
        <Box>
          <i style={{ color: 'blue' }}>Nội dung báo giá:</i>
        </Box>
        {selected?.isNew ? (
          <>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <StyledToggleButtonGroup
                  size="small"
                  color="primary"
                  value={selectActionPrice}
                  exclusive
                  onChange={handleActionPrice}
                  aria-label="Action price"
                >
                  <ToggleButton value={false} aria-label="Tìm giá">
                    Tìm giá
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    aria-label="Xem báo giá"
                    // disabled={!itemServiceSelectedToQuote}
                  >
                    Xem báo giá
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    aria-label="Xem báo giá"
                    // disabled={!itemServiceSelectedToQuote}
                  >
                    Gộp báo giá
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Box>
              <Box sx={{ marginLeft: 'auto' }}>
                <i
                  style={{
                    color: 'blue',
                  }}
                >
                  Đã chọn:
                </i>
                <IconButton aria-label="cart" onClick={handleClick}>
                  <StyledBadge badgeContent={countSelected} color="secondary">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
                {countSelected > 0 && (
                  <Popover
                    id="simple-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {itemServiceSelectedToQuote && (
                      <RenderItemSelectedList
                        item={itemServiceSelectedToQuote}
                        onDelete={handleDeleteItemSelectToQuote}
                      />
                    )}
                    {itemTruckingSelected && (
                      <RenderItemSelectedList
                        item={itemTruckingSelected}
                        isTruckingOrCustom
                        onDelete={handleDeleteItemTruckingSelected}
                      />
                    )}
                    {itemCustomsSelected && (
                      <RenderItemSelectedList
                        item={itemCustomsSelected}
                        isTruckingOrCustom
                        onDelete={handleDeleteItemCustomsSelected}
                      />
                    )}
                  </Popover>
                )}
              </Box>
            </Box>
            <ContainerContent>
              {!selectActionPrice ? renderSelectItemQuote() : renderControlQuote()}
            </ContainerContent>
          </>
        ) : (
          <ContainerContent>{renderControlQuote()}</ContainerContent>
        )}

        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: 2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
