import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button } from '@mui/material';

export const Columns = (handleSearchColumn, deleteItem) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã" name="code" onChange={handleSearchColumn} />,
    width: '100px',
    align: 'center',
  },
  {
    id: 'name',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Hình thức giao hàng" name="name" onChange={handleSearchColumn} />
    ),
    align: 'left',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    width: '10px',
    fixed: 'right',
    label: 'Thao tác',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
