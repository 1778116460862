import actionTypes from './type';
import storage from 'storage';

const initialState = {
  chanels: [],
  personalChanels: [],
  selectedChanel: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHANEL:
      if (state.selectedChanel?.isPersonal) {
        console.log('selectedChanel - isPersonal');
        return {
          ...state,
          chanels: action.chanels,
        };
      }
      var ind = action.chanels.findIndex((item) => item._id === state.selectedChanel._id);
      var selectedChanel = {};

      if (ind != -1) {
        selectedChanel = action.chanels[ind];
      } else {
        selectedChanel =
          !state.selectedChanel?._id && action.chanels?.length
            ? action.chanels[0]
            : state.selectedChanel;
      }
      console.log('selectedChanel - is not Personal', selectedChanel);

      return {
        ...state,
        selectedChanel,
        chanels: action.chanels,
      };

    case actionTypes.FETCH_PERSONAL_CHANEL:
      var ind = action.chanels.findIndex((item) => item._id === state.selectedChanel._id);
      if (ind != -1) {
        return {
          ...state,
          selectedChanel: action.chanels[ind],
          personalChanels: action.chanels,
        };
      }
      return {
        ...state,
        personalChanels: action.chanels,
      };
    case actionTypes.UPDATE_SELECTED_CHANEL:
      return {
        ...state,
        selectedChanel: action.chanel,
      };
    case actionTypes.ADD_CHANEL:
      const chanelFind = state.chanels.find((item) => item._id == action.chanel._id);
      if (chanelFind) {
        return state;
      }
      var chanels = [action.chanel];
      for (var chanel of state.chanels) {
        chanels.push(chanel);
      }
      return {
        ...state,
        chanels,
      };
    case actionTypes.DELETE_CHANEL:
      var chanels = state.chanels.filter((item) => item._id !== action.chanelId);
      return { ...state, chanels, selectedChanel: chanels[0] };

    case actionTypes.ADD_MEMBER:
      var ind = state.chanels.findIndex((el) => el._id == action.chanel._id);
      var newChanels = [...state.chanels];
      if (ind === -1) {
        newChanels.unshift(action.chanel);
        return {
          ...state,
          chanels: newChanels,
        };
      }
      if (state.selectedChanel._id === action.chanel._id) {
        return { ...state, selectedChanel: action.chanel };
      }

      newChanels[ind] = action.chanel;
      return { ...state, chanels: newChanels };

    case actionTypes.REMOVE_MEMBER:
      if (state.selectedChanel._id !== action.chanel._id) return state;
      if (storage.user?.Code === action.memberId) {
        var chanels = state.chanels.filter((item) => item._id !== action.chanel._id);
        return { ...state, chanels, selectedChanel: chanels[0] };
      }
      return {
        ...state,
        selectedChanel: action.chanel,
      };

    case actionTypes.UPDATE_CHANEL:
      if (!action.chanel?.isPersonal) {
        var chanels = state.chanels.map((item) => item);
        var index = chanels.findIndex((el) => el._id == action.chanel?._id);
        if (index !== -1) {
          chanels[index] = action.chanel;
        }
        return {
          ...state,
          chanels,
        };
      }
      var chanels = state.personalChanels.map((item) => item);
      var index = chanels.findIndex((el) => el._id == action.chanel?._id);
      if (index !== -1) {
        chanels[index] = action.chanel;
      }
      return {
        ...state,
        personalChanels: chanels,
      };
    default:
      return state;
  }
}
