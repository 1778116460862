import * as React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Collapse,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ExpandLess, ExpandMore, Circle as CircleIcon } from '@mui/icons-material';
import { NavLink as Link, matchPath, useLocation, useHistory } from 'react-router-dom';
import RenderIcon from 'commons/render-icons';

const NavLink = ({ children, to, selected }) => {
  const theme = useTheme();
  return (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
        color: 'inherit',
        ...(selected && {
          color: theme?.palette?.primary?.main,
        }),
      }}
    >
      {children}
    </Link>
  );
};
const ListItemIcon = ({ selected, white, ...props }) => (
  <MuiListItemIcon
    {...props}
    sx={{
      ...(selected && white
        ? {
            color: 'background.paper',
          }
        : selected && {
            color: 'primary.main',
          }),
    }}
  />
);
const ListItemText = ({ selected, white, sx, ...props }) => (
  <MuiListItemText
    {...props}
    sx={{
      ...sx,
      ...(selected && white
        ? {
            color: 'background.paper',
          }
        : selected && {
            color: 'primary.main',
          }),
    }}
  />
);

const Item = ({ icon, title, active, onclick, subItems, open, isSubItem = false, ...props }) => (
  <ListItemButton
    onClick={onclick}
    sx={{
      borderRadius: '25px',
      ...(active && {
        backgroundColor: 'primary.main',
        marginInline: '5px',
        '&:hover': {
          backgroundColor: 'primary.main',
        },
      }),
    }}
  >
    {icon && (
      <ListItemIcon selected={active} white>
        {/* {renderIcon(icon)} */}
        {RenderIcon.renderIcons(icon)}
      </ListItemIcon>
    )}
    <ListItemText selected={active} primary={title} white />
    {isSubItem ? (
      open ? (
        <ExpandLess sx={active && { color: 'background.paper' }} />
      ) : (
        <ExpandMore sx={active && { color: 'background.paper' }} />
      )
    ) : null}
  </ListItemButton>
);
function ItemMenu({
  id,
  title,
  icon,
  link,
  subItems = [],
  isOpenNav,
  activeKey,
  setActiveKey,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  const isActiveKey = React.useMemo(() => {
    return activeKey && activeKey === id;
  }, [activeKey]);

  React.useEffect(() => {
    setOpen(isActiveKey);
  }, [isActiveKey]);

  React.useEffect(() => {
    for (let item of subItems) {
      if (checkMatchPath(item.link)) {
        setActive(true);
        return;
      }
      setActive(false);
    }
  }, [location?.pathname]);
  const checkMatchPath = (pathName) => {
    return matchPath(pathName, {
      path: location?.pathname,
      exact: true,
      strict: false,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
    setActiveKey(id);
  };

  const handleSubItemClick = (item) => {
    history.push(item.link);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'open-menu-slide-bar';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {subItems.map((item) => (
        <NavLink to={item.link} key={item.key} selected={Boolean(checkMatchPath(item.link))}>
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
          >
            {item.title}
          </MenuItem>
        </NavLink>
      ))}
    </Menu>
  );
  if (!subItems?.length) {
    return (
      <NavLink to={link}>
        <Item {...{ icon, title, active }} />
      </NavLink>
    );
  }

  if (!isOpenNav) {
    return (
      <>
        <Item {...{ icon, title, active }} onclick={handleProfileMenuOpen} />
        {renderMenu}
      </>
    );
  }

  return (
    <>
      <Item {...{ icon, title, active, open, subItems }} isSubItem onclick={handleClick} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map((item) => {
            const isActive = Boolean(checkMatchPath(item.link));
            return (
              <Box key={item.key} onClick={() => handleSubItemClick(item)}>
                <ListItemButton
                  sx={{
                    borderRadius: '25px',
                    ...(isActive
                      ? {
                          pl: 5,
                        }
                      : {
                          pl: 8,
                        }),
                  }}
                >
                  {isActive ? (
                    <CircleIcon color="primary" sx={{ fontSize: 10, marginRight: '13px' }} />
                  ) : null}
                  <ListItemText
                    primary={item.title}
                    selected={isActive}
                    sx={{
                      fontWeight: 'bold',
                    }}
                  />
                </ListItemButton>
              </Box>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export { ItemMenu };
