import React, { useEffect, useState } from 'react';
import { Table, SearchInput, LoadingView } from 'components';
import { Box, Paper, Typography, Grid, IconButton } from '@mui/material';
import {
  Add as AddIcon,
  PermContactCalendar as PermContactCalendarIcon,
  Email as EmailIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@mui/icons-material';
import { TaskSummary } from './task-sumary';
import { TaskListLatest } from './task-list-latest';
import { styled } from '@mui/material/styles';
import ApiServices from 'services';
import { useHistory } from 'react-router-dom';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { HomeSummary } from '../home-summary';
import { Helmet } from 'react-helmet';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  backgroundColor: 'background.paper',
  color: theme.palette.text.secondary,
  borderRadius: '20px',
  minHeight: '7rem',
  // padding: '15px',
  display: 'flex',
  boxShadow: 'none',
  position: 'relative',
  alignItems: 'center',
}));
const HomePage = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const history = useHistory();
  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getHomeData();
      setHomeData(data);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => getData(), []);
  const dataRender = [
    {
      icon: <PermContactCalendarIcon fontSize="large" sx={{ color: 'background.paper' }} />,
      title: 'LỊCH HẸN KH',
      value: homeData?.countScheduleUser || 0,
      subTitle: 'Nội dung lịch hẹn KH',
    },
    {
      icon: <EmailIcon fontSize="large" sx={{ color: 'background.paper' }} />,
      title: 'TIN NHẮN MỚI',
      value: homeData?.countMailUnseen || 0,
      subTitle: 'Email chưa đọc',
    },
    {
      icon: <InsertDriveFileIcon fontSize="large" sx={{ color: 'background.paper' }} />,
      title: 'QUẢN LÝ TÀI LIỆU',
      value: '-',
      subTitle: 'Cập nhật tài liệu của bạn',
      link: '/documents',
    },
  ];
  const renderChangeHomePageButton = () => {
    return (
      <Box sx={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 1 }}>
        <IconButton onClick={() => setIsSummary(!isSummary)}>
          <ChangeCircleIcon sx={{ color: 'primary.main', width: '40px', height: '40px' }} />
        </IconButton>
      </Box>
    );
  };
  const renderContent = () => {
    if (isSummary) {
      return <HomeSummary />;
    }
    return (
      <Grid container spacing={2}>
        {dataRender.map(({ icon, title, value, subTitle, link }) => (
          <Grid item xs={12} md={4} key={`Statistical-${title}`}>
            <Item
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                if (link) {
                  history.push(link);
                }
              }}
            >
              <Box
                sx={{
                  height: 'fit-content',
                  backgroundColor: 'primary.main',
                  p: '10px',
                  borderRadius: '15px',
                  justifyContent: 'center',
                  display: 'flex',
                  mr: '10px',
                }}
              >
                {icon}
              </Box>
              <Box>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ color: 'tex.primary', fontWeight: '400' }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ color: 'primary.main', fontWeight: 'bolder' }}
                >
                  {value}
                </Typography>
                <Typography variant="h7" component="div" sx={{ color: 'text.primary' }}>
                  {subTitle}
                </Typography>
              </Box>
            </Item>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h7" component="div" sx={{ color: 'text.primary' }}>
            <b>Danh sách công việc</b>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <TaskSummary deals={homeData?.arrDeal} leads={homeData?.arrLead} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TaskListLatest />
        </Grid>
      </Grid>
    );
  };
  return (
    <Box sx={{ width: '100%', position: 'relative', minHeight: '100vh' }}>
      <LoadingView loading={loading} />
      <Helmet>
        <title>Trang Chủ | U&I Logistics</title>
      </Helmet>
      {renderChangeHomePageButton()}
      {renderContent()}
    </Box>
  );
};

export { HomePage };
