import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import Utils from 'commons/utils';
import { RenderCheckPriceStatus } from 'components/custom-status';

const HeaderFormDetail = ({ selected }) => {
  return (
    <Box sx={{ marginBottom: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <b>Trạng thái: </b> <RenderCheckPriceStatus status={selected?.statusCheck} />
        </Grid>
        <Grid item xs={4}>
          <b>Ngày tạo: </b> {Utils.formatDateFn(selected?.createdOn)}
        </Grid>
        <Grid item xs={4}>
          <b>Người tạo: </b> {selected?.createdBy}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(HeaderFormDetail);
