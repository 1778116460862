import ApiClient from './api-client';
// import Utils from '../commons/utils';
import Endpoint from './endpoints';
const host =
  process.env.NODE_ENV === 'develop'
    ? 'http://localhost:6612/'
    : process.env.REACT_APP_CHAT_API_URL;
class ChatServices {
  constructor() {
    this.apiClient = new ApiClient({
      baseURL: host,
      logOutput: true,
    });
  }
  getEnums = () => this.apiClient.get(Endpoint.chatEnum);
  getChanel = (query = {}) => this.apiClient.get(Endpoint.chatChanel, query);
  getPersonalChanel = () => this.apiClient.get(Endpoint.personalChanel);
  createChanel = (data) => this.apiClient.post(Endpoint.chatChanel, data);
  deleteChanel = (chanelId) => this.apiClient.delete(`${Endpoint.chatChanel}/${chanelId}`);
  getChanelById = (chanelId) => this.apiClient.get(`${Endpoint.chatChanel}/${chanelId}`);
  outGroup = (chanelId) => this.apiClient.delete(`${Endpoint.chatChanel}/${chanelId}/out-group`);
  //Messages
  getMessages = (chanelId, query = {}) =>
    this.apiClient.get(`${Endpoint.chatChanel}/${chanelId}${Endpoint.chatMessages}`, query);
  getMessageById = (chanelId, messageId) =>
    this.apiClient.get(`${Endpoint.chatChanel}/${chanelId}${Endpoint.chatMessages}/${messageId}`);
  createMessage = (data) => this.apiClient.post(Endpoint.chatMessages, data);
  removeMember = (chanelId, memberCode) =>
    this.apiClient.delete(`${Endpoint.chatChanel}/${chanelId}/remove-member/${memberCode}`);
  addMembers = (chanelId, data) =>
    this.apiClient.postCustom(`${Endpoint.chatChanel}/${chanelId}/add-members`, data);
  readMessage = (messageId, data = {}) =>
    this.apiClient.post(`${Endpoint.chatMessages}/${messageId}/read-message`, data);
  //Invite
  generateInviteLink = (chanelId, data) =>
    this.apiClient.post(`${Endpoint.chatChanel}/${chanelId}/invite-group`, data);
  addMemberByInviteLink = (token) =>
    this.apiClient.get(`${Endpoint.chatChanel}/invite-group-by-token/${token}`);
}
const ChatService = new ChatServices();
export default ChatService;
