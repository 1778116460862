import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Divider, IconButton, Drawer as MuiDrawer, List, Typography, Menu } from '@mui/material';

import ApiServices from 'services';

import { ItemMenu } from './item-menu';
import { dataItems } from './data-items';
import { useHistory } from 'react-router-dom';
import LogoImg from 'assets/img/logo.png';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      '& .MuiDrawer-paper::-webkit-scrollbar': {
        width: '10px',
        background: '#e0e0e0',
      },
      '& .MuiDrawer-paper::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey',
        borderRadius: '15px',
      },
      // '& .MuiDrawer-paper::-webkit-scrollbar-thumb:hover': { background: '#555' },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function SlideBar({ isOpen, open, close, DrawerHeader, ...props }) {
  const theme = useTheme();
  let history = useHistory();
  const [activeKey, setActiveKey] = React.useState('');
  const [dataMenus, setDataMenus] = React.useState([]);

  const getMenus = async () => {
    try {
      const { data } = await ApiServices.getMenus();
      if (data) {
        var filterParenn = data.filter((item) => item.parent === '');
        var filterChild = data.filter((item) => item.parent !== '');
        const sortParent = filterParenn.sort(function (a, b) {
          return parseFloat(a.sort) - parseFloat(b.sort);
        });

        const results = sortParent.map((parent, index) => {
          var mapdata = filterChild.filter((child) => {
            if (child.parent === parent.code) {
              return child;
            }
          });
          return {
            key: parent.id,
            code: parent.code,
            link: parent.url,
            title: parent.name,
            icon: parent.icon,
            subItems: mapdata.map((item) => {
              return {
                key: item.id,
                title: item.name,
                link: item.url,
              };
            }),
          };
        });
        setDataMenus(results);
      }
    } catch (err) {
      console.warn('initData - menus Process', err);
    }
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <Drawer variant="permanent" open={isOpen}>
      {!isOpen ? (
        <DrawerHeader sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={LogoImg}
            onClick={() => history.push('/')}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              height: '35px',
              cursor: 'pointer',
            }}
          />
        </DrawerHeader>
      ) : (
        <DrawerHeader sx={{ display: 'flex', justifyContent: 'center' }}>
          {/* <img
            src={LogoImg}
            onClick={() => history.push('/')}
            style={{
              // width: '100%',
              height: 'auto',
              objectFit: 'contain',
              height: '35px',
              cursor: 'pointer',
            }}
          /> */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ cursor: 'pointer', color: 'text.primary', mr: '10px', fontWeight: 'bold' }} //primary.main
            onClick={() => history.push('/')}
          >
            {isOpen && 'U&I Logistics'}
          </Typography>
          {/* <IconButton onClick={close}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </DrawerHeader>
      )}
      <Divider />

      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {dataMenus?.map((item) => (
          <ItemMenu
            key={item.key}
            {...item}
            id={item.key}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            isOpenNav={isOpen}
          />
        ))}
      </List>
    </Drawer>
  );
}

export { SlideBar };
