import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Link,
  Button,
  Avatar,
  Stack,
  Collapse,
} from '@mui/material';

import {
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
  BsFillCaretUpFill,
  BsTrash,
} from 'react-icons/bs';
import moment from 'moment';
import { LoadingView } from '../loading-view';
// import { LoadingView, SearchInput } from 'components';
import { visuallyHidden } from '@mui/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const sum = Math.ceil(count / rowsPerPage);

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, sum - 1));
  };

  let pageArea = [];
  // var i = page - 2 < 0 ? 0 : page - 2;
  // i < page + 1 > sum ? sum : page + 1;
  // i++

  const startPage = page - 2 < 0 ? 0 : page - 2;
  const endPage = page + 3 >= sum ? sum : page + 3;
  for (let i = startPage; i < endPage; i++) {
    if (i !== page) {
      pageArea.push(
        <Box
          sx={{
            padding: '10px',
            paddingBlock: '5px',
            borderRadius: 'shape.borderRadius',

            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
            },
          }}
          key={`page-pagination-${i}`}
          onClick={(event) => onPageChange(event, i)}
        >
          {i + 1}
        </Box>,
      );
      continue;
    }
    pageArea.push(
      <Avatar
        sx={{
          bgcolor: '#21409A',
          width: 25,
          height: 25,
          marginInline: '5px',
        }}
        variant="rounded"
        key={`page-pagination-${i}`}
      >
        <b style={{ fontSize: '12px' }}>{i + 1}</b>
      </Avatar>,
    );
  }
  if (endPage < sum) {
    pageArea.push(
      <Box
        sx={{
          padding: '10px',
          paddingBlock: '5px',
          borderRadius: 'shape.borderRadius',
        }}
        key={`page-pagination-more`}
      >
        ...
      </Box>,
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ mr: 0, ml: 0 }}
    >
      <Button
        variant="outlined"
        sx={{
          bgcolor: '#DFF2FF',
          padding: 0,
          minWidth: '25px',
          height: 25,
          marginInline: '5px',
        }}
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        <BsFillCaretLeftFill sx={{ fontSize: '14px' }} />
      </Button>
      {pageArea}
      <Button
        variant="outlined"
        sx={{
          bgcolor: '#DFF2FF',
          padding: 0,
          minWidth: '25px',
          height: 25,
          marginInline: '5px',
        }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <BsFillCaretRightFill sx={{ fontSize: '14px' }} />
      </Button>
    </Stack>
  );
}

function labelDisplayedRowsFn({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`} items`;
}
//=================================================================

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  ...props
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* =================== CheckBox ================================ */}
        <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: '#FFFFFF',
            // borderTop: '1px solid #EAEAEA',
            // borderBottom: '1px solid #EAEAEA',
            // boxSizing: 'border-box',
            color: '#343A40',
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {/* <TableCell
          sx={{
            minWidth: 50,
            backgroundColor: "#FFFFFF",
            color: "#343A40",
          }}
          padding="checkbox"
        >
          <b>STT</b>
        </TableCell> */}

        {headCells.map((headCell) => {
          const tmpStyle = {};
          if (headCell.fixed) {
            tmpStyle.position = 'sticky';
            tmpStyle[headCell.fixed] = 0;
          }
          return (
            <TableCell
              key={`Cell-${headCell.id}`}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={headCell?.sort && orderBy === headCell.id ? order : false}
              sx={{
                minWidth: headCell?.width || 170,
                backgroundColor: '#FFFFFF',
                color: '#343A40',
                ...tmpStyle,
              }}
            >
              {!headCell?.sort ? (
                <b>{headCell.label}</b>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={BsFillCaretUpFill}
                >
                  <b>{headCell.label}</b>
                  {/* <div style={{ display: "flex", justifyContent: "flex-end" }}> */}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                  {/* </div> */}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, render, renderSelect } = props;
  const renderSelected = () => {
    if (numSelected > 0) {
      return (
        <>
          {/* ===================  CheckBox ================================ */}
          <Typography sx={{ flex: '1 1 ' }} color="inherit" variant="subtitle1" component="div">
            Đã chọn {numSelected}
          </Typography>
          {renderSelect && renderSelect()}
        </>
      );
    }
    return render && render();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        flexWrap: 'wrap',
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {renderSelected()}
    </Toolbar>
  );
};

export const TableCustomForLead = ({
  rows = [],
  headCells = [],
  totalRecords,
  onPageChange,
  sizeTable = 'small',
  isHeader = true,
  isOpenCollapse,
  ...props
}) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    props?.onSelectedChange && props.onSelectedChange(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange &&
      onPageChange({
        pageNumber: newPage + 1,
        pageSize: rowsPerPage,
      });
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = event.target.value;
    setRowsPerPage(parseInt(pageSize, 10));
    setPage(0);
    onPageChange &&
      onPageChange({
        pageNumber: 0,
        pageSize: pageSize,
      });
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const renderRows = (row, index) => {
    {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${page}-${index}`;
      return (
        <React.Fragment>
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{
              backgroundColor: '#FFF',
              '&.MuiTableRow-hover:hover .mui-cell-fixed': {
                backgroundColor: '#DFF2FF',
              },
              '&.MuiTableRow-hover:hover': {
                backgroundColor: '#DFF2FF',
              },
              '&.MuiTableRow-root.Mui-selected': {
                backgroundColor: '#DFF2FF',
              },
              '&.MuiTableRow-root.Mui-selected .mui-cell-fixed': {
                backgroundColor: '#DFF2FF',
              },
            }}
          >
            {/* =================== CheckBox ================================ */}
            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </TableCell>
            {/* <TableCell padding="checkbox">{index + 1}</TableCell> */}

            {headCells.map((item, index) => {
              const tmpStyle = {};
              if (item.fixed) {
                tmpStyle.backgroundColor = 'fff';
                tmpStyle.position = 'sticky';
                tmpStyle[item.fixed] = 0;
                tmpStyle.backgroundColor = 'background.paper';
              }
              if (index === 0) {
                return (
                  <TableCell
                    key={`${item.id}-${row.id}`}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={tmpStyle}
                  >
                    {renderFirstItem(item, row)}
                  </TableCell>
                );
              }

              return (
                <TableCell
                  className={item.fixed ? 'mui-cell-fixed' : 'mui-cell'}
                  sx={tmpStyle}
                  key={`${item.id}-${row.id}`}
                  align={item?.align || 'center'}
                >
                  {item.render
                    ? item.render(row)
                    : item?.isDateFormat && row[item.id]
                    ? moment(row[item.id]).format('DD/MM/YYYY')
                    : row[item.id]}
                </TableCell>
              );
            })}
            {/* <TableCell className="mui-cell-fixed">
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell> */}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div" sx={{ background: 'blue' }}>
                    History
                  </Typography>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const renderFirstItem = (item, row) => {
    if (props?.onClickDetail) {
      return (
        <Link
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={() => props?.onClickDetail(row)}
        >
          {item.render ? item.render(row) : row[item.id]}
        </Link>
      );
    }
    return item.render ? item.render(row) : row[item.id] || 'underfine';
  };
  return (
    <>
      <Paper sx={{ width: '100%', position: 'relative' }}>
        <LoadingView loading={props?.loading} />
        <Box>
          <Typography
            sx={{ flex: '1 1', fontWeight: 'bold', marginLeft: '20px', paddingTop: '5px' }}
            variant="h7"
            id="tableTitle"
            component="div"
          >
            {props?.title}
          </Typography>
        </Box>
        {/* =================== Heder ================================ */}
        {isHeader && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            render={props?.renderHeader}
            renderSelect={props?.renderSelect}
          />
        )}
        <TableContainer
          sx={{
            borderRadius: '12px',
            maxHeight: 'calc(100vh - 235px)',
          }}
        >
          <MuiTable
            sx={{ minWidth: 500 }}
            stickyHeader
            aria-labelledby="tableTitle"
            // size={props?.isDense ? "small" : "medium"}
            size={sizeTable}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                //  rows.slice().sort(getComparator(order, orderBy)) */}

              {!onPageChange
                ? stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => renderRows(row, index))
                : stableSort(rows, getComparator(order, orderBy)).map((row, index) =>
                    renderRows(row, index),
                  )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: props.isDense ? 33 * emptyRows : 'auto',
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 100]}
          component="span"
          count={totalRecords ? totalRecords : rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          // labelDisplayedRows={labelDisplayedRowsFn}
          // rowsPerPageOptions={-1}
          labelRowsPerPage="Số dòng"
          showFirstButton={false}
          sx={{
            width: '100vw',
            marginLeft: 0,
            padding: 0,
            maxHeight: 'calc(100vh - 350px)',
            '& .MuiTablePagination-selectLabel': {},
            '& .MuiTablePagination-displayedRows': {
              display: 'none',
            },
            // '.MuiTablePagination-spacer': {
            //   flex: 'none',
            // },
            '.MuiTablePagination-select': {
              borderRadius: '4px',
              border: '1px solid #DFDFDF',
            },
            '.MuiInputBase-colorPrimary': {
              margin: '10px',
            },
            // ".MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select":
            //   {
            //     marginRight: "5px",
            //   },
          }}
        />
      </Paper>
    </>
  );
};
