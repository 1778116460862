import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createsubService(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updatesubService(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : 'Thông tin chi tiết'}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            serviceId: '',
            companyId: '',
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required('Vui lòng nhập mã'),
            name: Yup.string().max(255).required('Vui lòng nhập địa điểm'),
            serviceId: Yup.string().max(255).required('Vui lòng chọn dịch vụ'),
            companyId: Yup.string().max(255).required('Vui lòng chọn công ty'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Dịch vụ phụ"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <Selection
                required
                error={Boolean(touched.serviceId && errors.serviceId)}
                helperText={touched.serviceId && errors.serviceId}
                label="Dịch vụ"
                name="serviceId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serviceId}
                syncData={ApiServices.getServices}
              />
              <Selection
                required
                error={Boolean(touched.companyId && errors.companyId)}
                helperText={touched.companyId && errors.companyId}
                label="Công ty"
                name="companyId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyId}
                syncData={ApiServices.getCompanies}
              />
              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Ghi chú"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
