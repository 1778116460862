import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';
import Utils from 'commons/utils';

export { Mails };
function Mails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getMails();
      setData(data || []);
    } catch (err) {
      console.warn('initData - emails', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.deleteMail(row.id);
      window.UIMessage?.success('Xoá thành công');
      initData();
    } catch (err) {
      window.UIMessage?.error('Xoá thất bại');
      console.warn('deleteCell - emails', err);
    }
    setLoading(false);
  };

  const headCells = [
    {
      id: 'subject',
      numeric: false,
      disablePadding: true,
      label: 'Chủ Đề',
      width: 10,
      sort: true,
      align: 'left',
    },
    {
      id: 'to',
      numeric: true,
      disablePadding: false,
      label: 'Người nhận',
      width: 400,
      align: 'left',
      render: (row) => Utils.showArrayInTale(row.to),
    },
    {
      id: 'from',
      disablePadding: false,
      label: 'Người gửi',
      align: 'left',
    },
    {
      id: 'bcc',
      disablePadding: true,
      label: 'Bcc',
      align: 'left',
      render: (row) => Utils.showArrayInTale(row.bcc),
    },
    {
      id: 'cc',
      disablePadding: false,
      label: 'Cc',
      align: 'left',
      render: (row) => Utils.showArrayInTale(row.cc),
    },
    {
      id: 'body',
      disablePadding: false,
      label: 'Nội Dung',
      align: 'left',
    },
    {
      id: 'isSend',
      isDateFormat: true,
      disablePadding: false,
      label: 'Trạng Thái',
      align: 'left',
      render: (row) => <>{row?.isSend ? 'Đã gửi' : 'chưa gửi'}</>,
    },
    {
      id: 'timeSend',
      isDateFormat: true,
      disablePadding: false,
      label: 'Ngày gửi',
      align: 'left',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Thao tác',
      align: 'left',
      width: '10px',
      fixed: 'right',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data}
        headCells={headCells}
        onClickDetail={(row) => {
          setSelected(row);
        }}
        title="Danh Sách Mails"
        loading={loading}
        renderHeader={() => {
          return (
            <>
              <SearchInput sx={{ mr: 2 }} />
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  mr: 2,
                }}
              >
                {/* <Stack direction="row" spacing={1}> */}
                <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                  <BsFilter />
                </IconButton>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowDownCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px',
                    marginLeft: '8px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '0px 8px',
                  }}
                >
                  Nhập excel
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowUpCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px',
                    marginLeft: '8px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '0px 8px',
                  }}
                >
                  Tải excel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<BsPlus />}
                  sx={{
                    borderRadius: '20px',
                    // padding: "7px 15px",
                    textTransform: 'none',
                    marginLeft: '8px',
                    color: '#FFFFFF',
                    backgroundColor: '#21409A',
                    '.MuiButton-endIcon': {
                      marginLeft: '0px',
                    },
                    padding: '0px 10px',
                  }}
                  onClick={() => setSelected({ isNew: true })}
                >
                  Thêm mới
                </Button>
                {/* </Stack> */}
              </Box>
            </>
          );
        }}
      />
      <FormDetail {...{ selected, setSelected }} reload={initData} />
    </Box>
  );
}
