import React, { useCallback, useEffect } from 'react';
import { Box, Tooltip, FormGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import { useSelector, useDispatch } from 'react-redux';
import { updateResCheckPrice } from 'store/reducers/res-check-price/action';
import { Selection, TextInput, FreightPriceForm, FreightSubChargeForm, NumberFormatCustom } from 'components';
import Utils from 'commons/utils';
import { SUB_SERVICES_ID } from 'commons/constants';
import {
  Main,
  Container,
  HeaderForm,
  ContainerBody,
  CardAction,
  ContainerAction,
  ButtonWrapper,
  ErrorText,
} from './styles';

const ARRAY_BODY_ITEM = ['Giá cước'];

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      onAdd,
      values,
      itemData,
      contTypeCodeCheck,
      packingAirCodeCheck,
      dataFeeGroupList,
    },
    ref,
  ) => {
    const { SEA_FCL, SEA_LCL, AIR } = SUB_SERVICES_ID;
    const dispatch = useDispatch();
    const { unitList, carrierList } = useSelector((state) => state.categoryData);
    const [carrierId, setCarrierId] = React.useState('');
    const [carrierName, setCarrierName] = React.useState('');
    const [etd, setEtd] = React.useState('');
    const [expirydate, setExpirydate] = React.useState('');
    const [arrContFreight, setArrContFreight] = React.useState([]);
    const [arrPackingAirFreight, setArrPackingAirFreight] = React.useState([]);
    const [groupFeeGroup, setGroupFeeGroup] = React.useState([]);
    const [arrSurchargeFreight, setArrSurchargeFreight] = React.useState([]);
    const [arrSurchargeAirFreight, setArrSurchargeAirFreight] = React.useState([]);
    const [open, setOpen] = React.useState(true);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));

    React.useEffect(() => {
      setCarrierId(item?.carrierId || '');
      setCarrierName(item?.carrierName || '');
      setEtd(Utils.checkIsDate(item?.etd) ? Utils.convertToDateFormat(item?.etd) : '');
      setExpirydate(
        Utils.checkIsDate(item?.expirydate) ? Utils.convertToDateFormat(item?.expirydate) : '',
      );
      setArrContFreight(item?.arrContFreight || []);
      setArrPackingAirFreight(item?.arrPackingAirFreight || []);
      setArrSurchargeFreight(item?.arrSurchargeFreight || []);
      setArrSurchargeAirFreight(item?.arrSurchargeAirFreight || []);
    }, [item]);

    const getSurchargeByFeeGroupSS = async () => {
      const dataMapFeeGroup = dataFeeGroupList?.map((element) => {
        if (itemData.id === AIR) {
          const ind = arrSurchargeAirFreight?.findIndex((i) => i.feeGroupID === element.feeGroupID);
          if (ind !== -1) {
            element.surcharge = arrSurchargeAirFreight[ind]?.surcharge || [];
            element.checked = true;
          } else {
            element.checked = false;
            element.surcharge = [];
          }
        } else {
          const ind = arrSurchargeFreight?.findIndex((i) => i.feeGroupID === element.feeGroupID);
          if (ind !== -1) {
            element.surcharge = arrSurchargeFreight[ind]?.surcharge || [];
            element.checked = true;
          } else {
            element.checked = false;
            element.surcharge = [];
          }
        }
        return element;
      });
      setGroupFeeGroup(dataMapFeeGroup || []);
    };

    useEffect(() => {
      if (item && dataFeeGroupList?.length > 0) {
        getSurchargeByFeeGroupSS();
      }
    }, [item, arrSurchargeFreight, arrSurchargeAirFreight]);

    React.useEffect(() => {
      if (carrierId) {
        const name = carrierList.find((i) => i.id === carrierId)?.name;
        setCarrierName(name);
      } else {
        setCarrierName('');
      }
    }, [carrierId]);

    React.useEffect(() => {
      if (arrContFreight?.length > 0) {
        dispatch(updateResCheckPrice(itemData?.id, 'arrContFreight', arrContFreight, index));
      }
    }, [arrContFreight]);

    React.useEffect(() => {
      if (arrPackingAirFreight?.length > 0) {
        dispatch(
          updateResCheckPrice(itemData?.id, 'arrPackingAirFreight', arrPackingAirFreight, index),
        );
      }
    }, [arrPackingAirFreight]);

    React.useEffect(() => {
      if (groupFeeGroup && groupFeeGroup.length > 0) {
        const data = groupFeeGroup
          .filter((el) => el.checked)
          .map((item) => {
            return {
              feeGroupID: item?.feeGroupID,
              surcharge: item?.surcharge,
            };
          });
        if (itemData.id === AIR) {
          return dispatch(updateResCheckPrice(itemData?.id, 'arrSurchargeAirFreight', data, index));
        }
        return dispatch(updateResCheckPrice(itemData?.id, 'arrSurchargeFreight', data, index));
      }
    }, [groupFeeGroup]);

    const handleChangeCarrier = useCallback(
      (e) => {
        setCarrierId(e.target.value);
        dispatch(updateResCheckPrice(itemData?.id, 'carrierId', e.target.value, index));
      },
      [setCarrierId],
    );

    const handleChangeETD = useCallback(
      (e) => {
        setEtd(e.target.value);
        dispatch(updateResCheckPrice(itemData?.id, 'etd', e.target.value, index));
      },
      [setEtd],
    );
    const handleChangeExpiryDate = useCallback(
      (e) => {
        setExpirydate(e.target.value);
        dispatch(updateResCheckPrice(itemData?.id, 'expirydate', e.target.value, index));
      },
      [setExpirydate],
    );
    const handleChangeArrContFreight = useCallback(
      (e) => {
        setArrContFreight(e.target.value);
      },
      [setArrContFreight],
    );
    const handleChangeArrPackingAirFreight = useCallback(
      (e) => {
        setArrPackingAirFreight(e.target.value);
      },
      [setArrPackingAirFreight],
    );

    const handleCheckFeeGroup = (event, el) => {
      const data = groupFeeGroup.map((item2) => {
        if (item2?.feeGroupID === el?.feeGroupID) {
          item2.checked = event.target.checked;
        }
        return item2;
      });
      setGroupFeeGroup(data);
    };

    const handleCloseCheckedItem = (item) => {
      const ind = groupFeeGroup.findIndex((i) => i.feeGroupID === item.feeGroupID);
      if (ind !== -1) {
        const data = groupFeeGroup;
        data[ind].checked = false;
        setGroupFeeGroup(data);
      }
    };
    const handleChangeDataFeeGroup = (event, item) => {
      const data = groupFeeGroup.map((item2) => {
        if (item2?.feeGroupID === item?.feeGroupID) {
          item2.surcharge = event.target.value;
        }
        return item2;
      });
      setGroupFeeGroup(data);
    };
    const isDisabled = false;

    const handleOpenItem = () => {
      setOpen(!open);
    };

    return (
      <Main colorBackground={index % 2 !== 0 && 'rgba(240,248,255, 0.6)'}>
        <HeaderForm>
          <Box>
            <IconButton aria-label="expand row" size="small" onClick={handleOpenItem}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <i style={{ color: 'blue' }}>
              Giá cước hãng {itemData?.id === AIR ? 'hàng không' : 'tàu'}:{' '}
              <i style={{ color: 'red' }}>{carrierName} </i>
              {item?.code && ` - ${item?.code}`}
            </i>
          </Box>
          <CardAction>
            <ContainerAction>
              {index === values.length - 1 ? (
                <>
                  <Tooltip title="Xóa">
                    <ButtonWrapper
                      onClick={() => onDelete(index)}
                      sx={{ borderColor: 'error.main' }}
                    >
                      <HighlightOffIcon sx={{ color: 'error.main' }} />
                    </ButtonWrapper>
                  </Tooltip>
                  <Tooltip title="Thêm">
                    <ButtonWrapper
                      onClick={onAdd}
                      sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                      disabled={isDisabled}
                    >
                      <AddCircleOutlineIcon
                        sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                      />
                    </ButtonWrapper>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Xóa">
                  <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
                    <HighlightOffIcon sx={{ color: 'error.main' }} />
                  </ButtonWrapper>
                </Tooltip>
              )}
            </ContainerAction>
          </CardAction>
        </HeaderForm>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Container>
            <ContainerBody width="95%">
              <Box sx={{ width: '100%', marginLeft: '10px' }}>
                <Selection
                  sx={{
                    width: '100%',
                    marginTop: 0,
                    fontSize: '12px',
                  }}
                  sxInput={{ padding: '0px', margin: '0px' }}
                  label={`${itemData?.id === AIR ? 'Hãng hàng không' : 'Hãng tàu'}`}
                  name="carrierId"
                  onChange={handleChangeCarrier}
                  value={carrierId}
                  error={!carrierId}
                  fieldValue="id"
                  fieldView="name"
                  dataArrObject={carrierList}
                />
                {!carrierId && <ErrorText>Bắt buộc (*)</ErrorText>}
              </Box>
              <Box sx={{ width: '50%', marginLeft: '10px' }}>
                <TextInput
                  sx={{
                    width: '100%',
                    marginTop: 0,
                    fontSize: '12px',
                  }}
                  error={!etd}
                  label="ETD"
                  name="etd"
                  onChange={handleChangeETD}
                  value={etd}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {!etd && <ErrorText>Bắt buộc (*)</ErrorText>}
              </Box>
              <Box sx={{ width: '50%', marginLeft: '10px' }}>
                <TextInput
                  sx={{
                    width: '100%',
                    marginTop: 0,
                    fontSize: '12px',
                  }}
                  error={!expirydate}
                  label="Hết hạn"
                  name="expirydate"
                  onChange={handleChangeExpiryDate}
                  value={expirydate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {!expirydate && <ErrorText>Bắt buộc (*)</ErrorText>}
              </Box>
            </ContainerBody>
          </Container>
          {itemData?.id === SEA_FCL && (
            <>
              <Box sx={{ marginTop: '10px' }}>
                <FreightPriceForm
                  arrContType={contTypeCodeCheck}
                  unitList={unitList}
                  name="arrContFreight"
                  requiredTitle="Vui lòng nhập đầy đủ thông tin"
                  onChange={handleChangeArrContFreight}
                  value={arrContFreight}
                  isAddRow={false}
                />
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                {groupFeeGroup &&
                  groupFeeGroup.length > 0 &&
                  groupFeeGroup?.map((itemFeeGroup, index) => {
                    return (
                      <FormGroup key={itemFeeGroup.feeGroupID + index}>
                        <FormControlLabel
                          sx={{ width: '50%' }}
                          control={
                            <Checkbox
                              checked={itemFeeGroup.checked}
                              onChange={(event) => handleCheckFeeGroup(event, itemFeeGroup)}
                            />
                          }
                          label={itemFeeGroup.feeGroupName}
                        />
                        {itemFeeGroup.checked && contTypeCodeCheck && (
                          <FreightSubChargeForm
                            arrContType={contTypeCodeCheck}
                            arrSurcharge={itemFeeGroup.surchargeListFree}
                            arrUnit={unitList}
                            name={itemFeeGroup.feeGroupID}
                            onChange={(event) => handleChangeDataFeeGroup(event, itemFeeGroup)}
                            value={itemFeeGroup?.surcharge}
                            closeCheckedItem={() => handleCloseCheckedItem(itemFeeGroup)}
                            requiredTitle="Vui lòng nhập đầy đủ thông tin"
                          />
                        )}
                      </FormGroup>
                    );
                  })}
              </Box>
            </>
          )}
          {itemData?.id === SEA_LCL && (
            <>
              <Box sx={{ marginTop: '10px' }}>
                <FreightPriceForm
                  arrContType={ARRAY_BODY_ITEM}
                  unitList={unitList}
                  name="arrContFreight"
                  requiredTitle="Vui lòng nhập đầy đủ thông tin"
                  onChange={handleChangeArrContFreight}
                  value={arrContFreight}
                  isAddRow={false}
                  widthContainerLeft="20%"
                  widthContainerBody="30%"
                  widthContainerRight="45%"
                />
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                {groupFeeGroup &&
                  groupFeeGroup.length > 0 &&
                  groupFeeGroup?.map((itemFeeGroup, index) => {
                    return (
                      <FormGroup key={itemFeeGroup.feeGroupID + index}>
                        <FormControlLabel
                          sx={{ width: '50%' }}
                          control={
                            <Checkbox
                              checked={itemFeeGroup.checked}
                              onChange={(event) => handleCheckFeeGroup(event, itemFeeGroup)}
                            />
                          }
                          label={itemFeeGroup.feeGroupName}
                        />
                        {itemFeeGroup.checked && ARRAY_BODY_ITEM && (
                          <FreightSubChargeForm
                            arrContType={ARRAY_BODY_ITEM}
                            arrSurcharge={itemFeeGroup.surchargeListFree}
                            arrUnit={unitList}
                            name={itemFeeGroup.feeGroupID}
                            onChange={(event) => handleChangeDataFeeGroup(event, itemFeeGroup)}
                            value={itemFeeGroup?.surcharge}
                            closeCheckedItem={() => handleCloseCheckedItem(itemFeeGroup)}
                            requiredTitle="Vui lòng nhập đầy đủ thông tin"
                            widthContainerLeft="20%"
                            widthContainerBody="30%"
                            widthContainerRight="45%"
                          />
                        )}
                      </FormGroup>
                    );
                  })}
              </Box>
            </>
          )}
          {itemData?.id === AIR && (
            <>
              <Box sx={{ marginTop: '10px' }}>
                <FreightPriceForm
                  arrContType={packingAirCodeCheck}
                  unitList={unitList}
                  name="arrPackingAirFreight"
                  requiredTitle="Vui lòng nhập đầy đủ thông tin"
                  onChange={handleChangeArrPackingAirFreight}
                  value={arrPackingAirFreight}
                  isAddRow={false}
                  keyArray="arrPackingAir" // array cont main
                  keyNameOfArray="packingAirCode" // name of array cont
                />
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                {groupFeeGroup &&
                  groupFeeGroup.length > 0 &&
                  groupFeeGroup?.map((itemFeeGroup, index) => {
                    return (
                      <FormGroup key={itemFeeGroup.feeGroupID + index}>
                        <FormControlLabel
                          sx={{ width: '50%' }}
                          control={
                            <Checkbox
                              checked={itemFeeGroup.checked}
                              onChange={(event) => handleCheckFeeGroup(event, itemFeeGroup)}
                            />
                          }
                          label={itemFeeGroup.feeGroupName}
                        />
                        {itemFeeGroup.checked && packingAirCodeCheck && (
                          <FreightSubChargeForm
                            arrContType={packingAirCodeCheck}
                            arrSurcharge={itemFeeGroup.surchargeListFree}
                            arrUnit={unitList}
                            name={itemFeeGroup.feeGroupID}
                            onChange={(event) => handleChangeDataFeeGroup(event, itemFeeGroup)}
                            value={itemFeeGroup?.surcharge}
                            closeCheckedItem={() => handleCloseCheckedItem(itemFeeGroup)}
                            requiredTitle="Vui lòng nhập đầy đủ thông tin"
                            keyArray="arrPackingAir" // array cont main
                            keyNameOfArray="packingAirCode" // name of array cont
                          />
                        )}
                      </FormGroup>
                    );
                  })}
              </Box>
            </>
          )}
        </Collapse>
      </Main>
    );
  },
);
export default InputItem;
