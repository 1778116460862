import * as React from 'react';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FormulaCriteria from '../formula-criteria';
const InputItem = React.forwardRef(
  ({ item, index, onDelete, onAdd, values, setValues, error, showCheckCriteria }, ref) => {
    const [name, setName] = React.useState('');
    const [unit, setUnit] = React.useState('');
    const [status, setStatus] = React.useState(0);
    const [query, setQuery] = React.useState([]);
    const [isCollapse, setIsCollapse] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setName(item.name);
      setUnit(item.unit);
      setStatus(item.status || 0);
      setQuery(item.query || []);
    }, [item]);
    const onChangeName = React.useCallback(
      (e) => {
        setName(e.target.value);
        setValues((prev) => {
          prev[index].name = e.target.value;
          return [...prev];
        });
      },
      [name],
    );
    const onChangeUnit = React.useCallback(
      (e) => {
        setUnit(e.target.value);
        setValues((prev) => {
          prev[index].unit = e.target.value;
          return [...prev];
        });
      },
      [unit],
    );
    const onChangeCheckedStatus = React.useCallback(
      (e) => {
        let val = e.target.checked ? 1 : 0;
        setStatus(val);
        setValues((prev) => {
          let tmpData = prev.map((el) => ({ ...el, status: 0 }));
          tmpData[index].status = val;
          return [...tmpData];
        });
      },
      [status, values],
    );
    const onChangeQuery = React.useCallback(
      (val) => {
        setValues((prev) => {
          prev[index].query = val;
          return [...prev];
        });
      },
      [query, values],
    );
    React.useEffect(() => {
      onChangeQuery(query);
    }, [query.length]);

    const isDisabled = !name || !unit;
    return (
      <Box sx={{ position: 'relative', mt: '20px' }} key={'input-EvaluationCriteria-' + index}>
        {showCheckCriteria && (
          <StartWrapper>
            <FormControlLabel
              label="Là tiêu chí CHÍNH"
              labelPlacement="start"
              control={
                <Checkbox
                  icon={<StarBorderIcon />}
                  checkedIcon={<StarIcon sx={{ color: '#FFC300', bgcolor: 'background.paper' }} />}
                  disabled={Boolean(status)}
                  checked={Boolean(status)}
                  onChange={onChangeCheckedStatus}
                />
              }
            />
          </StartWrapper>
        )}

        <Card>
          <CardWrapper>
            <Box sx={{ flexGrow: 1 }}>
              <Box p={1} sx={{ display: 'flex', mt: '15px' }}>
                <StyledTextField
                  label="Tiêu chí đánh giá"
                  variant="outlined"
                  type="text"
                  size="small"
                  onChange={onChangeName}
                  value={name}
                  error={error}
                />
                <StyledTextField
                  label="Đơn vị"
                  sx={{ ml: '10px' }}
                  variant="outlined"
                  type="text"
                  size="small"
                  onChange={onChangeUnit}
                  error={error}
                  value={unit}
                />
              </Box>
              <Box sx={{ mb: '10px' }}>
                <FormulaWrapper onClick={() => setIsCollapse((prev) => !prev)}>
                  <FormulaTitle>
                    Công thức tính{' '}
                    {query?.length ? (
                      <span>
                        - <b>({query.length} công thức)</b>
                      </span>
                    ) : null}
                  </FormulaTitle>
                  {isCollapse ? <ExpandLess /> : <ExpandMore />}
                </FormulaWrapper>
                {isCollapse && <FormulaCriteria values={query} onChange={setQuery} />}
              </Box>
            </Box>
            {index === values.length - 1 ? (
              <ButtonWrapper
                onClick={onAdd}
                sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                disabled={isDisabled}
              >
                <AddCircleOutlineIcon
                  sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                  fontSize="small"
                />
              </ButtonWrapper>
            ) : (
              <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
                <HighlightOffIcon sx={{ color: 'error.main' }} fontSize="small" />
              </ButtonWrapper>
            )}
          </CardWrapper>
        </Card>
      </Box>
    );
  },
);
export default InputItem;

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
}));
const FormulaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingInline: '8px',
  cursor: 'pointer',
}));
const CardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '4px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.action.disabled,
}));
const FormulaTitle = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '400',
  flexGrow: 1,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
}));

const StartWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-20px',
  left: '15px',
  zIndex: 1,
}));
