import actionTypes from './type';

const initialState = {
  itemServiceSelectedToQuote: null,
  objQuotes: null,
  itemTruckingSelected: null,
  itemCustomsSelected: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SELECT_SERVICE_TO_QUOTE:
      return {
        ...state,
        itemServiceSelectedToQuote: action.data,
      };
    case actionTypes.UPDATE_SERVICE_TO_QUOTE:
      return {
        ...state,
        itemServiceSelectedToQuote: {
          ...state.itemServiceSelectedToQuote,
          ...action.data,
        },
      };
    case actionTypes.CLEAR_SERVICE_TO_QUOTE:
      return {
        ...state,
        itemServiceSelectedToQuote: null,
      };
    case actionTypes.SELECT_OBJ_QUOTES:
      return {
        ...state,
        objQuotes: action.data,
      };
    case actionTypes.UPDATE_OBJ_QUOTES:
      return {
        ...state,
        objQuotes: { ...state.objQuotes, ...action.data },
      };
    case actionTypes.CLEAR_OBJ_QUOTES:
      return {
        ...state,
        objQuotes: null,
      };
    // Trucking
    case actionTypes.SELECT_TRUCKING_SERVICE:
      return {
        ...state,
        itemTruckingSelected: action.data,
      };
    case actionTypes.CLEAR_TRUCKING_SERVICE:
      return {
        ...state,
        itemTruckingSelected: null,
      };
    case actionTypes.UPDATE_TRUCKING_SERVICE:
      return {
        ...state,
        itemTruckingSelected: {
          ...state.itemTruckingSelected,
          ...action.data,
        },
      };
    // Customs
    case actionTypes.SELECT_CUSTOMS_SERVICE:
      return {
        ...state,
        itemCustomsSelected: action.data,
      };
    case actionTypes.CLEAR_CUSTOMS_SERVICE:
      return {
        ...state,
        itemCustomsSelected: null,
      };
    case actionTypes.UPDATE_CUSTOMS_SERVICE:
      return {
        ...state,
        itemCustomsSelected: {
          ...state.itemCustomsSelected,
          ...action.data,
        },
      };
    case actionTypes.CLEAR_ALL_DATA_QUOTE:
      return {
        ...state,
        itemServiceSelectedToQuote: null,
        objQuotes: null,
        itemTruckingSelected: null,
        itemCustomsSelected: null,
      };

    default:
      return state;
  }
}
