import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';
import ContactsIcon from '@mui/icons-material/Contacts';
import DescriptionIcon from '@mui/icons-material/Description';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import TtyIcon from '@mui/icons-material/Tty';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import BusinessIcon from '@mui/icons-material/Business';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ChatIcon from '@mui/icons-material/Chat';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
export default class RenderIcon {
  static renderIcons = (icon) => {
    switch (icon) {
      case 'SettingsIcon':
        return <SettingsIcon />;
      case 'SupportAgentIcon':
        return <SupportAgentIcon />;
      case 'MonetizationOnIcon':
        return <MonetizationOnIcon />;
      case 'DashboardIcon':
        return <DashboardIcon />;
      case 'AssessmentIcon':
        return <AssessmentIcon />;
      case 'ArticleIcon':
        return <ArticleIcon />;
      case 'ContactsIcon':
        return <ContactsIcon />;
      case 'DescriptionIcon':
        return <DescriptionIcon />;
      case 'HeadsetMicIcon':
        return <HeadsetMicIcon />;
      case 'LibraryBooksIcon':
        return <LibraryBooksIcon />;
      case 'PhoneInTalkIcon':
        return <PhoneInTalkIcon />;
      case 'TtyIcon':
        return <TtyIcon />;
      case 'WorkIcon':
        return <WorkIcon />;
      case 'GroupIcon':
        return <GroupIcon />;
      case 'AccountBoxIcon':
        return <AccountBoxIcon />;
      case 'AdminPanelSettingsIcon':
        return <AdminPanelSettingsIcon />;
      case 'AppRegistrationIcon':
        return <AppRegistrationIcon />;
      case 'BusinessIcon':
        return <BusinessIcon />;
      case 'LeaderboardIcon':
        return <LeaderboardIcon />;
      case 'ChatIcon':
        return <ChatIcon />;
      case 'BusinessCenterIcon':
        return <BusinessCenterIcon />;
      case 'AccountTreeIcon':
        return <AccountTreeIcon />;

      default:
        break;
    }
  };
}
