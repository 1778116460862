import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function FormControlLabelPlacement({
  label,
  value,
  options = [],
  helperText = '',
  error = false,
  name = '',
  onChange = null,
  labelPlacement = 'start',
  isRow = true,
  syncData = null,
  renderOptions = null,
  renderDisabled = null,
}) {
  const [items, setItems] = React.useState([]);
  const initData = async () => {
    if (!syncData) {
      return;
    }
    try {
      const { data } = await syncData();
      setItems(data);
      // setItems(data);
    } catch (err) {
      console.log('ERROR - Selection initData', err);
    }
  };
  React.useEffect(() => initData(), [syncData]);
  const renderItems = (item, index, isSyncData = false) => (
    <FormControlLabel
      labelPlacement={labelPlacement}
      key={`FormControlLabel-${item?.label}-${index}`}
      value={item.id}
      control={<Radio sx={{ fontSize: '12px !important' }} />}
      label={item.name}
      disabled={renderDisabled ? renderDisabled(item.id) : false}
      sx={{ '.MuiFormControlLabel-label': { fontSize: '12px !important' } }}
    />
  );
  return (
    <FormControl error={error} variant="standard">
      <FormLabel id="demo-form-control-label-placement">{label}</FormLabel>
      <RadioGroup
        row={isRow}
        aria-labelledby="demo-form-control-label-placement"
        name={name}
        value={value}
        onChange={(e) => onChange && onChange(e)}
      >
        {options.map((item, index) => renderItems(item, index))}
        {items.map((item, index) => renderItems(item, index, true))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
