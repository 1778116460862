import actionTypes from './type';

const initialState = {
  isOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        isOpen: true,
      };
    case actionTypes.CLOSE:
      console.log({ action });
      return {
        isOpen: false,
      };
    default:
      return state;
  }
}
