import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'components';
import { Box, Typography } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './column';
import { ContainerHeader } from '../styles';
import { debounce } from 'lodash';
import Utils from 'commons/utils';

function TitleManagement() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [originData, setOriginData] = useState([]);

  const initData = async () => {
    setLoading(true);
    await ApiServices.getPositions()
      .then((res) => {
        if (res?.data?.data) {
          setData(res?.data?.data || []);
          setOriginData(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('initData - Title', err));
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;

      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.nameVI) {
        if (
          !Utils.convertValSearch(item?.nameVI)?.includes(Utils.convertValSearch(query?.nameVI))
        ) {
          checkFilter = false;
        }
      }
      if (query?.nameEN) {
        if (
          !Utils.convertValSearch(item?.nameEN)?.includes(Utils.convertValSearch(query?.nameEN))
        ) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );

  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          DANH SÁCH CHỨC DANH
        </Typography>
      </ContainerHeader>
    </>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data || []}
        headCells={Columns(onColumnSearch)}
        loading={loading}
        renderHeader={renderHeader}
        defaultPage={20}
      />
    </Box>
  );
}
export { TitleManagement };
