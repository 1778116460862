import React from 'react';
import Utils from 'commons/utils';
import { RenderAreaAndLocationFrom, RenderAreaAndLocationTo } from 'components/custom-location';

export const Columns = () => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'objLocationFrom',
    numeric: true,
    disablePadding: false,
    label: 'Địa điểm/ Khu vực đi',
    align: 'left',
    width: '200px',
    render: (row) => (
      <RenderAreaAndLocationFrom areaFrom={row?.objAreaFrom} locationFrom={row?.objLocationFrom} />
    ),
  },
  {
    id: 'objLocationTo',
    numeric: true,
    disablePadding: false,
    label: 'Địa điểm/ Khu vực đến',
    align: 'center',
    width: '200px',
    align: 'left',
    render: (row) => (
      <RenderAreaAndLocationTo areaTo={row?.objAreaTo} locationTo={row?.objLocationTo} />
    ),
  },
  {
    id: 'currencyCode',
    numeric: true,
    disablePadding: false,
    label: 'Đơn vị tiền tệ',
    align: 'center',
    width: '100px',
  },
  {
    id: 'expirydate',
    numeric: true,
    disablePadding: false,
    label: 'Hết hạn',
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row?.expirydate),
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
];
