import React, { useState, useEffect } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import { Telegram as TelegramIcon } from '@mui/icons-material';
import { Table } from 'components';
import ApiServices from 'services';
import { CLIENT_STATUS_ID } from 'commons/constants';

export const DealWaitCare = ({ getDataInit }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getClientCareHistoryByStatus(
        CLIENT_STATUS_ID.DEAL_WAIT_CARE_AGAIN,
      );
      setData(data || []);
    } catch (err) {
      console.warn('initData - Deal take care again', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const updateClientStatus = async (idClientCareHistory, idStatus) => {
    try {
      const { data } = await ApiServices.updateClientStatus(idClientCareHistory, `${idStatus}`);
      if (data) {
        await initData();
        await getDataInit();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeToDeal = async (row) => {
    await updateClientStatus(row.id, CLIENT_STATUS_ID.DEAL);
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Tên khách hàng',
      sort: true,
      align: 'left',
      render: (row) => <>{row?.objClientCompany?.name}</>,
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email doanh nghiệp',
      align: 'left',
      render: (row) => <>{row?.objClientCompany?.email}</>,
    },
    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: 'SĐT Doanh nghiệp',
      align: 'left',
      render: (row) => <>{row?.objClientCompany?.arrNumberPhone}</>,
    },
    {
      id: 'serviceName',
      numeric: true,
      disablePadding: false,
      width: 20,
      label: 'Dịch vụ',
      align: 'left',
      render: (row) => (
        <Chip label={row.serviceName} color="primary" size="small" sx={{ padding: '0px 10px' }} />
      ),
    },
    {
      id: 'actions',
      numeric: true,
      width: '10px',
      fixed: 'right',
      align: 'left',
      disablePadding: false,
      label: 'Thao tác',
      render: (row) => (
        <IconButton
          aria-label="delete"
          color="primary"
          size="small"
          onClick={() => onChangeToDeal(row)}
        >
          <TelegramIcon sx={{ transform: 'rotate(-180deg)' }} />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={data}
          headCells={headCells}
          sizeTable="medium"
          isHeader={false}
          loading={loading}
        />
      </Box>
    </>
  );
};
