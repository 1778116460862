const colors = {
  primary: '#21409a',
  // secondary: '#f7941d',
  //   primary: '#5344ED',
  secondary: '#FF5C93',
  warning: '#FFC260',
  success: '#3CD4A0',
  success2: '#44b700',
  info: '#9013FE',

  black: '#343a40',
  darkBlack: 'rgb(36, 40, 44)',
  background: '#f5f5f5',
  warningLight: 'rgba(253, 200, 69, .3)',
  warningMain: 'rgba(253, 200, 69, .5)',
  warningDark: 'rgba(253, 200, 69, .7)',
  borderColor: 'rgba(0, 0, 0, 0.13)',
  messageActive: 'rgba(239, 246, 252, 1)',
  messageBoxShadow: '0px 4px 5px 2px rgba(121, 197, 239, 0.38)',
};
export default colors;
