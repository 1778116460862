import actionTypes from './type';
import ApiServices from 'services';

export function selectQuoteId(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.SELECTED_QUOTE_ID_LIST });
  };
}
export function clearQuoteId() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_QUOTE_ID_LIST });
  };
}

export function selectContractData(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.SELECTED_CONTRACT_DATA });
  };
}
export function updateContractData(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.UPDATE_CONTRACT_DATA });
  };
}
export function clearContractData() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_CONTRACT_DATA });
  };
}
