import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';

function Services() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getServices();
      setData(data || []);
    } catch (err) {
      console.warn('initData - Client Services', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteService(row.id);

      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteCell - Service', err);
    }
    setLoading(false);
  };
  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Mã',
      width: 10,
      sort: true,
      align: 'left',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên Dịch Vụ',
      align: 'left',
    },
    {
      id: 'companyName',
      numeric: true,
      disablePadding: false,
      label: 'Công Ty',
      align: 'left',
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi Chú',
      align: 'left',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Thao tác',
      width: '10px',
      fixed: 'right',
      align: 'left',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={data}
          headCells={headCells}
          onClickDetail={(row) => {
            setSelected(row);
          }}
          title="Danh Sách Dịch Vụ"
          loading={loading}
          renderHeader={() => {
            return (
              <>
                <SearchInput sx={{ mr: 2 }} />
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                    mr: 2,
                  }}
                >
                  {/* <Stack direction="row" spacing={1}> */}
                  <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                    <BsFilter />
                  </IconButton>
                  <Button
                    variant="outlined"
                    startIcon={<BsArrowDownCircle />}
                    size="small"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '10px',
                      marginLeft: '8px',
                      color: '#6C757D',
                      border: '1px solid rgba(0, 0, 0, 0.4)',
                      padding: '0px 8px',
                    }}
                  >
                    Nhập excel
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<BsArrowUpCircle />}
                    size="small"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '10px',
                      marginLeft: '8px',
                      color: '#6C757D',
                      border: '1px solid rgba(0, 0, 0, 0.4)',
                      padding: '0px 8px',
                    }}
                  >
                    Tải excel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<BsPlus />}
                    sx={{
                      borderRadius: '20px',
                      // padding: "7px 15px",
                      textTransform: 'none',
                      marginLeft: '8px',
                      color: '#FFFFFF',
                      backgroundColor: '#21409A',
                      '.MuiButton-endIcon': {
                        marginLeft: '0px',
                      },
                      padding: '0px 10px',
                    }}
                    onClick={() => setSelected({ isNew: true })}
                  >
                    Thêm mới
                  </Button>
                  {/* </Stack> */}
                </Box>
              </>
            );
          }}
        />
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
    </>
  );
}
export { Services };
