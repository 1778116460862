import React, { useEffect, useState } from 'react';
import Schedule from './schedule/index';
import PieChart from './pie-chart';
import Statistical from './statistical';
import QuotaSummary from './quota-summary';
import TableCountSchedule from './table-schedule-user';
import ApiServices from 'services';
import moment from 'moment';
import { Box, Paper, Typography, Grid, Button, Stack } from '@mui/material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import { styled } from '@mui/material/styles';
import { LoadingView } from 'components';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor: 'background.paper',
  color: theme.palette.text.secondary,
  borderRadius: '20px',
  minHeight: '7rem',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  position: 'relative',
  alignItems: 'center',
  height: '100%',
}));

export const HomeSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [saleTarget, setSaleTarget] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [countClient, setCountClient] = useState([]);
  const [countSchedule, setCountSchedule] = useState({});
  const getInitData = async () => {
    setIsLoading(true);
    try {
      const [resSchedule, resSaleTarget, resCountClient, resCountSchedule] = await Promise.all([
        ApiServices.getScheduleManagement({
          startDate: moment().weekday(0),
          endDate: moment().weekday(6),
        }),
        ApiServices.getSalesTarget(),
        ApiServices.countClient(),
        ApiServices.countSchedule(),
      ]);
      setSchedule(resSchedule?.data);
      setSaleTarget(resSaleTarget?.data);
      setCountClient(resCountClient?.data);
      setCountSchedule(resCountSchedule?.data);
    } catch (error) {
      console.warn('ERROR - get init data', error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getInitData();
  }, []);

  const onHandleExport = async (event) => {
    try {
      const response = await ApiServices.exportExcelManager();
      if (response.data) {
        const outputFilename = `Bao_Cao_Quan_Ly_Lead.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        window.UIMessage?.success('Export excel thành công');
        link.remove();
      }
    } catch (err) {
      console.warn('Export excel - Clients', err);
      window.UIMessage?.error('Export excel thất bại !!!');
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'relative', minHeight: '100vh' }}>
      <LoadingView loading={isLoading} />
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12}>
          <Item>
            <Typography variant="h7" component="div" sx={{ fontWeight: 'bold' }}>
              TRẠNG THÁI KH
            </Typography>
            <PieChart data={countClient} />
          </Item>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Item>
            <QuotaSummary data={saleTarget} />
          </Item>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Item>
            <Typography variant="h7" component="div" sx={{ fontWeight: 'bold' }}>
              THỐNG KÊ CÔNG VIỆC
            </Typography>
            <Statistical data={countSchedule} />
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item lg={12} xs={12}>
          <Item>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={15}>
              <Typography variant="h7" component="string" sx={{ fontWeight: 'bold' }}>
                CHĂM SÓC KHÁCH HÀNG
              </Typography>
              <Button
                variant="outlined"
                startIcon={<BsArrowDownCircle />}
                size="small"
                sx={{
                  textTransform: 'none',
                  borderRadius: '10px',
                  marginLeft: 'auto',
                  color: '#6C757D',
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                  // padding: '0px 8px',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  position: 'relative',
                  alignItems: 'right',
                }}
                onClick={onHandleExport}
              >
                Tải excel
              </Button>
            </Stack>
            <TableCountSchedule />
          </Item>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Schedule data={schedule} />
        </Grid>
      </Grid>
    </Box>
  );
};
