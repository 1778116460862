import actionTypes from './type';
import { createSelector } from 'redux';
const initialState = {
  messages: [],
  resultKey: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_MESSAGES:
      return {
        messages: action.messages,
        resultKey: action.resultKey,
      };
    case actionTypes.LOAD_MORE_MESSAGES:
      const moreMessages = state.messages.map((item) => item);
      for (var message of action.messages) {
        moreMessages.push(message);
      }
      console.log('length', moreMessages.length);
      return {
        messages: moreMessages,
        resultKey: action.resultKey,
      };
    case actionTypes.ADD_MESSAGE:
      const messageFind = state.messages.find((item) => item._id == action.message._id);
      if (messageFind) {
        return state;
      }
      var arrMessages = [action.message];
      for (var message of state.messages) {
        arrMessages.push(message);
      }
      return {
        messages: arrMessages,
      };
    case actionTypes.DELETE_MESSAGE:
      var arrMessages = state.messages.map((item) => item);
      let index = arrMessages.indexOf(action.message);
      if (index !== -1) {
        arrMessages.splice(index, 1);
      }
      return {
        messages: arrMessages,
      };
    case actionTypes.UPDATE_MESSAGE:
      var arrMessages = state.messages.map((item) => item);
      index = arrMessages.findIndex((el) => el._id == action.message?._id);
      if (index !== -1) {
        arrMessages[index] = action.message;
      }
      return {
        messages: arrMessages,
      };
    default:
      return state;
  }
}
