import React from 'react';
import { Button } from '@mui/material';

export const Columns = (deleteItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
    width: 10,
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Tên nhà xe',
    align: 'center',
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
