import * as React from 'react';
import { Box } from '@mui/material';
import { Header, SlideBar } from 'components';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { openSidebar, closeSidebar } from 'store/reducers/open-sidebar/action';

export default function Layout({ children, ...props }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);

  const { isOpen } = useSelector((state) => state.openSideBar);

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(openSidebar());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(closeSidebar());
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Header isOpen={isOpen} open={handleDrawerOpen} close={handleDrawerClose} />
      <SlideBar
        isOpen={isOpen}
        open={handleDrawerOpen}
        close={handleDrawerClose}
        DrawerHeader={DrawerHeader}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: 100 }}>
        <DrawerHeader2 />
        <div style={{ width: '100%', mt: 50 }}>{children}</div>
      </Box>
    </Box>
  );
}
const DrawerHeader2 = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: 41,
  // ...theme.mixins.toolbar,

  // necessary for content to be below app bar
}));
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
