import axios from 'axios';
// import Endpoint from '../services/endpoints';
import Utils from '../commons/utils';
import storage from '../storage';

export default class ApiClient {
  constructor({ baseURL, logOutput = true, pathPrefix = '' }) {
    this.instance = axios.create({
      baseURL,
      timeout: 30000,
    });
    if (logOutput) {
      // this.instance.interceptors.request.use((request) => {
      //   console.log('Api request', request);
      //   return request;
      // });
      this.instance.interceptors.response.use(
        (response) => {
          console.log('Api response', response);
          return response;
        },
        (error) => {
          if (window.location.pathname !== '/login' && error.response.status === 401) {
            window.location.replace('/login');
          }

          console.warn('error response: ', error);

          // message.error(
          //   error?.response?.data?.message
          //     ? error?.response?.data?.message
          //       ? error?.response?.data?.message
          //       : error?.response?.data
          //     : error.message
          // );
          // if (
          //   error.response.data &&
          //   error.response.status === 400 &&
          //   error?.response?.data?.message
          // ) {
          //   window.UIMessage?.error('Lỗi', error?.response?.data?.message);
          // }
          // if (error.response.data && (error.response.status = 400)) {
          //   if (typeof error.response.data === 'string') {
          //     window.UIMessage?.error('Lỗi', error?.response?.data, 4000);
          //   }
          // }
          // ============= New  =======================
          /** Ref: UICRM-538
           * Case 1: Normal
           * response = {
           *              data: any (gi cũng đc)
           *              succeeded: fasle
           *              status: 400,
           *              message: 'Lí do lỗi'
           *            }
           * Case 2: Normal
           * response = {
           *              data: 'ERROR_LIST' /// buoc su dung key ERROR_LIST
           *              succeeded: fasle
           *              status: 400,
           *              message: 'Lí do lỗi'
           *              errors: ['error_1', 'error_2', 'error_3', 'error_4'...]
           *            }
           */
          if (error.response.status === 400) {
            if (error.response?.data?.data === 'ERROR_LIST') {
              window.UIMessage?.errorList('Lỗi', error?.response?.data?.errors ?? [], 5000);
            } else {
              window.UIMessage?.error('Lỗi', error?.response?.data?.message ?? '', 4000);
            }
          }

          if (error.response.status === 500) {
            window.UIMessage?.error(
              'Lỗi',
              error?.response?.data?.message ?? 'Thao tác thất bại',
              4000,
            );
          }
        },
      );
    }
    this.pathPrefix = pathPrefix || '';
    this.refreshTokenRequest = null;
    this.isExpiredToken = false;
  }

  request = async (
    method,
    path,
    requestHeaders,
    params = null,
    data = null,
    authenticate = false,
    isFormData = false,
    isFormatData = true,
  ) => {
    // console.log({ isFormatData });
    const headers = requestHeaders || {};
    if (authenticate || storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: this.pathPrefix + path,
      params: Utils.removeEmptyAttributes(params),
      data: isFormData || !isFormatData ? data : Utils.removeEmptyAttributes(data),
      headers,
    });
  };
  requestAnother = async (
    method,
    fullPath = '',
    requestHeaders,
    params = null,
    data = null,
    authenticate = false,
  ) => {
    const headers = { ...requestHeaders, 'Cache-Control': 'no-cache' } || {};
    if (authenticate && storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: fullPath,
      params: Utils.removeEmptyAttributes(params),
      data: data,
      headers,
    });
  };
  requestCustom = async (
    method,
    path = '',
    requestHeaders,
    params = null,
    data = null,
    authenticate = false,
    fullPath = null,
  ) => {
    const headers = requestHeaders || {};
    if (authenticate || storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: fullPath || this.pathPrefix + path,
      params: Utils.removeEmptyAttributes(params),
      data: data,
      headers,
    });
  };

  requestCustomBlob = async (
    method,
    path = '',
    requestHeaders,
    params = null,
    data = null,
    authenticate = false,
    fullPath = null,
  ) => {
    const headers = requestHeaders || {};
    if (authenticate || storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: fullPath || this.pathPrefix + path,
      params: Utils.removeEmptyAttributes(params),
      data: data,
      headers,
      responseType: 'blob',
    });
  };

  putCustom = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustom('PUT', path, headers, null, data, authenticate);
  };
  postCustom = async (
    path,
    data = null,
    params = null,
    authenticate = false,
    requestHeaders = null,
  ) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustom('POST', path, headers, params, data, authenticate);
  };

  postCustomBlob = async (
    path,
    data = null,
    params = null,
    authenticate = false,
    requestHeaders = null,
  ) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustomBlob('POST', path, headers, params, data, authenticate);
  };

  getCustomBlod = async (path, params = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustomBlob('GET', path, headers, params, null, authenticate);
  };
  get = async (path, params = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('GET', path, headers, params, null, authenticate);
  };

  post = async (
    path,
    data = null,
    authenticate = false,
    requestHeaders = null,
    isFormatData = true,
  ) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('POST', path, headers, null, data, authenticate, false, isFormatData);
  };

  postFormData = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = {
      'Content-Type': 'multipart/form-data',
      ...headers,
    };
    return this.request('POST', path, headers, null, data, authenticate, true);
  };
  putFormData = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'multipart/form-data', ...headers };
    return this.request('PUT', path, headers, null, data, authenticate, true);
  };
  put = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('PUT', path, headers, null, data, authenticate);
  };

  delete = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('DELETE', path, headers, null, data, authenticate);
  };
}
