import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  CardContent,
  Card,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
//styles
import useStyles from './styles';
import Logo from 'assets/img/logo.png';
import ImgLoging from 'assets/img/login/1.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ApiServices from 'services';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import * as appActions from 'store/reducers/app/action';
import Storage from 'storage';
import User from 'models/user';
import { useHistory } from 'react-router-dom';
import socketHandle from 'libs/socket-handle';
const LoginPage = () => {
  const dispatch = useDispatch();
  dispatch(appActions.signOut());
  socketHandle.removeListener();
  const history = useHistory();
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const submitLogin = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { data } = await ApiServices.login(values);
      if (data) {
        const decodeToken = await jwt_decode(data);
        const newUser = User.clone({ ...decodeToken, token: data });

        const signIn = async () => {
          window.UIMessage?.success('Đăng nhập thành công');
          await setTimeout(() => {}, 5000);
          return dispatch(appActions.signIn(newUser));
        };

        return signIn().then((res) => {
          history.location.state
            ? window.location.replace(history.location.state.from.pathname)
            : window.location.replace('/');
        });
      }
    } catch (err) {
      window.UIMessage?.error('Đăng nhập thất bại');
      console.warn('ERROR - login', err);
    }
    setSubmitting(false);
  };
  return (
    // <Box style={classes.container}>
    <Box className="login-page-container">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: '100%' }}>
          <Grid
            container
            spacing={2}
            sx={{
              height: '100%',
              boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
              backgroundColor: 'transparent',
              borderRadius: '10px',
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: '#A2D2FF',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                display: { xs: 'none', sm: 'flex', md: 'flex' },
              }}
            >
              <img src={Logo} style={{ width: 40, height: 40, objectFit: 'contain' }} />
              <Box sx={{ padding: '10px', width: '100%' }}>
                <img
                  src={ImgLoging}
                  style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                />
                <Typography
                  variant="h5"
                  align="center"
                  component="div"
                  gutterBottom
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Quản lý và thống kê thông tin khách hàng
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                backgroundColor: 'background.default',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
            >
              <Helmet>
                <title>Đăng nhập | U&I Logistics</title>
              </Helmet>
              <Box
                sx={{
                  // backgroundColor: 'background.default',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Container maxWidth="sm">
                  <Formik
                    initialValues={{
                      code: '',
                      password: '',
                    }}
                    validationSchema={Yup.object().shape({
                      code: Yup.string().max(255).required('Vui lòng nhập tài khoản'),
                      password: Yup.string().max(255).required('Vui lòng nhập mật khẩu'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      submitLogin(values, { setSubmitting });
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box sx={{ mb: 3 }}>
                          <Typography color="textPrimary" variant="h4" sx={{ fontWeight: 'bold' }}>
                            Đăng nhập hệ thống.
                          </Typography>
                          <Typography color="textPrimary" variant="p">
                            Sử dùng <b>tài khoản nội bộ</b> đã được cấp để đăng nhập.
                          </Typography>
                        </Box>
                        <TextField
                          error={Boolean(touched.code && errors.code)}
                          fullWidth
                          helperText={touched.code && errors.code}
                          label="Tài khoản"
                          margin="normal"
                          name="code"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.code}
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="Mật khẩu"
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box sx={{ py: 2 }}>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? 'Đang tải...' : 'Đăng nhập ngay'}
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export { LoginPage };
