import React from 'react';
import { Box, ListItemAvatar, ListItem, Avatar, IconButton, Typography, Grid } from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

export default function GridItems({ item, sx, renderIcon, setSelected, deleteCell, renderStatus }) {
  return (
    <Grid item xs={12} sm={6} md={4} key={item.id}>
      <ItemWrapper sx={sx}>
        <Box sx={{ display: 'flex' }}>
          <ListItemAvatar>
            <Avatar sx={{ backgroundColor: 'background.default' }}>{renderIcon(item)}</Avatar>
          </ListItemAvatar>
          <Box sx={{ flex: 1 }}>
            <Name onClick={() => setSelected(item)}>
              <Title>{item.name}</Title>
              <span>{item.objFileinfo?.extension}</span>
            </Name>
            <Typography sx={{ color: '#C8C6C6', fontWeight: 300, fontSize: 13 }} component="div">
              Tạo bởi <b>{item.createdBy}</b>{' '}
            </Typography>
          </Box>
          {renderStatus(item)}
        </Box>
        <MoreInFo>
          <Time component="div">{moment(item.createdOn).format('DD/MM/YY HH:mm')}</Time>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => window.showConfirm(() => deleteCell(item.id))}
          >
            <DeleteIcon />
          </IconButton>
        </MoreInFo>
      </ItemWrapper>
    </Grid>
  );
}
const styleIcon = {
  ml: '10px' /* margin-left */,
};
const Title = styled('div')(({}) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100px',
}));
const Name = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  color: 'text.primary',
  fontWeight: 500,
  cursor: 'pointer',
}));
const Time = styled(Typography)(() => ({
  color: '#C8C6C6',
  fontWeight: 400,
  flex: 1,
  marginLeft: 'auto',
  marginRight: '10px',
}));
const MoreInFo = styled(Box)(({ theme }) => ({
  m: '0 10px',
  alignItems: 'center',
  display: 'flex',
}));
const ItemWrapper = styled(ListItem)(({ theme }) => ({
  backgroundColor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  display: 'block',
}));
