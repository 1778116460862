import socketIOClient from 'socket.io-client';
import storage from 'storage';

const host =
  process.env.NODE_ENV === 'develop'
    ? 'http://localhost:6612/'
    : process.env.REACT_APP_CHAT_API_URL;

class SocketHandle {
  constructor() {
    this.io = null;
  }
  startListener() {
    if (!this.io) {
      this.io = socketIOClient.io(host, {
        auth: {
          token: storage.user?.token,
        },
      });
      this.io.on('connect', () => {
        console.log('socket - connected', this.io.id);
      });
      this.io.on('disconnect', (reason) => {
        console.log('Client disconnected', reason);
      });
      this.io.on('reconnect', (reason) => {
        console.log('Client reconnect', reason);
      });
    }
  }
  reconnect() {
    this.io.on('reconnect', (reason) => {
      console.log('Client reconnected', reason);
    });
  }
  removeListener() {
    console.debug('[Socket] removeListener');
    this.io?.disconnect();
    this.io = null;
  }
  onMessageListener(onAdded, onUpdated, onDeleted) {
    this.io?.on('message_added', (data) => onAdded && onAdded(data));
    this.io?.on('message_updated', (data) => onUpdated && onUpdated(data));
    this.io?.on('message_deleted', (data) => onDeleted && onDeleted(data));
  }

  onUsersOnlineListener(onChange) {
    this.io?.on('users_online_changed', (data) => onChange && onChange(data));
  }
  onUsersTypingListener(onTyping) {
    this.io?.on('on_typing', (data) => onTyping && onTyping(data));
  }

  onChanelListener(
    onAddedChanel,
    onUpdatedChanel,
    onDeleteChanel,
    onAddedMember,
    onAddedMemberByLinkToken,
    onDeletedMember,
  ) {
    this.io?.on('chanel_added', (data) => onAddedChanel && onAddedChanel(data));
    this.io?.on('chanel_updated', (data) => onUpdatedChanel && onUpdatedChanel(data));
    this.io?.on('chanel_deleted', (data) => onDeleteChanel && onDeleteChanel(data));
    this.io?.on('member_added', (data) => onAddedMember && onAddedMember(data));
    this.io?.on(
      'member_added_by_link_token',
      (data) => onAddedMemberByLinkToken && onAddedMemberByLinkToken(data),
    );
    this.io?.on('member_deleted', (data) => onDeletedMember && onDeletedMember(data));
  }
}
export default new SocketHandle();
