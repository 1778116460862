import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert as MuiAlert, AlertTitle } from '@mui/material';
import { clearAlert } from 'store/reducers/alert-messages/action';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} {...props} />;
});

const KEY_TITLE = {
  success: 'Thành công',
  error: 'Lỗi',
  warning: 'Cảnh báo',
  info: 'Thông báo',
};

export default function AlertMessage() {
  const dispatch = useDispatch();
  const { isOpen, title, message, severity, timeout, isShowList, messageList } = useSelector(
    (state) => state.alertMessage,
  );
  function handleClose() {
    dispatch(clearAlert());
  }

  const renderMessageList = (mes, ind) => {
    return (
      <div key={ind}>
        <strong>{ind + 1}. </strong>
        {mes}
      </div>
    );
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        sx={{ minWidth: '350px', maxWidth: '400px' }}
        severity={severity} // 'success' 'error' 'warning' 'warning'
        onClose={handleClose}
      >
        <AlertTitle>{title ? title : KEY_TITLE[severity]}</AlertTitle>
        {isShowList && messageList?.map(renderMessageList)}
        {message && message}
      </Alert>
    </Snackbar>
  );
}
