import * as React from 'react';
import { Box, CircularProgress, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoSrc from 'assets/img/logo.png';
export const LoadingContainer = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <LoadingWrapper>
      <LogoDiv className="loading-container">
        <Logo src={LogoSrc} variant="rounded" />
      </LogoDiv>
      {/* <CircularProgress color="primary" /> */}
    </LoadingWrapper>
  );
};
const LoadingWrapper = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255,255,255,0.6)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: theme.zIndex.drawer + 3,
}));
const Logo = styled(Avatar)(({ theme }) => ({
  ...theme.typography.body2,
  width: 'auto',
  height: 100,
  marginTop: 5,
  position: 'relative',
}));
const LogoDiv = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  padding: 8,
}));
