import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection } from 'components';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      onAdd,
      values,
      setValues,
      arrSurcharge,
      arrUnit,
      disabledField,
      widthContainerLeft = '65%',
      widthContainerRight = '30%',
    },
    ref,
  ) => {
    const [surchargeId, setSurchargeId] = React.useState('');
    const [price, setPrice] = React.useState(0);
    const [quantity, setQuantity] = React.useState(0);
    const [unitCode, setUnitCode] = React.useState('');

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setSurchargeId(item?.surchargeId);
      setPrice(item.price);
      setQuantity(item?.quantity);
      setUnitCode(item?.unitCode);
    }, [item]);

    const onChangePrice = React.useCallback(
      (e) => {
        setPrice(+e.target.value);
        setValues((prev) => {
          prev[index].price = +e.target.value;
          return [...prev];
        });
      },
      [price],
    );
    const onChangeQuantity = React.useCallback(
      (e) => {
        setQuantity(+e.target.value);
        setValues((prev) => {
          prev[index].quantity = +e.target.value;
          return [...prev];
        });
      },
      [quantity],
    );
    const onChangeUnit = React.useCallback(
      (e) => {
        setUnitCode(e.target.value);
        setValues((prev) => {
          prev[index].unitCode = e.target.value;
          return [...prev];
        });
      },
      [unitCode],
    );

    const onChangeSurcharge = React.useCallback(
      (e) => {
        setSurchargeId(e.target.value);
        setValues((prev) => {
          prev[index].surchargeId = e.target.value;
          return [...prev];
        });
      },
      [surchargeId],
    );

    const isDisabled = !surchargeId || !unitCode;

    return (
      <Container>
        <ContainerLeft width={widthContainerLeft}>
          <Box sx={{ width: '100%' }}>
            <Selection
              required
              sx={{
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Tên dịch vụ"
              onChange={onChangeSurcharge}
              value={surchargeId}
              dataArrObject={arrSurcharge}
              fieldValue="surchargeId"
              fieldView="surchargeName"
              error={!surchargeId}
              disabled={disabledField}
            />
            {!surchargeId && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
        </ContainerLeft>
        <ContainerRight width={widthContainerRight}>
          <Box sx={{ width: '100%' }}>
            <StyledTextField
              label="Giá"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={onChangePrice}
              value={price}
              error={!price}
              disabled={disabledField}
            />
            {!price && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <Box sx={{ width: '100%' }}>
            <StyledTextField
              label="Số lượng"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={onChangeQuantity}
              value={quantity}
              error={!quantity}
              disabled={disabledField}
            />
            {!quantity && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <Box sx={{ width: '100%', marginLeft: '10px' }}>
            <Selection
              required
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Đơn vị"
              name="serviceId"
              onChange={onChangeUnit}
              value={unitCode}
              dataArrObject={arrUnit}
              fieldValue="code"
              fieldView="name"
              error={!unitCode}
              disabled={disabledField}
            />
            {!unitCode && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
        </ContainerRight>
        <ContainerAction>
          {index === values.length - 1 ? (
            <>
              <ButtonWrapper
                onClick={() => onDelete(index)}
                sx={{ borderColor: 'error.main' }}
                disabled={disabledField}
              >
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
              <ButtonWrapper
                onClick={onAdd}
                sx={{
                  borderColor: isDisabled ? 'action.disabled' : 'success.main',
                }}
                disabled={isDisabled || disabledField}
              >
                <AddCircleOutlineIcon
                  sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                />
              </ButtonWrapper>
            </>
          ) : (
            <ButtonWrapper
              onClick={() => onDelete(index)}
              sx={{ borderColor: 'error.main' }}
              disabled={disabledField}
            >
              <HighlightOffIcon sx={{ color: 'error.main' }} />
            </ButtonWrapper>
          )}
        </ContainerAction>
      </Container>
    );
  },
);
export default InputItem;

const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '95%',
  marginLeft: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
}));
const ContainerLeft = styled(Box)(({ theme, width = '65%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerRight = styled(Box)(({ theme, width = '30%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
}));
