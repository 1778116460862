import React from 'react';

import {
  Business as BusinessIcon,
  Group as GroupIcon,
  Logout as LogoutIcon,
  ManageAccounts as ManageAccountsIcon,
  PermContactCalendar as PermContactCalendarIcon,
  PhoneInTalk as PhoneInTalkIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
} from '@mui/icons-material';

export const dataItems = [
  {
    key: '1',
    title: 'Khách Hàng',
    icon: <GroupIcon />,
    link: '/',
    subItems: [
      {
        key: '1.1',
        title: 'Nguồn KH',
        link: '/client-sources',
      },
      {
        key: '1.2',
        title: 'Nhóm KH',
        link: '/client-groups',
      },
      {
        key: '1.3',
        title: 'Khách Hàng',
        link: '/client-companies',
      },
      {
        key: '1.4',
        title: 'Dịch Vụ',
        link: '/services',
      },
      {
        key: '1.5',
        title: 'Loại KHTT',
        link: '/loyalty-types',
      },

      {
        key: '1.6',
        title: 'Tiêu chí Đánh giá KH',
        link: '/client-rating',
      },
      {
        key: '1.7',
        title: 'Trạng Thái KH',
        link: '/client-status',
      },
      {
        key: '1.8',
        title: 'Người Hỗ Trợ',
        link: '/clients',
      },
      {
        key: '1.9',
        title: 'Quy Trình Chăm Sóc',
        link: '/client-care-process',
      },
      {
        key: '1.10',
        title: 'Quản Lý Lịch Hẹn',
        link: '/schedule-management',
      },
    ],
  },
  {
    key: '2',
    title: 'Hợp Đồng',
    icon: <PermContactCalendarIcon />,
    link: '/',
    subItems: [
      {
        key: '2.1',
        title: 'Hợp đồng',
        link: '/contracts',
      },
      {
        key: '2.2',
        title: 'Lịch Sử Hợp đồng',
        link: '/contracts-history',
      },
      {
        key: '2.3',
        title: 'Báo giá',
        link: '/quotes',
      },
    ],
  },
  {
    key: '3',
    title: 'Sales',
    icon: <ManageAccountsIcon />,
    link: '/',
    subItems: [
      {
        key: '3.1',
        title: 'Lead Rác',
        link: '/client-trashes',
      },
      {
        key: '3.2',
        title: 'Lead Mới',
        link: '/new-leads',
      },
      {
        key: '3.3',
        title: 'Quản Lý Lead',
        link: '/client-care-history',
      },
      {
        key: '3.4',
        title: 'KH Tạm Ngưng',
        link: '/client-holding',
      },
    ],
  },
  {
    key: '4',
    title: 'Chăm Sóc KH',
    icon: <PhoneInTalkIcon />,
    link: '/',
    subItems: [
      {
        key: '4.1',
        title: 'KH Khiếu Nại',
        link: '/client-complaints',
      },
    ],
  },
  {
    key: '5',
    title: 'Danh Mục',
    icon: <BusinessIcon />,
    link: '/',
    subItems: [
      {
        key: '5.1',
        title: 'Công Ty',
        link: '/companies',
      },
      {
        key: '5.2',
        title: 'Phòng Ban',
        link: '/departments',
      },
      {
        key: '5.3',
        title: 'Chức Vụ',
        link: '/positions',
      },
      {
        key: '5.4',
        title: 'Người dùng',
        link: '/users',
      },
      {
        key: '5.5',
        title: 'Nhân viên',
        link: '/employees',
      },
      {
        key: '5.6',
        title: 'Tài liệu',
        link: '/documents',
      },
      {
        key: '5.7',
        title: 'Mails',
        link: '/mails',
      },
      {
        key: '5.8',
        title: 'Lịch Trình Người Dùng',
        link: '/schedule-user',
      },
    ],
  },
  {
    key: '6',
    title: 'Phân Quyền',
    icon: <AdminPanelSettingsIcon />,
    link: '/',
    subItems: [
      {
        key: '6.1',
        title: 'PQ Menu',
        link: '/permission-menu',
      },
      {
        key: '6.2',
        title: 'Nhóm Thành Viên',
        link: '/permission-member-group',
      },
      {
        key: '6.3',
        title: 'Chức Năng',
        link: '/permission-system-function',
      },
    ],
  },
];
