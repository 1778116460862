import React from 'react';
import { Button } from '@mui/material';
import Utils from 'commons/utils';
import { REQ_CHECK_PRICE_STATUS } from 'commons/constants';
import { RenderCheckPriceStatus, RenderCheckPriceState } from 'components/custom-status';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'serviceName',
    numeric: true,
    disablePadding: false,
    label: 'Dịch vụ',
    align: 'center',
  },
  {
    id: 'subServiceName',
    numeric: true,
    disablePadding: false,
    label: 'Dịch vụ phụ',
    align: 'center',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    label: 'Khách hàng',
    align: 'center',
    width: '200px',
  },
  {
    id: 'statusCheck',
    numeric: true,
    disablePadding: false,
    label: 'Trạng thái',
    align: 'center',
    render: (row) => <RenderCheckPriceStatus status={row?.statusCheck} />,
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: false,
    label: 'Người tạo',
    align: 'center',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.createdOn),
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Tình trạng',
    align: 'center',
    render: (row) => <RenderCheckPriceState state={row?.status} />,
  },
  {
    id: 'arrShareUserCode',
    numeric: true,
    disablePadding: false,
    label: 'Chia sẻ cho',
    align: 'center',
    render: (row) => row?.arrShareUserCode?.join(', '),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) =>
      (row?.statusCheck === REQ_CHECK_PRICE_STATUS.CREATE_CHECK_PRICE ||
        row?.statusCheck === REQ_CHECK_PRICE_STATUS.REJECT_CHECK_PRICE) && (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteContTypeItem(row))}
        >
          Xoá
        </Button>
      ),
  },
];
