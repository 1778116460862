import React, { useState } from 'react';
import { Box, Paper, Typography, Button, Toolbar } from '@mui/material';
import { BsClockHistory } from 'react-icons/bs';
import { styled } from '@mui/material/styles';
import { SearchInput } from 'components';
import { ClientCarelist } from './client-care-list';
import { DrawerLeadWaitCare } from './drawer-lead-wait-care';
import ApiServices from 'services';
import { CLIENT_STATUS_ID } from 'commons/constants';
import Utils from 'commons/utils';

const ClientCareHistory = () => {
  const { LEAD_TAKING_CARE, DEAL, DEAL_SUCCESS } = CLIENT_STATUS_ID;
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [columns, setColumns] = useState({});
  const [columnsOriginCare, setColumnsOriginCare] = useState([]);
  const [columnsOriginDeal, setColumnsOriginDeal] = useState([]);
  const [columnsOriginDealSuccess, setColumnsOriginDealSuccess] = useState([]);

  const getDataInit = async () => {
    let [getLeadsTakingCare, getDeals, getDealsSuccessful] = await Promise.all([
      ApiServices.getClientCareHistoryByStatus(LEAD_TAKING_CARE),
      ApiServices.getClientCareHistoryByStatus(DEAL),
      ApiServices.getClientCareHistoryByStatus(DEAL_SUCCESS),
    ]);

    const mapDataCare = getLeadsTakingCare?.data?.map((item) => {
      item.textSearch = `${item?.objClientCompany?.code}${item?.objClientCompany?.name}`;
      return item;
    });
    const mapDataDeal = getDeals?.data?.map((item) => {
      item.textSearch = `${item?.objClientCompany?.code}${item?.objClientCompany?.name}`;
      return item;
    });
    const mapDataDealSuccess = getDealsSuccessful?.data?.map((item) => {
      item.textSearch = `${item?.objClientCompany?.code}${item?.objClientCompany?.name}`;
      return item;
    });

    setColumnsOriginCare(mapDataCare || []);
    setColumnsOriginDeal(mapDataDeal || []);
    setColumnsOriginDealSuccess(mapDataDealSuccess || []);

    setColumns({
      [LEAD_TAKING_CARE]: {
        name: 'Khách hàng đang chăm sóc',
        items: mapDataCare || [],
        columnId: LEAD_TAKING_CARE,
      },
      [DEAL]: {
        name: 'Deal',
        items: mapDataDeal || [],
        columnId: DEAL,
      },
      [DEAL_SUCCESS]: {
        name: 'Deal thành công',
        items: mapDataDealSuccess || [],
        columnId: DEAL_SUCCESS,
      },
    });
  };

  React.useEffect(() => {
    getDataInit();
  }, []);

  const handleFilterData = (query, dataInit, idColumn) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query) {
        if (!Utils.convertValSearch(item?.textSearch)?.includes(Utils.convertValSearch(query))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });

    setColumns((prev) => {
      prev[idColumn].items = dataFilter;
      return { ...prev };
    });
  };

  const handleSearchColumn = (value, dataColumn, dataInit) => {
    switch (dataColumn.columnId) {
      case LEAD_TAKING_CARE:
        handleFilterData(value, dataInit, LEAD_TAKING_CARE);
        return;

      case DEAL:
        handleFilterData(value, dataInit, DEAL);
        return;

      case DEAL_SUCCESS:
        handleFilterData(value, dataInit, DEAL_SUCCESS);
        return;

      default:
        break;
    }
  };

  return (
    <>
      <Container>
        <Header>
          <Typography variant="h6" id="tableTitle" component="div">
            QUẢN LÝ KHÁCH HÀNG
          </Typography>
          <ContainerRight>
            <SearchInput sx={{ mr: 2 }} />
            <LeadWaitCare
              variant="contained"
              size="small"
              startIcon={<BsClockHistory />}
              onClick={() => setIsOpenDrawer(true)}
              // onMouseOver={() => console.log('')}
            >
              Chờ chăm sóc
            </LeadWaitCare>
          </ContainerRight>
        </Header>

        <ClientCarelist
          columns={columns}
          setColumns={setColumns}
          getDataInit={getDataInit}
          handleSearchColumn={handleSearchColumn}
          columnsOriginCare={columnsOriginCare}
          columnsOriginDeal={columnsOriginDeal}
          columnsOriginDealSuccess={columnsOriginDealSuccess}
        />
      </Container>

      <DrawerLeadWaitCare
        isOpen={isOpenDrawer}
        setIsOpen={setIsOpenDrawer}
        getDataInit={getDataInit}
      />
    </>
  );
};

export { ClientCareHistory };

const Container = styled(Paper)(({ theme }) => ({
  width: '100%',
  mb: 1,
  position: 'relative',
  overflow: 'hidden',
}));

const Header = styled(Toolbar)(({ theme }) => ({
  pl: { sm: 2 },
  pr: { xs: 1, sm: 1 },
}));

const ContainerRight = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  mr: 2,
}));

const LeadWaitCare = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  marginLeft: '8px',
  color: '#FFFFFF',
  backgroundColor: '#F9A907',
  '.MuiButton-endIcon': {
    marginLeft: '0px',
  },
  padding: '0px 16px',
}));
