import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createCargoType(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateCargoType(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Thành phố: ${selected?.name}`}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            nameEN: '',
            nameCN: '',
            nameJP: '',
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().max(255).required('Vui lòng nhập mã'),
            name: Yup.string().max(255).required('Vui lòng nhập loại hàng hóa'),
            nameEN: Yup.string().max(255).required('Vui lòng nhập tên địa điểm tiếng Anh'),
            nameCN: Yup.string().max(255).required('Vui lòng nhập tên địa điểm tiếng Hoa'),
            nameJP: Yup.string().max(255).required('Vui lòng nhập tên địa điểm tiếng Nhật'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Loại hàng hóa"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                required
                error={Boolean(touched.nameEN && errors.nameEN)}
                helperText={touched.nameEN && errors.nameEN}
                label="Loại hàng hóa tiếng Anh"
                name="nameEN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameEN}
              />
              <TextInput
                required
                error={Boolean(touched.nameCN && errors.nameCN)}
                helperText={touched.nameCN && errors.nameCN}
                label="Loại hàng hóa tiếng Hoa"
                name="nameCN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameCN}
              />
              <TextInput
                required
                error={Boolean(touched.nameJP && errors.nameJP)}
                helperText={touched.nameJP && errors.nameJP}
                label="Loại hàng hóa tiếng Nhật"
                name="nameJP"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameJP}
              />
              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Ghi chú"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
