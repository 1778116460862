import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Drawer, Box, Tab, Toolbar, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LeadWaitCare } from './lead-wait-care';
import { DealWaitCare } from './deal-wait-care';

const DRAWER_WIDTH = 900;

const DrawerLeadWaitCare = ({ isOpen, setIsOpen, getDataInit }) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={{ width: DRAWER_WIDTH }}>
          <Header>
            <Typography variant="h6" id="tableTitle" component="div">
              DANH SÁCH KHÁCH HÀNG
            </Typography>
          </Header>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab sx={{ marginLeft: 3 }} label="KHÁCH HÀNG CHỜ CHĂM SÓC LẠI" value="1" />
                  <Tab sx={{ marginLeft: 3 }} label="DEAL CHỜ CHĂM SÓC LẠI" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <LeadWaitCare getDataInit={getDataInit} />
              </TabPanel>
              <TabPanel value="2">
                <DealWaitCare getDataInit={getDataInit} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export { DrawerLeadWaitCare };

const Header = styled(Toolbar)(({ theme }) => ({
  pl: { sm: 2 },
  pr: { xs: 1, sm: 1 },
}));
