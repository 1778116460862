import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { ModalFormDetail, TextInput, Selection } from 'components';
import { LoadingView } from 'components/loading-view';
import { Box, Button, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Formik } from 'formik';
import {
  SUB_SERVICES_ID,
  SERVICES_ID,
  INPUT_TYPES,
  PRICE_BOARD_STATUS_OPTIONS,
} from 'commons/constants';
import ApiServices from 'services';
import HeaderFormDetail from '../header-form-detail';
import { CustomsCargoTypeForm } from '../forms-management/customs-cargo-type-form';
import { CustomsSubChargeForm } from '../forms-management/customs-sub-charge-form';

export const FormDetail = ({ selected, onCloseModal, initData, ...props }) => {
  const { SELECTION, SELECTION_LOCATION, DATE_PICKER, TEXT_INPUT } = INPUT_TYPES;
  const disableByStatus = useMemo(() => {
    return selected?.status === 1;
  }, [selected?.id]);

  const [loading, setLoading] = useState(false);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [locationVTList, setLocationVTList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [locationFromId, setLocationFromId] = useState('');
  const [areaFromId, setAreaFromId] = useState('');
  const [locationToId, setLocationToId] = useState('');
  const [areaToId, setAreaToId] = useState('');

  const disableByLocation = (!locationFromId && !areaFromId) || (!locationToId && !areaToId);

  useEffect(() => {
    if (selected) {
      setLocationFromId(selected?.locationFromId || '');
      setAreaFromId(selected?.areaFromId || '');
      setLocationToId(selected?.locationToId || '');
      setAreaToId(selected?.areaToId || '');
    }
  }, [selected]);

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(
        SUB_SERVICES_ID.CUSTOMS_FCL,
        true,
      );
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          if (!selected?.isNew) {
            const ind = selected?.arrSurcharge?.findIndex(
              (el) => el.feeGroupID === item.feeGroupID,
            );
            if (ind !== -1) {
              item.surcharge = selected?.arrSurcharge[ind]?.surcharge || [];
              item.checked = true;
            } else {
              item.checked = false;
            }
          } else {
            item.checked = false;
          }
          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDataInits = async () => {
    let [getUnit, getLocation, getArea, getCargoType, getCurrency] = await Promise.all([
      ApiServices.getUnits(),
      ApiServices.getLocationVTs(),
      ApiServices.getAreas(),
      ApiServices.getCargoTypes(),
      ApiServices.getCurrencies(),
    ]);
    setUnitList(getUnit.data || []);
    setLocationVTList(getLocation.data || []);
    setAreaList(getArea.data || []);
    setCargoTypeList(getCargoType.data || []);
    setCurrencyList(getCurrency.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoading(true);
      await getDataInits();
      setLoading(false);
    };
    getDataInit();
  }, []);

  useEffect(() => {
    if (selected) {
      getSurchargeByFeeGroupSS();
    }
  }, [selected]);

  const handleCheckFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.checked = event.target.checked;
      }
      return el;
    });
    setGroupFeeGroup(data);
  };

  const handleCloseCheckedItem = (item) => {
    const ind = groupFeeGroup.findIndex((i) => i.feeGroupID === item.feeGroupID);
    if (ind !== -1) {
      const data = groupFeeGroup;
      data[ind].checked = false;
      setGroupFeeGroup(data);
    }
  };
  const handleChangeLocationFrom = useCallback(
    async (e) => {
      setLocationFromId(e.target.value);
      setAreaFromId('');
    },
    [locationFromId],
  );
  const handleChangeAreaFrom = useCallback(
    async (e) => {
      setAreaFromId(e.target.value);
      setLocationFromId('');
    },
    [areaFromId],
  );
  const handleChangeLocationTo = useCallback(
    async (e) => {
      setLocationToId(e.target.value);
      setAreaToId('');
    },
    [locationToId],
  );
  const handleChangeAreaTo = useCallback(
    async (e) => {
      setAreaToId(e.target.value);
      setLocationToId('');
    },
    [areaToId],
  );
  const handleChangeDataFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    setGroupFeeGroup(data);
  };

  const filterDataIsChecked = () => {
    return groupFeeGroup
      ?.filter((el) => el.checked)
      ?.map((item) => {
        return {
          feeGroupID: item?.feeGroupID,
          surcharge: item?.surcharge,
        };
      });
  };

  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (groupFeeGroup?.length > 0) {
      values.arrSurcharge = filterDataIsChecked();
    } else {
      values.arrSurcharge = [];
    }
    values.serviceId = SERVICES_ID.CUSTOMS; // Thủ tục hải quan
    values.subServiceId = SUB_SERVICES_ID.CUSTOMS_FCL; // LCL Thủ tục hải quan
    if (locationFromId) {
      values.locationFromId = locationFromId;
    }
    if (areaFromId) {
      values.areaFromId = areaFromId;
    }
    if (locationToId) {
      values.locationToId = locationToId;
    }
    if (areaToId) {
      values.areaToId = areaToId;
    }
    delete values.isNew;
    try {
      const res = await ApiServices.createCustoms(values);
      if (res.status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        onCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const updateFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (groupFeeGroup?.length > 0) {
      values.arrSurcharge = filterDataIsChecked();
    } else {
      values.arrSurcharge = [];
    }
    if (!values.expirydate) {
      delete values.expirydate;
    }
    values.locationFromId = locationFromId;
    values.areaFromId = areaFromId;
    values.locationToId = locationToId;
    values.areaToId = areaToId;
    try {
      const { data } = await ApiServices.updateCustoms(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        onCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const renderFeeGroup = (item) => (
    <FormGroup key={item.feeGroupID}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={
          <Checkbox
            checked={item.checked}
            onChange={(event) => handleCheckFeeGroup(event, item)}
            disabled={disableByStatus}
          />
        }
        label={item.feeGroupName}
      />
      {item.checked && (
        <CustomsSubChargeForm
          arrSurcharge={item.surchargeListFree}
          arrUnit={unitList}
          name={item.feeGroupID}
          onChange={(event) => handleChangeDataFeeGroup(event, item)}
          value={item?.surcharge}
          closeCheckedItem={() => handleCloseCheckedItem(item)}
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          disabledField={disableByStatus}
          showQuantity={false}
        />
      )}
    </FormGroup>
  );

  const ARRAY_INPUT = [
    {
      type: SELECTION,
      field: 'status',
      label: 'Trang thái',
      fieldValue: 'value',
      fieldView: 'label',
      dataArrObject: PRICE_BOARD_STATUS_OPTIONS,
      disabled: selected?.isNew || disableByStatus,
      isShowItem: true,
    },
    {
      type: SELECTION,
      field: 'currencyCode',
      label: 'Đơn vị tiền tệ',
      dataArrObject: currencyList,
      fieldValue: 'code',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    { type: DATE_PICKER, field: 'expirydate', label: 'Hết hạn' },
    {
      value: locationFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(areaFromId) || disableByStatus,
      label: 'Điểm đi',
      type: SELECTION_LOCATION,
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationFrom,
    },
    {
      type: SELECTION_LOCATION,
      value: areaFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(locationFromId) || disableByStatus,
      label: 'Khu vực đi',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaFrom,
    },
    {
      type: SELECTION_LOCATION,
      value: locationToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(areaToId) || disableByStatus,
      label: 'Điểm đến',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationTo,
    },
    {
      type: SELECTION_LOCATION,
      value: areaToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(locationToId) || disableByStatus,
      label: 'Khu vực đến',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaTo,
    },
    { type: TEXT_INPUT, field: 'note', label: 'Ghi chú', disabled: disableByStatus },
  ];

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={onCloseModal}
        title={selected?.isNew ? 'Thêm mới' : 'Thông tin chi tiết'}
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <Divider sx={{ marginY: 1 }}>Thông tin cơ bản</Divider>
        {!loading && (
          <Formik
            initialValues={{
              status: 0,
              currencyCode: '',
              expirydate: '',
              note: '',
              arrTypeCargoIdCheck: [],
              arrCont: [],
              ...selected,
            }}
            validationSchema={Yup.object().shape({
              expirydate: Yup.string().max(255).required('Vui lòng chọn ngày hết hạn'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (selected?.isNew) {
                createFn(values, { setSubmitting });
              } else {
                updateFn(values, { setSubmitting });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {ARRAY_INPUT.map((item, index) => {
                      switch (item.type) {
                        case SELECTION:
                          return (
                            <Grid
                              key={`${item?.type}-${item?.field}-${index}`}
                              item
                              xs={4}
                              sm={6}
                              md={3}
                            >
                              <Selection
                                sxInput={{ padding: '0px', margin: '0px' }}
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item.label}
                                name={item.field}
                                data={item?.data}
                                disabled={item?.disabled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item.field]}
                                fieldValue={item?.fieldValue}
                                fieldView={item?.fieldView}
                                syncData={item?.syncData}
                                dataArrObject={item?.dataArrObject}
                              />
                            </Grid>
                          );
                        case SELECTION_LOCATION:
                          return (
                            <Grid
                              key={`${item?.type}-${item.label + index}`}
                              item
                              xs={4}
                              sm={6}
                              md={3}
                            >
                              <Selection
                                sxInput={{ padding: '0px', margin: '0px' }}
                                error={item?.isError}
                                helperText={item?.isError && `Vui lòng chọn ${item?.label}`}
                                label={item.label}
                                name={item.field}
                                disabled={item?.disabled}
                                onChange={item?.handleChange}
                                value={item?.value}
                                fieldValue={item?.fieldValue}
                                fieldView={item?.fieldView}
                                syncData={item?.syncData}
                                dataArrObject={item?.dataArrObject}
                                disabledItemNone={false}
                              />
                            </Grid>
                          );
                        case DATE_PICKER:
                          return (
                            <Grid key={`${item?.type}-${item.field}`} item xs={4} sm={6} md={3}>
                              <TextInput
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item.label}
                                name={item.field}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item.field]}
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={item?.disabled}
                              />
                            </Grid>
                          );
                        default:
                          return (
                            <Grid key={`${item?.type}-${item.field}`} item xs={4} sm={6} md={3}>
                              <TextInput
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item.label}
                                name={item.field}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item.field]}
                                disabled={item?.disabled}
                              />
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                </Box>
                <Divider sx={{ marginY: 1 }}>Phí khai báo hải quan</Divider>
                <Selection
                  sx={{ width: '50%', marginBottom: '10px' }}
                  error={Boolean(touched.arrTypeCargoIdCheck && errors.arrTypeCargoIdCheck)}
                  helperText={touched.arrTypeCargoIdCheck && errors.arrTypeCargoIdCheck}
                  label="Loại hàng"
                  name="arrTypeCargoIdCheck"
                  multiple={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.arrTypeCargoIdCheck || []}
                  disabled={disableByStatus}
                  fieldValue="id"
                  fieldView="name"
                  dataArrObject={cargoTypeList}
                />
                {values?.arrTypeCargoIdCheck?.length > 0 && (
                  <CustomsCargoTypeForm
                    arrContType={values.arrTypeCargoIdCheck}
                    cargoTypeList={cargoTypeList}
                    name="arrCont"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.arrCont}
                    disabledField={disableByStatus}
                  />
                )}
                {groupFeeGroup?.map(renderFeeGroup)}

                <Box sx={{ display: 'flex', py: 2 }}>
                  {disableByLocation && <ErrorText>Vui lòng nhập đầy đủ thông tin</ErrorText>}
                  <Box sx={{ marginLeft: 'auto' }}>
                    {!selected?.expirationStatus && (
                      <Button
                        color="primary"
                        disabled={isSubmitting || disableByLocation}
                        type="submit"
                        variant="contained"
                      >
                        {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                      </Button>
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};

const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '14px',
  marginLeft: '10px',
  fontWeight: 'bold',
}));
