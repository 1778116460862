import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  CardContent,
  Card,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as appActions from 'store/reducers/app/action';
import ApiServices from 'services';
import Storage from 'storage';

const ChangeEmail = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  const submitChangePassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const userId = Storage?.user?.Id;

    try {
      const { status } = await ApiServices.updateEmailUser(userId, values);
      if (status === 200) {
        dispatch(appActions.signIn({ ...Storage?.user, UserMail: values.user }));
        values.password = '';
        window.UIMessage?.success('Cập nhật Email thành công');
      }
    } catch (error) {
      console.log(error);
      window.UIMessage?.error('Cập nhật Email thất bại');
    }
    setSubmitting(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: 'background.default',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                user: Storage?.user?.UserMail,
                password: '',
              }}
              validationSchema={Yup.object().shape({
                user: Yup.string()
                  .max(255)
                  .email('Email không hợp lệ')
                  .required('Vui lòng nhập tài khoản'),
                password: Yup.string().max(255).required('Vui lòng nhập mật khẩu mới'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                submitChangePassword(values, { setSubmitting });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                      sx={{ fontWeight: 'h3', textAlign: 'center' }}
                    >
                      Cập nhật Email
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.user && errors.user)}
                    fullWidth
                    helperText={touched.user && errors.user}
                    label="Email"
                    margin="normal"
                    name="user"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Mật khẩu"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? 'Đang tải...' : 'XÁC NHẬN'}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Grid>
    </Box>
  );
};

export { ChangeEmail };
