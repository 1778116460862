import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';

export default function ({ open, setOpen, onChange }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Thêm công thức tính</DialogTitle>

      <Formik
        initialValues={{
          low: 0,
          high: 0,
          result: '',
        }}
        validationSchema={Yup.object().shape({
          result: Yup.string().max(255).required('Vui lòng nhập Tên'),
          low: Yup.string().min(-1, 'Vui lòng nhập lớn hơn 0'),
          high: Yup.string().min(-1, 'Vui lòng nhập lớn hơn 0'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onChange((prev) => [
            ...prev,
            {
              range: {
                low: values.low,
                high: values.high,
              },
              result: values.result,
            },
          ]);
          handleClose();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '480px' }}>
            <DialogContent>
              <DialogContentText>
                Vui lòng thêm công thức tính cho các tiêu chí đánh giá
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                type="number"
                label="Nhập giá trị bắt đầu"
                fullWidth
                variant="standard"
                name="low"
                error={Boolean(touched.low && errors.low)}
                helperText={touched.low && errors.low}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.low}
              />
              <TextField
                margin="dense"
                type="number"
                label="Nhập giá trị Kết thúc"
                fullWidth
                variant="standard"
                name="high"
                error={Boolean(touched.high && errors.high)}
                helperText={touched.high && errors.high}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.high}
              />
              <TextField
                margin="dense"
                type="text"
                label="Kết quả"
                fullWidth
                variant="standard"
                name="result"
                error={Boolean(touched.result && errors.result)}
                helperText={touched.result && errors.result}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.result}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>hủy</Button>
              <Button type="submit">Thêm mới</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
