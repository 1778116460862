import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useSelector, useDispatch } from 'react-redux';
import { addResCheckPrice, deleteResCheckPrice } from 'store/reducers/res-check-price/action';
import InputItem from './input-item';

export const ItemForm = ({
  dataInit,
  selected,
  contTypeCodeCheck,
  packingAirCodeCheck,
  name,
  requiredTitle = 'Vui lòng nhập thông tin',
  value,
  itemData,
  label = '',
  onChange,
  error = false,
  helperText = '',
  dataFeeGroupList,
}) => {
  const initialValue = {
    carrierId: '',
    etd: '',
    expirydate: '',
    arrContFreight: [],
    checkPriceId: dataInit?.checkPriceId,
    contTypeCodeCheck,
    packingAirCodeCheck,
    currencyCode: dataInit?.currencyCode,
    podId: dataInit?.podId,
    polId: dataInit?.polId,
    serviceId: dataInit?.serviceId,
    subServiceId: dataInit?.subServiceId,
    checkFreightRequest: dataInit?.checkFreightRequest,
    arrSurchargeFreight: [],
  };
  const dispatch = useDispatch();
  const [values, setValues] = React.useState([initialValue]);
  const initRef = React.useRef(true);

  const onAdd = () => {
    dispatch(addResCheckPrice(itemData?.id, initialValue));
    setValues((prev) => {
      let tmp = [...prev];
      tmp.push({
        carrierId: '',
        etd: '',
        expirydate: '',
        currencyCode: '',
        arrContFreight: [],
        checkPriceId: dataInit?.checkPriceId,
        contTypeCodeCheck,
        currencyCode: dataInit?.currencyCode,
        packingAirCodeCheck,
        podId: dataInit?.podId,
        polId: dataInit?.polId,
        serviceId: dataInit?.serviceId,
        subServiceId: dataInit?.subServiceId,
        checkFreightRequest: dataInit?.checkFreightRequest,
        arrSurchargeFreight: [],
      });
      return tmp;
    });
  };
  const onDelete = (index) => {
    if (values && values.length === 1) {
      setValues([initialValue]);
    } else {
      dispatch(deleteResCheckPrice(itemData?.id, index));
      setValues((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
    }
  };

  React.useEffect(() => {
    if (initRef.current && value?.length) {
      setValues(value);
      initRef.current = false;
    }
  }, [value]);
  React.useEffect(() => {
    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: values,
        },
      });
  }, [values]);
  const renderItems = (item, index) => {
    return (
      <InputItem
        key={'item-res-check-freight-' + index}
        item={item}
        index={index}
        onAdd={onAdd}
        onDelete={onDelete}
        values={values}
        itemData={itemData}
        setValues={setValues}
        error={error}
        contTypeCodeCheck={contTypeCodeCheck}
        packingAirCodeCheck={packingAirCodeCheck}
        dataFeeGroupList={dataFeeGroupList}
      />
    );
  };
  return (
    <FormGroup margin="dense">
      <FormLabel>{label}</FormLabel>
      {values.map((item, index) => renderItems(item, index))}
      <FormHelperText error={error}>{helperText && requiredTitle}</FormHelperText>
    </FormGroup>
  );
};
