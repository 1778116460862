import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { BsSave, BsPlusCircle } from 'react-icons/bs';
import { ModalFormDetail, TextInput, Selection } from 'components';
import { LoadingView } from 'components/loading-view';
import { Box, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Formik } from 'formik';
import { PRICE_BOARD_STATUS_OPTIONS, SUB_SERVICES_ID, SERVICES_ID } from 'commons/constants';
import ApiServices from 'services';
import HeaderFormDetail from '../header-form-detail';
import { TruckingSubChargeForm } from '../forms-management/trucking-sub-charge-form';
import { TruckingReturnContForm } from '../forms-management/trucking-return-cont-form';
import { TruckingPriceForm } from '../forms-management/trucking-price-form';
import { BtnCreateUpdate } from '../styles';

export const FormDetail = ({ selected, onCloseModal, initData, ...props }) => {
  const disableByStatus = useMemo(() => {
    return selected?.status === 1;
  }, [selected?.id]);

  const [loading, setLoading] = useState(false);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [locationVTList, setLocationVTList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [showReturnCont, setShowReturnCont] = useState(false);

  // const [dataError, setDataError] = useState(false);

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(SUB_SERVICES_ID.TRACTOR, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          if (!selected?.isNew) {
            const ind = selected?.arrSurcharge?.findIndex((i) => i.feeGroupID === item.feeGroupID);
            if (ind !== -1) {
              item.surcharge = selected?.arrSurcharge[ind]?.surcharge || [];
              item.checked = true;
            } else {
              item.checked = false;
            }
          } else {
            item.checked = false;
          }
          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDataInits = async () => {
    let [getUnit, getLocation, getArea, getCargoType, getCurrency, getVendor] = await Promise.all([
      ApiServices.getUnits(),
      ApiServices.getLocationVTs(),
      ApiServices.getAreas(),
      ApiServices.getCargoTypes(),
      ApiServices.getCurrencies(),
      ApiServices.getLeaseVendors(),
    ]);
    setUnitList(getUnit.data || []);
    setLocationVTList(getLocation.data || []);
    setAreaList(getArea.data || []);
    setCargoTypeList(getCargoType.data || []);
    setCurrencyList(getCurrency.data || []);
    setVendorList(getVendor.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoading(true);
      await getDataInits();
      setLoading(false);
    };
    getDataInit();
  }, []);

  useEffect(() => {
    if (selected) {
      getSurchargeByFeeGroupSS();
      if (selected?.subChargeNewPort && selected?.subChargeNewPort?.length > 0) {
        setShowReturnCont(true);
      }
    }
  }, [selected]);

  const handleCheckFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.checked = event.target.checked;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };

  const handleCloseCheckedItem = (item) => {
    const ind = groupFeeGroup.findIndex((i) => i.feeGroupID === item.feeGroupID);
    if (ind !== -1) {
      const data = groupFeeGroup;
      data[ind].checked = false;
      setGroupFeeGroup(data);
    }
  };

  const handleCheckedReturnCont = (event) => {
    setShowReturnCont(event.target.checked);
  };

  const handleChangeDataFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((item2) => {
      if (item2?.feeGroupID === item?.feeGroupID) {
        item2.surcharge = event.target.value;
      }
      return item2;
    });
    setGroupFeeGroup(data);
  };

  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!showReturnCont) {
      values.subChargeNewPort = [];
    }
    if (groupFeeGroup && groupFeeGroup.length > 0) {
      const data = groupFeeGroup
        .filter((el) => el.checked)
        .map((item) => {
          return {
            feeGroupID: item?.feeGroupID,
            surcharge: item?.surcharge,
          };
        });
      values.arrSurcharge = data;
    } else {
      values.arrSurcharge = [];
    }
    values.serviceId = SERVICES_ID.TRUCKING; // Thủ tục hải quan
    values.subServiceId = SUB_SERVICES_ID.TRACTOR; // LCL Thủ tục hải quan
    if (!values.locationFromId) {
      delete values.locationFromId;
    }
    if (!values.areaFromId) {
      delete values.areaFromId;
    }
    if (!values.locationToId) {
      delete values.locationToId;
    }
    if (!values.areaToId) {
      delete values.areaToId;
    }
    delete values.isNew;
    try {
      const res = await ApiServices.createTrucking(values);
      if (res.status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        onCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!showReturnCont) {
      values.subChargeNewPort = [];
    }
    if (groupFeeGroup && groupFeeGroup.length > 0) {
      const data = groupFeeGroup
        .filter((el) => el.checked)
        .map((item) => {
          return {
            feeGroupID: item?.feeGroupID,
            surcharge: item?.surcharge,
          };
        });
      values.arrSurcharge = data;
    } else {
      values.arrSurcharge = [];
    }
    if (!values.expirydate) {
      delete values.expirydate;
    }
    if (!values.locationFromId) {
      delete values.locationFromId;
    }
    if (!values.areaFromId) {
      delete values.areaFromId;
    }
    if (!values.locationToId) {
      delete values.locationToId;
    }
    if (!values.areaToId) {
      delete values.areaToId;
    }
    try {
      const { data } = await ApiServices.updateTrucking(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        onCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const renderFeeGroup = (item) => (
    <FormGroup key={item.feeGroupID}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={
          <Checkbox
            checked={item.checked}
            onChange={(event) => handleCheckFeeGroup(event, item)}
            disabled={disableByStatus}
          />
        }
        label={item.feeGroupName}
      />
      {item.checked && (
        <TruckingSubChargeForm
          arrSurcharge={item.surchargeListFree}
          arrUnit={unitList}
          name={item.feeGroupID}
          onChange={(event) => handleChangeDataFeeGroup(event, item)}
          value={item?.surcharge}
          closeCheckedItem={() => handleCloseCheckedItem(item)}
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          disabledField={disableByStatus}
        />
      )}
    </FormGroup>
  );

  const ARRAY_INPUT = [
    // { field: 'code', lable: 'Mã', disabled: !selected?.isNew },
    {
      field: 'status',
      lable: 'Trang thái',
      type: 'selection',
      dataArrObject: PRICE_BOARD_STATUS_OPTIONS,
      disabled: selected?.isNew || disableByStatus,
      isShowItem: true,
    },
    {
      field: 'locationFromId',
      lable: 'Điểm đi',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
      disabledByKeys: ['areaFromId'],
      disabledItemNone: false,
    },
    {
      field: 'areaFromId',
      lable: 'Khu vực đi',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
      disabledByKeys: ['locationFromId'],
      disabledItemNone: false,
    },
    {
      field: 'locationToId',
      lable: 'Điểm đến',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
      disabledByKeys: ['areaToId'],
      disabledItemNone: false,
    },
    {
      field: 'areaToId',
      lable: 'Khu vực đến',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
      disabledByKeys: ['locationToId'],
      disabledItemNone: false,
    },
    {
      field: 'currencyCode',
      lable: 'Đơn vị tiền tệ',
      type: 'selection',
      dataArrObject: currencyList,
      fieldValue: 'code',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'vendorId',
      lable: 'Nhà xe',
      type: 'selection',
      dataArrObject: vendorList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    { field: 'expirydate', lable: 'Hết hạn', type: 'date-picker' },
    { field: 'note', lable: 'Ghi chú', disabled: disableByStatus },
  ];

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={onCloseModal}
        title={selected?.isNew ? 'Thêm mới' : 'Thông tin chi tiết'}
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <Divider sx={{ marginY: 1 }}>Thông tin cơ bản</Divider>
        {!loading && (
          <Formik
            initialValues={{
              // code: '',
              status: 0,
              locationFromId: '',
              areaFromId: '',
              locationToId: '',
              areaToId: '',
              currencyCode: '',
              vendorId: '',
              expirydate: '',
              note: '',
              arrTypeCargoCode: [],
              arrContTractor: [],
              subChargeNewPort: [],
              ...selected,
            }}
            validationSchema={Yup.object().shape({
              // code: Yup.string().max(255).required('Vui lòng nhập mã'),
              // locationFromId: Yup.string().max(255).required('Vui lòng chọn điểm đi'),
              // areaFromId: Yup.string().max(255).required('Vui lòng chọn khu vực đi'),
              // locationToId: Yup.string().max(255).required('Vui lòng chọn điểm đến'),
              // areaToId: Yup.string().max(255).required('Vui lòng chọn khu vực đến'),
              currencyCode: Yup.string().max(255).required('Vui lòng chọn đơn vị tiền tệ'),
              vendorId: Yup.string().max(255).required('Vui lòng chọn nhà xe'),
              expirydate: Yup.string().max(255).required('Vui lòng chọn ngày hết hạn'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (selected?.isNew) {
                createFn(values, { setSubmitting });
              } else {
                updateFn(values, { setSubmitting });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {ARRAY_INPUT.map((item) => {
                      if (item.type == 'selection') {
                        if (selected?.expirationStatus && item.isShowItem) {
                          return <></>;
                        } else {
                          return (
                            <Grid key={`selection-${item.field}`} item xs={4} sm={6} md={3}>
                              <Selection
                                sxInput={{ padding: '0px', margin: '0px' }}
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item.lable}
                                name={item.field}
                                data={item?.data}
                                disabled={
                                  item?.disabledByKeys
                                    ? values[item?.disabledByKeys]
                                      ? true
                                      : false
                                    : item?.disabled
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item.field]}
                                fieldValue={item?.fieldValue}
                                fieldView={item?.fieldView}
                                syncData={item?.syncData}
                                dataArrObject={item?.dataArrObject}
                                disabledItemNone={item?.disabledItemNone}
                              />
                            </Grid>
                          );
                        }
                      }
                      if (item.type == 'date-picker') {
                        return (
                          <Grid key={`date-picker-${item.field}`} item xs={4} sm={6} md={3}>
                            <TextInput
                              error={Boolean(touched[item.field] && errors[item.field])}
                              helperText={touched[item.field] && errors[item.field]}
                              label={item.lable}
                              name={item.field}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values[item.field]}
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={item?.disabled}
                            />
                          </Grid>
                        );
                      }
                      return (
                        <Grid key={`input-${item.field}`} item xs={4} sm={6} md={3}>
                          <TextInput
                            error={Boolean(touched[item.field] && errors[item.field])}
                            helperText={touched[item.field] && errors[item.field]}
                            label={item.lable}
                            name={item.field}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values[item.field]}
                            disabled={item?.disabled}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Divider sx={{ marginY: 1 }}>Giá vận chuyển</Divider>
                <Selection
                  sx={{ width: '50%', marginBottom: '10px' }}
                  error={Boolean(touched.arrTypeCargoCode && errors.arrTypeCargoCode)}
                  helperText={touched.arrTypeCargoCode && errors.arrTypeCargoCode}
                  label="Loại hàng"
                  name="arrTypeCargoCode"
                  multiple={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.arrTypeCargoCode}
                  disabled={disableByStatus}
                  fieldValue="id"
                  fieldView="name"
                  dataArrObject={cargoTypeList}
                />
                {values.arrTypeCargoCode && values.arrTypeCargoCode.length > 0 && (
                  <TruckingPriceForm
                    arrContType={values.arrTypeCargoCode}
                    cargoTypeList={cargoTypeList}
                    name="arrContTractor"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.arrContTractor}
                    disabledField={disableByStatus}
                  />
                )}
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '50%' }}
                    control={
                      <Checkbox checked={showReturnCont} onChange={handleCheckedReturnCont} />
                    }
                    label="Lấy hoặc trả cont ở cảng khác"
                    disabled={disableByStatus}
                  />
                </FormGroup>
                {showReturnCont && (
                  <TruckingReturnContForm
                    locationVTList={locationVTList}
                    unitList={unitList}
                    name="subChargeNewPort"
                    requiredTitle="Vui lòng nhập đầy đủ thông tin"
                    onChange={handleChange}
                    value={values.subChargeNewPort}
                    disabledField={disableByStatus}
                  />
                )}
                {groupFeeGroup?.map(renderFeeGroup)}

                <Box sx={{ display: 'flex', py: 2 }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    {!selected?.expirationStatus && (
                      <BtnCreateUpdate
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        startIcon={selected?.isNew ? <BsPlusCircle /> : <BsSave />}
                      >
                        {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                      </BtnCreateUpdate>
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
