import { SearchInputColumn } from 'components';
export const Columns = (onColumnSearch) => [
  {
    id: 'code',
    disablePadding: false,
    label: <SearchInputColumn name="code" title="Mã chức vụ" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
  {
    id: 'nameVI',
    disablePadding: false,
    label: <SearchInputColumn name="nameVI" title="Tên chức vụ (Vi)" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
  {
    id: 'nameEN',
    disablePadding: false,
    label: <SearchInputColumn name="nameEN" title="Tên chức vụ (En)" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
];
