import React from 'react';
import {
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  IconButton,
  Button,
  FormControl,
  Avatar,
  Tooltip,
} from '@mui/material';
import { BsFillTrashFill, BsPlus } from 'react-icons/bs';
import ApiServices from 'services';
import { ComboBox } from 'components';

export const PopupUserSupporter = ({ clientCareHistoryItem, getClientCareHistoryDetails }) => {
  const [personName, setPersonName] = React.useState([]);
  const [dataMainUserById, setDataMainUserById] = React.useState([]);
  const [arrUserSupportFulll, setArrUserSupportFulll] = React.useState([]);

  const getListByPositionId = async (position, idClientCareHistory = '') => {
    try {
      const { data } = await ApiServices.getListByPositionId(position, idClientCareHistory);
      if (data && idClientCareHistory) {
        setDataMainUserById(data);
      } else if (data && !idClientCareHistory) {
        setArrUserSupportFulll(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const getDataInit = async () => {
      await getListByPositionId('admin');
      await getListByPositionId('admin', clientCareHistoryItem.id);
    };
    getDataInit();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const updateClientandUserMain = async (id, data) => {
    try {
      const { status } = await ApiServices.updateClientandUserMain(id, data);
      if (status === 200) {
        getClientCareHistoryDetails(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onAddUserMainSupport = async () => {
    if (personName) {
      const idSend = personName.map((item) => item.code);
      await updateClientandUserMain(clientCareHistoryItem.id, {
        arrMainUserCode: idSend.concat(clientCareHistoryItem.arrMainUserCode),
      });
      await getListByPositionId('admin', clientCareHistoryItem.id);
      await setPersonName([]);
    }
  };

  const onHandleChangeFn = async (event, newValue) => {
    setPersonName(newValue);
  };

  const onDelectUserMain = async (code) => {
    const mapDataIdMainClient = clientCareHistoryItem?.arrMainUserobj.map((item) => item.code);
    const deleteItem = mapDataIdMainClient.filter((item) => item !== code);
    await updateClientandUserMain(clientCareHistoryItem.id, { arrMainUserCode: deleteItem });
    await getListByPositionId('admin', clientCareHistoryItem.id);
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
        <FormControl
          sx={{
            m: 1,
            width: 500,
            borderRadius: '50px',
            textAlign: 'center',
            justifyContent: 'center',
            padding: '8px 0px 8px 0px',
          }}
        >
          <ComboBox
            multiple
            disableCloseOnSelect
            options={dataMainUserById}
            label="Người phụ trách chính"
            sx={{ width: 430 }}
            keyField="name"
            value={personName}
            onChange={(event, newValue) => onHandleChangeFn(event, newValue)}
          />
        </FormControl>
        <Button
          variant="contained"
          size="small"
          startIcon={<BsPlus />}
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            marginLeft: '18px',
            color: '#FFFFFF',
            backgroundColor: '#21409A',
            '.MuiButton-endIcon': {
              marginLeft: '0px',
            },
            padding: '5px 15px 5px 10px',
          }}
          onClick={onAddUserMainSupport}
        >
          Thêm
        </Button>
      </Stack>

      <Box
        sx={{
          width: '100%',
          minHeight: '250px',
          maxHeight: '600px',
          backgroundColor: '#FFF',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '5px',
        }}
      >
        <Grid container>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Avatar
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Mã
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Tên
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Tác vụ
            </Typography>
          </Grid>
        </Grid>
        {/* body - mapdata */}
        {clientCareHistoryItem?.arrMainUserobj?.map((item, index) => (
          <div key={item.code}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={2} sx={{ padding: 1, paddingLeft: 2 }}>
                <Avatar
                  alt={item.code}
                  src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                />
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                {item?.code}
              </Grid>
              <Grid item xs={5} sx={{ padding: 1 }}>
                {item?.name}
              </Grid>
              <Grid item xs={2} sx={{ padding: 1, paddingLeft: 3 }}>
                <Tooltip title="Xóa" placement="top-start" arrow>
                  <IconButton
                    aria-label="add to shopping cart"
                    color="primary"
                    size="small"
                    onClick={() => onDelectUserMain(item.code)}
                  >
                    <BsFillTrashFill />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
    </>
  );
};
