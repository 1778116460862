import React from 'react';
import { Box } from '@mui/material';
import { GeneralInfo } from './general-info';
import { ContractAppendix } from './contract-appendix';

const ContractForm = ({ selectedContract, getContractDetail, initData, isReadOnly = false }) => {
  return (
    <Box>
      <GeneralInfo
        selectedContract={selectedContract}
        getContractDetail={getContractDetail}
        initData={initData}
        isReadOnly={isReadOnly}
      />

      {/* Phụ lục hợp đồng */}
      <ContractAppendix
        isReadOnly={isReadOnly}
        selectedContract={selectedContract}
        getContractDetail={getContractDetail}
      />
    </Box>
  );
};

export { ContractForm };
