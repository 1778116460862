import React from 'react';
import { Button } from '@mui/material';
import Utils from 'commons/utils';
import { PRICE_BOARD_STATUS } from 'commons/constants';
import { RenderPriceBoardState, RenderPriceBoardStatus } from 'components/custom-status';
import { RenderAreaAndLocationFrom, RenderAreaAndLocationTo } from 'components/custom-location';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Trạng thái',
    align: 'center',
    render: (row) => <RenderPriceBoardStatus status={row?.status} />,
  },
  {
    id: 'expirationStatus',
    numeric: true,
    disablePadding: false,
    label: 'Tình trạng',
    align: 'center',
    render: (row) =>
      row?.expirationStatus && <RenderPriceBoardState state={row?.expirationStatus} />,
  },
  {
    id: 'objLocationFrom',
    numeric: true,
    disablePadding: false,
    label: 'Địa điểm/ Khu vực đi',
    align: 'left',
    width: '250px',
    render: (row) => (
      <RenderAreaAndLocationFrom areaFrom={row?.objAreaFrom} locationFrom={row?.objLocationFrom} />
    ),
  },
  {
    id: 'objLocationTo',
    numeric: true,
    disablePadding: false,
    label: 'Địa điểm/ Khu vực đến',
    align: 'center',
    width: '250px',
    align: 'left',
    render: (row) => (
      <RenderAreaAndLocationTo areaTo={row?.objAreaTo} locationTo={row?.objLocationTo} />
    ),
  },
  {
    id: 'currencyCode',
    numeric: true,
    disablePadding: false,
    label: 'Đơn vị tiền tệ',
    align: 'center',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.createdOn),
  },
  {
    id: 'updatedOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày chỉnh sửa cuối',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.updatedOn),
  },
  {
    id: 'expirydate',
    numeric: true,
    disablePadding: false,
    label: 'Hết hạn',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.expirydate),
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) =>
      row?.status !== PRICE_BOARD_STATUS.APPROVED && (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteContTypeItem(row))}
        >
          Xoá
        </Button>
      ),
  },
];
