import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { BsPlusCircle, BsArrowDownCircle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import {
  selectQuoteId,
  clearQuoteId,
  selectContractData,
  clearContractData,
} from 'store/reducers/contract/action';
import { Table } from 'components';
import { getColumnsLeader } from './columns-leader';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { ContainerHeader, BtnCreateContract, ButtonExport } from './styles';
// import storage from 'storage';
import { CreateForm } from './create-form';
import { UpdateForm } from './update-form';
import { ModalFormDetail } from 'components';

export function TabLeader() {
  const dispatch = useDispatch();
  const [contractList, setContractList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const onCloseModal = () => {
    setSelected(null);
    dispatch(clearQuoteId());
    dispatch(clearContractData());
  };

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    status: [0],
  });
  const reloadRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  useEffect(() => {
    if (loading) return;
    if (query) {
      clearTimeout(reloadRef.current);
      reloadRef.current = setTimeout(() => initData(), 100);
    }
  }, [query]);

  const onSearchInputChange = (value, key, searchArray = false) => {
    clearTimeout(searchInputRef.current);
    if (searchArray) {
      let newQuery = { ...query };
      if (!value) {
        delete newQuery.searchArray[key];
      } else {
        newQuery.searchArray = { ...newQuery.searchArray, [key]: value };
      }

      searchInputRef.current = setTimeout(() => setQuery(newQuery), 500);
    } else {
      searchInputRef.current = setTimeout(
        () => setQuery((prev) => ({ ...prev, [key]: value })),
        500,
      );
    }
  };

  const initData = React.useCallback(
    async (pagination = {}) => {
      setLoading(true);
      try {
        const { data } = await ApiServices.getAllPagingContracts({ ...query, ...pagination });
        if (totalRecords !== data?.totalRecords) setTotalRecords(data?.totalRecords);
        console.log({ data });
        setContractList(data?.data || []);
      } catch (err) {
        console.warn('initData - Clients', err);
      }
      setLoading(false);
    },
    [query, totalRecords],
  );

  const deleteItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteContract(row?.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('delete contract', err);
    }
    setLoading(false);
  };

  const handleExportClient = () => {
    setLoading(true);
    return ApiServices.exportExcelContract()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `DS_Khách_Hợp Đồng_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Contract file error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.signedDate = Utils.checkIsDate(row.signedDate)
      ? Utils.convertToDateFormat(row?.signedDate)
      : null;
    dataSelect.effectiveDate = Utils.checkIsDate(row.effectiveDate)
      ? Utils.convertToDateFormat(row?.effectiveDate)
      : null;
    dataSelect.extensionDate = Utils.checkIsDate(row.extensionDate)
      ? Utils.convertToDateFormat(row?.extensionDate)
      : null;
    dataSelect.expireDate = Utils.checkIsDate(row.expireDate)
      ? Utils.convertToDateFormat(row?.expireDate)
      : null;
    dataSelect.paymentTermDate = Utils.checkIsDate(row.paymentTermDate)
      ? Utils.convertToDateFormat(row?.paymentTermDate)
      : null;
    return dataSelect;
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Hợp đồng
        </Typography>
      </ContainerHeader>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClient}
      >
        Tải excel
      </ButtonExport>
      <BtnCreateContract
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </BtnCreateContract>
    </>
  );

  const handleSelected = (row) => {
    const dataSelect = validateValue(row);
    setSelected(dataSelect);
    dispatch(selectQuoteId(row?.arrQuotesId));
    dispatch(selectContractData(dataSelect));
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={contractList}
          headCells={getColumnsLeader(onSearchInputChange, deleteItem)}
          totalRecords={totalRecords}
          onPageChange={(paging) => {
            setQuery((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={handleSelected}
          loading={loading}
          isHeader={false}
        />

        {selected?.isNew ? (
          <ModalFormDetail
            open={selected}
            onCloseModal={onCloseModal}
            title="Thêm mới"
            maxWidth="md"
          >
            <CreateForm initData={initData} onCloseModal={onCloseModal} />
          </ModalFormDetail>
        ) : (
          <ModalFormDetail
            maxWidth="xl"
            open={selected}
            onCloseModal={onCloseModal}
            title="Cập nhật hợp đồng"
          >
            <UpdateForm
              isReadOnly={true}
              initData={initData}
              onCloseModal={onCloseModal}
              selected={selected}
            />
          </ModalFormDetail>
        )}
      </Box>
    </>
  );
}
