import React from 'react';
import { Box, Grid, Divider, Typography } from '@mui/material';
import Utils from 'commons/utils';

const ShowInfoCareOfService = ({ dataSelected, dataSelectedFull, dataSelectClientCareHistory }) => {
  return (
    <Box>
      <Divider sx={{ marginY: 1 }}>
        <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
          Thông tin cơ bản
        </Typography>
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <b>Mã: </b> <span style={{ color: 'blue' }}>{dataSelectedFull?.code}</span>
        </Grid>
        <Grid item xs={5}>
          <b>Khách hàng: </b> <span style={{ color: 'blue' }}>{dataSelectedFull?.name}</span>
        </Grid>
        <Grid item xs={4}>
          <b>Dịch vụ: </b> <span style={{ color: 'blue' }}>{dataSelected?.name}</span>
        </Grid>
      </Grid>
      {/* ============   Danh sách người liên hệ chính   ============ */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          marginTop: '15px',
        }}
      >
        <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
          Danh sách người liên hệ chính
        </Typography>
        <Divider />
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ padding: 1, textAlign: 'center', borderBottom: '1px solid #C0C0C0' }}
          >
            <Typography align="center" component="b" variant="string">
              Họ tên
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Số điện thoại
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Email
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Ngày sinh
            </Typography>
          </Grid>
        </Grid>
        {dataSelectClientCareHistory?.arrMainClientobj?.map((item) => (
          <div key={item.id}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1, textAlign: 'center' }}>
                {item?.fullName}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.phoneNumber}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.email}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.birthday && Utils.formatDateFn(item?.birthday)}
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
      {/* ============   Danh sách người liên hệ phụ   ============ */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          marginTop: '20px',
        }}
      >
        <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
          Danh sách người liên hệ phụ
        </Typography>
        <Divider />
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ padding: 1, textAlign: 'center', borderBottom: '1px solid #C0C0C0' }}
          >
            <Typography align="center" component="b" variant="string">
              Họ tên
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Số điện thoại
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Email
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Ngày sinh
            </Typography>
          </Grid>
        </Grid>
        {dataSelectClientCareHistory?.arrSecondaryClientobj?.map((item) => (
          <div key={item.id}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1, textAlign: 'center' }}>
                {item?.fullName}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.phoneNumber}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.email}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.birthday && Utils.formatDateFn(item?.birthday)}
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
      {/* ============   Danh sách đối tác   ============ */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          marginTop: '20px',
        }}
      >
        <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
          Danh sách đối tác
        </Typography>
        <Divider />
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ padding: 1, textAlign: 'center', borderBottom: '1px solid #C0C0C0' }}
          >
            <Typography align="center" component="b" variant="string">
              Đối tác
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Số điện thoại
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Email
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Fax
            </Typography>
          </Grid>
        </Grid>
        {dataSelectClientCareHistory?.arrVendorService?.map((item) => (
          <div key={item.id}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1, textAlign: 'center' }}>
                {item?.name}
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.arrNumberPhone?.join(', ')}
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.email}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.arrFax?.join(', ')}
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
      {/* ============   Danh sách người người phụ trách chính   ============ */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          marginTop: '20px',
        }}
      >
        <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
          Danh sách người người phụ trách chính
        </Typography>
        <Divider />
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ padding: 1, textAlign: 'center', borderBottom: '1px solid #C0C0C0' }}
          >
            <Typography align="center" component="b" variant="string">
              Mã
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Họ tên
            </Typography>
          </Grid>
        </Grid>
        {dataSelectClientCareHistory?.arrMainUserobj?.map((item) => (
          <div key={item.id}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1, textAlign: 'center' }}>
                {item?.code}
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.name}
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
      {/* ============   Danh sách người người phụ trách phụ   ============ */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          marginTop: '20px',
        }}
      >
        <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
          Danh sách người người phụ trách phụ
        </Typography>
        <Divider />
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ padding: 1, textAlign: 'center', borderBottom: '1px solid #C0C0C0' }}
          >
            <Typography align="center" component="b" variant="string">
              Mã
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              padding: 1,
              borderLeft: '1px solid #C0C0C0',
              borderBottom: '1px solid #C0C0C0',
              textAlign: 'center',
            }}
          >
            <Typography align="center" component="b" variant="string">
              Họ tên
            </Typography>
          </Grid>
        </Grid>
        {dataSelectClientCareHistory?.arrSecondaryUserobj?.map((item) => (
          <div key={item.id}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1, textAlign: 'center' }}>
                {item?.code}
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
              >
                {item?.name}
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export { ShowInfoCareOfService };
