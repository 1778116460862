import * as React from 'react';
import { debounce } from 'lodash';
import { Box, Divider, Typography, Grid } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { Selection } from 'components';

const SEARCH_USER_OPTIONS = [
  { value: 'ALL', content: 'Tất cả' },
  { value: '0', content: 'Người liên hệ' },
  { value: '1', content: 'Đối tác' },
];

const TabCallManagement = ({ clientCareHistoryItem }) => {
  const [calls, setCalls] = React.useState([]);
  const [searchUser, setSearchUser] = React.useState('ALL');

  const getCallByClientCareHistory = async (text = '') => {
    const textSearch = text === 'ALL' ? '' : text;

    try {
      const { data } = await ApiServices.getCallByClientCareHistory(
        clientCareHistoryItem.id,
        textSearch,
      );
      setCalls(data);
    } catch (error) {
      console.log(error);
    }
  };

  const timeoutGetData = React.useCallback(
    debounce((newSearch) => {
      getCallByClientCareHistory(newSearch);
    }, 500),
    [],
  );

  React.useEffect(() => {
    timeoutGetData(searchUser);
  }, [searchUser]);

  const handleChangeSearchUser = (event) => {
    setSearchUser(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100px',
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '15px',
        fontSize: '12px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            marginTop: '10px',
            marginLeft: '10px',
          }}
        >
          <Typography
            align="center"
            component="div"
            variant="h6"
            sx={{ marginTop: '0px', alignItems: 'center' }}
          >
            Lịch sử cuộc gọi
          </Typography>
        </div>
        <div
          style={{
            marginTop: '4px',
            marginRight: '10px',
            width: '200px',
          }}
        >
          <Selection
            label="Người liên hệ/ Đối tác"
            size="small"
            displayNone={false}
            dataArrObject={SEARCH_USER_OPTIONS}
            onChange={handleChangeSearchUser}
            value={searchUser}
          />
        </div>
      </div>

      <Divider />
      {/* header */}
      <Grid container>
        <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            TG bắt đầu
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            TG kết thúc
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Nội dung
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            File ghi âm
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Người liên hệ/ Đối tác
          </Typography>
        </Grid>
      </Grid>
      {/* body - mapdata */}
      {calls.map((item) => (
        <div key={item.id}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
              {Utils.formatDateHourFn(item?.callTimeFrom)}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {Utils.formatDateHourFn(item?.callTimeTo)}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.note}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.urlFile}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.customerType === 1 ? item?.vendorServiceName : item?.clientName}
            </Grid>
          </Grid>
        </div>
      ))}
    </Box>
  );
};

export { TabCallManagement };
