import React from 'react';
import { Divider, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import { BsFillTrashFill } from 'react-icons/bs';
import { ContainerTableDepartment } from '../styles';

export const RenderDepartment = ({ data, onDeleteDepartmentItem }) => {
  return (
    <ContainerTableDepartment>
      <Grid container>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Mã
          </Typography>
        </Grid>
        <Grid item xs={5} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Tên phòng ban
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            #
          </Typography>
        </Grid>
      </Grid>
      {data?.map((item, index) => (
        <div key={`${item?.id}-${index}`}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={3} sx={{ padding: 1 }}>
              {item?.code}
            </Grid>
            <Grid item xs={5} sx={{ padding: 1 }}>
              {item?.name}
            </Grid>
            <Grid item xs={1} sx={{ padding: 1 }}>
              <Tooltip title="Xóa" placement="top-start" arrow>
                <IconButton
                  aria-label="add to shopping cart"
                  color="primary"
                  size="small"
                  onClick={() => onDeleteDepartmentItem(item)}
                >
                  <BsFillTrashFill />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      ))}
    </ContainerTableDepartment>
  );
};
