import React, { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection, ComboBox } from 'components';
import { Box, Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import {
  CLIENT_COMPLAIN_STATUS_OPTIONS,
  CLIENT_COMPLAIN_STATUS,
  RESPONSE_STATUSES,
} from 'commons/constants';

const FORM_WIDTH = '600px';
export const FormDetail = ({ selected, setSelected, reloadData, ...props }) => {
  console.log({ selected });
  // ninit List
  const [customerList, setCustomerList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [clientCompanyId, setClientCompanyId] = useState(null);

  const [departmentId, setDepartmentId] = useState(null);
  const [departmentName, setDepartmentName] = useState('');

  const checkSubmit = useMemo(() => {
    return !clientCompanyId || !departmentId;
  }, [clientCompanyId, departmentId]);

  const getClientCustomer = async () => {
    try {
      const res = await ApiServices.getClientCompanies();
      if (res?.data) {
        setCustomerList(res?.data || []);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  const getDepartment = async () => {
    try {
      const res = await ApiServices.getDepartments();
      if (res?.data?.data) {
        setDepartmentList(res?.data?.data || []);
      }
    } catch (error) {
      console.log('getDepartments Failed:::', error);
    }
  };

  useEffect(() => {
    getClientCustomer();
    getDepartment();
  }, []);

  useEffect(() => {
    if (selected?.isNew) {
      setClientCompanyId(null);
      setDepartmentId(null);
      setDepartmentName('');
    } else {
      // customer
      if (selected?.clientCompanyId && customerList?.length > 0) {
        const findCustomer = customerList?.find((item) => item.id === selected.clientCompanyId);
        if (findCustomer) {
          setClientCompanyId(findCustomer);
        }
      }
      // Department
      if (selected?.departmentId && departmentList?.length > 0) {
        const findCustomer = departmentList?.find((item) => item.id === selected.departmentId);
        if (findCustomer) {
          setDepartmentId(findCustomer);
          setDepartmentName(findCustomer?.nameVI || '');
        }
      }
    }
  }, [customerList, selected, departmentList]);

  const handleChangeCustomer = useCallback(
    (_, newValue) => {
      setClientCompanyId(newValue || null);
    },
    [clientCompanyId],
  );

  const handleChangeDepartment = useCallback(
    (_, newValue) => {
      setDepartmentId(newValue || null);
      if (newValue) {
        setDepartmentName(newValue?.nameVI || newValue?.code);
      } else {
        setDepartmentName('');
      }
    },
    [departmentId],
  );

  const createFn = async (values, { setSubmitting }) => {
    const dataSend = {
      code: values?.code,
      complaintDate: values?.complaintDate,
      guestComplaints: values?.guestComplaints,
      clientCompanyId: clientCompanyId?.id,
      departmentId: departmentId?.id,
      departmentName: departmentName,
      causeofComplaint: values?.causeofComplaint,
      solution: values?.solution,
      description: values?.description,
      status: 0,
    };

    setSubmitting(true);
    return ApiServices.createClientComplaint(dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const updateFn = async (values, { setSubmitting }) => {
    const idUpdate = values?.id;
    if (!idUpdate) return;

    const dataSend = {
      id: idUpdate,
      code: values?.code,
      complaintDate: values?.complaintDate,
      guestComplaints: values?.guestComplaints,
      clientCompanyId: clientCompanyId?.id,
      departmentId: departmentId?.id,
      departmentName: departmentName,
      causeofComplaint: values?.causeofComplaint,
      solution: values?.solution,
      description: values?.description,
      status: values?.status,
    };

    setSubmitting(true);
    return ApiServices.updateClientComplaint(idUpdate, dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Cập nhật thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
      >
        <Formik
          initialValues={{
            code: '',
            complaintDate: '', // Ngày khiếu nại ComplaintDate
            guestComplaints: '', // Tên Người khiếu nại dạng GuestComplaints
            clientCompanyId: '', // Cty khiếu nại ClientCompanyId,ClientCompanyName
            departmentId: '', // Phòng ban khiếu nại : DepartmentId,DepartmentName
            status: '', // Mới:0/Chờ Duyệt:1/Đang Xử lý:2/ Hoàn Thành:3/Huy:4(Khi xac minh không xử ly1 )
            causeofComplaint: '', // Nội dung khiếu nại CauseofComplaint
            solution: '', // Nội dung xử lí khiếu nại Solution
            description: '', // Ghi chú
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required('Vui lòng nhập mã khiếu nại'),
            complaintDate: Yup.string().required('Vui lòng chọn ngày khiếu nại'),
            causeofComplaint: Yup.string().max(255).required('Vui lòng nhập nội dung khiếu nại'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      required
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                      label="Mã"
                      name="code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.code}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      required
                      error={Boolean(touched.complaintDate && errors.complaintDate)}
                      helperText={touched.complaintDate && errors.complaintDate}
                      label="Ngày khiếu nại"
                      name="complaintDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.complaintDate}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      label="Tên Khách hàng khiếu nại"
                      name="guestComplaints"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.guestComplaints}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {!selected?.isNew && (
                      <Selection
                        label="Trạng thái khiếu nại"
                        name="status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.status}
                        dataArrObject={CLIENT_COMPLAIN_STATUS_OPTIONS}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      error={!clientCompanyId}
                      helperText={!clientCompanyId && 'Vui lòng chọn công ty khiếu nại'}
                      options={customerList}
                      label="Công ty khiếu nại"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="name"
                      value={clientCompanyId}
                      onChange={handleChangeCustomer}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      error={!departmentId}
                      helperText={!departmentId && 'Vui lòng chọn phòng ban bị khiếu nại'}
                      options={departmentList}
                      label="Phòng ban bị khiếu nại"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="nameVI"
                      value={departmentId}
                      onChange={handleChangeDepartment}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      required
                      multiline={true}
                      rows={2}
                      error={Boolean(touched.causeofComplaint && errors.causeofComplaint)}
                      helperText={touched.causeofComplaint && errors.causeofComplaint}
                      label="Nội dung khiếu nại"
                      name="causeofComplaint"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.causeofComplaint}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      multiline={true}
                      rows={2}
                      label="Nội dung xử lí khiếu nại"
                      name="solution"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.solution}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      multiline={true}
                      rows={2}
                      label="Ghi chú"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ py: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  sx={{
                    padding: '10px 30px',
                    textTransform: 'none',
                  }}
                  color="primary"
                  disabled={
                    isSubmitting ||
                    checkSubmit ||
                    selected?.status === CLIENT_COMPLAIN_STATUS.COMPLETED
                  }
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
