import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import { CARRIER_TYPE } from 'commons/constants';

const CARRIER_TYPE_OPTIONS = [
  { value: 0, content: CARRIER_TYPE[0] },
  { value: 1, content: CARRIER_TYPE[1] },
];

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createCarrier(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateCarrier(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Khu vực: ${selected?.name}`}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            countryId: '',
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
              .required('Vui lòng nhập mã'),
            name: Yup.string().max(255).required('Vui lòng nhập tên hãng tàu/ hàng không'),
            isCarrier: Yup.string().max(255).required('Vui lòng chọn đơn vị'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Tên hãng tàu/ Hàng không"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                label="SĐT"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
              />
              <TextInput
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                label="Địa chỉ"
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
              />
              <Selection
                required
                error={Boolean(touched.isCarrier && errors.isCarrier)}
                helperText={touched.isCarrier && errors.isCarrier}
                label="Đơn vị vận chuyển"
                name="isCarrier"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.isCarrier}
                dataArrObject={CARRIER_TYPE_OPTIONS}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
