import React, { useState, useEffect, useMemo } from 'react';
import { Box, ToggleButton, Button, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import { ModalFormDetail } from 'components';
import { LoadingView } from 'components/loading-view';

import ApiServices from 'services';
import { CONTRACT_STATUS } from 'commons/constants';
import Utils from 'commons/utils';
//
import { ContractForm } from './contract-form';
import { RecordForm } from './record-form';

const UpdateForm = ({ onCloseModal, initData, selected, isReadOnly = false }) => {
  console.log({ selected });
  const [loading, setLoading] = useState(false);
  const [valueTab, setValueTab] = React.useState('1');
  const [selectedContract, setSelectedContract] = useState({});

  const getContractDetail = async (contractId) => {
    try {
      const res = await ApiServices.getDetailContract(contractId);
      if (res?.data) {
        setSelectedContract(res?.data || {});
      }
    } catch (error) {
      window.UIMessage?.error('Thêm mới thất bại !!!');
      console.log('Create Contract Failed:::', error);
    }
  };

  useEffect(() => {
    if (selected) {
      const contractId = selected?.id;
      getContractDetail(contractId);
    }
  }, [selected]);

  const handleClose = () => {
    onCloseModal();
  };

  const handleChangeTab = (_, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Hợp đồng" value="1" sx={{ textTransform: 'none' }} />
              <Tab label="Hồ sơ" value="2" sx={{ textTransform: 'none' }} />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ContractForm
              selectedContract={selectedContract}
              getContractDetail={getContractDetail}
              initData={initData}
              isReadOnly={isReadOnly}
            />
          </TabPanel>
          <TabPanel value="2">
            <RecordForm
              selectedContract={selectedContract}
              getContractDetail={getContractDetail}
              initData={initData}
              isReadOnly={isReadOnly}
            />
          </TabPanel>
        </TabContext>
      </Box>

      {/* <LoadingView loading={loading} /> */}
    </>
  );
};

export { UpdateForm };
