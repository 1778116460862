import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Selection, CheckPriceReqFreightForm, CheckPriceReqFreightLCLForm } from 'components';
import ApiServices from 'services';
import { SUB_SERVICES_ID } from 'commons/constants';

const SEA_PORT_TYPE = 0; // Cảng Hãng tàu
const AIR_PORT_TYPE = 1; // Cảng hàng không

export const FreightRequestForm = ({
  selected,
  itemData,
  subServiceSelectedList,
  setSubServiceSelectedList,
}) => {
  const { SEA_FCL, SEA_LCL, AIR } = SUB_SERVICES_ID;
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [contTypeCodeCheck, setContTypeCodeCheck] = useState([]);
  const [polId, setPolId] = useState('');
  const [podId, setPodId] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [packingAirCodeCheck, setPackingAirCodeCheck] = useState([]);
  const [checkFreightRequest, setCheckFreightRequest] = useState([]);
  const [subFeeList, setSubFeeList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [contTypeList, setContTypeList] = useState([]);
  const [packingAirList, setPackingAirList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    if (itemData && itemData?.contentList) {
      setPolId(itemData?.contentList?.polId || '');
      setPodId(itemData?.contentList?.podId || '');
      setCurrencyCode(itemData?.contentList?.currencyCode || '');
      setContTypeCodeCheck(itemData?.contentList?.contTypeCodeCheck || []);
      setPackingAirCodeCheck(itemData?.contentList?.packingAirCodeCheck || []);
      if (itemData?.contentList?.checkFreightRequest) {
        const dataConvert = { ...itemData?.contentList?.checkFreightRequest };
        setCheckFreightRequest([dataConvert] || []);
      }
    }
  }, [itemData]);

  useEffect(() => {
    if (itemData && itemData?.contentList && itemData?.contentList?.checkFreightRequest) {
      const dataConvert = { ...itemData?.contentList?.checkFreightRequest };
      setCheckFreightRequest([dataConvert] || []);
    }
  }, [itemData, itemData?.contentList, itemData?.contentList?.checkFreightRequest]);

  const getDataInits = async () => {
    let [getPortsByType, getCurrency, getContType, getPackingAir, getUnit] = await Promise.all([
      ApiServices.getPortsByType(itemData?.id === AIR ? AIR_PORT_TYPE : SEA_PORT_TYPE),
      ApiServices.getCurrencies(),
      ApiServices.getContTypes(),
      ApiServices.getPackingAirs(),
      ApiServices.getUnits(),
    ]);
    setPortList(getPortsByType.data || []);
    setCurrencyList(getCurrency.data || []);
    setContTypeList(getContType.data || []);
    setPackingAirList(getPackingAir.data || []);
    setUnitList(getUnit.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoadingInit(true);
      await getDataInits();
      setLoadingInit(false);
    };
    getDataInit();
  }, []);

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(itemData?.id, true);
      if (data) {
        const dataMap = data.map((item) => {
          return {
            id: item?.feeGroupID,
            name: item?.feeGroupName,
          };
        });
        setSubFeeList(dataMap);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (itemData) {
      getSurchargeByFeeGroupSS();
    }
  }, [itemData]);

  const handleChangeCont = useCallback(
    async (e) => {
      setContTypeCodeCheck(e.target.value);
      const ind = await subServiceSelectedList.findIndex((el) => el?.id === itemData?.id);
      if (ind === -1) return;
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.contTypeCodeCheck = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [contTypeCodeCheck],
  );
  const handleChangePackingAir = useCallback(
    async (e) => {
      setPackingAirCodeCheck(e.target.value);
      const ind = await subServiceSelectedList.findIndex((el) => el?.id === itemData?.id);
      if (ind === -1) return;
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.packingAirCodeCheck = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [packingAirCodeCheck],
  );
  const handleChangePol = useCallback(
    async (e) => {
      setPolId(e.target.value);
      const ind = await subServiceSelectedList.findIndex((el) => el?.id === itemData?.id);
      if (ind === -1) return;
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.polId = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [polId],
  );
  const handleChangePod = useCallback(
    async (e) => {
      setPodId(e.target.value);
      const ind = await subServiceSelectedList.findIndex((el) => el?.id === itemData?.id);
      if (ind === -1) return;
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.podId = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [podId],
  );
  const handleChangeCurrency = useCallback(
    async (e) => {
      setCurrencyCode(e.target.value);
      const ind = await subServiceSelectedList.findIndex((el) => el?.id === itemData?.id);
      if (ind === -1) return;
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.currencyCode = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [currencyCode],
  );
  const handleChangeFreightRequest = useCallback(
    async (e) => {
      setCheckFreightRequest(e.target.value);
      const ind = await subServiceSelectedList.findIndex((el) => el?.id === itemData?.id);
      if (ind === -1) return;
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.checkFreightRequest = e.target.value && e.target.value[0];
      setSubServiceSelectedList(newData);
    },
    [checkFreightRequest],
  );

  const ARRAY_SELECT_GENERAL_INFO = [
    {
      value: polId,
      required: 'Bắt buộc (*)',
      label: 'Cảng xếp dỡ',
      type: 'selection',
      dataArrObject: portList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangePol,
    },
    {
      value: podId,
      required: 'Bắt buộc (*)',
      label: 'Cảng đến',
      type: 'selection',
      dataArrObject: portList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangePod,
    },

    {
      value: currencyCode,
      required: 'Bắt buộc (*)',
      label: 'Đơn vị tiền tệ',
      type: 'selection',
      dataArrObject: currencyList,
      fieldValue: 'code',
      fieldView: 'name',
      handleChange: handleChangeCurrency,
    },
  ];

  return (
    <>
      {!loadingInit && !loading && (
        <>
          {itemData?.id === SEA_FCL && (
            <>
              <Selection
                sx={{ width: '50%', marginBottom: '10px' }}
                sxInput={{ padding: '0px', margin: '0px' }}
                error={!contTypeCodeCheck?.length}
                helperText={!contTypeCodeCheck?.length && 'Vui lòng chọn loại cont'}
                label="Loại cont"
                name="contTypeCodeCheck"
                multiple={true}
                onChange={handleChangeCont}
                value={contTypeCodeCheck}
                fieldValue="code"
                fieldView="name"
                dataArrObject={contTypeList}
              />
              <b>GIÁ CƯỚC</b>
            </>
          )}
          {itemData?.id === AIR && (
            <>
              <Selection
                sx={{ width: '50%', marginBottom: '10px' }}
                sxInput={{ padding: '0px', margin: '0px' }}
                error={!packingAirCodeCheck?.length}
                helperText={!packingAirCodeCheck?.length && 'Vui lòng chọn quy cách'}
                label="Quy cách"
                name="packingAirCodeCheck"
                multiple={true}
                onChange={handleChangePackingAir}
                value={packingAirCodeCheck}
                fieldValue="code"
                fieldView="name"
                dataArrObject={packingAirList}
              />
            </>
          )}
          <ContainerGeneral>
            {ARRAY_SELECT_GENERAL_INFO.map((item, index) => {
              return (
                <Selection
                  key={`selection-${item?.label + index}`}
                  sx={{ width: '50%', marginRight: '10px' }}
                  sxInput={{ padding: '0px', margin: '0px' }}
                  error={!item?.value && !!item?.required}
                  helperText={!item?.value && !!item?.required ? item?.required : ''}
                  label={item.label}
                  name={item.field}
                  onChange={item?.handleChange}
                  value={item?.value}
                  fieldValue={item?.fieldValue}
                  fieldView={item?.fieldView}
                  syncData={item?.syncData}
                  dataArrObject={item?.dataArrObject}
                />
              );
            })}
          </ContainerGeneral>
          <ContainerRequestPrice>
            {itemData?.id === SEA_FCL && contTypeCodeCheck?.length > 0 && (
              <CheckPriceReqFreightForm
                arrContType={contTypeCodeCheck}
                subFeeList={subFeeList}
                unitList={unitList}
                name="checkFreightRequest"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeFreightRequest}
                value={checkFreightRequest}
              />
            )}
            {itemData?.id === SEA_LCL && (
              <CheckPriceReqFreightLCLForm
                subFeeList={subFeeList}
                unitList={unitList}
                name="checkFreightRequest"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeFreightRequest}
                value={checkFreightRequest}
                hasInitData={!selected?.isNew && itemData?.contentList?.checkFreightRequest}
              />
            )}
            {itemData?.id === AIR && packingAirCodeCheck?.length > 0 && (
              <CheckPriceReqFreightForm
                arrContType={packingAirCodeCheck}
                subFeeList={subFeeList}
                unitList={unitList}
                name="checkFreightRequest"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeFreightRequest}
                value={checkFreightRequest}
                keyArray="arrPackingAir"
                keyNameOfArray="packingAirCode"
              />
            )}
          </ContainerRequestPrice>
        </>
      )}
    </>
  );
};

export const ContainerGeneral = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const ContainerRequestPrice = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
}));
