import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import { LEVEL_DASHBOARD } from 'commons/constants';

const LEVEL_DASHBOARD_OPTIONS = [
  { value: 0, content: LEVEL_DASHBOARD[0] },
  { value: 1, content: LEVEL_DASHBOARD[1] },
];

const arrayIcons = [
  {
    value: 'SettingsIcon',
    name: 'SettingsIcon',
  },
  {
    value: 'SupportAgentIcon',
    name: 'SupportAgentIcon',
  },
  {
    value: 'MonetizationOnIcon',
    name: 'MonetizationOnIcon',
  },
  {
    value: 'DashboardIcon',
    name: 'DashboardIcon',
  },
  {
    value: 'AssessmentIcon',
    name: 'AssessmentIcon',
  },
  {
    value: 'ArticleIcon',
    name: 'ArticleIcon',
  },
  {
    value: 'ContactsIcon',
    name: 'ContactsIcon',
  },
  {
    value: 'DescriptionIcon',
    name: 'DescriptionIcon',
  },
  {
    value: 'HeadsetMicIcon',
    name: 'HeadsetMicIcon',
  },
  {
    value: 'LibraryBooksIcon',
    name: 'LibraryBooksIcon',
  },
  {
    value: 'PhoneInTalkIcon',
    name: 'PhoneInTalkIcon',
  },
  {
    value: 'TtyIcon',
    name: 'TtyIcon',
  },
  {
    value: 'WorkIcon',
    name: 'WorkIcon',
  },
  {
    value: 'GroupIcon',
    name: 'GroupIcon',
  },
  {
    value: 'AccountBoxIcon',
    name: 'AccountBoxIcon',
  },
  {
    value: 'AdminPanelSettingsIcon',
    name: 'AdminPanelSettingsIcon',
  },
  {
    value: 'AppRegistrationIcon',
    name: 'AppRegistrationIcon',
  },
  {
    value: 'BusinessIcon',
    name: 'BusinessIcon',
  },
  {
    value: 'LeaderboardIcon',
    name: 'LeaderboardIcon',
  },
  {
    value: 'ChatIcon',
    name: 'ChatIcon',
  },
  {
    value: 'BusinessCenterIcon',
    name: 'BusinessCenterIcon',
  },
  {
    value: 'AccountTreeIcon',
    name: 'AccountTreeIcon',
  },
];

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, data, ...props }) => {
  const dataParent = data.filter((item) => item.parent === '');

  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createPermissionMenu(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
        window.location.reload();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updatePermissionMenu(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
        window.location.reload();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Menu: ${selected?.name}`}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            sort: '',
            url: '',
            parent: '',
            level: '',
            icon: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required('Vui lòng nhập mã'),
            name: Yup.string().max(255).required('Vui lòng nhập tên Menu'),
            sort: Yup.string().max(255).required('Vui lòng nhập thứ tự ưu tiên'),
            url: Yup.string().max(255).required('Vui lòng nhập đường dẫn'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                disabled={!selected?.isNew}
              />
              <TextInput
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Tên Menu"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                error={Boolean(touched.sort && errors.sort)}
                helperText={touched.sort && errors.sort}
                label="Thứ tự ưu tiên"
                name="sort"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sort}
                type="number"
              />
              <TextInput
                error={Boolean(touched.url && errors.url)}
                helperText={touched.url && errors.url}
                label="Đường dẫn"
                name="url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.url}
              />
              <Selection
                error={Boolean(touched.parent && errors.parent)}
                helperText={touched.parent && errors.parent}
                label="Parent"
                name="parent"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.parent}
                dataArrObject={dataParent}
                fieldValue="code"
                fieldView="name"
                disabledItemNone={false}
              />
              <Selection
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
                label="Cấp độ"
                name="level"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.level}
                dataArrObject={LEVEL_DASHBOARD_OPTIONS}
              />
              <Selection
                error={Boolean(touched.icon && errors.icon)}
                helperText={touched.icon && errors.icon}
                label="Icon"
                name="icon"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.icon}
                dataArrObjectIcon={arrayIcons}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
