import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection } from 'components';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      onAdd,
      values,
      setValues,
      error,
      arrSurcharge,
      arrUnit,
      disabledField,
      keyArray,
      keyNameOfArray,
      widthContainerLeft = '15%',
      widthContainerBody = '45%',
      widthContainerRight = '35%',
    },
    ref,
  ) => {
    const [remark, setName] = React.useState('');
    const [unitCode, setUnitCode] = React.useState('');
    const [arrayCont, setArrayCont] = React.useState([]);
    const [surchargeId, setSurchargeId] = React.useState('');

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setName(item?.remark);
      setUnitCode(item?.unitCode);
      setArrayCont(item[keyArray] || []);
      setSurchargeId(item?.surchargeId);
    }, [item]);
    const onChangeName = React.useCallback(
      (e) => {
        setName(e.target.value);
        setValues((prev) => {
          prev[index].remark = e.target.value;
          return [...prev];
        });
      },
      [remark],
    );
    const onChangeUnit = React.useCallback(
      (e) => {
        setUnitCode(e.target.value);
        setValues((prev) => {
          prev[index].unitCode = e.target.value;
          return [...prev];
        });
      },
      [unitCode],
    );

    const onChangeSurcharge = React.useCallback(
      (e) => {
        setSurchargeId(e.target.value);
        setValues((prev) => {
          prev[index].surchargeId = e.target.value;
          return [...prev];
        });
      },
      [surchargeId],
    );

    const onChangeCont = React.useCallback(
      (e, indContType) => {
        setArrayCont((prev) => {
          prev[indContType].price = +e.target.value;
          return [...prev];
        });

        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    const isDisabled = !surchargeId || !unitCode;
    return (
      <Container>
        <ContainerLeft width={widthContainerLeft}>
          <Box sx={{ width: '100%' }}>
            <Selection
              required
              sx={{
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Tên phụ phí"
              onChange={onChangeSurcharge}
              value={surchargeId}
              dataArrObject={arrSurcharge}
              fieldValue="surchargeId"
              fieldView="surchargeName"
              error={!surchargeId}
              disabled={disabledField}
            />
            {!surchargeId && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
        </ContainerLeft>
        <ContainerBody width={widthContainerBody}>
          {arrayCont?.map((el, indContType) => (
            <Box sx={{ width: '100%' }} key={indContType}>
              <StyledTextFieldPrice
                label={el[keyNameOfArray]}
                variant="outlined"
                type="text"
                size="small"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => onChangeCont(e, indContType)}
                value={el?.price}
                error={!el?.price}
                disabled={disabledField}
              />
              {!el?.price && <ErrorText>Bắt buộc (*)</ErrorText>}
            </Box>
          ))}
        </ContainerBody>
        <ContainerRight width={widthContainerRight}>
          <Box sx={{ width: '50%', marginLeft: '10px' }}>
            <Selection
              required
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Đơn vị tính"
              name="serviceId"
              onChange={onChangeUnit}
              value={unitCode}
              dataArrObject={arrUnit}
              fieldValue="code"
              fieldView="name"
              error={!unitCode}
              disabled={disabledField}
            />
            {!unitCode && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <StyledTextField
            label="Remark"
            variant="outlined"
            type="text"
            size="small"
            onChange={onChangeName}
            value={remark}
            error={error}
            disabled={disabledField}
          />
        </ContainerRight>
        <ContainerAction>
          {index === values.length - 1 ? (
            <>
              <ButtonWrapper
                onClick={() => onDelete(index)}
                sx={{ borderColor: 'error.main' }}
                disabled={disabledField}
              >
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
              <ButtonWrapper
                onClick={onAdd}
                sx={{
                  borderColor: isDisabled ? 'action.disabled' : 'success.main',
                }}
                disabled={isDisabled || disabledField}
              >
                <AddCircleOutlineIcon
                  sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                />
              </ButtonWrapper>
            </>
          ) : (
            <ButtonWrapper
              onClick={() => onDelete(index)}
              sx={{ borderColor: 'error.main' }}
              disabled={disabledField}
            >
              <HighlightOffIcon sx={{ color: 'error.main' }} />
            </ButtonWrapper>
          )}
        </ContainerAction>
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  marginLeft: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
}));
const ContainerBody = styled(Box)(({ theme, width = '45%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerLeft = styled(Box)(({ theme, width = '15%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerRight = styled(Box)(({ theme, width = '35%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
  // borderWidth: '1px',
  // borderStyle: 'solid',
  // borderColor: theme.palette.action.disabled,
}));
const StartWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-20px',
  left: '15px',
  zIndex: 1,
}));
