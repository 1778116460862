import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Checkbox,
  IconButton,
  Box,
  Button,
  Stack,
  Divider,
  Avatar,
  Badge,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import ChatServices from 'services/chat-services';
import { setSelectedChanel, fetchChanel } from 'store/reducers/chat-chanel/action';
import { BsCircle, BsCheckCircle } from 'react-icons/bs';
import { RiKey2Line } from 'react-icons/ri';
import './messages.css';

const AddMember = ({ selectedChanel, setAddMember }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const userMapIsMember = users.map((item) => {
    item.isMember = false;
    selectedChanel?.members.forEach((data) => {
      if (data.code === item.code) {
        item.isMember = true;
      }
    });
    return item;
  });

  const [checked, setChecked] = React.useState([]);
  const [valueMember, setValueMember] = React.useState([]);
  const isActiveSubmit = !valueMember.length;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newValueMember = [...valueMember];

    if (currentIndex === -1) {
      newChecked.push(value);
      newValueMember.push({ code: value });
    } else {
      newChecked.splice(currentIndex, 1);
      newValueMember.splice(currentIndex, 1);
    }

    setValueMember(newValueMember);
    setChecked(newChecked);
  };

  const onSubmitFn = async () => {
    try {
      const { data } = await ChatServices.addMembers(selectedChanel._id, valueMember);
      if (data.code === 'success') {
        window.UIMessage.success('Thêm thành viên thành công');
        setAddMember(false);
        dispatch(setSelectedChanel(data.results[0]));
        dispatch(fetchChanel());
      }
    } catch (error) {
      window.UIMessage.error('Thêm thành viên thất bại');
    }
  };

  return (
    <form>
      <Box
        className="add-members"
        // sx={{
        //   margin: '10px 0px',
        //   maxHeight: '400px',
        //   overflow: 'auto',
        //   textAlign: 'center',
        // }}
      >
        <List sx={{ width: '450px', maxWidth: 500, bgcolor: 'background.paper' }}>
          {userMapIsMember.map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem
                sx={{
                  transition: 'auto',
                  '&:hover': {
                    border: '1px solid red',
                    backgroundColor: 'rgb(240, 247, 255)',
                    borderColor: 'rgb(0, 127, 255)',
                    borderRadius: '10px',
                    color: '#0059B2',
                  },
                }}
                key={value.code}
                secondaryAction={
                  <IconButton
                    edge="end"
                    disabled={value.isMember}
                    aria-label="comments"
                    onClick={handleToggle(value.code)}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.code) !== -1}
                      tabIndex={-1}
                      disableRipple
                      disabled={value.isMember}
                      inputProps={{ 'aria-labelledby': labelId }}
                      icon={
                        value.isMember ? (
                          <BsCheckCircle
                            style={{ fontSize: 20, color: theme.palette.action.disabled }}
                          />
                        ) : (
                          <BsCircle style={{ fontSize: 20 }} />
                        )
                      }
                      checkedIcon={<BsCheckCircle style={{ fontSize: 20, color: 'green' }} />}
                    />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={!value.isMember ? handleToggle(value.code) : null}
                  dense
                >
                  <ListItemAvatar>
                    <ListItemIcon>
                      {value.code === selectedChanel.owner ? (
                        <Badge
                          badgeContent={<RiKey2Line />}
                          color="secondary"
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                          {/* <Avatar>{value.code.slice(0, 1).toUpperCase()}</Avatar> */}
                          <Avatar
                            alt={value.name}
                            src={
                              value?.urlAvatar &&
                              `${process.env.REACT_APP_API_URL}/${value?.urlAvatar}`
                            }
                          />
                        </Badge>
                      ) : (
                        // <Avatar>{value.code.slice(0, 1).toUpperCase()}</Avatar>
                        <Avatar
                          alt={value.name}
                          src={
                            value?.urlAvatar &&
                            `${process.env.REACT_APP_API_URL}/${value?.urlAvatar}`
                          }
                        />
                      )}
                    </ListItemIcon>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={value.name}
                    secondary={
                      <span
                        sx={{
                          '&:hover': {
                            color: '#0059B2',
                          },
                        }}
                      >
                        {value.code === selectedChanel.owner
                          ? 'Trưởng nhóm'
                          : value.isMember
                          ? 'Đã tham gia'
                          : ''}
                      </span>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Divider />
      <Box sx={{ marginTop: '10px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Button color="error" size="small" variant="outlined" onClick={() => setAddMember(false)}>
            Hủy
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={onSubmitFn}
            disabled={isActiveSubmit}
          >
            Xác Nhận
          </Button>
        </Stack>
      </Box>
    </form>
  );
};

export { AddMember };
