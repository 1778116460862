import React from 'react';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  { onChange, disabled = false, ...props },
  ref,
) {
  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props?.name,
            value: values?.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString={true}
      disabled={disabled}
      allowNegative={true}
    />
  );
});

export { NumberFormatCustom };
