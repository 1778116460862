import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';
import moment from 'moment';
import { Table } from 'components';
import { FormDetail } from './form-detail';
import ServicesModal from './services-modal';
import { getColumns } from './columns';
import ApiServices from 'services';
import storage from 'storage';
import Utils from 'commons/utils';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';
import { debounce } from 'lodash';

const CLIENT_TRASH_TEMPLATE = 'Client_Company_Template';
const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
};

export function ClientTrashes() {
  const inputRef = useRef();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [servicesModal, setServiceModal] = useState({});
  const [idAccept, setIdAccept] = useState('');
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [isAccept, setIsAccept] = useState(false);

  const initData = (query) => {
    setLoading(true);
    return ApiServices.pagingClientTrashes(query ? query : filter)
      .then((res) => {
        if (res?.data) {
          if (totalRecords !== res?.data?.totalRecords) setTotalRecords(res?.data?.totalRecords);
          setClients(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('get Client Trash:', err))
      .finally(() => setLoading(false));
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const reloadData = async () => {
    return await initData(filter);
  };

  const changeStatus = async (row, data = {}) => {
    if (!row?.id) return;
    setLoading(true);
    try {
      const res = await ApiServices.updateClientTrashStatus({
        ClientTrashStatusId: row.id,
        ...data,
      });

      if (res) {
        window.UIMessage?.success('Thay đổi trạng thái thành công');
        reloadData();
      }
    } catch (err) {
      window.UIMessage?.error('Thay đổi trạng thái thất bại !!!');
      console.warn('changeStatus - companies', err);
    }
    setLoading(false);
  };

  const handleImportExcel = async (event) => {
    if (loadingImport || !event) return;

    const file = event?.target?.files[0];
    inputRef.current.value = '';
    if (!file) {
      window.UIMessage?.error('Tải file excel thất bại !!!');
      return;
    }
    setLoadingImport(true);
    try {
      const res = await ApiServices.importExcelClientTrashes({
        excelFile: file,
      });
      if (res?.data) {
        window.UIMessage?.success(
          `Import excel thành công - Thêm mới ${res?.data?.insert} - Cập nhật ${res?.data?.update}`,
          3000,
        );
        // event.target.value = '';
        reloadData();
      }
    } catch (err) {
      console.warn('import excel - Clients', err);
      window.UIMessage?.error('Import excel thất bại !!!');
    }
    setLoadingImport(false);
  };

  const onHandleReject = (row) =>
    changeStatus(row, {
      reject: true,
    });

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.foundingDate = Utils.checkIsDate(row.foundingDate)
      ? Utils.convertToDateFormat(row?.foundingDate)
      : '';

    dataSelect.code = row?.code || '';
    dataSelect.name = row?.name || '';
    dataSelect.tax_Code = row?.tax_Code || '';
    dataSelect.arrAddress = row?.arrAddress || [];
    dataSelect.arrNumberPhone = row?.arrNumberPhone || [];
    dataSelect.arrFax = row?.arrFax || [];
    dataSelect.email = row?.email || '';
    dataSelect.arrServiceId = row?.arrServiceId || [];
    dataSelect.note = row?.note || '';
    dataSelect.clientSourceId = row?.clientSourceId || '';
    dataSelect.companyType = row?.companyType || '';
    return dataSelect;
  };

  const onHandleAccept = async (row) => {
    if (!row?.email && !row?.arrNumberPhone?.length) {
      window.UIMessage?.info(
        'Thông báo',
        'Vui lòng cập nhật thêm email hoặc số điện thoại để xác nhận',
        3000,
      );
      setIsAccept(true);
      const dataSelect = validateValue(row);
      setSelected(dataSelect);
      return;
    }
    setIdAccept(row?.id);
    setServiceModal(row);
  };

  const handleCloseForm = () => {
    setIsAccept(false);
    setSelected(null);
  };

  const handleExportClientTrash = async () => {
    setLoading(true);
    await ApiServices.exportExcelClientTrash()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `DS_Nguồn_Khách_Hàng_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      });
    setLoading(false);
  };

  const handleExportTemplate = async () => {
    setLoading(true);
    await ApiServices.exportTemplates(CLIENT_TRASH_TEMPLATE)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Nguồn_Khách_Hàng_Biểu_Mẫu.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      });
    setLoading(false);
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          NGUỒN KHÁCH HÀNG
        </Typography>
      </ContainerHeader>

      <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton>
      <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          onChange={handleImportExcel}
        />
      </ButtonImport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClientTrash}
      >
        Tải excel
      </ButtonExport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          filter={filter}
          rows={clients}
          headCells={getColumns(handleSearchColumn, onHandleAccept, onHandleReject)}
          totalRecords={totalRecords}
          onPageChange={(paging) => {
            setFilter((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={(row) => {
            const dataSelect = validateValue(row);
            setSelected(dataSelect);
          }}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail
          {...{
            selected,
            setSelected,
            reloadData,
            onReject: onHandleReject,
            onAccept: onHandleAccept,
            isAccept,
            handleCloseForm,
          }}
        />
        <ServicesModal
          open={servicesModal}
          setOpen={setServiceModal}
          idAccept={idAccept}
          onSubmitData={(row, arrServiceId) => {
            const arrServiceIdEnd = arrServiceId?.filter((item) => item);
            changeStatus(row, {
              status: storage.enums.ClientTrashStatus.da_chuyen,
              arrServiceId: arrServiceIdEnd,
            });
          }}
        />
      </Box>
    </>
  );
}
