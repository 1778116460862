import React, { useState, useCallback, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { TextInput, FileUploadContract } from 'components';
import { styled } from '@mui/material/styles';

const ContractAppendixForm = ({ onSubmit }) => {
  const [contractAddendumName, setContractAddendumName] = useState('');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [file, setFile] = useState([]);
  const [note, setNote] = useState('');

  const isSubmitting = useMemo(() => {
    return !contractAddendumName || !effectiveDate || !file || !file?.length > 0;
  }, [contractAddendumName, effectiveDate, file]);

  const handleSubmit = async () => {
    const fileTmp = file?.map(([item]) => item);
    const dataSend = {
      contractAddendumName,
      effectiveDate,
      note,
      arrfile: fileTmp,
    };
    onSubmit && onSubmit(dataSend);
  };

  const handleChangeName = useCallback(
    (e) => {
      setContractAddendumName(e.target.value);
    },
    [contractAddendumName],
  );
  const handleChangeEffectiveDate = useCallback(
    (e) => {
      setEffectiveDate(e.target.value);
    },
    [effectiveDate],
  );
  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );
  const handleChangeFile = useCallback(
    (e) => {
      console.log({ file: e.target.value });
      setFile(e.target.value);
    },
    [file],
  );

  return (
    <Box>
      <TextInput
        required
        error={!contractAddendumName}
        helperText={!contractAddendumName && 'Vui lòng nhập tên phụ lục'}
        label="Tên phụ lục"
        name="contractAddendumName"
        onChange={handleChangeName}
        value={contractAddendumName}
      />
      <TextInput
        required
        error={!effectiveDate}
        helperText={!effectiveDate && 'Vui lòng nhập ngày bắt đầu'}
        label="Ngày bắt đầu"
        name="effectiveDate"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeEffectiveDate}
        value={effectiveDate}
      />
      <TextInput
        label="Ghi chú"
        name="summaryNote"
        onChange={handleChangeNote}
        value={note}
        multiline={true}
        rows={2}
      />
      <FileUploadContract
        error={!file?.length > 0}
        helperText={!file?.length > 0 && 'Vui lòng nhập file đính kèm'}
        label="File đính kèm"
        name="file"
        // onBlur={handleBlur}
        onChange={handleChangeFile}
        value={file}
        multiple={true}
      />
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnCreateUpdate
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            variant="contained"
          >
            Thêm mới
          </BtnCreateUpdate>
        </Box>
      </Box>
    </Box>
  );
};

export { ContractAppendixForm };

export const BtnCreateUpdate = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '5px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '6px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
