import React, { useEffect, useState } from 'react';
import { Table } from 'components';
import { FormDetail } from './form-detail';
import { Box } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';
import { SUB_SERVICES_ID } from 'commons/constants';
import Utils from 'commons/utils';

function BondedWarehouse({ openAddBondedWarehouse, closeAddBondedWarehouse }) {
  const [seaFclList, setSeaFclList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (openAddBondedWarehouse) {
      setSelected({ isNew: true });
    }
  }, [openAddBondedWarehouse]);

  const onCloseModal = () => {
    setSelected(null);
    closeAddBondedWarehouse();
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getWarehouseBySubService(
        SUB_SERVICES_ID.BONDED_WAREHOUSE,
        {
          pageNumber: 1,
          pageSize: 10,
        },
      );
      setSeaFclList(data.data || []);
    } catch (err) {
      console.warn('initData - getWarehouseBySubService', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteContTypeItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteWarehouse(row.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteWarehouse', err);
    }
    setLoading(false);
  };
  const handleSelecteRow = (row) => {
    const dataSelect = { ...row };
    dataSelect.expirydate = Utils.checkIsDate(row.expirydate)
      ? Utils.convertToDateFormat(row.expirydate)
      : '';
    setSelected(dataSelect);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={seaFclList}
        headCells={Columns(deleteContTypeItem)}
        onClickDetail={(row) => handleSelecteRow(row)}
        loading={loading}
        isHeader={false}
      />
      <FormDetail {...{ selected, onCloseModal, initData }} />
    </Box>
  );
}
export { BondedWarehouse };
