import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { BsSave, BsPlusCircle } from 'react-icons/bs';
import {
  ModalFormDetail,
  TextInput,
  Selection,
  FreightPriceForm,
  FreightSubChargeForm,
  SimpleComboBox,
} from 'components';
import { LoadingView } from 'components/loading-view';
import { Box, Button, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Formik } from 'formik';
import {
  SUB_SERVICES_ID,
  SERVICES_ID,
  PRICE_BOARD_STATUS_OPTIONS,
  INPUT_TYPES,
} from 'commons/constants';
import ApiServices from 'services';
import HeaderFormDetail from '../header-form-detail';
import { BtnCreateUpdate } from '../styles';

const SEA_CARRIER_TYPE = 0; // Hãng tàu
const SEA_PORT_TYPE = 0; // Cảng Hãng tàu

export const FormDetail = ({ selected, onCloseModal, initData }) => {
  const { SELECTION, DATE_PICKER, TEXT_INPUT } = INPUT_TYPES;
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const [contTypeCodeCheck, setContTypeCodeCheck] = useState([]);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [coloaderList, setColoaderList] = useState([]);
  const [transShipmentPortList, setTransShipmentPortList] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState([]);

  const disableByStatus = useMemo(() => {
    return selected?.status === 1;
  }, [selected?.id]);

  const getSurchargeByFeeGroupSS = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(SUB_SERVICES_ID.SEA_FCL, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          if (!selected?.isNew) {
            const ind = selected?.arrSurchargeFreight?.findIndex(
              (i) => i.feeGroupID === item.feeGroupID,
            );
            if (ind !== -1) {
              item.surcharge = selected?.arrSurchargeFreight[ind]?.surcharge || [];
              item.checked = true;
            } else {
              item.checked = false;
            }
          } else {
            item.checked = false;
          }
          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDataInits = async () => {
    let [
      getCurrency,
      getPortsByType,
      getUnit,
      getCarrier,
      getCargoType,
      getColoader,
      getTransShipmentPort,
      getDeliveryMethod,
    ] = await Promise.all([
      ApiServices.getCurrencies(),
      ApiServices.getPortsByType(SEA_PORT_TYPE),
      ApiServices.getUnits(),
      ApiServices.getCarrierByType(SEA_CARRIER_TYPE),
      ApiServices.getCargoTypes(),
      ApiServices.getColoaders(),
      ApiServices.getTransShipmentPort(),
      ApiServices.getDeliveryMethods(),
    ]);
    setCurrencyList(getCurrency.data || []);
    setPortList(getPortsByType.data || []);
    setUnitList(getUnit.data || []);
    setCarrierList(getCarrier.data || []);
    setCargoTypeList(getCargoType.data || []);
    setColoaderList(getColoader.data || []);
    setTransShipmentPortList(getTransShipmentPort.data || []);
    setDeliveryMethod(getDeliveryMethod.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoading(true);
      await getDataInits();
      setLoading(false);
    };
    getDataInit();
  }, []);

  useEffect(() => {
    if (selected) {
      getSurchargeByFeeGroupSS();
      if (selected?.arrContFreight?.length > 0) {
        setShowPrice(true);
      }
      setContTypeCodeCheck(selected?.contTypeCodeCheck || []);
    }
  }, [selected]);

  const handleCheckedPrice = (event) => {
    setShowPrice(event.target.checked);
  };

  const handleCheckFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.checked = event.target.checked;
      }
      return el;
    });
    setGroupFeeGroup(data);
  };

  const handleCloseCheckedItem = (item) => {
    const ind = groupFeeGroup.findIndex((i) => i.feeGroupID === item.feeGroupID);
    if (ind !== -1) {
      const data = groupFeeGroup;
      data[ind].checked = false;
      setGroupFeeGroup(data);
    }
  };
  const handleChangeDataFeeGroup = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    setGroupFeeGroup(data);
  };
  const filterDataIsChecked = () => {
    return groupFeeGroup
      ?.filter((el) => el.checked)
      ?.map((item) => {
        return {
          feeGroupID: item?.feeGroupID,
          surcharge: item?.surcharge,
        };
      });
  };
  const createFn = (values, { setSubmitting }) => {
    const dataSend = { ...values };
    setSubmitting(true);
    if (!showPrice) {
      dataSend.arrContFreight = [];
    }
    if (groupFeeGroup?.length > 0) {
      dataSend.arrSurchargeFreight = filterDataIsChecked();
    } else {
      dataSend.arrSurchargeFreight = [];
    }
    dataSend.contTypeCodeCheck = contTypeCodeCheck;
    dataSend.serviceId = SERVICES_ID.FREIGHT; // Cước quốc tế
    dataSend.subServiceId = SUB_SERVICES_ID.SEA_FCL; // Đường biển FCL
    dataSend.objDetailFreightFCL = {
      transshipmentPortId: values.transshipmentPortId,
      coloaderId: values.coloaderId,
      deliveryMethodId: values.deliveryMethodId,
      transitTime: values.transitTime,
    };
    delete dataSend.isNew;
    delete dataSend.transshipmentPortId;
    delete dataSend.coloaderId;
    delete dataSend.deliveryMethodId;
    delete dataSend.transitTime;
    console.log({ dataSend });
    return ApiServices.createFreight(dataSend)
      .then((res) => {
        if (res.status === 200) {
          window.UIMessage?.success('Thêm mới thành công');
          onCloseModal();
          initData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const updateFn = (values, { setSubmitting }) => {
    const dataSend = { ...values };
    setSubmitting(true);
    if (!showPrice) {
      dataSend.arrContFreight = [];
    }
    if (groupFeeGroup?.length > 0) {
      dataSend.arrSurchargeFreight = filterDataIsChecked();
    } else {
      dataSend.arrSurchargeFreight = [];
    }
    if (!dataSend.expirydate) {
      delete dataSend.expirydate;
    }
    dataSend.contTypeCodeCheck = contTypeCodeCheck;
    dataSend.objDetailFreightFCL = {
      ...values.objDetailFreightFCL,
      transshipmentPortId: values.transshipmentPortId,
      coloaderId: values.coloaderId,
      deliveryMethodId: values.deliveryMethodId,
      transitTime: values.transitTime,
    };
    delete dataSend.isNew;
    delete dataSend.transshipmentPortId;
    delete dataSend.coloaderId;
    delete dataSend.deliveryMethodId;
    delete dataSend.transitTime;
    return ApiServices.updateFreight(dataSend.id, dataSend)
      .then((res) => {
        if (res?.data) {
          window.UIMessage?.success('Cập nhật thành công');
          onCloseModal();
          initData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log('update sea FCL Faild:', err);
      })
      .finally(() => setSubmitting(false));
  };
  const handleChangeContType = (item) => {
    setContTypeCodeCheck(item);
  };

  const ARRAY_INPUT = [
    {
      field: 'status',
      lable: 'Trang thái',
      type: SELECTION,
      fieldValue: 'value',
      fieldView: 'label',
      dataArrObject: PRICE_BOARD_STATUS_OPTIONS,
      disabled: selected?.isNew || disableByStatus,
      isShowItem: true,
    },
    {
      field: 'carrierId',
      lable: 'Hãng tàu',
      type: SELECTION,
      dataArrObject: carrierList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'polId',
      lable: 'Cảng xếp dỡ',
      type: SELECTION,
      dataArrObject: portList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'podId',
      lable: 'Cảng đến',
      type: SELECTION,
      dataArrObject: portList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'currencyCode',
      lable: 'Đơn vị tiền tệ',
      type: SELECTION,
      dataArrObject: currencyList,
      fieldValue: 'code',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    { field: 'etd', lable: 'ETD', type: DATE_PICKER, disabled: disableByStatus },
    { field: 'expirydate', lable: 'Hết hạn', type: DATE_PICKER },
    { field: 'note', lable: 'Ghi chú', disabled: disableByStatus, type: TEXT_INPUT },
    {
      field: 'typeCargoId',
      lable: 'Loại hàng',
      type: SELECTION,
      dataArrObject: cargoTypeList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'coloaderId',
      lable: 'Co-loader',
      type: SELECTION,
      dataArrObject: coloaderList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    {
      field: 'transshipmentPortId',
      lable: 'Cảng chuyển tải',
      type: SELECTION,
      dataArrObject: transShipmentPortList,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
    { field: 'refCode', lable: 'Ref code', disabled: disableByStatus, type: TEXT_INPUT },
    { field: 'frequency', lable: 'Tần suất', disabled: disableByStatus, type: TEXT_INPUT },
    { field: 'transitTime', lable: 'T/T Days', type: DATE_PICKER },
    { field: 'effectiveDate', lable: 'Ngày hiệu lực', type: DATE_PICKER },
    {
      field: 'deliveryMethodId',
      lable: 'Hình thức giao hàng',
      type: SELECTION,
      dataArrObject: deliveryMethod,
      fieldValue: 'id',
      fieldView: 'name',
      disabled: disableByStatus,
    },
  ];

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={onCloseModal}
        title={selected?.isNew ? 'Thêm mới' : 'Thông tin chi tiết'}
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <Divider sx={{ marginY: 1 }}>Thông tin cơ bản</Divider>
        {!loading && (
          <Formik
            initialValues={{
              status: 0,
              carrierId: '',
              polId: '',
              podId: '',
              currencyCode: '',
              etd: '',
              expirydate: '',
              arrContFreight: [],
              note: '',
              typeCargoId: '',
              coloaderId: '',
              transshipmentPortId: '',
              refCode: '',
              frequency: '',
              effectiveDate: '',
              deliveryMethodId: '',
              transitTime: '',
              ...selected,
            }}
            validationSchema={Yup.object().shape({
              carrierId: Yup.string().max(255).required('Vui lòng chọn hãng tàu'),
              polId: Yup.string().max(255).required('Vui lòng chọn cảng xếp dỡ'),
              podId: Yup.string().max(255).required('Vui lòng chọn cảng đến'),
              currencyCode: Yup.string().max(255).required('Vui lòng chọn đơn vị tiền tệ'),
              etd: Yup.string().max(255).required('Vui lòng chọn ngày ETD'),
              expirydate: Yup.string().max(255).required('Vui lòng chọn ngày hết hạn'),
              typeCargoId: Yup.string().max(255).required('Vui lòng chọn loại hàng'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (selected?.isNew) {
                createFn(values, { setSubmitting });
              } else {
                updateFn(values, { setSubmitting });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {ARRAY_INPUT.map((item, index) => {
                      switch (item.type) {
                        case SELECTION:
                          return (
                            <Grid key={`${item?.field}-${index}`} item xs={4} sm={6} md={3}>
                              <Selection
                                sxInput={{ padding: '0px', margin: '0px' }}
                                error={Boolean(touched[item.field] && errors[item.field])}
                                helperText={touched[item.field] && errors[item.field]}
                                label={item.lable}
                                name={item.field}
                                data={item?.data}
                                disabled={item?.disabled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item?.field]}
                                fieldValue={item?.fieldValue}
                                fieldView={item?.fieldView}
                                syncData={item?.syncData}
                                dataArrObject={item?.dataArrObject}
                              />
                            </Grid>
                          );
                        case DATE_PICKER:
                          return (
                            <Grid key={`${item?.field}-${index}`} item xs={4} sm={6} md={3}>
                              <TextInput
                                error={Boolean(touched[item?.field] && errors[item?.field])}
                                helperText={touched[item?.field] && errors[item?.field]}
                                label={item?.lable}
                                name={item?.field}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item?.field]}
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={item?.disabled}
                              />
                            </Grid>
                          );
                        default:
                          return (
                            <Grid key={`${item?.field}-${index}`} item xs={4} sm={6} md={3}>
                              <TextInput
                                error={Boolean(touched[item?.field] && errors[item?.field])}
                                helperText={touched[item?.field] && errors[item?.field]}
                                label={item?.lable}
                                name={item?.field}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[item?.field]}
                                disabled={item?.disabled}
                              />
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                </Box>
                <Divider sx={{ marginY: 1 }}>Bảng giá chi tiết</Divider>
                <SimpleComboBox
                  multiple
                  disableCloseOnSelect
                  label="Loại cont"
                  sx={{ width: '50%', marginBottom: '10px' }}
                  value={contTypeCodeCheck}
                  keyValue="code"
                  keyFieldView="code"
                  onChange={handleChangeContType}
                  syncData={ApiServices.getContTypes}
                />
                {contTypeCodeCheck?.length > 0 && (
                  <>
                    <FormGroup>
                      <FormControlLabel
                        sx={{ width: '30%' }}
                        control={<Checkbox checked={showPrice} onChange={handleCheckedPrice} />}
                        label="Giá cước"
                        disabled={disableByStatus}
                      />
                    </FormGroup>
                    {showPrice && (
                      <FreightPriceForm
                        arrContType={contTypeCodeCheck}
                        unitList={unitList}
                        name="arrContFreight"
                        requiredTitle="Vui lòng nhập đầy đủ thông tin"
                        onChange={handleChange}
                        value={values?.arrContFreight}
                        isAddRow={false}
                        closeCheckedItem={() => setShowPrice(false)}
                        disabledField={disableByStatus}
                      />
                    )}
                    {groupFeeGroup?.map((item, index) => {
                      return (
                        <FormGroup key={item.feeGroupID + index}>
                          <FormControlLabel
                            sx={{ width: '30%' }}
                            control={
                              <Checkbox
                                checked={item?.checked}
                                onChange={(event) => handleCheckFeeGroup(event, item)}
                                disabled={disableByStatus}
                              />
                            }
                            label={item?.feeGroupName}
                          />
                          {item?.checked && (
                            <FreightSubChargeForm
                              arrContType={contTypeCodeCheck}
                              arrSurcharge={item?.surchargeListFree}
                              arrUnit={unitList}
                              name={item?.feeGroupID}
                              onChange={(event) => handleChangeDataFeeGroup(event, item)}
                              value={item?.surcharge}
                              closeCheckedItem={() => handleCloseCheckedItem(item)}
                              requiredTitle="Vui lòng nhập đầy đủ thông tin"
                              disabledField={disableByStatus}
                            />
                          )}
                        </FormGroup>
                      );
                    })}
                  </>
                )}
                <Box sx={{ display: 'flex', py: 2 }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    {!selected?.expirationStatus && (
                      <BtnCreateUpdate
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        startIcon={selected?.isNew ? <BsPlusCircle /> : <BsSave />}
                      >
                        {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                      </BtnCreateUpdate>
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
