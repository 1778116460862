import React from 'react';
import { Box, Typography, Tab, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { CustomsLCL } from './customs-LCL';
import { CustomsAir } from './customs-air';
import { CustomsFCL } from './customs-FCL';

export const CustomsPriceBoard = () => {
  const [tabIndex, setTabIndex] = React.useState('1');
  const [addCustomsFCL, setAddCustomsFCL] = React.useState(false);
  const [addCustomsLCL, setAddCustomsLCL] = React.useState(false);
  const [addCustomsAir, setAddCustomsAir] = React.useState(false);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const closeAddNew = () => {
    if (tabIndex === '1') {
      setAddCustomsFCL(false);
    } else if (tabIndex === '2') {
      setAddCustomsLCL(false);
    } else if (tabIndex === '3') {
      setAddCustomsAir(false);
    }
  };

  const onHandleAddNew = () => {
    if (tabIndex === '1') {
      setAddCustomsFCL(true);
    } else if (tabIndex === '2') {
      setAddCustomsLCL(true);
    } else if (tabIndex === '3') {
      setAddCustomsAir(true);
    }
  };
  React.useEffect(() => {
    return () => {
      setAddCustomsFCL(false);
      setAddCustomsLCL(false);
      setAddCustomsAir(false);
    };
  }, []);
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" component="h6">
        BẢNG GIÁ DỊCH VỤ THỦ TỤC HẢI QUAN
      </Typography>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
            <TabItem label="FCL" value="1" />
            <TabItem label="LCL" value="2" />
            <TabItem label="Hàng không" value="3" />
            <Box sx={{ alignItems: 'center', marginLeft: 'auto' }}>
              <AddNewBtn variant="contained" onClick={onHandleAddNew}>
                Thêm mới
              </AddNewBtn>
            </Box>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: '5px 0px' }}>
          <CustomsFCL openAddCustomsFCL={addCustomsFCL} closeAddCustomsFCL={closeAddNew} />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '5px 0px' }}>
          <CustomsLCL openAddCustomsLCL={addCustomsLCL} closeAddCustomsLCL={closeAddNew} />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: '5px 0px' }}>
          <CustomsAir openAddCustomsAir={addCustomsAir} closeAddCustomsAir={closeAddNew} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));

export const AddNewBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  color: '#FFF',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));
