import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const colors = [
  'rgba(255, 99, 132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64,1)',
  'rgba(155, 159, 64, 1)',
  'rgba(225, 179, 64, 0.2)',
  'rgba(225, 179, 64, 0.2)',
  'rgba(225, 179, 64, 0.2)',
  'rgba(225, 179, 64, 0.2)',
  'rgba(225, 179, 64, 0.2)',
];
const PieChart = ({ data }) => {
  const color = data.map((item, index) => colors[index]);
  const inputData = {
    labels: data.map((item) => item?.ClientStatusName),
    datasets: [
      {
        label: '# of Votes',
        data: data.map((item) => item?.Count),
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ marginBlock: '10px' }}>
      <Pie
        data={inputData}
        options={{
          title: {
            display: true,
            text: 'Average Rainfall per month',
            fontSize: 20,
          },
          legend: {
            display: true,
            position: 'right',
          },
        }}
      />
    </div>
  );
};
export default PieChart;
