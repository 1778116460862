import React from 'react';
import { Typography, Box, Stack, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import storage from 'storage';
import { BsCheckAll } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import Utils from 'commons/utils';

export default function ChanelContainer({ chanel, children }) {
  const { users } = useSelector((state) => state.users);
  const renderForNewMessage = () => {
    if (!chanel?.members?.length || !chanel?.messages?.length) {
      return <BsCheckAll />;
    }
    const member = chanel.members.find((m) => m.code == storage.user.Code);
    if (member?.lastReadMessage && member?.lastReadMessage >= chanel?.messages[0].ts) {
      return <BsCheckAll />;
    }
    return <NewMessageIcon />;
  };
  const renderUserName = (userCode) => {
    if (userCode === storage.user.Code) {
      return 'Bạn';
    }
    const userTmp = users.find((u) => u.code == userCode);
    return userTmp?.name;
  };
  const renderContent = (message) => {
    if (message.text) {
      return message.text;
    }
    if (message.images?.length) {
      return 'Đã gửi hình ảnh';
    }
    if (message.files?.length) {
      return 'Đã đã gửi tệp đính kèm';
    }
  };
  return (
    <Wrapper>
      {children}
      <ChanelContent hasChildren={!!children}>
        <Description>
          <ChanelName>{chanel.name}</ChanelName>
          {renderForNewMessage()}
        </Description>
        {chanel?.messages?.length ? (
          <Description>
            <LastMessage>
              <PersonName>{renderUserName(chanel.messages[0]?.user)}</PersonName>:{' '}
              {renderContent(chanel.messages[0])}
            </LastMessage>
            <Time>{Utils.showTimeMessage(chanel.messages[0].ts)}</Time>
          </Description>
        ) : null}
      </ChanelContent>
    </Wrapper>
  );
}
const Wrapper = styled(Box)(({ hasChildren = false, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
}));
const ChanelContent = styled(Box)(({ hasChildren = false, theme }) => ({
  position: 'relative',
  flex: 1,
  width: hasChildren ? 'calc(100% - 52px)' : '100%',
}));
const Time = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: '12px',
  marginLeft: 8,
}));
//Description
const Description = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '100%',
}));
const LastMessage = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#303030',
  fontSize: '14px',
  fontWeight: 300,
  flex: 1,
}));
const PersonName = styled('span')(({ theme }) => ({
  fontWeight: 400,
}));
const ChanelName = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  flex: 1,
  width: 'calc(100% - 80px)',
}));
const NewMessageIcon = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  width: 12,
  height: 12,
  borderRadius: 6,
  background: theme.palette.primary.main,
  marginRight: 10,
}));
