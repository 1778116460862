import React from 'react';
import { Button } from '@mui/material';
import { PORT_TYPE } from 'commons/constants';

export const Columns = (deleteLocationVTItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Dịch vụ phụ',
    align: 'center',
  },
  {
    id: 'serviceName',
    numeric: true,
    disablePadding: false,
    label: 'Dịch vụ',
    align: 'center',
  },
  {
    id: 'companyName',
    numeric: true,
    disablePadding: false,
    label: 'Công ty',
    align: 'center',
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi Chú',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteLocationVTItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
