import { SearchInputColumn } from 'components';
export const Columns = (onColumnSearch) => [
  {
    id: 'code',
    disablePadding: true,
    label: 'Mã',
    label: <SearchInputColumn name="code" title="Mã chức danh" onChange={onColumnSearch} />,
    width: 10,
    align: 'center',
    sort: true,
  },
  {
    id: 'nameVI',
    disablePadding: false,
    label: <SearchInputColumn name="nameVI" title="Tên chức danh (Vi)" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
  {
    id: 'nameEN',
    disablePadding: false,
    label: 'Tên chức vụ (En)',
    label: <SearchInputColumn name="nameEN" title="Tên chức danh (En)" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
  {
    id: 'note',
    disablePadding: false,
    label: 'Ghi Chú',
    align: 'center',
  },
];
