import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, DateTimePicker } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createScheduleUser(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm lịch hẹn thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm lịch hẹn thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateScheduleUser(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={
          selected?.isNew
            ? 'Tạo mới'
            : `Công việc: ${selected?.name} ${selected?.subName ? `- ${selected?.subName}` : ''}`
        }
      >
        <Formik
          initialValues={{
            name: '',
            subName: '',
            beginWork: '',
            endWork: '',
            subBeginWork: '',
            subEndWork: '',
            manager: '',
            assigner: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Vui lòng nhập tên công việc chính'),
            subName: Yup.string().max(255).required('Vui lòng nhập công việc phụ'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Công việc chính"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                error={Boolean(touched.subName && errors.subName)}
                helperText={touched.subName && errors.subName}
                label="Công việc phụ"
                name="subName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subName}
              />
              <DateTimePicker
                onChange={handleChange}
                value={values.beginWork}
                error={Boolean(touched.beginWork && errors.beginWork)}
                helperText={touched.beginWork && errors.beginWork}
                label="Thời gian bắt đầu công việc chính"
                name="beginWork"
              />
              <DateTimePicker
                onChange={handleChange}
                value={values.endWork}
                error={Boolean(touched.endWork && errors.endWork)}
                helperText={touched.endWork && errors.endWork}
                label="Thời gian kết thúc công việc chính"
                name="endWork"
              />
              <DateTimePicker
                onChange={handleChange}
                value={values.subBeginWork}
                error={Boolean(touched.subBeginWork && errors.subBeginWork)}
                helperText={touched.subBeginWork && errors.subBeginWork}
                label="Thời gian bắt đầu công việc phụ"
                name="subBeginWork"
              />
              <DateTimePicker
                onChange={handleChange}
                value={values.subEndWork}
                error={Boolean(touched.subEndWork && errors.subEndWork)}
                helperText={touched.subEndWork && errors.subEndWork}
                label="Thời gian kết thúc công việc phụ"
                name="subEndWork"
              />
              <TextInput
                error={Boolean(touched.manager && errors.manager)}
                helperText={touched.manager && errors.manager}
                label="Người quản lý"
                name="manager"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.manager}
              />
              <TextInput
                error={Boolean(touched.assigner && errors.assigner)}
                helperText={touched.assigner && errors.assigner}
                label="Người giao việc"
                name="assigner"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.assigner}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
