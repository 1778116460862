import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button } from '@mui/material';
import { SEX } from 'commons/constants';

export const Columns = (handleSearchColumn, deleteItem) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã" name="code" onChange={handleSearchColumn} />,
    width: '100px',
    align: 'center',
  },
  {
    id: 'fullName',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Người liên hệ" name="fullName" onChange={handleSearchColumn} />
    ),
    align: 'left',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    with: '300px',
    label: (
      <SearchInputColumn
        title="Khách hàng"
        name="clientCompanyName"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
  },
  {
    id: 'sex',
    numeric: true,
    disablePadding: false,
    with: '100px',
    label: <SearchInputColumn title="Giới tính" name="sex" onChange={handleSearchColumn} />,
    align: 'center',
    render: (row) => SEX[row?.sex] && SEX[row?.sex],
  },
  {
    id: 'birthday',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Năm sinh" name="birthday" onChange={handleSearchColumn} />,
    with: '100px',
    align: 'center',
    render: (row) => Utils.formatDateFn(row.birthday),
  },
  {
    id: 'phoneNumber',
    numeric: true,
    disablePadding: false,
    with: '100px',
    label: <SearchInputColumn title="SĐT" name="phoneNumber" onChange={handleSearchColumn} />,
    align: 'center',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    with: '100px',
    label: <SearchInputColumn title="Email" name="email" onChange={handleSearchColumn} />,
    align: 'center',
  },
  {
    id: 'arrAddress',
    numeric: true,
    disablePadding: false,
    width: '300px',
    label: <SearchInputColumn title="Địa chỉ" name="arrAddress" onChange={handleSearchColumn} />,
    align: 'center',
    render: (row) => Utils.showArrayInTale(row.arrAddress),
  },
  {
    id: 'fax',
    numeric: true,
    disablePadding: false,
    with: '100px',
    label: <SearchInputColumn title="Ứng dụng liên hệ" name="fax" onChange={handleSearchColumn} />,
    align: 'center',
  },
  {
    id: 'nationality',
    numeric: true,
    disablePadding: false,
    with: '120px',
    label: <SearchInputColumn title="Quốc tịch" name="nationality" onChange={handleSearchColumn} />,
    align: 'center',
  },
  {
    id: 'religion',
    numeric: true,
    disablePadding: false,
    with: '120px',
    label: <SearchInputColumn title="Tôn giáo" name="religion" onChange={handleSearchColumn} />,
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    width: '10px',
    fixed: 'right',
    label: 'Thao tác',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
