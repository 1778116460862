import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Selection } from 'components';
import ApiServices from 'services';
import { SUB_SERVICES_ID } from 'commons/constants';
import { TractorPriceForm } from './tractor-price-form';
import { TruckCargoForm } from './truck-cargo-form';

export const TruckingRequestForm = ({
  selected,
  itemData,
  subServiceSelectedList,
  setSubServiceSelectedList,
}) => {
  const { TRACTOR, TRUCK } = SUB_SERVICES_ID;
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [locationVTList, setLocationVTList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [locationFromId, setLocationFromId] = useState('');
  const [areaFromId, setAreaFromId] = useState('');
  const [locationToId, setLocationToId] = useState('');
  const [areaToId, setAreaToId] = useState('');
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [arrTypeCargoCode, setArrTypeCargoCode] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyCode, setCurrencyCode] = useState('');
  const [arrContTractor, setArrContTractor] = useState([]);
  const [arrContTruck, setArrContTruck] = useState([]);

  useEffect(() => {
    if (itemData && itemData?.contentList) {
      setLocationFromId(itemData?.contentList?.locationFromId || '');
      setAreaFromId(itemData?.contentList?.areaFromId || '');
      setLocationToId(itemData?.contentList?.locationToId || '');
      setAreaToId(itemData?.contentList?.areaToId || '');
      setArrTypeCargoCode(itemData?.contentList?.arrTypeCargoCode || []);
      setCurrencyCode(itemData?.contentList?.currencyCode || '');
      setArrContTractor(itemData?.contentList?.arrContTractor || []);
      setArrContTruck(itemData?.contentList?.arrContTruck || []);
    }
  }, [itemData]);

  const getDataInits = async () => {
    let [getLocationVT, getArea, getCargoType, getCurrency] = await Promise.all([
      ApiServices.getLocationVTs(),
      ApiServices.getAreas(),
      ApiServices.getCargoTypes(),
      ApiServices.getCurrencies(),
    ]);
    setLocationVTList(getLocationVT.data || []);
    setAreaList(getArea.data || []);
    setCargoTypeList(getCargoType.data || []);
    setCurrencyList(getCurrency.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoadingInit(true);
      await getDataInits();
      setLoadingInit(false);
    };
    getDataInit();
  }, []);

  const handleChangeLocationFrom = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setLocationFromId(e.target.value);
      setAreaFromId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.locationFromId = e.target.value;
      newData[ind].contentList.areaFromId = null;
      setSubServiceSelectedList(newData);
    },
    [locationFromId],
  );
  const handleChangeAreaFrom = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setAreaFromId(e.target.value);
      setLocationFromId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.areaFromId = e.target.value;
      newData[ind].contentList.locationFromId = null;
      setSubServiceSelectedList(newData);
    },
    [areaFromId],
  );
  const handleChangeLocationTo = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setLocationToId(e.target.value);
      setAreaToId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.locationToId = e.target.value;
      newData[ind].contentList.areaToId = null;
      setSubServiceSelectedList(newData);
    },
    [locationToId],
  );
  const handleChangeAreaTo = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setAreaToId(e.target.value);
      setLocationToId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.areaToId = e.target.value;
      newData[ind].contentList.locationToId = null;
      setSubServiceSelectedList(newData);
    },
    [areaToId],
  );
  const handleChangeCargoType = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setArrTypeCargoCode(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.arrTypeCargoCode = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [arrTypeCargoCode],
  );
  const handleChangeCurrency = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setCurrencyCode(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.currencyCode = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [currencyCode],
  );
  const handleChangeTractorRequestPrice = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setArrContTractor(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.arrContTractor = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [arrContTractor],
  );
  const handleChangeTruckRequestPrice = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setArrContTruck(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.arrContTruck = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [arrContTruck],
  );

  const ARRAY_SELECT_GENERAL_INFO = [
    {
      value: locationFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(areaFromId),
      label: 'Điểm đi',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationFrom,
    },
    {
      value: areaFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(locationFromId),
      label: 'Khu vực đi',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaFrom,
    },
    {
      value: locationToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(areaToId),
      label: 'Điểm đến',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationTo,
    },
    {
      value: areaToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(locationToId),
      label: 'Khu vực đến',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaTo,
    },
  ];

  return (
    <>
      {!loadingInit && !loading && (
        <>
          <ContainerHeaderTractor>
            <Selection
              sx={{ width: '50%' }}
              error={!arrTypeCargoCode || arrTypeCargoCode?.length === 0}
              helperText={(!arrTypeCargoCode || arrTypeCargoCode?.length === 0) && 'Bắt buộc (*)'}
              label="Loại hàng"
              name="arrTypeCargoCode"
              multiple={true}
              onChange={handleChangeCargoType}
              value={arrTypeCargoCode}
              fieldValue="id"
              fieldView="name"
              dataArrObject={cargoTypeList}
            />
            {itemData?.id === TRACTOR && (
              <Selection
                sx={{ width: '50%', marginLeft: '10px' }}
                error={!currencyCode}
                helperText={!currencyCode && 'Bắt buộc (*)'}
                label="Đơn vị tiền tệ"
                name="currencyCode"
                onChange={handleChangeCurrency}
                value={currencyCode}
                fieldValue="code"
                fieldView="name"
                dataArrObject={currencyList}
              />
            )}
          </ContainerHeaderTractor>
          <b>GIÁ CƯỚC</b>
          <ContainerGeneral>
            {ARRAY_SELECT_GENERAL_INFO.map((item, index) => {
              return (
                <Selection
                  key={`selection-${item?.label + index}`}
                  sx={{ width: '50%', marginRight: '10px' }}
                  sxInput={{ padding: '0px', margin: '0px' }}
                  error={item?.isError}
                  helperText={item?.isError && 'Bắt buộc (*)'}
                  label={item.label}
                  name={item.field}
                  disabled={item?.disabled}
                  onChange={item?.handleChange}
                  value={item?.value}
                  fieldValue={item?.fieldValue}
                  fieldView={item?.fieldView}
                  syncData={item?.syncData}
                  dataArrObject={item?.dataArrObject}
                  disabledItemNone={false}
                />
              );
            })}
          </ContainerGeneral>
          <ContainerRequestPrice>
            {itemData?.id === TRACTOR && arrTypeCargoCode?.length > 0 && (
              <TractorPriceForm
                arrContType={arrTypeCargoCode}
                cargoTypeList={cargoTypeList}
                name="arrContTractor"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeTractorRequestPrice}
                value={arrContTractor}
                isShowPriceReal={false}
              />
            )}
            {itemData?.id === TRUCK && arrTypeCargoCode?.length > 0 && (
              <TruckCargoForm
                arrContType={arrTypeCargoCode}
                cargoTypeList={cargoTypeList}
                name="arrContTruck"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeTruckRequestPrice}
                value={arrContTruck}
                isShowPriceReal={false}
              />
            )}
          </ContainerRequestPrice>
        </>
      )}
    </>
  );
};

export const ContainerGeneral = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const ContainerRequestPrice = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
}));

export const ContainerHeaderTractor = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));
