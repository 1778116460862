import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection, NumberFormatCustom } from 'components';
import ApiServices from 'services';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      onAdd,
      values,
      setValues,
      disabledField,
      keyArray,
      keyNameOfArray,
      cargoTypeList,
      isShowPriceReal,
      isShowAction,
    },
    ref,
  ) => {
    const [contTypeCode, setContType] = React.useState('');
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setContType(item?.contTypeCode);
      setArrayCont(item[keyArray] || []);
    }, [item]);

    const onChangeContType = React.useCallback(
      (e) => {
        setContType(e.target.value);
        setValues((prev) => {
          prev[index].contTypeCode = e.target.value;
          return [...prev];
        });
      },
      [contTypeCode],
    );

    const onChangeCont = React.useCallback(
      (e, indContType, keyChange) => {
        const { value } = e.target;
        setArrayCont((prev) => {
          if (keyChange === 'cargoTypePrice') {
            prev[indContType].cargoTypePrice = +value;
          }
          if (keyChange === 'requestPrice') {
            prev[indContType].requestPrice = +value;
          }
          return [...prev];
        });
        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    const renderLabel = (id) => {
      const ind = cargoTypeList.findIndex((item) => item.id === id);
      if (ind === -1) return '';
      return cargoTypeList[ind]?.name;
    };

    const isDisabled =
      !contTypeCode ||
      (isShowPriceReal && !arrayCont.some((item) => item.cargoTypePrice)) ||
      !arrayCont.some((item) => item.requestPrice);

    return (
      <Container>
        <ContainerBody>
          <Box sx={{ width: '100%' }}>
            <Selection
              required
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Loại cont"
              name="contTypeCode"
              onChange={onChangeContType}
              value={contTypeCode}
              syncData={ApiServices.getContTypes}
              fieldValue="code"
              error={!contTypeCode}
              disabled={isShowPriceReal}
            />
            {!contTypeCode && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          {arrayCont?.map((el, indexRequest) => (
            <Box sx={{ width: '100%' }} key={'Request' + indexRequest}>
              <StyledTextFieldReqPrice
                label={'Request ' + renderLabel(el[keyNameOfArray])}
                variant="outlined"
                type="text"
                size="small"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => onChangeCont(e, indexRequest, 'requestPrice')}
                value={el?.requestPrice}
                error={!el?.requestPrice}
                disabled={isShowPriceReal}
              />
              {!el?.requestPrice && <ErrorText>Bắt buộc (*)</ErrorText>}
            </Box>
          ))}
          {isShowPriceReal &&
            arrayCont?.map((el, indContType) => (
              <Box sx={{ width: '100%' }} key={'Real' + indContType}>
                <StyledTextFieldPrice
                  label={renderLabel(el[keyNameOfArray])}
                  variant="outlined"
                  type="text"
                  size="small"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={(e) => onChangeCont(e, indContType, 'cargoTypePrice')}
                  value={el?.cargoTypePrice}
                  error={!el?.cargoTypePrice}
                  disabled={disabledField}
                />
                {!el?.cargoTypePrice && <ErrorText>Bắt buộc (*)</ErrorText>}
              </Box>
            ))}
        </ContainerBody>
        {isShowAction && (
          <ContainerAction>
            {index === values.length - 1 ? (
              <>
                <ButtonWrapper
                  onClick={() => onDelete(index)}
                  sx={{ borderColor: 'error.main' }}
                  disabled={disabledField}
                >
                  <HighlightOffIcon sx={{ color: 'error.main' }} />
                </ButtonWrapper>
                <ButtonWrapper
                  onClick={onAdd}
                  sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                  disabled={isDisabled}
                >
                  <AddCircleOutlineIcon
                    sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                  />
                </ButtonWrapper>
              </>
            ) : (
              <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
            )}
          </ContainerAction>
        )}
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));
const StyledTextFieldReqPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
  backgroundColor: '#FCF2EF',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px',
}));
const ContainerBody = styled(Box)(({ theme }) => ({
  width: '95%',
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
