import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  List,
  IconButton,
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
} from '@mui/icons-material';

const ShowListFile = ({ fileList = [], maxWidth = '100px' }) => {
  const renderFile = (item, index) => {
    if (!item) return <></>;
    return (
      <a
        target="_blank"
        href={`${process.env.REACT_APP_API_URL}/${item}`}
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: maxWidth,
        }}
      >
        <p
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: maxWidth,
          }}
        >
          <b style={{ color: 'red' }}>{index + 1}. </b>
          {item}
        </p>
      </a>
    );
  };

  return fileList?.map(renderFile);

  // return (
  // <List dense={true}>
  //   {fileList?.map(([item], index) => (
  //     <ListItem
  //       key={`file-item-${index}`}
  //       secondaryAction={
  //         <IconButton edge="end" aria-label="delete">
  //           <DeleteIcon fontSize="small" />
  //         </IconButton>
  //       }
  //     >
  //       <ListItemAvatar>
  //         <AttachFileIcon fontSize="small" />
  //       </ListItemAvatar>
  //       <ListItemText
  //         sx={item?.isAvailable && { cursor: 'pointer' }}
  //         onClick={() => {
  //           if (item?.isAvailable) {
  //             window.open(`${process.env.REACT_APP_API_URL}/${item}`, '_blank');
  //           }
  //         }}
  //         primary={item?.name}
  //       />
  //     </ListItem>
  //   ))}
  // </List>
  // );
};

export { ShowListFile };
