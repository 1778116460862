import React, { useState, useEffect, useMemo } from 'react';
import {
  BsEye,
  BsSave,
  BsBoxArrowInUpRight,
  BsXCircle,
  BsCheck2Circle,
  BsEnvelope,
} from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { updateServiceToQuote } from 'store/reducers/customerQuotes/action';
import { SUB_SERVICES_ID, QUOTE_STATUS, FEE_GROUP_ID } from 'commons/constants';
import { Box, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { QuoteWhLeasingPriceForm } from '../../forms-management/quote-wh-leasing-price-form';
import { QuoteWhHandlingChargeForm } from '../../forms-management/quote-wh-handling-charge-form';
import { QuoteWhInboundOutboundForm } from '../../forms-management/quote-wh-inbound-outbound-form';
import { QuoteWhTransportChargeForm } from '../../forms-management/quote-wh-transport-charge-form';
import { QuoteWhOtherServicesForm } from '../../forms-management/quote-wh-other-services-form';
import Utils from 'commons/utils';
import ApiServices from 'services';
import {
  ContainerPrice,
  ContainerItemService,
  BtnViewReportQuote,
  BtnCreateQuote,
  BtnRequestQuote,
  BtnRejectQuote,
  BtnApproveQuote,
  BtnSendQuote,
} from './styles';

const WarehouseControlQuoteForm = ({ handleCloseAfterSubmit, initData, selected }) => {
  const dispatch = useDispatch();
  const { itemServiceSelectedToQuote, objQuotes } = useSelector((state) => state.customerQuotes);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaseSpaceList, setLeaseSpaceList] = useState([]);

  console.log({ itemServiceSelectedToQuote });

  const isDisabledForm = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [selected]);

  const getLeaseSpace = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getLeaseSpaces();
      if (data) {
        setLeaseSpaceList(data || []);
      }
    } catch (err) {
      console.warn('getLeaseSpace', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeaseSpace();
  }, []);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSSAndFeeId(
        FEE_GROUP_ID.OTHER_SERVICES,
        SUB_SERVICES_ID.BONDED_WAREHOUSE,
      );
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = itemServiceSelectedToQuote?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = itemServiceSelectedToQuote?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (itemServiceSelectedToQuote?.subServiceId) {
      getSurchargeByFeeGroupSS(itemServiceSelectedToQuote?.subServiceId);
    }
  }, []);

  const createQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: {
        ...objQuotes,
        quotesStatus,
        quotedate: quoteDate,
        arrServiceId: [itemServiceSelectedToQuote?.serviceId],
        arrSubServiceId: [itemServiceSelectedToQuote?.subServiceId],
      },
      objWarehouse: { ...itemServiceSelectedToQuote },
    };
    try {
      const { status } = await ApiServices.createWarehouseQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const updateQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: { ...objQuotes, quotedate: quoteDate, quotesStatus },
      objWarehouse: { ...itemServiceSelectedToQuote },
    };
    try {
      const { status } = await ApiServices.updateWarehouseQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const handleSubmit = (quotesStatus, quoteDate = null) => {
    if (selected?.isNew) {
      return createQuote(quotesStatus, quoteDate);
    } else {
      return updateQuote(quotesStatus, quoteDate);
    }
  };

  const handleReportQuoteTemplate = async (subServiceId, quoteId) => {
    await ApiServices.exportFileOfWarehouse(subServiceId, quoteId)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `${selected?.code}.docx`);
        }
      })
      .catch((err) => {
        console.log('Failed to get report quote tempalte:', err);
      });
  };

  const handleChangeLeasingPrice = (e) => {
    dispatch(updateServiceToQuote({ warehouseLeasingPrice: e.target.value }));
  };
  const handleChangeHandlingCharge = (e) => {
    dispatch(updateServiceToQuote({ listHandlingCharges: e.target.value }));
  };
  const handleChangeInBound = (e) => {
    dispatch(updateServiceToQuote({ listCCSChargeInbound: e.target.value }));
  };
  const handleChangeOutBound = (e) => {
    dispatch(updateServiceToQuote({ listCCSChargeOutBound: e.target.value }));
  };
  const handleChangeTransportCharge = (e) => {
    dispatch(updateServiceToQuote({ transportServiceCharge: e.target.value }));
  };
  const handleChangeSubCharge = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    dispatch(updateServiceToQuote({ arrSurcharge: data }));
  };

  const renderSubChargeWarehouse = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: 'auto' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteWhOtherServicesForm
          arrSurcharge={item?.surchargeListFree}
          name={item.feeGroupID}
          onChange={(event) => handleChangeSubCharge(event, item)}
          value={item?.surcharge}
        />
      )}
    </FormGroup>
  );

  const renderLabelSubPrice = (label = '', checked = false) => (
    <FormGroup>
      <FormControlLabel
        sx={{ width: 'auto' }}
        control={<Checkbox checked={checked} />}
        label={label}
      />
    </FormGroup>
  );

  return (
    <>
      <ContainerItemService>
        <b>
          {itemServiceSelectedToQuote?.serviceName} - {itemServiceSelectedToQuote?.subServiceName}
        </b>
      </ContainerItemService>
      <ContainerPrice>
        <b>Warehouse leasing price</b>
        {leaseSpaceList?.length > 0 && (
          <QuoteWhLeasingPriceForm
            name="warehouseLeasingPrice"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={handleChangeLeasingPrice}
            value={itemServiceSelectedToQuote?.warehouseLeasingPrice}
            leaseSpaceList={leaseSpaceList}
            disabled={isDisabledForm}
          />
        )}

        {renderLabelSubPrice(
          'Handling charges',
          itemServiceSelectedToQuote?.listHandlingCharges?.length > 0,
        )}
        {itemServiceSelectedToQuote?.listHandlingCharges?.length > 0 && (
          <QuoteWhHandlingChargeForm
            name="listHandlingCharges"
            onChange={handleChangeHandlingCharge}
            value={itemServiceSelectedToQuote?.listHandlingCharges}
          />
        )}

        {renderLabelSubPrice(
          'Customs clearance service charges Inbound',
          itemServiceSelectedToQuote?.listCCSChargeInbound?.length > 0,
        )}
        {itemServiceSelectedToQuote?.listCCSChargeInbound?.length > 0 && (
          <QuoteWhInboundOutboundForm
            name="listCCSChargeOutBound"
            onChange={handleChangeInBound}
            value={itemServiceSelectedToQuote?.listCCSChargeInbound}
          />
        )}

        {renderLabelSubPrice(
          'Customs clearance service charges Outbound',
          itemServiceSelectedToQuote?.listCCSChargeOutBound?.length > 0,
        )}
        {itemServiceSelectedToQuote?.listCCSChargeOutBound?.length > 0 && (
          <QuoteWhInboundOutboundForm
            name="listCCSChargeOutBound"
            onChange={handleChangeOutBound}
            value={itemServiceSelectedToQuote?.listCCSChargeOutBound}
            isOutbound={true}
          />
        )}

        {renderLabelSubPrice(
          'Transport service charge',
          itemServiceSelectedToQuote?.transportServiceCharge?.length > 0,
        )}
        {itemServiceSelectedToQuote?.transportServiceCharge?.length > 0 && (
          <QuoteWhTransportChargeForm
            name="transportServiceCharge"
            onChange={handleChangeTransportCharge}
            value={itemServiceSelectedToQuote?.transportServiceCharge}
          />
        )}

        {groupFeeGroup?.map(renderSubChargeWarehouse)}
      </ContainerPrice>
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          {!selected?.isNew && (
            <BtnViewReportQuote
              variant="outlined"
              startIcon={<BsEye />}
              onClick={() => handleReportQuoteTemplate(selected?.arrSubServiceId[0], selected?.id)}
            >
              Xem báo giá
            </BtnViewReportQuote>
          )}

          {(selected?.isNew || selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) && (
            <>
              <BtnCreateQuote
                sx={{ marginRight: '15px' }}
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<BsSave size={16} />}
                onClick={() => handleSubmit(QUOTE_STATUS.ORIGIN_QUOTE)}
                disabled={!objQuotes?.clientCompanyId}
              >
                {selected?.isNew ? 'Tạo báo giá' : 'Lưu'}
              </BtnCreateQuote>
              <BtnRequestQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsBoxArrowInUpRight size={20} />}
                onClick={() =>
                  handleSubmit(QUOTE_STATUS.REQUEST_APPROVE, Utils.convertToDateFormat(new Date()))
                }
                disabled={!objQuotes?.clientCompanyId || !objQuotes?.clientId}
              >
                Trình duyệt
              </BtnRequestQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.REQUEST_APPROVE && (
            <>
              <BtnRejectQuote
                sx={{ marginRight: '15px' }}
                color="error"
                type="submit"
                variant="contained"
                startIcon={<BsXCircle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.REJECT_APPROVE)}
              >
                Không duyệt
              </BtnRejectQuote>
              <BtnApproveQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsCheck2Circle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
              >
                Duyệt
              </BtnApproveQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.APPROVED && (
            <BtnSendQuote
              color="success"
              type="submit"
              variant="contained"
              startIcon={<BsEnvelope size={20} />}
              // onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
            >
              Gửi báo giá
            </BtnSendQuote>
          )}
        </Box>
      </Box>
    </>
  );
};

export { WarehouseControlQuoteForm };
