import * as React from 'react';
import Box from '@mui/material/Box';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  IconButton,
  Badge,
} from '@mui/material';
import { RiKey2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedChanel } from 'store/reducers/chat-chanel/action';
import { MdClear } from 'react-icons/md';
import { styled } from '@mui/material/styles';
import ChatServices from 'services/chat-services';
import storage from 'storage';
import './messages.css';

const MemberList = ({ selectedChanel }) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);

  const membersList = selectedChanel?.members?.map((item) => {
    const dataUser = users.find((m) => m.code === item.code);
    return dataUser;
  });

  const onDeleteMemberFn = async (memberCode) => {
    try {
      const { data } = await ChatServices.removeMember(selectedChanel._id, memberCode);
      if (data.code === 'success') {
        window.UIMessage.success('Xóa thành viên thành công');
        dispatch(setSelectedChanel(data.results[0]));
      }
    } catch (error) {
      window.UIMessage.error('Xóa thành viên thất bại');
    }
  };

  return (
    <Box
      className="add-members"
      sx={{
        width: '450px',
        bgcolor: 'background.paper',
      }}
    >
      <List>
        {membersList?.map((item, index) => (
          <ListItemMember
            disablePadding
            key={index}
            secondaryAction={
              item.code !== selectedChanel.owner &&
              storage.user?.Code === selectedChanel.owner && (
                <IconButton
                  edge="end"
                  aria-label="comments"
                  color="primary"
                  onClick={() => onDeleteMemberFn(item.code)}
                  disabled={selectedChanel?.isPersonal}
                  sx={{
                    color: 'action.active',
                    fontSize: '20px',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                      color: '#0059B2',
                    },
                  }}
                >
                  <MdClear />
                </IconButton>
              )
            }
          >
            <ListItemButton>
              <ListItemIcon>
                {item.code === selectedChanel.owner ? (
                  <Badge
                    badgeContent={<RiKey2Line />}
                    color="secondary"
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    {/* <Avatar>{item.code.slice(0, 1).toUpperCase()}</Avatar> */}
                    <Avatar
                      alt={item.name}
                      src={item?.urlAvatar && `${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                    />
                  </Badge>
                ) : (
                  // <Avatar>{item.code.slice(0, 1).toUpperCase()}</Avatar>
                  <Avatar
                    alt={item.name}
                    src={item?.urlAvatar && `${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                secondary={
                  <span
                    sx={{
                      '&:hover': {
                        color: '#0059B2',
                      },
                    }}
                  >
                    {item.code === selectedChanel.owner ? 'Trưởng nhóm' : 'Thành viên'}
                    {/* {item.titleName} - {item.departmentName} */}
                  </span>
                }
              />
            </ListItemButton>
          </ListItemMember>
        ))}
      </List>
    </Box>
  );
};

export { MemberList };

const ListItemMember = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  transition: 'auto',
  '&:hover': {
    border: '1px solid red',
    backgroundColor: 'rgb(240, 247, 255)',
    borderColor: 'rgb(0, 127, 255)',
    borderRadius: '10px',
    color: '#0059B2',
  },
}));
