import React, { memo } from 'react';

export const RenderAreaAndLocationFrom = memo(({ areaFrom, locationFrom, suffixLabel = '' }) => {
  if ((!areaFrom && !locationFrom) || (areaFrom && locationFrom)) return '';

  const label = areaFrom ? `K.vực ${suffixLabel}: ` : `Đ.điểm ${suffixLabel}: `;
  return (
    <div>
      <i style={{ color: 'blue' }}>{label}</i>{' '}
      {(areaFrom && areaFrom?.name) || (locationFrom && locationFrom?.name)}
    </div>
  );
});
export const RenderAreaAndLocationTo = memo(({ areaTo, locationTo, suffixLabel = '' }) => {
  if ((!areaTo && !locationTo) || (areaTo && locationTo)) return '';

  const label = areaTo ? `K.vực ${suffixLabel}: ` : `Đ.điểm ${suffixLabel}: `;
  return (
    <div>
      <i style={{ color: 'red' }}>{label}</i>{' '}
      {(areaTo && areaTo?.name) || (locationTo && locationTo?.name)}
    </div>
  );
});
