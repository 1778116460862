import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box, Tooltip } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection } from 'components';
import ApiServices from 'services';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  ({ item, index, onDelete, onAdd, values, setValues, disabledField }, ref) => {
    const [weightandVolume, setWeightandVolume] = React.useState(0);
    const [price, setPrice] = React.useState(0);
    const [unitCode, setUnitCode] = React.useState('');
    const [extraCost, setExtraCost] = React.useState(0);
    const [extraQuantity, setExtraQuantity] = React.useState(0);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setWeightandVolume(item?.weightandVolume);
      setPrice(item.price);
      setUnitCode(item?.unitCode);
      setExtraCost(item?.extraCost);
      setExtraQuantity(item?.extraQuantity);
    }, [item]);

    const onChangeWeight = React.useCallback(
      (e) => {
        setWeightandVolume(+e.target.value);
        setValues((prev) => {
          prev[index].weightandVolume = +e.target.value;
          return [...prev];
        });
      },
      [weightandVolume],
    );
    const onChangePrice = React.useCallback(
      (e) => {
        setPrice(+e.target.value);
        setValues((prev) => {
          prev[index].price = +e.target.value;
          return [...prev];
        });
      },
      [price],
    );
    const onChangeUnit = React.useCallback(
      (e) => {
        setUnitCode(e.target.value);
        setValues((prev) => {
          prev[index].unitCode = e.target.value;
          return [...prev];
        });
      },
      [unitCode],
    );

    const onChangeExtraCost = React.useCallback(
      (e) => {
        setExtraCost(+e.target.value);
        setValues((prev) => {
          prev[index].extraCost = +e.target.value;
          return [...prev];
        });
      },
      [extraCost],
    );

    const onChangeExtraQuantity = React.useCallback(
      (e) => {
        setExtraQuantity(+e.target.value);
        setValues((prev) => {
          prev[index].extraQuantity = +e.target.value;
          return [...prev];
        });
      },
      [extraQuantity],
    );
    const isDisabled = !weightandVolume || !price || !unitCode;

    return (
      <Container>
        <ContainerBody>
          <Box sx={{ width: '100%' }}>
            <StyledTextFieldPrice
              label="Trọng lượng/Thể tích"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={onChangeWeight}
              value={weightandVolume}
              error={!weightandVolume}
              disabled={disabledField}
            />
            {!weightandVolume && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <Box sx={{ width: '50%' }}>
            <StyledTextFieldPrice
              label="Giá"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={onChangePrice}
              value={price}
              error={!price}
              disabled={disabledField}
            />
            {!price && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <Box sx={{ width: '50%' }}>
            <Selection
              required
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Đơn vị tính"
              name="serviceId"
              onChange={onChangeUnit}
              value={unitCode}
              syncData={ApiServices.getUnits}
              fieldValue="code"
              error={!unitCode}
              disabled={disabledField}
            />
            {!unitCode && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <StyledTextField
            label="Đơn giá thêm"
            variant="outlined"
            type="number"
            size="small"
            onChange={onChangeExtraCost}
            value={extraCost}
            disabled={disabledField}
          />
          <StyledTextField
            label="Số lượng thêm"
            variant="outlined"
            type="number"
            size="small"
            onChange={onChangeExtraQuantity}
            value={extraQuantity}
            disabled={disabledField}
          />
        </ContainerBody>
        <ContainerAction>
          {index === values.length - 1 ? (
            <>
              <Tooltip title="Xóa" arrow>
                <ButtonWrapper
                  marginBottom={isDisabled ? '15px' : '0px'}
                  onClick={() => onDelete(index)}
                  sx={{ borderColor: 'error.main' }}
                  disabled={disabledField}
                >
                  <HighlightOffIcon sx={{ color: 'error.main' }} />
                </ButtonWrapper>
              </Tooltip>
              <Tooltip title="Thêm" arrow>
                <ButtonWrapper
                  marginBottom={isDisabled ? '15px' : '0px'}
                  onClick={onAdd}
                  sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                  disabled={isDisabled}
                >
                  <AddCircleOutlineIcon
                    sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                  />
                </ButtonWrapper>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Xóa" arrow>
              <ButtonWrapper
                onClick={() => onDelete(index)}
                sx={{ borderColor: 'error.main' }}
                marginBottom={isDisabled ? '15px' : '0px'}
              >
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
            </Tooltip>
          )}
        </ContainerAction>
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '95%',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  marginLeft: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerBody = styled(Box)(({ theme }) => ({
  width: '95%',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false, marginBottom = '10px' }) => ({
  marginBottom,
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
