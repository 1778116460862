export default {
  SELECT_SERVICE_TO_QUOTE: 'select_service_to_quote',
  UPDATE_SERVICE_TO_QUOTE: 'update_service_to_quote',
  CLEAR_SERVICE_TO_QUOTE: 'clear_service_to_quote',
  SELECT_OBJ_QUOTES: 'select_obj_quotes',
  UPDATE_OBJ_QUOTES: 'update_obj_quotes',
  CLEAR_OBJ_QUOTES: 'clear_obj_quotes',
  SELECT_TRUCKING_SERVICE: 'select_trucking_service',
  CLEAR_TRUCKING_SERVICE: 'clear_trucking_service',
  UPDATE_TRUCKING_SERVICE: 'update_trucking_service',
  SELECT_CUSTOMS_SERVICE: 'select_customs_service',
  CLEAR_CUSTOMS_SERVICE: 'clear_customs_service',
  UPDATE_CUSTOMS_SERVICE: 'update_customs_service',
  CLEAR_ALL_DATA_QUOTE: 'clear_all_data_quote',
};
