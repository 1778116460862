export default {
  flex: 1,
  backgroundColor: 'transparent',
  fontSize: 16,
  control: {
    fontSize: 16,
    lineHeight: 1.2,
    minHeight: 20,
    maxHeight: 120,
    overflowY: 'auto',
  },

  highlighter: {
    padding: 8,
  },

  input: {
    fontSize: 16,
    lineHeight: 1.2,
    padding: 8,
    border: 0,
    outline: 'none',
    '&:focus-visible': {
      outline: 'none',
    },
  },

  suggestions: {
    borderRadius: 12,
    zIndex: 999,
    list: {
      backgroundColor: 'transparent',
      fontSize: 16,
      borderRadius: 12,
    },

    item: {
      borderRadius: 12,
      padding: '8px 12px',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
