import * as React from 'react';
import Box from '@mui/material/Box';
import { Paper, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ApiServices from 'services';
export default function ({ data, width, setData, reload }) {
  const [loading, setLoading] = React.useState(false);
  const onSubmit = async () => {
    setLoading(true);

    await ApiServices.updateServiceReceived(
      Object.values(data).map((el) => ({
        clientCompanyId: el?.row.id,
        arrServiceId: el.services.map((service) => service.id),
      })),
    )
      .then((res) => {
        if (res.status === 200) {
          window.UIMessage?.success('Thao tác thành công');
          setData({});
          reload();
        } else {
          window.UIMessage?.error(res);
        }
      })
      .catch((err) => {
        let messageError = '';
        if (err?.message) {
          messageError = err?.message || '';
        } else if (err?.data?.message) {
          messageError = err?.data?.message || '';
        }
        window.UIMessage?.error(`Tao tác thất bại !!! ${messageError}`);
      });
    setLoading(false);
  };

  return (
    <Box sx={{ width }}>
      <Typography variant="h7" component="h6" sx={{ p: '16px', textTransform: 'uppercase' }}>
        Danh sách Dịch vụ Đã chọn
      </Typography>
      <Wrapper elevation={0}>
        {Object.values(data).map((item, index) =>
          item?.services.length ? (
            <Box key={`selected-services-${item.row?.id}`}>
              <Typography variant="h7" component="h6">
                {index + 1}. {item.row?.name} ({item.services?.length} dịch vụ)
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {item.services.map((el) => (
                  <Typography
                    variant="h7"
                    component="h6"
                    sx={{ fontWeight: '300' }}
                    key={`selected-services-${el.id}-${item.row?.id}`}
                  >
                    - {el.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          ) : null,
        )}
      </Wrapper>
      <Box sx={{ p: '16px', display: 'flex' }}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          sx={{ ml: 'auto' }}
          disabled={loading}
          onClick={onSubmit}
        >
          Chăm sóc
        </Button>
      </Box>
    </Box>
  );
}
const Wrapper = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginLeft: 10,
}));
