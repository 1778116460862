import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routers from './routes';
import AlertMessage from 'components/alert-messages';
import { ConfirmMessage } from 'components';
import { useDispatch } from 'react-redux';
import {
  alertSuccess,
  alertError,
  alertErrorList,
  alertWarning,
  alertInfo,
} from 'store/reducers/alert-messages/action';
import { showConfirm } from 'store/reducers/confirm-messages/action';
// import { SocketClientHandle } from './socket-handle';
import * as serviceWorker from './serviceWorker';
import { Helmet } from 'react-helmet';
import { LoadingContainer } from 'components';
// import ChatServices from 'services/chat-services';
// import { fetchUsers } from 'store/reducers/users/action';
// import { fetchChanel, fetchPersonalChanel } from 'store/reducers/chat-chanel/action';

import storage from 'storage';
var hist = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const initRef = React.useRef(true);

  if (window.location.protocol == 'http:' && process.env.NODE_ENV != 'development') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }

  useEffect(() => {
    window.UIMessage = {
      success: (title = '', message = '', timeout = 2000) => {
        dispatch(alertSuccess(title, message, timeout));
      },
      error: (title = '', message = '', timeout = 3000) => {
        dispatch(alertError(title, message, timeout));
      },
      errorList: (title = '', messageList = [], timeout = 3000) => {
        dispatch(alertErrorList(title, messageList, timeout));
      },
      warning: (title = '', message = '', timeout = 2000) => {
        dispatch(alertWarning(title, message, timeout));
      },
      info: (title = '', message = '', timeout = 2000) => {
        dispatch(alertInfo(title, message, timeout));
      },
    };
    window.showConfirm = (sumbit) => {
      dispatch(showConfirm(sumbit));
    };
  }, []);
  const loadData = async () => {
    setIsLoading(true);
    return storage
      .loadData()
      .then(() => {
        if (!storage.isLoggedIn()) {
          setIsLoading(false);
          return true;
        }
        const initData = async () => {
          // await dispatch(fetchChanel());
          // await dispatch(fetchPersonalChanel()); //
          // await dispatch(fetchUsers()); //
          return true;
        };
        return initData().then(() => {
          setIsLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    initRef.current && loadData();
    initRef.current = false;

    serviceWorker.register();
    return (initRef.current = true);
  }, []);
  if (isLoading) {
    // return null;
    return <LoadingContainer loading />;
  }
  return (
    <>
      <Helmet>
        <title>CRM - U&I Logistics</title>
      </Helmet>
      <AlertMessage />
      <Router history={hist}>
        <Routers />
        <ConfirmMessage />
        {/* <SocketClientHandle /> */}
      </Router>
    </>
  );
};
export default App;
