import React, { useRef, useState, useEffect } from 'react';
import {
  Typography,
  Box,
  InputBase,
  Stack,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import { FileItem } from './file-item';
import { useSelector, useDispatch } from 'react-redux';
import Socket from 'libs/socket-handle';
import storage from 'storage';
import ChatServices from 'services/chat-services';
import { setSelectedChanel, updateChanel } from 'store/reducers/chat-chanel/action';
import data from 'emoji-mart/data/apple.json';
import { NimblePicker } from 'emoji-mart';
import Utils from 'commons/utils';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { MentionsInput, Mention } from 'react-mentions';
import MentionStyles from './mention-styles';
const IMAGE_FORMAT = 'image/png, image/gif, image/jpeg, image/jpg';
export const MessageInput = ({
  loading,
  text,
  setText,
  sendMessage,
  files,
  setFiles,
  images,
  setImages,
}) => {
  const dispatch = useDispatch();
  const delayTimer = useRef();
  const readMessage = useRef(false);
  const emojiRef = useRef(null);

  const { selectedChanel } = useSelector((state) => state.chatChanel);
  // const { messages } = useSelector((state) => state.chatMessage);
  const { typingUsers, users } = useSelector((state) => state.users);
  const [countLoadingFiles, setCountLoadingFiles] = useState(0);
  const disabledButtonSend =
    loading ||
    !selectedChanel?._id ||
    (!text.trim() && !images?.length && !files?.length) ||
    countLoadingFiles !== 0;

  const [showEmoji, setShowEmoji] = useState(false);
  useEffect(() => {
    Utils.onExecOutside(emojiRef, () => setShowEmoji(false));
  }, [emojiRef]);

  const colonsToUnicode = (text) => {
    return Utils.renderEmoji(text);
  };

  const onKeyPressInput = React.useCallback(
    (e) => {
      onClickChanel(selectedChanel);
      clearTimeout(delayTimer.current);
      delayTimer.current = setTimeout(function () {
        Socket.io?.emit('on_typing', {
          userCode: storage.user.Code,
          chanelId: selectedChanel?._id,
        });
      }, 500);
    },
    [selectedChanel],
  );

  const onClickChanel = async (chanel) => {
    if (readMessage.current) {
      return;
    }
    readMessage.current = true;
    if (!chanel?.messages?.length || !chanel?._id) {
      return (readMessage.current = false);
    }
    const userMember = chanel?.members.find((u) => u.code == storage.user.Code);

    if (userMember?.lastReadMessage && chanel?.messages[0].ts <= userMember.lastReadMessage) {
      return (readMessage.current = false);
    }
    return ChatServices.readMessage(chanel?.messages[0]._id)
      .then((response) => {
        if (response.data?.code == 'success') {
          const chanelData = response.data.results[0];
          chanelData.messages = chanel?.messages;
          dispatch(updateChanel(chanelData));
          dispatch(setSelectedChanel(chanelData));
        }
      })
      .catch((err) => {
        console.warn('api - readMessage, error: ', err);
      })
      .finally(() => (readMessage.current = false));
  };

  const onPasteHandle = (e) => {
    const filesData = e.clipboardData.files || [];
    if (filesData.length) {
      for (const el of filesData) {
        if (IMAGE_FORMAT.includes(el.type)) {
          setImages([...images, el]);
        } else {
          setFiles([...files, el]);
        }
      }
    }
  };

  const onFilesChange = (e) => {
    setFiles((prev) => [...prev, ...e.target.files]);
    setTimeout(() => (e.target.value = null), 2000);
  };

  const onImagesChange = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
    setTimeout(() => (e.target.value = null), 2000);
  };

  const onDeletedImage = (index) => {
    setImages((prev) => {
      let imgs = [];
      for (let indexImg in prev) {
        indexImg != index && imgs.push(prev[indexImg]);
      }
      return imgs;
    });
  };

  const onDeletedFile = (index) => {
    setFiles((prev) => {
      let imgs = [];
      for (let indexImg in prev) {
        indexImg != index && imgs.push(prev[indexImg]);
      }
      return imgs;
    });
  };

  const onUploadedFile = (index, data) => {
    setFiles((prev) => {
      let imgs = [];
      for (let indexImg in prev) {
        if (indexImg == index) {
          imgs.push(data);
          continue;
        }
        imgs.push(prev[indexImg]);
      }
      return imgs;
    });
  };

  const onUploadedImage = (index, data) => {
    setImages((prev) => {
      let imgs = [];
      for (let indexImg in prev) {
        if (indexImg == index) {
          imgs.push(data);
          continue;
        }
        imgs.push(prev[indexImg]);
      }
      return imgs;
    });
  };

  const renderUserTyping = () => {
    if (!typingUsers[selectedChanel?._id]) {
      return null;
    }
    let tUsers = [];
    for (let userCode of typingUsers[selectedChanel?._id]) {
      if (userCode != storage.user.Code) {
        const user = users.find((u) => u.code == userCode);
        user && tUsers.push(user.name || user.code);
      }
    }
    if (!tUsers?.length) {
      return null;
    }
    return (
      <TypingText>
        <b>{tUsers.join(', ')}</b> đang soạn tin nhắn ...
      </TypingText>
    );
  };
  let dropTimeout = null;

  return (
    <InputWrapper onClick={() => onKeyPressInput()}>
      {/* {isDrop && <DragOver>Gửi File</DragOver>} */}
      {renderUserTyping()}
      <FileWrapper>
        {images.map((image, index) => (
          <FileItem
            imaged
            index={index}
            file={image}
            key={image?.id || image?.name}
            onDeleted={onDeletedImage}
            onUploaded={onUploadedImage}
            setCountLoadingFiles={setCountLoadingFiles}
          />
        ))}
        {files.map((file, index) => (
          <FileItem
            index={index}
            count={files.length}
            file={file}
            key={file?.id || file?.name}
            onDeleted={onDeletedFile}
            onUploaded={onUploadedFile}
            setCountLoadingFiles={setCountLoadingFiles}
          />
        ))}
      </FileWrapper>
      <InputContainer>
        <InputMention
          placeholder="Gửi tin nhắn"
          // multiline
          // maxRows={4}
          allowSuggestionsAboveCursor
          onPaste={onPasteHandle}
          value={text}
          onKeyPress={onKeyPressInput}
          onKeyDown={(e) => {
            if ((e.keyCode === 13) & !e.shiftKey) {
              e.preventDefault();
              if (disabledButtonSend) {
                return;
              }
              sendMessage();
            }
          }}
          style={MentionStyles}
          onChange={(e) => setText(colonsToUnicode(e.target.value))}
        >
          <Mention
            markup="@<__id__>[__display__]"
            appendSpaceOnAdd
            data={users.map((u) => ({
              id: u.code,
              display: u.name || u.code,
            }))}
            trigger="@"
            style={{
              backgroundColor: '#cee4e5',
            }}
            displayTransform={(id, display) => `@${display}`}
          />
        </InputMention>
        <ButtonWrapper>
          {showEmoji && (
            <EmojiPicker ref={emojiRef}>
              <NimblePicker
                set="apple"
                data={data}
                emojiTooltip={false}
                showPreview={false}
                showSkinTones={false}
                onSelect={(emoji) => setText((prev) => prev + emoji.native)}
                native={false}
                theme="auto"
              />
            </EmojiPicker>
          )}
          <SendButton>
            <InsertEmojiBtn onClick={() => setShowEmoji((prev) => !prev)} />
          </SendButton>
          <SendButton>
            <Label htmlFor="messageUploadImage">
              <AttachImage />
            </Label>
            <input
              type="file"
              id="messageUploadImage"
              style={{ display: 'none' }}
              accept="image/*, video/*"
              onChange={onImagesChange}
              multiple
            />
          </SendButton>

          <SendButton>
            <Label htmlFor="messageUploadFile">
              <AttachFile />
            </Label>
            <input
              type="file"
              id="messageUploadFile"
              style={{ display: 'none' }}
              accept="*"
              multiple
              onChange={onFilesChange}
            />
          </SendButton>
          <SendButton disabled={!!disabledButtonSend} onClick={sendMessage}>
            {/* <SendIcon /> */}
            Gửi
          </SendButton>
        </ButtonWrapper>
      </InputContainer>
    </InputWrapper>
  );
};
const MentionItem = styled(Box)(({ theme, focused }) => ({}));
const DragOver = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 1,
  backgroundColor: '#ffffff',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.8,
}));
const TypingText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -16,
  left: 8,
  fontSize: 10,
  zIndex: 2,
  // background: theme.palette.background.default,
  backgroundColor: '#ffffff',
  paddingInline: 8,
  borderRadius: 4,
  opacity: 0.8,
}));
const FileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingInline: '10px',
}));
const Label = styled('label')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
const InsertEmojiBtn = styled(InsertEmoticonIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.text.secondary,
}));
const AttachImage = styled(ImageIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.text.secondary,
}));
const AttachFile = styled(AttachFileIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.text.secondary,
}));
const InputWrapper = styled('div')(({ theme }) => ({
  // border: `${theme.border.borderColor} solid 1px`,
  background: 'rgba(239, 246, 252, 1)',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  position: 'relative',
  // overflow: 'hidden',
}));
const InputContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  position: 'relative',
  width: '100% !important',
  '& .MuiInputBase-multiline': {
    width: '100% !important',
  },
  maxHeight: 120,
  display: 'flex',
}));
const InputMention = styled(MentionsInput)(({ theme }) => ({
  // color: 'inherit',
  // flex: 1,
  // display: 'flex',
  // maxHeight: 100,
  // alignItems: 'center',
  // overflowY: 'auto',
  // textarea: {
  //   padding: '10px',
  //   padding: theme.spacing(1, 1, 1, 1),
  //   border: 0,
  //   maxHeight: 100,
  //   fontSize: '15px',
  //   lineHeight: '15px',
  //   overflowY: 'auto !important',
  //   '&:focus-visible': {
  //     outline: 'none',
  //   },
  // },
}));
const InputMessage = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flex: 1,
  paddingInline: '10px',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    minWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));
const ButtonWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginRight: '10px',
}));

const SendButton = styled(Button)(({ theme }) => ({
  padding: '5px 5px',
  minWidth: '30px',
  textTransform: 'capitalize',
  fontWeight: 'bolder',
}));
const EmojiPicker = styled(Box)(() => ({
  position: 'absolute',
  bottom: '48px',
  right: '96px',
  float: 'right',
  width: '355px',
  marginLeft: 200,
  zIndex: 3,
}));
