import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckboxGroup } from 'components';
import ApiServices from 'services';

export default function ServicesModal({ open = false, setOpen, onSubmitData, idAccept }) {
  const [services, setServices] = React.useState([]);
  const [disabled, setDisable] = React.useState(true);
  const handleClose = () => {
    setOpen({});
  };
  React.useEffect(() => {
    console.log('Hello');
    setServices([]);
  }, [idAccept]);

  const handleSubmit = React.useCallback(async () => {
    if (onSubmitData) {
      onSubmitData(open, services);
    }
    handleClose();
  }, [services.length, open]);

  const isOpen = Boolean(open.id);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Chọn Dịch vụ của Khách hàng</DialogTitle>
        <DialogContent>
          <CheckboxGroup
            label="DS Dịch vụ"
            syncData={ApiServices.getServices}
            value={services}
            onChange={(res) => {
              setDisable(res.length === 0);
              setServices(res);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} autoFocus disabled={disabled}>
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
