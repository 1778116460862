import React from 'react';
import { Box, Typography, Tab, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { BsPlusCircle } from 'react-icons/bs';
import { styled } from '@mui/material/styles';
import { RequestCheckPrice } from './request-check-price';
import { Quotes } from './quotes';

export const CustomerQuotes = () => {
  const [tabIndex, setTabIndex] = React.useState('1');
  const [addCheckPrice, setAddCheckPrice] = React.useState(false);
  const [addQuotes, setAddQuotes] = React.useState(false);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const closeAddNew = () => {
    if (tabIndex === '1') {
      setAddQuotes(false);
    }
    if (tabIndex === '2') {
      setAddCheckPrice(false);
    }
  };

  const onHandleAddNew = () => {
    if (tabIndex === '1') {
      setAddQuotes(true);
    }
    if (tabIndex === '2') {
      setAddCheckPrice(true);
    }
  };
  React.useEffect(() => {
    return () => {
      setAddCheckPrice(false);
      setAddQuotes(false);
    };
  }, []);
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" component="h6">
        BÁO GIÁ KHÁCH HÀNG
      </Typography>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
            <TabItem label="Báo giá" value="1" />
            <TabItem label="Yêu cầu kiểm tra giá" value="2" />
            <Box sx={{ alignItems: 'center', marginLeft: 'auto' }}>
              <BtnCreateQuote
                variant="contained"
                startIcon={<BsPlusCircle />}
                onClick={onHandleAddNew}
              >
                {tabIndex === '2' ? 'YC kiểm tra giá' : 'Tạo báo giá'}
              </BtnCreateQuote>
            </Box>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: '5px 0px' }}>
          <Quotes openAddQuotes={addQuotes} closeAddQuotes={closeAddNew} />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '5px 0px' }}>
          <RequestCheckPrice openAddCheckPrice={addCheckPrice} closeAddCheckPrice={closeAddNew} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
const BtnCreateQuote = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '6px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
