import actionTypes from './type';

export const openSidebar = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN });
  };
};
export const closeSidebar = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE });
  };
};
