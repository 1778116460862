import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(({ item, index, setValues, leaseSpaceList, disabled }, ref) => {
  const [makeup, setMakeup] = React.useState(0);
  const [buyPrice, setBuyPrice] = React.useState(0);

  React.useImperativeHandle(ref, () => ({
    getAlert() {
      alert('getAlert from Child');
    },
  }));
  React.useEffect(() => {
    setMakeup(item?.makeup);
    setBuyPrice(item?.buyPrice);
  }, [item]);

  const handleMakeUp = React.useCallback(
    (e, price) => {
      const value = +e.target.value;
      setMakeup(value);
      setBuyPrice(price + (price * value) / 100);
      setValues((prev) => {
        prev[index].makeup = value;
        prev[index].buyPrice = price + (price * value) / 100;
        return [...prev];
      });
    },
    [makeup],
  );
  const handleBuyPrice = React.useCallback(
    (e, price) => {
      const value = +e.target.value;
      setBuyPrice(value);
      setMakeup(((value - price) / price) * 100);
      setValues((prev) => {
        prev[index].makeup = ((value - price) / price) * 100;
        prev[index].buyPrice = value;
        return [...prev];
      });
    },
    [buyPrice],
  );

  const renderSubChargeLabel = React.useCallback(
    (leasingPriceId) => {
      const leaseSpace = leaseSpaceList?.find((item) => item?.id === leasingPriceId);
      return leaseSpace?.name;
    },
    [item?.leasingPriceId, leaseSpaceList],
  );

  return (
    <Container>
      <TextLeasePrice
        label="Leas space"
        variant="outlined"
        type="text"
        size="small"
        multiline
        value={renderSubChargeLabel(item?.leasingPriceId)}
      />
      <TextFieldPrice
        label="Đơn giá"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
          disabled: true,
        }}
        value={item?.price}
      />
      <TextFieldMakeUp
        label="%Make Up"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        value={makeup}
        onChange={(event) => handleMakeUp(event, item?.price)}
        disabled={disabled}
      />
      <TextFieldBuyPrice
        label="Giá bán"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        value={buyPrice}
        onChange={(event) => handleBuyPrice(event, item?.price)}
        disabled={disabled}
      />
      <TextFieldUnit
        label="Đơn vị"
        variant="outlined"
        type="text"
        size="small"
        value={item?.unitCode}
      />
      <StyledTextField
        label="VAT"
        variant="outlined"
        type="number"
        size="small"
        value={item?.vat}
      />
    </Container>
  );
});
export default InputItem;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const TextLeasePrice = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '300px',
}));
const TextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: '#FCF2EF',
  marginRight: '10px',
}));
const TextFieldBuyPrice = styled(TextField)(({ theme }) => ({
  width: '150px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  marginRight: '10px',
}));
const TextFieldUnit = styled(TextField)(({ theme }) => ({
  width: '200px',
  marginRight: '10px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
