export default function () {
  return {
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    logotype: {
      display: 'flex',
      alignItems: 'center',
    },
    logotypeText: {
      fontWeight: 500,
      color: 'white',
    },
    logotypeIcon: {
      width: 70,
    },
    paperRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 404,
      padding: '1.5rem',
    },
    textRow: {
      textAlign: 'center',
    },
    errorCode: {
      fontSize: 148,
      fontWeight: 600,
    },
    safetyText: {
      fontWeight: 300,
    },
    backButton: {
      textTransform: 'none',
      fontSize: 22,
    },
  };
}
