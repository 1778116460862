import * as React from 'react';
import { TextField, InputAdornment, Chip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
export const ArrayInput = ({
  value = [],
  onChange,
  error,
  helperText,
  label = '',
  type = 'text',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  name,
  variant = 'standard',
  inputNumberStr = false,
  ...props
}) => {
  const [text, setText] = React.useState('');
  const formatValue = (data) => {
    return {
      persist: () => {},
      target: {
        type: 'change',
        name: name,
        value: data,
      },
    };
  };
  const handleDelete = (currentIndex) => {
    let tmpData = value;
    tmpData = tmpData.filter(function (value, index) {
      return index !== currentIndex;
    });

    onChange && onChange(formatValue(tmpData));
  };
  const addText = (e) => {
    if (text) {
      onChange && onChange(formatValue(value?.length ? [...value, text] : [text]));
      setText('');
    }
    e && e.preventDefault();
  };

  const handleChangeText = (event) => {
    const value = event.target.value;
    if (inputNumberStr && value && isNaN(value)) {
      return;
    }
    setText(value);
  };

  return (
    <TextField
      id="input-with-icon-textfield"
      sx={{ overflow: 'auto' }}
      placeholder={label}
      value={text}
      label={label}
      onChange={handleChangeText}
      onKeyDown={(e) => {
        e.keyCode === 13 && addText(e);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ pb: 1, display: 'flex' }}>
            {Array.isArray(value) &&
              value.map((text, index) => (
                <Chip
                  sx={{ mr: 1 }}
                  key={`array-input-${index}`}
                  label={text}
                  variant="outlined"
                  onClick={() => console.log('onClick')}
                  onDelete={() => handleDelete(index)}
                />
              ))}
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton aria-label="toggle password visibility" onClick={addText}>
            <AddIcon />
          </IconButton>
        ),
      }}
      {...{
        fullWidth,
        type,
        size,
        variant,
        margin,
        error,
        helperText,
      }}
    />
  );
};
