import React from 'react';
import { Button } from '@mui/material';
import Utils from 'commons/utils';
import { QUOTE_STATUS, QUOTE_STATE } from 'commons/constants';
import { RenderQuoteStatus } from 'components/custom-status';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã báo giá',
    align: 'center',
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: false,
    label: 'Người tạo',
    align: 'center',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    label: 'Khách hàng',
    align: 'center',
    width: '200px',
  },
  {
    id: 'arrService',
    numeric: true,
    disablePadding: false,
    label: 'Dịch vụ',
    align: 'center',
    render: (row) => row?.arrService?.map((item) => item?.name).join(', '),
  },
  {
    id: 'quotesStatus',
    numeric: true,
    disablePadding: false,
    label: 'Trạng thái',
    align: 'center',
    render: (row) => <RenderQuoteStatus status={row?.quotesStatus} />,
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Tình trạng',
    align: 'center',
    render: (row) =>
      row?.status === 1 && row?.timesNegotiations > 1
        ? `${QUOTE_STATE[row?.status]} lần ${row?.timesNegotiations}`
        : QUOTE_STATE[row?.status],
  },
  {
    id: 'quotedate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày báo giá',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.quotedate),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) =>
      (row?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE ||
        row?.quotesStatus === QUOTE_STATUS.REJECT_APPROVE ||
        row?.quotesStatus === QUOTE_STATUS.REJECT_QUOTE) && (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteContTypeItem(row))}
        >
          Xoá
        </Button>
      ),
  },
];
