import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TiDelete } from 'react-icons/ti';
import { style } from '@mui/system';

const MIN_WIDTH_SEARCH = '100px';
const DEFAULT_WIDTH_SEARCH = '100%';

export const SearchInputColumn = ({
  onChange,
  onBlur,
  sx,
  maxWidth,
  placeholder = 'Search…',
  title = '',
  name,
}) => {
  const [value, setValue] = React.useState('');
  const handleChange = (e) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };
  const onClearData = () => {
    setValue('');
    onChange && onChange({ target: { value: '', name } });
  };
  return (
    <Body>
      <div>{title}</div>
      <Container>
        <Search sx={sx} width={maxWidth}>
          <StyledInputBase
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            inputProps={{ 'aria-label': 'search' }}
            width={maxWidth}
          />
          {value && (
            <EndIconWrapper onClick={onClearData}>
              <TiDelete fontSize="20px" />
            </EndIconWrapper>
          )}
        </Search>
      </Container>
    </Body>
  );
};

const Body = styled('span')(({ theme }) => ({
  textAlign: 'center',
}));

const Title = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const Container = styled('div')(({ theme }) => ({
  fontSize: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

const Search = styled('div')(({ theme, width }) => ({
  position: 'relative',
  borderRadius: '12px',
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  maxWidth: width || DEFAULT_WIDTH_SEARCH,
  minWidth: MIN_WIDTH_SEARCH,
  height: '28px',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const EndIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '28px',
  right: 0,
  top: 0,
  cursor: 'pointer',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme, width }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    padding: '5px 10px 5px 8px',
    fontSize: '14px',
    justifyContent: 'center',
    transition: theme.transitions.create('width'),
    maxWidth: width || DEFAULT_WIDTH_SEARCH,
    minWidth: MIN_WIDTH_SEARCH,
    [theme.breakpoints.up('md')]: {
      // width: '20ch',
    },
  },
}));
