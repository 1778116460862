import * as React from 'react';

import { Card, Box, CardContent, TextField, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RadioButtonGroup } from 'components';
import apiServices from 'services';

export default function ({ selected, setSelected, reload }) {
  const [service, setService] = React.useState('');
  const [values, setValues] = React.useState([]);
  const [clientRating, setClientRating] = React.useState('');

  React.useEffect(() => {
    apiServices
      .getClientRating()
      .then((res) => {
        setClientRating(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  React.useEffect(() => {
    setValues([]);
  }, [service]);
  const filterService = React.useMemo(() => {
    return service ? clientRating.filter((item) => item.serviceId == service) : [];
  }, [service]);
  const handleChange = (e, index) => {
    setValues((prev) => {
      prev[index] = e.target.value;
      return prev;
    });
  };
  const handleSubmit = React.useCallback(
    async (updateData) => {
      let data = [];
      filterService.map((item, index) => {
        data.push({
          id: updateData.length ? updateData[index].id : '',
          name: item.name,
          note: item.note,
          option: item.option,
          serviceId: service,
          clientCareHistoryId: selected.arrClientCareHistory?.find((el) => el.serviceId == service)
            ?.id,
          clientCompanyId: selected.id,
          arrEvaluationCriteria: item.arrEvaluationCriteria.map((el, ind) => {
            const pos =
              index * (index !== 0 ? filterService[index - 1].arrEvaluationCriteria?.length : 0) +
              ind;
            return {
              unit: el.unit,
              name: el.name,
              query: el.query,
              status: el.status,
              value:
                values[pos] ||
                (updateData.length ? updateData[index]?.arrEvaluationCriteria[ind]?.value : 0),
            };
          }),
        });
      });

      if (!updateData.length) {
        return apiServices
          .createClientEvaluationCriteria(data)
          .then((res) => {
            window.UIMessage?.success('Thao tác thành công');
            setSelected(null);
            reload();
          })
          .catch((err) => {
            window.UIMessage?.error('Thao tác thất bại');
          });
      }
      return apiServices
        .updateClientEvaluationCriteria(data)
        .then((res) => {
          window.UIMessage?.success('Thao tác thành công');
          setSelected(null);
          reload();
        })
        .catch((err) => {
          window.UIMessage?.error('Thao tác thất bại');
        });
    },
    [values],
  );
  const dataUpdated = selected?.arrClientEvaluationCriteria?.filter(
    (el) => el.serviceId == service,
  );
  const renderLabel = (query, value) => {
    for (let el of query) {
      const { range, result } = el;
      if (value >= range.min && value <= range.max) {
        return <Chip label={result}></Chip>;
      }
    }
    return null;
  };

  return (
    <Box>
      <RadioButtonGroup
        label="Chọn dịch vụ"
        syncData={apiServices.getServices}
        value={service}
        onChange={(e) => setService(e.target.value)}
        renderDisabled={(val) =>
          selected?.arrClientCareHistory?.find((el) => el.serviceId == val) ? false : true
        }
      />
      {filterService.map((el, index) => {
        console.log({ el });
        return (
          <Box key={`filterService-${el.id}`}>
            <MainTitle>
              {el.name}:{' '}
              <b style={{ color: 'red' }}>
                {dataUpdated && dataUpdated[index]?.totalReturn?.totalResult}
              </b>
            </MainTitle>
            <Card sx={{ m: '10px 0' }}>
              <CardContent>
                <RowWrapper>
                  <Title sx={{ width: '190px' }}>Tiêu chí Đánh giá</Title>
                  <Title sx={{ width: '100px' }}>Đơn vị</Title>
                </RowWrapper>
                {el.arrEvaluationCriteria.map((item, ind) => {
                  const pos =
                    index * index !== 0
                      ? filterService[index - 1].arrEvaluationCriteria?.length
                      : 0 + ind;
                  return (
                    <RowWrapper key={`arrEvaluationCriteria-${item.name}-${ind}`}>
                      <span>{ind + 1}.</span>
                      <Name sx={{ width: '190px' }}>{item.name}</Name>
                      <TextInput
                        placeholder="Nhập"
                        size="small"
                        defaultValue={
                          dataUpdated?.length
                            ? dataUpdated[index]?.arrEvaluationCriteria[ind].value
                            : ''
                        }
                        type="number"
                        onChange={(e) => handleChange(e, pos)}
                      />
                      <Name sx={{ width: '100px' }}>{item.unit}</Name>
                    </RowWrapper>
                  );
                })}
              </CardContent>
            </Card>
          </Box>
        );
      })}
      {service && (
        <Box sx={{ py: 1 }}>
          <Button
            type="Button"
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => handleSubmit(dataUpdated)}
          >
            Gửi
          </Button>
        </Box>
      )}
    </Box>
  );
}
const TextInput = styled(TextField)(({ theme }) => ({
  width: '100px',
}));
const MainTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 400,
}));
const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '4px 0',
}));
const Title = styled(Box)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '16px',
}));
const Name = styled(Box)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '14px',
}));
