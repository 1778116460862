import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button } from '@mui/material';

export const Columns = (handleSearchColumn, deleteItem) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã đối tác" name="code" onChange={handleSearchColumn} />,
    width: '100px',
    align: 'center',
  },
  {
    id: 'name',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: <SearchInputColumn title="Tên đối tác" name="name" onChange={handleSearchColumn} />,
  },
  {
    id: 'arrService',
    numeric: true,
    disablePadding: false,
    with: '300px',
    label: <SearchInputColumn title="Dịch vụ" name="strService" onChange={handleSearchColumn} />,
    align: 'left',
    render: (row) => {
      const arrServiceName = row?.arrService?.map((item) => item.name);

      return Utils.showArrayInTale(arrServiceName || []);
    },
  },
  {
    id: 'arrNumberPhone',
    numeric: true,
    disablePadding: false,
    with: '100px',
    label: <SearchInputColumn title="SĐT" name="strNumberPhone" onChange={handleSearchColumn} />,
    align: 'left',
    render: (row) => Utils.showArrayInTale(row?.arrNumberPhone || []),
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    with: '100px',
    label: <SearchInputColumn title="Email" name="email" onChange={handleSearchColumn} />,
    align: 'center',
  },
  {
    id: 'arrAddress',
    numeric: true,
    disablePadding: false,
    width: '300px',
    label: <SearchInputColumn title="Địa chỉ" name="strAddress" onChange={handleSearchColumn} />,
    align: 'left',
    render: (row) => Utils.showArrayInTale(row?.arrAddress || []),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    width: '10px',
    fixed: 'right',
    label: 'Thao tác',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
