export const register = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('service-worker.js')
      .then(function () {
        return navigator.serviceWorker.ready;
      })
      .then(function (reg) {
        console.log('Service Worker is ready', reg);
        console.log({ reg });
        window.pushMassage = reg;
        reg.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              'BKMinKB93CQ54PiYmXMfLh1Q-dCWNgyD6tx1fTlq1Lx85WlQkgV8Al-d7WTz2-CARsGODVVR4fFrCRBcHXvbp0M',
          })
          .then(function (sub) {
            // reg.active.postMessage(
            //   JSON.stringify({ uid: 'test uid', token: 'test ABC', content: 'Hello Noti' }),
            // );
          });
      })
      .catch(function (error) {
        console.log('Error : ', error);
      });
  }
};

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
};
