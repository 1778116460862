import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button } from '@mui/material';
import { CLIENT_COMPLAIN_STATUS } from 'commons/constants';
import { RenderComplaintStatus } from 'components/custom-status';

export const Columns = (handleSearchColumn, deleteItem) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã khiếu nại" name="code" onChange={handleSearchColumn} />,
    width: '100px',
    align: 'center',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Trạng thái"
        // onChange={({ target }) => onSearchInputChange(target.value, 'status', true)}
      />
    ),
    align: 'center',
    width: '100px',
    render: (row) => <RenderComplaintStatus status={row?.status} />,
  },
  {
    id: 'guestComplaints',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Tên khách khiếu nại"
        name="guestComplaints"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Công ty" name="clientCompanyName" onChange={handleSearchColumn} />
    ),
    align: 'left',
  },
  {
    id: 'complaintDate',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Ngày khiếu nại"
        name="complaintDate"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
    render: (row) => Utils.formatDateFn(row.complaintDate),
  },
  {
    id: 'causeofComplaint',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Nội dung khiếu nại"
        name="causeofComplaint"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
  },
  {
    id: 'solution',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Nội dung xử lí" name="solution" onChange={handleSearchColumn} />
    ),
    align: 'left',
  },
  {
    id: 'departmentName',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Phòng ban bị phản ánh"
        name="departmentName"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
  },
  {
    id: 'createdBy',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Người tạo',
    align: 'left',
  },
  {
    id: 'createdOn',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'left',
    render: (row) => Utils.formatDateFn(row.createdOn),
  },
  {
    id: 'description',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Ghi chú',
    align: 'left',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    width: '10px',
    fixed: 'right',
    label: 'Thao tác',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        disabled={row?.status !== CLIENT_COMPLAIN_STATUS.ORIGIN}
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
