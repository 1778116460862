import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  flex: 1,
}));

export const ContainerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  py: 1,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const ContainerForm = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  height: 'calc(100vh - 175px)',
}));

export const ButtonAdd = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '1px 20px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));

export const ButtonExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '8px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));

export const ButtonImport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));
