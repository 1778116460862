import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const [serviceList, setServiceList] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [subServiceList, setSubServiceList] = useState([]);
  const [subServiceId, setSubServiceId] = useState('');

  useEffect(() => {
    setServiceId(selected?.serviceId || '');
    setSubServiceId(selected?.subServiceId || '');
  }, [selected]);

  const getServices = async () => {
    try {
      const { data } = await ApiServices.getServices();
      if (data) {
        setServiceList(data);
      }
    } catch (error) {
      console.error('getServices-ERR', error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const getSubServiceByServiceId = async (serviceId) => {
    try {
      const { data } = await ApiServices.getSubServiceByServiceId(serviceId);
      if (data) {
        setSubServiceList(data);
      }
    } catch (error) {
      console.warn('getSubServiceByServiceId:', error);
    }
  };

  useEffect(() => {
    if (serviceId) {
      getSubServiceByServiceId(serviceId);
    }
  }, [serviceId]);

  const createFn = async (values, { setSubmitting }) => {
    values.serviceId = serviceId;
    values.subServiceId = subServiceId;
    delete values.isNew;
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createSubFeeGroup(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    values.serviceId = serviceId;
    values.subServiceId = subServiceId;
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateSubFeeGroup(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const handleChangeService = (e) => {
    setServiceId(e.target.value);
    setSubServiceId('');
  };
  const handleChangeSubService = (e) => {
    setSubServiceId(e.target.value);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Nhóm phụ phí: ${selected?.name}`}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            nameEN: '',
            nameCN: '',
            nameJP: '',
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().max(255).required('Vui lòng nhập mã nhóm phụ phí'),
            name: Yup.string().max(255).required('Vui lòng nhập tên nhóm phụ phí'),
            nameEN: Yup.string().max(255).required('Vui lòng nhập tên nhóm phụ phí tiếng Anh'),
            nameCN: Yup.string().max(255).required('Vui lòng nhập tên nhóm phụ phí tiếng Hoa'),
            nameJP: Yup.string().max(255).required('Vui lòng nhập tên nhóm phụ phí tiếng Nhật'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã nhóm phụ phí"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Tên nhóm phụ phí"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                required
                error={Boolean(touched.nameEN && errors.nameEN)}
                helperText={touched.nameEN && errors.nameEN}
                label="Tên nhóm phụ phí tiếng Anh"
                name="nameEN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameEN}
              />
              <TextInput
                required
                error={Boolean(touched.nameCN && errors.nameCN)}
                helperText={touched.nameCN && errors.nameCN}
                label="Tên nhóm phụ phí tiếng Hoa"
                name="nameCN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameCN}
              />
              <TextInput
                required
                error={Boolean(touched.nameJP && errors.nameJP)}
                helperText={touched.nameJP && errors.nameJP}
                label="Tên nhóm phụ phí tiếng Nhật"
                name="nameJP"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameJP}
              />
              <Selection
                error={!serviceId}
                helperText={!serviceId && 'Vui lòng chọn dịch vụ'}
                label="Dịch vụ"
                name="serviceId"
                onChange={handleChangeService}
                value={serviceId}
                fieldValue="id"
                fieldView="name"
                dataArrObject={serviceList}
              />
              {serviceId && (
                <Selection
                  error={!subServiceId}
                  helperText={!subServiceId && 'Vui lòng chọn dịch vụ phụ'}
                  label="Dịch vụ phụ"
                  name="subServiceId"
                  onChange={handleChangeSubService}
                  value={subServiceId}
                  fieldValue="id"
                  fieldView="name"
                  dataArrObject={subServiceList}
                />
              )}
              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Ghi chú"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting || !serviceId || !subServiceId}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
