import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TiDelete } from 'react-icons/ti';
// const Search = styled('div')(({ theme, ...props }) => ({
//   position: 'relative',
//   borderRadius: '20px',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.primary.main, 0.1), //theme.palette.common.white || theme.palette.primary.main
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.primary.main, 0.2),
//   },
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     // marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
//   ...props?.sx,
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       width: '18ch',
//       '&:focus': {
//         width: '25ch',
//       },
//     },
//   },
// }));

const MIN_WIDTH_SEARCH = '200px';
const DEFAULT_WIDTH_SEARCH = '400px';

const Search = styled('div')(({ theme, width }) => ({
  position: 'relative',
  borderRadius: '20px',
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  maxWidth: width || DEFAULT_WIDTH_SEARCH,
  minWidth: MIN_WIDTH_SEARCH,
  height: '34px',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '34px',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const EndIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '34px',
  right: 0,
  top: 0,
  cursor: 'pointer',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme, width }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    maxWidth: width || DEFAULT_WIDTH_SEARCH,
    minWidth: MIN_WIDTH_SEARCH,
    [theme.breakpoints.up('md')]: {
      width: '32ch',
    },
  },
}));

export const SearchInput = (props) => {
  const [value, setValue] = React.useState('');
  const onChange = (e) => {
    setValue(e.target.value);
    props?.onChange && props?.onChange(e);
  };
  const onClearData = () => {
    setValue('');
    props?.onChange && props?.onChange({ target: { value: '' } });
  };
  return (
    <Search sx={props?.sx} width={props.maxWidth}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Tìm kiếm..."
        value={value}
        onChange={onChange}
        onBlur={props?.onBlur}
        inputProps={{ 'aria-label': 'search' }}
        width={props.maxWidth}
      />
      {value && (
        <EndIconWrapper onClick={onClearData}>
          <TiDelete fontSize="24px" />
        </EndIconWrapper>
      )}
    </Search>
  );
};
