import * as React from 'react';
import { Box, Button, Avatar, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';
export const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange, ...props }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const sum = Math.ceil(count / rowsPerPage);

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, sum - 1));
  };

  let pageArea = [];

  const startPage = page - 2 < 0 ? 0 : page - 2;
  const endPage = page + 3 >= sum ? sum : page + 3;
  for (let i = startPage; i < endPage; i++) {
    if (i !== page) {
      pageArea.push(
        <ContainerPage key={`page-pagination-${i}`} onClick={(event) => onPageChange(event, i)}>
          {i + 1}
        </ContainerPage>,
      );
      continue;
    }
    pageArea.push(
      <ContainerCurrentPage variant="rounded" key={`page-pagination-${i}`}>
        <b style={{ fontSize: '12px' }}>{i + 1}</b>
      </ContainerCurrentPage>,
    );
  }
  if (endPage < sum) {
    pageArea.push(<ContainerEndPage key={`page-pagination-more`}>...</ContainerEndPage>);
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ mr: 0, ml: 0 }}
    >
      <ButtonBackAndNext variant="outlined" disabled={page === 0} onClick={handleBackButtonClick}>
        <BsFillCaretLeftFill sx={{ fontSize: '14px' }} />
      </ButtonBackAndNext>
      {pageArea}
      <ButtonBackAndNext
        variant="outlined"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <BsFillCaretRightFill sx={{ fontSize: '14px' }} />
      </ButtonBackAndNext>
    </Stack>
  );
};

export const ContainerPage = styled(Box)(({ theme }) => ({
  padding: '10px',
  paddingBlock: '5px',
  borderRadius: 'shape.borderRadius',

  '&:hover': {
    backgroundColor: '#9BA3EB',
    cursor: 'pointer',
    borderRadius: '5px',
  },
}));

export const ContainerCurrentPage = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#21409A',
  width: 25,
  height: 25,
  marginInline: '5px',
}));

export const ContainerEndPage = styled(Box)(({ theme }) => ({
  padding: '10px',
  paddingBlock: '5px',
  borderRadius: 'shape.borderRadius',
}));

export const ButtonBackAndNext = styled(Button)(({ theme }) => ({
  bgcolor: '#DFF2FF',
  padding: 0,
  minWidth: '25px',
  height: 25,
  marginInline: '5px',
}));
