import React, { useEffect, useState, useCallback } from 'react';
import { Table, SearchInput, ModalFormDetail } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
// import { Add as AddIcon } from '@mui/icons-material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';
import { RESPONSE_STATUSES } from 'commons/constants';
import { debounce } from 'lodash';
import Utils from 'commons/utils';

import { EmailFormNew } from './email-form-new';

function User() {
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchUser, setSearchUser] = useState('');

  // email
  const [dataSelectEmail, setDataSelectEmail] = useState(null);

  const initData = async () => {
    setLoading(true);
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        const mapData = res?.data?.map((item) => {
          item.textSearch = item?.code + item?.name;
          return item;
        });
        setData(mapData || []);
        setOriginData(mapData || []);
      }
    } catch (err) {
      console.warn('initData - companies', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query) {
        if (!Utils.convertValSearch(item?.textSearch)?.includes(Utils.convertValSearch(query))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(searchUser, originData);
    }
  }, [searchUser]);

  const handleChangeTextSearch = (event) => {
    const value = event.target.value;
    setSearchUser(value || '');
  };

  const deleteCell = async (row) => {
    if (!row?.id) return;

    setLoading(true);
    try {
      const res = await ApiServices.deleteUser(row.id);
      if (res.status === RESPONSE_STATUSES.OK) {
        window.UIMessage?.success('Xoá thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xoá thất bại');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };

  const handleRestPassword = async (rowData) => {
    if (!rowData?.code) return;

    setLoading(true);
    try {
      const res = await ApiServices.resetPassword(rowData.code);
      if (res.status === RESPONSE_STATUSES.OK) {
        window.UIMessage?.success('Reset mật khẩu thành công');
      }
    } catch (err) {
      window.UIMessage?.error('Reset mật khẩu thất bại');
      console.warn('reset Password - companies', err);
    }
    setLoading(false);
  };

  const handleSelectToShowEmail = (rowSelect) => {
    setDataSelectEmail(rowSelect);
  };
  const handleClose = () => {
    setDataSelectEmail(null);
  };
  const formatDisplayImage = (urlImage, title) => {
    return (
      <>
        <img
          src={`${process.env.REACT_APP_API_URL}/${urlImage}`}
          alt={title}
          loading="lazy"
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            display: 'block',
            width: '80px',
            height: '80px',
            borderRadius: '10px',
          }}
        />
      </>
    );
  };

  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: true,
      label: 'Mã',
      width: 10,
      sort: true,
      align: 'left',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên người dùng',
      align: 'left',
    },
    {
      id: 'urlAvatar',
      numeric: true,
      disablePadding: false,
      label: 'Ảnh đại diện',
      align: 'left',
      width: '100px',
      render: (row) => formatDisplayImage(row?.urlAvatar, row?.code),
    },
    {
      id: 'departmentName',
      numeric: true,
      disablePadding: false,
      label: 'Phòng ban',
      align: 'left',
    },
    {
      id: 'titleName',
      numeric: true,
      disablePadding: false,
      label: 'Chức danh',
      align: 'left',
    },
    {
      id: 'userMail',
      numeric: true,
      disablePadding: false,
      label: 'E-mail',
      align: 'left',
      render: (row) => <span>{row?.userMail?.user}</span>,
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'left',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '#',
      align: 'center',
      width: '50px',
      fixed: 'right',
      render: (row) => (
        <>
          <Button
            variant="contained"
            size="small"
            sx={{ borderRadius: '15px', textTransform: 'none' }}
            onClick={() => handleRestPassword(row)}
          >
            Reset MK
          </Button>
        </>
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Thao tác',
      align: 'center',
      width: '100px',
      fixed: 'right',
      render: (row) => (
        <>
          <Button
            variant="contained"
            size="small"
            color="error"
            sx={{ borderRadius: '15px', textTransform: 'none' }}
            onClick={() => window.showConfirm(() => deleteCell(row))}
          >
            Xoá
          </Button>
          <br />
          <Button
            variant="contained"
            size="small"
            color="info"
            sx={{ borderRadius: '15px', textTransform: 'none', marginTop: '10px' }}
            onClick={() => handleSelectToShowEmail(row)}
          >
            Email
          </Button>
        </>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data}
        headCells={headCells}
        onClickDetail={(row) => {
          if (row.userMail) {
            if (Object.values(row?.userMail).length > 0) {
              row.email = row.userMail.user;
            } else {
              row.email = '';
            }
          }
          setSelected(row);
        }}
        title="DANH SÁCH NGƯỜI DÙNG"
        loading={loading}
        renderHeader={() => {
          return (
            <>
              <SearchInput sx={{ mr: 2 }} value={searchUser} onChange={handleChangeTextSearch} />
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  mr: 2,
                }}
              >
                {/* <Stack direction="row" spacing={1}> */}
                <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                  <BsFilter />
                </IconButton>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowDownCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px',
                    marginLeft: '8px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '0px 8px',
                  }}
                >
                  Nhập excel
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowUpCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px',
                    marginLeft: '8px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '0px 8px',
                  }}
                >
                  Tải excel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<BsPlus />}
                  sx={{
                    borderRadius: '20px',
                    // padding: "7px 15px",
                    textTransform: 'none',
                    marginLeft: '8px',
                    color: '#FFFFFF',
                    backgroundColor: '#21409A',
                    '.MuiButton-endIcon': {
                      marginLeft: '0px',
                    },
                    padding: '0px 10px',
                  }}
                  onClick={() => setSelected({ isNew: true })}
                >
                  Thêm mới
                </Button>
                {/* </Stack> */}
              </Box>
            </>
          );
        }}
      />
      <FormDetail {...{ selected, setSelected }} reload={initData} />
      <ModalFormDetail open={dataSelectEmail} onCloseModal={handleClose} title="Cập nhật Email">
        <EmailFormNew selectedEmail={dataSelectEmail} handleClose={handleClose} reload={initData} />
      </ModalFormDetail>
    </Box>
  );
}
export { User };
