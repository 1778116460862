import { styled } from '@mui/material/styles';
import { Box, Button, ToggleButtonGroup } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  flex: 1,
}));
export const ContainerContentBody = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'white',
  borderRadius: '15px',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
  boxShadow:
    'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
}));
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    textTransform: 'none',
    margin: theme.spacing(1),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
export const ContainerHeaderFormDetail = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  display: 'flex',
  // marginBottom: '10px',
  // marginTop: '5px',
}));
export const ContainerHeaderForm = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
}));

export const ContainerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  py: 1,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const ContainerForm = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  height: 'calc(100vh - 175px)',
}));

export const ButtonAdd = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  color: '#FFF',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));

export const ButtonExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '8px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
}));

export const ButtonImport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));
export const BtnCreateContract = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '6px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnViewReportContract = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 10px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '4px',
  },
}));
export const BtnRequestContract = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnPrintContract = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnRejectContract = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnApproveContract = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnSendQuote = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
