import * as React from 'react';
import { debounce } from 'lodash';
import { Box, Divider, Typography, Grid } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { ShowListFile, Selection } from 'components';

const SEARCH_USER_OPTIONS = [
  { value: 'ALL', content: 'Tất cả' },
  { value: '0', content: 'Người liên hệ' },
  { value: '1', content: 'Đối tác' },
];

const TabEmailManagement = ({ clientCareHistoryItem }) => {
  const [mails, setMails] = React.useState([]);
  const [searchUser, setSearchUser] = React.useState('ALL');

  const getMailByClientCareHistory = async (text = '') => {
    const textSearch = text === 'ALL' ? '' : text;

    try {
      const { data } = await ApiServices.getMailByClientCareHistory(
        clientCareHistoryItem.id,
        textSearch,
      );
      if (data) {
        setMails(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const timeoutGetData = React.useCallback(
    debounce((newSearch) => {
      getMailByClientCareHistory(newSearch);
    }, 500),
    [],
  );

  React.useEffect(() => {
    timeoutGetData(searchUser);
  }, [searchUser]);

  const handleChangeSearchUser = (event) => {
    setSearchUser(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100px',
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            marginTop: '10px',
            marginLeft: '10px',
          }}
        >
          <Typography
            align="center"
            component="div"
            variant="h6"
            sx={{ marginTop: '0px', alignItems: 'center' }}
          >
            Lịch sử gửi mail
          </Typography>
        </div>
        <div
          style={{
            marginTop: '4px',
            marginRight: '10px',
            width: '200px',
          }}
        >
          <Selection
            label="Người liên hệ/ Đối tác"
            size="small"
            displayNone={false}
            dataArrObject={SEARCH_USER_OPTIONS}
            onChange={handleChangeSearchUser}
            value={searchUser}
          />
        </div>
      </div>
      <Divider />
      {/* header */}
      <Grid container>
        <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Ngày gửi
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Tiêu đề
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Nội dung
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Liên hệ Đối tác
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Files
          </Typography>
        </Grid>
      </Grid>
      {/* body - mapdata */}
      {mails.map((item) => (
        <div key={item.id}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
              {Utils.formatDateHourFn(item?.createdOn)}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.subject.split('[')[0]}
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {Utils.htmlToString(item?.body)}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.customerType === 1 ? item?.vendorServiceName : item?.clientName}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              <ShowListFile fileList={item?.arrUrlfile} />
            </Grid>
          </Grid>
        </div>
      ))}
    </Box>
  );
};

export { TabEmailManagement };
