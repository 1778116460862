import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputItem from './input-item';

export const TractorPriceForm = ({
  arrContType = [],
  name,
  requiredTitle = 'Vui lòng nhập thông tin',
  value,
  label = '',
  onChange,
  error = false,
  helperText = '',
  disabledField = false,
  keyArray = 'arrCargoType', // array cont main
  keyNameOfArray = 'id', // name of array cont
  cargoTypeList = [],
  isShowPriceReal = true,
  isShowAction = true,
}) => {
  const initialValue = {
    contTypeCode: '',
    [keyArray]: arrContType?.map((item) => ({
      [keyNameOfArray]: item,
      cargoTypePrice: 0,
      requestPrice: 0,
    })),
  };
  const [values, setValues] = React.useState([initialValue]);
  const initRef = React.useRef(true);

  React.useEffect(() => {
    if (values?.length > 0 && arrContType?.length > 1) {
      const datas = values.map((item) => {
        const data = arrContType?.map((contType) => {
          const cont = item[keyArray]?.find((el) => el[keyNameOfArray] === contType);
          if (cont) {
            return cont;
          }
          return { [keyNameOfArray]: contType, cargoTypePrice: 0, requestPrice: 0 };
        });
        return { ...item, [keyArray]: data };
      });
      setValues(datas);
    }
  }, [arrContType?.length]);

  const onAdd = () => {
    setValues((prev) => {
      let tmp = [...prev];
      tmp.push({
        contTypeCode: '',
        [keyArray]: arrContType?.map((item) => ({
          [keyNameOfArray]: item,
          cargoTypePrice: 0,
          requestPrice: 0,
        })),
      });
      return tmp;
    });
  };
  const onDelete = (index) => {
    if (values && values.length === 1) {
      setValues([initialValue]);
    } else {
      setValues((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
    }
  };

  React.useEffect(() => {
    if (initRef.current && value?.length) {
      setValues(value);
      initRef.current = false;
    }
  }, [value]);
  React.useEffect(() => {
    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: values,
        },
      });
  }, [values]);
  const renderItems = (item, index) => {
    return (
      <InputItem
        key={'cargoTypePrice board-' + index}
        item={item}
        index={index}
        onAdd={onAdd}
        onDelete={onDelete}
        values={values}
        setValues={setValues}
        error={error}
        disabledField={disabledField}
        keyArray={keyArray}
        keyNameOfArray={keyNameOfArray}
        cargoTypeList={cargoTypeList}
        isShowPriceReal={isShowPriceReal}
        isShowAction={isShowAction}
      />
    );
  };
  return (
    <FormGroup margin="dense">
      <FormLabel>{label}</FormLabel>
      {values.map((item, index) => renderItems(item, index))}
      <FormHelperText error={error}>{helperText && requiredTitle}</FormHelperText>
    </FormGroup>
  );
};
