import actionTypes from './type';

const initialState = {
  subFeeList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SURCHARGE_BY_FEE_GROUP_SS:
      return {
        ...state,
        subFeeList: action.data,
      };
    case actionTypes.CLEAR_SURCHARGE_BY_FEE_GROUP_SS:
      return {
        ...state,
        subFeeList: [],
      };
    default:
      return state;
  }
}
