import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'components';

import storage from 'storage';

function PrivateRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        storage.isLoggedIn() ? (
          <Layout>{React.createElement(component, props)}</Layout>
        ) : (
          <Redirect
            push
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        storage.isLoggedIn() ? (
          <Redirect
            push
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <Layout>{React.createElement(component, props)}</Layout>
        )
      }
    />
  );
}

export { PrivateRoute, PublicRoute };
