import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputItem from './input-item';
import ApiServices from 'services';
import { FEE_GROUP_ID, SUB_SERVICES_ID } from 'commons/constants';

export const QuoteWhInboundOutboundForm = ({
  name,
  requiredTitle = 'Vui lòng nhập thông tin',
  value,
  label = '',
  onChange,
  error = false,
  helperText = '',
  isOutbound = false,
}) => {
  const initialValue = {
    surchargeId: '',
    localPrice: 0,
    overseasPrice: 0,
    vat: 0,
  };
  const [values, setValues] = React.useState([initialValue]);
  const initRef = React.useRef(true);
  const [surChargeListByFeeGroupId, setSurChargeListByFeeGroupId] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getInitSurCharge = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSSAndFeeId(
        isOutbound
          ? FEE_GROUP_ID.CUSTOM_CLEARANCE_CHARGE_OUTBOUND
          : FEE_GROUP_ID.CUSTOM_CLEARANCE_CHARGE_INBOUND,
        SUB_SERVICES_ID.BONDED_WAREHOUSE,
      );
      if (data) {
        setSurChargeListByFeeGroupId(data[0]?.surchargeListFree || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getInitSurCharge();
  }, []);

  const onAdd = () => {
    setValues((prev) => {
      let tmp = [...prev];
      tmp.push({
        surchargeId: '',
        localPrice: 0,
        overseasPrice: 0,
        vat: 0,
      });
      return tmp;
    });
  };
  const onDelete = (index) => {
    if (values && values.length === 1) {
      setValues([initialValue]);
    } else {
      setValues((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
    }
  };

  React.useEffect(() => {
    if (initRef.current && value?.length) {
      setValues(value);
      initRef.current = false;
    }
  }, [value]);
  React.useEffect(() => {
    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: values,
        },
      });
  }, [values]);
  const renderItems = (item, index) => {
    return (
      <InputItem
        key={'leasing-price-' + index}
        item={item}
        index={index}
        onAdd={onAdd}
        onDelete={onDelete}
        values={values}
        setValues={setValues}
        surChargeListByFeeGroupId={surChargeListByFeeGroupId}
      />
    );
  };
  return (
    <FormGroup margin="dense">
      <FormLabel>{label}</FormLabel>
      {!loading && values?.map((item, index) => renderItems(item, index))}
      <FormHelperText error={error}>{helperText && requiredTitle}</FormHelperText>
    </FormGroup>
  );
};
