import React from 'react';
import { Button } from '@mui/material';
import { PORT_TYPE } from 'commons/constants';
import storage from 'storage';

export const Columns = (deleteLocationVTItem) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Mã',
    width: 10,
    sort: true,
    align: 'center',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Tên cảng',
    align: 'center',
  },
  {
    id: 'nameEN',
    numeric: true,
    disablePadding: false,
    label: 'Tên Cảng (tiếng Anh)',
    align: 'center',
  },
  {
    id: 'nameCN',
    numeric: true,
    disablePadding: false,
    label: 'Tên Cảng (tiếng Hoa)',
    align: 'center',
  },
  {
    id: 'nameJP',
    numeric: true,
    disablePadding: false,
    label: 'Tên Cảng (tiếng Nhật)',
    align: 'center',
  },
  {
    id: 'typePort',
    numeric: true,
    disablePadding: false,
    label: 'Loại',
    align: 'center',
    render: (value) => PORT_TYPE[`${value?.typePort}`],
  },
  {
    id: 'listPortUse',
    numeric: true,
    disablePadding: false,
    label: 'Hình thức sử dụng',
    align: 'center',
    render: (value) => storage?.enums?.TypePortUse[`${value?.listPortUse}`],
  },
  {
    id: 'countryCode',
    numeric: true,
    disablePadding: false,
    label: 'Mã quốc gia',
    align: 'center',
  },
  {
    id: 'countryName',
    numeric: true,
    disablePadding: false,
    label: 'Quốc gia',
    align: 'center',
  },
  {
    id: 'areaName',
    numeric: true,
    disablePadding: false,
    label: 'Khu vực',
    align: 'center',
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi Chú',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteLocationVTItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
