import actionTypes from './type';

export const alertSuccess = (title, message, timeout) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE_SUCCESS, title, message, timeout });
  };
};

export const alertError = (title, message, timeout) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE_ERROR, title, message, timeout });
  };
};

export const alertErrorList = (title, messageList, timeout) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE_ERROR_LIST, title, messageList, timeout });
  };
};

export const alertWarning = (title, message, timeout) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE_WARNING, title, message, timeout });
  };
};

export const alertInfo = (title, message, timeout) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE_INFO, title, message, timeout });
  };
};

export const clearAlert = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE_CLEAR });
  };
};
