import actionTypes from './type';

const initialState = {
  isOpen: false,
  severity: '',
  title: '',
  message: '',
  messageList: [],
  isShowList: false,
  timeout: 2000,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.MESSAGE_SUCCESS:
      return {
        ...state,
        isOpen: true,
        severity: 'success',
        title: action.title,
        message: action.message,
        timeout: action.timeout || 2000,
        isShowList: false,
      };
    case actionTypes.MESSAGE_ERROR:
      return {
        ...state,
        isOpen: true,
        severity: 'error',
        title: action.title,
        message: action.message,
        timeout: action.timeout || 2000,
        isShowList: false,
      };
    case actionTypes.MESSAGE_ERROR_LIST:
      return {
        ...state,
        isOpen: true,
        severity: 'error',
        title: action.title,
        messageList: action.messageList,
        isShowList: true,
        timeout: action.timeout || 2000,
      };
    case actionTypes.MESSAGE_WARNING:
      return {
        ...state,
        isOpen: true,
        severity: 'warning',
        title: action.title,
        message: action.message,
        timeout: action.timeout || 2000,
        isShowList: false,
      };
    case actionTypes.MESSAGE_INFO:
      return {
        ...state,
        isOpen: true,
        severity: 'info',
        title: action.title,
        message: action.message,
        timeout: action.timeout || 2000,
        isShowList: false,
      };
    case actionTypes.MESSAGE_CLEAR:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
