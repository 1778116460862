import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import Utils from 'commons/utils';
import ApiServices from 'services';

function Companies() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCompanies();
      setData(data || []);
    } catch (err) {
      console.warn('initData - companies', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.deleteCompany(row.id);
      window.UIMessage?.success('Thao tác thành công');
      initData();
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };

  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: true,
      label: 'Mã',
      width: '10px',
      sort: true,
      align: 'center',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên Cty',
      align: 'center',
      width: '150px',
    },
    {
      id: 'levelCompany',
      numeric: true,
      disablePadding: false,
      label: 'Quy Mô CT',
      align: 'center',
      width: '100px',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
      align: 'center',
      width: '100px',
    },
    {
      id: 'tax_Code',
      numeric: true,
      disablePadding: false,
      label: 'Mã số thuế',
      align: 'center',
      width: '100px',
    },
    {
      id: 'arrNumberPhone',
      numeric: true,
      disablePadding: false,
      label: 'số ĐT',
      align: 'center',
      render: (row) => Utils.showArrayInTale(row.arrNumberPhone),
      width: '100px',
    },
    {
      id: 'arrFax',
      numeric: true,
      disablePadding: false,
      label: 'Fax',
      align: 'center',
      render: (row) => Utils.showArrayInTale(row.arrFax),
      width: '100px',
    },
    {
      address: 'arrAddress',
      numeric: false,
      disablePadding: false,
      label: 'Địa chỉ',
      width: '300px',
      align: 'left',
      render: (row) => Utils.showArrayInTale(row.arrAddress),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Thao tác',
      width: '10px',
      fixed: 'right',
      align: 'center',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data}
        headCells={headCells}
        onClickDetail={(row) => {
          setSelected(row);
        }}
        title="Danh Sách Công Ty"
        loading={loading}
        renderHeader={() => {
          return (
            <>
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <SearchInput sx={{ mr: 2 }} maxWidth={400} />
              </Box>
              <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                <BsFilter />
              </IconButton>
              <Button
                variant="outlined"
                startIcon={<BsArrowDownCircle />}
                size="small"
                sx={{
                  textTransform: 'none',
                  borderRadius: '15px',
                  marginLeft: '5px',
                  color: '#6C757D',
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                  padding: '4px 15px',
                  '.MuiButton-startIcon': {
                    marginRight: '2px',
                  },
                }}
              >
                Nhập excel
              </Button>
              <Button
                variant="outlined"
                startIcon={<BsArrowUpCircle />}
                size="small"
                sx={{
                  textTransform: 'none',
                  borderRadius: '15px',
                  marginLeft: '5px',
                  color: '#6C757D',
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                  padding: '4px 15px',
                  '.MuiButton-startIcon': {
                    marginRight: '2px',
                  },
                }}
              >
                Tải excel
              </Button>
              <Button
                variant="contained"
                size="small"
                endIcon={<BsPlus />}
                sx={{
                  textTransform: 'none',
                  borderRadius: '15px',
                  marginLeft: '5px',
                  color: '#FFF',
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                  padding: '4px 15px',
                  '.MuiButton-startIcon': {
                    marginRight: '2px',
                  },
                }}
                onClick={() => setSelected({ isNew: true })}
              >
                Thêm mới
              </Button>
            </>
          );
        }}
      />
      <FormDetail {...{ selected, setSelected }} reload={initData} />
    </Box>
  );
}
export { Companies };
