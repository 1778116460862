import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import EmailForm from './email-form';
import ApiServices from 'services';
import Storage from 'storage';

const FORM_WIDTH = '600px';
export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const [isCallApi, setIsCallApi] = useState(false);

  useEffect(() => {
    if (!selected?.isNew) {
      setIsCallApi(true);
    }
  }, [selected]);

  const createData = async function (values, { setSubmitting }) {
    const dataSend = { ...values };
    if (values.email) {
      dataSend.userMail = {
        user: values.email,
        password: values.passwordEmail,
      };
    }
    // dataSend.password = '123456';
    delete dataSend.email;
    delete dataSend.passwordEmail;
    delete dataSend.isNew;

    setSubmitting(true);
    try {
      const res = await ApiServices.createUser(dataSend);
      console.log({ created: res });
      reload();
      setSelected(null);
      window.UIMessage?.success('Thao tác thành công');
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('creat User - companies', err);
    }
    setSubmitting(false);
  };
  const updateData = async function (values, { setSubmitting }) {
    const dataSend = { ...values };
    if (values.email) {
      dataSend.userMail = {
        user: values.email,
      };
    }
    // dataSend.companyId = Storage?.user?.CompanyId;
    delete dataSend.email;
    delete dataSend.isNew;
    delete dataSend.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateUser(dataSend.id, dataSend);
      reload();
      setSelected(null);
      window.UIMessage?.success('Thao tác thành công');
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('create User - companies', err);
    }
    setSubmitting(false);
  };

  return (
    <HandleFormDetail
      {...{ selected, setSelected, width: FORM_WIDTH }}
      showTitleTab={selected?.isNew ? 'Tạo mới' : `Người dùng: ${selected?.name}`}
      // moreTabs={[
      //   {
      //     title: 'Email',
      //     content: () => (
      //       <EmailForm selected={selected} setSelected={setSelected} reload={reload} />
      //     ),
      //     disabled: selected?.isNew,
      //   },
      // ]}
    >
      <Formik
        initialValues={{
          code: '',
          name: '',
          companyId: '',
          departmentId: '',
          titleId: '',
          email: '',
          passwordEmail: '',
          note: '',
          ...selected,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required('Vui lòng nhập Mã'),
          name: Yup.string().max(255).required('vui lòng nhập tên người dùng'),
          companyId: Yup.string().max(255).required('vui lòng chọn công ty'),
          departmentId: Yup.string().required('vui lòng nhập phòng ban'),
          email: Yup.string().max(255).email('Email không hợp lệ'),
          titleId: Yup.string().required('vui lòng nhập chức danh'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextInput
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
              label="Mã"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
              disabled={!selected?.isNew}
            />
            <TextInput
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Tên người dùng"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
            <Selection
              error={Boolean(touched.companyId && errors.companyId)}
              helperText={touched.companyId && errors.companyId}
              label="Công ty"
              name="companyId"
              onBlur={handleBlur}
              onChange={(e) => {
                values.departmentId = '';
                handleChange(e);
                setIsCallApi(true);
              }}
              value={values.companyId}
              syncData={ApiServices.getCompanies}
            />
            {values?.companyId && (
              <Selection
                error={Boolean(touched.departmentId && errors.departmentId)}
                helperText={touched.departmentId && errors.departmentId}
                label="Phòng ban"
                name="departmentId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.departmentId}
                // syncData={ApiServices.getDepartments}
                setIsCallApi={setIsCallApi}
                syncData={() =>
                  isCallApi && ApiServices.getDepartmentByCompanyId(values?.companyId)
                }
              />
            )}
            <Selection
              error={Boolean(touched.titleId && errors.titleId)}
              helperText={touched.titleId && errors.titleId}
              label="Chức danh"
              name="titleId"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.titleId}
              syncData={ApiServices.getPositions}
            />
            <TextInput
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              label="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              disabled={!selected?.isNew}
            />
            {/* {values.email && selected?.isNew && (
              <TextInput
                error={Boolean(touched.passwordEmail && errors.passwordEmail)}
                helperText={touched.passwordEmail && errors.passwordEmail}
                label="Mật khẩu email"
                name="passwordEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passwordEmail}
              />
            )} */}
            <TextInput
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              label="Ghi chú"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
              multiline
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
