import actionTypes from './type';

const initialState = {
  unitList: [],
  currencyList: [],
  portList: [],
  carrierList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_UNITS:
      return {
        ...state,
        unitList: action.data,
      };
    case actionTypes.CLEAR_UNITS:
      return {
        ...state,
        unitList: [],
      };
    case actionTypes.FETCH_CURRENCIES:
      return {
        ...state,
        currencyList: action.data,
      };
    case actionTypes.CLEAR_CURRENCIES:
      return {
        ...state,
        currencyList: [],
      };
    case actionTypes.FETCH_PORTS_BY_TYPE:
      return {
        ...state,
        portList: action.data,
      };
    case actionTypes.CLEAR_PORTS_BY_TYPE:
      return {
        ...state,
        portList: [],
      };
    case actionTypes.FETCH_CARRIERS_BY_TYPE:
      return {
        ...state,
        carrierList: action.data,
      };
    case actionTypes.CLEAR_CARRIERS_BY_TYPE:
      return {
        ...state,
        carrierList: [],
      };
    default:
      return state;
  }
}
