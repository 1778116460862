import { createTheme } from '@mui/material';
import tinycolor from 'tinycolor2';

// colors
import colors from './colors';
// border
const borderWidth = 2;

const {
  primary,
  secondary,
  warning,
  success,
  info,
  black,
  darkBlack,
  background,
  warningLight,
  warningMain,
  warningDark,
  borderColor,
  success2,
  messageActive,
  messageBoxShadow,
} = colors;
// breakpoints
// const xl = 1920;
// const lg = 1280;
// const md = 960;
// const sm = 600;
// const xs = 0;

// spacing
const spacing = 8;

const lightenRate = 7.5;
const darkenRate = 15;

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    success2: {
      main: success2,
      light: tinycolor(success2).lighten(lightenRate).toHexString(),
      dark: tinycolor(success2).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
    },
    common: {
      black,
      darkBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      messageActiveColor: messageActive,
    },
    messageBoxShadow,
    spacing,
  },
  // breakpoints: {
  //   // Define custom breakpoint values.
  //   // These will apply to Material-UI components that use responsive
  //   // breakpoints, such as `Grid` and `Hidden`. You can also use the
  //   // theme breakpoint functions `up`, `down`, and `between` to create
  //   // media queries for these breakpoints
  //   values: {
  //     xl,
  //     lg,
  //     md,
  //     sm,
  //     xs,
  //   },
  // },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: 'static',
      },
    },
    // MuiTableCell: {
    //   root: {
    //     paddingLeft: spacing * 2,
    //     paddingRight: spacing * 2,
    //     borderBottom: `${borderWidth}px solid ${borderColor}`,
    //     [`@media (max-width:  ${sm}px)`]: {
    //       paddingLeft: spacing,
    //       paddingRight: spacing,
    //     },
    //   },
    // },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    // MuiExpansionPanelDetails: {
    //   root: {
    //     [`@media (max-width:  ${sm}px)`]: {
    //       paddingLeft: spacing,
    //       paddingRight: spacing,
    //     },
    //   },
    // },
  },
  typography: {
    useNextVariants: true,
  },
});
export default theme;
