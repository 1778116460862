import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { Box, Paper, Typography, Grid, Link } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

const Area = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '20px',
  width: '100%',
  m: '10px',
  padding: '10px',
}));
const TaskSummary = ({ deals, leads, ...props }) => {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0]}`,
    };
  }
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const renderItem = (item) => {
    return (
      <Box
        key={`item-tab-summary${item.id}`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBlock: '10px',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '10px',
          position: 'relative',
          padding: '10px',
          alignItems: 'center',
        }}
      >
        <Avatar {...stringAvatar(item?.objClient?.fullName || 'Default Name')} />
        <Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ marginInline: '10px' }}
            // align="center"
          >
            {item?.objClient?.fullName}
          </Typography>
          <Typography
            variant="h7"
            component="div"
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '5px 10px',
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '16px',
            }}
          >
            {item?.serviceName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              variant="h7"
              component="div"
              sx={{ marginInline: '10px' }}
              // align="center"
            >
              {item?.clientStatusName}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Area className="box-task-sumary">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h7" component="div" sx={{ color: 'primary.main' }} align="center">
            <b>Khách hàng</b>
          </Typography>
          <Box sx={{ minHeight: '200px', marginRight: '10px' }}>
            {leads?.length && leads.map((item) => renderItem(item))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h7" component="div" sx={{ color: 'primary.main' }} align="center">
            <b>Deal</b>
          </Typography>
          <Box sx={{ minHeight: '200px', marginLeft: '10px' }}>
            {deals?.length && deals.map((item) => renderItem(item))}
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex', pr: '15px' }}>
          <Link href="/client-care-history" underline="hover">
            Xem tất cả
          </Link>
        </Grid>

        <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Link href="/client-care-history" underline="hover">
            Xem tất cả
          </Link>
        </Grid>
      </Grid>
    </Area>
  );
};

export { TaskSummary };
