import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';
import { styled } from '@mui/material/styles';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { Table, ShowListFile, ModalFormDetail } from 'components';
import { ContainerHeader, BtnCreateContract } from '../styles';
import { ContractAppendixForm } from './contract-appendix-form';

const ContractAppendix = ({ selectedContract, getContractDetail, isReadOnly = false }) => {
  const [loading, setLoading] = useState(false);
  const [showCreateAppendix, setShowCreateAppendix] = useState(null);

  const handleShow = () => {
    setShowCreateAppendix({ isNew: true });
  };
  const handleClose = () => {
    setShowCreateAppendix(null);
  };

  const handleSubmit = async (dataSend) => {
    const contractId = selectedContract?.id;
    if (!contractId || !dataSend) return;
    try {
      const res = await ApiServices.createContractAppendix(contractId, dataSend);
      if (res?.status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        handleClose();
        getContractDetail(contractId);
      }
    } catch (error) {
      window.UIMessage?.error('Thêm mới thất bại !!!');
      console.log('Create Contract Failed:::', error);
    }
  };

  const handleDelete = async (rowData) => {
    const contractId = selectedContract?.id;
    const addendumCode = rowData?.code;
    if (!contractId || !addendumCode) return;
    try {
      const res = await ApiServices.getRemoveFileContract(contractId, addendumCode);
      if (res?.status === 200) {
        window.UIMessage?.success('Xóa thành công');
        getContractDetail(contractId);
      }
    } catch (error) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.log('Delete Contract Failed:::', error);
    }
  };

  const columns = [
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: 'Mã phụ lục',
      align: 'center',
      width: '150px',
    },
    {
      id: 'contractAddendumName',
      numeric: true,
      disablePadding: false,
      label: 'Tên phụ lục',
      align: 'center',
      width: '300px',
    },
    {
      id: 'urlFile',
      numeric: true,
      disablePadding: false,
      label: 'File phụ lục',
      align: 'center',
      render: (row) => <ShowListFile maxWidth="200px" fileList={row?.urlFile} />,
    },
    {
      id: 'effectiveDate',
      numeric: true,
      disablePadding: false,
      label: 'Ngày bắt đầu',
      align: 'center',
      width: '130px',
      render: (row) => Utils.formatDateFn(row?.effectiveDate),
    },
    // {
    //   id: 'expireDate',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Ngày kết thúc',
    //   align: 'center',
    //   render: (row) => Utils.formatDateFn(row?.expireDate),
    // },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'center',
      width: '200px',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Thao tác',
      width: '10px',
      fixed: 'right',
      align: 'center',
      render: (row) => (
        <Button
          disabled={isReadOnly}
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => handleDelete(row)}
        >
          Xoá
        </Button>
      ),
    },
  ];
  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Phụ lục hợp đồng
        </Typography>
      </ContainerHeader>
      <BtnCreateContract
        disabled={isReadOnly}
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={handleShow}
      >
        Thêm mới
      </BtnCreateContract>
    </>
  );

  return (
    <Box>
      <Table
        rows={selectedContract?.arrFileAddendum || []}
        headCells={columns}
        // totalRecords={totalRecords}
        onPageChange={(paging) => {}}
        // onClickDetail={handleSelected}
        loading={loading}
        renderHeader={renderHeader}
      />
      <ModalFormDetail
        open={showCreateAppendix}
        onCloseModal={handleClose}
        title="Thêm mới phụ lục"
        maxWidth="sm"
      >
        <ContractAppendixForm onSubmit={handleSubmit} />
      </ModalFormDetail>
    </Box>
  );
};

export { ContractAppendix };

export const ButtonAdd = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '5px',
  marginLeft: 'auto',
  color: '#FFF',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));
