import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Modal from './add-popup';
export default function ({ values, onChange }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Wrapper>
      <RowWrapper sx={{ bgcolor: 'action.hover' }}>
        <Title>Thấp</Title>
        <Title>Cao</Title>
        <Title sx={{ flexFlow: 1 }}>
          Kết quả{' '}
          <IconButton onClick={() => setOpen(true)}>
            <AddCircleIcon color="success" />
          </IconButton>
        </Title>
      </RowWrapper>
      {values.map((item, index) => (
        <RowWrapper key={`formula-criteria-RowWrapper-${index}`}>
          <Content>{item?.range?.low}</Content>
          <Content>{item?.range?.high}</Content>
          <Content sx={{ flexFlow: 1 }}>
            {item?.result}
            <IconButton
              onClick={() =>
                onChange((prev) => {
                  prev.splice(index, 1);
                  return [...prev];
                })
              }
            >
              <RemoveCircleIcon color="error" />
            </IconButton>
          </Content>
        </RowWrapper>
      ))}
      <Modal open={open} setOpen={setOpen} onChange={onChange} />
    </Wrapper>
  );
}

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '4px',
  paddingInline: '8px',
  flexDirection: 'column',
}));
const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px',
  borderRadius: '4px',
}));
const Title = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '500',
  minWidth: '130px',
  textAlign: 'center',
}));
const Content = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  minWidth: '130px',
  textAlign: 'center',
}));
