import React from 'react';
import { Box, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFillStickiesFill,
} from 'react-icons/bs';
import { SeaFCL } from './sea-FCL';
import { SeaLCL } from './sea-LCL';
import { AirFreight } from './air-freight';
import { TabItem, BtnCreateFreight, ButtonImport, ButtonExport } from './styles';
import ApiServices from 'services';
import Utils from 'commons/utils';
import moment from 'moment';

const TAB_FREIGHT_KEY = {
  seaFCL: '1',
  seaLCL: '2',
  air: '3',
};
const SEA_FCL_TEMPLATE = 'FreightFCL_Template';

export const FreightPriceBoard = () => {
  const [tabIndex, setTabIndex] = React.useState(TAB_FREIGHT_KEY.seaFCL);
  const [addSeaFCL, setAddSeaFCL] = React.useState(false);
  const [addSeaLCL, setAddSeaLCL] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [addAirFreight, setAddAirFreight] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const closeAddNew = () => {
    if (tabIndex === TAB_FREIGHT_KEY.seaFCL) {
      setAddSeaFCL(false);
    } else if (tabIndex === TAB_FREIGHT_KEY.seaLCL) {
      setAddSeaLCL(false);
    } else if (tabIndex === TAB_FREIGHT_KEY.air) {
      setAddAirFreight(false);
    }
  };

  const onHandleAddNew = () => {
    if (tabIndex === TAB_FREIGHT_KEY.seaFCL) {
      setAddSeaFCL(true);
    } else if (tabIndex === TAB_FREIGHT_KEY.seaLCL) {
      setAddSeaLCL(true);
    } else if (tabIndex === TAB_FREIGHT_KEY.air) {
      setAddAirFreight(true);
    }
  };
  React.useEffect(() => {
    return () => {
      setAddSeaFCL(false);
      setAddSeaLCL(false);
      setAddAirFreight(false);
    };
  }, []);

  const handleExportTemplate = () => {
    setLoading(true);
    return ApiServices.exportTemplates(SEA_FCL_TEMPLATE)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Bảng_giá_sea_Fcl_bieu_mau.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const handleExportClient = () => {
    setLoading(true);
    return ApiServices.exportExcelFclFreight()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `Bảng_giá_Sea_Fcl_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export sea Fcl error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const onHandleImport = (event) => {
    setLoading(true);
    return ApiServices.importExcelFclFreight({
      excelFile: event.target.files[0],
    })
      .then((res, message) => {
        if (res?.data) {
          window.UIMessage?.success(
            `Import excel thành công - Thêm mới ${res?.data?.insert} - Cập nhật ${res?.data?.update}`,
            4000,
          );
          event.target.value = '';
        }
      })
      .catch((err) => {
        console.warn('import excel - Lead mới', err);
        window.UIMessage?.error('Import excel thất bại !!!');
      })
      .finally(() => {
        setTabIndex(TAB_FREIGHT_KEY.seaFCL);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" component="h6">
        BẢNG GIÁ DỊCH VỤ CƯỚC QUỐC TẾ
      </Typography>
      <TabContext value={tabIndex ? tabIndex : TAB_FREIGHT_KEY.seaFCL}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
            <TabItem label="Đường biển FCL" value={TAB_FREIGHT_KEY.seaFCL} />
            <TabItem label="Đường biển LCL" value={TAB_FREIGHT_KEY.seaLCL} />
            <TabItem label="Hàng không" value={TAB_FREIGHT_KEY.air} />
            <Box sx={{ alignItems: 'center', marginLeft: 'auto' }}>
              {tabIndex === TAB_FREIGHT_KEY.seaFCL && (
                <>
                  <ButtonImport
                    variant="outlined"
                    component="label"
                    startIcon={<BsBoxArrowInUpRight />}
                  >
                    Nhập excel
                    <input
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      type="file"
                      hidden
                      onChange={onHandleImport}
                    />
                  </ButtonImport>
                  <ButtonExport
                    variant="outlined"
                    startIcon={<BsArrowDownCircle />}
                    onClick={handleExportClient}
                  >
                    Tải excel
                  </ButtonExport>
                  <ButtonExport
                    variant="outlined"
                    startIcon={<BsFillStickiesFill />}
                    onClick={handleExportTemplate}
                  >
                    Biểu mẫu
                  </ButtonExport>
                </>
              )}
              <BtnCreateFreight
                variant="contained"
                startIcon={<BsPlusCircle />}
                onClick={onHandleAddNew}
              >
                Thêm mới
              </BtnCreateFreight>
            </Box>
          </TabList>
        </Box>
        {tabIndex && (
          <>
            <TabPanel value={TAB_FREIGHT_KEY.seaFCL} sx={{ padding: '5px 0px' }}>
              <SeaFCL openAddSeaFCL={addSeaFCL} closeAddSeaFCL={closeAddNew} reloadData={reload} />
            </TabPanel>
            <TabPanel value={TAB_FREIGHT_KEY.seaLCL} sx={{ padding: '5px 0px' }}>
              <SeaLCL openAddSeaLCL={addSeaLCL} closeAddSeaLCL={closeAddNew} />
            </TabPanel>
            <TabPanel value={TAB_FREIGHT_KEY.air} sx={{ padding: '5px 0px' }}>
              <AirFreight openAddAirFreight={addAirFreight} closeAddAirFreight={closeAddNew} />
            </TabPanel>
          </>
        )}
      </TabContext>
    </Box>
  );
};
