import { combineReducers } from 'redux';
import app from './app/reducer';
import alertMessage from './alert-messages/reducer';
import confirm from './confirm-messages/reducer';
import chatChanel from './chat-chanel/reducer';
import chatMessage from './chat-messages/reducer';
import users from './users/reducer';
import openSideBar from './open-sidebar/reducer';
import categoryData from './category-data/reducer';
import checkPrice from './check-price/reducer';
import resCheckPrice from './res-check-price/reducer';
import customerQuotes from './customerQuotes/reducer';
import contract from './contract/reducer';

const rootReducer = combineReducers({
  app,
  alertMessage,
  confirm,
  chatChanel,
  chatMessage,
  users,
  openSideBar,
  categoryData,
  checkPrice,
  resCheckPrice,
  customerQuotes,
  contract,
});

export default rootReducer;
