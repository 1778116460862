import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectCustomsService } from 'store/reducers/customerQuotes/action';
import { TableCustomQuote } from 'components';
import { Box } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';
import { SUB_SERVICES_ID } from 'commons/constants';

function CustomsAir() {
  const dispatch = useDispatch();
  const [cusAirList, setCusAirList] = useState([]);
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getDataQuotesCustoms(SUB_SERVICES_ID.CUSTOMS_AIR, {
        pageNumber: 1,
        pageSize: 10,
      });
      setCusAirList(data?.data || []);
    } catch (err) {
      console.warn('initData - getDataQuotesCustoms', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    initData();
  }, []);

  const handleSelectedChange = (row, values) => {
    dispatch(selectCustomsService(values));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableCustomQuote
        rows={cusAirList}
        headCells={Columns()}
        loading={loading}
        isHeader={false}
        isCheckbox={true}
        onSelectedChange={handleSelectedChange}
        checkedOnlyItem={true}
      />
    </Box>
  );
}
export { CustomsAir };
