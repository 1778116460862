import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import { PORT_TYPE, VIETNAM_COUNTRY_ID } from 'commons/constants';
import storage from 'storage';

const PORT_TYPE_OPTIONS = [
  { value: 0, content: PORT_TYPE[0] },
  { value: 1, content: PORT_TYPE[1] },
];

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const [useTypeList, setUseTypeList] = useState([]);

  const getDataInit = async () => {
    const useType = await storage.enums.TypePortUse;
    if (!useType) return;
    const listData = Object.entries(storage?.enums?.TypePortUse)
      .filter((el) => el[1])
      .map((item) => ({
        value: item[0],
        content: item[1],
      }));
    setUseTypeList(listData);
  };

  useEffect(async () => {
    getDataInit();
  }, []);

  const createFn = async (values, { setSubmitting }) => {
    if (values.countryId !== VIETNAM_COUNTRY_ID) delete values.areaId;
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createPort(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    if (values.countryId !== VIETNAM_COUNTRY_ID) {
      values.areaId = null;
    }
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updatePort(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Cảng: ${selected?.name}`}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            nameEN: '',
            nameCN: '',
            nameJP: '',
            typePort: 0,
            countryId: '',
            areaId: '',
            note: '',
            listPortUse: [],
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
              .required('Vui lòng nhập mã địa điểm'),
            name: Yup.string().max(255).required('Vui lòng nhập tên cảng'),
            nameEN: Yup.string().max(255).required('Vui lòng nhập tên Cảng tiếng Anh'),
            nameCN: Yup.string().max(255).required('Vui lòng nhập tên Cảng tiếng Hoa'),
            nameJP: Yup.string().max(255).required('Vui lòng nhập tên Cảng tiếng Nhật'),
            countryId: Yup.string().max(255).required('Vui lòng chọn quốc gia'),
            typePort: Yup.string().max(255).required('Vui lòng chọn loại cảng'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Tên cảng"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                required
                error={Boolean(touched.nameEN && errors.nameEN)}
                helperText={touched.nameEN && errors.nameEN}
                label="Tên Cảng tiếng Anh"
                name="nameEN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameEN}
              />
              <TextInput
                required
                error={Boolean(touched.nameCN && errors.nameCN)}
                helperText={touched.nameCN && errors.nameCN}
                label="Tên Cảng tiếng Hoa"
                name="nameCN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameCN}
              />
              <TextInput
                required
                error={Boolean(touched.nameJP && errors.nameJP)}
                helperText={touched.nameJP && errors.nameJP}
                label="Tên Cảng tiếng Nhật"
                name="nameJP"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameJP}
              />
              <Selection
                required
                error={Boolean(touched.typePort && errors.typePort)}
                helperText={touched.typePort && errors.typePort}
                label="Loại"
                name="typePort"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.typePort}
                dataArrObject={PORT_TYPE_OPTIONS}
              />
              <Selection
                error={Boolean(touched.listPortUse && errors.listPortUse)}
                helperText={touched.listPortUse && errors.listPortUse}
                label="Hình thức giao hàng"
                name="listPortUse"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.listPortUse}
                dataArrObject={useTypeList}
                multiple
              />
              <Selection
                required
                error={Boolean(touched.countryId && errors.countryId)}
                helperText={touched.countryId && errors.countryId}
                label="Quốc gia"
                name="countryId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.countryId}
                syncData={ApiServices.getCountries}
              />
              {values.countryId === VIETNAM_COUNTRY_ID && (
                <>
                  <Selection
                    required
                    error={Boolean(touched.areaId && errors.areaId)}
                    helperText={touched.areaId && errors.areaId}
                    label="Khu vực"
                    name="areaId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.areaId}
                    syncData={ApiServices.getAreas}
                  />
                  {!values.areaId && <div style={{ color: 'red' }}>Vui lòng nhập khu vực</div>}
                </>
              )}

              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Ghi chú"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={
                    isSubmitting || (values.countryId === VIETNAM_COUNTRY_ID && !values.areaId)
                  }
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
