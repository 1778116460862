import React, { useEffect, useState } from 'react';
import { SearchInput, LoadingView } from 'components';
import { Box, Button, ButtonGroup, List, Typography, Grid } from '@mui/material';
import ApiServices from 'services';
import { styled } from '@mui/material/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CachedIcon from '@mui/icons-material/Cached';

import { FormDetail } from './form-detail';
import GridItems from './grid-items-view';
import ListItems from './list-items-view';

import PublicOffIcon from '@mui/icons-material/PublicOff';
import PublicIcon from '@mui/icons-material/Public';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

function Documents() {
  const [data, setData] = useState([]);
  const [type, setType] = useState('grid');
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const reloadRef = React.useRef(null);
  const renderStatus = (item) => {
    switch (item.fileStatus) {
      case 'private':
        return <PublicOffIcon />;
      case 'public':
        return <PublicIcon />;
      default:
        return <AssignmentIndIcon />;
    }
  };
  useEffect(() => {
    if (loading) return;
    if (query) {
      clearTimeout(reloadRef.current);
      reloadRef.current = setTimeout(() => initData(), 1000);
    }
  }, [query]);
  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getDocuments(query);
      setData(data || []);
    } catch (err) {
      console.warn('initData - companies', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.deleteDocument(id);
      window.UIMessage?.success('Xoá thành công');
      initData();
    } catch (err) {
      window.UIMessage?.error('Xoá thất bại');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };
  function renderIcon(item) {
    switch (item?.objFileinfo?.extension) {
      case '.pdf':
        return <PictureAsPdfIcon sx={{ color: 'primary.main' }} />;
      case '.png':
        return <ImageIcon sx={{ color: 'primary.main' }} />;
      case '.jpg':
        return <ImageIcon sx={{ color: 'primary.main' }} />;
      default:
        return <InsertDriveFileIcon sx={{ color: 'primary.main' }} />;
    }
  }

  function renderData() {
    if (type !== 'grid') {
      return (
        <List>
          {data.map((item) => (
            <ListItems
              key={`ListItem-documents-${item.id}`}
              {...{ item, renderIcon, setSelected, deleteCell, renderStatus }}
            />
          ))}
        </List>
      );
    }
    return (
      <Grid container spacing={2} sx={{ mt: '10px' }}>
        {data.map((item) => (
          <GridItems
            key={`ListItem-documents-${item.id}`}
            {...{ item, renderIcon, setSelected, deleteCell, renderStatus }}
          />
        ))}
      </Grid>
    );
  }
  return (
    <Box sx={{ width: '100%' }}>
      <LoadingView loading={loading} />
      <HeaderWrapper>
        <Typography sx={{ mb: 1, flex: 1 }} variant="h5" component="div">
          Quản lý tài liệu
        </Typography>
        <Button
          startIcon={<CachedIcon />}
          sx={{ ml: '16px', borderRadius: '20px' }}
          variant="outlined"
          onClick={() => setQuery({})}
        >
          Reload
        </Button>
        <ButtonGroup
          variant="text"
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            ml: '16px',
          }}
        >
          <Button onClick={() => setType('list')}>
            <ListIcon />
          </Button>
          <Button onClick={() => setType('grid')}>
            <GridViewIcon />
          </Button>
        </ButtonGroup>
        <Button
          startIcon={<AddIcon />}
          sx={{ ml: '16px', borderRadius: '20px' }}
          variant="contained"
          onClick={() => setSelected({ isNew: true })}
        >
          Thêm Mới
        </Button>
      </HeaderWrapper>
      <FilterWrapper>
        <Box sx={{ flex: 1 }}>
          <SearchInput
            value={query?.search}
            onChange={(e) => setQuery({ search: e.target.value })}
          />
        </Box>

        <Button
          variant={query?.fileStatus !== 'department' ? 'outlined' : 'contained'}
          onClick={() => setQuery((prev) => ({ ...prev, fileStatus: 'department' }))}
          sx={{ ml: '16px' }}
        >
          Phòng ban
        </Button>
        <Button
          variant={query?.fileStatus !== 'private' ? 'outlined' : 'contained'}
          onClick={() => setQuery((prev) => ({ ...prev, fileStatus: 'private' }))}
          sx={{ ml: '16px' }}
        >
          Cá nhân <PublicOffIcon />
        </Button>
        <Button
          variant={query?.fileStatus !== 'public' ? 'outlined' : 'contained'}
          onClick={() => setQuery((prev) => ({ ...prev, fileStatus: 'public' }))}
          sx={{ ml: '16px' }}
        >
          Dùng chung <PublicIcon />
        </Button>
      </FilterWrapper>
      <Wrapper>{renderData()}</Wrapper>
      <FormDetail {...{ selected, setSelected }} reload={initData} />
    </Box>
  );
}
const Wrapper = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}));
const FilterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '12px',
}));
export { Documents };
