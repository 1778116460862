import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button, Avatar } from '@mui/material';
import ServicesCheckbox from './services-checkbox';
import Storage from 'storage';

const renderService = (row, services, setServices, serviceList) => {
  return (
    <ServicesCheckbox
      data={row}
      serviceList={serviceList}
      services={services}
      onChange={(value) => {
        setServices((prev) => ({ ...prev, ...value }));
      }}
    />
  );
};

const renderClient = (row) => {
  return (
    <>
      {row?.name}
      {row?.companyId !== Storage.user.CompanyId && (
        <div style={{ color: 'blue' }}>
          (Được chia sẻ bởi: <b style={{ color: 'red' }}>{row?.companyCode}</b>)
        </div>
      )}
    </>
  );
};

export const getColumns = (deleteItem, services, setServices, onChangeSearch, serviceList) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã" name="code" onChange={onChangeSearch} />,
    align: 'center',
  },
  {
    id: 'urlImage',
    numeric: true,
    disablePadding: false,
    label: 'Logo',
    align: 'center',
    alignTitle: 'center',
    render: (row) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          alt={row?.code}
          sx={{
            width: 60,
            height: 60,
          }}
          src={`${process.env.REACT_APP_API_URL}/${row?.urlImage}`}
        />
      </div>
    ),
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    width: '200px',
    label: <SearchInputColumn title="Khách hàng" name="name" onChange={onChangeSearch} />,
    align: 'left',
    render: renderClient,
  },
  {
    id: 'arrService',
    numeric: true,
    disablePadding: false,
    width: '250px',
    label: (
      <SearchInputColumn title="DS Dịch vụ" name="arrService.name" onChange={onChangeSearch} />
    ),
    align: 'left',
    render: (row) => renderService(row, services, setServices, serviceList),
  },
  {
    id: 'arrNumberPhone',
    numeric: true,
    disablePadding: false,
    width: '120px',
    label: <SearchInputColumn title="SĐT" name="arrNumberPhone" onChange={onChangeSearch} />,
    render: (row) => Utils.showArrayInTale(row.arrNumberPhone),
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    width: '80px',
    label: <SearchInputColumn title="Email" name="email" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'tax_Code',
    numeric: true,
    disablePadding: false,
    width: '120px',
    label: <SearchInputColumn title="Mã số thuế" name="tax_Code" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'arrAddress',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Địa chỉ" name="arrAddress" onChange={onChangeSearch} />,
    align: 'left',
    width: '300px',
    render: (row) => Utils.showArrayInTale(row.arrAddress),
  },

  {
    id: 'arrFax',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Ứng dụng liên hệ" name="arrFax" onChange={onChangeSearch} />,
    align: 'left',
    render: (row) => Utils.showArrayInTale(row.arrFax),
  },
  {
    id: 'foundingDate',
    numeric: true,
    disablePadding: false,
    width: '100px',
    label: <SearchInputColumn title="Thành lập" name="allDate" onChange={onChangeSearch} />,
    align: 'left',
    render: (row) => Utils.formatDateFn(row.foundingDate),
  },
  {
    id: 'companyType',
    numeric: true,
    disablePadding: false,
    width: '100px',
    label: <SearchInputColumn title="Loại CT" name="companyType" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'cargotype',
    numeric: true,
    disablePadding: false,
    width: '120px',
    label: <SearchInputColumn title="Loại hàng hóa" name="cargotype" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'representer',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="Người đại diện" name="representer" onChange={onChangeSearch} />
    ),
    width: '120px',
    align: 'left',
  },
  {
    id: 'birthdayRepresenter',
    numeric: true,
    disablePadding: false,
    label: 'Ngày sinh NĐD',
    width: '100px',
    align: 'left',
    render: (row) => Utils.formatDateFn(row.birthdayRepresenter),
  },
  {
    id: 'emailRepresenter',
    numeric: true,
    disablePadding: false,
    width: '100px',
    label: 'Email NĐD',
    align: 'left',
  },
  {
    id: 'phoneRepresenter',
    numeric: true,
    disablePadding: false,
    width: '100px',
    label: 'SĐT NĐD',
    align: 'left',
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    width: '150px',
    label: <SearchInputColumn title="Ghi chú" name="note" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    render: (row) =>
      row?.companyId === Storage.user.CompanyId &&
      (!row?.arrServiceReceivedId || row?.arrServiceReceivedId?.length < 1) && (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteItem(row))}
        >
          Hủy
        </Button>
      ),
  },
];
