import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Utils from 'commons/utils';
import { ShowListFile, Table } from 'components';

const ContractDetail = ({ dataSelect }) => {
  const columns = [
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: 'Mã phụ lục',
      align: 'center',
      width: '150px',
    },
    {
      id: 'contractAddendumName',
      numeric: true,
      disablePadding: false,
      label: 'Tên phụ lục',
      align: 'center',
      width: '300px',
    },
    {
      id: 'urlFile',
      numeric: true,
      disablePadding: false,
      label: 'File phụ lục',
      align: 'center',
      render: (row) => <ShowListFile maxWidth="200px" fileList={row?.urlFile} />,
    },
    {
      id: 'effectiveDate',
      numeric: true,
      disablePadding: false,
      label: 'Ngày bắt đầu',
      align: 'center',
      width: '130px',
      render: (row) => Utils.formatDateFn(row?.effectiveDate),
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'center',
      width: '200px',
    },
  ];

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={3}>
          <b>Số hợp đồng:</b>
          <br />
          <i style={{ color: 'blue' }}>{dataSelect?.contractNumber}</i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Mã khách hàng:</b>
          <br />
          <i style={{ color: 'blue' }}>{dataSelect?.objClientCompany?.code || ''}</i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Khách hàng:</b>
          <br />
          <i style={{ color: 'blue' }}>{dataSelect?.clientCompanyName}</i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Ngày ký:</b>
          <br />
          <i style={{ color: 'blue' }}>
            {dataSelect?.signedDate && Utils.formatDateFn(dataSelect?.signedDate)}
          </i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Ngày bắt đầu:</b>
          <br />
          <i style={{ color: 'blue' }}>
            {dataSelect?.effectiveDate && Utils.formatDateFn(dataSelect?.effectiveDate)}
          </i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Ngày kết thúc:</b>
          <br />
          <i style={{ color: 'blue' }}>
            {dataSelect?.expireDate && Utils.formatDateFn(dataSelect?.expireDate)}
          </i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Ghi chú:</b>
          <br />
          <i style={{ color: 'blue' }}>{dataSelect?.note}</i>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <b>Dịch vụ:</b>
          {dataSelect?.arrService?.map((item) => (
            <div key={item?.id}>
              <i style={{ color: 'blue' }}>{item?.name}</i>
            </div>
          ))}
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '15px' }}>
        <b>File đính kèm:</b>
        <ShowListFile maxWidth="100%" fileList={dataSelect?.arrUrlfile} />
      </Box>
      <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
        Phụ lục hợp đồng
      </Typography>
      <Table
        rows={dataSelect?.arrFileAddendum || []}
        headCells={columns}
        onPageChange={(paging) => {}}
        isHeader={false}
      />
    </Box>
  );
};

export { ContractDetail };
