import React, { useState, useEffect, useMemo } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { BsSave, BsEnvelope } from 'react-icons/bs';
import { ModalFormDetail } from 'components';
import { LoadingView } from 'components/loading-view';
import { SERVICES_ID, REQ_CHECK_PRICE_STATUS } from 'commons/constants';
import ApiServices from 'services';
import HeaderFormDetail from './header-form-detail';
import { FreightRequestForm } from './freight-service/freight-request-form';
import { InformationGeneral } from './information-general';
import { TruckingRequestForm } from './trucking-service/trucking-request-form';
import { CustomsRequestForm } from './customs-service/customs-request-form';
import {
  ContainerContent,
  ContainerContentForm,
  ErrorText,
  BtnCreateCheckPrice,
  BtnSendCheckPrice,
} from './styles';

export const FormDetailRequest = ({ selected, onCloseModal, initData, ...props }) => {
  const { FREIGHT, TRUCKING, CUSTOMS } = SERVICES_ID;
  const { CREATE_CHECK_PRICE, WAIT_RES_CHECK_PRICE } = REQ_CHECK_PRICE_STATUS;
  const [dataSelected, setDataSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [serviceIdSelected, setServiceIdSelected] = useState(null);
  const [loadingGetSubService, setLoadingGetSubService] = useState(false);
  const [subServiceSelectedList, setSubServiceSelectedList] = useState([]);
  const [loadingGetContent, setLoadingGetContent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const isSubmitting = useMemo(() => {
    return !serviceIdSelected || !dataSelected?.clientCompanyId;
  }, [serviceIdSelected, dataSelected]);

  useEffect(() => {
    if (selected?.id) {
      setDataSelected({ ...selected });
      setServiceIdSelected(selected?.serviceId);
    }
  }, [selected]);

  const onHandleCloseModal = () => {
    setSubServiceSelectedList([]);
    setServiceIdSelected(null);
    setDataSelected({});
    setIsChecked(false);
    setLoadingGetSubService(false);
    setLoading(false);
    onCloseModal();
  };

  const getCheckPricesByService = async (checkPriceId, serviceId) => {
    if (loadingGetContent) return;
    setLoadingGetContent(true);
    try {
      const { data } = await ApiServices.getCheckPricesByService(checkPriceId, serviceId);
      if (data) {
        return getSubServiceByServiceId(serviceId, data);
      }
    } catch (err) {
      console.warn('getCheckPricesByService', err);
    } finally {
      setLoadingGetContent(false);
    }
  };

  const getSubServiceByServiceId = async (serviceId, dataContent = []) => {
    if (loadingGetSubService) return;
    setLoadingGetSubService(true);
    try {
      const { data } = await ApiServices.getSubServiceByServiceId(serviceId);
      if (data) {
        const dataMapSubService = data.map((item) => {
          let newData = {
            id: item?.id,
            name: item?.name,
            contentList: {},
          };
          if (!selected?.isNew && dataContent.length > 0) {
            const ind = dataContent?.findIndex((el) => el.subServiceId === item.id);
            if (ind !== -1) {
              newData.checked = true;
              newData.contentList = dataContent[ind];
              setIsChecked(true);
            } else {
              newData.checked = false;
            }
          } else {
            newData.checked = false;
          }
          return newData;
        });
        setSubServiceSelectedList(dataMapSubService || []);
      }
    } catch (err) {
      console.warn('getSubServiceByServiceId', err);
    } finally {
      setLoadingGetSubService(false);
    }
  };

  useEffect(() => {
    if (serviceIdSelected) {
      getSubServiceByServiceId(serviceIdSelected);
      setIsChecked(false);
    }
  }, [serviceIdSelected]);

  useEffect(() => {
    if (selected && !selected?.isNew && selected?.id && selected?.serviceId) {
      getCheckPricesByService(selected?.id, selected?.serviceId);
    }
  }, [selected]);

  const handleCheckedSubService = (event, item) => {
    if (event.target.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    const ind = subServiceSelectedList.findIndex((el) => el.id === item.id);
    if (ind === -1) return;
    const newData = [...subServiceSelectedList];
    newData[ind].checked = event.target.checked;
    setSubServiceSelectedList(newData);
  };

  const onHandleSubmit = async (statusCheck) => {
    setLoading(true);
    const dataSend = [];
    let subServiceIdIsChecked = '';
    if (subServiceSelectedList && subServiceSelectedList.length > 0) {
      const data = subServiceSelectedList
        .filter((el) => el.checked)
        .map((item) => {
          const dataContent = { ...item.contentList };
          dataContent.serviceId = serviceIdSelected;
          dataContent.subServiceId = item.id;
          subServiceIdIsChecked = item.id;
          return dataContent;
        });
      dataSend.push({ ...dataSelected, statusCheck, subServiceId: subServiceIdIsChecked });
      dataSend.push([...data]);
    }
    if (selected?.isNew) {
      try {
        const res = await ApiServices.createCheckPricePostService(serviceIdSelected, dataSend);
        if (res.status === 200) {
          window.UIMessage?.success('Thêm mới thành công');
          onHandleCloseModal();
          initData();
        } else {
          window.UIMessage?.error('Thêm mới thất bại !!!');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await ApiServices.updateCheckPriceEditByService(serviceIdSelected, dataSend);
        if (res.status === 200) {
          window.UIMessage?.success('Cập nhật thành công');
          onHandleCloseModal();
          initData();
        } else {
          window.UIMessage?.error('Cập nhật thất bại !!!');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderSubService = (item) => {
    switch (serviceIdSelected) {
      case FREIGHT:
        return (
          <FreightRequestForm
            itemData={item}
            selected={selected}
            subServiceSelectedList={subServiceSelectedList}
            setSubServiceSelectedList={setSubServiceSelectedList}
          />
        );
      case TRUCKING:
        return (
          <TruckingRequestForm
            itemData={item}
            selected={selected}
            subServiceSelectedList={subServiceSelectedList}
            setSubServiceSelectedList={setSubServiceSelectedList}
          />
        );
      case CUSTOMS:
        return (
          <CustomsRequestForm
            itemData={item}
            selected={selected}
            subServiceSelectedList={subServiceSelectedList}
            setSubServiceSelectedList={setSubServiceSelectedList}
          />
        );
      default:
        break;
    }
  };

  const renderContents = (item) => {
    return (
      <ContainerContentForm key={item?.id}>
        <FormGroup key={item.feeGroupID}>
          <FormControlLabel
            sx={{ width: '30%' }}
            control={
              <Checkbox
                checked={item.checked}
                onChange={(event) => handleCheckedSubService(event, item)}
                disabled={!item?.checked && isChecked}
              />
            }
            label={item.name}
          />
          {item.checked && renderSubService(item)}
        </FormGroup>
      </ContainerContentForm>
    );
  };

  const isSubmitSendCheckPrice = useMemo(() => {
    return (
      !serviceIdSelected ||
      !dataSelected?.clientCompanyId ||
      !dataSelected?.requestDate ||
      !dataSelected?.deadline ||
      !dataSelected?.arrShareUserCode ||
      !dataSelected?.arrShareUserCode?.length > 0 ||
      !dataSelected?.arrShareDepartmentId ||
      !dataSelected?.arrShareDepartmentId?.length > 0
    );
  }, [serviceIdSelected, dataSelected]);

  const isSubmitSaveCheckPrice = useMemo(() => {
    return !serviceIdSelected || !dataSelected?.clientCompanyId;
  }, [serviceIdSelected, dataSelected]);

  return (
    <>
      <ModalFormDetail
        backgroundModal="#f5f5f5"
        open={selected}
        onCloseModal={onHandleCloseModal}
        title={selected?.isNew ? 'Tạo yêu cầu check giá' : 'Cập nhật yêu cầu check giá'}
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <InformationGeneral
          dataSelected={dataSelected}
          setServiceIdSelected={setServiceIdSelected}
          setDataSelected={setDataSelected}
          selected={selected}
        />
        <b>Nội dung yêu cầu:</b>
        {!loading && subServiceSelectedList?.length > 0 && (
          <>
            <ContainerContent>
              {!loadingGetSubService && subServiceSelectedList?.map(renderContents)}
            </ContainerContent>

            <Box sx={{ display: 'flex', py: 2 }}>
              {isSubmitting && <ErrorText>Vui lòng nhập đầy đủ thông tin</ErrorText>}
              <Box sx={{ marginLeft: 'auto' }}>
                <BtnCreateCheckPrice
                  sx={{ marginRight: '15px' }}
                  color="primary"
                  disabled={isSubmitSaveCheckPrice}
                  type="submit"
                  variant="contained"
                  startIcon={<BsSave size={16} />}
                  onClick={() => onHandleSubmit(CREATE_CHECK_PRICE)} // 0: Khởi tạo
                >
                  Lưu
                </BtnCreateCheckPrice>
                <BtnSendCheckPrice
                  color="success"
                  disabled={isSubmitSendCheckPrice}
                  type="submit"
                  variant="contained"
                  startIcon={<BsEnvelope size={20} />}
                  onClick={() => onHandleSubmit(WAIT_RES_CHECK_PRICE)} // 1: Chờ phản hồi
                >
                  Gửi yêu cầu
                </BtnSendCheckPrice>
              </Box>
            </Box>
          </>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
