import React from 'react';
import { Box, Link, IconButton, Stack } from '@mui/material';
import { BiCopyAlt } from 'react-icons/bi';
import { BsCheckCircle } from 'react-icons/bs';

export const LinkInviteGroup = ({ tokenInviteGroup }) => {
  const [copyLink, setCopyLink] = React.useState(false);
  const onHandleCopyLink = () => {
    setCopyLink(false);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_HOSTNAME}/chat/invite-group-by-token?token=${tokenInviteGroup}`,
    );
    setCopyLink(true);
  };

  return (
    <>
      <Box
        sx={{
          // width: '700px',
          minHeight: '50px',
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          marginBottom: '10px',
          display: 'flex',
          padding: '10px',
          flex: '1 1',
        }}
      >
        {/* <Link sx={{ cursor: 'pointer', wordWrap: 'break-word' }}> */}
        <p
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '400px',
            // '&::-webkit-line-clamp': '3',
            color: copyLink ? 'green' : 'blue',
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={(e) => onHandleCopyLink(e)}
        >
          {`${process.env.REACT_APP_HOSTNAME}/chat/invite-group-by-token?token=${tokenInviteGroup}`}
        </p>
        {/* </Link> */}
        <Stack spacing={0.1}>
          <IconButton
            onClick={(e) => onHandleCopyLink(e)}
            aria-label="delete"
            color={copyLink ? 'success' : 'default'}
          >
            {copyLink ? <BsCheckCircle /> : <BiCopyAlt />}
          </IconButton>
          {copyLink ? (
            <p style={{ fontSize: '13px', color: 'green' }}>Đã copy</p>
          ) : (
            <p style={{ fontSize: '13px', color: 'black' }}>Copy</p>
          )}
        </Stack>
      </Box>
    </>
  );
};
