import React, { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection, ComboBox } from 'components';
import { Box, Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import {
  RESPONSE_STATUSES,
  TYPE_CLIENT,
  CLIENT_GIFT_TYPE_OPTIONS_WITH_CLIENT_TYPE,
} from 'commons/constants';

const FORM_WIDTH = '600px';
export const FormDetail = ({ selected, setSelected, reloadData, ...props }) => {
  // ninit List
  const [customerList, setCustomerList] = useState([]);

  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [clientCompanyName, setClientCompanyName] = useState('');
  const [clientType, setClientType] = useState(0);

  const [giftType, setGiftType] = useState('');

  const checkSubmit = useMemo(() => {
    return !clientCompanyId || !giftType;
  }, [clientCompanyId, giftType]);

  const getClientCustomer = async () => {
    try {
      const res = await ApiServices.getClientCompanies();
      if (res?.data) {
        setCustomerList(res?.data || []);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  useEffect(() => {
    getClientCustomer();
  }, []);

  useEffect(() => {
    if (selected?.isNew) {
      setClientCompanyId(null);
      setClientCompanyName('');
      setClientType(0);
    } else {
      // customer
      if (selected?.clientCompanyId && customerList?.length > 0) {
        const findCustomer = customerList?.find((item) => item.id === selected.clientCompanyId);
        if (findCustomer) {
          setClientCompanyId(findCustomer);
          setClientCompanyName(findCustomer?.name);
        } else {
          setClientCompanyId('');
          setClientCompanyName('');
        }
      }

      if (selected?.typeGift) {
        setGiftType(selected?.typeGift);
      } else {
        setGiftType('');
      }
    }
  }, [customerList, selected]);

  const handleChangeCustomer = useCallback(
    (_, newValue) => {
      setClientCompanyId(newValue || null);
      if (newValue) {
        setClientCompanyName(newValue?.name || newValue?.code);
        if (newValue?.arrClientCareHistory?.length > 0) {
          setClientType(1);
        } else {
          setClientType(0);
        }
        setGiftType('');
      } else {
        setClientCompanyName('');
        setClientType(0); // 0
      }
    },
    [clientCompanyId],
  );

  const handleChangeGiftType = useCallback(
    (event) => {
      const value = event.target.value;
      setGiftType(value);
    },
    [giftType],
  );

  const createFn = async (values, { setSubmitting }) => {
    const dataSend = {
      clientCompanyId: clientCompanyId?.id,
      realReceiver: values?.realReceiver,
      giftSendDate: values?.giftSendDate,
      typeClientCompany: 0,
      typeGift: giftType,
      priceGift: values?.priceGift || 0,
      note: values?.note || '',
    };

    setSubmitting(true);
    return ApiServices.createClientGift(dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const updateFn = async (values, { setSubmitting }) => {
    const idUpdate = values?.id;
    if (!idUpdate) return;

    const dataSend = {
      id: idUpdate,
      code: values?.code,
      clientCompanyId: clientCompanyId?.id,
      realReceiver: values?.realReceiver,
      giftSendDate: values?.giftSendDate,
      typeClientCompany: 0,
      typeGift: giftType,
      priceGift: values?.priceGift || 0,
      note: values?.note || '',
    };

    setSubmitting(true);
    return ApiServices.updateClientGift(idUpdate, dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Cập nhật thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
      >
        <Formik
          initialValues={{
            code: '',
            clientCompanyId: '', // Cty khiếu nại ClientCompanyId,ClientCompanyName
            realReceiver: '', // Nguoi nhan
            giftSendDate: '', // ngay tang
            typeClientCompany: '', // Loai KH
            priceGift: 0, // Gia tri
            note: '', // Ghi chú
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            giftSendDate: Yup.string().required('Vui lòng nhập thời gian tặng'),
            realReceiver: Yup.string().required('Vui lòng nhập người nhận'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Box>
                <Grid container spacing={1}>
                  {!selected?.isNew && (
                    <Grid item xs={12} sm={12} md={12}>
                      <TextInput
                        label="Mã quà tặng"
                        name="code"
                        value={values?.code}
                        // readOnly={true}
                        disabled
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      error={!clientCompanyId}
                      helperText={!clientCompanyId && 'Vui lòng chọn khách hàng'}
                      options={customerList}
                      label="Mã khách hàng"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="code"
                      value={clientCompanyId}
                      onChange={handleChangeCustomer}
                      disabled={!selected?.isNew}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      label="Khách hàng"
                      name="clientCompanyName"
                      value={clientCompanyName}
                      // readOnly={true}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      label="Loại khách hàng"
                      name="clientCompanyName"
                      value={TYPE_CLIENT[clientType] || ''}
                      // readOnly={true}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      required
                      error={Boolean(touched.realReceiver && errors.realReceiver)}
                      helperText={touched.realReceiver && errors.realReceiver}
                      label="Người nhận"
                      name="realReceiver"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.realReceiver}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Selection
                      error={!giftType}
                      helperText={!giftType && 'Vui lòng chọn khách hàng'}
                      label="Loại quà tặng"
                      name="typeGift"
                      onChange={handleChangeGiftType}
                      value={giftType}
                      dataArrObject={CLIENT_GIFT_TYPE_OPTIONS_WITH_CLIENT_TYPE[clientType || 0]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      label="Giá trị"
                      name="priceGift"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priceGift}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      required
                      error={Boolean(touched.giftSendDate && errors.giftSendDate)}
                      helperText={touched.giftSendDate && errors.giftSendDate}
                      label="Thời gian tặng"
                      name="giftSendDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.giftSendDate}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextInput
                      multiline={true}
                      rows={2}
                      label="Ghi chú"
                      name="note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ py: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  sx={{
                    padding: '10px 30px',
                    textTransform: 'none',
                  }}
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={checkSubmit}
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
