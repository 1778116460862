import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { Add as AddIcon, Attachment as AttachmentIcon } from '@mui/icons-material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';

function Contracts() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getContracts();
      setData(data || []);
    } catch (err) {
      console.warn('initData - companies', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.deleteContract(row.id);
      window.UIMessage?.success('Xoá thành công');
      initData();
    } catch (err) {
      window.UIMessage?.error('Xoá thất bại');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };

  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: true,
      label: 'Mã',
      width: 10,
      sort: true,
      align: 'left',
    },
    {
      id: 'contractNumber',
      numeric: true,
      disablePadding: false,
      label: 'Số hợp đồng',
      align: 'left',
    },
    {
      id: 'signedDate',
      isDateFormat: true,
      disablePadding: false,
      label: 'Ngày ký',
      align: 'left',
    },
    {
      id: 'expireDate',
      isDateFormat: true,
      disablePadding: false,
      label: 'Ngày hết hạn',
      align: 'left',
    },
    {
      id: 'extensionDate',
      isDateFormat: true,
      disablePadding: false,
      label: 'Ngày Gia hạn HĐ',
      align: 'left',
    },
    {
      id: 'urlContractFDF',
      disablePadding: false,
      label: 'file',
      align: 'left',
      render: (row) => (
        <>
          {row.urlContractFDF && (
            <a
              target="_blank"
              href={`${process.env.REACT_APP_API_URL}/${row.urlContractFDF}`}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100px',
              }}
            >
              <p
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100px',
                }}
              >
                {row?.objFileinfo?.name}
              </p>
            </a>
          )}
        </>
      ),
    },
    {
      id: 'clientId',
      disablePadding: false,
      label: 'Khách Hàng',
      align: 'left',
      render: (row) => <>{row?.clientObj?.fullName}</>,
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'left',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Thao tác',
      width: '10px',
      fixed: 'right',
      fixed: 'right',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data}
        headCells={headCells}
        onClickDetail={(row) => {
          setSelected(row);
        }}
        title="Danh Sách Hợp Đồng"
        loading={loading}
        renderHeader={() => {
          return (
            <>
              <SearchInput sx={{ mr: 2 }} />
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  mr: 2,
                }}
              >
                {/* <Stack direction="row" spacing={1}> */}
                <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                  <BsFilter />
                </IconButton>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowDownCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px',
                    marginLeft: '8px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '0px 8px',
                  }}
                >
                  Nhập excel
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowUpCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px',
                    marginLeft: '8px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '0px 8px',
                  }}
                >
                  Tải excel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<BsPlus />}
                  sx={{
                    borderRadius: '20px',
                    // padding: "7px 15px",
                    textTransform: 'none',
                    marginLeft: '8px',
                    color: '#FFFFFF',
                    backgroundColor: '#21409A',
                    '.MuiButton-endIcon': {
                      marginLeft: '0px',
                    },
                    padding: '0px 10px',
                  }}
                  onClick={() => setSelected({ isNew: true })}
                >
                  Thêm mới
                </Button>
                {/* </Stack> */}
              </Box>
            </>
          );
        }}
      />
      <FormDetail {...{ selected, setSelected }} reload={initData} />
    </Box>
  );
}
export { Contracts };
