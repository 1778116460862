import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box } from '@mui/material';
import { BsPlus } from 'react-icons/bs';
import ApiServices from 'services';
import { Columns } from './columns';
import { ContainerHeader, ButtonAdd } from './styles';
import Utils from 'commons/utils';

function SetupSurchargePage() {
  const [surchargeList, setSurchargeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const reloadRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  useEffect(() => {
    if (loading) return;
    if (query) {
      clearTimeout(reloadRef.current);
      reloadRef.current = setTimeout(() => initData(), 100);
    }
  }, [query]);

  const onSearchInputChange = (value, key, searchArray = false) => {
    clearTimeout(searchInputRef.current);
    if (searchArray) {
      let newQuery = { ...query };
      if (!value) {
        delete newQuery.searchArray[key];
      } else {
        newQuery.searchArray = { ...newQuery.searchArray, [key]: value };
      }
      searchInputRef.current = setTimeout(() => setQuery(newQuery), 500);
    } else {
      searchInputRef.current = setTimeout(
        () => setQuery((prev) => ({ ...prev, [key]: value })),
        500,
      );
    }
  };
  const initData = React.useCallback(
    async (pagination = {}) => {
      setLoading(true);
      try {
        const { data } = await ApiServices.getPagingSurcharges({ ...query, ...pagination });
        if (totalRecords !== data?.totalRecords) setTotalRecords(data?.totalRecords);
        setSurchargeList(data?.data || []);
      } catch (err) {
        console.warn('initData - getPagingSurcharges', err);
      }
      setLoading(false);
    },
    [query, totalRecords],
  );

  const deleteContTypeItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteSurcharge(row.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.log(err);
    }
    setLoading(false);
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <SearchInput sx={{ mr: 2 }} maxWidth={400} />
      </ContainerHeader>
      <ButtonAdd
        variant="contained"
        size="small"
        startIcon={<BsPlus />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const handleSelecteRow = (row) => {
    const dataSelect = { ...row };
    dataSelect.expirydate = Utils.checkIsDate(row.expirydate)
      ? Utils.convertToDateFormat(row.expirydate)
      : '';
    setSelected(dataSelect);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={surchargeList}
        headCells={Columns(deleteContTypeItem, onSearchInputChange)}
        totalRecords={totalRecords}
        onPageChange={(paging) => {
          setQuery((prev) => ({ ...prev, ...paging }));
        }}
        onClickDetail={(row) => handleSelecteRow(row)}
        title="Bảng thiết lập phụ phí"
        loading={loading}
        renderHeader={renderHeader}
      />
      <FormDetail {...{ selected, setSelected, initData }} />
    </Box>
  );
}
export { SetupSurchargePage };
