import * as React from 'react';
import { Typography, Divider, Button, IconButton, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { fetchUsers } from 'store/reducers/users/action';
import ChatServices from 'services/chat-services';
import { fetchPersonalChanel, setSelectedChanel } from 'store/reducers/chat-chanel/action';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import storage from 'storage';
import ChanelContent from './chanel-container';

export default function UserChanel({ IconNew, onClickChanel }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { users, onlineUsers } = useSelector((state) => state.users);
  const { personalChanels } = useSelector((state) => state.chatChanel);
  const { selectedChanel } = useSelector((state) => state.chatChanel);

  const userFetchRef = React.useRef(true);

  const renDerOnlineStatus = (user) => {
    const isOnline = -1 !== onlineUsers.indexOf(user.code);
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant={isOnline ? 'dot' : ''}
        sx={{ mr: '12px' }}
      >
        <Avatar
          alt={user.name}
          src={user?.urlAvatar && `${process.env.REACT_APP_API_URL}/${user?.urlAvatar}`}
        />
      </StyledBadge>
    );
  };
  // React.useEffect(() => {
  //   if (userFetchRef.current) {
  //     dispatch(fetchUsers());
  //   }
  //   userFetchRef.current = false;
  // }, []);
  const getChanelByUserId = (userCode) => {
    if (userCode === storage.user.Code) {
      let isAvailable = personalChanels.findIndex(
        (item) =>
          item.members?.length == 1 && item.members?.findIndex((el) => el.code == userCode) !== -1,
      );

      if (isAvailable !== -1) {
        return personalChanels[isAvailable];
      }
      return null;
    }
    let isExist = personalChanels.findIndex(
      (item) => item.members?.findIndex((el) => el.code == userCode) !== -1,
    );

    if (isExist !== -1) {
      return personalChanels[isExist];
    }
    return null;
  };
  const onUserClick = async (userCode) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (userCode === storage.user.Code) {
      let isAvailable = personalChanels.findIndex(
        (item) =>
          item.members?.length == 1 && item.members?.findIndex((el) => el.code == userCode) !== -1,
      );
      if (isAvailable === -1) {
        return ChatServices.createChanel({
          name: 'person',
          members: [
            {
              code: userCode,
            },
          ],
          isPersonal: true,
        })
          .then((response) => {
            dispatch(fetchPersonalChanel());
            onClickChanel(response.data?.results[0]);
          })
          .catch((err) => console.error('createChanel - error', err))
          .finally(() => setLoading(false));
      }
      onClickChanel(personalChanels[isAvailable]);

      return setLoading(false);
    }
    let isExist = personalChanels.findIndex(
      (item) => item.members?.findIndex((el) => el.code == userCode) !== -1,
    );
    if (isExist !== -1) {
      onClickChanel(personalChanels[isExist], true);
      return setLoading(false);
    } else {
      return ChatServices.createChanel({
        name: 'person',
        members: [
          {
            code: userCode,
          },
        ],
        isPersonal: true,
      })
        .then((response) => {
          dispatch(fetchPersonalChanel());
          onClickChanel(response.data?.results[0], true);
        })
        .catch((err) => console.error('createChanel - error', err))
        .finally(() => setLoading(false));
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      {users.map((user) => {
        const chanel = getChanelByUserId(user.code) || {};
        chanel.name = user.name || user.code;
        return (
          <ChanelItem
            key={`ChanelItem-${user.id}`}
            isActive={selectedChanel?._id && chanel?._id == selectedChanel._id}
            onClick={() => onUserClick(user.code)}
          >
            <ChanelContent chanel={chanel}>{renDerOnlineStatus(user)}</ChanelContent>
          </ChanelItem>
        );
      })}
    </Box>
  );
}
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.success2.main,
    color: theme.palette.success2.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
const ChanelItem = styled(Box)(({ isActive, theme }) => ({
  ...theme.typography.body2,
  paddingBlock: 8,
  margin: '5px',
  cursor: 'pointer',
  paddingInline: 8,
  borderRadius: 8,
  position: 'relative',
  width: '100%',
  '&:hover': {
    background: theme.palette.action.hover,
  },
  ...(isActive && {
    background: theme.palette.background.messageActiveColor,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }),
}));
