// 'success' 'error' 'warning' 'info'
export default {
  FETCH_UNITS: 'fetch_units',
  CLEAR_UNITS: 'clear_units',
  FETCH_CURRENCIES: 'fetch_currencies',
  CLEAR_CURRENCIES: 'clear_currencies',
  FETCH_PORTS_BY_TYPE: 'fetch_ports_by_type',
  CLEAR_PORTS_BY_TYPE: 'clear_ports_by_type',
  FETCH_CARRIERS_BY_TYPE: 'fetch_carriers_by_type',
  CLEAR_CARRIERS_BY_TYPE: 'clear_carriers_by_type',
};
