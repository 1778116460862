import React from 'react';
import { Box, Tab, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { SeaFcl } from './sea-fcl';
import { SeaLcl } from './sea-lcl';
import { Air } from './air';
import { SUB_SERVICES_ID } from 'commons/constants';

const SUB_SERVICES_OF_FREIGHT = [
  {
    label: 'Đường biển FCL',
    value: SUB_SERVICES_ID.SEA_FCL,
  },
  {
    label: 'Đường biển LCL',
    value: SUB_SERVICES_ID.SEA_LCL,
  },
  {
    label: 'Hàng không',
    value: SUB_SERVICES_ID.AIR,
  },
];

export const FreightService = () => {
  const { SEA_FCL, SEA_LCL, AIR } = SUB_SERVICES_ID;
  const [tabIndex, setTabIndex] = React.useState(SEA_FCL);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={tabIndex}>
        <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
          {SUB_SERVICES_OF_FREIGHT.map((item) => (
            <TabItem key={`${item.value}`} label={item.label} value={item.value} />
          ))}
        </TabList>

        <TabPanel value={SEA_FCL} sx={{ padding: '5px 0px' }}>
          <SeaFcl />
        </TabPanel>
        <TabPanel value={SEA_LCL} sx={{ padding: '5px 0px' }}>
          <SeaLcl />
        </TabPanel>
        <TabPanel value={AIR} sx={{ padding: '5px 0px' }}>
          <Air />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
