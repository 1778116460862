import actionTypes from './type';

const initialState = {
  submit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CONFIRM:
      console.log({ action });
      return {
        ...action,
      };
    case actionTypes.CLEAR:
      return {
        submit: null,
      };
    default:
      return state;
  }
}
