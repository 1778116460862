import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(({ item, index, setValues, surChargeListByFeeGroupId }, ref) => {
  React.useImperativeHandle(ref, () => ({
    getAlert() {
      alert('getAlert from Child');
    },
  }));

  const renderSubChargeLabel = React.useCallback(
    (surchargeId) => {
      const surcharge = surChargeListByFeeGroupId?.find(
        (item) => item?.surchargeId === surchargeId,
      );
      return surcharge?.surchargeName;
    },
    [item?.surchargeId, surChargeListByFeeGroupId],
  );

  return (
    <Container>
      <StyledTextSubCharge
        label="Mô tả"
        variant="outlined"
        type="text"
        size="small"
        multiline
        value={renderSubChargeLabel(item?.surchargeId)}
      />
      <StyledTextFieldPrice
        label="Local"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
          disabled: true,
        }}
        value={item?.localPrice}
      />
      <StyledTextFieldPrice
        label="Overseas"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
          disabled: true,
        }}
        value={item?.overseasPrice}
      />
      <StyledTextField label="VAT" variant="outlined" type="text" size="small" value={item?.vat} />
    </Container>
  );
});
export default InputItem;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const StyledTextSubCharge = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '400px',
}));
const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '120px',
  marginRight: '10px',
}));
