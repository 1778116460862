import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(({ item, index, setValues, disabled, locationVTList }, ref) => {
  const [quantity, setQuantity] = React.useState(0);
  const [makeup, setMakeup] = React.useState(0);
  const [buyPrice, setBuyPrice] = React.useState(0);

  React.useImperativeHandle(ref, () => ({
    getAlert() {
      alert('getAlert from Child');
    },
  }));
  React.useEffect(() => {
    setQuantity(item.quantity);
    setMakeup(item?.makeup);
    setBuyPrice(item?.buyPrice);
  }, [item]);

  const renderLabelLocationVT = React.useCallback(
    (arrLocationVTId) => {
      const arrLocationVT = locationVTList.filter((item) => arrLocationVTId.includes(item.id));
      return arrLocationVT.map((item) => item.name).join(', ');
    },
    [locationVTList, item?.arrLocationVTId],
  );

  const handleChangeQuantity = React.useCallback(
    (e, price) => {
      const value = +e.target.value;
      setQuantity(value);
      setBuyPrice((price + (price * makeup) / 100) * value);
      setValues((prev) => {
        prev[index].quantity = value;
        prev[index].buyPrice = (price + (price * makeup) / 100) * value;
        return [...prev];
      });
    },
    [quantity],
  );

  const handleMakeUp = React.useCallback(
    (e, price) => {
      const value = +e.target.value;
      setMakeup(value);
      setBuyPrice((price + (price * value) / 100) * quantity);
      setValues((prev) => {
        prev[index].makeup = value;
        prev[index].buyPrice = (price + (price * value) / 100) * quantity;
        return [...prev];
      });
    },
    [makeup],
  );

  const handleBuyPrice = React.useCallback(
    (e, price, quantity) => {
      const value = +e.target.value;
      setBuyPrice(value);
      setMakeup(((value / quantity - price) / price) * 100);
      setValues((prev) => {
        prev[index].makeup = ((value / quantity - price) / price) * 100;
        prev[index].buyPrice = value;
        return [...prev];
      });
    },
    [buyPrice],
  );

  return (
    <Container>
      <StyledTextLocation
        label="Địa điểm vận tải"
        variant="outlined"
        type="text"
        size="small"
        value={renderLabelLocationVT(item?.arrLocationVTId || [])}
      />
      <StyledTextFieldPrice
        label="Đơn giá"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
          disabled: true,
        }}
        value={item?.price}
      />
      <StyledTextFieldPrice
        label="Số lượng"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        value={quantity}
        onChange={(event) => handleChangeQuantity(event, item?.price)}
        disabled={disabled}
      />
      <TextFieldMakeUp
        label="%Make Up"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        value={makeup}
        onChange={(event) => handleMakeUp(event, item?.price)}
        disabled={disabled}
      />
      <TextFieldBuyPrice
        label="Giá bán"
        variant="outlined"
        type="text"
        size="small"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        value={buyPrice}
        onChange={(event) => handleBuyPrice(event, item?.price)}
        disabled={disabled}
      />
      <StyledTextField
        label="Đơn vị"
        variant="outlined"
        type="text"
        size="small"
        value={item?.unitCode}
      />
    </Container>
  );
});
export default InputItem;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const StyledTextLocation = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '400px',
}));
const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '120px',
  marginRight: '10px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: '#FCF2EF',
  marginRight: '10px',
}));
const TextFieldBuyPrice = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  marginRight: '10px',
}));
