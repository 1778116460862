import actionTypes from './type';
import ApiServices from 'services';

export function selectServiceToQuote(item) {
  return (dispatch) => {
    return dispatch({ data: item, type: actionTypes.SELECT_SERVICE_TO_QUOTE });
  };
}
export function updateServiceToQuote(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.UPDATE_SERVICE_TO_QUOTE });
  };
}
export function clearServiceToQuote() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_SERVICE_TO_QUOTE });
  };
}

export function selectObjQuotes(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.SELECT_OBJ_QUOTES });
  };
}
export function updateObjectQuotes(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.UPDATE_OBJ_QUOTES });
  };
}
export function clearObjectQuotes() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_OBJ_QUOTES });
  };
}
// Trucking
export function selectTruckingService(item) {
  return (dispatch) => {
    return dispatch({ data: item, type: actionTypes.SELECT_TRUCKING_SERVICE });
  };
}
export function clearTruckingService() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_TRUCKING_SERVICE });
  };
}
export function updateTruckingService(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.UPDATE_TRUCKING_SERVICE });
  };
}
// Customs
export function selectCustomsService(item) {
  return (dispatch) => {
    return dispatch({ data: item, type: actionTypes.SELECT_CUSTOMS_SERVICE });
  };
}
export function clearCustomsService() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_CUSTOMS_SERVICE });
  };
}
export function updateCustomsService(data) {
  return (dispatch) => {
    return dispatch({ data, type: actionTypes.UPDATE_CUSTOMS_SERVICE });
  };
}

export function clearAllDataQuote() {
  return (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_ALL_DATA_QUOTE });
  };
}
