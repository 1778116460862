import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchUnits,
  fetchCurrencies,
  fetchPortsByType,
  fetchCarriersByType,
} from 'store/reducers/category-data/action';
import ApiServices from 'services';

import { SUB_SERVICES_ID } from 'commons/constants';
import { ResCheckPriceItems } from './res-check-price-items';
const SEA_PORT_TYPE = 0; // Cảng Hãng tàu
const AIR_PORT_TYPE = 1; // Cảng hàng không

export const FreightResponseForm = ({ selected, itemData }) => {
  const dispatch = useDispatch();
  const [loadingInit, setLoadingInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFeeGroupList, setDataFeeGroupList] = useState([]);

  useEffect(() => {
    const getDataInit = async () => {
      setLoadingInit(true);
      await dispatch(fetchUnits());
      await dispatch(fetchCurrencies());
      await dispatch(
        fetchPortsByType(itemData?.id === SUB_SERVICES_ID.AIR ? AIR_PORT_TYPE : SEA_PORT_TYPE),
      );
      await dispatch(
        fetchCarriersByType(itemData?.id === SUB_SERVICES_ID.AIR ? AIR_PORT_TYPE : SEA_PORT_TYPE),
      );
      setLoadingInit(false);
    };
    getDataInit();
  }, []);
  const getSurchargeByFeeGroupSS = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(itemData?.id, true);
      if (data) {
        setDataFeeGroupList(data);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (itemData) {
      getSurchargeByFeeGroupSS();
    }
  }, [itemData]);

  return (
    !loadingInit && (
      <ResCheckPriceItems
        itemData={itemData}
        selected={selected}
        dataFeeGroupList={dataFeeGroupList}
      />
    )
  );
};
