import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      setValues,
      error,
      disabledField,
      keyArray,
      keyNameOfArray,
      disabled,
      isSeaLcl,
      isAir,
    },
    ref,
  ) => {
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setArrayCont(item[keyArray] || []);
    }, [item]);

    const onChangeCont = React.useCallback(
      (e, indContType, keyChange, price) => {
        const value = +e.target.value;
        setArrayCont((prev) => {
          if (keyChange === 'price') {
            prev[indContType].price = value;
          }
          if (keyChange === 'makeup') {
            // Gia ban = Gia mua + gia mua * makeup
            if (isAir) {
              prev[indContType].buyPrice = price + value;
            } else {
              prev[indContType].buyPrice = price + (price * value) / 100;
            }
            prev[indContType].makeup = value;
          }
          if (keyChange === 'buyPrice') {
            // Gia ban = Gia mua + gia mua * makeup
            if (isAir) {
              prev[indContType].makeup = value - price;
            } else {
              prev[indContType].makeup = ((value - price) / price) * 100;
            }
            prev[indContType].buyPrice = value;
          }
          return [...prev];
        });

        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    return (
      <Container>
        <Box sx={{ width: '300px', marginRight: '10px' }}>
          <StyledTextSubCharge
            label="Tên phụ phí"
            variant="outlined"
            type="text"
            size="small"
            value={item?.surchargeName}
          />
        </Box>
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`cont-${indContType}`}>
            <StyledTextFieldPrice
              label={el[keyNameOfArray]}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.price}
              error={!el?.price}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`MakeUp-${indContType}`}>
            <TextFieldMakeUp
              label={`%Make Up ${!isSeaLcl ? el[keyNameOfArray] : ''}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => onChangeCont(e, indContType, 'makeup', el?.price)}
              value={el?.makeup}
              disabled={disabled}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`Price-${indContType}`}>
            <TextFieldPriceCont
              label={`Giá bán ${!isSeaLcl ? el[keyNameOfArray] : ''}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => onChangeCont(e, indContType, 'buyPrice', el?.price)}
              value={el?.buyPrice}
              error={!el?.buyPrice}
              disabled={disabled}
            />
            {!el?.buyPrice && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
        ))}
        <StyledTextField
          label="Đơn vị tính"
          variant="outlined"
          type="text"
          size="small"
          value={item?.unitCode}
        />
        <StyledTextField
          label="Remark"
          variant="outlined"
          type="text"
          size="small"
          value={item?.remark}
          error={error}
          disabled={disabledField}
        />
      </Container>
    );
  },
);
export default InputItem;

const StyledTextSubCharge = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '300px',
}));
const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '120px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: '#FCF2EF',
}));
const TextFieldPriceCont = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
