import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, Stack, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChatServices from 'services/chat-services';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/SearchOutlined';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AddChanel } from './add-chanel';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchChanel,
  setSelectedChanel,
  fetchPersonalChanel,
  updateChanel,
} from 'store/reducers/chat-chanel/action';
import { LoadingView, LoadingContainer } from 'components';
import UserChanel from './user-chanel';
import storage from 'storage';
import ChanelContent from './chanel-container';

export const Chanel = () => {
  const [collapseChanel, setCollapseChanel] = useState(true);
  const [collapsePersonal, setCollapsePersonal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalChanel, setModalChanel] = useState(false);
  const dispatch = useDispatch();
  const { chanels } = useSelector((state) => state.chatChanel);

  const { selectedChanel } = useSelector((state) => state.chatChanel);

  const initial = useRef(true);
  const initData = async () => {
    setLoading(true);
    initial.current = false;
    await dispatch(fetchChanel());
    await dispatch(fetchPersonalChanel());
    setLoading(false);
  };
  const onClickChanel = async (chanel) => {
    const userMember = chanel.members.find((u) => u.code == storage.user.Code);
    if (!chanel.messages?.length) {
      return dispatch(setSelectedChanel(chanel));
    }
    if (userMember?.lastReadMessage && chanel.messages[0].ts <= userMember.lastReadMessage) {
      return dispatch(setSelectedChanel(chanel));
    }
    return ChatServices.readMessage(chanel.messages[0]._id)
      .then((response) => {
        if (response.data?.code == 'success') {
          const chanelData = response.data.results[0];
          chanelData.messages = chanel?.messages;
          dispatch(updateChanel(chanelData));
          dispatch(setSelectedChanel(chanelData));
        }
      })
      .catch((err) => {
        console.warn('api - readMessage, error: ', err);
        dispatch(setSelectedChanel(chanel));
      });
  };

  return (
    <ChanelContainer sx={{ flexGrow: 0, p: '8px' }}>
      <LoadingView loading={loading} />
      <GroupWrapper>
        <TitleWrapper>
          <IconButton onClick={() => setCollapseChanel((prev) => !prev)}>
            {collapseChanel ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant="h7" component={'span'} sx={{ textTransform: 'uppercase', flex: 1 }}>
            <b>Nhóm</b>
          </Typography>
          <IconButton onClick={() => setModalChanel((prev) => !prev)}>
            <AddIcon />
          </IconButton>
        </TitleWrapper>
        <Stack>
          {collapseChanel &&
            chanels.map((chanel) => (
              <ChanelItem
                key={`chanel-data-${chanel._id}`}
                isActive={selectedChanel?._id && chanel?._id == selectedChanel._id}
                onClick={() => onClickChanel(chanel)}
              >
                <ChanelContent chanel={chanel} />

                {/* {chanel?._id != selectedChanel?._id && <Divider sx={{ marginTop: '5px' }} />} */}
              </ChanelItem>
            ))}
        </Stack>
      </GroupWrapper>
      <GroupWrapper sx={{ mt: '20px', flexGrow: 0 }}>
        <TitleWrapper>
          <IconButton onClick={() => setCollapsePersonal((prev) => !prev)}>
            {collapsePersonal ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant="h7" component={'span'} sx={{ textTransform: 'uppercase', flex: 1 }}>
            <b>Cá nhân</b>
          </Typography>
          <IconButton>
            <SearchIcon />
          </IconButton>
        </TitleWrapper>
        {collapsePersonal && <UserChanel IconNew={NewMessageIcon} onClickChanel={onClickChanel} />}
      </GroupWrapper>
      <AddChanel open={modalChanel} setOpen={setModalChanel} />
    </ChanelContainer>
  );
};

const GroupWrapper = styled(Box)(({ theme }) => ({
  boxShadow: theme.palette.messageBoxShadow,
  borderRadius: '12px',
  padding: '12px',
  backgroundColor: '#FFFFFF',
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
}));
const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '-10px',
}));
const NewMessageIcon = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  width: 10,
  height: 10,
  borderRadius: 5,
  background: theme.palette.primary.main,
  marginRight: 10,
}));
const ChanelContainer = styled(Box)(({ theme }) => ({
  // ...theme.typography.body2,
  color: theme.palette.text.secondary,
  position: 'relative',
  height: 'calc(100vh - 86px)',
  overflowY: 'hidden',
  '&:hover': {
    overflowY: 'auto',
  },
}));
const ChanelItem = styled(Box)(({ theme, isActive }) => ({
  // ...theme.typography.body2,
  paddingBlock: 12,
  marginInline: 4,
  marginBlock: 4,
  paddingInline: 8,
  cursor: 'pointer',
  borderRadius: 8,
  '&:hover': {
    background: theme.palette.action.hover,
  },
  ...(isActive && {
    background: theme.palette.background.messageActiveColor,
    paddingInline: 8,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }),
}));
