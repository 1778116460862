import React from 'react';
import { IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { RenderAreaAndLocationFrom, RenderAreaAndLocationTo } from 'components/custom-location';

const RenderItemSelectedList = ({ item, isTruckingOrCustom = false, onDelete }) => {
  return (
    <List dense={false} sx={{ width: '450px' }}>
      <ListItem
        secondaryAction={
          <IconButton edge="end" aria-label="delete" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemText
          primary={
            <b>
              {item?.serviceName} - {item?.subServiceName}
            </b>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <b style={{ color: 'blue' }}>Mã: {item?.code}</b>
              </Typography>
              {isTruckingOrCustom && (
                <>
                  <br />
                  <RenderAreaAndLocationFrom
                    areaFrom={item?.objAreaFrom}
                    locationFrom={item?.objLocationFrom}
                    suffixLabel="đi"
                  />
                  <RenderAreaAndLocationTo
                    areaTo={item?.objAreaTo}
                    locationTo={item?.objLocationTo}
                    suffixLabel="đến"
                  />
                </>
              )}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
};

export { RenderItemSelectedList };
