import React, { useState, useEffect } from 'react';
import { Box, Badge, Collapse, IconButton } from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { BsPlusCircle } from 'react-icons/bs';
import { ModalFormDetail, ComboBox } from 'components';
import { LoadingView } from 'components/loading-view';
import ApiServices from 'services';
import { GeneralInformation } from './general-information';
import { RenderDepartment } from './render-department';
import { RenderUserAccount } from './render-user-account';
import { ContainerHeaderForm, ButtonAdd } from '../styles';
import Storage from 'storage';
import { RESPONSE_STATUSES } from 'commons/constants';

export const FormDetail = ({ selected, onCloseModal, initData, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState([]);
  const [userAccount, setUserAccount] = useState([]);
  const [userAccountList, setUserAccountList] = useState([]);
  const [userAccountSelected, setUserAccountSelected] = useState([]);
  const [openDepartment, setOpenDepartment] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);

  const handleOpenItemDepartment = () => {
    setOpenDepartment(!openDepartment);
  };
  const handleOpenItemAccount = () => {
    setOpenAccount(!openAccount);
  };

  const getDataDepartment = () => {
    setLoading(true);
    return ApiServices.getDepartmentByCompanyId(Storage?.user?.CompanyId)
      .then((res) => {
        console.log({ res });
        if (res?.data) {
          let original = [];
          let isSelected = [];
          res?.data?.map((item) => {
            if (selected?.arrShareDepartmentId?.includes(item.id)) {
              return isSelected.push(item);
            }
            return original.push(item);
          });
          setDepartmentList(original);
          setDepartmentSelected(isSelected);
        }
      })
      .catch((err) => {
        console.log('Get Department:', err);
      })
      .finally(() => setLoading(false));
  };
  const getDataUserAcount = () => {
    setLoading(true);
    return ApiServices.getUsers()
      .then((res) => {
        console.log({ res });
        if (res?.data) {
          let original = [];
          let isSelected = [];
          res?.data?.map((item) => {
            if (selected?.arrShareUserCode?.includes(item.code)) {
              return isSelected.push(item);
            }
            return original.push(item);
          });
          setUserAccountList(original);
          setUserAccountSelected(isSelected);
        }
      })
      .catch((err) => {
        console.log('Get UserAcount:', err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selected?.id) {
      getDataDepartment();
      getDataUserAcount();
    }
  }, [selected]);

  const onHandleCloseModal = () => {
    setLoading(false);
    onCloseModal();
  };

  const handleChangeDepartment = (_, newValue) => {
    setDepartments(newValue);
  };
  const handleChangeUserAccount = (_, newValue) => {
    setUserAccount(newValue);
  };
  const handleAddDepartment = () => {
    setLoading(true);
    const lateDepartmentData = [...departments, ...departmentSelected];
    const departmentId = lateDepartmentData.map((department) => department.id);
    const departmentCode = lateDepartmentData.map((department) => department.code);
    ApiServices.updateClientSupportBoard(selected?.id, {
      arrDepartmentId: departmentId,
      arrDepartmentCode: departmentCode,
    })
      .then((res) => {
        if (res?.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm phòng ban thành công');
          setDepartmentSelected(lateDepartmentData);
          const filterDataFinal = departmentList?.filter((item) => !departmentId.includes(item.id));
          setDepartmentList(filterDataFinal);
          initData?.();
        }
      })
      .catch((err) => console.log('Thêm phòng ban thất bại', err))
      .finally(() => setDepartments([]));
    setLoading(false);
  };
  const handleAddUserAccount = () => {
    setLoading(true);
    const lateUserAccountData = [...userAccount, ...userAccountSelected];
    const userAccountId = lateUserAccountData.map((user) => user.id);
    const userAccountCode = lateUserAccountData.map((user) => user.code);
    ApiServices.updateClientSupportBoard(selected?.id, {
      arrUserCode: userAccountCode,
    })
      .then((res) => {
        if (res?.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm tài khoản thành công');
          setUserAccountSelected(lateUserAccountData);
          const filterDataFinal = userAccountList?.filter(
            (item) => !userAccountId.includes(item.id),
          );
          setUserAccountList(filterDataFinal);
          initData?.();
        }
      })
      .catch((err) => console.log('Thêm tài khoản thất bại', err))
      .finally(() => setUserAccount([]));
    setLoading(false);
  };

  const handleDeleteDepartmentItem = (itemSelected) => {
    setLoading(true);
    const lateDepartmentData = departmentSelected.filter((item) => item.id !== itemSelected.id);
    const departmentId = lateDepartmentData.map((department) => department.id);
    const departmentCode = lateDepartmentData.map((department) => department.code);
    ApiServices.updateClientSupportBoard(selected?.id, {
      arrDepartmentId: departmentId,
      arrDepartmentCode: departmentCode,
    })
      .then((res) => {
        if (res?.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          setDepartmentSelected(lateDepartmentData);
          const filterDataFinal = departmentList.concat(itemSelected);
          setDepartmentList(filterDataFinal);
          initData?.();
        }
      })
      .catch((err) => console.log('Xóa thất bại', err));
    setLoading(false);
  };
  const handleDeleteUserAccount = (itemSelected) => {
    setLoading(true);
    const lateAccountData = userAccountSelected.filter((item) => item.id !== itemSelected.id);
    const userAccountCode = lateAccountData.map((account) => account.code);
    ApiServices.updateClientSupportBoard(selected?.id, {
      arrUserCode: userAccountCode,
    })
      .then((res) => {
        if (res?.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          setUserAccountSelected(lateAccountData);
          const filterDataFinal = userAccountList.concat(itemSelected);
          setUserAccountList(filterDataFinal);
          initData?.();
        }
      })
      .catch((err) => console.log('Xóa thất bại', err));
    setLoading(false);
  };

  return (
    <>
      <ModalFormDetail
        backgroundModal="#f5f5f5"
        open={selected}
        onCloseModal={onHandleCloseModal}
        title="Thông tin chi tiết"
      >
        <b>Thông tin cơ bản:</b>
        <GeneralInformation selected={selected} />
        <Box>
          <IconButton aria-label="expand row" size="small" onClick={handleOpenItemDepartment}>
            {openDepartment ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
          <b>
            Danh sách phòng ban được chia sẻ{' '}
            <span style={{ color: 'red' }}>({departmentSelected?.length})</span>
          </b>
        </Box>
        <Collapse in={openDepartment} timeout="auto" unmountOnExit>
          <ContainerHeaderForm>
            <ComboBox
              multiple
              disableCloseOnSelect
              limitTags={2}
              options={departmentList}
              label="Chọn phòng ban"
              sx={{ width: 500 }}
              keyField="name"
              value={departments}
              onChange={handleChangeDepartment}
            />
            <Badge badgeContent={departments?.length} color="secondary" overlap="circular">
              <ButtonAdd
                variant="contained"
                startIcon={<BsPlusCircle />}
                disabled={!departments?.length > 0}
                onClick={handleAddDepartment}
              >
                Thêm
              </ButtonAdd>
            </Badge>
          </ContainerHeaderForm>
          <RenderDepartment
            data={departmentSelected}
            onDeleteDepartmentItem={handleDeleteDepartmentItem}
          />
        </Collapse>
        <Box sx={{ marginTop: '10px' }}>
          <Box>
            <IconButton aria-label="expand row" size="small" onClick={handleOpenItemAccount}>
              {openAccount ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <b>
              Danh sách tài khoản được chia sẻ{' '}
              <span style={{ color: 'red' }}>({userAccountSelected?.length})</span>
            </b>
          </Box>
          <Collapse in={openAccount} timeout="auto" unmountOnExit>
            <ContainerHeaderForm>
              <ComboBox
                multiple
                limitTags={2}
                disableCloseOnSelect
                options={userAccountList}
                label="Chọn tài khoản"
                sx={{ width: 500 }}
                keyField="code"
                value={userAccount}
                onChange={handleChangeUserAccount}
              />
              <Badge badgeContent={userAccount?.length} color="secondary" overlap="circular">
                <ButtonAdd
                  variant="contained"
                  startIcon={<BsPlusCircle />}
                  disabled={!userAccount?.length > 0}
                  onClick={handleAddUserAccount}
                >
                  Thêm
                </ButtonAdd>
              </Badge>
            </ContainerHeaderForm>
            <RenderUserAccount
              data={userAccountSelected}
              onDeleteUserAccount={handleDeleteUserAccount}
            />
          </Collapse>
        </Box>
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
