import React from 'react';
import { Button } from '@mui/material';
import Utils from 'commons/utils';
import { PRICE_BOARD_STATUS } from 'commons/constants';
import { RenderPriceBoardState, RenderPriceBoardStatus } from 'components/custom-status';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Trạng thái',
    align: 'center',
    render: (row) => <RenderPriceBoardStatus status={row?.status} />,
  },
  {
    id: 'expirationStatus',
    numeric: true,
    disablePadding: false,
    label: 'Tình trạng',
    align: 'center',
    render: (row) =>
      row?.expirationStatus && <RenderPriceBoardState state={row?.expirationStatus} />,
  },
  {
    id: 'objPOL',
    numeric: true,
    disablePadding: false,
    label: 'Cảng xếp dỡ',
    align: 'center',
    width: '200px',
    render: (row) => `${row?.objPOL?.name} - ${row?.objPOL?.countryCode}`,
  },
  {
    id: 'objPOD',
    numeric: true,
    disablePadding: false,
    label: 'Cảng đên',
    align: 'center',
    width: '200px',
    render: (row) => `${row?.objPOD?.name} - ${row?.objPOD?.countryCode}`,
  },
  {
    id: 'carrierName',
    numeric: true,
    disablePadding: false,
    label: 'Carrier',
    align: 'center',
  },
  {
    id: 'currencyCode',
    numeric: true,
    disablePadding: false,
    label: 'Đơn vị tiền tệ',
    align: 'center',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.createdOn),
  },
  {
    id: 'updatedOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày chỉnh sửa cuối',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.updatedOn),
  },
  {
    id: 'etd',
    numeric: true,
    disablePadding: false,
    label: 'ETD',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.etd),
  },
  {
    id: 'expirydate',
    numeric: true,
    disablePadding: false,
    label: 'Hết hạn',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.expirydate),
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) =>
      row?.status !== PRICE_BOARD_STATUS.APPROVED && (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteContTypeItem(row))}
        >
          Xoá
        </Button>
      ),
  },
];
