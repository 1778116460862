import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { BsSave, BsXCircle, BsCheck2Circle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSubServiceSelected,
  clearSubServiceSelected,
} from 'store/reducers/res-check-price/action';
import {
  clearUnits,
  clearCurrencies,
  clearPortsByType,
  clearCarriersByType,
} from 'store/reducers/category-data/action';
import { ModalFormDetail } from 'components';
import { LoadingView } from 'components/loading-view';
import ApiServices from 'services';
import HeaderFormDetail from './header-form-detail';
import { InformationGeneral } from './information-general';
import { FreightResponseForm } from './freight-service/freight-response-form';
import { TruckingResponseForm } from './trucking-service/trucking-response-form';
import { CustomsResponseForm } from './customs-service/customs-response-form';
import { REQ_CHECK_PRICE_STATUS, SERVICES_ID } from 'commons/constants';
import {
  ContainerContent,
  ContainerContentForm,
  ErrorText,
  BtnCreateCheckPrice,
  BtnRejectCheckPrice,
  BtnSendCheckPrice,
} from './styles';

export const FormDetailResponse = ({ selected, onCloseModal, initData }) => {
  const { WAIT_RES_CHECK_PRICE, RES_CHECK_PRICE, REJECT_CHECK_PRICE } = REQ_CHECK_PRICE_STATUS;
  const { FREIGHT, TRUCKING, CUSTOMS } = SERVICES_ID;
  const dispatch = useDispatch();
  const { subServiceSelectedList } = useSelector((state) => state.resCheckPrice);
  const [dataSelected, setDataSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [serviceIdSelected, setServiceIdSelected] = useState(null);
  const [loadingGetSubService, setLoadingGetSubService] = useState(false);
  const [loadingGetContent, setLoadingGetContent] = useState(false);

  const isSubmitting = useMemo(() => {
    return !serviceIdSelected || !dataSelected?.clientCompanyId;
  }, [serviceIdSelected, dataSelected]);

  useEffect(() => {
    return () => {
      dispatch(clearSubServiceSelected());
      dispatch(clearUnits());
      dispatch(clearCurrencies());
      dispatch(clearPortsByType());
      dispatch(clearCarriersByType());
    };
  }, []);

  useEffect(() => {
    if (selected?.id) {
      setDataSelected({ ...selected });
      setServiceIdSelected(selected?.serviceId);
    }
  }, [selected]);

  const onHandleCloseModal = () => {
    dispatch(clearSubServiceSelected());
    setServiceIdSelected(null);
    setDataSelected({});
    setLoadingGetSubService(false);
    setLoading(false);
    onCloseModal();
  };

  const getCheckPricesByService = async (checkPriceId, serviceId) => {
    if (loadingGetContent) return;
    setLoadingGetContent(true);
    try {
      const { data } = await ApiServices.getCheckPricesByService(checkPriceId, serviceId);
      if (data) {
        return getSubServiceByServiceId(serviceId, data);
      }
    } catch (err) {
      console.warn('getCheckPricesByService', err);
    } finally {
      setLoadingGetContent(false);
    }
  };

  const getSubServiceByServiceId = async (serviceId, dataContent = []) => {
    if (loadingGetSubService) return;
    setLoadingGetSubService(true);
    await dispatch(getSubServiceSelected(serviceId, dataContent));
    setLoadingGetSubService(false);
  };

  useEffect(() => {
    if (selected && !selected?.isNew && selected?.id && selected?.serviceId) {
      getCheckPricesByService(selected?.id, selected?.serviceId);
    }
  }, [selected]);

  const onHandleSubmit = async (statusCheck) => {
    setLoading(true);
    const dataSend = [];
    dataSend.push({ ...dataSelected, statusCheck });
    if (subServiceSelectedList && subServiceSelectedList.length > 0) {
      const data = subServiceSelectedList.find((el) => el.checked)?.contentList;
      dataSend.push(data);
    }
    try {
      const res = await ApiServices.updateCheckPriceEditByService(serviceIdSelected, dataSend);
      if (res.status === 200) {
        window.UIMessage?.success('Cập nhật thành công');
        onHandleCloseModal();
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renderSubService = (item, index) => {
    switch (serviceIdSelected) {
      case FREIGHT:
        return <FreightResponseForm itemData={item} selected={selected} />;
      case TRUCKING:
        return <TruckingResponseForm itemData={item} indexData={index} selected={selected} />;

      case CUSTOMS:
        return <CustomsResponseForm itemData={item} indexData={index} selected={selected} />;
      default:
        break;
    }
  };

  const renderContents = (item, index) => {
    if (item?.checked)
      return (
        <ContainerContentForm key={item?.id + index}>
          <div>
            <b>{item.name}</b>
          </div>
          {renderSubService(item, index)}
        </ContainerContentForm>
      );
  };

  return (
    <>
      <ModalFormDetail
        backgroundModal="#f5f5f5"
        open={selected}
        onCloseModal={onHandleCloseModal}
        title="Phản hồi yêu cầu check giá"
      >
        {!selected?.isNew && <HeaderFormDetail selected={selected} />}
        <InformationGeneral
          dataSelected={dataSelected}
          setServiceIdSelected={setServiceIdSelected}
          setDataSelected={setDataSelected}
          selected={selected}
          isDisabled={true}
        />
        {!loading && (
          <>
            <b>Nội dung yêu cầu</b>
            <ContainerContent>
              {!loadingGetSubService && subServiceSelectedList?.map(renderContents)}
            </ContainerContent>

            {selected && selected?.statusCheck === WAIT_RES_CHECK_PRICE && (
              <Box sx={{ display: 'flex', py: 2 }}>
                {isSubmitting && <ErrorText>Vui lòng nhập đầy đủ thông tin</ErrorText>}
                <Box sx={{ marginLeft: 'auto' }}>
                  <BtnCreateCheckPrice
                    sx={{ marginRight: '15px' }}
                    color="primary"
                    type="submit"
                    variant="contained"
                    startIcon={<BsSave size={16} />}
                    onClick={() => onHandleSubmit(WAIT_RES_CHECK_PRICE)}
                  >
                    Lưu
                  </BtnCreateCheckPrice>
                  <BtnRejectCheckPrice
                    sx={{ marginRight: '15px' }}
                    color="secondary"
                    type="submit"
                    variant="contained"
                    startIcon={<BsXCircle size={20} />}
                    onClick={() => onHandleSubmit(REJECT_CHECK_PRICE)}
                  >
                    Từ chối
                  </BtnRejectCheckPrice>
                  <BtnSendCheckPrice
                    color="success"
                    type="submit"
                    variant="contained"
                    startIcon={<BsCheck2Circle size={20} />}
                    onClick={() => onHandleSubmit(RES_CHECK_PRICE)}
                  >
                    Phản hồi
                  </BtnSendCheckPrice>
                </Box>
              </Box>
            )}
          </>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
