import Utils from 'commons/utils';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'objTypeCargo',
    numeric: true,
    disablePadding: false,
    label: 'Loại hàng hóa',
    align: 'center',
    render: (row) => row?.objTypeCargo?.name,
  },
  {
    id: 'currencyCode',
    numeric: true,
    disablePadding: false,
    label: 'ĐV tiền tệ',
    align: 'center',
    width: '100px',
  },
  {
    id: 'expirydate',
    numeric: true,
    disablePadding: false,
    label: 'Hết hạn',
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row?.expirydate),
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
];
