import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextInput, Selection, DatePicker, FileUpload, HtmlEditor, ArrayInput } from 'components';
import { Box, Button } from '@mui/material';
import { INPUT_TYPES } from 'commons/constants';
import ApiServices from 'services';
import Storage from 'storage';

//
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const MailForm = ({
  setOpenmailForm,
  clientCareHistoryItem,
  setValue,
  idClientSupportEmail,
  emailSupporter,
  isVendor = false,
}) => {
  const [dataUserEmail, setDataUserEmail] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getUserInfo = async (userCode) => {
    try {
      const res = await ApiServices.getUserByCode(userCode);
      console.log({ res: res?.data?.userMail });
      if (res?.data?.userMail) {
        setDataUserEmail(res?.data?.userMail || {});
        //
        if (res?.data?.userMail?.signatureHTML) {
          let html = '<p></p>';
          if (res?.data?.userMail?.signatureHTML) {
            html = res?.data?.userMail?.signatureHTML;
          }
          const contentBlock = htmlToDraft(html);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorStateDefault = EditorState.createWithContent(contentState);
          console.log({ editorStateDefault });
          setEditorState(editorStateDefault);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    console.log({ email: Storage?.user?.Id });
    const userCode = Storage?.user?.Code;
    if (userCode) {
      getUserInfo(userCode);
    }
  }, []);

  const createData = async function (data, { setSubmitting }) {
    setSubmitting(true);
    let values = data;
    try {
      if (values?.file?.length) {
        let tmp = values?.file.map(([item]) => item);
        values.file = tmp;
      }
      values.clientCareHistoryId = clientCareHistoryItem.id;

      if (isVendor) {
        values.customerType = 1;
        values.vendorServiceId = idClientSupportEmail;
      } else {
        values.customerType = 0;
        values.clientId = idClientSupportEmail;
      }

      const res = await ApiServices.createMail(values);
      if (res.status === 200) {
        window.UIMessage?.success('Thao tác thành công');
        setOpenmailForm(false);
        return setValue('3');
      } else {
        window.UIMessage?.error('Thao tác thất bại');
      }
    } catch (err) {
      console.warn('create Mail - companies', err);
    }
    setSubmitting(false);
  };

  const arrInput = [
    { field: 'subject', lable: 'Tiêu Đề' },
    { field: 'from', lable: 'Người gửi', disabled: true },
    { field: 'to', lable: 'Người nhận', type: INPUT_TYPES.ARRAY_TEXT_INPUT },
    { field: 'bcc', lable: 'Bcc', type: INPUT_TYPES.ARRAY_TEXT_INPUT },
    { field: 'cc', lable: 'Cc', type: INPUT_TYPES.ARRAY_TEXT_INPUT },
    {
      field: 'file',
      lable: 'File Đính kèm',
      type: INPUT_TYPES.FILE_UPLOAD,
      multiple: true,
    },
    {
      field: 'body',
      lable: 'Nội dung',
      type: INPUT_TYPES.HTML_EDITOR,
    },
  ];

  const initData = {
    subject: '',
    from: Storage?.user?.UserMail,
    body: '',
    // clientCareHistoryId: `${clientCareHistoryItem?.id}`,
    isSend: true,
    to: emailSupporter.split(','),
    bcc: [],
    cc: [],
    file: [],
  };

  const handleEditorStateChange = (value) => {
    let contentState = stateFromHTML(value);
    console.log({ contentState });
    setEditorState(value);
  };

  return (
    <>
      <Formik
        initialValues={initData}
        validationSchema={Yup.object().shape({
          subject: Yup.string().max(255).required('Vui lòng nhập tiêu đề mail'),
          from: Yup.string()
            .email('Vui lòng nhập đúng định dạng email')
            .max(255)
            .required('Vui lòng nhập người gửi mail'),
          to: Yup.array('vui lòng nhập người nhận mail')
            .of(Yup.string().email(({ value }) => ` ${value} không phải là email`))
            .min(1, 'vui lòng nhập người nhận mail'),
          bcc: Yup.array().of(Yup.string().email(({ value }) => ` ${value} không phải là email`)),
          cc: Yup.array().of(Yup.string().email(({ value }) => ` ${value} không phải là email`)),
        })}
        onSubmit={(values, { setSubmitting }) => {
          return createData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {arrInput.map((item) => {
              switch (item.type) {
                case INPUT_TYPES.SELECTION:
                  return (
                    <Selection
                      key={`Selection-${item.field}`}
                      label={item.lable}
                      name={item.field}
                      data={item?.data}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      syncData={item?.syncData}
                      fieldView={item.field.fieldView}
                      fieldShowFunc={item?.fieldShowFunc}
                      getValue={item.getValue}
                      disabledFunc={item.disabledFunc}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      value={values[item.field]}
                    />
                  );
                case INPUT_TYPES.DATE_PICKER:
                  return (
                    <DatePicker
                      key={`DatePicker-${item.field}`}
                      onChange={handleChange}
                      value={values[item.field]}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      name={item.field}
                      label={item.lable}
                    />
                  );
                case INPUT_TYPES.FILE_UPLOAD:
                  return (
                    <FileUpload
                      key={`FileUpload-${item.field}`}
                      error={Boolean(touched.file && errors.file)}
                      helperText={touched.file && errors.file}
                      label={item.lable}
                      name={item.field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      multiple={item?.multiple}
                    />
                  );
                case INPUT_TYPES.HTML_EDITOR:
                  return (
                    <>
                      <HtmlEditor
                        key={`HtmlEditor-${item.field}`}
                        helperText={touched.file && errors.file}
                        label={item.lable}
                        name={item.field}
                        onChange={handleChange}
                        value={values[item.field]}
                      />
                      {values.body === '' && (
                        <div style={{ color: 'red' }}>Vui lòng nhập nội dung email</div>
                      )}
                      <div style={{ marginTop: '10px' }}>
                        <b>Chữ ký:</b>
                        <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          onEditorStateChange={handleEditorStateChange}
                        />
                      </div>
                    </>
                  );
                case INPUT_TYPES.ARRAY_TEXT_INPUT:
                  return (
                    <ArrayInput
                      key={`ArrayInput-${item.field}`}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.lable}
                      name={item.field}
                      onChange={handleChange}
                      value={values[item.field]}
                    />
                  );
                default:
                  return (
                    <TextInput
                      key={`TextInput-${item.field}`}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.lable}
                      name={item.field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      multiline={item?.multiline}
                      disabled={item?.disabled}
                    />
                  );
              }
            })}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <Button
                  sx={{
                    width: '300px',
                    mt: 1,
                    padding: '10px 0px',
                    textTransform: 'none',
                  }}
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  Gửi Mail
                </Button>
              </div>
            </div>
            {/* <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                Gửi Mail
              </Button>
            </Box> */}
          </form>
        )}
      </Formik>
    </>
  );
};

export { MailForm };
