import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection, DatePicker, FileUpload } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';

import storage from 'storage';
import ApiServices from 'services';
const FORM_WIDTH = '500px';
// Define folow by enum
const DEPARTMENT_DOCUMENT_TYPE = 'department';

export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const createData = async function (values, { setSubmitting }) {
    setSubmitting(true);
    try {
      const res = await ApiServices.createDocument(values);
      if (res.status === 200) {
        reload();
        setSelected(null);
        window.UIMessage?.success('Thao tác thành công');
      } else {
        window.UIMessage?.error('Thao tác thất bại');
      }
    } catch (err) {
      console.warn('create Document - companies', err);
    }
    setSubmitting(false);
  };
  const updateData = async function (values, { setSubmitting }) {
    delete values.updatedOn;
    setSubmitting(true);
    if (values?.file?.isAvailable) {
      delete values.file;
    }
    try {
      const { data } = await ApiServices.updateDocument(values.id, values);
      reload();
      setSelected(null);
      window.UIMessage?.success('Thao tác thành công');
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('create Document - companies', err);
    }
    setSubmitting(false);
  };
  let fileStatus = [];
  for (const [key, value] of Object.entries(storage.enums?.fileStatus)) {
    fileStatus.push({
      content: value,
      value: key,
    });
  }

  const arrInput = [
    { field: 'name', lable: 'Tên tài liệu' },
    {
      field: 'fileStatus',
      lable: 'Loại tài liêụ',
      type: 'selection',
      dataArrObject: fileStatus,
      // multiple: true,
    },
    {
      field: 'departmentId',
      lable: 'Phòng ban',
      type: 'selection',
      syncData: ApiServices.getDepartments,
    },
  ];
  return (
    <HandleFormDetail {...{ selected, setSelected, width: FORM_WIDTH }} moreTabs={[]}>
      <Formik
        initialValues={{
          name: '',
          departmentId: '',
          fileStatus: '',
          note: '',
          ...selected,
          file: selected?.objFileinfo ? { ...selected?.objFileinfo, isAvailable: true } : '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Vui lòng nhập Tên tài liệu'),
          fileStatus: Yup.string().max(255).required('Vui lòng nhập loại tài liệu'),
          departmentId: Yup.string().when('fileStatus', {
            is: (val) => val === DEPARTMENT_DOCUMENT_TYPE,
            then: (schema) => schema.required('Vui lòng chọn phòng ban'),
          }),
          file: selected?.isNew && Yup.string().max(255).required('Vui lòng thêm file đính kèm'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {arrInput.map((item) => {
              if (item.type === 'selection') {
                // cover UICRM-527 by Duong , show input department if user select fileStatus === department
                if (values.fileStatus !== DEPARTMENT_DOCUMENT_TYPE && item.field === 'departmentId')
                  return;
                return (
                  <Selection
                    key={`selection-Document-${item.field}`}
                    error={Boolean(touched[item.field] && errors[item.field])}
                    helperText={touched[item.field] && errors[item.field]}
                    label={item.lable}
                    name={item.field}
                    data={item?.data}
                    multiple={item?.multiple}
                    onChange={handleChange}
                    value={values[item.field]}
                    syncData={item?.syncData}
                    dataArrObject={item?.dataArrObject}
                  />
                );
              }
              if (item.type === 'date-picker') {
                return (
                  <DatePicker
                    key={`selection-Document-${item.field}`}
                    onChange={handleChange}
                    value={values[item.field]}
                    error={Boolean(touched[item.field] && errors[item.field])}
                    helperText={touched[item.field] && errors[item.field]}
                    label={item.lable}
                    name={item.field}
                  />
                );
              }
              return (
                <TextInput
                  key={`input-Document-${item.field}`}
                  error={Boolean(touched[item.field] && errors[item.field])}
                  helperText={touched[item.field] && errors[item.field]}
                  label={item.lable}
                  name={item.field}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values[item.field]}
                  // multiline
                />
              );
            })}
            <TextInput
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              label="Ghi chú"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
              multiline
            />
            <FileUpload
              error={Boolean(touched.file && errors.file)}
              helperText={touched.file && errors.file}
              label="File đính kèm"
              name="file"
              onChange={handleChange}
              value={values?.file}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
