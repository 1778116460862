import actionTypes from './type';

const initialState = {
  initializing: false,
  user: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.APP_INITIALIZING_CHANGED:
      return {
        ...state,
        initializing: action.initializing,
      };
    case actionTypes.USER_CHANGED:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}
