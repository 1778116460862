import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { BsPlus, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';

function ClientRating() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getClientRating();
      setData(data || []);
    } catch (err) {
      console.warn('initData - companies', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.deleteClientRating(row.id);
      window.UIMessage?.success('Xoá thành công');
      initData();
    } catch (err) {
      window.UIMessage?.error('Xoá thất bại');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Tên',
      width: 10,
      sort: true,
      align: 'left',
    },
    {
      id: 'arrEvaluationCriteria',
      numeric: true,
      disablePadding: false,
      label: 'Số Tiêu chí',
      align: 'left',
      width: 300,
      render: (row) => {
        return row.arrEvaluationCriteria.map((item, index) => (
          <Box key={'item' + row.id + item.name + index}>
            <b>{index + 1}.</b> Tên: <b>{item.name}</b> - Đơn vị: <b>{item.unit}</b>
          </Box>
        ));
      },
    },
    {
      id: 'serviceName',
      numeric: true,
      disablePadding: false,
      label: 'Dịch vụ',
      align: 'left',
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'left',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Thao tác',
      width: '10px',
      fixed: 'right',
      fixed: 'right',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data}
        headCells={headCells}
        onClickDetail={(row) => {
          setSelected(row);
        }}
        title="Danh Sách Tiêu chí Đánh giá Khách hàng"
        loading={loading}
        renderHeader={() => {
          return (
            <>
              <SearchInput sx={{ mr: 2 }} />
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  mr: 2,
                }}
              >
                {/* <Stack direction="row" spacing={1}> */}
                <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                  <BsFilter />
                </IconButton>

                <Button
                  variant="contained"
                  size="small"
                  endIcon={<BsPlus />}
                  sx={{
                    borderRadius: '20px',
                    // padding: "7px 15px",
                    textTransform: 'none',
                    marginLeft: '8px',
                    color: '#FFFFFF',
                    backgroundColor: '#21409A',
                    '.MuiButton-endIcon': {
                      marginLeft: '0px',
                    },
                    padding: '0px 10px',
                  }}
                  onClick={() => setSelected({ isNew: true })}
                >
                  Thêm mới
                </Button>
                {/* </Stack> */}
              </Box>
            </>
          );
        }}
      />
      <FormDetail {...{ selected, setSelected }} reload={initData} />
    </Box>
  );
}
export { ClientRating };
