import React, { useState, useEffect, useMemo } from 'react';
import {
  BsEye,
  BsSave,
  BsBoxArrowInUpRight,
  BsXCircle,
  BsCheck2Circle,
  BsEnvelope,
} from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { updateServiceToQuote } from 'store/reducers/customerQuotes/action';
import { SERVICES_ID, SUB_SERVICES_ID, QUOTE_STATUS } from 'commons/constants';
import { Box, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { QuoteFreightPriceForm } from '../../forms-management/quote-freight-price-form';
import { QuoteFreightSubChargeForm } from '../../forms-management/quote-freight-sub-charge-form';
import { HeaderFreight } from './header';
import {
  ContainerPrice,
  ContainerSumPrice,
  StyledTextField,
  ContainerLeft,
  ContainerMainPrice,
  ContainerItemSum,
  ContainerLabelSum,
  BtnViewReportQuote,
  BtnCreateQuote,
  BtnRequestQuote,
  BtnRejectQuote,
  BtnApproveQuote,
  BtnSendQuote,
} from './styles';
const LABEL_CALC_SUM = ['Tổng giá mua:', 'Tổng % Make up:', 'Tổng giá bán:'];

const FreightControlQuoteForm = ({ handleCloseAfterSubmit, initData, selected }) => {
  const dispatch = useDispatch();
  const { SEA_FCL, SEA_LCL, AIR } = SUB_SERVICES_ID;
  const { itemServiceSelectedToQuote, objQuotes } = useSelector((state) => state.customerQuotes);
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  const isSeaFcl = useMemo(() => {
    return itemServiceSelectedToQuote?.subServiceId === SEA_FCL;
  }, [itemServiceSelectedToQuote?.subServiceId]);
  const isSeaLcl = useMemo(() => {
    return itemServiceSelectedToQuote?.subServiceId === SEA_LCL;
  }, [itemServiceSelectedToQuote?.subServiceId]);
  const isAir = useMemo(() => {
    return itemServiceSelectedToQuote?.subServiceId === AIR;
  }, [itemServiceSelectedToQuote?.subServiceId]);

  const isDisabledForm = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [selected]);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = await data.map((item) => {
          let ind = -1;
          if (isAir) {
            ind = itemServiceSelectedToQuote?.arrSurchargeAirFreight?.findIndex(
              (i) => i.feeGroupID === item.feeGroupID,
            );
          } else {
            ind = itemServiceSelectedToQuote?.arrSurchargeFreight?.findIndex(
              (i) => i.feeGroupID === item.feeGroupID,
            );
          }
          if (ind !== -1) {
            if (isAir) {
              item.surcharge =
                itemServiceSelectedToQuote?.arrSurchargeAirFreight[ind]?.surcharge || [];
            } else {
              item.surcharge =
                itemServiceSelectedToQuote?.arrSurchargeFreight[ind]?.surcharge || [];
            }
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (itemServiceSelectedToQuote?.subServiceId) {
      getSurchargeByFeeGroupSS(itemServiceSelectedToQuote?.subServiceId);
    }
  }, []);

  const calcSumPriceAndMakeUp = useMemo(() => {
    if (!itemServiceSelectedToQuote) return [];
    // filter data to calc sum by arrSurchargeFreight
    let result = [];
    if (isAir) {
      result = itemServiceSelectedToQuote?.arrSurchargeAirFreight
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue?.surcharge), [])
        ?.reduce(
          (previousValue, currentValue) => previousValue.concat(currentValue?.arrPackingAir),
          [],
        );
    } else {
      result = itemServiceSelectedToQuote?.arrSurchargeFreight
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue?.surcharge), [])
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue?.arrCont), []);
    }
    // filter data arrContFreight
    if (isAir) {
      result.push(...itemServiceSelectedToQuote?.arrPackingAirFreight[0]?.arrPackingAir);
    } else {
      result.push(...itemServiceSelectedToQuote?.arrContFreight[0]?.arrCont);
    }
    // Calc sum price and make up and buy price
    if (isSeaFcl) {
      const calcSumAll = itemServiceSelectedToQuote?.contTypeCodeCheck?.map((item) => {
        const calcEachItem = result?.reduce(
          (prev, current) => {
            if (current?.contTypeCode === item) {
              const dataCal = {
                contTypeCode: prev?.contTypeCode,
                price: prev.price + current?.price,
                buyPrice: prev.buyPrice + current?.buyPrice,
              };
              return (prev = dataCal);
            }
            return prev;
          },
          {
            contTypeCode: item,
            price: 0,
            buyPrice: 0,
          },
        );
        return calcEachItem;
      }, []);
      return calcSumAll;
    }
    if (isSeaLcl) {
      const calcSumForSeaLcl = result?.reduce(
        (prev, current) => {
          const dataCal = {
            price: prev.price + current?.price,
            buyPrice: prev.buyPrice + current?.buyPrice,
          };
          return (prev = dataCal);
        },
        {
          price: 0,
          buyPrice: 0,
        },
      );
      return [calcSumForSeaLcl];
    }
    if (isAir) {
      const calcSumAllForAir = itemServiceSelectedToQuote?.packingAirCodeCheck?.map((item) => {
        const calcEachItem = result?.reduce(
          (prev, current) => {
            if (current?.packingAirCode === item) {
              const dataCal = {
                packingAirCode: prev?.packingAirCode,
                price: prev.price + current?.price,
                buyPrice: prev.buyPrice + current?.buyPrice,
              };
              return (prev = dataCal);
            }
            return prev;
          },
          {
            packingAirCode: item,
            price: 0,
            buyPrice: 0,
          },
        );
        return calcEachItem;
      }, []);
      return calcSumAllForAir;
    }
    return [];
  }, [itemServiceSelectedToQuote]);

  const handleChangeContFreight = (e) => {
    if (isAir) {
      dispatch(updateServiceToQuote({ arrPackingAirFreight: e.target.value }));
    } else {
      dispatch(updateServiceToQuote({ arrContFreight: e.target.value }));
    }
  };
  const handleChangeSubCharge = (event, item) => {
    const data = groupFeeGroup.map((el) => {
      if (el?.feeGroupID === item?.feeGroupID) {
        el.surcharge = event.target.value;
      }
      return el;
    });
    if (isAir) {
      dispatch(updateServiceToQuote({ arrSurchargeAirFreight: data }));
    } else {
      dispatch(updateServiceToQuote({ arrSurchargeFreight: data }));
    }
  };

  const renderSubChargeFreight = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteFreightSubChargeForm
          arrContType={
            isAir
              ? itemServiceSelectedToQuote?.packingAirCodeCheck
              : itemServiceSelectedToQuote?.contTypeCodeCheck
          }
          arrSurcharge={item.surchargeListFree}
          name={item.feeGroupID}
          onChange={(event) => handleChangeSubCharge(event, item)}
          value={item?.surcharge}
          disabled={isDisabledForm}
          isSeaLcl={isSeaLcl}
          isAir={isAir}
          keyArray={isAir ? 'arrPackingAir' : 'arrCont'} // array cont main
          keyNameOfArray={isAir ? 'packingAirCode' : 'contTypeCode'} // name of array cont
        />
      )}
    </FormGroup>
  );
  const renderSumPrice = (item, index) => (
    <ContainerMainPrice key={`${item?.contTypeCode}-${index}`} isSeaLcl={isSeaLcl}>
      <ContainerItemSum>
        <StyledTextField
          label={isSeaLcl ? 'Giá' : isAir ? item?.packingAirCode : item?.contTypeCode}
          variant="outlined"
          type="text"
          size="small"
          color="primary"
          focused
          value={new Number(item?.price).toFixed(2)}
        />
      </ContainerItemSum>
      <ContainerItemSum>
        <StyledTextField
          label={
            isAir
              ? `Makeup ${item?.packingAirCode}`
              : `% Makeup ${!isSeaLcl ? item?.contTypeCode : ''}`
          }
          variant="outlined"
          type="text"
          size="small"
          color="secondary"
          focused
          value={new Number(
            isAir
              ? item?.buyPrice - item?.price
              : ((item?.buyPrice - item?.price) / item?.price) * 100,
          ).toFixed(2)}
        />
      </ContainerItemSum>
      <ContainerItemSum>
        <StyledTextField
          label={`Giá bán ${!isSeaLcl ? (isAir ? item?.packingAirCode : item?.contTypeCode) : ''}`}
          variant="outlined"
          type="text"
          size="small"
          color="success"
          focused
          value={new Number(item?.buyPrice).toFixed(2)}
        />
      </ContainerItemSum>
    </ContainerMainPrice>
  );

  const createQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: {
        ...objQuotes,
        quotesStatus,
        quotedate: quoteDate,
        arrServiceId: [itemServiceSelectedToQuote?.serviceId],
        arrSubServiceId: [itemServiceSelectedToQuote?.subServiceId],
      },
      objFreight: { ...itemServiceSelectedToQuote },
    };
    try {
      const { status } = await ApiServices.createFreightQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const updateQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: { ...objQuotes, quotedate: quoteDate, quotesStatus },
      objFreight: { ...itemServiceSelectedToQuote },
    };
    try {
      const { status } = await ApiServices.updateFreightQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const handleSubmit = (quotesStatus, quoteDate = null) => {
    if (selected?.isNew) {
      return createQuote(quotesStatus, quoteDate);
    } else {
      return updateQuote(quotesStatus, quoteDate);
    }
  };

  const handleReportQuoteTemplate = async (subServiceId, quoteId) => {
    if (subServiceId === AIR) return;
    // if (subServiceId === AIR) {
    //   await ApiServices.exportFileOfFreightAir(subServiceId, quoteId)
    //     .then((res) => {
    //       if (res?.data) {
    //         Utils.exportTemplate(res?.data, `${selected?.code}.docx`);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('Failed to get report quote tempalte:', err);
    //     });
    // }

    await ApiServices.exportFileOfFreightFclLcl(subServiceId, quoteId)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `${selected?.code}.docx`);
        }
      })
      .catch((err) => {
        console.log('Failed to get report quote tempalte:', err);
      });
  };

  return (
    <>
      <HeaderFreight />
      <b>Giá cước</b>

      <ContainerPrice>
        <QuoteFreightPriceForm
          arrContType={
            isAir
              ? itemServiceSelectedToQuote?.packingAirCodeCheck
              : itemServiceSelectedToQuote?.contTypeCodeCheck
          }
          name="arrContFreight"
          onChange={handleChangeContFreight}
          value={
            isAir
              ? itemServiceSelectedToQuote?.arrPackingAirFreight
              : itemServiceSelectedToQuote?.arrContFreight
          }
          disabled={isDisabledForm}
          isSeaLcl={isSeaLcl}
          isAir={isAir}
          keyArray={isAir ? 'arrPackingAir' : 'arrCont'} // array cont main
          keyNameOfArray={isAir ? 'packingAirCode' : 'contTypeCode'} // name of array cont
        />
        {groupFeeGroup?.map(renderSubChargeFreight)}

        <ContainerSumPrice>
          {(isSeaFcl || isAir) && (
            <ContainerMainPrice>
              {LABEL_CALC_SUM?.map((item, index) => (
                <ContainerLabelSum key={index}>
                  <ContainerLeft>
                    <i style={{ color: 'red' }}>{item}</i>
                  </ContainerLeft>
                </ContainerLabelSum>
              ))}
            </ContainerMainPrice>
          )}
          {isSeaLcl && (
            <ContainerMainPrice>
              <ContainerLabelSum>
                <ContainerLeft>
                  <i style={{ color: 'red' }}>Tổng:</i>
                </ContainerLeft>
              </ContainerLabelSum>
            </ContainerMainPrice>
          )}
          {calcSumPriceAndMakeUp?.map(renderSumPrice)}
        </ContainerSumPrice>
      </ContainerPrice>

      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          {!selected?.isNew && (
            <BtnViewReportQuote
              variant="outlined"
              startIcon={<BsEye />}
              onClick={() => handleReportQuoteTemplate(selected?.arrSubServiceId[0], selected?.id)}
            >
              Xem báo giá
            </BtnViewReportQuote>
          )}

          {(selected?.isNew || selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) && (
            <>
              <BtnCreateQuote
                sx={{ marginRight: '15px' }}
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<BsSave size={16} />}
                onClick={() => handleSubmit(QUOTE_STATUS.ORIGIN_QUOTE)}
                disabled={!objQuotes?.clientCompanyId}
              >
                {selected?.isNew ? 'Tạo báo giá' : 'Lưu'}
              </BtnCreateQuote>
              <BtnRequestQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsBoxArrowInUpRight size={20} />}
                onClick={() =>
                  handleSubmit(QUOTE_STATUS.REQUEST_APPROVE, Utils.convertToDateFormat(new Date()))
                }
                disabled={!objQuotes?.clientCompanyId || !objQuotes?.clientId}
              >
                Trình duyệt
              </BtnRequestQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.REQUEST_APPROVE && (
            <>
              <BtnRejectQuote
                sx={{ marginRight: '15px' }}
                color="error"
                type="submit"
                variant="contained"
                startIcon={<BsXCircle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.REJECT_APPROVE)}
              >
                Không duyệt
              </BtnRejectQuote>
              <BtnApproveQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsCheck2Circle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
              >
                Duyệt
              </BtnApproveQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.APPROVED && (
            <BtnSendQuote
              color="success"
              type="submit"
              variant="contained"
              startIcon={<BsEnvelope size={20} />}
              // onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
            >
              Gửi báo giá
            </BtnSendQuote>
          )}
        </Box>
      </Box>
    </>
  );
};

export { FreightControlQuoteForm };
