import React from 'react';
import * as Yup from 'yup';
import {
  HandleFormDetail,
  TextInput,
  Selection,
  DatePicker,
  FileUpload,
  HtmlEditor,
  ArrayInput,
} from 'components';
import { Box, Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import { INPUT_TYPES } from 'commons/constants';

import ApiServices from 'services';
const FORM_WIDTH = '600px';
const joinArrToString = (arr = []) => {
  if (!arr.length) {
    return '';
  }
  return arr.join(',');
};
export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const formRef = React.useRef(null);
  const createData = async function (data, { setSubmitting }) {
    setSubmitting(true);
    let values = data;
    try {
      if (values?.file?.length) {
        let tmp = values?.file.map(([item]) => item);
        values.file = tmp;
      }
      // values?.to?.length && (values.to = joinArrToString(values.to));
      // values?.bcc?.length && (values.bcc = joinArrToString(values.bcc));
      // values?.cc?.length && (values.cc = joinArrToString(values.cc));

      console.log({ values });

      const res = await ApiServices.createMail(values);
      console.log(res);
      if (res.status === 200) {
        reload();
        setSelected(null);
        window.UIMessage?.success('Thao tác thành công');
      } else {
        window.UIMessage?.error('Thao tác thất bại');
      }
    } catch (err) {
      console.warn('create Mail - companies', err);
    }
    setSubmitting(false);
  };
  const updateData = async function (data, { setSubmitting }) {
    delete values.updatedOn;
    setSubmitting(true);
    let values = data;
    if (values?.file?.length) {
      let tmp = values?.file.map(([item]) => item);
      values.file = tmp.filter((item) => !item?.available);
    }
    // values?.to?.length && (values.to = joinArrToString(values.to));
    // values?.bcc?.length && (values.bcc = joinArrToString(values.bcc));
    // values?.cc?.length && (values.cc = joinArrToString(values.cc));
    try {
      const { data } = await ApiServices.updateMail(selected.id, values);
      console.log(data);
      reload();
      setSelected(null);
      window.UIMessage?.success('Thao tác thành công');
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('create Mail - companies', err);
    }
    setSubmitting(false);
  };
  const arrInput = [
    { field: 'subject', lable: 'Tiêu Đề' },
    {
      field: 'from',
      type: INPUT_TYPES.SELECTION,
      lable: 'Người gửi',
      syncData: ApiServices.getUsers,
      getValue: (item) => item?.userMail?.user,
      disabledFunc: (item) => Boolean(!item?.userMail?.user),
      fieldShowFunc: (item) => `${item?.code} - ${item?.userMail?.user}`,
    },
    { field: 'to', lable: 'Người nhận', type: INPUT_TYPES.ARRAY_TEXT_INPUT },
    { field: 'bcc', lable: 'Bcc', type: INPUT_TYPES.ARRAY_TEXT_INPUT },
    { field: 'cc', lable: 'Cc', type: INPUT_TYPES.ARRAY_TEXT_INPUT },
    {
      field: 'body',
      lable: 'Nội dung',
      type: INPUT_TYPES.HTML_EDITOR,
    },
    {
      field: 'clientCareHistoryId',
      lable: 'Khách hàng',
      type: INPUT_TYPES.SELECTION,
      syncData: ApiServices.getClientCareHistories,
      fieldShowFunc: (item) => `${item?.objClient?.fullName} - ${item?.serviceName}`,
    },
    {
      field: 'file',
      lable: 'File Đính kèm',
      type: INPUT_TYPES.FILE_UPLOAD,
      multiple: true,
    },
  ];
  const initData = {
    subject: '',
    from: '',
    to: [],
    bcc: [],
    cc: [],
    body: '',
    clientCareHistoryId: '',
    isSend: true,
    ...selected,
    file: selected?.arrFileinfo?.length
      ? selected?.arrFileinfo.map((item) => {
          return [
            {
              ...item,
              isAvailable: true,
            },
          ];
        })
      : [],
  };

  return (
    <HandleFormDetail
      sx={{ paddingTop: '45px' }}
      showTitleTabFn={() => {
        return (
          <Box
            sx={{
              display: 'flex',
              py: 2,
              justifyContent: 'space-between',
              position: 'fixed',
              top: 0,
              right: 0,
              width: FORM_WIDTH,
              marginRight: '17px',
              padding: '10px',
              backgroundColor: 'ghostwhite',
            }}
          >
            <Typography color="primary" variant="h6" noWrap component="div">
              {selected?.subject ? `Chủ đề: ${selected.subject}` : 'Tạo mới'}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', pr: '10px' }}>
              <Button
                color="primary"
                disabled={formRef?.current?.isSubmitting}
                // fullWidth
                size="small"
                onClick={() => formRef?.current?.handleSubmit && formRef.current.handleSubmit()}
                variant="contained"
              >
                {selected?.isNew ? 'Gửi Email' : 'Cập nhật'}
              </Button>
              {!selected?.isNew && !selected?.isSend && (
                <Button
                  sx={{ mr: 3 }}
                  color="primary"
                  disabled={formRef?.current?.isSubmitting}
                  size="small"
                  type="submit"
                  variant="contained"
                  onClick={async () => {
                    console.log('ok');
                    formRef?.current?.handleChange &&
                      (await formRef.current.handleChange({
                        persist: () => {},
                        target: {
                          type: 'change',
                          name: 'isSend',
                          value: true,
                        },
                      }));
                    formRef?.current?.handleSubmit && formRef.current.handleSubmit();
                  }}
                >
                  Gửi Lại
                </Button>
              )}
            </Box>
          </Box>
        );
      }}
      {...{ selected, setSelected, width: FORM_WIDTH }}
    >
      <Formik
        innerRef={formRef}
        initialValues={initData}
        validationSchema={Yup.object().shape({
          subject: Yup.string().max(255).required('Vui lòng nhập Tiêu đề mail'),
          from: Yup.string()
            .email('Vui lòng nhập đúng định dạng email')
            .max(255)
            .required('vui lòng nhập Người gửi mail'),
          to: Yup.array('vui lòng nhập Người nhận mail')
            .of(Yup.string().email(({ value }) => ` ${value} không phải là email`))
            .min(1, 'vui lòng nhập Người nhận mail'),
          bcc: Yup.array().of(Yup.string().email(({ value }) => ` ${value} không phải là email`)),
          cc: Yup.array().of(Yup.string().email(({ value }) => ` ${value} không phải là email`)),

          clientCareHistoryId: Yup.string()
            .max(255)
            .required('vui lòng nhập tình thẻ chăm sóc khách hàng'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {arrInput.map((item) => {
              switch (item.type) {
                case INPUT_TYPES.SELECTION:
                  return (
                    <Selection
                      key={`Selection-${item.field}`}
                      label={item.lable}
                      name={item.field}
                      data={item?.data}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      syncData={item?.syncData}
                      fieldView={item.field.fieldView}
                      fieldShowFunc={item?.fieldShowFunc}
                      getValue={item.getValue}
                      disabledFunc={item.disabledFunc}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      value={values[item.field]}
                    />
                  );
                case INPUT_TYPES.DATE_PICKER:
                  return (
                    <DatePicker
                      key={`DatePicker-${item.field}`}
                      onChange={handleChange}
                      value={values[item.field]}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      name={item.field}
                      label={item.lable}
                      name={item.field}
                    />
                  );
                case INPUT_TYPES.FILE_UPLOAD:
                  return (
                    <FileUpload
                      key={`FileUpload-${item.field}`}
                      error={Boolean(touched.file && errors.file)}
                      helperText={touched.file && errors.file}
                      label={item.lable}
                      name={item.field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      multiple={item?.multiple}
                    />
                  );
                case INPUT_TYPES.HTML_EDITOR:
                  return (
                    <HtmlEditor
                      key={`HtmlEditor-${item.field}`}
                      helperText={touched.file && errors.file}
                      label={item.lable}
                      name={item.field}
                      onChange={handleChange}
                      value={values[item.field]}
                    />
                  );

                case INPUT_TYPES.ARRAY_TEXT_INPUT:
                  return (
                    <ArrayInput
                      key={`ArrayInput-${item.field}`}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.lable}
                      name={item.field}
                      onChange={handleChange}
                      value={values[item.field]}
                    />
                  );
                default:
                  return (
                    <TextInput
                      key={`TextInput-${item.field}`}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.lable}
                      name={item.field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      multiline={item?.multiline}
                    />
                  );
              }
            })}
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
