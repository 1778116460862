import React from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const ItemQuota = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}));
const TitleNumber = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
}));

const Number = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: '1.5rem',
  textAlign: 'center',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
}));

const TitleTopSale = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
  width: '100%',
  fontSize: '1.2rem',
}));

const ItemSale = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor: 'background.paper',
  color: theme.palette.text.secondary,
  marginBlock: '10px',
  display: 'flex',
  boxShadow: 'none',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  borderRadius: '20px',
}));
const Name = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  textAlign: 'center',
}));
const NumberSale = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}));
const QuotaSummary = ({ data }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <ItemQuota>
        <TitleNumber>Mục tiêu</TitleNumber>
        <Number>20 Tỷ VNĐ</Number>
      </ItemQuota>
      <ItemQuota>
        <TitleNumber>Doanh số</TitleNumber>
        <Number>10 Tỷ VNĐ</Number>
      </ItemQuota>
      <TitleTopSale>TOP SALES</TitleTopSale>
      {/* {data.map((item) => (
        <ItemSale key={`ItemSale-${item?.id}`}>
          <Name>{item?.userSales}</Name>
          <NumberSale>{item?.expectedRevenue}</NumberSale>
        </ItemSale>
      ))} */}
      <ItemSale>
        <Name>Nguyễn Thành Đạt</Name>
        <NumberSale>500.000.000</NumberSale>
      </ItemSale>
    </Box>
  );
};
export default QuotaSummary;
