import React from 'react';
import { Box, Paper, Typography, Grid, Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const MainTitle = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor: 'background.paper',
  color: theme.palette.text.secondary,
  borderRadius: '20px',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  position: 'relative',
  alignItems: 'center',
  fontWeight: 'bold',
}));
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor: 'background.paper',
  color: theme.palette.text.secondary,
  borderRadius: '20px',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  position: 'relative',
  height: '100%',
  justifyItems: 'center',
  alignItems: 'center',
}));
const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
}));
const Number = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  fontWeight: '900',
  textAlign: 'center',
  textTransform: 'uppercase',
  marginTop: 'auto',
}));

const ListItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  width: '100%',
  // alignItems: 'center',
  justifyItems: 'center',
}));
const Statistical = ({ data }) => {
  if (!data?.length) {
    return null;
  }
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {/* <Grid item xs={12}>
        <MainTitle>THỐNG KÊ CÔNG VIỆC</MainTitle>
        
      </Grid> */}
      <Grid item xs={4}>
        <Item>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <CreateNewFolderIcon />
          </Avatar>

          <Title>Mới</Title>
          <Number>{data[0]?.New}</Number>
        </Item>
      </Grid>

      {/* <Grid item xs={3}>
        <Typography variant="h7" component="div" sx={{ fontWeight: 'bold' }}>
          Đang Chờ
        </Typography>
        {data[0]?.Pending}
      </Grid> */}
      <Grid item xs={4}>
        <Item>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <PendingActionsIcon />
          </Avatar>
          <Title>Đang xử lý</Title>
          <Number>{data[0]?.Working}</Number>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <FactCheckIcon />
          </Avatar>
          <Title>Hoàn thành</Title>
          <Number>{data[0]?.Done}</Number>
        </Item>
      </Grid>
    </Grid>
  );
};
export default Statistical;
