import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ({ data, serviceList, services, onChange }) {
  const [checked, setChecked] = React.useState({});
  const dataSize = Object.keys(services).length;
  React.useEffect(() => {
    if (!dataSize) {
      setChecked({});
    }
  }, [dataSize]);
  const onChecked = (e, val) => {
    const newChecked = { ...checked, [val.id]: e.target.checked ? val : false };
    setChecked(newChecked);
    onChange &&
      onChange({
        [data.id]: {
          row: data,
          services: Object.keys(newChecked)
            .filter((item) => newChecked[item])
            .map((item) => newChecked[item]),
        },
      });
  };
  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {data?.arrService?.map((item) => {
        if (serviceList?.includes(item?.id)) {
          const isDisabled = data?.arrServiceReceivedId?.includes(item?.id);
          return (
            <FormControlLabel
              key={data.id + item.id}
              label={item.name}
              control={
                <Checkbox
                  size="small"
                  checked={Boolean(isDisabled || checked[item.id])}
                  onChange={(e) => onChecked(e, item)}
                  disabled={isDisabled}
                />
              }
            />
          );
        }
      })}
    </Box>
  );

  return <div>{children}</div>;
}
