import React from 'react';
import { Box } from '@mui/material';
import { Table, ShowListFile } from 'components';
import Utils from 'commons/utils';

const RecordDetail = ({ dataSelect }) => {
  const columns = [
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: 'Mã hồ sơ',
      align: 'center',
      width: '150px',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên hồ sơ',
      align: 'center',
      width: '300px',
    },
    {
      id: 'urlFile',
      numeric: true,
      disablePadding: false,
      label: 'File hồ sơ',
      align: 'center',
      render: (row) => <ShowListFile maxWidth="200px" fileList={row?.urlFile} />,
    },
    {
      id: 'expireDate',
      numeric: true,
      disablePadding: false,
      label: 'Ngày hết hạn',
      align: 'center',
      width: '150px',
      render: (row) => Utils.formatDateFn(row?.expireDate),
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'center',
      width: '200px',
    },
  ];

  return (
    <Box>
      {' '}
      <Table
        rows={dataSelect?.arrFileDocument || []}
        headCells={columns}
        onPageChange={(paging) => {}}
        isHeader={false}
      />
    </Box>
  );
};

export { RecordDetail };
