import React from 'react';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ContractDetail } from './contract-detail';
import { RecordDetail } from './record-detail';

const DetailContract = ({ dataSelect }) => {
  const [valueTab, setValueTab] = React.useState('1');

  const handleChangeTab = (_, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Hợp đồng" value="1" sx={{ textTransform: 'none' }} />
              <Tab label="Hồ sơ" value="2" sx={{ textTransform: 'none' }} />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ContractDetail dataSelect={dataSelect} />
          </TabPanel>
          <TabPanel value="2">
            <RecordDetail dataSelect={dataSelect} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export { DetailContract };
