import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { Toolbar, Typography } from '@mui/material';

export const TableToolbar = ({ numSelected, render, renderSelect }) => {
  const renderSelected = () => {
    if (numSelected > 0) {
      return (
        <>
          {/* ===================  CheckBox ================================ */}
          <Typography sx={{ flex: '1 1 ' }} color="inherit" variant="subtitle1" component="div">
            Đã chọn {numSelected}
          </Typography>
          {renderSelect && renderSelect()}
        </>
      );
    }
    return render && render();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        flexWrap: 'wrap',
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {renderSelected()}
    </Toolbar>
  );
};
