import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, ArrayInput } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';

import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const createData = async function (values, { setSubmitting }) {
    setSubmitting(true);
    try {
      const { data } = await ApiServices.createCompany(values);
      window.UIMessage?.success('Thao tác thành công');
      reload();
      setSelected(null);
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('createCompany - companies', err);
    }
    setSubmitting(false);
  };
  const updateData = async function (values, { setSubmitting }) {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateCompany(values.id, values);
      window.UIMessage?.success('Thao tác thành công');
      reload();
      setSelected(null);
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('createCompany - companies', err);
    }
    setSubmitting(false);
  };

  return (
    <HandleFormDetail
      {...{ selected, setSelected, width: FORM_WIDTH }}
      showTitleTab={selected?.isNew ? 'Tạo mới' : `Công ty: ${selected?.name}`}
      moreTabs={[
        {
          title: 'Test Tab More',
          content: () => <> TAB 2</>,
        },
      ]}
    >
      <Formik
        initialValues={{
          code: '',
          name: '',
          levelCompany: '',
          email: '',
          tax_Code: '',
          arrNumberPhone: [],
          arrFax: [],
          arrAddress: [],
          ...selected,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required('Vui lòng nhập Mã Công ty'),
          name: Yup.string().max(255).required('vui lòng nhập tên Công ty'),
          email: Yup.string()
            .email('Vui lòng nhập đúng định dạng email')
            .max(255)
            .required('Vui lòng nhập email Công ty'),
          tax_Code: Yup.string().max(255).required('Vui lòng nhập Mã số thuế'),
          levelCompany: Yup.number().integer(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextInput
              required
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
              label="Mã công ty"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
            />
            <TextInput
              required
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Tên công ty"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
            <TextInput
              required
              error={Boolean(touched.levelCompany && errors.levelCompany)}
              helperText={touched.levelCompany && errors.levelCompany}
              label="Quy mô công ty"
              name="levelCompany"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.levelCompany}
              type="number"
            />
            <TextInput
              required
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              label="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
            />
            <TextInput
              required
              error={Boolean(touched.tax_Code && errors.tax_Code)}
              helperText={touched.tax_Code && errors.tax_Code}
              label="Mã số thuế"
              name="tax_Code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.tax_Code}
            />
            <ArrayInput
              value={values.arrNumberPhone}
              fullWidth
              onChange={handleChange}
              label="Số điện thoại"
              name="arrNumberPhone"
            />
            <ArrayInput
              value={values.arrAddress}
              fullWidth
              onChange={handleChange}
              label="Địa chỉ"
              name="arrAddress"
            />
            <ArrayInput
              value={values.arrFax}
              fullWidth
              onChange={handleChange}
              label="Fax"
              name="arrFax"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
