import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import ApiServices from 'services';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: '50%',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

const CS_LEVEL_DEFINE = [1, 2, 3, 4, 5];

export const Department = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);

  const initData = () => {
    setLoading(true);
    return ApiServices.getDepartments()
      .then((res) => {
        console.log({ Department: res?.data?.data });
        if (res?.data?.data) {
          let two = [];
          let three = [];
          let four = [];
          let fine = [];
          res?.data?.data?.forEach((element) => {
            if (element?.csLevel === 2) {
              two.push(element);
              return;
            }
            if (element?.csLevel === 3) {
              three.push(element);
              return;
            }
            if (element?.csLevel === 4) {
              four.push(element);
              return;
            }
            if (element?.csLevel === 5) {
              fine.push(element);
              return;
            }
          });

          setData2(two);
          setData3(three);
          setData4(four);
          setData5(fine);
        }
      })
      .catch((err) => console.warn('initData - department', err))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={null}
      >
        {/* // Cty thứ 1 */}
        <StyledTreeItem
          nodeId="1"
          label={
            <div
              style={{
                fontSize: '25px',
                width: '100%',
                color: 'blue',
                display: 'flex',
              }}
            >
              Công ty Cổ Phần LOGISTICS U&I
              <span
                style={{
                  marginLeft: 'auto',
                }}
              >
                Công ty
              </span>
            </div>
          }
        >
          {data2?.map((item2) => {
            const renderDataThree = data3?.filter((el3) => el3.parentId === item2?.id);
            return (
              <StyledTreeItem
                key={item2?.id}
                nodeId={item2?.id}
                label={
                  <div
                    className={classes.labelRoot}
                    style={{
                      fontSize: '25px',
                      width: '100%',
                      fontWeight: 'bold',
                    }}
                  >
                    <Typography variant="body1" className={classes.labelText}>
                      {item2?.nameVI} <span>({item2?.nameEN})</span>
                    </Typography>
                    <Typography variant="body1" color="inherit">
                      {item2?.csLevelName}
                    </Typography>
                  </div>
                }
              >
                {renderDataThree?.map((item3) => {
                  const renderDataFour = data4?.filter((el4) => el4.parentId === item3?.id);
                  return (
                    <StyledTreeItem
                      key={item3?.id}
                      nodeId={item3?.id}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography variant="body1" className={classes.labelText}>
                            {item3?.nameVI} <span>({item3?.nameEN})</span>
                          </Typography>
                          <Typography variant="body1" color="inherit">
                            {item3?.csLevelName}
                          </Typography>
                        </div>
                      }
                    >
                      {renderDataFour?.map((item4) => {
                        const renderDataFine = data5?.filter((el5) => el5.parentId === item4?.id);
                        return (
                          <StyledTreeItem
                            key={item4?.id}
                            nodeId={item4?.id}
                            label={
                              <div className={classes.labelRoot}>
                                <Typography variant="body2" className={classes.labelText}>
                                  {item4?.nameVI} <span>({item4?.nameEN})</span>
                                </Typography>
                                <Typography variant="body2" color="inherit">
                                  {item4?.csLevelName}
                                </Typography>
                              </div>
                            }
                          >
                            {renderDataFine?.map((item5) => {
                              return (
                                <StyledTreeItem
                                  key={item5?.id}
                                  nodeId={item5?.id}
                                  label={
                                    <div className={classes.labelRoot}>
                                      <Typography variant="body2" className={classes.labelText}>
                                        {item5?.nameVI} <span>({item5?.nameEN})</span>
                                      </Typography>
                                      <Typography variant="caption" color="inherit">
                                        {item5?.csLevelName}
                                      </Typography>
                                    </div>
                                  }
                                ></StyledTreeItem>
                              );
                            })}
                          </StyledTreeItem>
                        );
                      })}
                    </StyledTreeItem>
                  );
                })}
              </StyledTreeItem>
            );
          })}
        </StyledTreeItem>
      </TreeView>
    </>
  );
};
