import React from 'react';
import Utils from 'commons/utils';
import { Button } from '@mui/material';
import { SearchInputColumn, ShowListFile } from 'components';
import { CONTRACT_STATUS } from 'commons/constants';
import { RenderContractStatus } from 'components/custom-status';
import { Circle as CircleIcon } from '@mui/icons-material';

function displayArrService(dataRow = []) {
  if (!dataRow) return '';

  return dataRow?.map((item, index) => (
    <div key={index} style={{ textAlign: 'left' }}>
      <CircleIcon color="primary" sx={{ fontSize: 10, marginRight: '5px' }} />
      {item.name}
    </div>
  ));
}

export const getColumnsPersonal = (onSearchInputChange, deleteItem) => [
  {
    id: 'contractNumber',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: (
      <SearchInputColumn
        title="Mã hợp đồng"
        onChange={({ target }) => onSearchInputChange(target.value, 'contractNumber', true)}
      />
    ),
  },
  // {
  //   id: 'contractStatus',
  //   numeric: false,
  //   disablePadding: false,
  //   label: (
  //     <SearchInputColumn
  //       title="Trạng thái"
  //       onChange={({ target }) => onSearchInputChange(target.value, 'contractStatus', true)}
  //     />
  //   ),
  //   width: '10px',
  //   align: 'center',
  //   render: (row) => <RenderContractStatus status={row?.contractStatus} />,
  // },
  {
    id: 'clientCompanyCode',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Mã khách hàng"
        onChange={({ target }) => onSearchInputChange(target.value, 'clientCompanyName', true)}
      />
    ),
    width: '130px',
    align: 'center',
    render: (row) => row?.objClientCompany?.code || '',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Khách hàng"
        onChange={({ target }) => onSearchInputChange(target.value, 'clientCompanyName', true)}
      />
    ),
    width: '300px',
    align: 'center',
  },
  {
    id: 'arrService',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Dịch vụ"
        onChange={({ target }) => onSearchInputChange(target.value, 'typeContractName', true)}
      />
    ),
    align: 'center',
    render: (row) => displayArrService(row?.arrService),
  },
  {
    id: 'arrUrlfile',
    numeric: true,
    disablePadding: false,
    label: 'File đính kèm',
    align: 'center',
    render: (row) => <ShowListFile maxWidth="200px" fileList={row?.arrUrlfile} />,
  },
  {
    id: 'effectiveDate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày bắt đầu',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.effectiveDate),
  },
  {
    id: 'expireDate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày kết thúc',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.expireDate),
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.createdOn),
  },
];
