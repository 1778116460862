import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'components';
import { FormDetail } from './form-detail';
import { Box, Typography } from '@mui/material';
import ApiServices from 'services';
import { BsPlusCircle } from 'react-icons/bs';
import { Columns } from './columns';
import { ContainerHeader, ButtonAdd } from './styles';
import { RESPONSE_STATUSES } from 'commons/constants';
import { debounce } from 'lodash';

const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
};

function DeliveryMethod() {
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const initData = (query) => {
    setLoading(true);
    return ApiServices.getPagingDeliveryMethods(query ? query : filter)
      .then((res) => {
        if (res?.data) {
          if (totalRecords !== res?.data?.totalRecords) setTotalRecords(res?.data?.totalRecords);
          setDeliveryMethod(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('get Clients:', err))
      .finally(() => setLoading(false));
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const deleteItem = async (row) => {
    setLoading(true);
    return ApiServices.deleteDeliveryMethod(row.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          initData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteCell - companies', err);
      })
      .finally(() => setLoading(false));
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Hình Thức Giao Hàng
        </Typography>
      </ContainerHeader>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const handleClickDetail = (row) => {
    setSelected(row);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          filter={filter}
          rows={deliveryMethod}
          headCells={Columns(handleSearchColumn, deleteItem)}
          totalRecords={totalRecords}
          onPageChange={(paging) => {
            setFilter((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={handleClickDetail}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
    </>
  );
}
export { DeliveryMethod };
