import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const ImageSlider = ({ images, open, setOpen }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => {
    if (open) {
      const index = images.findIndex((item) => item.id == open.id);
      index !== -1 && setPhotoIndex(index);
    }
  }, [open]);
  if (!open) {
    return null;
  }
  const getImagesSrc = (imageObj) => {
    return (
      process.env.REACT_APP_FILE_API_URL +
      process.env.REACT_APP_FILE_PATH_PREFIX +
      `/streaming/${imageObj?.contentType && imageObj.contentType.replace('/', '%2F')}/${
        imageObj?.id
      }`
    );
  };
  return (
    <div
      className="crm-image-slider"
      style={{
        ' .ReactModal__Overlay': {
          zIndex: '99999 !important',
        },
      }}
    >
      <Lightbox
        reactModalStyle={{
          content: {
            zIndex: 99999,
          },
        }}
        reactModalProps={{ overlayClassName: 'css-modal-overplay' }}
        mainSrc={getImagesSrc(images[photoIndex])}
        nextSrc={getImagesSrc(images[(photoIndex + 1) % images.length])}
        prevSrc={getImagesSrc(images[(photoIndex + images.length - 1) % images.length])}
        onCloseRequest={() => setOpen(null)}
        onMovePrevRequest={() =>
          setPhotoIndex((prev) => (prev + images.length - 1) % images.length)
        }
        onMoveNextRequest={() => setPhotoIndex((prev) => (prev + 1) % images.length)}
      />
    </div>
  );
};
