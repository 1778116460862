import React, { useEffect, useState, useCallback } from 'react';
import { Table, SearchInputColumn } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, Typography } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { debounce } from 'lodash';
import { ContainerHeader, ButtonAdd } from './styles';

function PermissionSysFunc() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState(null);
  const [originData, setOriginData] = useState([]);

  const initData = async () => {
    setLoading(true);
    try {
      const res = await ApiServices.getPermissionSysFuncs();
      if (res?.data) {
        setData(res?.data || []);
        setOriginData(res?.data || []);
      }
    } catch (err) {
      console.warn('initData - Client Groups', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;

      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.controllerName) {
        if (
          !Utils.convertValSearch(item?.controllerName)?.includes(
            Utils.convertValSearch(query?.controllerName),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.actionName) {
        if (
          !Utils.convertValSearch(item?.actionName)?.includes(
            Utils.convertValSearch(query?.actionName),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.description) {
        if (
          !Utils.convertValSearch(item?.description)?.includes(
            Utils.convertValSearch(query?.description),
          )
        ) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );

  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deletePermissionSysFunc(row.id);

      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };
  const headCells = [
    {
      id: 'code',
      disablePadding: false,
      label: 'Mã',
      width: 10,
      sort: true,
      align: 'center',
      label: <SearchInputColumn name="code" title="Mã" onChange={onColumnSearch} />,
    },
    {
      id: 'controllerName',
      numeric: true,
      disablePadding: false,
      align: 'center',
      sort: true,
      label: (
        <SearchInputColumn name="controllerName" title="controllerName" onChange={onColumnSearch} />
      ),
    },
    {
      id: 'actionName',
      numeric: true,
      disablePadding: false,
      align: 'center',
      label: <SearchInputColumn name="actionName" title="Chức Năng" onChange={onColumnSearch} />,
    },
    {
      id: 'arrTagMenu',
      numeric: true,
      disablePadding: false,
      label: 'arrTagMenu',
      align: 'center',
      render: (row) => Utils.showArrayInTale(row.arrTagMenu),
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: <SearchInputColumn name="description" title="Mô tả" onChange={onColumnSearch} />,
      align: 'center',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Thao tác',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Phân Quyền Chức Năng
        </Typography>
      </ContainerHeader>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={data}
          headCells={headCells}
          onClickDetail={(row) => {
            setSelected(row);
          }}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
    </>
  );
}
export { PermissionSysFunc };
