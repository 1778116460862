import React from 'react';
import Utils from 'commons/utils';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã',
    align: 'center',
  },
  {
    id: 'carrierName',
    numeric: true,
    disablePadding: false,
    label: 'Hãng tàu',
    align: 'center',
    width: '200px',
  },
  {
    id: 'objPOL',
    numeric: true,
    disablePadding: false,
    label: 'Cảng xếp dỡ',
    align: 'center',
    width: '200px',
    render: (row) => `${row?.objPOL?.name} - ${row?.objPOL?.countryCode}`,
  },
  {
    id: 'objPOD',
    numeric: true,
    disablePadding: false,
    label: 'Cảng đên',
    align: 'center',
    width: '200px',
    render: (row) => `${row?.objPOD?.name} - ${row?.objPOD?.countryCode}`,
  },
  {
    id: 'currencyCode',
    numeric: true,
    disablePadding: false,
    label: 'Đơn vị tiền tệ',
    align: 'center',
    width: '100px',
  },
  {
    id: 'etd',
    numeric: true,
    disablePadding: false,
    label: 'ETD',
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row?.etd),
  },
  {
    id: 'expirydate',
    numeric: true,
    disablePadding: false,
    label: 'Hết hạn',
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row?.expirydate),
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
];
