import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Selection, TextInput, FileUploadContract, ComboBox } from 'components';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { styled } from '@mui/material/styles';

const RecordCreateForm = ({ onSubmit, isReadOnly }) => {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [file, setFile] = useState([]);
  const [note, setNote] = useState('');

  const isSubmitting = useMemo(() => {
    return !code || !name || !file || !file?.length > 0;
  }, [code, name, file]);

  const handleSubmit = async () => {
    const fileTmp = file?.map(([item]) => item);
    const dataSend = {
      code,
      name,
      expireDate,
      note,
      arrfile: fileTmp,
    };

    console.log({ dataSend });
    onSubmit && onSubmit(dataSend);
  };

  const handleChangeCode = useCallback(
    (e) => {
      setCode(e.target.value);
    },
    [code],
  );
  const handleChangeName = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [name],
  );
  const handleChangeEffectiveDate = useCallback(
    (e) => {
      setExpireDate(e.target.value);
    },
    [expireDate],
  );
  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );
  const handleChangeFile = useCallback(
    (e) => {
      console.log({ file: e.target.value });
      setFile(e.target.value);
    },
    [file],
  );

  return (
    <Box>
      <TextInput
        required
        error={!code}
        helperText={!code && 'Vui lòng nhập mã hồ sơ'}
        label="Mã hồ sơ"
        name="code"
        onChange={handleChangeCode}
        value={code}
      />
      <TextInput
        required
        error={!name}
        helperText={!name && 'Vui lòng nhập tên hồ sơ'}
        label="Tên hồ sơ"
        name="name"
        onChange={handleChangeName}
        value={name}
      />
      <TextInput
        // required
        // error={!expireDate}
        // helperText={!expireDate && 'Vui lòng nhập ngày kết thúc'}
        label="Ngày kết thúc"
        name="expireDate"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeEffectiveDate}
        value={expireDate}
      />
      <TextInput
        label="Ghi chú"
        name="summaryNote"
        onChange={handleChangeNote}
        value={note}
        multiline={true}
        rows={2}
      />
      <FileUploadContract
        error={!file?.length > 0}
        helperText={!file?.length > 0 && 'Vui lòng nhập file đính kèm'}
        label="File đính kèm"
        name="file"
        // onBlur={handleBlur}
        onChange={handleChangeFile}
        value={file}
        multiple={true}
      />
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnCreateUpdate
            color="primary"
            disabled={isSubmitting || isReadOnly}
            onClick={handleSubmit}
            variant="contained"
          >
            Thêm mới
          </BtnCreateUpdate>
        </Box>
      </Box>
    </Box>
  );
};

export { RecordCreateForm };

export const BtnCreateUpdate = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '5px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '6px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
