import React, { useEffect, useState, useRef, useCallback } from 'react';
import { List } from 'react-virtualized';

import {
  Typography,
  Box,
  InputBase,
  Stack,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMessages,
  addMessage,
  updateMessage,
  loadMore,
  deleteMessage,
} from 'store/reducers/chat-messages/action';
import { setSelectedChanel, updateChanel } from 'store/reducers/chat-chanel/action';
import { fetchUsers } from 'store/reducers/users/action';
import InfoIcon from '@mui/icons-material/Info';
import ChatServices from 'services/chat-services';
import moment from 'moment';
// import Socket from '../../../libs/socket';
import { Message as MessageItem } from './message-item';
import ChanelMenu from './chanel-menu';
import storage from 'storage';
import Color from 'themes/colors';
import { MessageInput } from './message-input';
import { ImageSlider, LoadingView } from 'components';
moment.locale('vi');
export const Messages = () => {
  const dispatch = useDispatch();
  const { messages, resultKey } = useSelector((state) => state.chatMessage);
  const { users } = useSelector((state) => state.users);
  const { selectedChanel } = useSelector((state) => state.chatChanel);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSend, setLoadingSend] = useState('');
  const [text, setText] = useState('');
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [openImageSlider, setOpenImageSlider] = useState(false);

  const initRef = useRef(true);
  const messageBodyRef = useRef(null);
  const handleScroll = async (event) => {
    if (loadingMore || !resultKey) {
      return;
    }
    setLoadingMore(true);
    if (messageBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageBodyRef.current;

      if (!initRef.current && clientHeight - scrollTop > scrollHeight - 100) {
        await dispatch(loadMore(selectedChanel._id, { resultKey }));
      }
    }
    setLoadingMore(false);
  };

  useEffect(() => {
    messageBodyRef.current.scrollTop = 0;
    const fetchData = async () => {
      setLoading(true);
      if (!selectedChanel?._id) {
        return;
      }
      initRef.current = true;
      await dispatch(fetchMessages(selectedChanel._id, { limit: 20 }));
      initRef.current = false;
      setLoading(false);
    };
    fetchData();
    setLoading(false);
  }, [selectedChanel?._id]);

  const sendMessage = useCallback(async () => {
    if (loadingSend) {
      return;
    }
    try {
      setLoadingSend(text);
      const reqData = {
        text,
        chanelId: selectedChanel._id,
        images,
        files,
      };
      setText('');
      setImages([]);
      setFiles([]);
      const { data } = await ChatServices.createMessage(reqData);

      if (data.code == 'success') {
        dispatch(addMessage(data.results[0]));
        const chanelTmp = selectedChanel;
        chanelTmp.lastMessage = data.results[0];
        dispatch(updateChanel(chanelTmp));
        dispatch(setSelectedChanel(chanelTmp));
        messageBodyRef.current && (messageBodyRef.current.scrollTop = 0);
      }
    } catch (error) {
      console.error('sendMessage - ERR', error);
    }
    setLoadingSend('');
  }, [text, files, images]);
  const renderName = () => {
    if (!selectedChanel || !selectedChanel?.isPersonal) {
      return selectedChanel?.name;
    }
    const userCode = selectedChanel.members?.find((el) => el.code != storage.user.Code);
    const user = userCode && users.find((el) => el.code == userCode.code);

    return user?.name || storage.user?.Name;
  };
  let imagesUrl = [];
  for (let el of messages) {
    if (el.images?.length) {
      imagesUrl = [...imagesUrl, ...el.images];
    }
  }
  const renderMessages = () => {
    let tmpMessage = null;

    return messages.map((item, index) => {
      var showAvatar = true;

      if (item.user === tmpMessage?.user && tmpMessage.ts - item.ts < 120000) {
        showAvatar = false;
      } else {
        tmpMessage = item;
      }

      return (
        <MessageItem
          key={`message-item-${item._id}`}
          item={item}
          isShowAvatar={showAvatar}
          setOpenImageSlider={setOpenImageSlider}
        />
      );
    });
  };
  return (
    <MessagesWrapper>
      <MessagesHeader>
        <MessagesHeaderTitle component={'span'}>{renderName()}</MessagesHeaderTitle>
        <ChanelMenu />
      </MessagesHeader>
      <MessagesContainer>
        <LoadingView loading={loading} />
        <MessagesBody ref={messageBodyRef} onScroll={handleScroll}>
          {loadingSend && (
            <MessageElement>
              <MessageContent>
                <TextWrapper component="pre">{loadingSend}</TextWrapper>
                <LoadingMessage>
                  <MuiCircularProgress size="15px" />
                </LoadingMessage>
              </MessageContent>
            </MessageElement>
          )}
          {renderMessages()}
          {loadingMore && (
            <MessageElement>
              <MessageContent style={{ justifyContent: 'center' }}>
                <LoadingMessage>
                  <MuiCircularProgress size="20px" />
                </LoadingMessage>
              </MessageContent>
            </MessageElement>
          )}
        </MessagesBody>
        {/* <List
          rowCount={messages.length}
          rowHeight={10}
          height="calc(100vh - 200px)"
          width="100%"
          rowRenderer={({
            key, // Unique key within array of rows
            index, // Index of row within collection
            isScrolling, // The List is currently being scrolled
            isVisible, // This row is visible within the List (eg it is not an overscanned row)
            style, // Style object to be applied to row (to position it)
            parent,
          }) => (
            <MessageItem
              style={style}
              key={`message-item-${messages[index]._id}`}
              item={messages[index]}
            />
          )}
        /> */}
        <MessageInput
          {...{ text, setText, sendMessage, loading, files, setFiles, images, setImages }}
        />
        <ImageSlider open={openImageSlider} setOpen={setOpenImageSlider} images={imagesUrl} />
      </MessagesContainer>
    </MessagesWrapper>
  );
};

const MuiCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: '5px',
  marginRight: '5px',
}));
const LoadingMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
const TextWrapper = styled(Box)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#3f219a',
  padding: '8px 16px',
  fontSize: '14px',
  borderRadius: '16px',
}));
const MessageContent = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  mb: '10px',
}));
const MessageElement = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBlock: '10px',
}));
const MessagesBody = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column-reverse',
  maxHeight: 'calc(100vh - 219px)',
  overflowY: 'scroll',
  overflowX: 'hidden',
  marginBottom: 5,
  position: 'relative',
}));
const MessagesHeaderTitle = styled(Box)(({ theme }) => ({
  // ...theme.typography.body2,
  flex: 1,
  fontWeight: 500,
}));
const MessagesWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: 'calc(100vh - 100px)',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  boxShadow: theme.palette.messageBoxShadow,
  borderRadius: '12px',
  padding: '12px',
  margin: 8,
  backgroundColor: '#FFFFFF',
}));
const MessagesContainer = styled(Box)(({ theme }) => ({
  // ...theme.typography.body2,
  color: theme.palette.text.secondary,
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: 'calc(100vh - 166px)',
}));
const MessagesHeader = styled(Box)(({ theme }) => ({
  // ...theme.typography.body2,
  color: theme.palette.text.secondary,
  display: 'flex',
  textTransform: 'capitalize',
  alignItems: 'center',
  fontWeight: '600',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));
