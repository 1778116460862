import actionTypes from './type';

const initialState = {
  quoteIdList: [],
  contractData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SELECTED_QUOTE_ID_LIST:
      return {
        ...state,
        quoteIdList: action.data,
      };
    case actionTypes.CLEAR_QUOTE_ID_LIST:
      return {
        ...state,
        quoteIdList: [],
      };
    case actionTypes.SELECTED_CONTRACT_DATA:
      return {
        ...state,
        contractData: action.data,
      };
    case actionTypes.UPDATE_CONTRACT_DATA:
      return {
        ...state,
        contractData: {
          ...state.contractData,
          ...action.data,
        },
      };
    case actionTypes.CLEAR_CONTRACT_DATA:
      return {
        ...state,
        contractData: {},
      };

    default:
      return state;
  }
}
