import Endpoint from './endpoints';

import ApiClient from './api-client';
import Utils from '../commons/utils';

class RestfullServices {
  constructor() {
    this.apiClient = new ApiClient({
      baseURL: process.env.REACT_APP_API_URL,
      pathPrefix: process.env.REACT_APP_PATH_PREFIX,
      logOutput: true,
    });
    this.apiClientHRM = new ApiClient({
      baseURL: process.env.REACT_APP_API_HRM_URL,
      pathPrefix: process.env.REACT_APP_PATH_PREFIX,
      logOutput: true,
    });
  }
  // Account Api
  login = ({ code, password }) => this.apiClient.post(Endpoint.login, { code, password });
  // Companies
  getCompanies = (data = {}) => this.apiClient.get(Endpoint.company, data);
  getGetDataCompany = (data) => this.apiClient.get(Endpoint.company + '/GetDataCompany', data);
  getCompany = (id) => this.apiClient.get(`${Endpoint.company}/${id}`);
  createCompany = (data) => this.apiClient.post(Endpoint.company, data);
  updateCompany = (id, data) => this.apiClient.put(`${Endpoint.company}/${id}`, data);
  deleteCompany = (id) => this.apiClient.delete(`${Endpoint.company}/${id}`);
  //Client-Sources
  getClientSources = (data) => this.apiClient.get(Endpoint.clientSource, data);
  createClientSource = (data) => this.apiClient.post(Endpoint.clientSource, data);
  updateClientSource = (id, data) => this.apiClient.put(Endpoint.clientSource + `/${id}`, data);
  deleteClientSource = (id) => this.apiClient.delete(Endpoint.clientSource + `/${id}`);
  // Department
  getDepartments = (data = {}) => this.apiClient.get(Endpoint.department, data);
  getDepartment = (id) => this.apiClient.get(`${Endpoint.department}/${id}`);
  getDepartmentByCompanyId = (companyId, data) =>
    this.apiClient.get(Endpoint.department + `/GetDepartmentByCompanyId/${companyId}`, data);
  createDepartment = (data) => this.apiClient.post(Endpoint.department, data);
  updateDepartment = (id, data) => this.apiClient.put(`${Endpoint.department}/${id}`, data);
  deleteDepartment = (id) => this.apiClient.delete(`${Endpoint.department}/${id}`);
  // positions
  getPositions = (data = {}) => this.apiClient.get(Endpoint.position, data);
  getPosition = (id) => this.apiClient.get(`${Endpoint.position}/${id}`);
  createPosition = (data) => this.apiClient.post(Endpoint.position, data);
  updatePosition = (id, data) => this.apiClient.put(`${Endpoint.position}/${id}`, data);
  deletePosition = (id) => this.apiClient.delete(`${Endpoint.position}/${id}`);
  //Client-Groups
  getClientGroups = (data) => this.apiClient.get(Endpoint.clientGroup, data);
  createClientGroup = (data) => this.apiClient.post(Endpoint.clientGroup, data);
  updateClientGroup = (id, data) => this.apiClient.put(Endpoint.clientGroup + `/${id}`, data);
  deleteClientGroup = (id) => this.apiClient.delete(Endpoint.clientGroup + `/${id}`);
  //Client-Companies
  getClientCompanies = (data) => this.apiClient.get(Endpoint.clientCompany, data);
  getClientCompanyDetail = (id, data) =>
    this.apiClient.get(Endpoint.clientCompany + `/${id}`, data);
  getfilterSupportBoard = (companyId, data) =>
    this.apiClient.get(
      Endpoint.clientCompany + `/GetfilterSupportBoard?companyId=${companyId}`,
      data,
    );
  createClientCompany = (data) => this.apiClient.post(Endpoint.clientCompany, data);
  updateClientCompany = (id, data) => this.apiClient.put(Endpoint.clientCompany + `/${id}`, data);
  deleteClientCompany = (id) => this.apiClient.delete(Endpoint.clientCompany + `/${id}`);
  importExcelClientCompany = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.clientCompany + '/ImportExcel', formData);
  };
  importExcelDealSuccess = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(
      Endpoint.clientCompany + '/ImportExcelDealSuccess',
      formData,
    );
  };
  changeAvatarClientCompany = (id, data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.clientCompany + `/ImportFileImage/${id}`, formData);
  };
  //Client-Status
  getClientStatus = (data) => this.apiClient.get(Endpoint.clientStatus, data);
  createClientStatus = (data) => this.apiClient.post(Endpoint.clientStatus, data);
  updateClientStatusDM = (id, data) => this.apiClient.put(Endpoint.clientStatus + `/${id}`, data);
  deleteClientStatus = (id) => this.apiClient.delete(Endpoint.clientStatus + `/${id}`);
  // users
  getUsers = (data = {}) => this.apiClient.get(Endpoint.user, data);
  getUser = (id) => this.apiClient.get(`${Endpoint.user}/${id}`);
  getUserByCode = (code) => this.apiClient.get(`${Endpoint.user}/GetCode/${code}`);
  createUser = (data) => this.apiClient.post(Endpoint.user, data);
  updateUser = (id, data) => this.apiClient.put(`${Endpoint.user}/${id}`, data);
  deleteUser = (id) => this.apiClient.delete(`${Endpoint.user}/${id}`);
  changePassword = (data) => this.apiClient.post(Endpoint.user + '/ChangePassword', data);
  resetPassword = (data) => this.apiClient.postCustom(Endpoint.user + '/ResetPassword', data);
  changeAvatar = (id, data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.user + `/UploadIcon?Userid=${id}`, formData);
  };
  //Clients
  getClients = (data) => this.apiClient.get(Endpoint.client, data);
  getClientDetail = (id, data) => this.apiClient.get(Endpoint.client + `/${id}`, data);
  createClient = (data) => this.apiClient.post(Endpoint.client, data);
  getPagingClient = (data) => this.apiClient.post(Endpoint.client + '/GetPaging', data);
  updateClient = (id, data) => this.apiClient.put(Endpoint.client + `/${id}`, data);
  deleteClient = (id) => this.apiClient.delete(Endpoint.client + `/${id}`);
  importExcelClient = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.client + '/ImportExcel', formData);
  };

  getListByClientCompanyId = (clientCompanyId, clientCareHistoryId = '', data = null) =>
    this.apiClient.get(
      Endpoint.client +
        `/GetListByClientCompanyId/${clientCompanyId}?ClientCareHistoryId=${clientCareHistoryId}`,
      data,
    );
  getFullListByClientCompanyId = (clientCompanyId, data = null) =>
    this.apiClient.get(Endpoint.client + `/GetFullListByClientCompanyId/${clientCompanyId}`, data);

  //clientCareProcess
  getClientCareProcess = (data) => this.apiClient.get(Endpoint.clientCareProcess, data);
  createClientCareProcess = (data) => this.apiClient.post(Endpoint.clientCareProcess, data);
  updateClientCareProcess = (id, data) =>
    this.apiClient.put(Endpoint.clientCareProcess + `/${id}`, data);
  deleteClientCareProcess = (id) => this.apiClient.delete(Endpoint.clientCareProcess + `/${id}`);
  //Client Complaints
  getClientComplaints = (data) => this.apiClient.get(Endpoint.clientComplaint, data);
  getPagingComplaints = (data) =>
    this.apiClient.post(Endpoint.clientComplaint + '/GetPaging', data);
  createClientComplaint = (data) => this.apiClient.post(Endpoint.clientComplaint, data);
  updateClientComplaint = (id, data) =>
    this.apiClient.put(Endpoint.clientComplaint + `/${id}`, data);
  deleteClientComplaint = (id) => this.apiClient.delete(Endpoint.clientComplaint + `/${id}`);
  updateStatusComplaint = (id, status) =>
    this.apiClient.postCustom(Endpoint.clientComplaint + `/UpdateStatus/${id}`, status);
  updateClientStatusCancel = (id, data) =>
    this.apiClient.put(Endpoint.clientComplaint + `/UpdateCancel?ClientComplaintid=${id}`, data);

  exportExcelClientComplaint = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientComplaint + `/ExportExcel`, data);
  importExcelClientComplaint = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.clientComplaint + '/ImportExcel', formData);
  };
  //Services
  getServices = (data) => this.apiClient.get(Endpoint.service, data);
  getServicesForCheckPrice = (data) =>
    this.apiClient.get(Endpoint.service + '/GetbyCheckPrice', data);
  createService = (data) => this.apiClient.post(Endpoint.service, data);
  updateService = (id, data) => this.apiClient.put(Endpoint.service + `/${id}`, data);
  deleteService = (id) => this.apiClient.delete(Endpoint.service + `/${id}`);
  getServicesByClient = (idClient, data) =>
    this.apiClient.get(Endpoint.service + `/GetByClient/${idClient}`, data);
  //Employees
  getEmployees = (data) => this.apiClient.get(Endpoint.employee, data);
  createEmployee = (data) => this.apiClient.post(Endpoint.employee, data);
  updateEmployee = (id, data) => this.apiClient.put(Endpoint.employee + `/${id}`, data);
  deleteEmployee = (id) => this.apiClient.delete(Endpoint.employee + `/${id}`);
  //Loyalty Types
  getLoyaltyTypes = (data) => this.apiClient.get(Endpoint.loyaltyType, data);
  createLoyaltyType = (data) => this.apiClient.post(Endpoint.loyaltyType, data);
  updateLoyaltyType = (id, data) => this.apiClient.put(Endpoint.loyaltyType + `/${id}`, data);
  deleteLoyaltyType = (id) => this.apiClient.delete(Endpoint.loyaltyType + `/${id}`);
  //get Enums
  getEnums = () => this.apiClient.requestAnother('GET', 'Data/Enum.json');
  //documents
  getDocuments = (data) => this.apiClient.get(Endpoint.document, data);
  createDocument = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.document, formData);
  };
  updateDocument = (id, data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.putFormData(Endpoint.document + `/${id}`, formData);
  };
  deleteDocument = (id) => this.apiClient.delete(Endpoint.document + `/${id}`);
  getDocumentByStatus = (status) => this.apiClient.get(Endpoint.getByFileStatus + `/${status}`);
  // emails
  getMails = (data) => this.apiClient.get(Endpoint.mail, data);
  createMail = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.postAndSendMail, formData);
  };
  sendEmail = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.sendEmail, formData);
  };
  updateMail = (id, data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.putFormData(Endpoint.mail + `/${id}`, formData);
  };
  deleteMail = (id) => this.apiClient.delete(Endpoint.mail + `/${id}`);
  // Client Care History
  getClientCareHistories = (data) => this.apiClient.get(Endpoint.clientCareHistory, data);
  createClientCareHistory = (data) => this.apiClient.post(Endpoint.clientCareHistory, data);
  getClientCareHistoryByStatus = (id, data = null) =>
    this.apiClient.get(Endpoint.clientCareHistory + `/GetListByStatus/${id}`, data);
  updateClientStatus = (idClientCareHistory, idStatus) =>
    this.apiClient.postCustom(
      Endpoint.clientCareHistory + `/UpdateClientStatus/${idClientCareHistory}`,
      idStatus,
    );
  updateApproveProcess = (idClientCareHistory, arrIdStep) =>
    this.apiClient.postCustom(
      Endpoint.clientCareHistory + `/UpdateApproveProcess/${idClientCareHistory}`,
      arrIdStep,
    );
  getClientCareHistoryDetails = (id, data = null) =>
    this.apiClient.get(Endpoint.clientCareHistory + `/${id}`, data);
  updateClientandUserMain = (id, data) =>
    this.apiClient.post(Endpoint.clientCareHistory + `/UpdateClientandUserMain/${id}`, data);
  // Khach hang tam ngung dich vu /api/ClientCareHistory/UpdateServiceOff/{ClientCompanyId}
  updateServiceOff = (clientCompanyId, data) =>
    this.apiClient.postCustom(
      Endpoint.clientCareHistory + `/UpdateServiceOff/${clientCompanyId}`,
      data,
    );
  // Schedule Management
  getSchedulesManagement = (data) => this.apiClient.get(Endpoint.scheduleManagement, data);
  getScheduleByClientCareHistory = (id, intCustomerType = '', data = null) =>
    this.apiClient.get(
      Endpoint.scheduleManagement +
        `/GetbyClientCareHistory/${id}?intCustomerType=${intCustomerType}`,
      data,
    );
  createScheduleManagement = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.scheduleManagement, formData);
  };

  updateScheduleManagement = (id, data) =>
    this.apiClient.put(Endpoint.scheduleManagement + `/${id}`, data);
  deleteScheduleManagement = (id) => this.apiClient.delete(Endpoint.scheduleManagement + `/${id}`);
  // Call Management
  getCallsManagement = (data) => this.apiClient.get(Endpoint.callManagement, data);
  getCallByClientCareHistory = (id, intCustomerType = '', data = null) =>
    this.apiClient.get(
      Endpoint.callManagement + `/GetbyClientCareHistory/${id}?intCustomerType=${intCustomerType}`,
      data,
    );
  createCallManagement = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.callManagement, formData);
  };
  // Email Management
  getEmailsManagement = (data) => this.apiClient.get(Endpoint.emailManagement, data);
  getMailByClientCareHistory = (id, intCustomerType = '', data = null) =>
    this.apiClient.get(
      Endpoint.emailManagement + `/GetbyClientCareHistory/${id}?intCustomerType=${intCustomerType}`,
      data,
    );
  // home
  getHomeData = (data) => this.apiClient.get(Endpoint.home, data);
  //home - Manager
  getScheduleManagement = (data) => this.apiClient.post(Endpoint.scheduleOfManager, data);
  getSalesTarget = (data) => this.apiClient.get(Endpoint.salesTarget, data);
  countClient = (data) => this.apiClient.get(Endpoint.countClient, data);
  countClientByUser = (data) => this.apiClient.get(Endpoint.countClientByUser, data);
  countSchedule = (data) => this.apiClient.get(Endpoint.countSchedule, data);
  // Permission Menu
  getPermissionMenus = (data = {}) => this.apiClient.get(Endpoint.permissionMenu, data);
  createPermissionMenu = (data) => this.apiClient.post(Endpoint.permissionMenu, data);
  updatePermissionMenu = (id, data) => this.apiClient.put(`${Endpoint.permissionMenu}/${id}`, data);
  deletePermissionMenu = (id) => this.apiClient.delete(`${Endpoint.permissionMenu}/${id}`);
  // Permission Sys Function
  getPermissionSysFuncs = (data = {}) => this.apiClient.get(Endpoint.permissionSysFunc, data);
  createPermissionSysFunc = (data) => this.apiClient.post(Endpoint.permissionSysFunc, data);
  updatePermissionSysFunc = (id, data) =>
    this.apiClient.put(`${Endpoint.permissionSysFunc}/${id}`, data);
  deletePermissionSysFunc = (id) => this.apiClient.delete(`${Endpoint.permissionSysFunc}/${id}`);
  // Permission MemberGroup
  getPermissionMemberGroups = (data = {}) =>
    this.apiClient.get(Endpoint.permissionMemberGroup, data);
  createPermissionMemberGroup = (data) => this.apiClient.post(Endpoint.permissionMemberGroup, data);
  updatePermissionMemberGroup = (id, data) =>
    this.apiClient.put(`${Endpoint.permissionMemberGroup}/${id}`, data);
  deletePermissionMemberGroup = (id) =>
    this.apiClient.delete(`${Endpoint.permissionMemberGroup}/${id}`);
  getMemberGroupById = (id, data = null) =>
    this.apiClient.get(Endpoint.permissionMemberGroup + `/${id}`, data);
  getMenuPermission = (id, data = null) =>
    this.apiClient.get(Endpoint.permissionMemberGroup + `/GetMenuPermission/${id}`, data);
  setMenus = (id, data) =>
    this.apiClient.post(Endpoint.permissionMemberGroup + `/setMenu/${id}`, data);

  getSysFunctionPermission = (id, data = null) =>
    this.apiClient.get(Endpoint.permissionMemberGroup + `/GetSysFunctionPermission/${id}`, data);
  setPermission = (id, data) =>
    this.apiClient.post(Endpoint.permissionMemberGroup + `/SetPermission/${id}`, data);

  getUserPermission = (id, data = null) =>
    this.apiClient.get(Endpoint.user + `/GetUserPermission/${id}`, data);
  setMemberGroup = (data) => this.apiClient.post(Endpoint.user + `/SetMemberGroup`, data);

  // Schedule User
  getScheduleUsers = (data = {}) => this.apiClient.get(Endpoint.scheduleUser, data);

  updateScheduleUser = (id, data) => this.apiClient.put(`${Endpoint.scheduleUser}/${id}`, data);
  deleteScheduleUser = (id) => this.apiClient.delete(`${Endpoint.scheduleUser}/${id}`);
  // Set Email User
  updateEmailUser = (id, data) => this.apiClient.post(`${Endpoint.setEmailUser}/${id}`, data);
  // ClientTrash
  getClientTrashes = (data) => this.apiClient.get(Endpoint.clientTrash, data);
  pagingClientTrashes = (data) => this.apiClient.post(Endpoint.clientTrash + '/GetPaging', data);
  getClientTrash = (data) => this.apiClient.get(Endpoint.clientTrash, data);
  createClientTrash = (data) => this.apiClient.post(Endpoint.clientTrash, data);
  updateClientTrash = (id, data) => this.apiClient.put(`${Endpoint.clientTrash}/${id}`, data);
  deleteClientTrash = (id) => this.apiClient.delete(`${Endpoint.clientTrash}/${id}`);
  updateClientTrashStatus = (data) =>
    this.apiClient.post(Endpoint.clientTrash + '/UpdateStatus', data);
  // new lead
  updateServiceReceived = (data) => this.apiClient.postCustom(`${Endpoint.serviceReceived}`, data);
  getNewLead = (data) => this.apiClient.post(Endpoint.getNewLead, data);
  getCustomers = (data) => this.apiClient.post(Endpoint.getCustomer, data);
  cancelNewLead = (id) => this.apiClient.delete(`${Endpoint.cancelNewLead}/${id}`);
  // GetListByPositionId
  getListByPositionId = (positionId = 'admin', clientCareHistoryId = '', data = null) =>
    this.apiClient.get(
      Endpoint.getListByPositionId + `/${positionId}?ClientCareHistoryId=${clientCareHistoryId}`,
      data,
    );
  // Menu
  getMenus = (data = {}) => this.apiClient.get(Endpoint.menu, data);
  getMenu = (id) => this.apiClient.get(`${Endpoint.menu}/${id}`);
  createMenu = (data) => this.apiClient.post(Endpoint.menu, data);
  updateMenu = (id, data) => this.apiClient.put(`${Endpoint.menu}/${id}`, data);
  deleteMenu = (id) => this.apiClient.delete(`${Endpoint.menu}/${id}`);
  //Client rating
  getClientRating = (data) => this.apiClient.get(Endpoint.clientRating, data);
  createClientRating = (data) => this.apiClient.post(Endpoint.clientRating, data);
  updateClientRating = (id, data) => this.apiClient.put(`${Endpoint.clientRating}/${id}`, data);
  deleteClientRating = (id) => this.apiClient.delete(`${Endpoint.clientRating}/${id}`);
  updateEvaluationCriteria = (id, data) =>
    this.apiClient.post(`${Endpoint.evaluationCriteria}/${id}`, data);
  // Manager
  getCountClientCareHistoryByUserCode = (data = {}) =>
    this.apiClient.get(Endpoint.manager + '/CountClientCareHistoryByUserCode', data);
  // =====================  Export Excel   ===========================================
  exportExcelClient = (data) =>
    this.apiClient.getCustomBlod(Endpoint.client + `/ExportExcel`, data);
  exportExcelManager = (data) =>
    this.apiClient.getCustomBlod(Endpoint.manager + `/ExportExcel`, data);

  exportTemplateClientComplaint = (id, data) =>
    this.apiClient.postCustomBlob(Endpoint.clientComplaint + `/FileWord/${id}`, data);
  exportExcel_TamNgung = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientCompany + `/ExportExcel_TamNgung`, data);

  exportExcelClientCompany = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientCompany + `/ExportExcel`, data);
  exportExcelDeal = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientCompany + `/ExportExcelDeal`, data);
  exportExcelDeal = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientCompany + `/ExportExcelDeal`, data);

  exportExcelClientTrash = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientTrash + `/ExportExcel`, data);

  exportTemplates = (key, data) =>
    this.apiClient.getCustomBlod(Endpoint.home + `/ExportTemplate/${key}`, data);

  // =====================  Import Excel   ===========================================
  importExcelClientTrashes = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.clientTrash + '/ImportExcel', formData);
  };
  // =====================  HRM   ====================================================
  //Companies from HRM
  getCompaniesHRM = (data = {}) => this.apiClientHRM.get(Endpoint.company, data);
  getCompanyHRM = (id) => this.apiClientHRM.get(`${Endpoint.company}/${id}`);
  createCompanyHRM = (data) => this.apiClientHRM.post(Endpoint.company, data);
  updateCompanyHRM = (id, data) => this.apiClientHRM.put(`${Endpoint.company}/${id}`, data);
  deleteCompanyHRM = (id) => this.apiClientHRM.delete(`${Endpoint.company}/${id}`);
  // client evaluation criteria
  getClientEvaluationCriteria = (data) =>
    this.apiClient.get(Endpoint.clientEvaluationCriteria, data);
  createClientEvaluationCriteria = (data) =>
    this.apiClient.postCustom(Endpoint.clientEvaluationCriteria + '/PostList', data);
  updateClientEvaluationCriteria = (data) =>
    this.apiClient.putCustom(Endpoint.clientEvaluationCriteria + '/PutList', data);
  // get full list
  getFullListDealCustomer = (data) => this.apiClient.get(Endpoint.getFullListDealCustomer, data);
  getFullListByClientCompanyId = (id, data) =>
    this.apiClient.get(Endpoint.getFullListByClientCompanyId + `/${id}`, data);
  //Area-Page
  getAreas = (data) => this.apiClient.get(Endpoint.area, data);
  createArea = (data) => this.apiClient.post(Endpoint.area, data);
  updateArea = (id, data) => this.apiClient.put(Endpoint.area + `/${id}`, data);
  deleteArea = (id) => this.apiClient.delete(Endpoint.area + `/${id}`);
  //Country-Page
  getCountries = (data) => this.apiClient.get(Endpoint.country, data);
  createCountry = (data) => this.apiClient.post(Endpoint.country, data);
  updateCountry = (id, data) => this.apiClient.put(Endpoint.country + `/${id}`, data);
  deleteCountry = (id) => this.apiClient.delete(Endpoint.country + `/${id}`);
  //Carrier-Page
  getCarriers = (data) => this.apiClient.get(Endpoint.carrier, data);
  getCarrierByType = (type = 0, data) =>
    this.apiClient.get(Endpoint.carrier + `/GetbyCarrierType?IsCarrier=${type}`, data);
  createCarrier = (data) => this.apiClient.post(Endpoint.carrier, data);
  updateCarrier = (id, data) => this.apiClient.put(Endpoint.carrier + `/${id}`, data);
  deleteCarrier = (id) => this.apiClient.delete(Endpoint.carrier + `/${id}`);
  //Currency-Page
  getCurrencies = (data) => this.apiClient.get(Endpoint.currency, data);
  createCurrency = (data) => this.apiClient.post(Endpoint.currency, data);
  updateCurrency = (id, data) => this.apiClient.put(Endpoint.currency + `/${id}`, data);
  deleteCurrency = (id) => this.apiClient.delete(Endpoint.currency + `/${id}`);
  //LocationVT-Page
  getLocationVTs = (data) => this.apiClient.get(Endpoint.locationVT, data);
  createLocationVT = (data) => this.apiClient.post(Endpoint.locationVT, data);
  updateLocationVT = (id, data) => this.apiClient.put(Endpoint.locationVT + `/${id}`, data);
  deleteLocationVT = (id) => this.apiClient.delete(Endpoint.locationVT + `/${id}`);
  //Port-Page
  getPorts = (data) => this.apiClient.get(Endpoint.port, data);
  getPortsByType = (type = 0, data) =>
    this.apiClient.get(Endpoint.port + `/GetbyTypePort?IsPort=${type}`, data);
  getTransShipmentPort = (data) =>
    this.apiClient.get(Endpoint.port + `/GetbyTypePortUse/transshipmentport`, data);
  createPort = (data) => this.apiClient.post(Endpoint.port, data);
  updatePort = (id, data) => this.apiClient.put(Endpoint.port + `/${id}`, data);
  deletePort = (id) => this.apiClient.delete(Endpoint.port + `/${id}`);
  //Cargo Type-Page
  getCargoTypes = (data) => this.apiClient.get(Endpoint.cargoType, data);
  createCargoType = (data) => this.apiClient.post(Endpoint.cargoType, data);
  updateCargoType = (id, data) => this.apiClient.put(Endpoint.cargoType + `/${id}`, data);
  deleteCargoType = (id) => this.apiClient.delete(Endpoint.cargoType + `/${id}`);
  //Unit-Page
  getUnits = (data) => this.apiClient.get(Endpoint.unit, data);
  createUnit = (data) => this.apiClient.post(Endpoint.unit, data);
  updateUnit = (id, data) => this.apiClient.put(Endpoint.unit + `/${id}`, data);
  deleteUnit = (id) => this.apiClient.delete(Endpoint.unit + `/${id}`);
  //Subservice-Page
  getsubServices = (data) => this.apiClient.get(Endpoint.subService, data);
  getSubServiceByServiceId = (id, data) =>
    this.apiClient.get(Endpoint.subService + `/GetbyService/${id}`, data);
  createsubService = (data) => this.apiClient.post(Endpoint.subService, data);
  updatesubService = (id, data) => this.apiClient.put(Endpoint.subService + `/${id}`, data);
  deletesubService = (id) => this.apiClient.delete(Endpoint.subService + `/${id}`);
  //Cont Type Page
  getContTypes = (data) => this.apiClient.get(Endpoint.contType, data);
  createContType = (data) => this.apiClient.post(Endpoint.contType, data);
  updateContType = (id, data) => this.apiClient.put(Endpoint.contType + `/${id}`, data);
  deleteContType = (id) => this.apiClient.delete(Endpoint.contType + `/${id}`);
  //Cont Type Page
  getContractTypes = (data) => this.apiClient.get(Endpoint.contractType, data);
  createContractType = (data) => this.apiClient.post(Endpoint.contractType, data);
  updateContractType = (id, data) => this.apiClient.put(Endpoint.contractType + `/${id}`, data);
  deleteContractType = (id) => this.apiClient.delete(Endpoint.contractType + `/${id}`);
  //Sub Fee Group Page
  getSubFeeGroups = (data) => this.apiClient.get(Endpoint.subFeeGroup, data);
  getSubFeeGroupBySS = (subServiceId, serviceId, data) =>
    this.apiClient.get(
      Endpoint.subFeeGroup + `/GetServiceandSS?SubserviceId=${subServiceId}&serviceId=${serviceId}`,
      data,
    );
  createSubFeeGroup = (data) => this.apiClient.post(Endpoint.subFeeGroup, data);
  updateSubFeeGroup = (id, data) => this.apiClient.put(Endpoint.subFeeGroup + `/${id}`, data);
  deleteSubFeeGroup = (id) => this.apiClient.delete(Endpoint.subFeeGroup + `/${id}`);
  //Lease Space Page
  getLeaseSpaces = (data) => this.apiClient.get(Endpoint.leaseSpace, data);
  createLeaseSpace = (data) => this.apiClient.post(Endpoint.leaseSpace, data);
  updateLeaseSpace = (id, data) => this.apiClient.put(Endpoint.leaseSpace + `/${id}`, data);
  deleteLeaseSpace = (id) => this.apiClient.delete(Endpoint.leaseSpace + `/${id}`);
  //PackingAir Page
  getPackingAirs = (data) => this.apiClient.get(Endpoint.packingAir, data);
  createPackingAir = (data) => this.apiClient.post(Endpoint.packingAir, data);
  updatePackingAir = (id, data) => this.apiClient.put(Endpoint.packingAir + `/${id}`, data);
  deletePackingAir = (id) => this.apiClient.delete(Endpoint.packingAir + `/${id}`);
  //Surcharge Page
  getSurcharges = (data) => this.apiClient.get(Endpoint.surCharge, data);
  getPagingSurcharges = (data) => this.apiClient.post(Endpoint.surCharge + '/GetPaging', data);
  createSurcharge = (data) => this.apiClient.post(Endpoint.surCharge, data);
  updateSurcharge = (id, data) => this.apiClient.put(Endpoint.surCharge + `/${id}`, data);
  deleteSurcharge = (id) => this.apiClient.delete(Endpoint.surCharge + `/${id}`);
  getSurchargeByFeeGroupSS = (subServiceId, group = true, data) =>
    this.apiClient.get(
      Endpoint.surCharge + `/GetByFeegroupSS?subserviceID=${subServiceId}&group=${group}`,
      data,
    );
  getSurchargeByFeeGroupSSAndFeeId = (feeGroupID, subServiceId, group = true, data) =>
    this.apiClient.get(
      Endpoint.surCharge +
        `/GetByFeegroupSS?feegroupID=${feeGroupID}&subserviceID=${subServiceId}&group=${group}`,
      data,
    );
  getSurchargeByFeeGroupId = (feegroupID, data) =>
    this.apiClient.get(Endpoint.surCharge + `/GetByFeegroup?feegroupID=${feegroupID}`, data);
  // Freight Page
  getFreights = (data) => this.apiClient.get(Endpoint.freight, data);
  getFreightBySubService = (subServiceId, data) =>
    this.apiClient.post(Endpoint.freight + `/GetPagingSS/${subServiceId}`, data);
  createFreight = (data) => this.apiClient.post(Endpoint.freight, data);
  updateFreight = (id, data) => this.apiClient.put(Endpoint.freight + `/${id}`, data);
  deleteFreight = (id) => this.apiClient.delete(Endpoint.freight + `/${id}`);
  exportExcelFclFreight = (data) =>
    this.apiClient.getCustomBlod(Endpoint.freight + `/ExportExcelFCL`, data);
  importExcelFclFreight = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.freight + '/ImportExcelFCL', formData);
  };
  // Customs Page
  getCustoms = (data) => this.apiClient.get(Endpoint.customs, data);
  getCustomsBySubService = (subServiceId, data) =>
    this.apiClient.post(Endpoint.customs + `/GetPagingSS/${subServiceId}`, data);
  createCustoms = (data) => this.apiClient.post(Endpoint.customs, data);
  updateCustoms = (id, data) => this.apiClient.put(Endpoint.customs + `/${id}`, data);
  deleteCustoms = (id) => this.apiClient.delete(Endpoint.customs + `/${id}`);
  // Trucking Page
  getTruckings = (data) => this.apiClient.get(Endpoint.trucking, data);
  getTruckingBySubService = (subServiceId, data) =>
    this.apiClient.post(Endpoint.trucking + `/GetPagingSS/${subServiceId}`, data);
  createTrucking = (data) => this.apiClient.post(Endpoint.trucking, data);
  updateTrucking = (id, data) => this.apiClient.put(Endpoint.trucking + `/${id}`, data);
  deleteTrucking = (id) => this.apiClient.delete(Endpoint.trucking + `/${id}`);
  //Vendor Page
  getLeaseVendors = (data) => this.apiClient.get(Endpoint.vendor, data);
  createVendor = (data) => this.apiClient.post(Endpoint.vendor, data);
  updateVendor = (id, data) => this.apiClient.put(Endpoint.vendor + `/${id}`, data);
  deleteVendor = (id) => this.apiClient.delete(Endpoint.vendor + `/${id}`);
  // Warehouse Page
  getWarehouses = (data) => this.apiClient.get(Endpoint.warehouse, data);
  getWarehouseBySubService = (subServiceId, data) =>
    this.apiClient.post(Endpoint.warehouse + `/GetPagingSS/${subServiceId}`, data);
  createWarehouse = (data) => this.apiClient.post(Endpoint.warehouse, data);
  updateWarehouse = (id, data) => this.apiClient.put(Endpoint.warehouse + `/${id}`, data);
  deleteWarehouse = (id) => this.apiClient.delete(Endpoint.warehouse + `/${id}`);
  // Check Prices Page
  getCheckPrices = (data) => this.apiClient.get(Endpoint.checkPrice, data);
  getCheckPricesByService = (checkPriceId, serviceId, data) =>
    this.apiClient.get(
      Endpoint.checkPrice + `/GetbyService?checkpriceId=${checkPriceId}&serviceId=${serviceId}`,
      data,
    );
  createCheckPrice = (data) => this.apiClient.post(Endpoint.checkPrice, data);
  updateCheckPrice = (id, data) => this.apiClient.put(Endpoint.checkPrice + `/${id}`, data);
  deleteCheckPrice = (id) => this.apiClient.delete(Endpoint.checkPrice + `/${id}`);
  createCheckPricePostService = (serviceId, data) =>
    this.apiClient.postCustom(Endpoint.checkPrice + `/PostServices/${serviceId}`, data);
  updateCheckPriceEditByService = (serviceId, data) =>
    this.apiClient.putCustom(Endpoint.checkPrice + `/EditbyServices/${serviceId}`, data);
  deleteCheckPriceByServiceId = (id, serviceId) =>
    this.apiClient.delete(Endpoint.checkPrice + `/DeleteCheck/${id}?serviceid=${serviceId}`);
  // Quotes Page
  getQuotes = (data) => this.apiClient.get(Endpoint.quote, data);
  getPagingQuotes = (data, clientCompanyId = '') =>
    this.apiClient.post(Endpoint.quote + `/GetPaging?ClientCompanyId=${clientCompanyId}`, data);
  createQuote = (data) => this.apiClient.post(Endpoint.quote, data);
  updateQuote = (id, data) => this.apiClient.put(Endpoint.quote + `/${id}`, data);
  deleteQuote = (id) => this.apiClient.delete(Endpoint.quote + `/${id}`);
  getDataQuotesFreight = (subServiceId, data) =>
    this.apiClient.post(Endpoint.quote + `/GetDataQuotesFreight/${subServiceId}`, data);
  getDataQuotesCustoms = (subServiceId, data) =>
    this.apiClient.post(Endpoint.quote + `/GetDataQuotesCustoms/${subServiceId}`, data);
  getDataQuotesTrucking = (subServiceId, data) =>
    this.apiClient.post(Endpoint.quote + `/GetDataQuotesTrucking/${subServiceId}`, data);
  getDataQuotesWarehouse = (subServiceId, data) =>
    this.apiClient.post(Endpoint.quote + `/GetDataQuotesWarehouse/${subServiceId}`, data);

  getQuoteDetail = (quoteId, isGroup = false, data) =>
    this.apiClient.get(Endpoint.quote + `/GetDetail/${quoteId}/${isGroup}`, data);
  createFreightQuote = (data) => this.apiClient.post(Endpoint.quote + '/PostFreightQuotes', data);
  updateFreightQuote = (data) => this.apiClient.put(Endpoint.quote + `/PutFreightQuotes`, data);
  createTruckingQuote = (data) => this.apiClient.post(Endpoint.quote + '/PostTruckingQuotes', data);
  updateTruckingQuote = (data) => this.apiClient.put(Endpoint.quote + `/PutTruckingQuotes`, data);
  createCustomsQuote = (data) => this.apiClient.post(Endpoint.quote + '/PostCustomsQuotes', data);
  updateCustomsQuote = (data) => this.apiClient.put(Endpoint.quote + `/PutCustomsQuotes`, data);
  createWarehouseQuote = (data) =>
    this.apiClient.post(Endpoint.quote + '/PostWarehouseQuotes', data);
  updateWarehouseQuote = (data) => this.apiClient.put(Endpoint.quote + `/PutWarehouseQuotes`, data);
  createCustomTruckQuote = (data) =>
    this.apiClient.post(Endpoint.quote + '/PostCustomTruckQuotes', data);
  updateCustomTruckQuote = (data) =>
    this.apiClient.put(Endpoint.quote + `/PutCustomTruckQuotes`, data);
  //Contract
  getContracts = (data) => this.apiClient.get(Endpoint.contract, data);
  getDetailContract = (contractId, data) =>
    this.apiClient.get(Endpoint.contract + `/GetDetail/${contractId}`, data);

  createContract = (data) => {
    const formData = Utils.convertJsonToFormDataForContract(data);
    return this.apiClient.postFormData(Endpoint.contract, formData);
  };
  updateContract = (id, data) => {
    const formData = Utils.convertJsonToFormDataForContract(data);
    return this.apiClient.putFormData(Endpoint.contract + `/${id}`, formData);
  };
  deleteContract = (id) => this.apiClient.delete(Endpoint.contract + `/${id}`);

  exportExcelContract = (data) =>
    this.apiClient.getCustomBlod(Endpoint.contract + `/ExportExcel`, data);

  // Thêm mới phụ lục - Hợp đồng
  createContractAppendix = (contractId, data) => {
    const formData = Utils.convertJsonToFormDataForContract(data);
    return this.apiClient.postFormData(
      Endpoint.contract + `/AddfileContract/${contractId}`,
      formData,
    );
  };

  // xóa phụ lục HĐ
  getRemoveFileContract = (contractId, strArrFileAddendumCode, data) =>
    this.apiClient.get(
      Endpoint.contract +
        `/RemoveFileContract?strContractId=${contractId}&strArrFileAddendumCode=${strArrFileAddendumCode}`,
      data,
    );

  // Thêm mới hồ sơ - Hợp đồng
  createContractRecord = (contractId, data) => {
    const formData = Utils.convertJsonToFormDataForContract(data);
    return this.apiClient.postFormData(
      Endpoint.contract + `/AddfileContractDocument/${contractId}`,
      formData,
    );
  };

  // xóa hồ sơ HĐ
  getRemoveContractRecord = (contractId, recordCode, data) =>
    this.apiClient.get(
      Endpoint.contract +
        `/RemoveFileContractDocument?strContractId=${contractId}&strArrFileDocumentCode=${recordCode}`,
      data,
    );
  // Contract Tab cá nhân
  getPagingContracts = (data) =>
    this.apiClient.post(Endpoint.contract + '/GetPagingByUser/true', data);
  // Contract Tab quản lí
  getAllPagingContracts = (data) => this.apiClient.post(Endpoint.contract + '/GetAllPaging', data);

  getDetailQuotes = (data) =>
    this.apiClient.postCustom(Endpoint.contract + '/GetDetailQuotes', data);
  createContractQuote = (data) => this.apiClient.post(Endpoint.contract, data);
  updateContractQuote = (id, data) => this.apiClient.put(Endpoint.contract + `/${id}`, data);
  // Report Template of Quote
  // Freight
  exportFileOfFreightFclLcl = (subServiceId, quoteId, data) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportTemplate + `/FileWordFCL_LCL?subserviceid=${subServiceId}&quoteid=${quoteId}`,
      data,
    );
  exportFileOfFreightAir = (subServiceId, quoteId, data) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportTemplate + `/FileWordHK?subserviceid=${subServiceId}&quoteid=${quoteId}`,
      data,
    );
  exportFileOfTractor = (subServiceId, quoteId, data) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportTemplate + `/FileWordTractor?subserviceid=${subServiceId}&quoteid=${quoteId}`,
      data,
    );
  exportFileOfWarehouse = (subServiceId, quoteId, data) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportTemplate + `/FileWordBWMS?subserviceid=${subServiceId}&quoteid=${quoteId}`,
      data,
    );
  //Client-Support-Board
  getClientSupportBoards = (data) => this.apiClient.get(Endpoint.clientSupportBoard, data);
  getClientSupportBoardsByClientCompany = (share = false, data) =>
    this.apiClient.get(
      Endpoint.clientSupportBoard + `/GetByClientCompany?sharecompany=${share}`,
      data,
    );
  createClientSupportBoard = (data) => this.apiClient.post(Endpoint.clientSupportBoard, data);
  updateClientSupportBoard = (id, data) =>
    this.apiClient.put(Endpoint.clientSupportBoard + `/${id}`, data);
  deleteClientSupportBoard = (id) => this.apiClient.delete(Endpoint.clientSupportBoard + `/${id}`);
  // Co-loader
  getColoaders = (data = {}) => this.apiClient.get(Endpoint.coloader, data);
  createColoader = (data) => this.apiClient.post(Endpoint.coloader, data);
  updateColoader = (id, data) => this.apiClient.put(`${Endpoint.coloader}/${id}`, data);
  deleteColoader = (id) => this.apiClient.delete(`${Endpoint.coloader}/${id}`);
  // Delivery-method
  getDeliveryMethods = (data = {}) => this.apiClient.get(Endpoint.deliveryMethod, data);
  getPagingDeliveryMethods = (data) =>
    this.apiClient.post(Endpoint.deliveryMethod + '/GetPaging', data);
  createDeliveryMethod = (data) => this.apiClient.post(Endpoint.deliveryMethod, data);
  updateDeliveryMethod = (id, data) => this.apiClient.put(`${Endpoint.deliveryMethod}/${id}`, data);
  deleteDeliveryMethod = (id) => this.apiClient.delete(`${Endpoint.deliveryMethod}/${id}`);

  // Title - Chức danh
  getTitleList = (data = {}) => this.apiClient.get(Endpoint.title, data);

  // VendorService
  getVendorServices = (data) => this.apiClient.get(Endpoint.vendorService, data);
  getVendorServicesByVendorId = (clientCareHistoryId, data) =>
    this.apiClient.get(
      Endpoint.vendorService + `/GetListByVendorId?clientcarehistoryId=${clientCareHistoryId}`,
      data,
    );
  createVendorService = (data) => this.apiClient.post(Endpoint.vendorService, data);
  updateVendorService = (id, data) => this.apiClient.put(Endpoint.vendorService + `/${id}`, data);
  deleteVendorService = (id) => this.apiClient.delete(Endpoint.vendorService + `/${id}`);

  //
  updateVendorToClientCareHistory = (id, data) =>
    this.apiClient.post(Endpoint.clientCareHistory + `/UpdateVendor/${id}`, data);

  getContractListByClientCareHistory = (clientCareHistoryId, data) =>
    this.apiClient.get(Endpoint.contract + `/GetbyClientCareHistory/${clientCareHistoryId}`, data);

  // Client Gift
  getPagingClientGift = (data, paramSearch) => {
    console.log({ paramSearch });

    let typeClientCompany =
      paramSearch?.typeClientCompany === 2 ? '' : paramSearch?.typeClientCompany;

    let strClientCompanyName = paramSearch?.strClientCompanyName || '';
    let strRealReceiver = paramSearch?.strRealReceiver || '';

    return this.apiClient.post(
      Endpoint.clientGift +
        `/GetPagingByParma?typeClientCompany=${typeClientCompany}&strClientCompanyName=${strClientCompanyName}&strRealReceiver=${strRealReceiver}`,
      data,
    );
  };
  createClientGift = (data) => this.apiClient.post(Endpoint.clientGift, data);
  updateClientGift = (id, data) => this.apiClient.put(Endpoint.clientGift + `/${id}`, data);
  deleteClientGift = (id) => this.apiClient.delete(Endpoint.clientGift + `/${id}`);
  exportExcelClientGift = (data) =>
    this.apiClient.getCustomBlod(Endpoint.clientGift + `/ExportExcel`, data);
  importExcelClientGift = (data) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.clientGift + '/ImportExcel', formData);
  };
}

const restFullServices = new RestfullServices();
export default restFullServices;
