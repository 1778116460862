import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { TextInput, HtmlEditor } from 'components';
import Services from 'services';

//
import { stateFromHTML } from 'draft-js-import-html';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const EmailFormNew = ({ selectedEmail, handleClose, reload }) => {
  console.log({ selectedEmail });
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  //
  const [checkedUpdatePassword, setCheckedUpdatePassword] = useState(false);
  const [passwordNew, setPasswordNew] = useState('');

  useEffect(() => {
    if (selectedEmail?.userMail?.user) {
      setUser(selectedEmail?.userMail?.user);
    }

    let html = '<p></p>';
    if (selectedEmail?.userMail?.signatureHTML) {
      html = selectedEmail?.userMail?.signatureHTML;
    }
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateDefault = EditorState.createWithContent(contentState);

    setEditorState(editorStateDefault);
  }, [selectedEmail]);

  const handleUpdateEmail = async (updateEmailNew = false, checkedUpdatePassword) => {
    if (loading) return;

    console.log('handle handleUpdateEmail');
    console.log({ checkedUpdatePassword });
    const convertDataSignature = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
    let dataSendEmail = {};
    if (updateEmailNew) {
      // cập nhật email cũ
    } else {
      // cập nhật email mới
      dataSendEmail = {
        user,
        password: passwordNew,
        signatureHTML: convertDataSignature,
      };
    }
    console.log({ dataSendEmail, convertDataSignature });

    setLoading(true);
    return await Services.updateEmailUser(selectedEmail.id, dataSendEmail)
      .then((response) => {
        if (response.status === 200) {
          window.UIMessage?.success('Thao tác thành công');
          handleClose();

          return reload();
        } else {
          window.UIMessage?.error('Thao tác thất bại');
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thao tác thất bại');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditorStateChange = (value) => {
    let contentState = stateFromHTML(value);
    console.log({ contentState });
    setEditorState(value);
  };

  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  const handleChangeUpdatePassword = (event) => {
    setCheckedUpdatePassword(event.target.checked);
  };
  const handleChangePasswordNew = (event) => {
    setPasswordNew(event.target.value);
  };

  return (
    <>
      <div>
        {/* <Typography variant="p" gutterBottom component="div">
          <b>Email:</b>
          <span style={{ marginLeft: '10px' }}>{user}</span>
        </Typography> */}
        <Grid item xs={4} sx={{ marginTop: '2px' }}>
          <TextInput label="Email" value={user} onChange={handleChangeUser} />
          {!user && <i style={{ color: 'red' }}>Email không được để trống</i>}
        </Grid>
        <Grid item xs={4} sx={{ marginTop: '-6px' }}>
          <TextInput label="Mật khẩu" value={passwordNew} onChange={handleChangePasswordNew} />
          {!passwordNew && <i style={{ color: 'red' }}>Mật khẩu không được để trống</i>}
        </Grid>
        {/* <Typography variant="p" gutterBottom component="div">
          <b>Mật khẩu hiện tại:</b>
          <span style={{ marginLeft: '10px' }}>***********</span>
        </Typography> */}
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={checkedUpdatePassword}
              onChange={handleChangeUpdatePassword}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Cập nhật mật khẩu Email"
        />
        {checkedUpdatePassword && (
          <Grid container>
            <Grid item xs={4} sx={{ marginTop: '-6px' }}>
              <TextInput
                label="Mật khẩu mới"
                value={passwordNew}
                onChange={handleChangePasswordNew}
              />
              {!passwordNew && <i style={{ color: 'red' }}>Mật khẩu không được để trống</i>}
            </Grid>
          </Grid>
        )} */}
        <div style={{ marginTop: '10px' }}>
          <b>Chữ ký:</b>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={handleEditorStateChange}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: '300px',
              mt: 1,
              padding: '10px 0px',
              textTransform: 'none',
            }}
            disabled={!passwordNew || !user}
            onClick={() => handleUpdateEmail(false, checkedUpdatePassword)}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </>
  );
};

export { EmailFormNew };
