import actionTypes from './type';

import ApiServices from 'services';

export function fetchUsers() {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getUsers();
      return dispatch({ users: data, type: actionTypes.FETCH_USERS });
    } catch (error) {
      console.error('fetchUsers-ERR', error);
    }
    return dispatch({ users: [], type: actionTypes.FETCH_USERS });
  };
}
export function changeOnlineUsers(users = []) {
  return async (dispatch) => {
    dispatch({ users: users, type: actionTypes.ONLINE_USERS_CHANGED });
  };
}
export function changeTypingUsers(users = []) {
  return async (dispatch) => {
    dispatch({ users: users, type: actionTypes.TYPING_USERS_CHANGED });
  };
}
