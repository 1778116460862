import ApiClient from './api-client';
// import Utils from '../commons/utils';
import Endpoint from './endpoints';
class FileServices {
  constructor() {
    this.apiClient = new ApiClient({
      baseURL: process.env.REACT_APP_FILE_API_URL,
      pathPrefix: process.env.REACT_APP_FILE_PATH_PREFIX,
      logOutput: true,
    });
  }
  uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiClient.postFormData(Endpoint.fileUpload, formData);
  };
  uploadFiles = (files) => {
    const formData = new FormData();
    for (let file of files) {
      formData.append('files', file);
    }
    return this.apiClient.postFormData(Endpoint.filesUpload, formData);
  };
  getFileById = (id) => this.apiClient.get('/' + id);
}
const FileService = new FileServices();
export default FileService;
