import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  flex: 1,
}));

export const ButtonExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '8px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));
