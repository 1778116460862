import React, { useEffect, useState } from 'react';
import { Table, SearchInput } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';

function ClientGroups() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getClientGroups();
      setData(data || []);
    } catch (err) {
      console.warn('initData - Client Groups', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const deleteCell = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteClientGroup(row.id);
      console.log(res);

      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteCell - companies', err);
    }
    setLoading(false);
  };
  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Mã',
      width: 10,
      sort: true,
      align: 'center',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên Nhóm',
      align: 'center',
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi Chú',
      align: 'center',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      align: 'center',
      width: '10px',
      fixed: 'right',
      label: 'Thao tác',
      render: (row) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteCell(row))}
        >
          Xoá
        </Button>
      ),
    },
  ];
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={data}
          headCells={headCells}
          onClickDetail={(row) => {
            setSelected(row);
          }}
          title="Nhóm Khách Hàng"
          loading={loading}
          renderHeader={() => {
            return (
              <>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <SearchInput sx={{ mr: 2 }} maxWidth={400} />
                </Box>
                <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
                  <BsFilter />
                </IconButton>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowDownCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '15px',
                    marginLeft: '5px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '4px 15px',
                    '.MuiButton-startIcon': {
                      marginRight: '2px',
                    },
                  }}
                >
                  Nhập excel
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<BsArrowUpCircle />}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '15px',
                    marginLeft: '5px',
                    color: '#6C757D',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '4px 15px',
                    '.MuiButton-startIcon': {
                      marginRight: '2px',
                    },
                  }}
                >
                  Tải excel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<BsPlus />}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '15px',
                    marginLeft: '5px',
                    color: '#FFF',
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    padding: '4px 15px',
                    '.MuiButton-startIcon': {
                      marginRight: '2px',
                    },
                  }}
                  onClick={() => setSelected({ isNew: true })}
                >
                  Thêm mới
                </Button>
              </>
            );
          }}
        />
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
    </>
  );
}
export { ClientGroups };
