import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Box } from '@mui/material';
import { DatePicker } from 'components';
import { CheckboxManager } from './checkbox-manager';

export default function ServicesModal({ open, setOpen, currentServiceList, onSubmitData }) {
  const [services, setServices] = useState([]);
  const [disabled, setDisable] = useState(true);
  const [dateHold, setDateHold] = useState('');
  const [reasonHold, setReasonHold] = useState('');

  const serviceOptions = open?.arrService?.filter((item) => currentServiceList?.includes(item?.id));

  const handleClose = () => {
    setOpen({});
    setDateHold('');
    setReasonHold('');
  };
  useEffect(() => {
    setServices([]);
  }, [open]);
  const handleSubmit = useCallback(async () => {
    if (onSubmitData) {
      onSubmitData(open, services, reasonHold, dateHold);
    }
    handleClose();
  }, [services.length, open, reasonHold, dateHold]);

  function handleChangeDate(event) {
    setDateHold(event.target.value);
  }

  function handleChangeReason(event) {
    setReasonHold(event.target.value || '');
  }

  const isOpen = Boolean(open.id);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Vui lòng chọn dịch vụ muốn tạm ngưng</DialogTitle>
        <DialogContent>
          <CheckboxManager
            label="DS Dịch vụ"
            options={serviceOptions}
            value={services}
            onChange={(res) => {
              setDisable(res.length === 0);
              setServices(res);
            }}
          />
        </DialogContent>

        <Box
          sx={{
            padding: '0 10px',
          }}
        >
          <DatePicker
            sx={{
              marginBottom: '10px',
              padding: 0,
            }}
            marginTop="0"
            size="small"
            onChange={handleChangeDate}
            value={null}
            error={true}
            label="Ngày tạm ngưng"
          />
          <TextField
            error={!reasonHold}
            sx={{ width: '100%', marginTop: '15px' }}
            multiline
            rows={4}
            id="outlined-error"
            size="small"
            label="Nội dung"
            value={reasonHold}
            onChange={handleChangeReason}
          />
        </Box>

        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} autoFocus disabled={disabled || !reasonHold}>
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
