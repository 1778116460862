import React, { useEffect, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ModalFormDetail, SearchInputColumn } from 'components';
import { Box, Button, Typography } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';

import ApiServices from 'services';
import { FormDetail } from './form-detail';
import { ContainerHeader, ButtonAdd } from './styles';
import Utils from 'commons/utils';
import { debounce } from 'lodash';

function Row(props) {
  const { row, deleteCell, setSelected } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (row) {
      setOpen(row?.isCollapse);
    }
  }, [row]);

  return (
    <React.Fragment>
      {/*           Thang Cha                           */}
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {row?.submenus?.length > 0 ? (
              open ? (
                <KeyboardArrowUpIcon style={{ color: 'blue' }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: 'blue' }} />
              )
            ) : (
              <HighlightOffIcon style={{ color: 'red' }} />
            )}
          </IconButton>
          <b
            style={{
              color: 'red',
              marginLeft: '10px',
            }}
          >
            {row?.submenus?.length}
          </b>
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          {row?.code}
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          {row?.name}
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          {row?.sort}
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          {row?.url}
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          Bố
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          {row?.level}
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          {row?.icon}
        </TableCell>
        <TableCell align="center" style={{ color: 'blue', fontWeight: '400' }}>
          <Box>
            <IconButton aria-label="expand row" size="small" onClick={() => setSelected(row)}>
              <BorderColorIcon />
            </IconButton>
            <IconButton aria-label="expand row" size="small" onClick={() => deleteCell(row?.id)}>
              <HighlightOffIcon style={{ color: 'red' }} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {open && (
        <>
          {row?.submenus?.map((sub, index) => {
            return (
              <TableRow
                key={`${sub?.code}-${sub?.parent}-${index}`}
                sx={{ backgroundColor: '#eee', boxShadow: '5px 5px 10px 0 #eee' }}
              >
                <TableCell>
                  <b
                    style={{
                      marginLeft: '10px',
                    }}
                  >
                    {index + 1}
                  </b>
                </TableCell>
                <TableCell align="center">{sub?.code}</TableCell>
                <TableCell align="center">{sub?.name}</TableCell>
                <TableCell align="center">{sub?.sort}</TableCell>
                <TableCell align="center">{sub?.url}</TableCell>
                <TableCell align="center">Con</TableCell>
                <TableCell align="center">{sub?.level}</TableCell>
                <TableCell align="center">{sub?.icon}</TableCell>
                <TableCell align="center">
                  <Box>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setSelected(sub)}
                    >
                      <BorderColorIcon />
                    </IconButton>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => deleteCell(sub?.id)}
                    >
                      <HighlightOffIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
}

const PermissionMenu = () => {
  const [originData, setOriginData] = useState([]);
  const [dataFormat, setDataFormat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState(null);

  const initData = async () => {
    setLoading(true);
    try {
      const res = await ApiServices.getPermissionMenus();
      if (res?.data) {
        const filterParent = res?.data?.filter((el) => !el?.parent);
        const dataFormat = filterParent?.map((item) => {
          const filterSubmenu = res?.data?.filter((element) => element?.parent === item?.code);
          item.submenus = filterSubmenu || [];
          item.isCollapse = false;
          return item;
        });

        setDataFormat(dataFormat || []);
        setOriginData(res?.data || []);
      }
    } catch (err) {
      console.warn('initData - Client Groups', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];

    dataInit?.map((item) => {
      let checkFilter = true;

      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    const filterParent = dataInit?.filter((el) => !el?.parent);
    const dataParentIsFilter = dataFilter
      ?.filter((parentIsFilter) => !parentIsFilter?.parent)
      ?.map((isFilter) => isFilter?.id);

    const dataFormat = filterParent
      ?.map((item) => {
        const filterSubmenu = dataFilter?.filter((element) => element?.parent === item?.code);
        item.submenus = filterSubmenu || [];
        item.isCollapse = query?.code || query?.name ? true : false;
        return item;
      })
      ?.filter(
        (dataFormatFilter) =>
          dataFormatFilter?.submenus?.length || dataParentIsFilter?.includes(dataFormatFilter?.id),
      );

    setDataFormat(dataFormat || []);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );

  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const deleteCell = async (menuId) => {
    if (!menuId) return;

    setLoading(true);
    try {
      const res = await ApiServices.deletePermissionMenu(menuId);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('delete Menu Error:::', err);
    }
    setLoading(false);
  };

  //   const handleShowModal = () => {};

  //   const onCloseModal = () => {
  //     setSelected(null);
  //   };

  return (
    <TableContainer component={Paper}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <ContainerHeader>
          <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
            Phân Quyền Menu
          </Typography>
        </ContainerHeader>
        <ButtonAdd
          size="small"
          variant="contained"
          startIcon={<BsPlusCircle />}
          onClick={() => setSelected({ isNew: true })}
        >
          Thêm mới
        </ButtonAdd>
      </Box>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width={'10%'} />
            <TableCell align="center" width={'10%'}>
              <SearchInputColumn name="code" title="Mã" onChange={onColumnSearch} />
            </TableCell>
            <TableCell align="center" width={'20%'}>
              <SearchInputColumn name="name" title="Tên Menu" onChange={onColumnSearch} />
            </TableCell>
            <TableCell align="center" width={'10%'}>
              Thứ tự
            </TableCell>
            <TableCell align="center" width={'10%'}>
              Đường dẫn
            </TableCell>
            <TableCell align="center" width={'10%'}>
              Parent
            </TableCell>
            <TableCell align="center" width={'10%'}>
              Level
            </TableCell>
            <TableCell align="center" width={'10%'}>
              Icon
            </TableCell>
            <TableCell align="center" width={'10%'}>
              Thao tác
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFormat?.map((row, index) => (
            <Row
              key={`${row?.code}-${row?.name}-${index}`}
              row={row}
              deleteCell={deleteCell}
              setSelected={setSelected}
            />
          ))}
        </TableBody>
      </Table>
      <FormDetail {...{ selected, setSelected, initData, data: originData }} />
      {/* <ModalFormDetail
        open={selected}
        onCloseModal={onCloseModal}
        title="Thêm mới"
        maxWidth="md"
      ></ModalFormDetail> */}
    </TableContainer>
  );
};

export { PermissionMenu };
