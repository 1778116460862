import React, { useEffect, useState, useCallback } from 'react';
import { TextInput, Selection } from 'components';
import { ContainerHeader, ContainerHeaderForm } from './styles';
import ApiServices from 'services';

const InformationGeneral = ({
  dataSelected,
  setServiceIdSelected,
  setDataSelected,
  selected,
  isDisabled = false,
}) => {
  const [serviceId, setServiceId] = useState('');
  const [clientCompanyId, setClientCompanyId] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [deadline, setDeadline] = useState('');
  const [arrShareUserCode, setArrShareUserCode] = useState([]);
  const [arrShareDepartmentId, setArrShareDepartmentId] = useState([]);

  useEffect(() => {
    if (!selected?.isNew) {
      setServiceId(dataSelected?.serviceId || '');
      setClientCompanyId(dataSelected?.clientCompanyId || '');
      setRequestDate(dataSelected?.requestDate || '');
      setDeadline(dataSelected?.deadline || '');
      setArrShareUserCode(dataSelected?.arrShareUserCode || []);
      setArrShareDepartmentId(dataSelected?.arrShareDepartmentId || []);
    } else {
      setServiceId('');
      setClientCompanyId('');
      setRequestDate('');
      setDeadline('');
      setArrShareUserCode([]);
      setArrShareDepartmentId([]);
    }
  }, [selected]);
  const handleChangeService = useCallback(
    (e) => {
      setServiceIdSelected(e.target.value);
      setServiceId(e.target.value);
      setDataSelected((prev) => {
        prev.serviceId = e.target.value;
        return { ...prev };
      });
    },
    [serviceId],
  );

  const handleChangeClient = useCallback(
    (e) => {
      setClientCompanyId(e.target.value);
      setDataSelected((prev) => {
        prev.clientCompanyId = e.target.value;
        return { ...prev };
      });
    },
    [clientCompanyId],
  );

  const handleChangeRequestDate = useCallback(
    (e) => {
      setRequestDate(e.target.value);
      setDataSelected((prev) => {
        prev.requestDate = e.target.value;
        return { ...prev };
      });
    },
    [requestDate],
  );
  const handleChangeDeadlineDate = useCallback(
    (e) => {
      setDeadline(e.target.value);
      setDataSelected((prev) => {
        prev.deadline = e.target.value;
        return { ...prev };
      });
    },
    [deadline],
  );
  const handleChangeUser = useCallback(
    (e) => {
      setArrShareUserCode(e.target.value);
      setDataSelected((prev) => {
        prev.arrShareUserCode = e.target.value;
        return { ...prev };
      });
    },
    [arrShareUserCode],
  );
  const handleChangeDepartment = useCallback(
    (e) => {
      setArrShareDepartmentId(e.target.value);
      setDataSelected((prev) => {
        prev.arrShareDepartmentId = e.target.value;
        return { ...prev };
      });
    },
    [arrShareDepartmentId],
  );

  return (
    <ContainerHeader>
      <ContainerHeaderForm>
        <Selection
          required
          sxInput={{ padding: '0px', margin: '0px' }}
          error={!serviceId}
          helperText={!serviceId && 'Bắt buộc (*)'}
          label="Dịch vụ"
          name="serviceId"
          onChange={handleChangeService}
          value={serviceId}
          syncData={ApiServices.getServicesForCheckPrice}
          fieldView="name"
          disabled={isDisabled}
        />
        <Selection
          required
          sxInput={{ padding: '0px', margin: '0px' }}
          sx={{ marginLeft: '10px' }}
          error={!clientCompanyId}
          helperText={!clientCompanyId && 'Bắt buộc (*)'}
          label="Khách hàng"
          name="clientCompanyId"
          onChange={handleChangeClient}
          value={clientCompanyId}
          syncData={ApiServices.getClientCompanies}
          disabled={isDisabled}
        />
        <TextInput
          sx={{ marginLeft: '10px' }}
          error={!requestDate}
          helperText={!requestDate && 'Chưa nhập ngày yêu cầu'}
          label="Ngày yêu cầu"
          name="requestDate"
          onChange={handleChangeRequestDate}
          value={requestDate}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
        <TextInput
          sx={{ marginLeft: '10px' }}
          error={!deadline}
          helperText={!deadline && 'Chưa nhập ngày deadline'}
          label="Deadline"
          name="deadline"
          onChange={handleChangeDeadlineDate}
          value={deadline}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
        <Selection
          sx={{ marginLeft: '10px' }}
          error={!arrShareUserCode || !arrShareUserCode.length > 0}
          helperText={
            (!arrShareUserCode || !arrShareUserCode.length > 0) && 'Chưa chọn người chia sẻ'
          }
          label="Chia sẻ cho"
          name="arrShareUserCode"
          multiple={true}
          onChange={handleChangeUser}
          value={arrShareUserCode}
          syncData={ApiServices.getUsers}
          fieldValue="code"
          fieldView="name"
          disabled={isDisabled}
        />
        <Selection
          sx={{ marginLeft: '10px' }}
          error={!arrShareDepartmentId || !arrShareDepartmentId.length > 0}
          helperText={
            (!arrShareDepartmentId || !arrShareDepartmentId.length > 0) && 'Chưa chọn phòng ban'
          }
          label="Gửi cho phòng ban"
          name="arrShareDepartmentId"
          multiple={true}
          onChange={handleChangeDepartment}
          value={arrShareDepartmentId}
          syncData={ApiServices.getDepartments}
          fieldView="name"
          disabled={isDisabled}
        />
      </ContainerHeaderForm>
    </ContainerHeader>
  );
};

export { InformationGeneral };
