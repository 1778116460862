import React from 'react';
import * as Yup from 'yup';
import {
  HandleFormDetail,
  TextInput,
  Selection,
  DatePicker,
  EvaluationCriteria,
  RadioButtonGroup,
} from 'components';
import { Box, Button, Switch, FormControlLabel } from '@mui/material';
import { Formik } from 'formik';

import ApiServices from 'services';
import Storage from 'storage';
import { options } from 'numeral';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const [option, setOption] = React.useState(0);

  React.useEffect(() => {
    if (selected) {
      setOption(selected?.option);
    }
  }, [selected]);

  // Create Rating
  const createData = async function (values, { setSubmitting }) {
    values.companyId = Storage?.user?.CompanyId;
    setSubmitting(true);
    values.option = option;
    try {
      const res = await ApiServices.createClientRating(values);
      if (res.status === 200) {
        reload();
        setSelected(null);
        window.UIMessage?.success('Thao tác thành công');
      } else {
        window.UIMessage?.error('Thao tác thất bại');
      }
    } catch (err) {
      console.warn('create Contract - companies', err);
    }
    setSubmitting(false);
  };

  // Update rating
  const updateData = async function (values, { setSubmitting }) {
    delete values.updatedOn;
    values.option = option;
    setSubmitting(true);
    if (values?.file?.isAvailable) {
      delete values.file;
    }
    try {
      const { data } = await ApiServices.updateClientRating(values.id, values);
      reload();
      setSelected(null);
      window.UIMessage?.success('Thao tác thành công');
    } catch (err) {
      window.UIMessage?.error('Thao tác thất bại');
      console.warn('create Contract - companies', err);
    }
    setSubmitting(false);
  };
  const arrInput = [
    { field: 'name', label: 'Tên Tiêu chí' },
    {
      field: 'serviceId',
      label: 'Dịch vụ',
      type: 'selection',
      syncData: ApiServices.getServices,
    },
    { field: 'note', label: 'Ghi chú' },
    {
      field: 'option',
      label: 'Công thức tính tổng',
      type: 'switch',
      disabled: true,
      sx: { mb: '0px' },
    },
  ];
  return (
    <HandleFormDetail
      {...{ selected, setSelected, width: FORM_WIDTH }}
      showTitleTab={selected?.isNew ? 'Tạo mới' : `Hợp đồng: ${selected?.name}`}
      moreTabs={[
        {
          title: 'Test Tab More',
          content: () => <> TAB 2</>,
        },
      ]}
    >
      <Formik
        initialValues={{
          name: '',
          serviceId: '',
          arrEvaluationCriteria: [],
          note: '',
          ...selected,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Vui lòng nhập Tên'),
          serviceId: Yup.string().max(255).required('vui lòng nhập Dịch vụ'),
          arrEvaluationCriteria: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Vui lòng nhập Tên Tiêu chí'),
              unit: Yup.string().required('Vui lòng nhập Đơn vị'),
            }),
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '480px' }}>
            {arrInput.map((item) => {
              switch (item.type) {
                case 'selection':
                  return (
                    <Selection
                      key={`selection-Contract-${item.field}`}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.label}
                      name={item.field}
                      data={item?.data}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      syncData={item?.syncData}
                    />
                  );
                case 'date-picker':
                  return (
                    <DatePicker
                      key={`selection-Contract-${item.field}`}
                      onChange={handleChange}
                      value={values[item.field]}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.label}
                      name={item.field}
                    />
                  );
                case 'switch':
                  return (
                    <FormControlLabel
                      key={`selection-Contract-${item.field}`}
                      labelPlacement="start"
                      label={item.label}
                      sx={item.sx}
                      control={
                        <Switch
                          checked={Boolean(values[item.field])}
                          disabled={item.disabled || false}
                          onChange={(e) =>
                            handleChange({
                              target: { name: item.field, value: e.target.checked ? 1 : 0 },
                            })
                          }
                        />
                      }
                    />
                  );
                default:
                  return (
                    <TextInput
                      key={`input-Contract-${item.field}`}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      helperText={touched[item.field] && errors[item.field]}
                      label={item.label}
                      name={item.field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      multiline={item?.multiline || false}
                    />
                  );
              }
            })}
            <Box>
              <RadioButtonGroup
                label="Chọn cách tính"
                value={option}
                onChange={({ target }) => setOption(+target.value)}
                options={[
                  {
                    id: 0,
                    name: 'Không',
                  },
                  {
                    id: 1,
                    name: 'Theo 1 tiêu chí',
                  },
                  {
                    id: 2,
                    name: 'Theo tổng tiêu chí',
                  },
                ]}
              />
            </Box>
            <EvaluationCriteria
              name="arrEvaluationCriteria"
              onChange={handleChange}
              value={values.arrEvaluationCriteria}
              error={Boolean(touched.arrEvaluationCriteria && errors.arrEvaluationCriteria)}
              helperText={touched.arrEvaluationCriteria && errors.arrEvaluationCriteria}
              showCheckCriteria={option === 1}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
