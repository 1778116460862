import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Grid, Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateObjectQuotes } from 'store/reducers/customerQuotes/action';
import { Selection } from 'components';
import { ContainerHeader, ContainerHeaderForm } from './styles';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { QUOTE_STATUS_OPTIONS, QUOTE_STATUS } from 'commons/constants';

const InformationGeneral = ({ selected }) => {
  const dispatch = useDispatch();
  const [clientCompanyId, setClientCompanyId] = useState('');
  const [clientId, setClientId] = useState('');
  const [arrUserCode, setArrUserCode] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);

  const isDisableByStatus = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [selected]);

  useEffect(() => {
    if (selected && !selected?.isNew) {
      setClientCompanyId(selected?.clientCompanyId || '');
      setClientId(selected?.clientId || '');
      setArrUserCode(selected?.arrUserCode || []);
    }
  }, [selected]);

  const getFullListByClientCompanyId = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getFullListByClientCompanyId(id);
      if (data) {
        setClientList(data);
      }
    } catch (error) {
      console.warn('getFullListByClientCompanyId:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientCompanyId) {
      getFullListByClientCompanyId(clientCompanyId);
    }
  }, [clientCompanyId]);

  const handleChangeClientCompany = useCallback(
    (e) => {
      setClientCompanyId(e.target.value);
      setClientId('');
      dispatch(updateObjectQuotes({ clientCompanyId: e.target.value, clientId: '' }));
    },
    [clientCompanyId],
  );
  const handleChangeClient = useCallback(
    (e) => {
      setClientId(e.target.value);
      dispatch(updateObjectQuotes({ clientId: e.target.value }));
    },
    [clientId],
  );

  const handleChangeUser = useCallback(
    (e) => {
      setArrUserCode(e.target.value);
      dispatch(updateObjectQuotes({ arrUserCode: e.target.value }));
    },
    [arrUserCode],
  );

  return (
    <>
      {!selected?.isNew && (
        <Box sx={{ marginBottom: '10px', marginTop: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <b>Mã báo giá: </b> <span style={{ color: 'red' }}>{selected?.code}</span>
            </Grid>
            <Grid item xs={4}>
              <b>Ngày báo giá: </b>{' '}
              <span style={{ color: 'red' }}>{Utils.formatDateFn(selected?.quotedate)}</span>
            </Grid>
            <Grid item xs={4}>
              <b>Trạng thái: </b>{' '}
              {QUOTE_STATUS_OPTIONS[selected?.quotesStatus] && (
                <Chip
                  size="small"
                  label={QUOTE_STATUS_OPTIONS[selected?.quotesStatus]?.label}
                  color={QUOTE_STATUS_OPTIONS[selected?.quotesStatus]?.color}
                  sx={{ padding: '5px 10px' }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      <ContainerHeader>
        <ContainerHeaderForm>
          <Selection
            required
            sxInput={{ padding: '0px', margin: '0px' }}
            sx={{ marginLeft: '10px' }}
            error={!clientCompanyId}
            helperText={!clientCompanyId && 'Bắt buộc (*)'}
            label="Khách hàng"
            name="clientCompanyId"
            onChange={handleChangeClientCompany}
            value={clientCompanyId}
            syncData={ApiServices.getClientCompanies}
            disabled={!selected?.isNew}
          />
          {clientCompanyId && !loading && (
            <Selection
              required
              sxInput={{ padding: '0px', margin: '0px' }}
              sx={{ marginLeft: '10px' }}
              error={!clientId}
              helperText={!clientId && 'Bắt buộc (*)'}
              label="Người hỗ trợ"
              name="clientId"
              onChange={handleChangeClient}
              value={clientId}
              disabled={isDisableByStatus}
              fieldValue="id"
              fieldView="fullName"
              dataArrObject={clientList}
            />
          )}
          <Selection
            sx={{ marginLeft: '10px' }}
            error={!arrUserCode || !arrUserCode.length > 0}
            helperText={(!arrUserCode || !arrUserCode.length > 0) && 'Chưa chọn người chia sẻ'}
            label="Chia sẻ cho"
            name="arrUserCode"
            multiple={true}
            onChange={handleChangeUser}
            value={arrUserCode}
            syncData={ApiServices.getUsers}
            fieldValue="code"
            fieldView="name"
            disabled={isDisableByStatus}
          />
        </ContainerHeaderForm>
      </ContainerHeader>
    </>
  );
};

export { InformationGeneral };
