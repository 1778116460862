import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { TabPersonal } from './tab-personal';
import { TabLeader } from './tab-leader';

export function ContractsManagement() {
  const [valueTab, setValueTab] = React.useState('1');

  const handleChangeTab = (_, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="CÁ NHÂN" value="1" />
              <Tab label="QUẢN LÝ" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <TabPersonal />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <TabLeader />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
