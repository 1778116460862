import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import Storage from 'storage';
import ApiServices from 'services';

const FORM_WIDTH = '500px';

export const FormDetail = ({ selected, setSelected, reload, ...props }) => {
  const createData = async function (values, { setSubmitting }) {
    values.companyId = Storage?.user?.CompanyId;
    setSubmitting(true);
    await ApiServices.createPosition(values)
      .then(({ status }) => {
        if (status === 200) {
          reload();
          setSelected(null);
          window.UIMessage?.success('Tạo mới thành công');
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Tạo mới thất bại');
        console.warn('create Position:', err);
      });
    setSubmitting(false);
  };
  const updateData = async function (values, { setSubmitting }) {
    delete values.updatedOn;
    setSubmitting(true);
    await ApiServices.updatePosition(values.id, values)
      .then(({ status }) => {
        if (status === 200) {
          reload();
          setSelected(null);
          window.UIMessage?.success('Cập nhật thành công');
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại');
        console.warn('update Position', err);
      });
    setSubmitting(false);
  };

  return (
    <HandleFormDetail
      {...{ selected, setSelected, width: FORM_WIDTH }}
      showTitleTab={selected?.isNew ? 'Tạo mới' : `Chức vụ: ${selected?.name}`}
    >
      <Formik
        initialValues={{
          code: '',
          name: '',
          level: '',
          departmentId: '',
          note: '',
          ...selected,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required('Vui lòng nhập Mã Chức vụ'),
          name: Yup.string().max(255).required('vui lòng nhập tên Chức vụ'),
          departmentId: Yup.string().required('vui lòng nhập Phòng ban'),
          level: Yup.number().integer(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (selected?.isNew) {
            return createData(values, { setSubmitting });
          }
          return updateData(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextInput
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
              label="Mã chức vụ"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
            />
            <TextInput
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Tên chức vụ"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
            <TextInput
              error={Boolean(touched.level && errors.level)}
              helperText={touched.level && errors.level}
              label="Cấp bậc"
              name="level"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.level}
              type="number"
            />
            <Selection
              error={Boolean(touched.departmentId && errors.departmentId)}
              helperText={touched.departmentId && errors.departmentId}
              label="Phòng ban"
              name="departmentId"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.departmentId}
              syncData={ApiServices.getDepartments}
            />
            <TextInput
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              label="Ghi chú"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
              multiline
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </HandleFormDetail>
  );
};
