import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { CheckPriceReqFreightForm, CheckPriceReqFreightLCLForm, Selection } from 'components';
import { SUB_SERVICES_ID } from 'commons/constants';
import { ItemForm } from './item-form';

export const ResCheckPriceItems = ({ itemData, selected, dataFeeGroupList }) => {
  const { SEA_FCL, SEA_LCL, AIR } = SUB_SERVICES_ID;
  const { unitList, currencyList, portList } = useSelector((state) => state.categoryData);
  const subFeeList = useMemo(() => {
    return dataFeeGroupList.map((item) => {
      return {
        id: item?.feeGroupID,
        name: item?.feeGroupName,
      };
    });
  }, [dataFeeGroupList]);

  const renderRequestCheckPrice = (item) => {
    switch (item?.subServiceId) {
      case SEA_FCL:
        return (
          <CheckPriceReqFreightForm
            arrContType={item?.contTypeCodeCheck}
            subFeeList={subFeeList}
            unitList={unitList}
            name="checkFreightRequest"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={[item.checkFreightRequest]}
            disabledResponseCheck={true}
          />
        );
      case SEA_LCL:
        return (
          <CheckPriceReqFreightLCLForm
            subFeeList={subFeeList}
            unitList={unitList}
            name="checkFreightRequest"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={[item.checkFreightRequest]}
            disabledResponseCheck={true}
            hasInitData={true}
          />
        );
      case AIR:
        return (
          <CheckPriceReqFreightForm
            arrContType={item?.packingAirCodeCheck}
            subFeeList={subFeeList}
            unitList={unitList}
            name="checkFreightRequest"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={[item.checkFreightRequest]}
            keyArray="arrPackingAir"
            keyNameOfArray="packingAirCode"
            disabledResponseCheck={true}
          />
        );
      default:
        break;
    }
  };

  const renderFixDataItem = (item) => (
    <>
      <Selection
        sx={{
          width: '50%',
          marginTop: 0,
          fontSize: '12px',
        }}
        sxInput={{ padding: '0px', margin: '0px' }}
        label="Cảng xếp dỡ"
        name="polId"
        value={item?.polId}
        fieldValue="id"
        fieldView="name"
        dataArrObject={portList}
        disabled={true}
      />
      <Selection
        sx={{
          width: '50%',
          marginTop: 0,
          fontSize: '12px',
          marginLeft: '10px',
        }}
        sxInput={{ padding: '0px', margin: '0px' }}
        label="Cảng đến"
        name="podId"
        value={item?.podId}
        fieldValue="id"
        fieldView="name"
        dataArrObject={portList}
        disabled={true}
      />
      <Selection
        sx={{
          width: '50%',
          marginTop: 0,
          fontSize: '12px',
          marginLeft: '10px',
        }}
        sxInput={{ padding: '0px', margin: '0px' }}
        label="ĐV tiền tệ"
        name="currencyCode"
        value={item?.currencyCode}
        fieldValue="code"
        fieldView="name"
        dataArrObject={currencyList}
        disabled={true}
      />
    </>
  );

  return (
    <>
      {itemData?.contentList?.length > 0 && (
        <>
          <ContainerRequestPrice>
            {renderRequestCheckPrice(itemData?.contentList[0])}
            <ContainerInfo>{renderFixDataItem(itemData?.contentList[0])}</ContainerInfo>
          </ContainerRequestPrice>
          <ItemForm
            selected={selected}
            contTypeCodeCheck={itemData?.contentList[0]?.contTypeCodeCheck}
            packingAirCodeCheck={itemData?.contentList[0]?.packingAirCodeCheck}
            dataInit={{ ...itemData?.contentList[0] }}
            name="itemForm"
            itemData={itemData}
            value={itemData?.contentList}
            dataFeeGroupList={dataFeeGroupList}
          />
        </>
      )}
    </>
  );
};

export const ContainerInfo = styled(Box)(({ theme }) => ({
  width: '95%',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '8px',
}));

export const ContainerRequestPrice = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '10px',
  height: 'fit-content',
  backgroundColor: '#FCF2EF',
  borderRadius: '15px',
  padding: '5px 20px',
}));
