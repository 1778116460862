import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import { USAGE_STATUS } from 'commons/constants';

const FORM_WIDTH = '500px';
const USAGE_STATUS_OPTIONS = [
  { value: 0, content: USAGE_STATUS[0].label },
  { value: 1, content: USAGE_STATUS[1].label },
];

export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const [serviceList, setServiceList] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [subServiceList, setSubServiceList] = useState([]);
  const [subServiceId, setSubServiceId] = useState('');
  const [feeGroupList, setFeeGroupList] = useState([]);
  const [feeGroupId, setFeeGroupId] = useState('');

  useEffect(() => {
    setServiceId(selected?.serviceId || '');
    setSubServiceId(selected?.subServiceId || '');
    setFeeGroupId(selected?.feeGroupId || '');
  }, [selected]);

  const getServices = async () => {
    try {
      const { data } = await ApiServices.getServices();
      if (data) {
        setServiceList(data);
      }
    } catch (error) {
      console.error('getServices-ERR', error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const getSubServiceByServiceId = async (serviceId) => {
    try {
      const { data } = await ApiServices.getSubServiceByServiceId(serviceId);
      if (data) {
        setSubServiceList(data);
      }
    } catch (error) {
      console.warn('getSubServiceByServiceId:', error);
    }
  };

  useEffect(() => {
    if (serviceId) {
      getSubServiceByServiceId(serviceId);
    }
  }, [serviceId]);

  const getSubFeeGroupBySS = async (subServiceId, serviceId) => {
    try {
      const { data } = await ApiServices.getSubFeeGroupBySS(subServiceId, serviceId);
      if (data) {
        setFeeGroupList(data);
      }
    } catch (error) {
      console.warn('getSubFeeGroupBySS:', error);
    }
  };

  useEffect(() => {
    if (subServiceId && serviceId) {
      getSubFeeGroupBySS(subServiceId, serviceId);
    }
  }, [subServiceId]);

  const handleChangeService = (e) => {
    setServiceId(e.target.value);
    setSubServiceId('');
    setFeeGroupId('');
  };
  const handleChangeSubService = (e) => {
    setSubServiceId(e.target.value);
    setFeeGroupId('');
  };
  const handleChangeFeeGroup = (e) => {
    setFeeGroupId(e.target.value);
  };

  const createFn = async (values, { setSubmitting }) => {
    values.serviceId = serviceId;
    values.subServiceId = subServiceId;
    values.feeGroupId = feeGroupId;
    delete values.isNew;

    setSubmitting(true);
    try {
      const { status } = await ApiServices.createSurcharge(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    values.serviceId = serviceId;
    values.subServiceId = subServiceId;
    values.feeGroupId = feeGroupId;
    delete values.updatedOn;
    if (!values?.expirydate) {
      delete values.expirydate;
    }
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateSurcharge(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : 'Thông tin chi tiết'}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            nameEN: '',
            nameCN: '',
            nameJP: '',
            decription: '',
            price: 0,
            unitCode: '',
            expirydate: null,
            usageStatus: 0,
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().max(255).required('Vui lòng nhập mã'),
            // nameEN: Yup.string().max(255).required('Vui lòng nhập tên phụ phí tiếng Anh'),
            // nameCN: Yup.string().max(255).required('Vui lòng nhập tên phụ phí tiếng Hoa'),
            // nameJP: Yup.string().max(255).required('Vui lòng nhập tên phụ phí tiếng Nhật'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Tên phụ phí (tiếng Việt)"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <TextInput
                required
                error={Boolean(touched.nameEN && errors.nameEN)}
                helperText={touched.nameEN && errors.nameEN}
                label="Tên phụ phí tiếng Anh"
                name="nameEN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameEN}
              />
              <TextInput
                required
                error={Boolean(touched.nameCN && errors.nameCN)}
                helperText={touched.nameCN && errors.nameCN}
                label="Tên phụ phí tiếng Hoa"
                name="nameCN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameCN}
              />
              <TextInput
                required
                error={Boolean(touched.nameJP && errors.nameJP)}
                helperText={touched.nameJP && errors.nameJP}
                label="Tên phụ phí tiếng Nhật"
                name="nameJP"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameJP}
              />
              <TextInput
                error={Boolean(touched.decription && errors.decription)}
                helperText={touched.decription && errors.decription}
                label="Mô tả"
                name="decription"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.decription}
              />
              <Selection
                error={!serviceId}
                helperText={!serviceId && 'Vui lòng chọn dịch vụ'}
                label="Dịch vụ"
                name="serviceId"
                onChange={handleChangeService}
                value={serviceId}
                fieldValue="id"
                fieldView="name"
                dataArrObject={serviceList}
              />
              {serviceId && (
                <>
                  <Selection
                    error={!subServiceId}
                    helperText={!subServiceId && 'Vui lòng chọn dịch vụ phụ'}
                    label="Dịch vụ phụ"
                    name="subServiceId"
                    onChange={handleChangeSubService}
                    value={subServiceId}
                    fieldValue="id"
                    fieldView="name"
                    dataArrObject={subServiceList}
                  />
                  {subServiceId && (
                    <Selection
                      error={!feeGroupId}
                      helperText={!feeGroupId && 'Vui lòng chọn nhóm phụ phí'}
                      label="Dịch vụ phụ"
                      name="feeGroupId"
                      onChange={handleChangeFeeGroup}
                      value={feeGroupId}
                      fieldValue="id"
                      fieldView="name"
                      dataArrObject={feeGroupList}
                    />
                  )}
                </>
              )}
              <TextInput
                required
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                label="Đơn giá"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                type="number"
              />
              <Selection
                error={Boolean(touched.unitCode && errors.unitCode)}
                helperText={touched.unitCode && errors.unitCode}
                label="Đơn vị tính"
                name="unitCode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.unitCode}
                syncData={ApiServices.getUnits}
                fieldValue="code"
              />
              <TextInput
                error={Boolean(touched.expirydate && errors.expirydate)}
                helperText={touched.expirydate && errors.expirydate}
                label="Hết hạn"
                name="expirydate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.expirydate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Selection
                error={Boolean(touched.usageStatus && errors.usageStatus)}
                helperText={touched.usageStatus && errors.usageStatus}
                label="Trang thái"
                name="usageStatus"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.usageStatus}
                dataArrObject={USAGE_STATUS_OPTIONS}
              />
              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Ghi chú"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />

              <Box sx={{ py: 2 }}>
                {!values.name && !values.nameEN && !values.nameCN && !values.nameJP && (
                  <i
                    style={{
                      color: 'red',
                    }}
                  >
                    Vui lòng nhập ít nhất 1 tên phụ phí (tiếng Việt, Anh, Hoa, Nhật)
                  </i>
                )}
                <Button
                  sx={{
                    marginTop: '5px',
                  }}
                  color="primary"
                  disabled={
                    isSubmitting ||
                    !serviceId ||
                    !subServiceId ||
                    !feeGroupId ||
                    (!values.name && !values.nameEN && !values.nameCN && !values.nameJP)
                  }
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
