import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button, Badge } from '@mui/material';

export const getColumns = (deleteCell, typeClient) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Mã',
    width: 10,
    sort: true,
    align: 'left',
    render: (row) => <>{row?.objClientCompany?.code}</>,
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Khách hàng',
    align: 'left',
    render: (row) => <>{row?.objClientCompany?.name}</>,
  },
  {
    id: 'serviceName',
    numeric: true,
    disablePadding: false,
    label: 'Dịch Vụ',
    align: 'left',
  },
  {
    id: 'objClientCompanyPause',
    numeric: true,
    disablePadding: false,
    label: 'Lý do tạm ngưng',
    align: 'left',
    render: (row) => <>{row?.objClientCompanyPause?.reasonForSuspension}</>,
  },
  {
    id: 'foundingDate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạm ngưng',
    align: 'left',
    render: (row) => Utils.formatDateFn(row?.objClientCompanyPause?.suspensionDate),
  },
  {
    id: 'tax_Code',
    numeric: true,
    disablePadding: false,
    label: 'Mã Số Thuế',
    align: 'left',
    render: (row) => <>{row?.objClientCompany?.tax_Code}</>,
  },
  {
    id: 'arrAddress',
    numeric: true,
    disablePadding: false,
    label: 'Địa Chỉ',
    align: 'left',
    render: (row) => Utils.showArrayInTale(row?.objClientCompany?.arrAddress),
  },
  {
    id: 'arrNumberPhone',
    numeric: true,
    disablePadding: false,
    label: 'SĐT',
    align: 'left',
    render: (row) => Utils.showArrayInTale(row?.objClientCompany?.arrNumberPhone),
  },
  {
    id: 'arrFax',
    numeric: true,
    disablePadding: false,
    label: 'Ứng dụng liên hệ',
    align: 'left',
    render: (row) => Utils.showArrayInTale(row?.objClientCompany?.arrFax),
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
    align: 'left',
    render: (row) => <>{row?.objClientCompany?.email}</>,
  },
  {
    id: 'foundingDate',
    numeric: true,
    disablePadding: false,
    label: 'Thành Lập',
    align: 'left',
    render: (row) => Utils.formatDateFn(row?.objClientCompany?.foundingDate),
  },
  {
    id: 'companyType',
    numeric: true,
    disablePadding: false,
    label: 'Loại CT',
    align: 'left',
    render: (row) => <>{row?.objClientCompany?.companyType}</>,
  },

  {
    id: 'loyaltyTypeName',
    numeric: true,
    disablePadding: false,
    label: 'Loại KH',
    align: 'center',
    render: (row) => typeClient(row?.objClientCompany?.loyaltyTypeName),
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi Chú',
    align: 'left',
    render: (row) => <>{row?.objClientCompany?.note}</>,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    fixed: 'right',
    align: 'left',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="primary"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => deleteCell(row)}
      >
        Chăm sóc lại
      </Button>
    ),
  },
];
