import actionTypes from './type';
import ChatServices from 'services/chat-services';

export function fetchChanel(query = { limit: 100 }) {
  return async (dispatch) => {
    try {
      const { data } = await ChatServices.getChanel(query);
      if (data.code === 'success') {
        return dispatch({
          chanels: data.results,
          type: actionTypes.FETCH_CHANEL,
        });
      }
    } catch (error) {
      console.error('fetchChanel-ERR', error);
    }
    return dispatch({ chanels: [], type: actionTypes.FETCH_CHANEL });
  };
}
export function fetchPersonalChanel() {
  return async (dispatch) => {
    try {
      const { data } = await ChatServices.getPersonalChanel();
      if (data.code === 'success') {
        return dispatch({
          chanels: data.results,
          type: actionTypes.FETCH_PERSONAL_CHANEL,
        });
      }
    } catch (error) {
      console.error('fetchChanel-ERR', error);
    }
    return dispatch({ chanels: [], type: actionTypes.FETCH_PERSONAL_CHANEL });
  };
}
export function setSelectedChanel(chanel) {
  return (dispatch) => dispatch({ chanel, type: actionTypes.UPDATE_SELECTED_CHANEL });
}
export function deleteChanel(chanelId) {
  return (dispatch) => dispatch({ chanelId, type: actionTypes.DELETE_CHANEL });
}
export function addChanel(chanel) {
  return (dispatch) => dispatch({ chanel, type: actionTypes.ADD_CHANEL });
}
export function updateChanel(chanel) {
  return (dispatch) => dispatch({ chanel, type: actionTypes.UPDATE_CHANEL });
}

export function addMember(chanel) {
  return (dispatch) => dispatch({ chanel, type: actionTypes.ADD_MEMBER });
}

export function removeMember(chanel, memberId) {
  return (dispatch) => dispatch({ chanel, memberId, type: actionTypes.REMOVE_MEMBER });
}
