import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, DateTimePicker } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createScheduleManagement(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm lịch hẹn thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm lịch hẹn thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateScheduleManagement(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Cuộc hẹn: ${selected?.appointmentName}`}
      >
        <Formik
          initialValues={{
            appointmentName: '',
            location: '',
            time: '',
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            appointmentName: Yup.string()
              .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
              .required('Vui lòng nhập tên cuộc hẹn'),
            location: Yup.string().max(255).required('Vui lòng nhập địa điểm'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                error={Boolean(touched.appointmentName && errors.appointmentName)}
                helperText={touched.appointmentName && errors.appointmentName}
                label="Tên cuộc hẹn"
                name="appointmentName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.appointmentName}
              />
              <DateTimePicker
                onChange={handleChange}
                value={values.time}
                error={Boolean(touched.time && errors.time)}
                helperText={touched.time && errors.time}
                label="Thời gian hẹn"
                name="time"
              />
              <TextInput
                error={Boolean(touched.location && errors.location)}
                helperText={touched.location && errors.location}
                label="Địa điểm"
                name="location"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.location}
              />
              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Nội dung"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
