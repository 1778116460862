import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Table } from 'components';
import { FormDetailRequest } from './form-detail-request';
import { FormDetailResponse } from './form-detail-response';
import ApiServices from 'services';
import { Columns } from './columns';
import Utils from 'commons/utils';
import { REQ_CHECK_PRICE_STATUS } from 'commons/constants';

function RequestCheckPrice({ openAddCheckPrice, closeAddCheckPrice }) {
  const { WAIT_RES_CHECK_PRICE, RES_CHECK_PRICE, REJECT_CHECK_PRICE } = REQ_CHECK_PRICE_STATUS;
  const [reqCheckPriceList, setReqCheckPriceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (openAddCheckPrice) {
      setSelected({ isNew: true });
    }
  }, [openAddCheckPrice]);

  const onCloseModal = () => {
    setSelected(null);
    closeAddCheckPrice();
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCheckPrices();
      if (data) {
        setReqCheckPriceList(data || []);
      }
    } catch (err) {
      console.warn('initData - getCheckPrices', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteContTypeItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteCheckPriceByServiceId(row?.id, row?.serviceId);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('deleteCheckPrice', err);
    }
    setLoading(false);
  };
  const handleSelecteRow = (row) => {
    const dataSelect = { ...row };
    dataSelect.requestDate = Utils.checkIsDate(row.requestDate)
      ? Utils.convertToDateFormat(row.requestDate)
      : '';
    dataSelect.deadline = Utils.checkIsDate(row.deadline)
      ? Utils.convertToDateFormat(row.deadline)
      : '';
    setSelected(dataSelect);
  };

  const renderFormDetail = () => {
    if (
      (selected && selected?.statusCheck === WAIT_RES_CHECK_PRICE) ||
      selected?.statusCheck === RES_CHECK_PRICE ||
      selected?.statusCheck === REJECT_CHECK_PRICE
    ) {
      return <FormDetailResponse {...{ selected, onCloseModal, initData }} />;
    }
    return <FormDetailRequest {...{ selected, onCloseModal, initData }} />;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={reqCheckPriceList}
        headCells={Columns(deleteContTypeItem)}
        onClickDetail={(row) => handleSelecteRow(row)}
        loading={loading}
        isHeader={false}
      />
      {renderFormDetail()}
    </Box>
  );
}
export { RequestCheckPrice };
