import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputItem from './input-item';

export const WhOtherServicesForm = ({
  arrSurcharge,
  arrUnit,
  name,
  requiredTitle = 'Vui lòng nhập thông tin',
  value,
  label = '',
  onChange,
  error = false,
  helperText = '',
  closeCheckedItem,
  disabledField = false,
  widthContainerLeft = '50%',
  widthContainerRight = '45%',
}) => {
  const initialValue = {
    surchargeId: '',
    price: 0,
    unitCode: '',
    vat: 0,
  };
  const [values, setValues] = React.useState([initialValue]);
  const initRef = React.useRef(true);

  const onAdd = () => {
    setValues((prev) => {
      let tmp = [...prev];
      tmp.push({
        surchargeId: '',
        price: 0,
        unitCode: '',
        vat: 0,
      });
      return tmp;
    });
  };
  const onDelete = (index) => {
    if (values && values.length === 1) {
      closeCheckedItem && closeCheckedItem();
    }
    setValues((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  React.useEffect(() => {
    if (initRef.current && value?.length) {
      setValues(value);
      initRef.current = false;
    }
  }, [value]);
  React.useEffect(() => {
    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: values,
        },
      });
  }, [values]);
  const renderItems = (item, index) => {
    return (
      <InputItem
        key={'input-Customs-' + index}
        arrSurcharge={arrSurcharge}
        arrUnit={arrUnit}
        item={item}
        index={index}
        onAdd={onAdd}
        onDelete={onDelete}
        values={values}
        setValues={setValues}
        error={error}
        disabledField={disabledField}
        widthContainerLeft={widthContainerLeft}
        widthContainerRight={widthContainerRight}
      />
    );
  };
  return (
    <FormGroup margin="dense">
      <FormLabel>{label}</FormLabel>
      {values.map((item, index) => renderItems(item, index))}
      <FormHelperText error={error}>{helperText && requiredTitle}</FormHelperText>
    </FormGroup>
  );
};
