import React from 'react';
import {
  Card,
  CardHeader,
  Avatar,
  CardActions,
  CardMedia,
  Stack,
  Box,
  Link,
  Typography,
} from '@mui/material';
import { GroupsOutlined as GroupsOutlinedIcon } from '@mui/icons-material';

import jwt_decode from 'jwt-decode';
import storage from 'storage';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedChanel } from 'store/reducers/chat-chanel/action';

import { FileIcon, defaultStyles } from 'react-file-icon';
import colors from 'themes/colors';
import DownloadIcon from '@mui/icons-material/Download';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import utils from 'commons/utils';
import { Popup } from 'components';
import { PopupInviteGroup } from './popup-invite-group';
import ImgWarning from 'assets/img/warning.jpg';
import Utils from 'commons/utils';

export const Message = ({ item, setOpenImageSlider, isShowAvatar = true }) => {
  const dispatch = useDispatch();
  moment.locale('vi');
  const { selectedChanel, chanels } = useSelector((state) => state.chatChanel);
  const { users } = useSelector((state) => state.users);
  const [isSelect, setIsSelect] = React.useState(null);

  if (selectedChanel?._id !== item.chanelId) {
    return null;
  }
  const user = users.find((u) => u.code == item.user);

  const onClickFile = (file) => {
    window
      .open(
        process.env.REACT_APP_FILE_API_URL +
          process.env.REACT_APP_FILE_PATH_PREFIX +
          `/DownloadURLByIdAsync/${file.id}/${file.contentType?.replace('/', '%2F')}`,
        '_blank',
      )
      .focus();
  };
  const renderFiles = (files, sx = {}) => {
    if (!files?.length) {
      return;
    }

    return files.map((file) => (
      <FileContainer
        key={'show-file-' + file.id}
        onClick={() => onClickFile(file)}
        sx={{ cursor: 'pointer', ...sx }}
      >
        <IconWrapper>
          <FileIcon
            color={colors.secondary}
            extension={file?.contentType && file?.contentType.split('/').pop()}
          />
        </IconWrapper>
        <NameWrapper
          sx={{
            ml: '5px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '200px',
          }}
        >
          {file.filename}
        </NameWrapper>
        <DownloadIcon fontSize="20px" sx={{ m: 'auto', ml: '10px' }} />
      </FileContainer>
    ));
  };
  const renderImages = (files) => {
    if (!files?.length) {
      return;
    }

    return (
      <ImageList
        sx={{
          maxWidth: 600,
          height: 'auto',
          overflowY: 'visible',
          '&. MuiImageList-root': {
            overflowY: 'visible',
          },
        }}
        cols={files?.length < 3 ? files?.length : 3}
      >
        {files.map((file) => (
          <ImageListItem key={'ImageListItem' + file.id}>
            <img
              onClick={() => setOpenImageSlider(file)}
              style={{
                width: 200,
                height: 'auto',
                cursor: 'pointer',
                borderRadius: '12px',
              }}
              src={
                process.env.REACT_APP_FILE_API_URL +
                process.env.REACT_APP_FILE_PATH_PREFIX +
                `/streaming/${file.contentType?.replace('/', '%2F')}/${file.id}`
              }
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const checkIsMemberInGroup = (text, decodeToken) => {
    const chanel = chanels.find((m) => m._id == decodeToken?._id);
    if (chanel) {
      if (selectedChanel._id === decodeToken._id) {
        return;
      }
      return dispatch(setSelectedChanel(chanel));
    }
    return setIsSelect(text);
  };

  const renderLinkInvite = (text) => {
    const tokenBySelect = text.split('/chat/invite-group-by-token?token=')[1];
    if (tokenBySelect) {
      try {
        const decodeToken = jwt_decode(tokenBySelect);
        if (decodeToken) {
          return (
            <Card
              // className="card-display-in-draggable"
              sx={{ width: '300px' }}
              style={{
                userSelect: 'none',
                margin: '5px 3px',
                padding: '5px 0px',
                borderRadius: '12px',
                backgroundColor: 'white',
                cursor: 'pointer',
                // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                boxShadow:
                  'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
              }}
              onClick={() => checkIsMemberInGroup(text, decodeToken)}
            >
              <CardHeader
                // className="card-display-in-draggable"
                avatar={
                  <Avatar variant="rounded">
                    <GroupsOutlinedIcon />
                  </Avatar>
                }
                sx={{ padding: '16px 16px 0px 16px' }}
                title={
                  <>
                    Nhóm: <b>{decodeToken?.name}</b>
                  </>
                }
                subheader={<Link underline="hover">crmdev.unilogistics</Link>}
              />
              <CardActions disableSpacing>
                <Link
                  underline="hover"
                  sx={{
                    paddingLeft: '5px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '13px',
                  }}
                >
                  {text}
                </Link>
              </CardActions>
            </Card>
          );
        }
      } catch (error) {
        console.log('Token is invalid');
        console.log(error);
        return (
          <Card
            sx={{ width: '200px' }}
            style={{
              userSelect: 'none',
              margin: '5px 3px',
              borderRadius: '12px',
              backgroundColor: 'white',
              cursor: 'pointer',
              boxShadow:
                'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
            }}
          >
            <CardHeader
              sx={{ padding: '16px 16px 0px 16px', fontSize: '12px' }}
              title={<Typography sx={{ color: 'red' }}>Link mời không hợp lệ</Typography>}

              // subheader="CRM website"
            />
            <Box sx={{ height: '80px' }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', height: 80, objectFit: 'contain' }}
                image={ImgWarning}
                alt="Warning"
              />
            </Box>
            <CardActions disableSpacing>
              <Link
                underline="hover"
                href={text}
                sx={{
                  paddingLeft: '5px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: '13px',
                }}
              >
                {text}
              </Link>
            </CardActions>
          </Card>
        );
      }
    }
  };

  if (storage.user.Code == item.user) {
    return (
      <MessageItem key={`message-item-${item._id}`} avatar={isShowAvatar ? 'ok' : ''}>
        {item.text ? (
          item.text.includes(
            `${process.env.REACT_APP_HOSTNAME}/chat/invite-group-by-token?token=`,
          ) ? (
            <MessageContentSender>{renderLinkInvite(item?.text)}</MessageContentSender>
          ) : (
            <MessageContentSender>
              <TextWrapperSender>
                <TextSender component="pre">{utils.parseHyperLinkFromText(item?.text)}</TextSender>
              </TextWrapperSender>
            </MessageContentSender>
          )
        ) : null}

        <FileWrapper>
          {renderFiles(item.files)}
          {renderImages(item.images)}
        </FileWrapper>
        {isShowAvatar && <TimeWrapperSender> {Utils.showTimeMessage(item.ts)}</TimeWrapperSender>}
        <Popup
          title="Thông tin nhóm"
          open={!!isSelect}
          closePopup={() => setIsSelect(null)}
          body={<PopupInviteGroup isSelect={isSelect} setIsSelect={setIsSelect} />}
        />
      </MessageItem>
    );
  }
  // const avatar =
  //   'https://crmdevapi.unilogistics.vn/file_upload//Employee//Icon//t%E1%BA%A3i%20xu%E1%BB%91ng_20220316094939031.png';
  return (
    <>
      <MessageWrapper key={`message-item-${item._id}`}>
        {isShowAvatar && (
          <AvatarUser
            alt={user?.Name}
            src={user?.urlAvatar && `${process.env.REACT_APP_API_URL}/${user?.urlAvatar}`}
          />
        )}
        <Box>
          {item.text && (
            <MessageItem avatar={isShowAvatar ? 'ok' : ''}>
              {item.text.includes(
                `${process.env.REACT_APP_HOSTNAME}/chat/invite-group-by-token?token=`,
              ) ? (
                <MessageContentForLink>{renderLinkInvite(item?.text)}</MessageContentForLink>
              ) : (
                <MessageContent>
                  <TextWrapper component="pre">
                    {utils.parseHyperLinkFromText(item?.text)}
                  </TextWrapper>
                </MessageContent>
              )}
            </MessageItem>
          )}

          <FileWrapper sx={{ alignItems: 'flex-start', ml: isShowAvatar ? 0 : '50px' }}>
            {renderFiles(item.files)}
            {renderImages(item.images)}
          </FileWrapper>

          {isShowAvatar && (
            <TimeWrapper sx={{ mt: item?.files?.length ? 0 : '-8px' }}>
              <NameWrapper sx={{ fontWeight: 400 }}>{user?.name || user?.code}</NameWrapper>
              {Utils.showTimeMessage(item.ts)}
            </TimeWrapper>
          )}
        </Box>
        <Popup
          title="Thông tin nhóm"
          open={!!isSelect}
          closePopup={() => setIsSelect(null)}
          body={<PopupInviteGroup isSelect={isSelect} setIsSelect={setIsSelect} />}
        />
      </MessageWrapper>
    </>
  );
};
const FileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 10,
  margin: '4px 0px',
  borderColor: theme.palette.action.hover,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
}));
const FileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));
const IconWrapper = styled(Box)(({ theme }) => ({
  height: 30,
  width: 30,
}));
const AvatarUser = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.action.selected,
  marginRight: '10px',
}));
const TextWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.main,
  padding: '10px',
  wordBreak: 'break-all',
  fontSize: '14px',
}));
const TimeWrapperSender = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  fontWeight: 300,
  marginBottom: '8px',
  fontSize: '12px',
}));
const TimeWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginBottom: '8px',
  fontWeight: 300,
  marginTop: '-8px',
}));
const NameWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: 5,
  fontWeight: 500,
}));
const MessageContent = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  background: '#E7E7E7',
  borderRadius: '16px',
  maxWidth: '85%',
}));
const MessageContentForLink = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  // background: '#E7E7E7',
  // borderRadius: '16px',
}));
const TextSender = styled(Box)(({ theme }) => ({
  color: 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  wordBreak: 'break-all',
  fontSize: '14px',
  ' a': {
    color: '#ffffff',
  },
}));
const TextWrapperSender = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  padding: '8px 16px',
  backgroundColor: '#3f219a',
  maxWidth: '85%',
  minWidth: '50px',
}));
const MessageContentSender = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  minWidth: '100px',
}));
const MessageItem = styled(Stack)(({ avatar }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100px',
  marginBottom: '8px',
  ...(!avatar && {
    marginLeft: '50px',
    marginBottom: '4px',
  }),
  maxWidth: '100%',
}));
const MessageWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // alignItems: 'center',
}));
const InfoWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '8px',
}));
