import actionTypes from './type';

export const showConfirm = (submit) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CONFIRM, submit });
  };
};
export const clear = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLEAR });
  };
};
