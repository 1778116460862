import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection } from 'components';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      unitList,
      item,
      index,
      onDelete,
      onAdd,
      values,
      setValues,
      isAddRow,
      disabledField,
      keyArray,
      keyNameOfArray,
      widthContainerLeft = '15%',
      widthContainerBody = '45%',
      widthContainerRight = '35%',
    },
    ref,
  ) => {
    const [remark, setName] = React.useState('');
    const [unitCode, setUnitCode] = React.useState('');
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setName(item?.remark);
      setUnitCode(item?.unitCode);
      setArrayCont(item[keyArray] || []);
    }, [item]);
    const onChangeName = React.useCallback(
      (e) => {
        setName(e.target.value);
        setValues((prev) => {
          prev[index].remark = e.target.value;
          return [...prev];
        });
      },
      [remark],
    );
    const onChangeUnit = React.useCallback(
      (e) => {
        setUnitCode(e.target.value);
        setValues((prev) => {
          prev[index].unitCode = e.target.value;
          return [...prev];
        });
      },
      [unitCode],
    );

    const onChangeCont = React.useCallback(
      (e, indContType) => {
        setArrayCont((prev) => {
          prev[indContType].price = +e.target.value;
          return [...prev];
        });

        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );
    const onChangeCommission = React.useCallback(
      (e, indContType) => {
        setArrayCont((prev) => {
          prev[indContType].commission = +e.target.value;
          return [...prev];
        });

        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    const isDisabled = !unitCode;
    return (
      <Container>
        <ContainerLeft width={widthContainerLeft}></ContainerLeft>
        <ContainerBody width={widthContainerBody}>
          {arrayCont?.map((el, indContType) => (
            <Box key={indContType}>
              <Box sx={{ width: '100%' }}>
                <StyledTextFieldPrice
                  label={el[keyNameOfArray]}
                  variant="outlined"
                  type="text"
                  size="small"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={(e) => onChangeCont(e, indContType)}
                  value={el?.price}
                  error={!el?.price}
                  disabled={disabledField}
                />
                {!el?.price && <ErrorText>Bắt buộc (*)</ErrorText>}
              </Box>
              <br />
              <Box sx={{ width: '100%' }}>
                <StyledTextFieldPrice
                  label={'Hoa hồng' + el[keyNameOfArray]}
                  variant="outlined"
                  type="text"
                  size="small"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={(e) => onChangeCommission(e, indContType)}
                  value={el?.commission}
                  disabled={disabledField}
                />
              </Box>
            </Box>
          ))}
        </ContainerBody>
        <ContainerRight width={widthContainerRight}>
          <Box sx={{ width: '50%', marginLeft: '10px' }}>
            <Selection
              required
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Đơn vị tính"
              name="serviceId"
              onChange={onChangeUnit}
              value={unitCode}
              fieldValue="code"
              fieldView="name"
              dataArrObject={unitList}
              error={!unitCode}
              disabled={disabledField}
            />
            {!unitCode && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          <StyledTextField
            label="Remark"
            variant="outlined"
            type="text"
            size="small"
            onChange={onChangeName}
            value={remark}
            disabled={disabledField}
          />
        </ContainerRight>
        <ContainerAction>
          {index === values.length - 1 ? (
            <>
              <ButtonWrapper
                onClick={() => onDelete(index)}
                sx={{ borderColor: 'error.main' }}
                disabled={disabledField}
              >
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
              {isAddRow && (
                <ButtonWrapper
                  onClick={onAdd}
                  sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                  disabled={isDisabled}
                >
                  <AddCircleOutlineIcon
                    sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                  />
                </ButtonWrapper>
              )}
            </>
          ) : (
            <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
              <HighlightOffIcon sx={{ color: 'error.main' }} />
            </ButtonWrapper>
          )}
        </ContainerAction>
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  marginLeft: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerBody = styled(Box)(({ theme, width = '45%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerLeft = styled(Box)(({ theme, width = '15%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerRight = styled(Box)(({ theme, width = '35%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
  // borderWidth: '1px',
  // borderStyle: 'solid',
  // borderColor: theme.palette.action.disabled,
}));
const StartWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-20px',
  left: '15px',
  zIndex: 1,
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
