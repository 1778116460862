import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { TextInput, Selection } from 'components';
import ApiServices from 'services';
import ChatServices from 'services/chat-services';
import { useDispatch } from 'react-redux';
import { fetchChanel } from 'store/reducers/chat-chanel/action';
import storage from 'storage';
export const AddChanel = ({ open, setOpen }) => {
  const [name, setName] = useState('');
  const [members, setMembers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    resetData();
  }, [open]);
  const resetData = () => {
    setName('');
    setMembers([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addChanel = useCallback(async () => {
    try {
      let allMembers = [
        {
          code: storage.user.Code,
          role: 1,
        },
      ];
      for (let member of members) {
        const objItem = JSON.parse(member);
        allMembers.push({
          code: objItem.code,
          role: 2,
        });
      }
      const { data } = await ChatServices.createChanel({
        name,
        members: allMembers,
      });
      if (data.code === 'success') {
        window.UIMessage.success('Thêm nhóm thành công');
        dispatch(fetchChanel());
      }
    } catch (error) {
      console.error('addChanel - ERR', error);
    }

    handleClose();
  }, [name, members]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Thêm nhóm chat</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Thêm tên nhóm Chat và các thành viên trong nhóm
        </DialogContentText>
        <TextInput
          variant="outlined"
          margin="normal"
          value={name}
          label="Tên nhóm"
          onChange={(e) => setName(e.target.value)}
        />

        <Selection
          label="Thành viên "
          multiple={true}
          // onBlur={handleBlur}
          onChange={(e) => setMembers(e.target.value)}
          value={members}
          syncData={ApiServices.getUsers}
          getValue={(item) => JSON.stringify(item)}
          renderValue={(selected) => selected.map((item) => JSON.parse(item).name).join(',')}
          checkBox
          checkedRender={(options, item) => {
            for (let option of options) {
              if (item.id === JSON.parse(option).id) {
                return true;
              }
            }
            return false;
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Hủy</Button>
        <Button onClick={addChanel} autoFocus variant="outlined">
          Thêm nhóm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
