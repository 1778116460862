import React from 'react';
import { Avatar } from '@mui/material';
import { SearchInputColumn } from 'components';

const GENDER = {
  1: 'Nam',
  2: 'Nữ',
  3: 'Khác',
};

const STAFF_STATUS = {
  1: 'Hiện diện',
  2: 'Nghỉ việc',
};

export const Columns = (onColumnSearch) => [
  {
    id: 'fullname',
    disablePadding: false,
    label: <SearchInputColumn name="fullname" title="Họ và tên" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
  {
    id: 'avatar',
    disablePadding: false,
    label: 'Ảnh đại diện',
    align: 'center',
    alignTitle: 'center',
    render: (row) => (
      <div>
        <Avatar
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
          src={`https://uonpapi.unilogistics.vn/${row?.avatar}`}
        />
      </div>
    ),
  },
  {
    id: 'code',
    disablePadding: false,
    label: <SearchInputColumn name="code" title="Mã nhân viên" onChange={onColumnSearch} />,
    align: 'center',
    sort: true,
  },
  {
    id: 'workingDate',
    isDateFormat: true,
    disablePadding: false,
    label: 'Ngày bắt đầu làm việc',
    align: 'center',
  },
  {
    id: 'officeWorkingDate',
    isDateFormat: true,
    disablePadding: false,
    label: 'Ngày vào làm chính thức',
    align: 'center',
  },
  {
    id: 'mass',
    disablePadding: false,
    label: <SearchInputColumn name="mass" title="Khối" onChange={onColumnSearch} />,
    align: 'center',
    render: (row) => row?.mass?.nameVI,
  },
  {
    id: 'department',
    disablePadding: false,
    label: <SearchInputColumn name="department" title="Phòng ban" onChange={onColumnSearch} />,
    align: 'center',
    render: (row) => row?.department?.nameVI,
  },
  {
    id: 'departmentPart',
    disablePadding: false,
    label: <SearchInputColumn name="departmentPart" title="Bộ phận" onChange={onColumnSearch} />,
    align: 'center',
    render: (row) => row?.departmentPart?.nameVI,
  },
  {
    id: 'positionNameVI',
    disablePadding: false,
    label: (
      <SearchInputColumn name="positionNameVI" title="Chức Vụ (Vi)" onChange={onColumnSearch} />
    ),
    align: 'center',
  },

  {
    id: 'positionNameEN',
    disablePadding: false,
    label: (
      <SearchInputColumn name="positionNameEN" title="Chức Vụ (En)" onChange={onColumnSearch} />
    ),
    align: 'center',
  },
  {
    id: 'titleNameVI',
    disablePadding: false,
    label: (
      <SearchInputColumn name="titleNameVI" title="Chức Danh (Vi)" onChange={onColumnSearch} />
    ),
    align: 'center',
  },
  {
    id: 'titleNameEN',
    disablePadding: false,
    label: 'Chức Danh (En)',
    label: (
      <SearchInputColumn name="titleNameEN" title="Chức Danh (En)" onChange={onColumnSearch} />
    ),
    align: 'center',
  },
  {
    id: 'workplaceCode',
    disablePadding: false,
    label: (
      <SearchInputColumn name="workplaceCode" title="Nơi Làm Việc" onChange={onColumnSearch} />
    ),
    align: 'center',
  },
  {
    id: 'probationCode',
    disablePadding: false,
    label: <SearchInputColumn name="probationCode" title="Mã Thử Việc" onChange={onColumnSearch} />,
    align: 'center',
  },
  {
    id: 'gender',
    disablePadding: false,
    label: 'Giới Tính',
    align: 'center',
    render: (row) => row?.gender && GENDER[row?.gender],
  },
  {
    id: 'birthDate',
    disablePadding: false,
    isDateFormat: true,
    label: 'Ngày Sinh',
    align: 'center',
  },
  {
    id: 'employeeStatus',
    disablePadding: false,
    label: 'Tình trạng nhân sự',
    align: 'center',
    render: (row) => row?.employeeStatus && STAFF_STATUS[row?.employeeStatus],
  },
];
