import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { Table, ModalFormDetail } from 'components';
import { FormDetail } from './form-detail';
import { Box, IconButton, Typography } from '@mui/material';
import {
  BsArrowDownCircle,
  BsFilter,
  BsBoxArrowInUpRight,
  BsFillStickiesFill,
} from 'react-icons/bs';
import Utils from 'commons/utils';
import Storage from 'storage';
import ApiServices from 'services';
import { Columns } from './columns';
import { ContainerHeader, ButtonExport, ButtonImport } from './styles';
import { debounce } from 'lodash';
import ServicesModal from './services-modal';
import { DrawerInformationDetails } from './information-detail';
import { ShowInfoCareOfService } from './show-info-care-of-service';

const TEMPALTE_NAME = 'Lead_Bieu_Mau_DealSuccess';
const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
};

function ClientCompanies() {
  const inputRef = useRef();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [servicesModal, setServiceModal] = useState({});
  const [currentServiceList, setCurrentServiceList] = useState([]);

  //
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [clientCareHistoryItem, setClientCareHistoryItem] = React.useState({});
  const [clientInformationDetail, setClientInformationDetail] = React.useState({});
  const [dataSelectShowInfo, setDataSelectShowInfo] = useState(null);
  const [dataSelectShowInfoFull, setDataSelectShowInfoFull] = useState(null);
  const [dataSelectClientCareHistory, setDataSelectClientCareHistory] = useState(null);

  const getCurrentService = () => {
    setLoading(true);
    return ApiServices.getServices()
      .then((res) => {
        if (res?.data) {
          const arrServiceId = res?.data?.map((item) => item.id);
          setCurrentServiceList(arrServiceId);
        }
      })
      .catch((err) => console.log('get Current Service:', err))
      .finally(() => setLoading(false));
  };

  const initData = (query) => {
    setLoading(true);
    return ApiServices.getCustomers(query)
      .then((res) => {
        if (res?.data) {
          if (totalRecords !== res?.data?.totalRecords) setTotalRecords(res?.data?.totalRecords);
          setData(res?.data?.data || []);
        }
      })
      .catch((err) => console.warn('get Client:', err))
      .finally(() => setLoading(false));
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  useEffect(() => {
    getCurrentService();
  }, []);

  const reloadData = async () => {
    return await initData(filter);
  };

  const handleHoldServiceNew = (clientCompanyId, serviceList, reasonHold, dateHold) => {
    setLoading(true);

    const dataSend = {
      objClientCompanyPause: {
        reasonForSuspension: reasonHold || '',
        suspensionDate: dateHold || null,
        userSuspension: Storage?.user?.Code,
      },
      arrServiceId: serviceList,
    };

    return ApiServices.updateServiceOff(clientCompanyId, dataSend)
      .then((res) => {
        if (res?.status === 200) {
          window.UIMessage?.success('Tạm ngưng thành công');
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const controlHoldingClient = (row) => {
    const currentServices = row?.arrService?.filter((item) =>
      currentServiceList?.includes(item?.id),
    );
    if (!currentServices || currentServices?.length === 0) return;
    // if (currentServices?.length === 1) {
    //   return handleHoldService(
    //     row.id,
    //     currentServices.map((el) => el.id),
    //   );
    // }
    return setServiceModal(row);
  };
  const handleHoldMoreService = (rowData, arrServiceId, reasonHold, dateHold) => {
    console.log({ rowData, arrServiceId, reasonHold, dateHold });
    const filterService = arrServiceId?.filter((item) => item);
    return handleHoldServiceNew(rowData.id, filterService, reasonHold, dateHold);
  };

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.foundingDate = Utils.checkIsDate(row.foundingDate)
      ? Utils.convertToDateFormat(row.foundingDate)
      : '';
    dataSelect.birthdayRepresenter = Utils.checkIsDate(row.birthdayRepresenter)
      ? Utils.convertToDateFormat(row.birthdayRepresenter)
      : '';

    dataSelect.tax_Code = row.tax_Code || '';
    dataSelect.arrAddress = row.arrAddress || [];
    dataSelect.arrNumberPhone = row.arrNumberPhone || [];
    dataSelect.arrFax = row.arrFax || [];
    dataSelect.email = row.email || '';
    dataSelect.companyType = row.companyType || '';
    dataSelect.arrServiceId = row.arrServiceId || [];
    dataSelect.note = row.note || '';
    dataSelect.cargotype = row.cargotype || '';
    dataSelect.representer = row.representer || '';
    dataSelect.emailRepresenter = row.emailRepresenter || '';
    dataSelect.clientSourceId = row.clientSourceId || '';
    dataSelect.clientSourceName = row.clientSourceName || '';
    dataSelect.clientTrashId = row.clientTrashId || '';
    dataSelect.updatedBy = row.updatedBy || '';
    dataSelect.phoneRepresenter = row.phoneRepresenter || '';
    return dataSelect;
  };
  const handleExportClientCompany = () => {
    setLoading(true);
    return ApiServices.exportExcelDeal()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `Khách_Hàng_Hiện_Hữu_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Client Company error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const handleExportTemplate = () => {
    setLoading(true);
    return ApiServices.exportTemplates(TEMPALTE_NAME)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Khách_Hàng_Hiện_Hữu_Biểu_Mẫu.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const onHandleImport = async (event) => {
    if (loadingImport || !event) return;

    const file = event?.target?.files[0];
    inputRef.current.value = '';
    if (!file) {
      window.UIMessage?.error('Tải file excel thất bại !!!');
      return;
    }
    setLoadingImport(true);
    try {
      const res = await ApiServices.importExcelDealSuccess({
        excelFile: file,
      });
      if (res?.data) {
        window.UIMessage?.success(
          `Import excel thành công - Thêm mới ${res?.data?.insert} - Cập nhật ${res?.data?.update}`,
          3000,
        );
        // event.target.value = '';
        reloadData();
      }
    } catch (error) {
      console.warn('import excel - Lead mới', error);
      window.UIMessage?.error('Import excel thất bại !!!');
    } finally {
      setLoadingImport(false);
    }
  };
  //
  const getClientCareHistoryDetails = async (idClientCareHistory) => {
    // try {
    //   const { data } = await ApiServices.getClientCareHistoryDetails(idClientCareHistory);
    //   console.log({ data });
    //   // setClientCareHistoryItem(data);
    //   // setClientInformationDetail(data.objClientCompany);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const onCloseModal = () => {
    setDataSelectShowInfo(null);
    setDataSelectShowInfoFull(null);
    setDataSelectClientCareHistory(null);
  };
  const handleShowClientCareHistory = async (serviceSelectedItem, rowDataFull) => {
    setDataSelectShowInfo(serviceSelectedItem);
    setDataSelectShowInfoFull(rowDataFull);

    const dataFind = rowDataFull?.arrClientCareHistory?.find(
      (item) => item?.serviceId === serviceSelectedItem?.id,
    );
    setDataSelectClientCareHistory(dataFind || null);
  };

  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          DANH SÁCH KHÁCH HÀNG HIỆN HỮU
        </Typography>
      </ContainerHeader>

      <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton>
      <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          onChange={onHandleImport}
        />
      </ButtonImport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClientCompany}
      >
        Tải excel
      </ButtonExport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport>
    </>
  );
  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelected(dataSelect);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          filter={filter}
          rows={data}
          headCells={Columns(
            handleSearchColumn,
            controlHoldingClient,
            currentServiceList,
            handleShowClientCareHistory,
          )}
          totalRecords={totalRecords}
          onPageChange={(paging) => {
            setFilter((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={handleClickDetail}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, reloadData }} />
        <ServicesModal
          open={servicesModal}
          setOpen={setServiceModal}
          currentServiceList={currentServiceList}
          onSubmitData={handleHoldMoreService}
        />
        <DrawerInformationDetails
          isOpenDrawer={isOpenDrawer}
          setIsOpenDrawer={setIsOpenDrawer}
          clientCareHistoryItem={clientCareHistoryItem}
          clientInformationDetail={clientInformationDetail}
          getClientCareHistoryDetails={getClientCareHistoryDetails}
        />
        <ModalFormDetail
          open={dataSelectShowInfo}
          onCloseModal={onCloseModal}
          title="Thông tin chi tiết"
          maxWidth="md"
        >
          <ShowInfoCareOfService
            dataSelected={dataSelectShowInfo}
            dataSelectedFull={dataSelectShowInfoFull}
            dataSelectClientCareHistory={dataSelectClientCareHistory}
          />
        </ModalFormDetail>
      </Box>
    </>
  );
}
export { ClientCompanies };
