import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'components';
import { Box } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';
import { debounce } from 'lodash';
import Utils from 'commons/utils';

function Employees() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [originData, setOriginData] = useState([]);

  const initData = async () => {
    setLoading(true);
    try {
      const res = await ApiServices.getEmployees();
      if (res?.data?.data) {
        setData(res?.data?.data || []);
        setOriginData(res?.data?.data || []);
      }
    } catch (err) {
      console.warn('initData - employee', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.fullname) {
        if (
          !Utils.convertValSearch(item?.fullname)?.includes(Utils.convertValSearch(query?.fullname))
        ) {
          checkFilter = false;
        }
      }
      if (query?.mass) {
        if (
          !Utils.convertValSearch(item?.mass?.nameVI)?.includes(Utils.convertValSearch(query?.mass))
        ) {
          checkFilter = false;
        }
      }

      if (query?.department) {
        if (
          !Utils.convertValSearch(item?.department?.nameVI)?.includes(
            Utils.convertValSearch(query?.department),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.departmentPart) {
        if (
          !Utils.convertValSearch(item?.departmentPart?.nameVI)?.includes(
            Utils.convertValSearch(query?.departmentPart),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.positionNameVI) {
        if (
          !Utils.convertValSearch(item?.positionNameVI)?.includes(
            Utils.convertValSearch(query?.positionNameVI),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.positionNameEN) {
        if (
          !Utils.convertValSearch(item?.positionNameEN)?.includes(
            Utils.convertValSearch(query?.positionNameEN),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.titleNameVI) {
        if (
          !Utils.convertValSearch(item?.titleNameVI)?.includes(
            Utils.convertValSearch(query?.titleNameVI),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.titleNameEN) {
        if (
          !Utils.convertValSearch(item?.titleNameEN)?.includes(
            Utils.convertValSearch(query?.titleNameEN),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.workplaceCode) {
        if (
          !Utils.convertValSearch(item?.workplaceCode)?.includes(
            Utils.convertValSearch(query?.workplaceCode),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.probationCode) {
        if (
          !Utils.convertValSearch(item?.probationCode)?.includes(
            Utils.convertValSearch(query?.probationCode),
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );

  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const renderHeader = () => (
    <>
      <h2>Danh Sách Nhân Viên</h2>
    </>
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={data}
        headCells={Columns(onColumnSearch)}
        loading={loading}
        renderHeader={renderHeader}
      />
    </Box>
  );
}
export { Employees };
