import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, ArrayInput, Selection } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import { SEX, RESPONSE_STATUSES } from 'commons/constants';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, reloadData }) => {
  // Create
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    return ApiServices.createVendorService(values)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };
  // Update
  const updateFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    return ApiServices.updateVendorService(values.id, values)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Cập nhật thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            arrServiceId: [],
            arrNumberPhone: [],
            email: '',
            arrAddress: [],
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
              .required('Vui lòng nhập mã KH!!!'),
            name: Yup.string().max(255).required('Vui lòng nhập tên đối tác!!!'),
            email: Yup.string().max(255).email('Email không hợp lệ'),
            arrServiceId: Yup.array().min(1, 'Vui lòng chọn dịch vụ!!!').nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                sx={{ marginTop: '10px' }}
                required
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Tên đối tác"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <Selection
                sx={{ marginTop: '10px' }}
                required
                error={Boolean(touched.arrServiceId && errors.arrServiceId)}
                helperText={touched.arrServiceId && errors.arrServiceId}
                label="Danh sách dịch vụ"
                name="arrServiceId"
                multiple={true}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.arrServiceId}
                syncData={ApiServices.getServices}
                listDisable={values?.arrServiceReceivedId}
              />
              <ArrayInput
                sx={{ marginTop: '10px' }}
                value={values.arrNumberPhone}
                fullWidth
                onChange={handleChange}
                label="Số điện thoại"
                name="arrNumberPhone"
                inputNumberStr={true}
              />

              <TextInput
                sx={{ marginTop: '15px' }}
                required
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
              <ArrayInput
                sx={{ marginTop: '10px' }}
                value={values.arrAddress}
                fullWidth
                onChange={handleChange}
                label="Địa chỉ"
                name="arrAddress"
              />

              <Box sx={{ py: 4 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
