import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  Chip,
  Typography,
  Avatar,
  AvatarGroup,
  Tooltip,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { Popup } from 'components';
import { PopupUserSupporter } from './popups/popup-user-supporter';
import { PopupUserSecondSupporter } from './popups/popup-user-second-supporter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const LeftInformation = ({
  clientCareHistoryItem,
  clientInformationDetail,
  getClientCareHistoryDetails,
}) => {
  const txtField = React.useRef(null);
  const [openUserCode, setOpenUserCode] = React.useState(false);
  const [openUserSubCode, setOpenUserSubCode] = React.useState(false);

  const onChangeAvatarFn = async (event) => {
    try {
      const { data } = await ApiServices.changeAvatarClientCompany(clientInformationDetail?.id, {
        file: event?.target?.files?.[0],
      });
      if (data) {
        getClientCareHistoryDetails(clientCareHistoryItem?.id);
        window.UIMessage?.success('Cập nhật thành công');
      }
    } catch (err) {
      console.warn('import excel - Change Avatar', err);
      window.UIMessage?.error('Cập nhật thất bại !!!');
    }
  };

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          sx={{
            padding: 0,
          }}
          onClick={() => {
            txtField.current.click();
          }}
        >
          <Avatar
            alt={clientInformationDetail?.code}
            src={
              clientInformationDetail?.urlImage &&
              `${process.env.REACT_APP_API_URL}/${clientInformationDetail?.urlImage}`
            }
            sx={{
              width: 100,
              height: 100,
              margin: '5px auto',
              cursor: 'pointer',
            }}
          />
          <input accept="image/*" ref={txtField} type="file" hidden onChange={onChangeAvatarFn} />
        </IconButton>
        <Typography variant="h6" gutterBottom component="div">
          {clientInformationDetail?.name}
        </Typography>
        <Chip
          label="Loại KHTT"
          color="primary"
          size="small"
          sx={{ padding: '0px 10px', marginBottom: '15px' }}
        />
      </Box>
      <Divider>
        <Chip label="Người đại diện" />
      </Divider>
      <Box sx={{ marginLeft: '10px', marginTop: '5px' }}>
        <Typography variant="p" gutterBottom component="div">
          <b>Người đại diện:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.representer}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>Ngày sinh:</b>
          <span style={{ marginLeft: '10px' }}>
            {Utils.formatDateFn(clientCareHistoryItem?.objClientCompany?.birthdayRepresenter)}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>Email:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.emailRepresenter}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>SĐT:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.phoneRepresenter}
          </span>
        </Typography>
      </Box>
      <Divider>
        <Chip label="Công ty" />
      </Divider>
      <Box sx={{ marginLeft: '10px', marginTop: '5px' }}>
        <Typography variant="p" gutterBottom component="div">
          <b>Mã số thuế:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.tax_Code}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>Ngày thành lập:</b>
          <span style={{ marginLeft: '10px' }}>
            {Utils.formatDateFn(clientCareHistoryItem?.objClientCompany?.foundingDate)}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>SĐT:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.arrNumberPhone?.[0]}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>Email:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.email}
          </span>
        </Typography>
        <Typography variant="p" gutterBottom component="div">
          <b>Loại hàng hóa:</b>
          <span style={{ marginLeft: '10px' }}>
            {clientCareHistoryItem?.objClientCompany?.cargotype}
          </span>
        </Typography>
      </Box>
      <Divider>
        <Chip label="Sale" />
        {/* Người phụ trách chính */}
      </Divider>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="p" gutterBottom component="b" sx={{ margin: 'auto 10px' }}>
          Phụ trách chính
        </Typography>
        <AvatarGroup
          max={3}
          sx={{ marginLeft: 'auto', marginRight: '15px', cursor: 'pointer' }}
          onClick={() => setOpenUserCode(true)}
        >
          {clientCareHistoryItem?.arrMainUserobj?.map((item, index) => (
            <Tooltip key={index} title={item.name} placement="top-start" arrow>
              <Avatar
                alt={item.code}
                src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                // sx={{ width: 28, height: 28 }}
              />
            </Tooltip>
          ))}
          {clientCareHistoryItem?.arrMainUserobj && (
            <Tooltip title="Thêm" placement="top-start" arrow>
              <Avatar sx={{ bgcolor: '#2FA4FF' }}>
                <AddIcon />
              </Avatar>
            </Tooltip>
          )}
        </AvatarGroup>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Typography variant="p" gutterBottom component="b" sx={{ margin: 'auto 10px' }}>
          Phụ trách phụ
        </Typography>

        <AvatarGroup
          max={3}
          sx={{ marginLeft: 'auto', marginRight: '15px', cursor: 'pointer' }}
          onClick={() => setOpenUserSubCode(true)}
        >
          {clientCareHistoryItem?.arrSecondaryUserobj?.map((item, index) => (
            <Tooltip key={index} title={item.name} placement="top-start" arrow>
              <Avatar
                alt={item.code}
                src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                // sx={{ width: 28, height: 28 }}
              />
            </Tooltip>
          ))}
          {clientCareHistoryItem?.arrSecondaryUserobj && (
            <Tooltip title="Thêm" placement="top-start" arrow>
              <Avatar sx={{ bgcolor: '#2FA4FF' }}>
                <AddIcon />
              </Avatar>
            </Tooltip>
          )}
        </AvatarGroup>
      </Box>
      <Popup
        title="Người phụ trách chính"
        open={openUserCode}
        closePopup={() => setOpenUserCode(false)}
        body={
          <PopupUserSupporter
            setOpenUserCode={setOpenUserCode}
            clientCareHistoryItem={clientCareHistoryItem}
            getClientCareHistoryDetails={getClientCareHistoryDetails}
          />
        }
      />
      <Popup
        title="Người phụ trách phụ"
        open={openUserSubCode}
        closePopup={() => setOpenUserSubCode(false)}
        body={
          <PopupUserSecondSupporter
            setOpenUserSubCode={setOpenUserSubCode}
            clientCareHistoryItem={clientCareHistoryItem}
            getClientCareHistoryDetails={getClientCareHistoryDetails}
          />
        }
      />
    </>
  );
};
