import * as React from 'react';
import {
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  IconButton,
  Button,
  FormControl,
  Tooltip,
} from '@mui/material';
import { BsTelephone, BsFillTrashFill, BsCalendar3, BsPlus } from 'react-icons/bs';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { CareClientSteps } from '../care-client-steps';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { CallForm } from '../forms/call-form';
import { ScheduleForm } from '../forms/schedule-form';
import { MailForm } from '../forms/mail-form';
import { Popup, ComboBox } from 'components';

const TabInformationDetail = ({
  clientCareHistoryItem,
  clientInformationDetail,
  getClientCareHistoryDetails,
  value,
  setValue,
}) => {
  const [clientCareHistoryData, setClientCareHistoryData] = React.useState({});
  const [personName, setPersonName] = React.useState([]);
  const [arrClientSupportFulll, setArrClientSupportFulll] = React.useState([]);
  const [arrClientSupported, setArrClientSupported] = React.useState([]);
  const [openCallForm, setOpenCallForm] = React.useState(false);
  const [openScheduleForm, setOpenScheduleForm] = React.useState(false);
  const [openMailForm, setOpenmailForm] = React.useState(false);
  const [idClientSupport, setIdClientSupport] = React.useState('');
  const [idClientSupportEmail, setIdClientSupportEmail] = React.useState('');
  const [idClientSupportCall, setIdClientSupportCall] = React.useState('');
  const [emailSupporter, setEmailSupporter] = React.useState('');

  const [memberClient, setMemberClient] = React.useState([]);
  const [memberClientSub, setMemberClientSub] = React.useState([]);

  // Vendor
  const [vendorList, setVendorList] = React.useState([]);
  const [vendorSelected, setVendorSelected] = React.useState([]);

  //
  const [isVendorCallForm, setIsVendorCallForm] = React.useState(false);
  const [isVendorMailForm, setIsVendorMailForm] = React.useState(false);
  const [isVendorScheduleForm, setIsVendorScheduleForm] = React.useState(false);

  const getInit = async () => {
    try {
      const { data } = await ApiServices.getClientCareHistoryDetails(clientCareHistoryItem.id);
      if (data) {
        setClientCareHistoryData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getListByClientCompanyId = async (idClientCompany, idClientCareHistory = '') => {
    try {
      const { data } = await ApiServices.getListByClientCompanyId(
        idClientCompany,
        idClientCareHistory,
      );
      if (data && idClientCareHistory) {
        setArrClientSupported(data);
      } else if (data && !idClientCareHistory) {
        setArrClientSupportFulll(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorList = async () => {
    try {
      const res = await ApiServices.getVendorServicesByVendorId(clientCareHistoryItem.id);
      if (res?.data) {
        setVendorList(res?.data || []);
      }
    } catch (error) {
      console.log(`Get Vendor List failed:::`, error);
    }
  };

  React.useEffect(() => {
    const getDataInit = async () => {
      await getInit();
      await getListByClientCompanyId(clientCareHistoryItem.clientCompanyId);
      await getListByClientCompanyId(
        clientCareHistoryItem.clientCompanyId,
        clientCareHistoryItem.id,
      );
    };
    getDataInit();
    getVendorList();
  }, []);

  const updateClientandUserMain = async (id, data) => {
    try {
      const { status } = await ApiServices.updateClientandUserMain(id, data);
      if (status === 200) {
        getClientCareHistoryDetails(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDelectClientMain = async (id) => {
    const mapDataIdMainClient = clientCareHistoryItem?.arrMainClientobj?.map((item) => item.id);
    const deleteItem = mapDataIdMainClient?.filter((item) => item !== id);
    await updateClientandUserMain(clientCareHistoryItem.id, { arrMainClientId: deleteItem });
    await getListByClientCompanyId(clientCareHistoryItem.clientCompanyId, clientCareHistoryItem.id);
  };

  const onDelectClientSub = async (id) => {
    const mapDataIdSubClient = clientCareHistoryItem?.arrSecondaryClientobj?.map((item) => item.id);
    const deleteItemSub = mapDataIdSubClient?.filter((item) => item !== id);
    await updateClientandUserMain(clientCareHistoryItem.id, {
      arrSecondaryClientId: deleteItemSub,
    });
    await getListByClientCompanyId(
      clientCareHistoryItem?.clientCompanyId,
      clientCareHistoryItem?.id,
    );
  };

  const callManagementFn = async (idSupporter, isVendor = false) => {
    setIsVendorCallForm(isVendor);
    setIdClientSupportCall(idSupporter);
    setOpenCallForm(true);
  };

  const sendMailManagementFn = async (values, isVendor = false) => {
    setIsVendorMailForm(isVendor);
    setIdClientSupportEmail(values.id);
    setEmailSupporter(values.email);
    setOpenmailForm(true);
  };

  const scheduleManagementFn = async (idSupporter, isVendor = false) => {
    setIsVendorScheduleForm(isVendor);
    setIdClientSupport(idSupporter);
    setOpenScheduleForm(true);
  };

  const onHandleChangeFn = async (event, newValue) => {
    setMemberClient(newValue);
  };

  const onAddUserSub = async () => {
    if (memberClientSub) {
      const idSend = memberClientSub.map((item) => item.id);
      await updateClientandUserMain(clientCareHistoryItem.id, {
        arrSecondaryClientId: idSend.concat(clientCareHistoryItem.arrSecondaryClientId),
      });
      await getListByClientCompanyId(
        clientCareHistoryItem.clientCompanyId,
        clientCareHistoryItem.id,
      );
      setMemberClientSub([]);
    }
  };

  const handleAddVendor = async () => {
    const mapId = vendorSelected?.map((item) => item?.id);
    if (!mapId) return;

    try {
      const res = await ApiServices.updateVendorToClientCareHistory(clientCareHistoryItem.id, {
        arrVendorServiceId: mapId.concat(clientCareHistoryItem.arrVendorServiceId),
      });

      if (res.status === 200) {
        getClientCareHistoryDetails(clientCareHistoryItem.id);
        getVendorList();
        setVendorSelected([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteVendor = async (id) => {
    const current = clientCareHistoryItem?.arrVendorService?.map((item) => item.id);
    const deleteFinal = current?.filter((item) => item !== id);

    try {
      const res = await ApiServices.updateVendorToClientCareHistory(clientCareHistoryItem.id, {
        arrVendorServiceId: deleteFinal || [],
      });

      if (res.status === 200) {
        getClientCareHistoryDetails(clientCareHistoryItem.id);
        getVendorList();
        setVendorSelected([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleChangeSubClientFn = async (_, newValue) => {
    setMemberClientSub(newValue);
  };

  const handleChangeVendor = async (_, newValue) => {
    setVendorSelected(newValue);
  };

  const onAddUserMain = async () => {
    if (memberClient) {
      const idSend = memberClient.map((item) => item.id);
      await updateClientandUserMain(clientCareHistoryItem.id, {
        arrMainClientId: idSend.concat(clientCareHistoryItem.arrMainClientId),
      });
      await getListByClientCompanyId(
        clientCareHistoryItem.clientCompanyId,
        clientCareHistoryItem.id,
      );
      await setMemberClient([]);
    }
  };

  return (
    <>
      {Object.entries(clientCareHistoryData).length !== 0 ? (
        clientCareHistoryData?.objClientCareProcess.length !== 0 ? (
          <CareClientSteps clientCareHistoryData={clientCareHistoryData} reload={getInit} />
        ) : (
          <></>
        )
      ) : null}

      {/* ===============================  Liên hệ chính  ===============================*/}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        sx={{ marginTop: '15px' }}
      >
        <FormControl
          sx={{
            width: 300,
            borderRadius: '50px',
            textAlign: 'center',
            justifyContent: 'center',
            padding: '8px 5px 8px 0px',
          }}
        >
          <ComboBox
            multiple
            disableCloseOnSelect
            options={arrClientSupported}
            label="Người liên hệ chính"
            sx={{ width: 300 }}
            keyField="fullName"
            value={memberClient}
            onChange={(event, newValue) => onHandleChangeFn(event, newValue)}
          />
        </FormControl>
        <Button
          variant="contained"
          size="small"
          startIcon={<BsPlus />}
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            marginLeft: '8px',
            color: '#FFFFFF',
            backgroundColor: '#21409A',
            '.MuiButton-endIcon': {
              marginLeft: '0px',
            },
            padding: '5px 15px 5px 10px',
          }}
          onClick={onAddUserMain}
        >
          Thêm
        </Button>
      </Stack>

      <Box
        sx={{
          width: '100%',
          minHeight: '250px',
          backgroundColor: '#FFF',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '5px',
        }}
      >
        <Grid container>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Liên hệ chính
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              SĐT
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Ngày sinh
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Tác vụ
            </Typography>
          </Grid>
        </Grid>
        {/* body - mapdata */}
        {clientCareHistoryItem?.arrMainClientobj?.map((item, index) => (
          <div key={item.code}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1 }}>
                {item?.fullName}
              </Grid>
              <Grid item xs={2} sx={{ padding: 1 }}>
                {item?.phoneNumber}
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                {Utils.formatDateFn(item?.birthday)}
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                <Tooltip title="Gọi điện" placement="top-start" arrow>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => callManagementFn(item.id)}
                  >
                    <BsTelephone />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Gửi mail" placement="top-start" arrow>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => sendMailManagementFn(item)}
                  >
                    <MailOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Lịch hẹn" placement="top-start" arrow>
                  <IconButton
                    aria-label="add an alarm"
                    color="primary"
                    size="small"
                    onClick={() => scheduleManagementFn(item.id)}
                  >
                    <BsCalendar3 />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Xóa" placement="top-start" arrow>
                  <IconButton
                    aria-label="add to shopping cart"
                    color="primary"
                    size="small"
                    onClick={() => onDelectClientMain(item.id)}
                  >
                    <BsFillTrashFill />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>

      {/* ===============================  Hộ trợ phụ  ===============================*/}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        sx={{ marginTop: '15px' }}
      >
        <FormControl
          sx={{
            width: 300,
            borderRadius: '50px',
            textAlign: 'center',
            justifyContent: 'center',
            padding: '8px 5px 8px 0px ',
          }}
        >
          <ComboBox
            multiple
            disableCloseOnSelect
            options={arrClientSupported}
            label="Người liên hệ phụ"
            sx={{ width: 300 }}
            keyField="fullName"
            value={memberClientSub}
            onChange={(event, newValue) => onHandleChangeSubClientFn(event, newValue)}
          />
        </FormControl>
        <Button
          variant="contained"
          size="small"
          startIcon={<BsPlus />}
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            marginLeft: '5px',
            color: '#FFFFFF',
            backgroundColor: '#21409A',
            '.MuiButton-endIcon': {
              marginLeft: '0px',
            },
            padding: '5px 15px 5px 10px',
          }}
          onClick={onAddUserSub}
        >
          Thêm
        </Button>
      </Stack>
      <Box
        sx={{
          width: '100%',
          minHeight: '250px',
          backgroundColor: '#FFF',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '5px',
        }}
      >
        <Grid container>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Liên hệ phụ
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              SĐT
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Ngày sinh
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Tác vụ
            </Typography>
          </Grid>
        </Grid>
        {clientCareHistoryItem?.arrSecondaryClientobj?.map((item, index) => (
          <div key={`${item.code} - ${index}`}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1 }}>
                {item?.fullName}
              </Grid>
              <Grid item xs={2} sx={{ padding: 1 }}>
                {item?.phoneNumber}
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                {Utils.formatDateFn(item?.birthday)}
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                <Tooltip title="Gọi điện" placement="top-start" arrow>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => callManagementFn(item.id)}
                  >
                    <BsTelephone />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Gửi mail" placement="top-start" arrow>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => sendMailManagementFn(item)}
                  >
                    <MailOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Lịch hẹn" placement="top-start" arrow>
                  <IconButton
                    aria-label="add an alarm"
                    color="primary"
                    size="small"
                    onClick={() => scheduleManagementFn(item.id)}
                  >
                    <BsCalendar3 />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Xóa" placement="top-start" arrow>
                  <IconButton
                    aria-label="add to shopping cart"
                    color="primary"
                    size="small"
                    onClick={() => onDelectClientSub(item.id)}
                  >
                    <BsFillTrashFill />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
      {/* ===============================  Đối tác ===============================*/}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        sx={{ marginTop: '15px' }}
      >
        <FormControl
          sx={{
            width: 300,
            borderRadius: '50px',
            textAlign: 'center',
            justifyContent: 'center',
            padding: '8px 5px 8px 0px ',
          }}
        >
          <ComboBox
            multiple
            disableCloseOnSelect
            options={vendorList}
            label="Đối tác"
            sx={{ width: 300 }}
            keyField="name"
            value={vendorSelected}
            onChange={(event, newValue) => handleChangeVendor(event, newValue)}
          />
        </FormControl>
        <Button
          variant="contained"
          size="small"
          startIcon={<BsPlus />}
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            marginLeft: '5px',
            color: '#FFFFFF',
            backgroundColor: '#21409A',
            '.MuiButton-endIcon': {
              marginLeft: '0px',
            },
            padding: '5px 15px 5px 10px',
          }}
          onClick={() => handleAddVendor(vendorSelected)}
        >
          Thêm
        </Button>
      </Stack>
      <Box
        sx={{
          width: '100%',
          minHeight: '250px',
          backgroundColor: '#FFF',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '5px',
        }}
      >
        <Grid container>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Đối tác
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              SĐT
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Email
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: 1 }}>
            <Typography m={1} align="center" component="b" variant="string">
              Tác vụ
            </Typography>
          </Grid>
        </Grid>
        {clientCareHistoryItem?.arrVendorService?.map((item, index) => (
          <div key={`${item.code} - ${index}`}>
            <Divider variant="middle" />
            <Grid container>
              <Grid item xs={4} sx={{ padding: 1 }}>
                {item?.name}
              </Grid>
              <Grid item xs={2} sx={{ padding: 1 }}>
                {item?.arrNumberPhone?.[0]}
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                {item?.email}
              </Grid>
              <Grid item xs={3} sx={{ padding: 1 }}>
                <Tooltip title="Gọi điện" placement="top-start" arrow>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => callManagementFn(item.id, true)}
                  >
                    <BsTelephone />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Gửi mail" placement="top-start" arrow>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => sendMailManagementFn(item, true)}
                  >
                    <MailOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Lịch hẹn" placement="top-start" arrow>
                  <IconButton
                    aria-label="add an alarm"
                    color="primary"
                    size="small"
                    onClick={() => scheduleManagementFn(item.id, true)}
                  >
                    <BsCalendar3 />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Xóa" placement="top-start" arrow>
                  <IconButton
                    aria-label="add to shopping cart"
                    color="primary"
                    size="small"
                    onClick={() => handleDeleteVendor(item.id)}
                  >
                    <BsFillTrashFill />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
      {/* ===============================  Popup ===============================*/}
      <Popup
        title="Thông tin gọi điện"
        open={openCallForm}
        closePopup={() => setOpenCallForm(false)}
        body={
          <CallForm
            setOpenCallForm={setOpenCallForm}
            clientCareHistoryItem={clientCareHistoryItem}
            setValue={setValue}
            idClientSupportCall={idClientSupportCall}
            isVendor={isVendorCallForm}
          />
        }
      />
      <Popup
        title="Hẹn Gặp"
        open={openScheduleForm}
        closePopup={() => setOpenScheduleForm(false)}
        body={
          <ScheduleForm
            setOpenScheduleForm={setOpenScheduleForm}
            clientCareHistoryItem={clientCareHistoryItem}
            setValue={setValue}
            idClientSupport={idClientSupport}
            isVendor={isVendorScheduleForm}
          />
        }
      />
      <Popup
        maxWidth={false}
        title="Gửi Mail"
        open={openMailForm}
        closePopup={() => setOpenmailForm(false)}
        body={
          <MailForm
            setOpenmailForm={setOpenmailForm}
            clientCareHistoryItem={clientCareHistoryItem}
            setValue={setValue}
            clientInformationDetail={clientInformationDetail}
            idClientSupportEmail={idClientSupportEmail}
            emailSupporter={emailSupporter}
            isVendor={isVendorMailForm}
          />
        }
      />
    </>
  );
};

export { TabInformationDetail };
