import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { SimpleComboBox, LoadingView, HandleFormDetail } from 'components';
import ApiServices from 'services';
import { ContainerFormDetail } from '../styles';
import Storage from 'storage';
import { RESPONSE_STATUSES } from 'commons/constants';
const FORM_WIDTH = '500px';

export const FormCreate = ({ selected, onCloseModal, initData, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [clientCompanyId, setClientCompanyId] = useState('');
  const [clientCompanyCode, setClientCompanyCode] = useState('');
  const [companyId, setCompanyId] = useState('');

  const isSubmit = !clientCompanyId || !companyId;

  const handleClose = () => {
    onCloseModal();
    setClientCompanyId('');
    setClientCompanyCode('');
    setCompanyId('');
  };

  const handleAddNew = async () => {
    const dataSend = {
      clientCompanyId,
      clientCompanyCode,
      companyId,
    };
    setLoading(true);

    ApiServices.createClientSupportBoard(dataSend)
      .then((res) => {
        if (res?.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          handleClose();
          initData();
        }
      })
      .catch((err) => console.log('create Client Support Board: ', err));
    setLoading(false);
  };

  const handleChangeClientCompany = (item, newValue) => {
    setClientCompanyId(item || '');
    setClientCompanyCode(newValue?.code);
  };
  const handleChangeCompany = (item, newValue) => {
    setCompanyId(item);
  };

  const renderAddNew = () => (
    <>
      <ContainerFormDetail>
        <SimpleComboBox
          label="Chọn công ty chia sẻ (*)"
          disableClearable
          sx={{ marginBottom: '10px', marginLeft: '10px' }}
          value={companyId}
          keyValue="id"
          keyFieldView="name"
          onChange={handleChangeCompany}
          syncData={ApiServices.getGetDataCompany}
          disabled={!selected?.isNew}
        />
        <SimpleComboBox
          label="Khách hàng (*)"
          disableClearable
          sx={{ marginBottom: '10px', marginLeft: '10px' }}
          value={clientCompanyId}
          keyValue="id"
          keyFieldView="name"
          renderLabel={(option) => `${option?.code} - ${option?.name}`}
          renderOption={(option) => `${option?.code} - ${option?.name}`}
          onChange={handleChangeClientCompany}
          syncData={() => ApiServices.getfilterSupportBoard(Storage?.user?.CompanyId)}
        />
      </ContainerFormDetail>
      <Box sx={{ py: 2, px: 2 }}>
        <Button
          color="primary"
          disabled={isSubmit}
          fullWidth
          size="small"
          variant="contained"
          onClick={handleAddNew}
        >
          Thêm mới
        </Button>
      </Box>
    </>
  );

  const renderViewDetails = () => (
    <Box sx={{ m: 2 }}>
      <b>Mã: </b> <span style={{ color: 'blue' }}>{selected?.code}</span>
      <br />
      <b>Tên khách hàng: </b> <span style={{ color: 'blue' }}>{selected?.name}</span>
      <br />
      <b>Công ty chủ quản: </b> <span style={{ color: 'blue' }}>{selected?.companyCode}</span>
      <br />
      <b>Mã số thuế: </b> <span style={{ color: 'blue' }}>{selected?.tax_Code}</span>
      <br />
      <b>Địa chỉ: </b> <span style={{ color: 'blue' }}>{selected?.arrAddress}</span>
      <br />
      <b>Fax: </b> <span style={{ color: 'blue' }}>{selected?.arrFax}</span>
      <br />
      <b>Email: </b> <span style={{ color: 'blue' }}>{selected?.email}</span>
    </Box>
  );

  return (
    <>
      <HandleFormDetail
        {...{ selected, width: FORM_WIDTH }}
        showTitleTab={
          selected?.isNew ? 'Tạo mới (Công ty bên ngoài)' : 'Thông tin chi tiết (Công ty bên ngoài)'
        }
        onClose={handleClose}
      >
        {selected?.isNew ? renderAddNew() : renderViewDetails()}
      </HandleFormDetail>
      <LoadingView loading={loading} />
    </>
  );
};
