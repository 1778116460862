import React from 'react';
import { Divider, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import { BsFillTrashFill } from 'react-icons/bs';
import { ContainerTableUserAccount } from '../styles';

export const RenderUserAccount = ({ data, onDeleteUserAccount }) => {
  return (
    <ContainerTableUserAccount>
      <Grid container>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Tài khoản
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Họ tên
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Công ty
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Phòng ban
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            Chức danh
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ padding: 1 }}>
          <Typography m={1} align="center" component="b" variant="string">
            #
          </Typography>
        </Grid>
      </Grid>
      {data?.map((item, index) => (
        <div key={`${item?.id}-${index}`}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <i style={{ color: 'blue' }}>{item?.code}</i>
            </Grid>
            <Grid item xs={3} sx={{ padding: 1 }}>
              {item?.name}
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {item?.companyName}
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {item?.departmentName}
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {item?.titleName}
            </Grid>
            <Grid item xs={1} sx={{ padding: 1 }}>
              <Tooltip title="Xóa" placement="top-start" arrow>
                <IconButton
                  aria-label="add to shopping cart"
                  color="primary"
                  size="small"
                  onClick={() => onDeleteUserAccount(item)}
                >
                  <BsFillTrashFill />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      ))}
    </ContainerTableUserAccount>
  );
};
