import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
}));
export const ContainerHeaderForm = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
}));
export const ContainerContent = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'white',
  borderRadius: '15px',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '20px',
  boxShadow:
    'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
}));
export const ContainerContentForm = styled(Box)(({ theme }) => ({
  width: '100%',
  // padding: '20px',
}));
export const BtnCreateCheckPrice = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const ErrorText = styled('i')(({ theme }) => ({
  color: 'red',
  fontSize: '17px',
  marginLeft: '10px',
  fontWeight: 'italic',
}));
export const BtnRejectCheckPrice = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnSendCheckPrice = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
