import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Chanel } from './chanels';
import { Messages } from './messages';

export const Chat = () => {
  // if (window.location.protocol == 'http:' && process.env.NODE_ENV != 'development') {
  //   window.location.href = window.location.href.replace('http:', 'https:');
  // }
  if (window.location.protocol == 'http:') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }
  return (
    <Box sx={{ flexGrow: 1, mb: '-24px' }} className="chat-message">
      <Grid container spacing="4px">
        <Grid item xs={4}>
          <Chanel />
        </Grid>
        <Grid item xs={8}>
          <Messages />
        </Grid>
      </Grid>
    </Box>
  );
};
