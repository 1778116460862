import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { Table, ModalFormDetail } from 'components';
import { Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DetailContract } from './detail-contract';

const TabContract = ({ clientCareHistoryItem }) => {
  const [contractList, setContractList] = useState([]);
  const [showDetail, setShowDetail] = useState(null);

  const getInit = async (id) => {
    try {
      const res = await ApiServices.getContractListByClientCareHistory(id);
      if (res?.data) {
        setContractList(res?.data);
      }
    } catch (error) {
      console.log('get data init failed:::', error);
    }
  };

  useEffect(() => {
    if (clientCareHistoryItem?.id) {
      getInit(clientCareHistoryItem?.id);
    }
  }, [clientCareHistoryItem]);

  const handleShowDetail = (rowData) => {
    setShowDetail(rowData);
  };
  const onCloseModal = () => {
    setShowDetail(null);
  };

  const columns = [
    {
      id: 'contractNumber',
      numeric: true,
      disablePadding: false,
      align: 'center',
      label: 'Mã hợp đồng',
    },
    {
      id: 'effectiveDate',
      numeric: true,
      disablePadding: false,
      label: 'Ngày bắt đầu',
      align: 'center',
      render: (row) => Utils.formatDateFn(row?.effectiveDate),
    },
    {
      id: 'expireDate',
      numeric: true,
      disablePadding: false,
      label: 'Ngày kết thúc',
      align: 'center',
      render: (row) => Utils.formatDateFn(row?.expireDate),
    },
    {
      id: 'createdOn',
      numeric: true,
      disablePadding: false,
      label: 'Ngày tạo',
      align: 'center',
      render: (row) => Utils.formatDateFn(row?.createdOn),
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Ghi chú',
      align: 'center',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '#',
      width: '10px',
      fixed: 'right',
      align: 'center',
      render: (row) => (
        <IconButton onClick={() => handleShowDetail(row)}>
          <VisibilityIcon color="primary" />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Table rows={contractList} headCells={columns} isHeader={false} />
      <ModalFormDetail
        maxWidth="xl"
        open={!!showDetail}
        onCloseModal={onCloseModal}
        title="Thông tin chi tiết hợp đồng"
      >
        <DetailContract dataSelect={showDetail} />
      </ModalFormDetail>
    </Box>
  );
};

export { TabContract };
