import React from 'react';
import { Box, Tab, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { CustomsFcl } from './customs-fcl';
import { CustomsLcl } from './customs-lcl';
import { CustomsAir } from './customs-air';
import { SUB_SERVICES_ID } from 'commons/constants';

const SUB_SERVICES_OF_CUSTOMS = [
  {
    label: 'FCL',
    value: SUB_SERVICES_ID.CUSTOMS_FCL,
  },
  {
    label: 'LCL',
    value: SUB_SERVICES_ID.CUSTOMS_LCL,
  },
  {
    label: 'Hàng không',
    value: SUB_SERVICES_ID.CUSTOMS_AIR,
  },
];

export const CustomsService = () => {
  const { CUSTOMS_FCL, CUSTOMS_LCL, CUSTOMS_AIR } = SUB_SERVICES_ID;
  const [tabIndex, setTabIndex] = React.useState(CUSTOMS_FCL);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={tabIndex}>
        <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
          {SUB_SERVICES_OF_CUSTOMS.map((item) => (
            <TabItem key={`${item.value}`} label={item.label} value={item.value} />
          ))}
        </TabList>

        <TabPanel value={CUSTOMS_FCL} sx={{ padding: '5px 0px' }}>
          <CustomsFcl />
        </TabPanel>
        <TabPanel value={CUSTOMS_LCL} sx={{ padding: '5px 0px' }}>
          <CustomsLcl />
        </TabPanel>
        <TabPanel value={CUSTOMS_AIR} sx={{ padding: '5px 0px' }}>
          <CustomsAir />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
