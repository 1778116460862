import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl'; //FormControl
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxLabels({
  label = '',
  name = '',
  options = [],
  value = [],
  onChange = null,
  syncData = null,
  syncDataWithParam = null,
}) {
  const [items, setItems] = React.useState([]);
  const [state, setState] = React.useState({});
  const initData = React.useCallback(async () => {
    if (!syncData) {
      return;
    }
    try {
      const { data } = await syncData(syncDataWithParam || {});
      setItems(data);
      setState(() => {
        const newState = {};
        data.forEach((item) => {
          newState[item.id] = value.includes(item.id);
        });
        return newState;
      });
    } catch (err) {
      console.log('ERROR - Selection initData', err);
    }
  }, [syncData, syncDataWithParam]);
  React.useEffect(() => {
    setState(() => {
      const newState = {};
      items.forEach((item) => {
        newState[item.id] = value.includes(item.id) || false;
      });
      return newState;
    });
  }, [value.length]);
  React.useEffect(() => {
    if (!syncData) {
      return;
    }
    initData();
  }, [syncData]);
  const onChangeCheckbox = React.useCallback(
    (e, index, item) => {
      setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
      let res = value;
      if (e.target.checked) {
        res[index] = item.id;
      } else {
        res = value.filter((v) => v !== e.target.name);
      }
      onChange && onChange(res);
    },
    [value],
  );
  return (
    <FormControl component="fieldset">
      <FormLabel>{label}</FormLabel>
      <FormGroup>
        {items.map((item, index) => {
          return (
            <FormControlLabel
              key={`checkbox-group-${item.id}`}
              control={
                <Checkbox
                  checked={Boolean(state[item.id])}
                  onChange={(e) => onChangeCheckbox(e, index, item)}
                  name={item.id}
                />
              }
              label={item.name || item.code}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
