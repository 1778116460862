import React from 'react';
import { Box, Tab, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Tractor } from './tractor';
import { Truck } from './truck';
import { SUB_SERVICES_ID } from 'commons/constants';

const SUB_SERVICES_OF_TRUCKING = [
  {
    label: 'Đầu kéo',
    value: SUB_SERVICES_ID.TRACTOR,
  },
  {
    label: 'Xe tải',
    value: SUB_SERVICES_ID.TRUCK,
  },
];

export const TruckingService = () => {
  const { TRACTOR, TRUCK } = SUB_SERVICES_ID;
  const [tabIndex, setTabIndex] = React.useState(TRACTOR);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={tabIndex}>
        <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
          {SUB_SERVICES_OF_TRUCKING.map((item) => (
            <TabItem key={`${item.value}`} label={item.label} value={item.value} />
          ))}
        </TabList>

        <TabPanel value={TRACTOR} sx={{ padding: '5px 0px' }}>
          <Tractor />
        </TabPanel>
        <TabPanel value={TRUCK} sx={{ padding: '5px 0px' }}>
          <Truck />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
