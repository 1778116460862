import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography, TextField } from '@mui/material';

export const Main = styled(Box)(({ theme, colorBackground = 'white' }) => ({
  width: '100%',
  marginBottom: '10px',
  height: 'fit-content',
  backgroundColor: colorBackground,
  borderRadius: '15px',
  padding: '10px',
}));

export const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  marginLeft: '10px',
}));
export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
export const HeaderForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const ContainerBody = styled(Box)(({ theme, width = '45%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
export const ContainerLeft = styled(Box)(({ theme, width = '15%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
export const ContainerRight = styled(Box)(({ theme, width = '35%' }) => ({
  width: width,
  display: 'flex',
  flexDirection: 'row',
}));
export const CardAction = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  border: '1px solid',
  padding: '5px',
  borderRadius: '8px',
}));
export const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
export const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
  // borderWidth: '1px',
  // borderStyle: 'solid',
  // borderColor: theme.palette.action.disabled,
}));
export const StartWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-20px',
  left: '15px',
  zIndex: 1,
}));
export const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
