import React, { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { Table } from 'components';
import { FormCreate } from './form-create';
import { FormDetail } from './form-detail';
import { Box, Typography } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';
import { ContainerHeader } from '../styles';
import { BtnCustom } from '../styles';

function InternalCompany({ openAddInternalCompany, closeAddInternalCompany }) {
  const [clientSupportBoardList, setClientSupportBoardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (openAddInternalCompany) {
      setSelected({ isNew: true });
    }
  }, [openAddInternalCompany]);

  const onCloseModal = () => {
    setSelected(null);
    closeAddInternalCompany();
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getClientSupportBoardsByClientCompany();
      setClientSupportBoardList(data || []);
    } catch (err) {
      console.warn('initData - getClientSupportBoardsByClientCompany', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteUnitItem = async (row) => {
    setLoading(true);
    try {
      const res = await ApiServices.deleteClientSupportBoard(row.id);
      if (res) {
        window.UIMessage?.success('Xóa thành công');
        initData();
      }
    } catch (err) {
      window.UIMessage?.error('Xóa thất bại !!!');
      console.warn('delete Client Support Board', err);
    }
    setLoading(false);
  };

  const renderFormDetail = () => {
    if (!selected?.isNew) {
      return <FormDetail {...{ selected, onCloseModal, initData }} />;
    }
    return <FormCreate {...{ selected, onCloseModal, initData }} />;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        rows={clientSupportBoardList}
        headCells={Columns(deleteUnitItem)}
        onClickDetail={(row) => setSelected(row)}
        loading={loading}
        isHeader={false}
      />

      {renderFormDetail()}
    </Box>
  );
}
export { InternalCompany };
