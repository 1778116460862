import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SimpleComboBox, LoadingView, HandleFormDetail } from 'components';
import ApiServices from 'services';
import { ContainerFormDetail } from '../styles';
import Storage from 'storage';
import { RESPONSE_STATUSES } from 'commons/constants';
const FORM_WIDTH = '700px';

export const FormCreate = ({ selected, onCloseModal, initData, ...props }) => {
  const [clientCompanyId, setClientCompanyId] = useState('');
  const [clientCompanyCode, setClientCompanyCode] = useState('');
  const [arrDepartmentId, setArrDepartmentId] = useState([]);
  const [arrDepartmentCode, setArrDepartmentCode] = useState([]);
  const [arrUserCode, setArrUserCode] = useState([]);
  const [loading, setLoading] = useState(false);

  const isSubmit = !clientCompanyId;

  const handleClose = () => {
    onCloseModal();
    setClientCompanyId('');
    setArrDepartmentId([]);
    setArrUserCode([]);
  };

  useEffect(() => {
    if (selected && !selected?.isNew) {
      setClientCompanyId(selected?.shareClientCompanyId || '');
      setArrDepartmentId(selected?.arrShareDepartmentId || []);
      setArrUserCode(selected?.arrShareUserCode || []);
      setArrDepartmentCode(selected?.shareArrDepartmentCode || '');
    }
  }, [selected]);

  const handleAddNew = async () => {
    const dataSend = {
      companyId: Storage?.user?.CompanyId,
      clientCompanyId,
      clientCompanyCode,
      arrDepartmentId,
      arrDepartmentCode,
      arrUserCode,
    };
    setLoading(true);
    ApiServices.createClientSupportBoard(dataSend)
      .then((res) => {
        if (res?.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          handleClose();
          initData();
        }
      })
      .catch((err) => console.log('create Client Support Board: ', err));
    setLoading(false);
  };

  const handleChangeClientCompany = (item, newValue) => {
    setClientCompanyId(item || '');
    setClientCompanyCode(newValue?.code);
  };
  const handleChangeDepartment = (item, newValue) => {
    setArrDepartmentId(item);
    setArrDepartmentCode(newValue?.map((item) => item.code));
  };
  const handleChangeUser = (item, newValue) => {
    setArrUserCode(item);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, width: FORM_WIDTH }}
        showTitleTab={
          selected?.isNew ? 'Tạo mới (Công ty nội bộ)' : 'Thông tin chi tiết (Công ty nội bộ)'
        }
        onClose={handleClose}
      >
        <ContainerFormDetail>
          {selected?.isNew && (
            <SimpleComboBox
              label="Khách hàng (*)"
              disableClearable
              sx={{ marginBottom: '10px', marginLeft: '10px' }}
              value={clientCompanyId}
              keyValue="id"
              keyFieldView="name"
              renderLabel={(option) => `${option?.code} - ${option?.name}`}
              renderOption={(option) => `${option?.code} - ${option?.name}`}
              onChange={handleChangeClientCompany}
              syncData={() => ApiServices.getfilterSupportBoard(Storage?.user?.CompanyId)}
            />
          )}
          <SimpleComboBox
            multiple
            disableCloseOnSelect
            label="Danh sách phòng ban"
            sx={{ marginBottom: '10px', marginLeft: '10px' }}
            value={arrDepartmentId}
            keyValue="id"
            keyFieldView="name"
            onChange={handleChangeDepartment}
            syncData={() => ApiServices.getDepartmentByCompanyId(Storage?.user?.CompanyId)}
          />
          <SimpleComboBox
            multiple
            disableCloseOnSelect
            label="Danh sách user"
            sx={{ marginBottom: '10px', marginLeft: '10px' }}
            value={arrUserCode}
            keyValue="code"
            keyFieldView="name"
            onChange={handleChangeUser}
            syncData={ApiServices.getUsers}
          />
        </ContainerFormDetail>
        <Box sx={{ py: 2, px: 2 }}>
          <Button
            color="primary"
            disabled={isSubmit}
            fullWidth
            size="small"
            variant="contained"
            onClick={handleAddNew}
          >
            Thêm mới
          </Button>
        </Box>
      </HandleFormDetail>
      <LoadingView loading={loading} />
    </>
  );
};
