import actionTypes from './type';
import ApiServices from 'services';

export function fetchUnits() {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getUnits();
      return dispatch({ data, type: actionTypes.FETCH_UNITS });
    } catch (error) {
      console.error('fetchUnits-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_UNITS });
  };
}
export function clearUnits() {
  return async (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_UNITS });
  };
}

export function fetchCurrencies() {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getCurrencies();
      return dispatch({ data, type: actionTypes.FETCH_CURRENCIES });
    } catch (error) {
      console.error('fetchCurrencies-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_CURRENCIES });
  };
}
export function clearCurrencies() {
  return async (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_CURRENCIES });
  };
}

export function fetchPortsByType(type) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getPortsByType(type);
      return dispatch({ data, type: actionTypes.FETCH_PORTS_BY_TYPE });
    } catch (error) {
      console.error('fetchPortsByType-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_PORTS_BY_TYPE });
  };
}
export function clearPortsByType() {
  return async (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_PORTS_BY_TYPE });
  };
}
export function fetchCarriersByType(type) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getCarrierByType(type);
      return dispatch({ data, type: actionTypes.FETCH_CARRIERS_BY_TYPE });
    } catch (error) {
      console.error('fetchCarriersByType-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_CARRIERS_BY_TYPE });
  };
}
export function clearCarriersByType() {
  return async (dispatch) => {
    return dispatch({ type: actionTypes.CLEAR_CARRIERS_BY_TYPE });
  };
}
