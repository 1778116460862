export const WEBSITE_URL = '';

// export const SERVER_URL = 'http://113.161.174.80:5657/';

// export const PATH_PREFIX = '/api';

export default {
  login: '/Login/Login',
  company: '/Company',
  clientSource: '/ClientSource',
  department: '/Department',
  position: '/Position',
  clientGroup: '/ClientGroup',
  clientCompany: '/ClientCompany',
  clientStatus: '/ClientStatus',
  user: '/User',
  client: '/Client',
  clientCareProcess: '/ClientCareProcess',
  clientComplaint: '/ClientComplaint',
  service: '/Service',
  employee: '/Employee',
  loyaltyType: '/LoyaltyType',
  contract: '/Contract',
  document: '/DocumentManagement',
  mail: '/EmailManagement',
  clientCareHistory: '/ClientCareHistory',
  postAndSendMail: '/EmailManagement/PostandSendMail',
  sendEmail: '/EmailManagement/SendEmail',
  scheduleManagement: '/ScheduleManagement',
  callManagement: '/CallManagement',
  emailManagement: '/EmailManagement',
  home: '/Home',
  scheduleOfManager: '/Manager/ScheduleManagement',
  salesTarget: '/Manager/Salestarget',
  countClient: '/Manager/CountClientCareByStatus',
  countClientByUser: '/Manager/CountClientCareByUser',
  countSchedule: '/Manager/CountScheduleUser',
  manager: '/Manager',
  permissionMenu: '/Menu',
  permissionMemberGroup: '/MemberGroup',
  permissionSysFunc: '/SysFunction',
  chatChanel: '/chanel',
  chatMessages: '/messages',
  chatEnum: '/enums',
  personalChanel: '/chanel-personals',
  scheduleUser: '/ScheduleUser',
  fileUpload: '/UploadFile',
  filesUpload: '/UploadFiles',
  fileDownload: '/DownloadByIdAsync',
  setEmailUser: '/User/SetUserEmail',
  getByFileStatus: 'DocumentManagement/GetByFileStatus',
  clientTrash: '/ClientTrash',
  serviceReceived: '/ClientCompany/UpdateServiceReceived',
  getNewLead: '/ClientCompany/GetPaging',
  getCustomer: '/clientCompany/GetListDealCustomer',
  cancelNewLead: '/ClientCompany/DeleteClient',
  getListByPositionId: '/User/GetListByPositionId',
  menu: '/Menu',
  clientRating: '/ClientRatingCategory',
  updateEvaluationCriteria: '/ClientRatingCategory/updateEvaluationCriteria',
  clientEvaluationCriteria: '/ClientEvaluationCriteria',
  getFullListDealCustomer: '/ClientCompany/GetFullListDealCustomer',
  getFullListByClientCompanyId: '/Client/GetFullListByClientCompanyId',
  area: '/Area',
  country: '/Country',
  carrier: '/Carrier',
  currency: '/Currency',
  locationVT: '/LocationVT',
  port: '/Port',
  cargoType: '/TypeCargo',
  unit: '/Unit',
  subService: '/SubService',
  contType: '/TypeCont',
  contractType: '/TypeContract',
  subFeeGroup: '/FeeGroup',
  leaseSpace: '/LeaseSpace',
  packingAir: '/PackingAir',
  surCharge: '/SurCharge',
  freight: '/Freight',
  customs: '/Customs',
  trucking: '/Trucking',
  vendor: '/Vendor',
  warehouse: '/Warehouse',
  checkPrice: '/CheckPrices',
  quote: '/Quotes',
  reportTemplate: '/ReportTemplate',
  clientSupportBoard: '/ClientSupportBoard',
  coloader: '/Coloader',
  deliveryMethod: '/DeliveryMethod',
  // New
  title: '/Title',
  vendorService: '/VendorService',
  clientGift: '/CustomerGifts',
};
