import React from 'react';
import { Box, Typography, Button, Stepper, Step, StepButton } from '@mui/material';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';

import { green } from '@mui/material/colors';
import ApiServices from 'services';

const CareClientSteps = ({ clientCareHistoryData, reload }) => {
  const steps = clientCareHistoryData.objClientCareProcess;

  const dataCompleteInit = clientCareHistoryData?.objClientCareProcess?.map((item, index) => {
    if (clientCareHistoryData.listStep) {
      const ind = clientCareHistoryData?.listStep?.findIndex((data) => data === item.id);
      return ind !== -1 ? true : false;
    } else {
      return false;
    }
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(dataCompleteInit);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    const completeTrue = completed?.filter((item) => {
      return item === true;
    });
    return completeTrue?.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    const dataSend = newCompleted?.map((item, index) => {
      return item ? steps[index].id : '';
    });

    const arrIdStepsComplete = dataSend?.filter((dataFilter) => {
      return dataFilter !== '';
    });
    submitFinishSteps(arrIdStepsComplete);
  };

  const submitFinishSteps = async (arrIdStepsComplete) => {
    try {
      const { status } = await ApiServices.updateApproveProcess(
        clientCareHistoryData.id,
        arrIdStepsComplete,
      );
      if (status === 200) {
        window.UIMessage?.success('Cập nhật thành công');
        handleNext();
        reload();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((item, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {item.name}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 3, mb: 1, color: green[500] }}>
              Đã hoàn thành các bước chăm sóc.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep !== steps.length &&
              (completed[activeStep] ? (
                <Typography sx={{ mt: 2, mb: 1, color: green[500] }}>
                  Bước {activeStep + 1} đã hoàn thành
                </Typography>
              ) : (
                <Typography sx={{ mt: 2, mb: 1 }}>Bước {activeStep + 1} </Typography>
              ))}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="outlined"
                size="small"
                startIcon={<ArrowBackIosIcon />}
              >
                QUAY LẠI
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}></Typography>
                ) : (
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={<CheckCircleOutlineIcon />}
                    sx={{ mr: 1 }}
                    onClick={handleComplete}
                  >
                    HOÀN THÀNH
                  </Button>
                ))}
              <Button
                onClick={handleNext}
                variant="outlined"
                size="small"
                endIcon={<ArrowForwardIosIcon />}
              >
                BƯỚC TIẾP THEO
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export { CareClientSteps };
