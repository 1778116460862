import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const LoadingView = ({ loading }) => {
  const theme = useTheme();
  if (!loading) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: theme.zIndex.drawer + 3,
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};
