import React from 'react';
import {
  Box,
  Link,
  Stack,
  Typography,
  Button,
  Avatar,
  AvatarGroup,
  Divider,
  Grid,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import jwt_decode from 'jwt-decode';
import { useLocation, useHistory } from 'react-router-dom';
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';

import { useSelector, useDispatch } from 'react-redux';
import { fetchChanel } from 'store/reducers/chat-chanel/action';

import { LoadingView } from 'components/loading-view';
import ChatServices from 'services/chat-services';

export const PopupInviteGroup = ({ isSelect, setIsSelect }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataToken, setDataToken] = React.useState({});
  const [token, setToken] = React.useState('');
  const [mapUserAndMember, setMapUserAndMember] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { selectedChanel } = useSelector((state) => state.chatChanel);
  const { users } = useSelector((state) => state.users);

  // const mapUserAndMember =
  //   selectedChanel?.members.map((item) => {
  //     const data = users.find((m) => m.code === item.code);
  //     return data;
  //   }) || [];

  React.useEffect(() => {
    const getDataToken = async () => {
      if (isSelect) {
        setIsLoading(true);
        const tokenBySelect = isSelect.split('/chat/invite-group-by-token?token=')[1];
        if (tokenBySelect) {
          setToken(tokenBySelect);

          const decodeToken = await jwt_decode(tokenBySelect);
          if (decodeToken) {
            setDataToken(decodeToken);
            try {
              const { data } = await ChatServices.getChanelById(decodeToken._id);
              if (data.code === 'success') {
                const datamapUserAndMember =
                  data?.results[0]?.members.map((item) => {
                    const dataUser = users.find((m) => m.code === item.code);
                    return dataUser;
                  }) || [];
                setMapUserAndMember(datamapUserAndMember);
              }
            } catch (error) {
              console.log({ error });
            }
          }
        }
        setIsLoading(false);
      }
    };
    getDataToken();
  }, []);

  const onHanldeAccept = async () => {
    try {
      const { data } = await ChatServices.addMemberByInviteLink(token);
      if (data.code === 'success') {
        setIsSelect(null);
        await dispatch(fetchChanel());
        window.UIMessage.success('Bạn đã tham gia nhóm thành công');
      }
    } catch (error) {
      window.UIMessage.error('Tham gia nhóm thất bại');
    }
  };

  const onHanldeCancel = async () => {
    setIsSelect(null);
  };

  return (
    <>
      <LoadingView loading={isLoading} />
      <Box
        sx={{
          padding: '0px 10px 10px 10px',
          borderRadius: '5px',
          width: '400px',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <AvatarGroup
            max={4}
            sx={{
              justifyContent: 'center',
            }}
          >
            {mapUserAndMember.map((item, index) => (
              <Avatar
                key={item.id}
                alt={item.name}
                src={item?.urlAvatar && `${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                // sx={{ width: 56, height: 56 }}
              />
            ))}
          </AvatarGroup>
          <Typography m={1} component="p" variant="string">
            <b>{dataToken?.name}</b>
          </Typography>
          <Typography component="div" variant="string" sx={{ fontSize: '14px' }}>
            {mapUserAndMember?.length} thành viên
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            margin: '10px 0px',
            maxHeight: '300px',
            overflow: 'auto',
            textAlign: 'center',
          }}
        >
          <Grid container spacing={2}>
            {mapUserAndMember?.map((item, index) => (
              <Grid item xs={4} md={4} key={item.id}>
                <Avatar
                  alt={item.name}
                  src={item?.urlAvatar && `${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
                  sx={{
                    marginLeft: '35px',
                  }}
                />
                <Typography component="div" variant="string" sx={{ fontSize: '12px' }}>
                  {item.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ marginTop: '10px' }}>
          <Typography mb={5} align="center" component="i" variant="string">
            Bạn có muốn tham gia vào nhóm này không?
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginTop: '5px', alignItems: 'end', justifyContent: 'end' }}
          >
            <ButtonCancel
              variant="outlined"
              startIcon={<BsXCircle />}
              color="error"
              onClick={onHanldeCancel}
            >
              Không
            </ButtonCancel>
            <ButtonAccept
              variant="outlined"
              color="success"
              startIcon={<BsCheck2Circle />}
              onClick={onHanldeAccept}
            >
              Tham gia
            </ButtonAccept>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

const ButtonCancel = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  padding: '4px 20px',
}));

const ButtonAccept = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  // color: '#6C757D',
  // border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 20px',
  // '.MuiButton-startIcon': {
  //   marginRight: '2px',
  // },
}));
