import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Selection } from 'components';
import ApiServices from 'services';
import { SUB_SERVICES_ID } from 'commons/constants';
import { CusCargoTypePrice } from './cus-cargo-type-price';
import { CusWeightForm } from './cus-weight-form';

export const CustomsRequestForm = ({
  itemData,
  subServiceSelectedList,
  setSubServiceSelectedList,
}) => {
  const { CUSTOMS_FCL, CUSTOMS_LCL, CUSTOMS_AIR } = SUB_SERVICES_ID;
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [locationVTList, setLocationVTList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [locationFromId, setLocationFromId] = useState('');
  const [areaFromId, setAreaFromId] = useState('');
  const [locationToId, setLocationToId] = useState('');
  const [areaToId, setAreaToId] = useState('');
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [arrTypeCargoIdCheck, setArrTypeCargoIdCheck] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyCode, setCurrencyCode] = useState('');
  const [arrCont, setArrCont] = useState([]);
  const [contTypeList, setContTypeList] = useState([]);
  const [arrSpecificationsWeight, setArrSpecificationsWeight] = useState([]);
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    if (itemData && itemData?.contentList) {
      setLocationFromId(itemData?.contentList?.locationFromId || '');
      setAreaFromId(itemData?.contentList?.areaFromId || '');
      setLocationToId(itemData?.contentList?.locationToId || '');
      setAreaToId(itemData?.contentList?.areaToId || '');
      setArrTypeCargoIdCheck(itemData?.contentList?.arrTypeCargoIdCheck || []);
      setCurrencyCode(itemData?.contentList?.currencyCode || '');
      setArrCont(itemData?.contentList?.arrCont || []);
      setArrSpecificationsWeight(itemData?.contentList?.arrSpecificationsWeight || []);
    }
  }, [itemData]);

  const getDataInits = async () => {
    let [getLocationVT, getArea, getCargoType, getCurrency, getContType, getUnit] =
      await Promise.all([
        ApiServices.getLocationVTs(),
        ApiServices.getAreas(),
        ApiServices.getCargoTypes(),
        ApiServices.getCurrencies(),
        ApiServices.getContTypes(),
        ApiServices.getUnits(),
      ]);
    setLocationVTList(getLocationVT.data || []);
    setAreaList(getArea.data || []);
    setCargoTypeList(getCargoType.data || []);
    setCurrencyList(getCurrency.data || []);
    setContTypeList(getContType.data || []);
    setUnitList(getUnit.data || []);
  };

  useEffect(() => {
    const getDataInit = async () => {
      setLoadingInit(true);
      await getDataInits();
      setLoadingInit(false);
    };
    getDataInit();
  }, []);

  const handleChangeLocationFrom = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setLocationFromId(e.target.value);
      setAreaFromId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.locationFromId = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [locationFromId],
  );
  const handleChangeAreaFrom = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setAreaFromId(e.target.value);
      setLocationFromId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.areaFromId = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [areaFromId],
  );
  const handleChangeLocationTo = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setLocationToId(e.target.value);
      setAreaToId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.locationToId = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [locationToId],
  );
  const handleChangeAreaTo = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setAreaToId(e.target.value);
      setLocationToId('');
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.areaToId = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [areaToId],
  );
  const handleChangeCargoType = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setArrTypeCargoIdCheck(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.arrTypeCargoIdCheck = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [arrTypeCargoIdCheck],
  );
  const handleChangeCurrency = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setCurrencyCode(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.currencyCode = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [currencyCode],
  );
  const handleChangeArrCont = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setArrCont(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.arrCont = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [arrCont],
  );
  const handleChangeSpecificationWeight = useCallback(
    async (e) => {
      const ind = await subServiceSelectedList?.findIndex((el) => el.id === itemData.id);
      if (ind === -1) return;
      setArrSpecificationsWeight(e.target.value);
      const newData = [...subServiceSelectedList];
      newData[ind].contentList.arrSpecificationsWeight = e.target.value;
      setSubServiceSelectedList(newData);
    },
    [arrSpecificationsWeight],
  );

  const ARRAY_SELECT_GENERAL_INFO = [
    {
      value: locationFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(areaFromId),
      label: 'Điểm đi',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationFrom,
    },
    {
      value: areaFromId,
      isError: !locationFromId && !areaFromId,
      disabled: Boolean(locationFromId),
      label: 'Khu vực đi',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaFrom,
    },
    {
      value: locationToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(areaToId),
      label: 'Điểm đến',
      type: 'selection',
      dataArrObject: locationVTList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeLocationTo,
    },
    {
      value: areaToId,
      isError: !locationToId && !areaToId,
      disabled: Boolean(locationToId),
      label: 'Khu vực đến',
      type: 'selection',
      dataArrObject: areaList,
      fieldValue: 'id',
      fieldView: 'name',
      handleChange: handleChangeAreaTo,
    },
  ];

  return (
    <>
      {!loadingInit && !loading && (
        <>
          <ContainerHeaderTractor>
            {itemData?.id === CUSTOMS_FCL && (
              <Selection
                sx={{ width: '50%', marginRight: '10px' }}
                error={!arrTypeCargoIdCheck || arrTypeCargoIdCheck?.length === 0}
                helperText={
                  (!arrTypeCargoIdCheck || arrTypeCargoIdCheck?.length === 0) && 'Bắt buộc (*)'
                }
                label="Loại hàng"
                name="arrTypeCargoIdCheck"
                multiple={true}
                onChange={handleChangeCargoType}
                value={arrTypeCargoIdCheck}
                fieldValue="id"
                fieldView="name"
                dataArrObject={cargoTypeList}
              />
            )}
            <Selection
              sx={{ width: '30%' }}
              error={!currencyCode}
              helperText={!currencyCode && 'Bắt buộc (*)'}
              label="Đơn vị tiền tệ"
              name="currencyCode"
              onChange={handleChangeCurrency}
              value={currencyCode}
              fieldValue="code"
              fieldView="name"
              dataArrObject={currencyList}
            />
          </ContainerHeaderTractor>
          <ContainerGeneral>
            {ARRAY_SELECT_GENERAL_INFO.map((item, index) => {
              return (
                <Selection
                  key={`selection-${item?.label + index}`}
                  sx={{ width: '50%', marginRight: '10px' }}
                  sxInput={{ padding: '0px', margin: '0px' }}
                  error={item?.isError}
                  helperText={item?.isError && 'Bắt buộc (*)'}
                  label={item.label}
                  name={item.field}
                  disabled={item?.disabled}
                  onChange={item?.handleChange}
                  value={item?.value}
                  fieldValue={item?.fieldValue}
                  fieldView={item?.fieldView}
                  syncData={item?.syncData}
                  dataArrObject={item?.dataArrObject}
                  disabledItemNone={false}
                />
              );
            })}
          </ContainerGeneral>
          <ContainerRequestPrice>
            {itemData?.id === CUSTOMS_FCL && arrTypeCargoIdCheck?.length > 0 && (
              <CusCargoTypePrice
                arrContType={arrTypeCargoIdCheck}
                cargoTypeList={cargoTypeList}
                contTypeList={contTypeList}
                name="arrCont"
                requiredTitle="Vui lòng nhập đầy đủ thông tin"
                onChange={handleChangeArrCont}
                value={arrCont}
                isShowPriceReal={false}
              />
            )}
            {(itemData?.id === CUSTOMS_LCL || itemData?.id === CUSTOMS_AIR) &&
              unitList.length > 0 && (
                <CusWeightForm
                  unitList={unitList}
                  name="arrSpecificationsWeight"
                  requiredTitle="Vui lòng nhập đầy đủ thông tin"
                  onChange={handleChangeSpecificationWeight}
                  value={arrSpecificationsWeight}
                  isShowPriceReal={false}
                />
              )}
          </ContainerRequestPrice>
        </>
      )}
    </>
  );
};

export const ContainerGeneral = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const ContainerRequestPrice = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
}));

export const ContainerHeaderTractor = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));
