import { styled } from '@mui/material/styles';
import { Box, Button, Tab } from '@mui/material';

export const ContainerHeader = styled(Box)(({ theme }) => ({
  flex: 1,
}));
export const ContainerHeaderForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '5px 5px 8px 0px',
}));
export const ContainerTableDepartment = styled(Box)(({ theme }) => ({
  width: '50%',
  minHeight: '200px',
  maxHeight: '300px',
  overflow: 'auto',
  backgroundColor: '#FFF',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  borderRadius: '5px',
}));
export const ContainerTableUserAccount = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '200px',
  maxHeight: '500px',
  backgroundColor: '#FFF',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  borderRadius: '5px',
  overflow: 'auto',
}));
export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
export const ContainerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  py: 1,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const ContainerForm = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  height: 'calc(100vh - 175px)',
}));

export const ContainerFormDetail = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  // backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  marginBottom: '10px',
  marginTop: '5px',
  marginRight: '5px',
}));
export const SimpleButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const ButtonAdd = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '2px 20px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const ButtonExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '8px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
}));

export const ButtonImport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));
export const BtnCustom = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '6px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
