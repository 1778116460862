import React from 'react';
import { Box, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { BsPlusCircle } from 'react-icons/bs';
import { TabItem, SimpleButton } from './styles';
import { InternalCompany } from './internal-company';
import { ExternalCompany } from './external-company';

export const ClientSupportBoard = () => {
  const [tabIndex, setTabIndex] = React.useState('1');
  const [addInternalCompany, setAddInternalCompany] = React.useState(false);
  const [addExternalCompany, setAddExternalCompany] = React.useState(false);

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const closeAddNew = () => {
    if (tabIndex === '1') {
      setAddInternalCompany(false);
    } else if (tabIndex === '2') {
      setAddExternalCompany(false);
    }
  };

  const onHandleAddNew = () => {
    if (tabIndex === '1') {
      setAddInternalCompany(true);
    } else if (tabIndex === '2') {
      setAddExternalCompany(true);
    }
  };
  React.useEffect(() => {
    return () => {
      setAddInternalCompany(false);
      setAddExternalCompany(false);
    };
  }, []);
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" component="h6">
        QUẢN LÝ QUYỀN TRUY CẬP KHÁCH HÀNG
      </Typography>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
            <TabItem label="Công ty nội bộ" value="1" />
            <TabItem label="Công ty được chia sẻ" value="2" />
            <Box sx={{ alignItems: 'center', marginLeft: 'auto' }}>
              <SimpleButton
                variant="contained"
                startIcon={<BsPlusCircle />}
                onClick={onHandleAddNew}
              >
                Thêm mới
              </SimpleButton>
            </Box>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: '5px 0px' }}>
          <InternalCompany
            openAddInternalCompany={addInternalCompany}
            closeAddInternalCompany={closeAddNew}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '5px 0px' }}>
          <ExternalCompany
            openAddExternalCompany={addExternalCompany}
            closeAddExternalCompany={closeAddNew}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
