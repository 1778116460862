export default {
  ADD_CHANEL: 'add_chanel',
  DELETE_CHANEL: 'delete_chanel',
  UPDATE_CHANEL: 'update_chanel',
  FETCH_CHANEL: 'fetch_chanel',
  FETCH_PERSONAL_CHANEL: 'fetch_personal_chanel',
  UPDATE_SELECTED_CHANEL: 'update_selected_chanel',
  ADD_MEMBER: 'add_member',
  REMOVE_MEMBER: 'remove_member',
};
