import React, { useState, useEffect, useMemo } from 'react';
import {
  BsEye,
  BsSave,
  BsBoxArrowInUpRight,
  BsXCircle,
  BsCheck2Circle,
  BsEnvelope,
} from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { updateTruckingService, updateCustomsService } from 'store/reducers/customerQuotes/action';
import { SUB_SERVICES_ID, QUOTE_STATUS } from 'commons/constants';
import { Box, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { QuoteCustomsCargoTypeForm } from '../../forms-management/quote-customs-cargo-type-form';
import { QuoteCustomsWeightForm } from '../../forms-management/quote-customs-weight-form';
import { QuoteTruckingPriceForm } from '../../forms-management/quote-trucking-price-form';
import { QuoteTruckingReturnContForm } from '../../forms-management/quote-trucking-return-cont-form';
import { SubCharge } from './sub-charge';

import Utils from 'commons/utils';
import ApiServices from 'services';

import { HeaderFreight } from './header';
import {
  ContainerPrice,
  BtnViewReportQuote,
  BtnCreateQuote,
  BtnRequestQuote,
  BtnRejectQuote,
  BtnApproveQuote,
  BtnSendQuote,
} from './styles';

const MergeCustomsAndTruckingForm = ({ handleCloseAfterSubmit, initData, selected }) => {
  const dispatch = useDispatch();
  const { CUSTOMS_FCL } = SUB_SERVICES_ID;
  const { objQuotes, itemTruckingSelected, itemCustomsSelected } = useSelector(
    (state) => state.customerQuotes,
  );

  const [loading, setLoading] = useState(false);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [locationVTList, setLocationVTList] = useState([]);

  const isDisabledForm = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [selected]);

  const getCargoType = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCargoTypes();
      if (data) {
        setCargoTypeList(data || []);
      }
    } catch (err) {
      console.warn('getCargoType', err);
    } finally {
      setLoading(false);
    }
  };
  const getLocationVT = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getLocationVTs();
      if (data) {
        setLocationVTList(data || []);
      }
    } catch (err) {
      console.warn('getLocationVT', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargoType();
    getLocationVT();
  }, []);

  const createQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: {
        ...objQuotes,
        quotesStatus,
        quotedate: quoteDate,
        arrServiceId: [itemTruckingSelected?.serviceId, itemCustomsSelected?.serviceId],
        arrSubServiceId: [itemTruckingSelected?.subServiceId, itemCustomsSelected?.subServiceId],
      },
      objTrucking: { ...itemTruckingSelected },
      objCustoms: { ...itemCustomsSelected },
    };
    try {
      const { status } = await ApiServices.createCustomTruckQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const updateQuote = async (quotesStatus, quoteDate) => {
    const dataSend = {
      objQuotes: { ...objQuotes, quotedate: quoteDate, quotesStatus },
      objTrucking: { ...itemTruckingSelected },
      objCustoms: { ...itemCustomsSelected },
    };
    try {
      const { status } = await ApiServices.updateCustomTruckQuote(dataSend);
      if (status === 200) {
        handleCloseAfterSubmit();
        initData();
      }
    } catch (error) {
      console.warn('handleSubmit:', error);
    }
  };
  const handleSubmit = (quotesStatus, quoteDate = null) => {
    if (selected?.isNew) {
      return createQuote(quotesStatus, quoteDate);
    } else {
      return updateQuote(quotesStatus, quoteDate);
    }
  };

  const handleChangeCargoType = (event) => {
    dispatch(updateCustomsService({ arrCont: event.target.value }));
  };
  const handleChangeSpecificationsWeight = (event) => {
    dispatch(updateCustomsService({ arrSpecificationsWeight: event.target.value }));
  };
  const handleChangeContTractor = (event) => {
    dispatch(updateTruckingService({ arrContTractor: event.target.value }));
  };
  const handleChangeReturnCont = (event) => {
    dispatch(updateTruckingService({ subChargeNewPort: event.target.value }));
  };

  const renderCustomsService = (item) => (
    <>
      <HeaderFreight item={item} />
      <b>Giá cước:</b>
      <ContainerPrice>
        {item?.subServiceId === CUSTOMS_FCL && (
          <QuoteCustomsCargoTypeForm
            arrContType={item?.arrTypeCargoIdCheck}
            cargoTypeList={cargoTypeList}
            name="arrCont"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={(event) => handleChangeCargoType(event)}
            value={item?.arrCont}
            // disabled={isDisabledForm}
          />
        )}
        {item?.subServiceId !== CUSTOMS_FCL && (
          <QuoteCustomsWeightForm
            name="arrSpecificationsWeight"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={(event) => handleChangeSpecificationsWeight(event)}
            value={item?.arrSpecificationsWeight}
            // disabled={isDisabledForm}
          />
        )}
        <SubCharge dataSubCharge={item} />
      </ContainerPrice>
    </>
  );

  const renderTruckingService = (item) => (
    <>
      <HeaderFreight item={item} />
      <b>Giá cước:</b>
      <ContainerPrice>
        <QuoteTruckingPriceForm
          arrContType={item?.arrTypeCargoCode}
          cargoTypeList={cargoTypeList}
          name="arrContTractor"
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          onChange={(event) => handleChangeContTractor(event)}
          value={item?.arrContTractor}
          // disabled={isDisabledForm}
        />
        <FormGroup>
          <FormControlLabel
            sx={{ width: '50%' }}
            control={<Checkbox checked={item?.subChargeNewPort?.length > 0 ? true : false} />}
            label="Lấy hoặc trả cont ở cảng khác"
          />
        </FormGroup>
        {item?.subChargeNewPort?.length > 0 && (
          <QuoteTruckingReturnContForm
            locationVTList={locationVTList}
            name="subChargeNewPort"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            onChange={(event) => handleChangeReturnCont(event)}
            value={item.subChargeNewPort}
            // disabled={isDisabledForm}
          />
        )}
        <SubCharge dataSubCharge={item} />
      </ContainerPrice>
    </>
  );

  return (
    <>
      {itemTruckingSelected && renderTruckingService(itemTruckingSelected)}
      {itemCustomsSelected && renderCustomsService(itemCustomsSelected)}
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          {!selected?.isNew && (
            <BtnViewReportQuote
              variant="outlined"
              startIcon={<BsEye />}
              // onClick={() => handleReportQuoteTemplate(selected?.arrSubServiceId[0], selected?.id)}
            >
              Xem báo giá
            </BtnViewReportQuote>
          )}
          {(selected?.isNew || selected?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) && (
            <>
              <BtnCreateQuote
                sx={{ marginRight: '15px' }}
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<BsSave size={16} />}
                onClick={() => handleSubmit(QUOTE_STATUS.ORIGIN_QUOTE)}
                disabled={!objQuotes?.clientCompanyId}
              >
                {selected?.isNew ? 'Tạo báo giá' : 'Lưu'}
              </BtnCreateQuote>
              <BtnRequestQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsBoxArrowInUpRight size={20} />}
                onClick={() =>
                  handleSubmit(QUOTE_STATUS.REQUEST_APPROVE, Utils.convertToDateFormat(new Date()))
                }
                disabled={!objQuotes?.clientCompanyId || !objQuotes?.clientId}
              >
                Trình duyệt
              </BtnRequestQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.REQUEST_APPROVE && (
            <>
              <BtnRejectQuote
                sx={{ marginRight: '15px' }}
                color="error"
                type="submit"
                variant="contained"
                startIcon={<BsXCircle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.REJECT_APPROVE)}
              >
                Không duyệt
              </BtnRejectQuote>
              <BtnApproveQuote
                color="success"
                type="submit"
                variant="contained"
                startIcon={<BsCheck2Circle size={20} />}
                onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
              >
                Duyệt
              </BtnApproveQuote>
            </>
          )}
          {selected?.quotesStatus === QUOTE_STATUS.APPROVED && (
            <BtnSendQuote
              color="success"
              type="submit"
              variant="contained"
              startIcon={<BsEnvelope size={20} />}
              // onClick={() => handleSubmit(QUOTE_STATUS.APPROVED)}
            >
              Gửi báo giá
            </BtnSendQuote>
          )}
        </Box>
      </Box>
    </>
  );
};

export { MergeCustomsAndTruckingForm };
