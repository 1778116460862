import { styled } from '@mui/material/styles';
import { Box, Button, Tab } from '@mui/material';

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
}));
export const BtnCreateUpdate = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnCreateFreight = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 25px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnRequestFreight = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnRejectFreight = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));
export const BtnApproveFreight = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  margin: '0px 5px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '10px',
  },
}));

export const ButtonExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '8px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));
export const ButtonImport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '5px',
  },
}));
