import React from 'react';
import { Button, Chip } from '@mui/material';
import { SearchInputColumn } from 'components';
import { USAGE_STATUS } from 'commons/constants';
import Utils from 'commons/utils';
import { BsPencilSquare } from 'react-icons/bs';

export const Columns = (deleteContTypeItem, onSearchInputChange) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Mã"
        onChange={({ target }) => onSearchInputChange(target.value, 'code', true)}
      />
    ),
    align: 'center',
    width: '180px',
    render: (row) => {
      if (row?.code) {
        return row?.code;
      }
      return (
        <BsPencilSquare
          style={{
            fontSize: '20px',
          }}
        />
      );
    },
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Tên phụ phí (tiếng Việt)"
        onChange={({ target }) => onSearchInputChange(target.value, 'name', true)}
      />
    ),
    align: 'center',
    width: '180px',
    // render: (row) => {
    //   if (row?.name) {
    //     return row?.name;
    //   }
    //   return (
    //     <BsPencilSquare
    //       style={{
    //         fontSize: '20px',
    //       }}
    //     />
    //   );
    // },
  },
  {
    id: 'nameEN',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Phụ phí (tiếng Anh)"
        onChange={({ target }) => onSearchInputChange(target.value, 'nameEN', true)}
      />
    ),
    align: 'center',
    width: '180px',
  },
  {
    id: 'nameCN',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Phụ phí (tiếng Hoa)"
        onChange={({ target }) => onSearchInputChange(target.value, 'nameCN', true)}
      />
    ),
    align: 'center',
    width: '180px',
  },
  {
    id: 'nameJP',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Phụ phí (tiếng Nhật)"
        onChange={({ target }) => onSearchInputChange(target.value, 'nameJP', true)}
      />
    ),
    align: 'center',
    width: '180px',
  },
  {
    id: 'decription',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Mô tả"
        onChange={({ target }) => onSearchInputChange(target.value, 'decription', true)}
      />
    ),
    align: 'center',
  },
  {
    id: 'subServiceName',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Dịch vụ phụ"
        onChange={({ target }) => onSearchInputChange(target.value, 'subServiceName', true)}
      />
    ),
    align: 'center',
  },
  {
    id: 'feeGroupName',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Nhóm phụ phí"
        onChange={({ target }) => onSearchInputChange(target.value, 'feeGroupName', true)}
      />
    ),
    align: 'center',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Đơn giá',
    align: 'center',
  },
  {
    id: 'unitCode',
    numeric: true,
    disablePadding: false,
    label: 'Đơn vị tính',
    align: 'center',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.createdOn),
  },
  {
    id: 'updatedOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày chỉnh sửa cuối',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.updatedOn),
  },
  {
    id: 'expirydate',
    numeric: true,
    disablePadding: false,
    label: 'Hết hạn',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.expirydate),
  },
  {
    id: 'usageStatus',
    numeric: true,
    disablePadding: false,
    label: 'Trạng thái',
    align: 'center',
    render: (row) => {
      return (
        <Chip
          size="small"
          label={USAGE_STATUS[row?.usageStatus]?.label}
          color={USAGE_STATUS[row?.usageStatus]?.color}
          sx={{ padding: '0px 10px 0px 10px' }}
        />
      );
    },
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: 'Ghi chú',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteContTypeItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
