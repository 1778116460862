import User from '../models/user';
import { STORAGE } from '../commons/constants';
import Utils from '../commons/utils';
import ApiServices from '../services';
import ChatService from 'services/chat-services';

class Storage {
  constructor() {
    this.user = new User();
    this.enums = {};
    // this.chatEnums = {};
    this.isDarkTheme = false;
    this.isCollapsed = false;
    this.activeMenuItem = {
      openKey: 'sub1',
      key: 'car',
    };
  }

  loadData = async () => {
    return Promise.all([this.getEnums(), this.retrieveUser()]);
  };
  getChatEnums = async () => {
    try {
      const { data } = await ChatService.getEnums();
      this.chatEnums = data;
    } catch (error) {
      console.warn('ERR-get Enums', error);
    }
  };
  getEnums = async () => {
    try {
      const { data } = await ApiServices.getEnums();
      console.log('getEnums', data);
      this.enums = data;
    } catch (error) {
      console.warn('ERR-get Enums', error);
    }
  };

  saveUser = async (user) => {
    try {
      this.user = User.clone({
        ...this.user,
        ...Utils.removeEmptyAttributes(user),
      });
      console.log(this.user);
      await localStorage.setItem(STORAGE.USER_DATA, JSON.stringify(this.user));
    } catch (error) {
      console.log('[Storage] Save user error', error);
    } finally {
      return this.user;
    }
  };

  retrieveUser = async () => {
    try {
      const user = await localStorage.getItem(STORAGE.USER_DATA);
      if (user) {
        this.user.update(JSON.parse(user));
        return this.user;
      }
    } catch (error) {
      console.log('[Storage] Retrieve user data error:', error);
    }
    return null;
  };

  removeUser = async () => {
    console.log('Remove');
    try {
      this.user = new User();
      await localStorage.removeItem(STORAGE.USER_DATA);
      await this.removeDataDecodeToken();
      await this.removeTokenFireBase();
    } catch (error) {
      console.log('[Storage] Remove user data error', error);
    }
  };

  isLoggedIn = () => {
    return this.user && this.user.token;
  };

  // Decode Data By Token
  saveDataDecodeToken = (dataUser) => {
    return localStorage.setItem(STORAGE.USER_DATA_DECODE_TOKEN, JSON.stringify(dataUser));
  };
  getDataDecodeToken = () => {
    return JSON.parse(localStorage.getItem(STORAGE.USER_DATA_DECODE_TOKEN));
  };
  removeDataDecodeToken = () => {
    return localStorage.removeItem(STORAGE.USER_DATA_DECODE_TOKEN);
  };

  // Firebase
  saveTokenFireBase = (tokenFirebase) => {
    return localStorage.setItem(STORAGE.TOKEN_FIREBASE, JSON.stringify(tokenFirebase));
  };
  getTokenFireBase = () => {
    return JSON.parse(localStorage.getItem(STORAGE.TOKEN_FIREBASE));
  };
  removeTokenFireBase = () => {
    return localStorage.removeItem(STORAGE.TOKEN_FIREBASE);
  };
}

const storage = new Storage();
export default storage;
