import actionTypes from './type';

const initialState = {
  users: [],
  onlineUsers: [],
  typingUsers: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USERS:
      return {
        ...state,
        users: action.users,
      };
    case actionTypes.ONLINE_USERS_CHANGED:
      return {
        ...state,
        onlineUsers: action.users,
      };
    case actionTypes.TYPING_USERS_CHANGED:
      return {
        ...state,
        typingUsers: action.users,
      };
    default:
      return state;
  }
}
