import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { Selection } from 'components';
import ApiServices from 'services';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  (
    {
      item,
      index,
      onDelete,
      onAdd,
      values,
      setValues,
      disabledField,
      keyArray,
      keyNameOfArray,
      cargoTypeList,
    },
    ref,
  ) => {
    const [contTypeCode, setContType] = React.useState('');
    const [arrayCont, setArrayCont] = React.useState([]);
    const [remark, setRemark] = React.useState('');

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setContType(item?.contTypeCode);
      setRemark(item?.remark);
      setArrayCont(item[keyArray] || []);
    }, [item]);

    const onChangeContType = React.useCallback(
      (e) => {
        setContType(e.target.value);
        setValues((prev) => {
          prev[index].contTypeCode = e.target.value;
          return [...prev];
        });
      },
      [contTypeCode],
    );

    const onChangeRemark = React.useCallback(
      (e) => {
        setRemark(e.target.value);
        setValues((prev) => {
          prev[index].remark = e.target.value;
          return [...prev];
        });
      },
      [remark],
    );

    const onChangeCont = React.useCallback(
      (e, indContType) => {
        setArrayCont((prev) => {
          prev[indContType].cargoTypePrice = +e.target.value;
          return [...prev];
        });
        setValues((prev) => {
          prev[index][keyArray] = arrayCont;
          return [...prev];
        });
      },
      [arrayCont],
    );

    const renderLabel = (id) => {
      const ind = cargoTypeList.findIndex((item) => item.id === id);
      if (ind === -1) return '';
      return cargoTypeList[ind]?.name;
    };

    const isDisabled = !contTypeCode || !arrayCont.some((item) => item.cargoTypePrice);

    return (
      <Container>
        <ContainerBody>
          <Box sx={{ width: '100%' }}>
            <Selection
              required
              sx={{
                width: '100%',
                marginTop: 0,
                fontSize: '12px',
              }}
              sxInput={{ padding: '0px', margin: '0px' }}
              label="Loại cont"
              name="contTypeCode"
              onChange={onChangeContType}
              value={contTypeCode}
              syncData={ApiServices.getContTypes}
              fieldValue="code"
              error={!contTypeCode}
              disabled={disabledField}
            />
            {!contTypeCode && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
          {arrayCont?.map((el, indContType) => (
            <Box sx={{ width: '100%' }} key={indContType}>
              <StyledTextFieldPrice
                label={renderLabel(el[keyNameOfArray])}
                variant="outlined"
                type="text"
                size="small"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => onChangeCont(e, indContType)}
                value={el?.cargoTypePrice}
                error={!el?.cargoTypePrice}
                disabled={disabledField}
              />
              {!el?.cargoTypePrice && <ErrorText>Bắt buộc (*)</ErrorText>}
            </Box>
          ))}
          <StyledTextField
            label="Remark"
            variant="outlined"
            type="text"
            size="small"
            onChange={onChangeRemark}
            value={remark}
            disabled={disabledField}
          />
        </ContainerBody>
        <ContainerAction>
          {index === values.length - 1 ? (
            <>
              <ButtonWrapper
                onClick={() => onDelete(index)}
                sx={{ borderColor: 'error.main' }}
                disabled={disabledField}
              >
                <HighlightOffIcon sx={{ color: 'error.main' }} />
              </ButtonWrapper>
              <ButtonWrapper
                onClick={onAdd}
                sx={{ borderColor: isDisabled ? 'action.disabled' : 'success.main' }}
                disabled={isDisabled}
              >
                <AddCircleOutlineIcon
                  sx={{ color: isDisabled ? 'action.disabled' : 'success.main' }}
                />
              </ButtonWrapper>
            </>
          ) : (
            <ButtonWrapper onClick={() => onDelete(index)} sx={{ borderColor: 'error.main' }}>
              <HighlightOffIcon sx={{ color: 'error.main' }} />
            </ButtonWrapper>
          )}
        </ContainerAction>
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  width: '95%',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  marginLeft: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px',
}));
const ContainerBody = styled(Box)(({ theme }) => ({
  width: '95%',
  display: 'flex',
  flexDirection: 'row',
}));
const ContainerAction = styled(Box)(({ theme }) => ({
  width: '5%',
  display: 'flex',
  flexDirection: 'row',
}));
const ButtonWrapper = styled(Box)(({ theme, disabled = false }) => ({
  pointerEvents: disabled ? 'none' : 'all',
  paddingInline: '6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0 4px 4px 0',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
